import React, { useEffect } from 'react';
import './TextArea.css';

function TextAreaCustom(props) {
    const {
        label,
        validation,
        count,
        handleChange,
        onKeyUpHandler = false,
        onClickHandler = false,
        value,
        helperText,
        placeholder,
        errorMsg,
        disabled,
        error,
        name,
        allowExceedMaxLength = false
    } = props;



    return (
        <div className="TextArea">
            <div className='TextAreaHeader'>
                {props.FieldLabel && (<div className="FieldLabel"> {label} {validation && <span>*</span>} </div>)}
                <div className='HeaderRightBody'>{props.HeaderRightBody}</div>
            </div>
            {props.ButtonHeaderStatus &&
                <div className='TextAreaButtonHeader'>{props.ButtonHeader}</div>
            }
            <textarea
                type="text"
                rows={5}
                maxLength={!allowExceedMaxLength ? count : undefined}
                placeholder={placeholder}
                onChange={(e) => {
                    handleChange(e);
                }}
                onKeyUp={(e) => {
                    if (onKeyUpHandler) {
                        onKeyUpHandler(e)
                    }
                    console.log(e, 'keypressed');
                }}
                onClick={(e) => {
                    if (onClickHandler) {
                        onClickHandler(e)
                    }
                }}
                value={value}
                disabled={disabled ? disabled : false}
                name={name}
                style={{ resize: "none", border: `${error ? '1px solid red' : ''}` }}
            />
            {count && (
                <div className='CharCountCustomContainer'>
                    <span className="CharCount">
                        {!value ? '0' : value.length}/{count}
                    </span>
                    {props.PartStatus &&
                        <>
                            <div className="Sline"></div>
                            <span className="CharCount">
                                PARTS {!value ? '0' : `${Math.ceil(value.length / props.PartCharaterLimit)}/${props.Partlimit}`}
                            </span>
                        </>
                    }
                </div>
            )}
            <div className="HelperTextCounter">
                <div>
                    <p className="error-msg">{errorMsg}</p>
                    <p className="HelperText">{helperText}</p>
                </div>
            </div>
        </div>
    );
}

export default TextAreaCustom;