import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Buttons from '../../../../components/Form/Button/Button';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import EditAccountDetails from './EditAccountDetails';
import { useGetSchoolQuery, useUpdateSchoolMutation } from '../../../../redux/features/schools/schoolApi';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import useProtected from '../../../../hooks/useProtected';
import { toast } from 'react-toastify';
import { countries } from '../../../../assets/data/countries';
import { currencies } from '../../../../assets/data/currencies';

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
    "&.MuiTableCell-root": {
        width: '52px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#F8F8F8",
        },
    },
}))(TableRow);
// ================= Table OD and EVEN Code ========//


const AccountDetails = () => {
    const { schoolId } = useSelector(state => state.auth.user);
    const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId)
    const [updateSchool, updateSchoolOptions] = useUpdateSchoolMutation();

    useProtected(error);
    useProtected(updateSchoolOptions.error);

    const [detailsDrawer, setDetailsDrawer] = useState(false)
    const [schoolInfo, setSchoolInfo] = useState({})
    const [nameError, setNameError] = useState('')
    const [dbaError, setDbaError] = useState('')
    const [einNumberError, setEinNumberError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [countryError, setCountryError] = useState('')


    // const [formFieldsValue, setFormFieldsValue] = ({})
    let initialObj = {
        name: '',
        dba: '',
        einNumber: '',
        email: '',
        phone: '',
        phoneExt: '',
        country: '',
        address: "",
        addressLine1: "",
        addressLine2: "",
        city: '',
        state: '',
        zip: '',
        currency: '',
        timezone: ''
    }

    const [formFieldsValue, setFormFieldsValue] = useState(initialObj)

    const setValues = (schoolInfoData) => {
        setFormFieldsValue({
            name: schoolInfoData.name,
            dba: schoolInfoData.dba,
            einNumber: schoolInfoData.einNumber,
            email: schoolInfoData.email,
            phone: schoolInfoData.phone,
            phoneExt: schoolInfoData.phoneExt,
            country: schoolInfoData.country,
            address: schoolInfoData.address,
            addressLine1: schoolInfoData.addressLine1,
            addressLine2: schoolInfoData.addressLine2,
            city: schoolInfoData.city,
            state: schoolInfoData.state,
            zip: schoolInfoData.zip,
            currency: schoolInfoData.currency,
            timezone: schoolInfoData.timezone
        })
    }

    useEffect(() => {
        if (!isLoading && data && !error) {
            setSchoolInfo(data?.body?.data)
        }
    }, [data, error, isLoading])
    useEffect(() => {
        setValues(schoolInfo)
    }, [schoolInfo])

    const profileErrorObject = {
        name: "",
        dba: "",
        einNumber: "",
        email: "",
        country: "",
        address: "",
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
        currency: "",
        timezone: ""
    };
    const [errors, setErrors] = useState(profileErrorObject)
    const [errorStatus, setErrorStatus] = useState(false)
    const [manualAddress, setManualAddress] = useState(false)
    useEffect(() => {
        if (errorStatus) {
            checkErrors()
        }
    }, [errorStatus, formFieldsValue])




    const checkErrors = async () => {
        let error = 0;
        let errorObject = { ...errors };

        if (formFieldsValue?.name === undefined || formFieldsValue?.name === "") {
            error++;
            errorObject.name = "Required";
        } else {
            errorObject.name = "";
        }

        if (formFieldsValue?.dba === undefined || formFieldsValue?.dba === "") {
            error++;
            errorObject.dba = "Required";
        } else {
            errorObject.dba = "";
        }

        if (formFieldsValue?.einNumber === undefined || formFieldsValue?.einNumber === "") {
            error++;
            errorObject.einNumber = "Required";
        } else if (formFieldsValue?.einNumber.length < 9) {
            error++;
            errorObject.einNumber = "Enter a valid EIN number";
        } else {
            errorObject.einNumber = "";
        }

        if (formFieldsValue?.email === undefined || formFieldsValue?.email === "") {
            error++;
            errorObject.email = "Required";
        } else {
            errorObject.email = "";
        }
        if (formFieldsValue?.timezone === undefined || formFieldsValue?.timezone === "") {
            error++;
            errorObject.timezone = "Required";
        } else {
            errorObject.timezone = "";
        }

        if (formFieldsValue?.country === undefined || formFieldsValue?.country === "") {
            error++;
            errorObject.country = "Required";
        } else {
            errorObject.country = "";
        }
        if (manualAddress && (formFieldsValue?.address === undefined || formFieldsValue?.address === "")) {
            error++;
            errorObject.address = "Required";
        } else {
            errorObject.address = "";
        }

        if (!manualAddress && (formFieldsValue?.addressLine1 === undefined || formFieldsValue?.addressLine1 === "")) {
            error++;
            errorObject.addressLine1 = "Required";
        } else {
            errorObject.addressLine1 = "";
        }

        if (!manualAddress && (formFieldsValue?.city === undefined || formFieldsValue?.city === "")) {
            error++;
            errorObject.city = "Required";
        } else {
            errorObject.city = "";
        }

        if (!manualAddress && (formFieldsValue?.state === undefined || formFieldsValue?.state === "")) {
            error++;
            errorObject.state = "Required";
        } else {
            errorObject.state = "";
        }

        if (!manualAddress && (formFieldsValue?.zip === undefined || formFieldsValue?.zip === "")) {
            error++;
            errorObject.zip = "Required";
        } else {
            errorObject.zip = "";
        }
        setErrors({ ...errors, ...errorObject });
        return error > 0 ? false : true
    };

    const handleUpdateSettings = async () => {
        setErrorStatus(true)
        let data = {
            name: formFieldsValue.name,
            dba: formFieldsValue.dba,
            einNumber: formFieldsValue.einNumber,
            email: formFieldsValue.email,
            phone: formFieldsValue.phone,
            phoneExt: formFieldsValue.phoneExt,
            country: formFieldsValue.country,
            address: formFieldsValue.address,
            addressLine1: formFieldsValue?.addressLine1,
            addressLine2: formFieldsValue?.addressLine2,
            city: formFieldsValue?.city,
            state: formFieldsValue?.state,
            zip: formFieldsValue?.zip,
            currency: formFieldsValue.currency,
            timezone: formFieldsValue.timezone
        }
        updateAllRequiredField()
        const checkValid = await checkErrors()


        if (einNumberError != "" || !isAllValid() || !checkValid) {
            return false
        }
        let result = await updateSchool({ ...data, id: schoolInfo._id })

        if (result?.data) {
            toast.success('Account details updated successfully');
            handleDrawerCLose()

        } else {
            toast.error(result?.data?.message);
        }
    }
    const handleCopyCode = () => {
        if (schoolInfo?.schoolAccessCode) {
            navigator.clipboard.writeText(schoolInfo?.schoolAccessCode)
                .then(() => {
                    toast.success('Copied');
                })
                .catch((error) => {
                    console.error('Error copying to clipboard: ', error);
                    toast.error('Failed to copy code');
                });
        } else {
            toast.warning('No code to copy');
        }
    };

    const addHyphenAfterTwoDigits = (number) => {
        const numberStr = number
        if (Number.isInteger(number)) {
            numberStr = number.toString();
        }
        if (numberStr.length >= 2) {
            const modifiedStr = numberStr.slice(0, 2) + '-' + numberStr.slice(2);
            return modifiedStr;
        }
        return number.toString(); // Return the original number as a string if it's not a valid operation
    }
    const prepareEinNumberValue = (val) => {
        val = val.replace(/[^0-9]/g, '')
        if (val.length > 9) {
            val = val.slice(0, -1)
        }
        return val
    }
    const checkRequiredNumber = (event, fieldName) => {
        if (fieldName == 'name') {
            setNameError(event.target.value == "" ? "Required" : "")
        } else if (fieldName == 'dba') {
            setDbaError(event.target.value == "" ? "Required" : "")

        } else if (fieldName == 'einNumber') {
            setEinNumberError(event.target.value == "" ? "Required" : "")


        } else if (fieldName == 'email') {
            setEmailError(event.target.value == "" ? "Required" : "")

        } else if (fieldName == 'country') {
            setCountryError(event.target.value == "" ? "Required" : "")
        }
    }
    const isAllValid = () => {
        if (nameError != "") return false
        if (dbaError != "") return false
        if (einNumberError != "") return false
        if (emailError != "") return false
        if (countryError != "") return false

        return true
    }


    const updateAllRequiredField = () => {

        setNameError(formFieldsValue.name == "" ? "Required" : "")
        setDbaError(formFieldsValue.dba == "" ? "Required" : "")

        setEinNumberError(formFieldsValue.einNumber == "" ? "Required" : "")
        setEinNumberError(formFieldsValue.einNumber.length < 9 ? "Enter a valid EIN number" : "")
        setEmailError(formFieldsValue.email == "" ? "Required" : "")

        setCountryError(formFieldsValue.country == "" ? "Required" : "")
    }

    const handleDrawerCLose = () => {
        setDetailsDrawer(false); setErrors(profileErrorObject); setValues(schoolInfo)
        setErrorStatus(false)
    }
    return (
        <>
            <div className="TableContainer oddEvenTable" style={{ border: '1px solid #E8EAED' }}>
                {/* <SimpleBackdrop open={updateSettingsOptions.isLoading} /> */}
                <div className="TableInnerHeader" style={{ padding: '12px 1.2rem 0 1.2rem', marginBottom: '14px' }}>
                    <h3>Account Details</h3>
                    <div className="EditUserProfile" >
                        <Buttons
                            label="Edit Details"
                            buttonColor="#0450E1"
                            border="#E4E8EC 1px solid"
                            color="#fff"
                            width="118px"
                            height="38px"
                            fontSize="14px"
                            onClick={() => setDetailsDrawer(true)}
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableBody>
                            {/* ================= School access code ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">School access code</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo accessDetailsPasswordRow">
                                    <span onClick={() => !schoolInfo?.schoolAccessCode && setDetailsDrawer(true)}>{schoolInfo?.schoolAccessCode || 'N/A'}</span>
                                    {/* <a className="addText" href='/'  >Add gender</a> */}
                                    <div style={{ paddingRight: "8px" }}>
                                        <Buttons
                                            label="Copy"
                                            icon={<ContentCopyOutlinedIcon fontSize='small' />}
                                            buttonColor=""
                                            border="#C6C6D0 1px solid"
                                            color="#004FE0"
                                            width=""
                                            height="28px"
                                            fontSize="14px"
                                            onClick={(e) => handleCopyCode()}
                                        />
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= School full name ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">School full name</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.name || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add School full name</a>}</span>

                                    {/* <a className="addText" href='/'  >Add gender</a> */}

                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= DBA/Short name ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">DBA/Short name</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.dba || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add DBA/Short name</a>}</span>
                                    {/* <a className="addText" href='/' >Add country</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= EIN ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">EIN</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.einNumber ? addHyphenAfterTwoDigits(schoolInfo?.einNumber) : <a className="addText" onClick={() => setDetailsDrawer(true)} >Add EIN</a>}</span>
                                    {/* <a className="addText" href='/' >Add address</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Email ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Email</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.email || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add email</a>}</span>
                                    {/* <a className="addText" href='/' >Add whatsapp number</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Phone ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Phone</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{(schoolInfo.phoneExt) ? `${schoolInfo?.phone}, ext. ${schoolInfo.phoneExt}` : schoolInfo?.phone || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add phone</a>}</span>
                                    {/* <a className="addText" href='/' >Add birthDate</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Country ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Country</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.country ? countries?.find(country => country.value === schoolInfo.country)?.label : <a className="addText" onClick={() => setDetailsDrawer(true)} >Add country</a>}</span>
                                    {/* <a className="addText" href='/' >Add timezones</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Address ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Address</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.addressLine1 ? `${schoolInfo?.addressLine1} ${schoolInfo?.city}, ${schoolInfo?.state} ${schoolInfo?.zip}` : schoolInfo?.address?.label || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add address</a>}</span>
                                    {/* <a className="addText" href='/' >Add timezones</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Currency ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Currency</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.currency ? currencies?.find(curr => curr.value === schoolInfo.currency)?.label : <a className="addText" onClick={() => setDetailsDrawer(true)} >Add currency</a>}</span>
                                    {/* <a className="addText" href='/' >Add timezones</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* ================= Timezone ================= */}
                            <StyledTableRow >
                                <StyledTableCell align="left" className="TableLabel">Timezone</StyledTableCell>
                                <StyledTableCell align="left" className="TableInfo">
                                    <span>{schoolInfo?.timezone || <a className="addText" onClick={() => setDetailsDrawer(true)} >Add timezone</a>}</span>
                                    {/* <a className="addText" href='/' >Add timezones</a> */}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <SideDrawer
                title="Edit Account Details"
                cancelButtonLabel="Cancel"
                deleteText="Delete Permanently"
                sideOpen="right"
                open={detailsDrawer}
                handleDrawerClose={handleDrawerCLose}
                ButtonLeft="Upload New"
                FooterWithoutTab={true}
                ButtonLabel="Save"
                clickSaveButton={(e) => handleUpdateSettings()}
                body={
                    <div>
                        <EditAccountDetails
                            setEinNumberError={setEinNumberError}
                            schoolInfo={schoolInfo}
                            setFormFieldsValue={setFormFieldsValue}
                            formFieldsValue={formFieldsValue}
                            einNumberError={einNumberError}
                            nameError={nameError}
                            setNameError={setNameError}
                            dbaError={dbaError}
                            setDbaError={setDbaError}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            countryError={countryError}
                            setCountryError={setCountryError}
                            checkRequiredNumber={checkRequiredNumber}
                            manualAddress={manualAddress} setManualAddress={setManualAddress} errors={errors}
                        />
                    </div>
                }
            />
        </>
    )
}

export default AccountDetails