import { api } from '../../api/apiSlice';

const templatesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMessageTemplateLists: builder.query({
            query: () => ({
                url: '/message-templates/all',
            }),
            providesTags: ['MessageTemplates'],
        }),
        createMessageTemplate: builder.mutation({
            query: (newTemplate) => ({
                url: '/message-templates/create',
                method: 'POST',
                body: newTemplate,
            }),
            invalidatesTags: ['MessageTemplates'],
        }),
        getMessageTemplate: builder.query({
            query: (id) => ({
                url: `/message-templates/${id}`,
            }),
            providesTags: ['MessageTemplate'],
        }),
        updateMessageTemplate: builder.mutation({
            query: ({ id, ...updatedTemplate }) => ({
                url: `/message-templates/${id}`,
                method: 'PATCH',
                body: updatedTemplate,
            }),
            invalidatesTags: ['MessageTemplate', 'MessageTemplates'],
        }),
        deleteMessageTemplates: builder.mutation({
            query: (id) => ({
                url: `/message-templates/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['MessageTemplates'],
        }),
        searchMessageTemplates: builder.mutation({
            query: (data) => ({
                url: '/message-templates/search',
                method: 'POST',
                body: data,
            }),
        }),
    })
});

export const {
    useGetMessageTemplateListsQuery, // ? get all template
    useCreateMessageTemplateMutation, // ? create new 
    useGetMessageTemplateQuery, // ? get single one
    useUpdateMessageTemplateMutation, // ? update
    useDeleteMessageTemplatesMutation, // ? delete
    useSearchMessageTemplatesMutation // ? search
} = templatesApi;
