import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthData, getUserData } from '../components/hooks/HelperFunctions';
import { useSelector } from "react-redux";

const SchoolRouteWithoutLayout = ({ collapsed, children }) => {

    const user = useSelector((state) => state.auth.user);
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();
    // const userAuthenticated = getUserAuthData();
    // retrive user data
    // const userData = getUserData();
    // assign user role
    const userType = user?.userType ? user.userType : "";
    if (!user) {
        return <Navigate to="/signin" state={{ from: location.pathname }} replace />
    } else if (!((userType === 'staff') || (userType === 'owner') || (userType === 'faculty'))) {
        return <Navigate to="/parent-portal" state={{ from: location.pathname }} replace />
    }



    return (
        <>
            {children}
        </>
    );
}

export default SchoolRouteWithoutLayout;