import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';



function AttendanceTableCell(props) {
    const {
        setAttendanceDateToInsert,
        setStudentIdForAttendance,
        updateAttendanceSingle,
        fullWeekDates,
        setAttendanceIdToUpdate,
        row
    } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event, singleDate, id, attendanceId) => {
        setAttendanceDateToInsert(singleDate)
        setStudentIdForAttendance(id)
        setAttendanceIdToUpdate(attendanceId)
        setAnchorEl(event.currentTarget);
    };
    const setAttendanceHandler = (status) => {
        updateAttendanceSingle(status)
        handleClose()
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {
                props.cell.map((item, i) => (
                    <TableCell className="Attendance-table-status-cell">

                        {item.disabled &&
                            <div className='cellDisabled'></div>
                        }

                        <span className='Attendance-table-status-background' style={{ background: item.Color }}></span>
                        <Button
                            id="basic-button"
                            variant='text'
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e) => handleClickMenu(e, fullWeekDates[i], row.id, item.attendanceId)}
                            className='Attendance-table-status'
                        >
                            <span className='Attendance-table-label' style={{ color: item.Color }}>
                                {item.Status}
                            </span>
                        </Button>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={(e) => setAttendanceHandler('P')}>
                                <div className='AttendanceMenuItem'>
                                    <div
                                        className='AttendanceColorBox'
                                        style={{ background: '#41AB37' }}
                                    ></div>
                                    <p><span>P</span> - <span>Present</span></p>
                                </div>
                            </MenuItem>

                            <MenuItem onClick={(e) => setAttendanceHandler('L')}>
                                <div className='AttendanceMenuItem'>
                                    <div
                                        className='AttendanceColorBox'
                                        style={{ background: '#FFB606' }}
                                    ></div>
                                    <p><span>L</span> - <span>Late</span></p>
                                </div>
                            </MenuItem>

                            <MenuItem onClick={(e) => setAttendanceHandler('A')}>
                                <div className='AttendanceMenuItem'>
                                    <div
                                        className='AttendanceColorBox'
                                        style={{ background: '#BA1A1A' }}
                                    ></div>
                                    <p><span>A</span> - <span>Absent</span></p>
                                </div>
                            </MenuItem>

                            <MenuItem onClick={(e) => setAttendanceHandler('WKD')}>
                                <div className='AttendanceMenuItem'>
                                    <div
                                        className='AttendanceColorBox'
                                        style={{ background: '#595E72' }}
                                    ></div>
                                    <p><span>WKD</span> - <span>Weekend</span></p>
                                </div>
                            </MenuItem>

                        </Menu>
                    </TableCell>
                ))
            }
        </>
    );
}

export default AttendanceTableCell;
