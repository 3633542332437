import React, { useState } from 'react';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Table from '../../../components/Table/AttendanceTable';
import Export from './Export';

import {
  useGetAttendanceByDateRangeMutation,
  useExportAttendanceReportMutation,
  useGetAttendanceBySearchMutation,
  useGetAllAttendanceCodesQuery,
  useGetAttendanceByFilterMutation
} from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { useGetStudentsQuery } from '../../../redux/features/users/userApi';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import axios from 'axios';
import AttendanceFilter from './AttendanceFilter';
import { useGetRoleListQuery } from '../../../redux/features/roles/roleApi';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import Loader from '../../../components/Loader/Loader';
import moment from 'moment-timezone';

const AttendanceHistory = () => {

  const [getAttendanceByDateRange, getAttendanceByDateRangeOption] = useGetAttendanceByDateRangeMutation()
  const [exportAttendanceReport, exportAttendanceReportOption] = useExportAttendanceReportMutation()
  const { data: studentData, isLoading: studentIsLoading, isError: studentIsError, error: studentError } = useGetStudentsQuery()
  const [filterAttendance, filterAttendanceOptions] = useGetAttendanceByFilterMutation();
  const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const { data: attendanceCodeResponse, isLoading: isLoadingAttendanceCode, error: attendanceCodeError } = useGetAllAttendanceCodesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useProtected(getAttendanceByDateRangeOption.error);
  useProtected(exportAttendanceReportOption.error);
  useProtected(studentError);
  useProtected(filterAttendanceOptions.error);
  useProtected(attendanceCodeError);

  const [AttendanceExport, setAttendanceExport] = useState(false);
  const [allStudentsAttendance, setAllStudentsAttendance] = useState([])
  const [rows, setRows] = useState([]);
  const [historyShowDate, setHistoryShowDate] = useState(dayjs())
  const [startDate, setStartDate] = useState({})
  const [endDate, setEndDate] = useState({})
  const [allStudents, setStudents] = useState([])
  const [allStudentsAttendanceOriginal, setAllStudentsAttendanceOriginal] = useState([]);
  const [userTypesExport, setUserTypesExport] = useState([])
  const [attendenceTypesExport, setAttendenceTypesExport] = useState([])
  const [gradeTypesExport, setGradeTypesExport] = useState([])
  const [userTypesEditExport, setUserTypesEditExport] = useState([])
  const [attendenceTypesEditExport, setAttendenceTypesEditExport] = useState([])
  const [gradeTypesEditExport, setGradeTypesEditExport] = useState([])
  const [userTypesExportEditOption, setUserTypesExportEditOption] = useState([])
  const [attendenceTypesExportEditOption, setAttendenceTypesExportEditOption] = useState([])
  const [gradeTypesExportEditOption, setGradeTypesExportEditOption] = useState([])
  const [exportType, setExportType] = useState('excel')
  const [dateRange, setDateRange] = useState('30_days')
  const [startDateExport, setStartDateExport] = useState('')
  const [endDateExport, setEndDateExport] = useState('')






  // ========================= export part ========================================//
  const userTypesHandler = (e, value) => {
    let userTypes = [...userTypesExport]
    if (e.target.checked) {
      userTypes.push(value)
    } else {
      userTypes = userTypes.filter(item => item !== value)
    }
    setUserTypesExport(userTypes)
  }

  const attendanceTypesHandler = (e, value) => {
    let attedenceTypes = [...attendenceTypesExport]
    if (e.target.checked) {
      attedenceTypes.push(value)
    } else {
      attedenceTypes = attedenceTypes.filter(item => item !== value)
    }
    setAttendenceTypesExport(attedenceTypes)
  }

  const gradeTypesHandler = (e, value) => {
    let gradeTypes = [...gradeTypesExport]
    if (e.target.checked) {
      gradeTypes.push(value)
    } else {
      gradeTypes = gradeTypes.filter(item => item !== value)
    }
    setGradeTypesExport(gradeTypes)
  }

  const userTypesEditHandler = (e, value) => {
    let userTypes = [...userTypesEditExport]
    if (e.target.checked) {
      userTypes.push(value)
    } else {
      userTypes = userTypes.filter(item => item !== value)
    }
    setUserTypesEditExport(userTypes)
  }

  const attendanceTypesEditHandler = (e, value) => {
    let attedenceTypes = [...attendenceTypesEditExport]
    if (e.target.checked) {
      attedenceTypes.push(value)
    } else {
      attedenceTypes = attedenceTypes.filter(item => item !== value)
    }
    setAttendenceTypesEditExport(attedenceTypes)
  }

  const gradeTypesEditHandler = (e, value) => {
    let gradeTypes = [...gradeTypesEditExport]
    if (e.target.checked) {
      gradeTypes.push(value)
    } else {
      gradeTypes = gradeTypes.filter(item => item !== value)
    }
    setGradeTypesEditExport(gradeTypes)
  }

  useEffect(() => {
    console.log('from edit export : ', userTypesEditExport, attendenceTypesEditExport, gradeTypesEditExport)
  }, [userTypesEditExport, attendenceTypesEditExport, gradeTypesEditExport])

  const handleExportType = (e) => {
    setExportType(e.target.value.toLowerCase())
  }


  const handleDateRange = (e) => {
    setDateRange(e.target.value)
    // Get the current date
    const currentDate = new Date();

    // Format the date to YYYY-MM-DD
    let formattedEndDate = currentDate.toISOString().split('T')[0];
    let formattedStartDate = '';


    if (e.target.value === "30_days") {
      // Subtract 30 days
      const thirtyDaysAgo = new Date(currentDate);
      thirtyDaysAgo.setDate(currentDate.getDate() - 30);
      formattedStartDate = thirtyDaysAgo.toISOString().split('T')[0]

    } else if (e.target.value === "3_months") {
      // Subtract 3 months
      const threeMonthsAgo = new Date(currentDate);
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
      formattedStartDate = threeMonthsAgo.toISOString().split('T')[0]

    } else if (e.target.value === "6_months") {
      // Subtract 6 months
      const sixMonthsAgo = new Date(currentDate);
      sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
      formattedStartDate = sixMonthsAgo.toISOString().split('T')[0]

    } else if (e.target.value === "12_months") {
      // Subtract 12 months
      const twelveMonthsAgo = new Date(currentDate);
      twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);
      formattedStartDate = twelveMonthsAgo.toISOString().split('T')[0]

    } else {
      // Subtract 5 years
      const fiveYearsAgo = new Date(currentDate);
      fiveYearsAgo.setFullYear(currentDate.getFullYear() - 5);
      formattedStartDate = fiveYearsAgo.toISOString().split('T')[0];
    }

    setStartDateExport(formattedStartDate)
    setEndDateExport(formattedEndDate)

  }
  // ======================== export part ========================================//

  useEffect(() => {
    if (!studentIsLoading && !studentIsError) {
      setStudents(studentData?.body?.data)
    }
  }, [studentData, studentIsLoading, studentIsError])

  // const getMondayAndSunday = () => {
  //   const today = new Date(historyShowDate.$y, historyShowDate.$M, historyShowDate.$D);

  //   const currentDayOfWeek = today.getUTCDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

  //   const monday = new Date(today);
  //   monday.setUTCDate(today.getUTCDate() - currentDayOfWeek + 0); // Subtract days to get to Monday

  //   const nextSunday = new Date(today);
  //   nextSunday.setUTCDate(today.getUTCDate() - currentDayOfWeek + 7); // Add days to get to next Sunday

  //   return {
  //     thisWeekMonday: monday.toISOString(),
  //     nextWeekSunday: nextSunday.toISOString()
  //   };
  // }

  const getMondayAndSunday = () => {
    const today = new Date(historyShowDate.$y, historyShowDate.$M, historyShowDate.$D);

    // Adjust for EST timezone offset (subtract 4 hours for EST)
    today.setHours(today.getHours() - 4);

    const currentDayOfWeek = today.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

    const monday = new Date(today);
    monday.setDate(monday.getDate() - currentDayOfWeek); // Subtract days to get to Monday

    const nextSunday = new Date(monday);
    nextSunday.setDate(monday.getDate() + 6); // Add 6 days to get next Sunday

    return {
      thisWeekMonday: monday.toISOString(),
      nextWeekSunday: nextSunday.toISOString()
    };
  }

  const getAttendanceByDateRangeInitail = async () => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    let allStudentsAttendenceData = await getAttendanceByDateRange({
      "startDate": thisWeekMonday,
      "endDate": nextWeekSunday
      // "startDate": "2023-08-01T14:56:59.301Z",
      // "endDate": "2023-08-28T14:56:59.301Z"
    })

    getAttendanceByDateRanges(allStudentsAttendenceData)
  }

  const getAttendanceByDateRanges = (studentsAttendenceData) => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const start = new Date(thisWeekMonday); // Replace with your desired date
    const end = new Date(nextWeekSunday);
    // Replace with your desired date
    let studentsToShow = []

    studentsAttendenceData?.data?.body?.data?.forEach((studentData) => {
      let attendenceDate = new Date(studentData.date);
      let foundStudent = studentsToShow.find((student) => {
        let singleAttendenceDate = new Date(student?.date);
        return (singleAttendenceDate.toLocaleDateString('en-US') === attendenceDate.toLocaleDateString('en-US') && student.userUniqueID === studentData.userUniqueID)
      })
      if (!foundStudent) studentsToShow.push(studentData)
    })
    console.log(start, 346, end, thisWeekMonday, 346, nextWeekSunday)
    setStartDate(start)
    setEndDate(end)

    let arrangedStudents = studentDataArrange(studentsToShow)

    //=============if the filter is true accordingly filter result  we will get data in allStudentsAttendance otherwise accordingly getDataByDateRange we will get data allStudentsAttendanceOriginal ==========//

    if (filterAttendanceData) {
      setAllStudentsAttendance(arrangedStudents);
    } else {
      setAllStudentsAttendanceOriginal(arrangedStudents);
    }
  }

  const studentDataArrangeNotListed = (studentList) => {
    let strArr = []

    for (let i = 0; i < studentList.length; i++) {
      let obj = {
        _id: studentList[i]._id,
        name: `${studentList[i].firstName} ${studentList[i].lastName}`,
        userUniqueID: studentList[i].userUniqueID,
        weeks_present: []
      }
      strArr.push(obj)
    }

    return strArr
  }
  const studentDataArrange = (attendanceData) => {

    const transformedData = attendanceData.reduce((acc, attendance) => {
      const { _id, userUniqueID, name, date, status } = attendance;

      if (!acc[userUniqueID]) {
        acc[userUniqueID] = {
          _id,
          userUniqueID,
          name,
          weeks_present: []
        };
      }

      acc[userUniqueID].weeks_present.push({ date, status });

      return acc;
    }, {});

    const result = Object.values(transformedData);
    return result;
  }

  useEffect(() => {
    getAttendanceByDateRangeInitail()
  }, [historyShowDate])



  const handleOpenAttendanceExport = () => {
    if (!isTheActionPermitted('exportAttendanceHistory')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setAttendanceExport(true);
  }
  const handleCloseAttendanceExport = () => setAttendanceExport(false);



  useEffect(() => {
    setRows(convertDataToCellRows(allStudentsAttendance));
  }, [allStudentsAttendance])


  // ========================filter drawer start ===========================//
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
  const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const [filterAttendanceData, setfilterAttendanceData] = useState(false);
  const [roles, setRoles] = useState([])
  const [streams, setStream] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [searchTerm, setSearchTerm] = useState('');

  const convertAttendanceCodeResponseToSelectItem = (codes) => {

    if (codes.length > 0) {
      return codes.map((item) => ({ label: item.code, value: item.code }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoadingAttendanceCode && !attendanceCodeError) {
      setAttendanceCodeOptions(
        convertAttendanceCodeResponseToSelectItem(
          attendanceCodeResponse.body.data,
        ),
      );
      setAttendanceCodeOptionsUnfiltered(attendanceCodeResponse.body.data)
    }
  }, [attendanceCodeResponse, isLoadingAttendanceCode, attendanceCodeError]);

  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
    setfilterAttendanceData(true)
  };

  const handleCloseFilterDrawer = async () => {
    setStream([])
    setUserTypes([])
    setOpenFilter(false);
  };

  const handleCloseFilterDrawerButton = () => {
    setfilterAttendanceData(false)
    handleCloseFilterDrawer()
  }

  useEffect(() => {
    if (!filterAttendanceData) {
      setRows(convertDataToCellRows(allStudentsAttendanceOriginal))
    }
  }, [filterAttendanceData, allStudentsAttendanceOriginal]);



  // useEffect(() => {
  //   console.log(streams, userTypes)

  // }, [streams, userTypes])

  //UserTypes are setting as roles
  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      setRoles(roleData?.body?.data);
    }
  }, [roleData, roleIsLoading, roleIsError]);

  console.log(roles, 328)

  const filterHandler = async () => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const result = await filterAttendance({
      term: searchTerm,
      date: {
        "startDate": thisWeekMonday,
        "endDate": nextWeekSunday
        // "startDate": "2023-08-01T14:56:59.301Z",
        // "endDate": "2023-08-28T14:56:59.301Z"
      }, // Pass the array of dates to your server
      userType: userTypes,
      streams: streams
    });

    if (result) {
      getAttendanceByDateRanges(result)
      setfilterAttendanceData(true);
    }
    setOpenFilter(false);
  };
  // ========================filter drawer end ===========================//





  const convertDataToCellRows = (arr) => {

    if (!filterAttendanceData) {
      //this line get the students are not in the list of arr, which came from the attendence table
      let studentLeft = allStudents.filter(itemB => !arr.some(itemA => itemA.userUniqueID == itemB.userUniqueID))

      //this line arrange the students not in the list same structure of arr
      let arrangedStudents = studentDataArrangeNotListed(studentLeft)

      // this line merges them 
      arr = [...arr, ...arrangedStudents];
    }

    // arr.map((item) => console.log(item, 414))


    if (arr.length > 0) {
      // this section shows every students weekly present status 
      return arr.map((item) => {
        const truncatedName = item?.name ? (item.name.length > 40 ? item.name.substring(0, 30) + '...' : item.name) : 'N/A';
        let cell = [
          { text: item?.userUniqueID || 'N/A' },
          {
            linkText: truncatedName,
            linkTextStatus: true,
            link: `/users_u/${item?.userUniqueID}`,
          },

        ]
        for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setUTCDate(currentDate.getUTCDate() + 1)) {
          const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          const dayName = daysOfWeek[currentDate.getUTCDay()];

          const today = new Date()


          if (dayName === 'Sunday' || dayName === 'Saturday' || (today < currentDate)) {
            cell.push({
              textBold: '',
              CellColor: '#FFF',
              TextAlign: 'center',
              textcolor: '#10A711',
            })
          }

          else {
            let found_day = item?.weeks_present?.find((w) => {
              let a = new Date(w.date).toISOString().split('T')[0]
              let b = currentDate.toISOString().split('T')[0]
              return a === b
            })
            if (found_day) {

              let presentStatus = ''
              let presentBackground = ''
              let presentTextColor = ''

              if (found_day.status === 'P') {
                presentStatus = 'P'
                presentBackground = '#E2F4E2'
                presentTextColor = '#10A711'
              } else if (found_day.status === 'A') {
                presentStatus = 'A'
                presentBackground = '#F7E4E4'
                presentTextColor = '#BA1A1A'
              } else if (found_day.status === 'L') {
                presentStatus = 'L'
                presentBackground = '#FFF3E0'
              } else if (found_day.status !== 'P' && found_day.status !== 'L' && found_day.status !== 'A') {
                presentStatus = found_day.status
                presentBackground = '#EDEDEF'
                presentTextColor = '#676C7B'
              }
              // } else if (found_day.status !== 'P' && found_day.status !== 'L' && found_day.status !== 'A') {
              //   presentStatus = found_day.status
              //   presentBackground = '#EDEDEF'
              //   presentTextColor = '#676C7B'
              // }

              cell.push({
                textBold: <span style={{ fontWeight: '700' }}>{presentStatus}</span>,
                CellColor: presentBackground,
                TextAlign: 'center',
                textcolor: presentTextColor,
              })
            } else {
              cell.push({
                textBold: "",
                CellColor: '',
                TextAlign: 'center',
                textcolor: '',
              })
            }

          }

        }

        return {
          cell
        }
      })

    } else {
      return [];
    }
  }


  //==== WEEK DATE CODE //

  // const headCellsWeek = [
  //   {
  //     YearStatus: true,
  //     Year: "Dec 2023",
  //     colSpan: "2",

  //   },
  //   {
  //     Day: "MON",
  //     Date: "11",
  //     DateStatus: true,
  //   },
  //   {
  //     Day: "TUE",
  //     Date: "12",
  //     DateStatus: true,
  //     DayActicve: 'DayActicve',
  //   },
  //   {
  //     Day: "WED",
  //     Date: "16",
  //     DateStatus: true,
  //   },
  //   {
  //     Day: "THU",
  //     Date: "14",
  //     DateStatus: true,
  //   },
  //   {
  //     Day: "FRI",
  //     Date: "15",
  //     DateStatus: true,
  //   },
  //   {
  //     Day: "SAT",
  //     Date: "16",
  //     DateStatus: true,
  //   },
  //   {
  //     Day: "SUN",
  //     Date: "17",
  //     DateStatus: true,
  //   },

  // ];

  //==== WEEK DATE CODE //


  const headCells = [
    {
      id: 'ID',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '10%',
      textAlign: 'left',
      textWeight: 700,

    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '20%',
      textAlign: 'left',
      class: "BorderLine"
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
  ];

  // Get the current date
  const currentDate = new Date();

  const currentMonthIndex = currentDate.getMonth(); // it's index 7 means august
  const currentYear = currentDate.getFullYear(); // 2023
  const currentDateNumber = currentDate.getDate(); // 29


  // this code show the month in this format May 2023
  const date = new Date(historyShowDate.$y, historyShowDate.$M);
  const dateOptions = { year: 'numeric', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', dateOptions);


  let headCellsWeek = [];

  const slideHead = () => {
    let calenderHead = []
    const thisDate = new Date();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    for (let currentDate = new Date(startDate); currentDate <= endDate; currentDate.setUTCDate(currentDate.getUTCDate() + 1)) {
      console.log(startDate, 342, 342, endDate, historyShowDate)
      const dayAsInteger = currentDate.getUTCDate();
      const monthAsInteger = currentDate.getUTCMonth();
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayName = daysOfWeek[currentDate.getUTCDay()];
      const thisDateAsInteger = thisDate.getUTCDate();
      const thisMonthAsInteger = thisDate.getUTCMonth();
      let weekHeader = {
        Day: dayName.slice(0, 3).toUpperCase(),
        Date: dayAsInteger,
        DateStatus: true,
        DayActicve: (dayAsInteger == thisDateAsInteger && monthAsInteger == thisMonthAsInteger) ? 'DayActicve' : '',
      }
      calenderHead.push(weekHeader)
    }
    headCellsWeek = [{
      YearStatus: true,
      Year: `${dayjs(historyShowDate)?.format(
        "MMM"
      )} ${thisDate.getFullYear()} `,
      colSpan: "2",
    }, ...calenderHead]
    return calenderHead
  }

  const dateSliderData = [
    {
      Slides: (<div className="WeekSldier" style={{ padding: 12 }}>{slideHead()}</div>),
    },
  ];

  //======================== export part start =============================================//
  const exportTypes = [
    {
      "type": "csv",
      "file": "data.csv"
    },
    {
      "type": "excel",
      "file": "attendance.xlsx"
    }
  ]

  const handleExportAttendance = async () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const user = localStorage.getItem('user');
    const userToken = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;


    const currentDate = new Date();
    let formattedEndDate = currentDate.toISOString().split('T')[0];
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    let formattedStartDate = thirtyDaysAgo.toISOString().split('T')[0]

    let data = {
      "exportType": exportType,
      "startDate": (startDateExport === "") ? formattedStartDate : startDateExport,
      "endDate": (endDateExport === "") ? formattedEndDate : endDateExport,
      "userTypes": userTypesExport,
      "attendanceTypes": attendenceTypesExport,
      "gradeTypes": gradeTypesExport
    }
    try {

      if (gradeTypesExport?.length === 0 && attendenceTypesExport?.length === 0 && userTypesExport?.length === 0) {
        toast.error("Please select at least one option")
        return false
      }

      const response = await axios.post(baseUrl + '/attendance/exportAttendance', data, {
        // headers: {
        //     'Authorization': `Bearer YOUR_AUTH_TOKEN` // replace with your actual token
        // },
        responseType: 'blob' // Important to set this
      });
      let today = new Date()
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let activeType = exportTypes.find(singleExportType => singleExportType.type === exportType)
      const fileName = `attendance-history-${moment(today)?.format("DDMMYYYY")}.${activeType?.file?.split('.').pop() || 'csv'}`;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
    handleCloseAttendanceExport()
  }

  //======================== export part end =============================================//
  return (
    <>
      <div className="TableSection">

        {getAttendanceByDateRangeOption.isLoading && <div style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: '9999',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '-10%',
          marginTop: '-5%'
        }}>
          <Loader
            height="100px"
            width="100px"
            borderWidth="10px"
            isFullWidth={true}
          />
        </div>}
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <DatePicker
              label="Select Date"
              defaultValue={dayjs(`${currentYear}-${currentMonthIndex + 1}-${currentDateNumber}`)}
              onChange={(newValue) => setHistoryShowDate(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider> */}

        <Table
          title="Attendance History"
          tableHeader={true}
          addLabel="Add entry"
          AddButton={false}
          // DateRange={true}
          DateField={true}
          YearLabel={formattedDate}
          value={historyShowDate}
          handleChange={(date) => setHistoryShowDate(date)}

          //DateRangeOnChange={(date) => setHistoryShowDate(date.toISOString())}
          headCells={headCells}
          rows={rows}
          dateSliderData={dateSliderData}

          //onClick={handleOpenDrawer}
          footer={true}
          //   handleSearchText={handleSearchText}
          //   handleSearch={handleSearch}
          //filter part 
          filterCountStatus={(streams?.length + userTypes?.length) > 0}
          filterCountNumber={(streams?.length + userTypes?.length)}
          FilterButtonDrawer={true}
          onClickFilterButton={handleFilterDrawerOpen}
          goToPreviousDate={(e) => setHistoryShowDate(historyShowDate.subtract(1, 'day'))}
          goToNextDate={(e) => setHistoryShowDate(historyShowDate.add(1, 'day'))}
          ExportButton={true}
          onClickExport={handleOpenAttendanceExport}
          RowsPerPage={20}
          headCellsWeek={headCellsWeek}
        />



      </div>

      {/* Export drawer */}
      <SideDrawer
        open={AttendanceExport}
        handleDrawerClose={handleCloseAttendanceExport}
        title="Export"
        sideOpen="right"
        ButtonLabel="EXPORT"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={() => handleExportAttendance()}
        body={
          <>
            <Export
              userTypesHandler={userTypesHandler}
              attendanceTypesHandler={attendanceTypesHandler}
              gradeTypesHandler={gradeTypesHandler}
              userTypesEditHandler={userTypesEditHandler}
              attendanceTypesEditHandler={attendanceTypesEditHandler}
              gradeTypesEditHandler={gradeTypesEditHandler}
              handleExportType={handleExportType}
              handleDateRange={handleDateRange}
              exportType={exportType}
              dateRange={dateRange}
              userTypesExport={userTypesExport}
              attendenceTypesExport={attendenceTypesExport}
              gradeTypesExport={gradeTypesExport}
              setUserTypesExport={setUserTypesExport}
              setAttendenceTypesExport={setAttendenceTypesExport}
              setGradeTypesExport={setGradeTypesExport}
              userTypesEditExport={userTypesEditExport}
              attendenceTypesEditExport={attendenceTypesEditExport}
              gradeTypesEditExport={gradeTypesEditExport}
              setUserTypesEditExport={setUserTypesEditExport}
              setAttendenceTypesEditExport={setAttendenceTypesEditExport}
              setGradeTypesEditExport={setGradeTypesEditExport}
              userTypesExportEditOption={userTypesExportEditOption}
              setUserTypesExportEditOption={setUserTypesExportEditOption}
              attendenceTypesExportEditOption={attendenceTypesExportEditOption}
              setAttendenceTypesExportEditOption={setAttendenceTypesExportEditOption}
              gradeTypesExportEditOption={gradeTypesExportEditOption}
              setGradeTypesExportEditOption={setGradeTypesExportEditOption} />
          </>
        }
      />

      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCloseFilterDrawerButton}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        BtnColor="#0450e1"
        BtnTextcolor="#fff"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}

        body={
          <>
            <AttendanceFilter
              label="Status"
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              roles={roles}
              attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
              attendanceCodeOptions={attendanceCodeOptions}
            />
          </>
        }
      />
    </>
  );
};

export default AttendanceHistory;
