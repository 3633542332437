import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddSchedule from "./AddSchedule";
import InnerPageLayout from '../../../components/InnerPageLayout/InnerPageLayout';
import Status from "../../../components/Status/Status";
import CircleIcon from '@mui/icons-material/Circle';
import { useAddScheduleMutation, useCheckCalenderScheduleNameMutation, useDeleteScheduleMutation, useEditScheduleMutation, useGetCalenderCategoriesQuery, useGetCalenderDetailsQuery, useSearchScheduleMutation } from "../../../redux/features/calender/calenderApi";
import { useParams } from "react-router-dom";
import useProtected from "../../../hooks/useProtected";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import DraggableDialog from "../../../components/Dialog/Dialog";
import moment from "moment-timezone";
import { sortByCreatedDateDesc } from "../../../hooks/HelperFunctions";



const SchoolCalendar = () => {

  const { id } = useParams();

  const { data, isLoading, isFetching, error, refetch } = useGetCalenderDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: calenderCategoryData,
    isLoading: calenderCategoryIsLoading,
    isFetching: calenderCategoryIsFetching,
    error: calenderCategoryError
  } = useGetCalenderCategoriesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  const {
    data: schoolYearData,
    isFetching: schoolYearIsFetching,
    isLoading: schoolYearIsLoading,
    error: schoolYearError
  } = useGetSchoolYearListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [addSchedule, addScheduleOptions] = useAddScheduleMutation();
  const [editSchedule, editScheduleOptions] = useEditScheduleMutation()
  const [deleteSchedule, deleteScheduleOptions] = useDeleteScheduleMutation()
  const [searchSchedule, searchScheduleOptions] = useSearchScheduleMutation()
  const [checkScheduleName, checkScheduleNameOptions] = useCheckCalenderScheduleNameMutation()
  const [isSaving, setIsSaving] = useState(false);
  useProtected(error)
  useProtected(calenderCategoryError)
  useProtected(addScheduleOptions.error)
  useProtected(editScheduleOptions.error)
  useProtected(deleteScheduleOptions.error)
  useProtected(searchScheduleOptions.error)
  useProtected(checkScheduleNameOptions.error)

  let initialScheduleObject = {
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    category: "",
    id: ''
  }

  let initialScheduleErrorObject = {
    name: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    category: ""
  }

  const [rows, setRows] = useState([])
  const [schedules, setSchedules] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [deleteName, setDeleteName] = useState('')
  const [schoolYears, setSchoolYears] = useState([])
  const [calenderDetails, setCalenderDetails] = useState({})
  const [resetsearchTerm, setResetSearchTerm] = useState(false)
  const [openAddCalendar, setOpenAddCalendar] = useState(false)
  const [editScheduleMode, setEditScheduleMode] = useState(false)
  const [calenderCategories, setCalenderCategories] = useState([])
  const [categoryStatusData, setCategoryStatusData] = useState([])
  const [scheduleObject, setScheduleObject] = useState(initialScheduleObject)
  const [scheduleErrorObject, setScheduleErrorObject] = useState(initialScheduleErrorObject)

  const handleDrawerOpenAddCalendar = () => {
    setOpenAddCalendar(true);
  }

  const [openAddSchedule, setOpenAddSchedule] = useState(false);
  const handleDrawerOpenAddSchedule = () => {
    setOpenAddSchedule(true);
  };
  const handleDrawerCloseAddSchedule = () => {
    setIsSaving(false)
    setOpenAddSchedule(false);
    setEditScheduleMode(false)
  };


  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCalenderDetails(data?.body?.data)
    }
  }, [data, isLoading, isFetching])
  useEffect(() => {
    console.log(editScheduleMode, 342)
  }, [editScheduleMode])

  useEffect(() => {
    if (!calenderCategoryIsLoading && !calenderCategoryIsFetching) {
      setCalenderCategories(calenderCategoryData?.body?.data)
    }
  }, [calenderCategoryData, calenderCategoryIsLoading, calenderCategoryIsFetching])

  useEffect(() => {
    setCategoryStatusData(createCategoryStatusData(calenderCategories))
  }, [calenderCategories])

  useEffect(() => {
    if (categoryStatusData?.length > 0 || calenderDetails?.schedules?.length > 0) {
      setSchedules(calenderDetails?.schedules)
      setRows(convertDataToCellRows(calenderDetails?.schedules))
    }
  }, [calenderDetails, categoryStatusData])


  useEffect(() => {
    if (categoryStatusData?.length > 0 || data?.body?.data?.schedules?.length > 0) {
      setSchedules(data?.body?.data?.schedules);
    }
  }, [data, isLoading, isFetching, categoryStatusData]);



  useEffect(() => {
    if (!schoolYearIsFetching && !schoolYearIsLoading) {
      setSchoolYears(schoolYearData?.body?.data)
    }
  }, [schoolYearData, schoolYearIsFetching, schoolYearIsLoading])

  const createCategoryStatusData = (calenderDetails) => {
    let categoryStatusData = []
    calenderDetails?.map((singleCategory) => {
      categoryStatusData.push({
        value: singleCategory?._id,
        statuscolor: singleCategory?.color,
        select: singleCategory?.name
      })
    })
    return categoryStatusData
  }

  const formattedDate = (val) => {
    const date = new Date(val);
    const options = { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'UTC' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  }

  const deleteScheduleClickHandler = (val) => {
    setDeleteName(val)
    handleOpenDelete()
  }


  const editScheduleClickHandler = async (val) => {
    setEditScheduleMode(true)
    // console.log('foundSchedule', schedules, 345, updatedSchedules)
    let foundSchedule = schedules?.find(schedule => schedule.name == val)
    if (foundSchedule) {
      setScheduleObject({
        name: foundSchedule?.name,
        startDate: new dayjs(foundSchedule?.startDate),
        endDate: new dayjs(foundSchedule?.endDate),
        startTime: new dayjs(foundSchedule?.startTime),
        endTime: new dayjs(foundSchedule?.endTime),
        category: foundSchedule?.category,
        id: foundSchedule?.id
      })
      handleDrawerOpenAddSchedule()
    }


  }
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => {
        let startDate = formattedDate(item?.startDate)
        let endDate = formattedDate(item?.endDate)

        let foundCat = categoryStatusData?.find((categoryStatus) => categoryStatus?.value == item?.category)
        console.log(item, foundCat, 'foundCat', categoryStatusData)
        return {
          cell: [
            { text: item?.name || "N/A", },
            { text: <div className="TableTextWithIcon"><div style={{ color: (foundCat) ? foundCat?.statuscolor : "N/A", display: "flex", }}><CircleIcon /></div>{(foundCat) ? foundCat?.select : "N/A"}</div>, },
            { text: startDate || "N/A" },
            { text: endDate || "N/A" },
            { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
          ],

          //======= Action Date Start===== //
          action: [
            { label: 'Edit', onClick: (e) => editScheduleClickHandler(item?.name) },
            { label: 'Delete', onClick: (e) => deleteScheduleClickHandler(item?.name) },
          ]
        }
      });
    } else {
      return [];
    }

  };




  const onClick = () => {
    // alert('onclick working fine!');
  };

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '60%', textAlign: 'left', },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Category', width: '25%', textAlign: 'left', },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Start', width: '10%', textAlign: 'left', },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'End', width: '10%', },
    { id: 'carbs', numeric: true, disablePadding: false, label: '', width: '10%', },
  ];


  // const rows = [
  //   {
  //     cell: [
  //       { text: 'Weekend', },
  //       { text: <div className="TableTextWithIcon"><div style={{ color: "#D50000", display: "flex", }}><CircleIcon /></div> Holiday/Recess (No school)</div>, },
  //       { text: '1-Sep-2024', },
  //       { text: '26-Jun-2024', },
  //       { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: 'View', onClick: onClick },
  //       { label: 'Edit', onClick: onClick },
  //       { label: 'Delete', onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       { text: 'Weekend', },
  //       { text: <div className="TableTextWithIcon"><div style={{ color: "#F6BF26", display: "flex", }}><CircleIcon /></div> First/Last Day of School</div>, },
  //       { text: '1-Sep-2024', },
  //       { text: '26-Jun-2024', },
  //       { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: 'View', onClick: onClick },
  //       { label: 'Edit', onClick: onClick },
  //       { label: 'Delete', onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       { text: 'Weekend', },
  //       { text: <div className="TableTextWithIcon"><div style={{ color: "#F4511E", display: "flex", }}><CircleIcon /></div> End of Term</div>, },
  //       { text: '1-Sep-2024', },
  //       { text: '26-Jun-2024', },
  //       { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: 'View', onClick: onClick },
  //       { label: 'Edit', onClick: onClick },
  //       { label: 'Delete', onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },
  // ];




  const breadcrumbsData = [
    {
      label: "Calendars",
      link: "/calendars",
      icon: <i class="ri-presentation-fill"></i>,
      //handleClick={handleClick}
    },

    {
      label: calenderDetails?.name,
    },

  ];

  const menuOptions = [
    { label: 'label 1' },
    { label: 'label 2' },
    { label: 'label 3' },
    { label: 'label 4' },

  ];


  const staticStatustData = [
    {
      Label: "Academic year",
      SubLabel: schoolYears?.find((year) => year?._id == calenderDetails?.academicYearId)?.name,
      Color: "#7D8597",
      line: false,
      StatusWidth: "320px",
      StatusStaticMaxWidth: "initial",
    }
  ];


  // const checkNameExist = async () => {
  //   if (scheduleObject.name == "") {
  //     return false
  //   }
  //   let searchResult = await checkScheduleName({ name: scheduleObject.name, id });
  //   if (searchResult?.data && searchResult.data?.body?.data && searchResult.data?.body?.data?.length > 0) {
  //     return true
  //   }
  //   return false
  // }
  const checkNameExist = async () => {
    if (scheduleObject.name === "") {
      return false;
    }
    const searchResult = await checkScheduleName({ name: scheduleObject.name, id });
    if (searchResult?.data?.body?.data?.length > 0) {

      if (editScheduleMode) {
        const currentSchedule = schedules.find(schedule => schedule.id === scheduleObject.id);
        if (currentSchedule?.name === scheduleObject.name) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  useEffect(() => {
    console.log("scheduleErrorObject : ", scheduleErrorObject)
  }, [scheduleErrorObject])

  const dateFormatted = (date) => {
    let data = new Date(date.$y, date.$M, date.$D)
    return isNaN(data) ? "Invalid Date" : data
  }

  const isScheduleValid = async () => {
    let error = 0
    let errorObject = { ...scheduleErrorObject }



    if (scheduleObject?.name == "") {
      error++
      errorObject = { ...errorObject, name: "Required" }
    } else {
      // const nameExist = !editScheduleMode ? await checkNameExist() : false
      const nameExist = await checkNameExist();
      if (nameExist) {
        error++
        errorObject = { ...errorObject, name: "The schedule name is already in use. Choose a different name." }
      } else {
        errorObject = { ...errorObject, name: "" }
      }
    }

    const startDate = dateFormatted(scheduleObject?.startDate);
    const endDate = dateFormatted(scheduleObject?.endDate);

    if (scheduleObject?.startDate == "" || startDate === "Invalid Date") {
      error++
      errorObject = { ...errorObject, startDate: "Required" }
    } else {
      if (scheduleObject?.startDate > scheduleObject?.endDate) {
        error++
        errorObject = { ...errorObject, startDate: `The "Start Date" must be earlier than the "End Date"` }
      } else {
        errorObject = { ...errorObject, startDate: "" }
      }
    }

    if (scheduleObject?.endDate == "" || endDate === "Invalid Date") {
      error++
      errorObject = { ...errorObject, endDate: "Required" }
    } else {
      if (scheduleObject?.startDate > scheduleObject?.endDate) {
        error++
        errorObject = { ...errorObject, endDate: `The "End Date" should be later than the "Start Date"` }
      } else {
        errorObject = { ...errorObject, endDate: "" }
      }
    }

    if (scheduleObject?.startTime == "" || scheduleObject?.startTime === null) {
      error++
      errorObject = { ...errorObject, startTime: "Required" }
    } else {
      if (scheduleObject?.startTime > scheduleObject?.endTime) {
        error++
        errorObject = { ...errorObject, startTime: `The "Start Time" must be earlier than the "End Time"` }
      } else {
        errorObject = { ...errorObject, startTime: "" }
      }
    }

    if (scheduleObject?.endTime == "" || scheduleObject?.endTime === null) {
      error++
      errorObject = { ...errorObject, endTime: "Required" }
    } else {
      if (scheduleObject?.startTime > scheduleObject?.endTime) {
        error++
        errorObject = { ...errorObject, endTime: `The "End Time" should be later than the "Start Time"` }
      } else {
        errorObject = { ...errorObject, endTime: "" }
      }
    }

    if (scheduleObject?.category == "") {
      error++
      errorObject = { ...errorObject, category: "Required" }
    } else {
      errorObject = { ...errorObject, category: "" }
    }

    setScheduleErrorObject({ ...scheduleErrorObject, ...errorObject })

    return error > 0 ? false : true
  }

  const addScheduleHandler = async () => {
    if (isSaving) return
    let data = { ...scheduleObject }

    setIsSaving(true)
    let isValid = await isScheduleValid()

    if (!isValid) {
      setIsSaving(false)
      return false
    }

    let result = !editScheduleMode ? await addSchedule({ ...data, id: id }) : await editSchedule({ dataId: id, ...data })
    if (result?.data && result?.data?.error == false) {
      toast.success(!editScheduleMode ? 'Schedule added' : 'Schedule updated')
      refetch(id)
      setScheduleObject(initialScheduleObject)
      handleDrawerCloseAddSchedule()
      setEditScheduleMode(false)
      setScheduleErrorObject(initialScheduleErrorObject)
    }
  }


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };

  //======= Delete dialog ===== //


  const closeDeleteDialogHandler = () => {
    setDeleteName('')
    handleCloseDeleteDialog()
  }

  const deleteCalenderConfirmHandler = async () => {

    let data = {
      name: deleteName
    }
    let result = await deleteSchedule({ ...data, id: id })
    if (result?.data) {
      toast.success('Schedule removed');
      await refetch(id)
    } else {
      toast.error(result?.data?.message);
    }
    handleCloseDeleteDialog()
    setDeleteName('')
  }

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await searchSchedule({ name: searchTerm, id });
    console.log(result?.data, 'data')
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])


  const handleResetSearch = async () => {
    const result = await searchSchedule({ name: "", id });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  const addScheduleDrawerCloserHandler = () => {
    setScheduleObject(initialScheduleObject)
    setScheduleErrorObject(initialScheduleErrorObject)
    handleDrawerCloseAddSchedule()
  }

  return (
    <>

      <InnerPageLayout
        BackArrow={true}
        BackArrowlink="/calendars"
        //BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}

        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}

        ButtonOutLinedStatus={true}
        ButtonOutLinedLabel="Export"
        //ButtonOutLinedIcon={}
        //ButtonOutLinedLink=""
        //ButtonOutLinedOnClick={}

        BodyStatus={true}
        Body={<div>

          <Status
            MainTitle={true}
            BackLinkLabel="Calendars"
            Backlink="/calendars"
            Title={calenderDetails?.name}
            StaticStatus={true}
            staticStatustData={staticStatustData}

          />

          <div className="TableSection">
            <Table

              innerHeader={true}
              innerTitle="Schedules"
              AddButtonInner={true}
              addLabelInner="Add schedule"
              InnerButtonColor="#004FE0"
              InnerButtonTextColor="#fff"

              headCells={headCells}
              rows={rows}
              Checkbox={true}
              footer={true}
              showSearchBox={true}
              RowsPerPage={10}
              onClickInnerButton={handleDrawerOpenAddSchedule}

              emptyTableTitle="No schedules added yet"
              emptyTableNote="Add schedules to this calendar"
              showButton={true}
              btnLabel="Add schedule"
              onClickEmptyCard={handleDrawerOpenAddSchedule}

              handleSearchText={handleSearchText}
              handleSearch={handleSearch}
              setResetSearchTerm={setResetSearchTerm}
              resetsearchTerm={resetsearchTerm}

            />
          </div>

        </div>}

      />





      {/* AddCalendars Drawer */}
      <SideDrawer
        open={openAddSchedule}
        handleDrawerClose={(e) => addScheduleDrawerCloserHandler()}
        title={(editScheduleMode) ? "Edit Schedule" : "Add Schedule"}
        ButtonLabel={(editScheduleMode) ? "Update now" : "Add now"}
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => addScheduleHandler()}
        body={
          <>
            <AddSchedule
              scheduleObject={scheduleObject}
              setScheduleObject={setScheduleObject}
              calenderCategories={calenderCategories}
              categoryStatusData={categoryStatusData}
              scheduleErrorObject={scheduleErrorObject}
              setScheduleErrorObject={setScheduleErrorObject}
              editScheduleMode={editScheduleMode} />
          </>
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={(e) => closeDeleteDialogHandler()}
        handleConfirmCloseDialog={(e) => deleteCalenderConfirmHandler()}
        title="Remove schedule from calender?"
        body={<div>Are you sure you want to remove this schedule from the calender?</div>}
        ModalFooter={true}
        actionButton="REMOVE"
      />



    </>
  );
};

export default SchoolCalendar;
