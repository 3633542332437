import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import "./AttendanceTable.css";



import AttendanceTableCell from './AttendanceTableCell';
import { useEffect } from 'react';
import { useState } from 'react';
import { monthArray } from '../../utils/helpers';
import { useAddAttendanceToSectionOfCourseMutation, useGetAttendanceOfSectionOfCourseMutation, useUpdateAttendanceToSectionOfCourseMutation, useUpdateMultipleAttendanceToSectionOfCourseMutation } from '../../redux/features/classes/classApi';
import useProtected from '../../hooks/useProtected';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';




// const rows = [
//     {
//         name: "Mohammad Nure Tabassum Parsa",
//         id: "000001",

//         cell: [

//             {
//                 Status: "P",
//                 Color: "#41AB37",
//             },
//             {
//                 Status: "L",
//                 Color: "#FFB606",
//             },
//             {
//                 Status: "A",
//                 Color: "#BA1A1A",
//             },
//             {
//                 Status: "WKD",
//                 Color: "#595E72",
//             },
//             {
//                 Status: "P",
//                 Color: "#41AB37",
//             },
//             {
//                 Status: "",
//                 Color: "",
//                 disabled: "disabled",
//             },
//             {
//                 Status: "",
//                 Color: "",
//                 disabled: "disabled",
//             },


//         ]

//     },

//     {

//         name: "Abdullah al-Razi",
//         id: "000001",

//         cell: [

//             {
//                 Status: "P",
//                 Color: "#41AB37",
//             },
//             {
//                 Status: "L",
//                 Color: "#FFB606",
//             },
//             {
//                 Status: "A",
//                 Color: "#BA1A1A",
//             },
//             {
//                 Status: "WKD",
//                 Color: "#595E72",
//             },
//             {
//                 Status: "P",
//                 Color: "#41AB37",
//             },
//             {
//                 Status: "",
//                 Color: "",
//                 disabled: "disabled",
//             },
//             {
//                 Status: "",
//                 Color: "",
//                 disabled: "disabled",
//             },


//         ]

//     },


// ];






function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, selectedMonthYear, updateAttendanceMultiple, setMultipleAttendanceSelectDay } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setStatusForSameColumn = (status) => {

        updateAttendanceMultiple(status)
        handleClose()
    }

    const clickMenuHandler = (event, day) => {
        setMultipleAttendanceSelectDay(day)
        handleClickMenu(event)
    }

    return (
        <TableHead className='Attendance-table-head'>
            <TableRow>

                <TableCell className='Attendance-table-date'>
                    <Typography variant='h3'>{monthArray[selectedMonthYear.getMonth()].slice(0, 3)} {selectedMonthYear.getFullYear()}</Typography>
                </TableCell>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='center'
                        padding='normal'
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ border: '1px solid #ebe9ec', fontSize: '16px', position: "relative" }}
                    >
                        {headCell.disabled &&
                            <div className='cellDisabled'></div>
                        }
                        <span className='At_date_label'>{headCell.label}</span>
                        <div className='At_Date'>
                            <Typography variant='h4' className={headCell.ActiveDate} style={{ width: "40px", height: "40px", }}>{headCell.date}</Typography>
                        </div>
                    </TableCell>
                ))}
            </TableRow>



            <TableRow sx={{ background: '#edf1f6', color: '#5f5e62' }}>
                <TableCell padding="none">
                    <div className='Attendance-table-selected-section'>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                            className='Attendance-table-checkbox'
                        />
                        <span className='Attendance-table-checkbox-caption'>{numSelected} selected</span>
                    </div>
                </TableCell>

                {headCells.map((headCell, i) => (
                    <TableCell key={i} >
                        {numSelected > 0 &&
                            <div className='Attendance-select-container' style={{ display: headCell?.disabled ? 'none' : 'block' }}>
                                <Button
                                    id="basic-button"
                                    variant='text'
                                    startIcon={<AddCircleOutlineRoundedIcon />}
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e) => clickMenuHandler(e, headCell.day)}
                                >
                                    select
                                </Button>
                                <div className='SelectFeildMenuContainer'>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={(e) => setStatusForSameColumn('P', headCell.day)}>
                                            <div className='AttendanceMenuItem'>
                                                <div
                                                    className='AttendanceColorBox'
                                                    style={{ background: '#41AB37' }}
                                                ></div>
                                                <p><span>P</span> - <span>Present</span></p>
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => setStatusForSameColumn('L', headCell.day)}>
                                            <div className='AttendanceMenuItem'>
                                                <div
                                                    className='AttendanceColorBox'
                                                    style={{ background: '#FFB606' }}
                                                ></div>
                                                <p><span>L</span> - <span>Late</span></p>
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => setStatusForSameColumn('A', headCell.day)}>
                                            <div className='AttendanceMenuItem'>
                                                <div
                                                    className='AttendanceColorBox'
                                                    style={{ background: '#BA1A1A' }}
                                                ></div>
                                                <p><span>A</span> - <span>Absent</span></p>
                                            </div>
                                        </MenuItem>
                                        <MenuItem onClick={(e) => setStatusForSameColumn('WKD', headCell.day)}>
                                            <div className='AttendanceMenuItem'>
                                                <div
                                                    className='AttendanceColorBox'
                                                    style={{ background: '#595E72' }}
                                                ></div>
                                                <p><span>WKD</span> - <span>Weekend</span></p>
                                            </div>
                                        </MenuItem>

                                    </Menu>
                                </div>
                            </div>
                        }

                    </TableCell>
                ))}
            </TableRow>

        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};





export default function EnhancedTable(props) {

    const {
        selectedDate,
        setSelectedDate,
        section_id,
        course_id,
        courseDetails,
        setWeekStartDate,
        setWeekEndDate,
        filterAtteandanceDatas,
        filterAttendanceStatus
    } = props

    const [getAttendanceOfSection, getAttendanceOfSectionOptions] = useGetAttendanceOfSectionOfCourseMutation()
    const [addAttendanceToSection, addAttendanceToSectionOptions] = useAddAttendanceToSectionOfCourseMutation()
    const [updateAttendanceOfSection, updateAttendanceOfSectionOptions] = useUpdateAttendanceToSectionOfCourseMutation()
    const [updateMultipleAttendanceOfSection, updateMultipleAttendanceOfSectionOptions] = useUpdateMultipleAttendanceToSectionOfCourseMutation()

    useProtected(getAttendanceOfSectionOptions.error)
    useProtected(addAttendanceToSectionOptions.error)
    useProtected(updateAttendanceOfSectionOptions.error)
    useProtected(updateMultipleAttendanceOfSectionOptions.error)

    const { schoolId } = useSelector(state => state.auth.user || {})

    const RowsPerPage = props.RowsPerPage ? props.RowsPerPage : 5;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(RowsPerPage);
    const [headCells, setHeadCells] = useState([])
    const [selectedMonthYear, setSelectedMonthYear] = useState(new Date())
    const [sectionInfo, setSectionInfo] = useState({})
    const [attendanceData, setAttendancedData] = useState([])
    const [fullWeekDates, setFullWeekDates] = useState({})
    const [rows, setRows] = useState([])
    const [visibleRows2, setVisibleRows2] = useState([])
    const [attendanceDateToInsert, setAttendanceDateToInsert] = useState('')
    const [studentIdForAttendance, setStudentIdForAttendance] = useState('')
    const [attendanceIdToUpdate, setAttendanceIdToUpdate] = useState('')
    const [attendanceSelectedIdList, setAttendanceSelectedIdList] = useState([])
    const [multipleAttendanceSelectDay, setMultipleAttendanceSelectDay] = useState(new Date())




    useEffect(() => {
        let dateObj = new Date()
        if (selectedDate != "") {
            dateObj = new Date(selectedDate)
        }
        let currentWeekMonday = getCurrentWeekMonday(dateObj)
        let fullWeekInfo = getNextSevenDays(currentWeekMonday)

        setHeadCells(setFullStructureForHead(fullWeekInfo))

        setSelectedMonthYear(dateObj)
        setWeekStartDate(fullWeekInfo[0])
        setWeekEndDate(fullWeekInfo[6])
        getSectionAttendanceHandler(fullWeekInfo[0], fullWeekInfo[6])
        setFullWeekDates(fullWeekInfo)

    }, [selectedDate])

    useEffect(() => {
        setSectionInfo(courseDetails?.sections?.find(section => section.id === section_id))
    }, [courseDetails])

    useEffect(() => {

    }, [sectionInfo])

    const getSectionAttendanceHandler = async (startDate, endDate) => {
        let data = {
            "sectionId": section_id,
            "startDate": startDate,
            "endDate": endDate
        }
        let result = await getAttendanceOfSection({ ...data })
        setAttendancedData(result?.data?.body?.data)
    }

    // useEffect(() => {
    //     console.log(345, filterAttendanceStatus)
    // }, [filterAttendanceStatus])

    const findAttendanceOfAnUser = (singleStudent, singleDate) => {
        let data = filterAttendanceStatus ? filterAtteandanceDatas : attendanceData

        return data?.find(singleAttendance => {

            let singleAttendance_date = new Date(singleAttendance.date)

            // Check if day, month, and year match
            const isDayMatch = singleAttendance_date.getDate() === singleDate.getDate();
            const isMonthMatch = singleAttendance_date.getMonth() === singleDate.getMonth();
            const isYearMatch = singleAttendance_date.getFullYear() === singleDate.getFullYear();


            // Return true if all components match
            return isDayMatch && isMonthMatch && isYearMatch && (singleAttendance?.studentId == singleStudent?.id);

        })
    }
    //=============================== this code based est time ==========================//
    useEffect(() => {
        const desiredDateFormat = 'YYYY-MM-DD' // Only include date formatting


        let StudentsAttendanceInfoArray = [];

        let filterStudents = filterAtteandanceDatas?.reduce((acc, student) => {
            const filtered = sectionInfo?.students?.filter((studentData) => studentData?.id === student?.studentId);
            return acc.concat(filtered);
        }, [])
        let students = filterAttendanceStatus ? filterStudents : sectionInfo?.students
        students?.map(singleStudent => {
            let newStudentAttendanceInfo = {
                name: singleStudent?.name || "N/A",
                id: singleStudent?.id || "N/A",
                userUniqueId: singleStudent?.userUniqueId || "N/A"
            };
            let attendanceStatusesInAWeek = [];

            fullWeekDates?.map(singleDate => {
                const found = findAttendanceOfAnUser(singleStudent, singleDate);

                if (found) {
                    let cellInfo = {
                        Status: "",
                        Color: "",
                    };

                    if (found?.attendance === 'P') {
                        cellInfo = {
                            Status: "P",
                            Color: "#41AB37",
                            attendanceId: found?._id
                        };
                    } else if (found?.attendance === 'L') {
                        cellInfo = {
                            Status: "L",
                            Color: "#FFB606",
                            attendanceId: found?._id
                        };
                    } else if (found?.attendance === 'A') {
                        cellInfo = {
                            Status: "A",
                            Color: "#BA1A1A",
                            attendanceId: found?._id
                        };
                    } else if (found?.attendance === 'WKD') {
                        cellInfo = {
                            Status: "WKD",
                            Color: "#595E72",
                            attendanceId: found?._id
                        };
                    }

                    attendanceStatusesInAWeek.push(cellInfo);
                } else {
                    // Convert singleDate to EST timezone with date format
                    const singleDateNewYork = moment(singleDate).format(desiredDateFormat);


                    // Get today's date in EST with desired format (MM/DD/YYYY)
                    const today = moment().format(desiredDateFormat);
                    // console.log(singleDateNewYork, 345, today);
                    // Disable cell if singleDate is after today (EST)
                    const isDisabled = singleDateNewYork > today;

                    attendanceStatusesInAWeek.push({
                        Status: "",
                        Color: "",
                        disabled: isDisabled ? "disabled" : "",
                    });


                }
            });

            newStudentAttendanceInfo.cell = attendanceStatusesInAWeek;
            StudentsAttendanceInfoArray.push(newStudentAttendanceInfo);
        });

        setRows(StudentsAttendanceInfoArray);
    }, [attendanceData, filterAtteandanceDatas, filterAttendanceStatus]);
    //=============================== this code based est time ==========================//

    // useEffect(() => {
    //     let currentDateObject = new Date()
    //     let StudentsAttendanceInfoArray = []
    //     sectionInfo?.students?.map(singleStudent => {
    //         let newStudentAttendanceInfo = {
    //             name: singleStudent?.name || "N/A",
    //             id: singleStudent?.id || "N/A",
    //             userUniqueId: singleStudent?.userUniqueId || "N/A"
    //         }
    //         let attendanceStatusesInAWeek = []

    //         fullWeekDates?.map(singleDate => {

    //             let found = findAttendanceOfAnUser(singleStudent, singleDate)

    //             if (found) {
    //                 let cellInfo = {
    //                     Status: "",
    //                     Color: "",
    //                 }
    //                 if (found?.attendance == 'P') {
    //                     cellInfo = {
    //                         Status: "P",
    //                         Color: "#41AB37",
    //                         attendanceId: found?._id
    //                     }
    //                 } else if (found?.attendance == 'L') {
    //                     cellInfo = {
    //                         Status: "L",
    //                         Color: "#FFB606",
    //                         attendanceId: found?._id
    //                     }
    //                 } else if (found?.attendance == 'A') {
    //                     cellInfo = {
    //                         Status: "A",
    //                         Color: "#BA1A1A",
    //                         attendanceId: found?._id
    //                     }
    //                 } else if (found?.attendance == 'WKD') {
    //                     cellInfo = {
    //                         Status: "WKD",
    //                         Color: "#595E72",
    //                         attendanceId: found?._id
    //                     }
    //                 }

    //                 attendanceStatusesInAWeek.push(cellInfo)
    //             } else {
    //                 const currentDateObjectDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
    //                 // const currentDateObjectDate = new Date(currentDateObject.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    //                 const singleDateNewYork = new Date(singleDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
    //                 if (currentDateObjectDate < singleDateNewYork) {
    //                     // if (currentDateObjectDate < singleDateNewYork) {
    //                     // console.log(currentDateObjectDate, 345, singleDateNewYork)
    //                     attendanceStatusesInAWeek.push({
    //                         Status: "",
    //                         Color: "",
    //                         disabled: "disabled",
    //                     })
    //                 } else {
    //                     attendanceStatusesInAWeek.push({
    //                         Status: "",
    //                         Color: "",
    //                     })
    //                 }
    //             }



    //         })
    //         newStudentAttendanceInfo.cell = attendanceStatusesInAWeek
    //         StudentsAttendanceInfoArray.push(newStudentAttendanceInfo)

    //     })
    //     setRows(StudentsAttendanceInfoArray)
    // }, [attendanceData])

    const updateAttendanceSingle = async (status) => {

        let data = {
            studentIds: [studentIdForAttendance],
            courseId: course_id,
            date: attendanceDateToInsert,
            attendance: status,
            schoolId: schoolId,
            sectionId: section_id
        }

        let singleStudent = sectionInfo?.students?.find(student => student.id == studentIdForAttendance)

        let found = findAttendanceOfAnUser(singleStudent, attendanceDateToInsert)
        let result = null
        if (found && attendanceIdToUpdate != "") {
            data.id = attendanceIdToUpdate
            result = await updateAttendanceOfSection({ ...data })

        } else {

            result = await addAttendanceToSection({ ...data })
        }

        if (result?.data?.body) {
            let data = {
                "sectionId": section_id,
                "startDate": fullWeekDates[0],
                "endDate": fullWeekDates[6]
            }
            let result2 = await getAttendanceOfSection({ ...data })
            setAttendancedData(result2?.data?.body?.data)

        }

    }

    const updateAttendanceMultiple = async (status) => {
        let listToUpdateAttendanceIds = []
        let listToAddStudent = []

        attendanceSelectedIdList?.map((singleStudentId) => {
            let singleStudent = sectionInfo?.students?.find(student => student.id == singleStudentId)
            let found = findAttendanceOfAnUser(singleStudent, multipleAttendanceSelectDay)
            if (found) {
                listToUpdateAttendanceIds.push(found._id)
            } else {
                listToAddStudent.push(singleStudentId)
            }
        })

        let dataToAdd = {
            studentIds: listToAddStudent,
            courseId: course_id,
            date: multipleAttendanceSelectDay,
            attendance: status,
            schoolId: schoolId,
            sectionId: section_id
        }

        let dataToUpdate = {
            attendanceIds: listToUpdateAttendanceIds,
            attendance: status
        }



        let result = await addAttendanceToSection({ ...dataToAdd })

        let result2 = await updateMultipleAttendanceOfSection({ ...dataToUpdate })


        let data = {
            "sectionId": section_id,
            "startDate": fullWeekDates[0],
            "endDate": fullWeekDates[6]
        }
        let result3 = await getAttendanceOfSection({ ...data })
        setAttendancedData(result3?.data?.body?.data)


    }

    //=============================== this code based est time ==========================//
    const setFullStructureForHead = (fullWeekInfo) => {
        const desiredFormat = 'YYYY-MM-DD'; // Year, Month, Day format

        const currentDate = moment().format(desiredFormat);

        let structuredTableHeadData = fullWeekInfo?.map(day => {
            const dayInEST = moment(day).format(desiredFormat);
            // console.log(currentDate, 345, dayInEST); // Log only date part

            const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            return {
                "id": dayNames[day.getDay()],
                "numeric": true,
                "label": dayNames[day.getDay()].toLowerCase(),
                "date": day.getDate(), // Use directly for date
                "ActiveDate": (currentDate === dayInEST) ? "ActiveDate" : "", // Compare formatted dates
                "disabled": moment(currentDate).isBefore(dayInEST), // Moment.js comparison
                "day": day
            }
        });
        return structuredTableHeadData;
    }
    //=============================== this code based est time ==========================//

    // const setFullStructureForHead = (fullWeekInfo) => {
    //     let currentDateObject = new Date()

    //     let structuredTableHeadData = fullWeekInfo?.map(day => {
    //         console.log(currentDateObject, 345, day)
    //         const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //         return {
    //             "id": dayNames[day.getDay()],
    //             "numeric": true,
    //             "label": dayNames[day.getDay()].toLowerCase(),
    //             "date": day.getDate(),
    //             "ActiveDate": (day == currentDateObject) ? "ActiveDate" : "",
    //             "disabled": (currentDateObject < day) ? true : false,
    //             "day": day
    //         }
    //     })
    //     return structuredTableHeadData
    // }
    const getNextSevenDays = (startDate) => {
        if (!(startDate instanceof Date) || isNaN(startDate)) {
            throw new Error('Invalid date');
        }

        const nextSevenDays = [];
        const currentDate = new Date(startDate);

        for (let i = 0; i < 7; i++) {
            nextSevenDays.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return nextSevenDays;
    }


    const getCurrentWeekMonday = (inputDate) => {
        if (!(inputDate instanceof Date) || isNaN(inputDate)) {
            throw new Error('Invalid date');
        }

        const currentDate = new Date(inputDate);

        // Calculate the start of the week (Monday)
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

        return currentDate;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        setAttendanceSelectedIdList(selected)
    }, [selected])
    const handleClick = (event, id) => {
        let newIds = []
        if (event.target.checked) {
            let found = attendanceSelectedIdList?.find(singleId => singleId == id)
            newIds = [...attendanceSelectedIdList]
            if (!found) {
                newIds.push(id)
            }
        } else {
            newIds = attendanceSelectedIdList?.filter(singleId => singleId != id)
        }
        setAttendanceSelectedIdList(newIds)

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    useEffect(() => {
        setVisibleRows2(() =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ), [order, orderBy, page, rowsPerPage])
    }, [rows, rowsPerPage])


    useEffect(() => {
    }, [selectedMonthYear])


    //===== Menu Modal ===//
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState('');
    const open = Boolean(anchorEl);

    const handleClickMenu = (event, selectedValue) => {

        setSelectedValue(selectedValue);
        setAnchorEl(event.currentTarget);
        // setAnchorEl(event.target.value)
        console.log("button event", event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (

        <div className='Attendance-table-container'>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                        selectedMonthYear={selectedMonthYear}
                        updateAttendanceMultiple={updateAttendanceMultiple}
                        setMultipleAttendanceSelectDay={setMultipleAttendanceSelectDay}
                    />
                    <TableBody className='Attendance-table-body'>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                // {visibleRows2.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        //onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >


                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            sx={{ border: '1px solid #ebe9ec', cursor: 'pointer' }}
                                            onClick={(event) => handleClick(event, row.id)}
                                            className='Attendance-table-name-cell'
                                        >
                                            <div className='Attendance-table-name-container'>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    className='Attendance-table-checkbox'
                                                />
                                                <div className='Attendance-table-name-d'>
                                                    <Avatar className='Attendance-table-avatar' />
                                                    <div className='Attendance-table-name'>
                                                        <h1>{row.name}</h1>
                                                        <p>{row.userUniqueId}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>


                                        {/* == Attendence Cell == */}
                                        <AttendanceTableCell
                                            cell={row.cell}
                                            fullWeekDates={fullWeekDates}
                                            row={row}
                                            setAttendanceDateToInsert={setAttendanceDateToInsert}
                                            setStudentIdForAttendance={setStudentIdForAttendance}
                                            updateAttendanceSingle={updateAttendanceSingle}
                                            setAttendanceIdToUpdate={setAttendanceIdToUpdate}
                                        />


                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <div className="Table">
                <div className="TableContainer DrawerTable">
                    <div className='tablePage TableFooter attendanceTableFooterContainer'>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                            component="div"
                            count={rows?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={<div className='PaginationResultLabel'>Results</div>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}