import { api } from '../../api/apiSlice';

const feeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllFees: builder.query({
            query: () => ({
                url: '/fees/all',
            }),
            providesTags: ['Fees'],
        }),
        getFeeDetails: builder.query({
            query: (id) => ({
                url: `/fees/getSingle/${id}`,
            }),
            providesTags: ['Fee'],
        }),
        addFees: builder.mutation({
            query: (data) => ({
                url: `/fees/create`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Fees'],
        }),

        updateFees: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/fees/update/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Fees'],
        }),
        checkFeeCode: builder.mutation({
            query: (data) => ({
                url: '/fees/checkFeeCode',
                method: 'POST',
                body: data,
            }),
        }),
        deleteFee: builder.mutation({
            query: (id) => ({
                url: `/fees/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Fees'],
        }),
        paymentsSearch: builder.mutation({
            query: (data) => ({
                url: `/payments/admin/search`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Payments'],
        }),
        saveAccountData: builder.mutation({
            query: (data) => ({
                url: `/payments/admin/save-bank-account`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Payments'],
        }),
        verifyMicroDeposit: builder.mutation({
            query: (data) => ({
                url: `/payments/admin/verify-micro-deposit`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Payments', "UserDetails"],
        }),
    }),
});

export const {
    useGetAllFeesQuery,
    useGetFeeDetailsQuery,
    useAddFeesMutation,
    useUpdateFeesMutation,
    useCheckFeeCodeMutation,
    useDeleteFeeMutation,
    useLazyGetFeeDetailsQuery,
    useCheckUniqueFeeMutation,
    usePaymentsSearchMutation,
    useSaveAccountDataMutation,
    useVerifyMicroDepositMutation,
} = feeApi;