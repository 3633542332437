import React from "react";
import Table from "../../../Table/Table copy";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const UserTable = () => {
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      // width: "30%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "ID",
      // width: "20%",
      textAlign: 'left'
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Role",
      // width: "20%",
      textAlign: 'left'
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Status",
      // width: "20%",
      textAlign: "left",
    },
  ];

  const rows = [
    {
      cell: [
        { textBold: "Shayan Mahmud " },
        { textBold: "000022" },
        { textBold: "Student" },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Active", align: 'block' },
      ],
    },
    {
        cell: [
          { textBold: "Shayan Mahmud " },
          { textBold: "000022" },
          { textBold: "Student" },
          { Status: true, background: "#FCE7E9", color: "#E63946", label: "Inactive", align: 'block' },
        ],
      },
  ];

  return (
    <>
      <div className="TableSection">
        <Table
          title="Course"
          tableHeader={false}
          addLabel="New User"
          link="add-courses"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={false}
          innerHeader={true}
          innerTitle=""
          FilterButton={false }
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
        />
      </div>
    </>
  );
};

export default UserTable;
