import * as React from 'react';


import { CgSearch } from 'react-icons/cg';

import './SearchBox.css';

export default function SearchBox(props) {
  return (
    <div className='SearchBox'>
      <input
        className='SearchBoxField'
        type='text'
        placeholder={props.placeholder}
        value={props.searchText}
        onChange={props.handleSearchText}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.handleSearch()
          }
        }}
      />

      <div
        className='SearchBoxButton'
        onClick={props.handleSearch}
        onMouseDown={props.handleSearch}
        edge="end"
      >
        <CgSearch />
      </div>

    </div>
  );
}