import React, { useState, useEffect } from "react";
import VerticalTab from '../../../components/VerticalTab/VerticalTab';
import { CleanHands, Person, Receipt, Security } from "@mui/icons-material";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import Avatar from '@mui/material/Avatar';
import { deepPurple, } from '@mui/material/colors';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Buttons from "../../../components/Form/Button/Button";
import { useGetUserDetailsQuery } from "../../../redux/features/users/userApi";
import { useSelector } from "react-redux";
import { getProfileAvatarFromName, toTitleCase } from "../../../utils/helpers";
import useProtected from "../../../hooks/useProtected";
import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop';
import AccountDetails from "./AccountDetails/AccountDetails";
import Branding from "./Branding/Branding";
import { BrushFill } from "../../../assets/imgs/icons/brushFill";
import { IcOutlineAttachMoney } from "../../../assets/imgs/icons/IcOutlineAttachMoney";
import FeesPlans from "./FeesPlans/FeesPlans";
import { BankIcon } from "../../../assets/imgs/icons/bankIcon";
import Payment from "./Payment/Payment";






function OrgSettingsPage(props) {
    const { _id: id, schoolId } = useSelector(state => state.auth.user);
    const { data, isLoading, isError, error, refetch } = useGetUserDetailsQuery(id, {
        refetchOnMountOrArgChange: true,
    });


    useProtected(error);

    const [editProfile, setEditProfile] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [startTab, setStartTab] = useState(0);
    const [personalInfo, setPersonalInfo] = useState([]);
    // const [tabData, setTabData] = useState([
    //     {
    //         icon: <BusinessOutlinedIcon />,
    //         label: "Account details",
    //     },

    //     {
    //         icon: <BrushOutlinedIcon />,
    //         label: "Branding",
    //         selectedIcon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><BrushFill /></span>
    //     },

    //     {
    //         icon: <IcOutlineAttachMoney />,
    //         label: "Fees & Plans",
    //     },
    //     {
    //         icon: <BankIcon />,
    //         label: "Payment",
    //         selectedIcon: <BankIcon />
    //     }

    // ])

    // const tabBody = [

    //     {
    //         id: 0,
    //         panel: <AccountDetails />
    //     },
    //     {
    //         id: 1,
    //         panel: <Branding />,
    //     },

    //     {
    //         id: 2,
    //         panel: <FeesPlans />,
    //     },

    //     {
    //         id: 3,
    //         panel: <Payment />,
    //     },

    // ]

    const currentTabData = [
        {
            icon: <BusinessOutlinedIcon />,
            label: "Account details",
        },

        {
            icon: <BrushOutlinedIcon />,
            label: "Branding",
            selectedIcon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><BrushFill /></span>
        },
    ]

    const conditionalTabData = [
        {
            icon: <IcOutlineAttachMoney />,
            label: "Fees & Plans",
        },
        {
            icon: <BankIcon />,
            label: "Payment",
            selectedIcon: <BankIcon />
        }
    ];


    const tabData = [...currentTabData, ...conditionalTabData]
    const currentTabBody = [
        {
            id: 0,
            panel: <AccountDetails />
        },
        {
            id: 1,
            panel: <Branding />,
        },
    ]

    const conditionalTabBody =
        [
            {
                id: 2,
                panel: <FeesPlans />,
            },

            {
                id: 3,
                panel: <Payment />,
            },
        ];



    const tabBody = [...currentTabBody, ...conditionalTabBody]
    // useEffect(() => {
    //     if (process.env.REACT_APP_ENV === 'stage') {
    //         let newTabData = [...tabData];
    //         let foundTab = newTabData?.find((tab) => tab?.label === "Fees & Plans");

    //         if (!foundTab) {
    //             newTabData.splice(2, 0, {
    //                 icon: <IcOutlineAttachMoney />,
    //                 label: "Fees & Plans",
    //             });
    //             setTabData(newTabData);
    //         }
    //     }
    // }, [tabData]);

    const handleEditProfile = () => {
        setEditProfile(true);
    }




    const handleTabSwitch = (activeTab) => {
        console.log(activeTab);
        setCurrentTab(activeTab);
    }

    useEffect(() => {
        if (!isLoading && data && !error) {
            setPersonalInfo(data.body.data)
        }
    }, [data, error, isLoading])


    console.log("fgj")
    return (
        <div className="">
            <SimpleBackdrop open={isLoading} />
            <div>
                <h1 style={{ margin: '0', fontSize: '36px' }}>Settings</h1>
            </div>

            <div>
                <VerticalTab
                    //title="Introduction to Islamic Economics and Finance"
                    //name="MUNA Academy"
                    link="#"
                    tabData={tabData}
                    tabBody={tabBody}
                    onTabSwitch={handleTabSwitch}
                    activeTab={startTab}
                //onClick={onClick()}
                //ButtonLabel="Start Class"
                />

            </div>
            {/* <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} /> */}
        </div>
    );
}


export default OrgSettingsPage;

