import React, { useState, useEffect } from "react";

import { getDataMap, getMonths, getYears, getDates, dateOptions } from "../../../../hooks/HelperFunctions"

import Grid from '@material-ui/core/Grid';
// import TextField from '../../../../../components/Form/TextField/TextField';
import GooglePlaceField from "../../../../components/Form/GooglePlaceField/GooglePlaceField";
// import GooglePlacesAutoField from '../../../../../components/Form/GooglePlaceField/GooglePlacesAutoField';

// import SelectField from '../../../../../components/Form/SelectField/SelectField';
import ComboBox from '../../../../components/Form/ComboBox/ComboBox';

import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple, } from '@mui/material/colors';
import FileUplaoder from "../../../../components/Form/FileUplaoder/FileUplaoder"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import TextField from "../../../../components/Form/TextField/TextField";
import moment from "moment-timezone";
import { useMemo } from "react";
import { countries } from "../../../../assets/data/countries";
import { getProfileAvatarFromName, mapMonth, monthArray } from "../../../../utils/helpers";
import { type } from "@testing-library/user-event/dist/type";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../components/Form/Button/Button";
import Externaluse from "../../../../components/MediaLibrary/Externaluse";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, FormHelperText } from "@mui/material";



function UpdatePersonalInfo(props) {



    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });
    const { personalInfo, setPersonalInfo, manualAddress, setManualAddress, errors } = props;
    // const [disableSaveBtn, setDisableSaveBtn] = useState(true);

    const [openSelectMedia, setOpenSelectMedia] = useState(false);

    // const { personalInfo, setPersonalInfo } = props;

    const [selectedValue, setSelectedValue] = React.useState(false);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [mediaItem, setMediaItem] = useState({});
    // const [manualAddress, setManualAddress] = useState(false)
    const [selectedFIle, setSelectedFIle] = useState({});
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);

    //handleDrawerCloseSelectMedia
    const handleDrawerCloseSelectMedia = () => {
        setOpenSelectMedia(false);
    };

    useMemo(() => {
        const names = moment.tz.names();
        let offsetTmz = [];

        for (let i in names) {
            offsetTmz = [...offsetTmz, `${names[i]} (GMT${moment.tz(names[i]).format('Z')})`]
        }
        // console.log({ offsetTmz })
        setTimeZoneOptions(offsetTmz.map(item => ({ label: item, value: item })))
    }, [])


    //getMediaItem
    const getMediaItem = (item) => {
        // setting the media item
        setMediaItem(item);
    };

    //handleOpenNewMedia
    const handleOpenNewMedia = (status) => {
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
    };
    //handleDrawerOpenSelectMedia
    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
    };

    //handleMediaUploadClose
    const handleMediaUploadClose = () => {
        setOpenNewMedia(false);
    };

    //handleDrawerOpenNewMedia
    const handleDrawerOpenNewMedia = () => {
        // console.log("handleDrawerOpenNewMedia");
        setOpenNewMedia(true);
    };
    const clickSelectMediaItem = () => {
        //check if any item is selected
        if (selectedValue) {
            // console.log(mediaItem);
            //set the media item
            setImageSelection(mediaItem.url);
            handleChangeWithData("imageName", mediaItem.name);

            // console.log( personalInfo)
            setOpenSelectMedia(false);
        } else {
            alert("Please select any media");
        }
    };

    // === onChange event handler, get field data from event === //
    const handleChanges = (fieldName) => (event) => {
        const fieldData = event.target.value;
        // console.log(fieldName, fieldData);
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };

    // === onChange event handler, get field data from parem === //
    const handleChangeWithData = (fieldName, fieldData) => {
        setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
        // setDisableSaveBtn(false);
    };

    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }

    const setImageSelection = (fileUrl) => {
        let temp = personalInfo;
        temp.profilePic = fileUrl;
        setPersonalInfo(temp);
        handleChangeWithData("profilePic", fileUrl);
    };

    const fileSelectedHandler = (e) => {

        if (props.selectedFile) {
            props.selectedFile(e);
        }

    }


    return (
        <div className="ModalForm">
            <Grid container spacing={3}>



                <Grid item xs={12} sm={4} md={4}>
                    <TextField
                        label="First Name"
                        required={true}
                        value={personalInfo?.firstName}
                        handleChange={(val, e) => handleChanges("firstName")(e)}
                        error={errors?.firstName}
                        helperText={errors?.firstName}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <TextField
                        label="Middle Name"
                        required={false}
                        value={personalInfo?.middleName}
                        handleChange={(val, e) => handleChanges("middleName")(e)}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <TextField
                        label="Last Name"
                        required={true}
                        value={personalInfo?.lastName}
                        handleChange={(val, e) => handleChanges("lastName")(e)}
                        error={errors?.lastName}
                        helperText={errors?.lastName}

                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <div className="FieldLabel">Profile Photo</div>


                    <div className="UploadPhotoCard">
                        <div className="UploadPhotoCardImg">
                            <Avatar
                                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                                src={personalInfo?.profilePic ? personalInfo?.profilePic : ""}
                            >{getProfileAvatarFromName(personalInfo)}</Avatar>
                        </div>

                        {personalInfo?.profilePic && (
                            <div className="UploadPhotoFiled">
                                <div className="UploadPhotoImgCol">
                                    <span><img src={personalInfo?.profilePic} /></span>
                                    <div className="ImgDetails">Profile Image</div>
                                </div>
                                <a className="UploadPhotoFiledDelete">
                                    <DeleteForeverRoundedIcon onClick={() => {
                                        setPersonalInfo({ ...personalInfo, profilePic: '' });
                                    }} />
                                </a>
                            </div>
                        )}

                        {!personalInfo?.profilePic && (
                            <div className="UplaodPhotoModal">
                                <div>

                                    <Buttons
                                        label="Add From Media Library"
                                        buttonColor="transparent"
                                        border="#E4E8EC 1px solid"
                                        icon={<AddRoundedIcon />}
                                        color="#004FE0"
                                        width="100%"
                                        height="70px"
                                        fontSize="14px"
                                        link=""
                                        onClick={() => handleDrawerOpenSelectMedia()}
                                    />

                                    <SideDrawer
                                        title="Media Library"
                                        ButtonLabel="Add"
                                        //clickSaveButton={clickSaveButton}
                                        cancelButtonLabel="Cancel"
                                        sideOpen="right"
                                        open={openSelectMedia}
                                        handleDrawerClose={handleDrawerCloseSelectMedia}
                                        FooterWithoutTab={true}
                                        LeftButton={true}
                                        ButtonLeft="Upload New"
                                        ButtonLeftIcon={<FileUploadOutlinedIcon />}
                                        clickButtonLeft={handleDrawerOpenNewMedia}
                                        clickSaveButton={clickSelectMediaItem}
                                        padding="0"
                                        BackArrow={true}
                                        CloseArrowHide={true}
                                        body={
                                            <MediaLibrarySelectMedia
                                                onClickRadioIcon={setSelectedValue}
                                                onItemSelected={getMediaItem}
                                            />
                                        }
                                    />

                                    <Externaluse
                                        open={openNewMedia}
                                        handleCloseButton={handleMediaUploadClose}
                                        handleOpenNewMedia={(handleOpenNewMedia)}
                                    />




                                </div>
                            </div>
                        )}

                    </div>

                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <div className="RedioButton">
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label" className="FieldLabel">Gender</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={personalInfo?.gender}
                                onChange={handleChanges("gender")}
                            >
                                <FormControlLabel value="Male" control={<Radio />} label="Male" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <FormControlLabel value="Female" control={<Radio />} label="Female" />

                            </RadioGroup>
                        </FormControl>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    {/* <SelectField
            label="Birthday"
            validation={false}
            placeholder="Month"
            options={getMonths()}
            selectedOption={personalInfo.birthMonth}
            handleChange={handleChanges("birthMonth")}
          /> */}

                    <ComboBox
                        label="DOB"
                        placeholder="Month"
                        validation={false}
                        options={getMonths()}
                        selectedOption={personalInfo?.birthMonth ? personalInfo?.birthMonth : ""}
                        handleChange={(fieldData) => handleChangeWithData("birthMonth", fieldData)}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <ComboBox
                        placeholder="Day"
                        validation={false}
                        options={dateOptions(personalInfo?.birthMonth, personalInfo?.birthYear)}
                        selectedOption={personalInfo?.birthDay}
                        handleChange={(fieldData) => handleChangeWithData("birthDay", fieldData)}
                    />

                    {/* <TextField
                        placeholder="Day"
                        value={personalInfo.birthDay}
                        handleChange={handleChanges("birthDay")}
                    /> */}
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    {/* <SelectField
            placeholder="Year"
            value={personalInfo.birthYear}
            handleChange={handleChanges("birthYear")}
            options={getYears()}
            selectedOption={personalInfo.birthYear? personalInfo.birthYear : "2007"}
          /> */}
                    <ComboBox
                        placeholder="Year"
                        validation={false}
                        options={getYears()}
                        selectedOption={personalInfo?.birthYear}
                        handleChange={(fieldData) => handleChangeWithData("birthYear", fieldData)}
                    />
                </Grid>

                {/* country drop-down */}
                <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                        label="Select country"
                        validation={false}
                        options={countries}
                        selectedOption={personalInfo?.country}
                        handleChange={(fieldData) => handleChangeWithData("country", fieldData)}
                        error={errors?.country}
                        helperText={errors?.country}
                    />
                </Grid>

                {
                    manualAddress ?
                        <Grid item xs={12} sm={12} md={12}>
                            <GooglePlaceField
                                label="Address"
                                validation={true}
                                placeholder="Your current resident address"
                                value={checkJsonStringOrNot(personalInfo?.address)}
                                handleChange={(address) => handleChangeWithData("address", address)}
                                error={errors?.adddress}
                                helperText={errors?.adddress}
                            />
                            {(errors?.adddress) ? <FormHelperText sx={{ color: '#ba1b1a' }} >Required</FormHelperText> : ""}
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        label="Street address"
                                        required={true}
                                        value={personalInfo?.addressLine1}
                                        handleChange={(val, e) => handleChanges("addressLine1")(e)}
                                        error={errors?.addressLine1}
                                        helperText={errors?.addressLine1}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Apt/Floor"
                                        required={false}
                                        value={personalInfo?.addressLine2}
                                        handleChange={(val, e) => handleChanges("addressLine2")(e)}

                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="City/Town/Village"
                                        required={true}
                                        value={personalInfo?.city}
                                        handleChange={(val, e) => handleChanges("city")(e)}

                                        error={errors?.city}
                                        helperText={errors?.city}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="State/Province/Territory"
                                        required={true}
                                        value={personalInfo?.state}
                                        handleChange={(val, e) => handleChanges("state")(e)}
                                        city
                                        error={errors?.state}
                                        helperText={errors?.state}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Zip/Postal code"
                                        required={true}
                                        value={personalInfo?.zip}
                                        handleChange={(val, e) => handleChanges("zip")(e)}
                                        error={errors?.zip}
                                        helperText={errors?.zip}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                }

                <Grid item xs={12} sm={12} md={12} sx={{ padding: '0px' }}>
                    <Button
                        variant="text"
                        sx={{ padding: '0px', marginTop: '-26px', color: '#0450E1', textTransform: 'initial' }}
                        onClick={() => setManualAddress(!manualAddress)}
                    >
                        {manualAddress ?
                            "Enter address manually" : "Search your address"}</Button>
                </Grid>

                {/* height education drop-down */}
                {/* <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                        label="Highest education"
                        validation={false}
                        options={getDataMap(highestEducation, "heduId", "title")}
                        // selectedOption={personalInfo.heduId}
                        handleChange={(fieldData) => handleChangeWithData("heduId", fieldData)}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label="Current profession"
                        required={false}
                        // value={personalInfo.currentProfession}
                        handleChange={handleChanges("currentProfession")}
                    />
                </Grid> */}

                {/* <Grid item xs={12} sm={12} md={12}>
          
        </Grid> */}



                {/* <Grid item xs={12} sm={12} md={12}>
          <ComboBox
              label="Current Grade"
              validation={false}
              options={getDataMap(gradeLevels, "gradeId","title")}
              selectedOption={personalInfo.currentGrade}
              handleChange={ (fieldData) => handleChangeWithData("currentGrade", fieldData)}
          />
        </Grid> */}

                {/* <Grid item xs={12} sm={12} md={12}>
          <ComboBox
              label="Age Group"
              validation={false}
              options={getDataMap(ageGroups, "ageId","title")}
              selectedOption={personalInfo.ageGroup}
              handleChange={ (fieldData) => handleChangeWithData("ageGroup", fieldData)}
          />
        </Grid> */}



            </Grid>
        </div >

    );
}


export default UpdatePersonalInfo;





































