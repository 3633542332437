import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddCalendars from "./AddCalendars";
import { useAddCalenderMutation, useCheckCalenderNameMutation, useDeleteCalenderMutation, useEditCalenderMutation, useGetCalendersQuery, useSearchCalenderMutation } from "../../../redux/features/calender/calenderApi";
import useProtected from "../../../hooks/useProtected";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import { getUserData } from "../../../hooks/HelperFunctions";
import { toast } from "react-toastify";
import DraggableDialog from "../../../components/Dialog/Dialog";

const Calendars = () => {


  const {
    data,
    isFetching,
    isLoading,
    error
  } = useGetCalendersQuery(undefined, { refetchOnMountOrArgChange: true });
  const {
    data: schoolYearData,
    isFetching: schoolYearIsFetching,
    isLoading: schoolYearIsLoading,
    error: schoolYearError
  } = useGetSchoolYearListQuery(undefined, { refetchOnMountOrArgChange: true });

  const [addCalender, addCalenderOptions] = useAddCalenderMutation();
  const [updateCalender, updateCalenderOptions] = useEditCalenderMutation();
  const [deleteCalender, deleteCalenderOptions] = useDeleteCalenderMutation();
  const [searchCalender, searchCalenderOptions] = useSearchCalenderMutation();
  const [checkCalenderName, checkCalenderNameOptions] = useCheckCalenderNameMutation();
  const [isSaving, setIsSaving] = useState(false);


  useProtected(error);
  useProtected(schoolYearError);
  useProtected(addCalenderOptions.error);
  useProtected(updateCalenderOptions.error);
  useProtected(deleteCalenderOptions.error);
  useProtected(searchCalenderOptions.error);
  useProtected(checkCalenderNameOptions.error);


  const initialCalenderObject = {
    name: "",
    academicYearId: "",
    createdBy: ""
  }

  const initialCalenderErrorObject = {
    name: "",
    academicYearId: "",
  }

  const [rows, setRows] = useState([])
  const [editId, setEditId] = useState('')
  const [duplicateId, setDuplicateId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [searchTerm, setSearchTerm] = useState("")
  const [schoolYears, setSchoolYears] = useState([])
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [openAddCalendar, setOpenAddCalendar] = useState(false);
  const [schoolYearOptions, setSchoolYearOptions] = useState([])
  const [calenderObject, setCalenderObject] = useState(initialCalenderObject)
  const [calenderErrorObject, setCalenderErrorObject] = useState(initialCalenderErrorObject)



  const handleDrawerOpenAddCalendar = () => {
    setOpenAddCalendar(true);
  };
  const handleDrawerCloseAddCalendar = () => {
    setIsSaving(false)
    setOpenAddCalendar(false);
    setDuplicateId('')
    setEditId('')
  };

  useEffect(() => {
    if (!schoolYearIsFetching && !schoolYearIsLoading && schoolYears.length > 0 && data && !isLoading && !isFetching) {
      let datas = [...data?.body?.data];
      datas.sort((a, b) => {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      setRows(convertDataToCellRows(datas))
    }
  }, [schoolYearIsLoading, schoolYearIsFetching, schoolYears, data, isLoading, isFetching])


  useEffect(() => {
    if (!schoolYearIsFetching && !schoolYearIsLoading) {
      const data = schoolYearData.body.data;
      setSchoolYears(schoolYearData?.body?.data)
      setSchoolYearOptions(getPreparedArray(data || []))
    }
  }, [schoolYearData, schoolYearIsFetching, schoolYearIsLoading])


  useEffect(() => {
    console.log('calenderObject : ', calenderObject)
  }, [calenderObject])

  const getPreparedArray = (data, value = 'name') => {
    return data.map((item) => ({
      value: item['_id'],
      label: item[value],
    }));
  };

  const duplicateCalenderHandler = (id) => {
    setDuplicateId(id)
    handleDrawerOpenAddCalendar()
  }
  const editCalenderHandler = (id) => {
    setEditId(id)
    handleDrawerOpenAddCalendar()
  }

  const deleteCalenderHandler = (id) => {
    setDeleteId(id)
    handleOpenDelete()

  }
  const currentDate = new Date();
  const isCurrent = (data) => {
    const startDate = new Date(data?.startDate);
    const endDate = new Date(data?.endDate);
    return currentDate >= startDate && currentDate < new Date(endDate.setDate(endDate.getDate() + 1))
  }
  const convertDataToCellRows = (arr) => {

    if (arr?.length > 0) {

      return arr.map((item) => {
        const foundYear = schoolYears?.find((year) => year?._id == item?.academicYearId);
        const isYearCurrent = isCurrent(foundYear);

        return {
          cell: [
            { linkText: item?.name || "N/A", linkTextStatus: true, link: `/school-calendar/${item?._id}` },
            { date: foundYear?.name, chip: isYearCurrent ? <span style={{ fontSize: '12px' }}>Current</span> : "", chipStatus: isYearCurrent ? true : false },
            {},
            { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
          ],

          //======= Action Date Start===== //
          action: [
            { label: 'View', link: `/school-calendar/${item?._id}`, },
            { label: 'Edit', onClick: (e) => editCalenderHandler(item?._id) },
            { label: 'Duplicate', onClick: (e) => duplicateCalenderHandler(item?._id) },
            { label: 'Delete', onClick: (e) => deleteCalenderHandler(item?._id) },
          ],
        };
      });
    } else {
      return [];
    }

  };


  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '60%', textAlign: 'left', },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Academic year', width: '25%', textAlign: 'left', },
    { id: 'fat', numeric: true, disablePadding: false, label: '', width: '10%', textAlign: 'left', },
    { id: 'carbs', numeric: true, disablePadding: false, label: '', width: '10%', },
  ];



  const checkNameExist = async () => {

    const trimmedName = calenderObject.name.trim();
    if (trimmedName === "") {
      return false;
    }
    let searchResult = await checkCalenderName({ name: trimmedName });
    if (searchResult?.data && searchResult.data?.body?.data && searchResult.data?.body?.data?.length > 0) {
      return true
    }
    return false
  }


  const isCalenderValid = async () => {
    let error = 0
    let errorObject = { ...calenderErrorObject }
    const trimmedName = calenderObject.name.trim();
    if (trimmedName == "") {
      error++
      errorObject = { ...errorObject, name: "Required" }
    } else {
      const nameExist = await checkNameExist()
      if (nameExist && !editId) {
        error++
        errorObject = { ...errorObject, name: "The calendar name is already in use. Choose a different name." }
      } else {
        errorObject = { ...errorObject, name: "" }
      }
    }

    if (calenderObject?.academicYearId == "") {
      error++
      errorObject = { ...errorObject, academicYearId: "Required" }
    } else {
      errorObject = { ...errorObject, academicYearId: "" }
    }

    setCalenderErrorObject({ ...calenderErrorObject, ...errorObject })

    return error > 0 ? false : true
  }

  const saveCalenderHandler = async () => {
    if (isSaving) return
    let data = { ...calenderObject }
    let userInfo = getUserData()

    setIsSaving(true)
    let isValid = await isCalenderValid()

    if (!isValid) {
      setIsSaving(false)
      return false
    }

    data.createdBy = userInfo?._id

    let result = (editId === "") ? await addCalender(data) : await updateCalender({ ...data, id: editId })

    if (result?.data && result?.data?.error === false) {
      (editId === "") ? toast.success(`Calender ${duplicateId ? 'duplicated' : 'added'}`) : toast.success('Calender updated')

      setCalenderObject(initialCalenderObject)
      handleDrawerCloseAddCalendar()

      setEditId('')
      setDuplicateId('')
      setCalenderErrorObject(initialCalenderErrorObject)
    }
  }

  const deleteCalenderConfirmHandler = async () => {
    let id = deleteId

    let result = await deleteCalender(id)
    console.log(result)
    if (result?.data) {
      toast.success('Calender removed');
    } else {
      toast.error(result?.data?.message);
    }
    handleCloseDeleteDialog()
    setDeleteId('')
  }

  const closeDrawerHandler = () => {
    setCalenderErrorObject(initialCalenderErrorObject)
    setCalenderObject(initialCalenderObject)
    setEditId('')
    handleDrawerCloseAddCalendar()
  }


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };

  //======= Delete dialog ===== //


  const closeDeleteDialogHandler = () => {
    setDeleteId('')
    handleCloseDeleteDialog()
  }

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchCalender({ name: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
    setPageNumberFromOutSide(false)
  };

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
    setPageNumberFromOutSide(false)
  }, [resetsearchTerm])

  const handleResetSearch = async () => {
    const result = await searchCalender({ name: "" });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  return (
    <>
      <div className="TableSection">
        <Table
          title="Calendars"
          tableHeader={true}
          AddButton={true}
          addLabel="Add"
          headCells={headCells}
          rows={rows}
          Checkbox={true}
          footer={true}
          innerHeader={true}
          SearchBoxTop={true}
          RowsPerPage={20}
          onClick={handleDrawerOpenAddCalendar}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          isDataLoading={isLoading || schoolYearIsLoading}

        />
      </div>


      {/* AddCalendars Drawer */}
      <SideDrawer
        open={openAddCalendar}
        handleDrawerClose={(e) => closeDrawerHandler()}
        title={(editId !== "") ? "Edit Calender" : (duplicateId !== "") ? "Duplicate Calendar" : "Add Calendar"}
        ButtonLabel={(editId != "") ? "Update now" : (duplicateId !== "") ? "Duplicate now" : "Add now"}
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => saveCalenderHandler()}

        body={
          <>
            <AddCalendars
              calenderObject={calenderObject}
              setCalenderObject={setCalenderObject}
              schoolYears={schoolYears}
              schoolYearOptions={schoolYearOptions}
              editId={editId}
              duplicateId={duplicateId}
              calenderErrorObject={calenderErrorObject}
              setCalenderErrorObject={setCalenderErrorObject} />

          </>
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={(e) => closeDeleteDialogHandler()}
        handleConfirmCloseDialog={(e) => deleteCalenderConfirmHandler()}
        title="Remove calender?"
        body={<div>Are you sure you want to remove this calender?</div>}
        ModalFooter={true}
        actionButton="REMOVE"
      />


    </>
  );
};

export default Calendars;
