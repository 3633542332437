import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/Table'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SelectUsers from './SelectUsers';
import { useAddUserToUserScanMutation, useGetScanUserListQuery, useRemoveUserFromUserScanMutation } from "../../../redux/features/attendence/attendanceApi";
import { capitalizeWords } from '../../../hooks/HelperFunctions';
import { truncateText } from "../../../hooks/HelperFunctions"
import SelectUsersScan from './SelectUsersScan';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import DraggableDialog from '../../../components/hooks/Dialog';


const ScanUser = () => {
    const { data, isLoading, isFetching, error } = useGetScanUserListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })

    const [addUserToScan, addUserToScanOptions] = useAddUserToUserScanMutation();

    const [removeUserFromScanUserList, removeUserFromScanUserListOptions] = useRemoveUserFromUserScanMutation();


    useProtected(removeUserFromScanUserListOptions.error);
    useProtected(addUserToScanOptions.error);
    useProtected(error);


    const [rows, setRows] = useState([])
    const [userIdList, setUserIdList] = useState([]);
    const [userIdListJust, setUserIdListJust] = useState([]);
    const [alreadyAddedUsers, setAlreadyAddedUsers] = useState([]);

    const [OpenSelectUsers, setSelectUsers] = useState(false);
    const handleOpenSelectUsers = () => setSelectUsers(true);
    const handleCloseSelectUsers = () => setSelectUsers(false);

    const [checkedUsers, setCheckedUsers] = useState([]);

    const [deleteId, setDeleteId] = useState('')


    useEffect(() => {
        if (!isLoading && !isFetching) {
            setRows(convertDataToCellRows(data.body.data));
            setAlreadyAddedUsers(data.body.data);
        }
    }, [data, isLoading, isFetching])

    const [addUserDrawer, setAddUserDrawer] = useState(false)

    const headCells = [
        { id: 'Name', numeric: false, disablePadding: true, label: 'Name', width: '50%', textAlign: 'left', },
        { id: 'ID', numeric: false, disablePadding: true, label: 'ID', width: '20%', textAlign: 'left', },
        { id: 'userType', numeric: false, disablePadding: true, label: 'User Type', width: '20%', textAlign: 'left', },
        { id: 'Users', numeric: false, disablePadding: true, label: '', width: '10%', textAlign: 'right', },
    ];


    const selectUsers = (userList) => {
        const newList = userList.map((item) => ({
            _id: item?._id || '',
            name: `${item?.firstName || ''} ${item?.lastName || ''}`,
            userType: item?.userType || '',
            userImage: item?.userImage || '',
            userUniqueID: item?.userUniqueID || '',
        }));
        setUserIdList(newList);
        // setUserIdListJust(userList.map(item => item?._id || ''))
    };


    //======= Delete dialog ===== //
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDelete = () => {
        setOpenDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDialog(false)
    };
    //======= Delete dialog ===== //


    const convertDataToCellRows = (arr) => {
        if (arr?.length > 0) {
            return arr?.map((item) => ({
                cell: [
                    { textBold: `${truncateText(`${capitalizeWords(item?.firstName)} ${capitalizeWords(item?.lastName)}`, 35)}` },
                    { textBold: (item?.userUniqueID) ? item?.userUniqueID : 'N/A' },
                    { textBold: (item?.userType) ? capitalizeWords(item?.userType) : 'N/A' },
                    {
                        Button: true,
                        buttonLabel: 'remove',
                        buttonColor: '#FFFFFF',
                        buttonBorder: '#E4E8EC 1px solid',
                        buttonTextcolor: '#004FE0',
                        // buttonLink: '#',
                        // buttonTarget: '_blank',
                        iconStatus: false,
                        buttonIcon: '',
                        onClick: (e) => deleteScanUserHandler(item?._id)
                    },
                ]
            }));
        } else {
            return [];
        }
    };


    const addUsersToScanHandler = async () => {
        let scanUsersData = checkedUsers.map((user) => {

            return {
                userUniqueID: user?.userUniqueID,
                userSystemId: user?._id,
                userName: user?.userName ? user?.userName : user?.email,
                email: user?.email,
                userType: user?.userType,
                firstName: user?.firstName,
                lastName: user?.lastName,
                profilePic: user?.profilePic
            }

        })
        if (scanUsersData?.length === 0) {
            toast.error("Please select at least one user")
            return false
        }
        let data = {
            userList: scanUsersData
        }

        const result = await addUserToScan(data);
        if (!result?.data?.error) {
            toast.success('Users added successfully');
            setUserIdList([]);
            setUserIdListJust([]);
            setAddUserDrawer(false)
            setCheckedUsers([])

        } else {
            toast.error(result?.data?.message);
        }




    }

    const deleteScanUserHandler = async (userId) => {

        setDeleteId(userId)
        handleOpenDelete()


    }

    const confirmDeleteHandler = async () => {
        let data = {
            id: deleteId
        }

        const result = await removeUserFromScanUserList(data);

        if (result?.data) {
            console.log('User removed successfully');
            toast.success('User removed successfully');
            handleCloseDeleteDialog()


        } else {
            toast.error(result?.data?.message);
        }
    }




    return (
        <>
            <div className="UserRoll">
                <Table
                    title={`${alreadyAddedUsers?.length} users added`}
                    tableHeader={true}
                    addLabel="Add users"
                    AddButton={true}
                    icon={<AddRoundedIcon />}
                    headCells={headCells}
                    rows={rows}
                    onClick={() => setAddUserDrawer(true)}
                    // footer={roleUsersToUpdate.length === 0 ? false : true}
                    footer={true}
                    RowsPerPage={20}

                    emptyTableTitle="No user added yet"
                    emptyTableNote="Added users will be visible here"
                    btnLabel="Add users"
                    showButton={false}
                // onClickEmptyCard={handleDrawerOpenRole}
                />
            </div>

            {/* Add user drawer */}
            <SideDrawer
                open={addUserDrawer}
                handleDrawerClose={() => setAddUserDrawer(false)}
                title="Add Users"
                BackArrow={true}
                CloseArrowHide={true}
                sideOpen="right"
                ButtonLabel={(checkedUsers?.length > 0) ? `Select ${checkedUsers?.length} users` : "Select 0 users"}
                cancelButtonLabel="Back"
                FooterWithoutTab={true}
                padding="0"
                // drawerWidth="min(100%, 724px)"
                drawerWidth="744px"
                // ButtonLabel={`Add ${roleUsers?.length || 0} user${roleUsers?.length > 1 ? 's' : ''}`}
                // ButtonLabel={`Add ${roleUsers?.length || 0} users`}
                clickSaveButton={(e) => addUsersToScanHandler()}
                body={
                    <>
                        <SelectUsersScan
                            selectUsers={selectUsers}
                            handleCloseSelectUsers={handleCloseSelectUsers}
                            selectedList={userIdList}
                            selectedListJust={userIdListJust}
                            renderFullItem={true}
                            setCheckedUsers={setCheckedUsers}
                            alreadyAddedUsers={alreadyAddedUsers}
                        />
                    </>
                }
            />

            <DraggableDialog
                openDialog={openDialog}
                handleConfirmCloseDialog={confirmDeleteHandler}
                handleCloseDialog={handleCloseDeleteDialog}
                // title="Delete this user?"
                // body="Are you sure you want to delete this? Once you delete it, you can't get it back."
                title="Remove user from users list?"
                body={<span style={{ fontSize: '14px' }}>Are you sure you want to remove this user from the users list?</span>}
                ModalFooter={true}
            />
        </>
    )
}

export default ScanUser