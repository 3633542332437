import React, { useState, useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditAbleTableCell from './EditAbleTableCell';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../Form/Button/Button';

import './EditAbleTable.css';

function EditAbleTable(props) {

  const { rows, headCells, SubmitButtonLabel, SubmitButtonWidth, SubmitOnClick, handleAddNew, handleremove, usersData } = props;



  const [inputList, setinputList] = useState([{ firstName: '', lastName: '' }]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setinputList(list);

  }

  // const handleremove = index => {
  //   const list = [...inputList];
  //   list.splice(index, 1);
  //   setinputList(list);
  // }

  // const handleaddclick = () => {
  //   setinputList([...inputList, { firstName: '', lastName: '' }]);
  // }



  const [isTouchedAtBottom, setIsTouchedAtBottom] = useState(false);

  // useEffect(() => {
  //   const handleTouchEnd = (e) => {
  //     const windowHeight = window.innerHeight;
  //     const touchY = e.touches[0].clientY;


  //     if (touchY >= windowHeight - 50) {
  //       setIsTouchedAtBottom(true);
  //     } else {
  //       setIsTouchedAtBottom(false);
  //     }
  //   };

  //   window.addEventListener('touchend', handleTouchEnd);

  //   return () => {
  //     window.removeEventListener('touchend', handleTouchEnd);
  //   };
  // }, []);

  const divClassName = isTouchedAtBottom ? 'touched' : '';
  // remove button
  const emailData = usersData?.find((item) => item?.email)

  return (
    <div className="EditAbleTable">
      <Paper sx={{ width: '100%', overflow: 'auto', boxShadow: 'none' }}>
        <TableContainer component={Paper}
          sx={{
            // "&.MuiPaper-root": { display: 'block', maxHeight: "100vh", overflowX: 'auto' },
            "&.MuiPaper-root": {
              // overflow: 'auto !important',
              overflow: rows.length >= 7 ? 'auto !important' : 'visible',
              // '&::-webkit-scrollbar': {
              //   width: '0px',
              //   height: '0px',
              // },
              // '-ms-overflow-style': 'none',
              // 'scrollbar-width': 'none',
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#cbcbcb',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#f8f8f8',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f8f8f8',
              },
            },
            // "& .MuiTable-root": {display: 'block', width: '100%' }
            maxHeight: 440
          }}

        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headCells.map((item, i) => (
                  <TableCell style={{ minWidth: item.width, width: item.width, maxWidth: item.width, }}>{item.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
            // sx={{
            //   "&.MuiTableBody-root": { display: 'block', width: '100vw', maxHeight: "50vh", overflowX: 'auto' },
            // }}
            >

              {
                inputList?.map((x, i) => {
                  return (
                    <>
                      {
                        rows?.map((row, index) => (
                          <TableRow key={i}>
                            <EditAbleTableCell
                              cell={row.cell}
                              RemoveButton={
                                (usersData.length > 1 && emailData != '') &&
                                <div className="EditAbleTableRemoveCell" style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                  <Button onClick={() => handleremove(index)} className="EditAbleTableRemove">Remove</Button>
                                </div>
                              }
                            />
                          </TableRow>
                        ))
                      }
                    </>

                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className={divClassName}> */}
        {rows.length > 0 &&
          <div div className="EditableTableFooter">

            <Button onClick={handleAddNew} className="EditAbleTableAddMore" sx={{ fontWeight: 600 }}><AddRoundedIcon /> ADD MORE </Button>
            <Buttons
              label={SubmitButtonLabel}
              buttonColor="#0450E1"
              border="#0450E1 1px solid"
              color="#fff"
              width={SubmitButtonWidth}
              height="32px"
              fontSize="14px"
              onClick={SubmitOnClick}
            />
          </div>
        }
        {/* </div> */}
      </Paper>
    </div >
  );
}

export default EditAbleTable;
