import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery, useGetUsersByUserTypesMutation } from "../../../redux/features/users/userApi";
import {
  useGetCourseDetailsQuery,
  useAddCourseTeachersMutation,
  useDeleteGroupUsersMutation,
  useGetCourseTeachersSearchResultMutation,
  useDeleteCourseTeachersMutation,
  useUpdateTeacherTypeMutation
} from '../../../redux/features/classes/classApi';
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import AddTeachers from './AddTeachers';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DraggableDialog from "../../../components/Dialog/Dialog";
import Crown from '../../../assets/imgs/vip-crown-fill.svg';
import Filter from './Filter';
import { toTitleCase } from "../../../utils/helpers";
import Tooltip from "../../../components/Form/Tooltip/Tooltip";
import { truncateText } from "../../../hooks/HelperFunctions";

const TeachingTeam = ({ courseDetails, section_id }) => {
  const { course_id } = useParams()

  const [addCourseTeachers, addCourseTeachersOptions] = useAddCourseTeachersMutation();
  const [removeCourseTeachers, removeCourseTeachersOptions] = useDeleteCourseTeachersMutation()
  const [updateTeacherType, updateTeacherTypeOptions] = useUpdateTeacherTypeMutation()
  const { data, isLoading, isError, error } = useGetCourseDetailsQuery(course_id, { refetchOnMountOrArgChange: true });


  useProtected(error);
  useProtected(addCourseTeachersOptions.error);
  useProtected(updateTeacherTypeOptions.error);
  useProtected(removeCourseTeachersOptions.error);

  const [getSearchedUserList, options] = useGetCourseTeachersSearchResultMutation();
  const [getUsersByUserTypes, getUsersByUserTypesOptions] = useGetUsersByUserTypesMutation();


  const [rows, setRows] = useState([]);
  const [removeId, setRemoveId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [courseUsers, setCourseUsers] = useState([]);
  // const [courseDetails, setCourseDetails] = useState({})
  const [courseUsersToUpdate, setCourseUsersToUpdate] = useState([]);
  const [userTypesDatas, setUserTypesDatas] = useState([])
  const [allTeachers, setAllTeachers] = useState([])
  const [primaryTeacher, setPrimaryTeacher] = useState({})

  const [OpenAddUsers, setAddUsers] = useState(false);
  const handleOpenAddUsers = () => {
    setAddUsers(true)
    getUsersByUserTypesData()
  };
  const handleCloseAddUsers = () => {
    setAddUsers(false)
    setCourseUsers([])
    setCourseUsersToUpdate([])
  };

  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event, id) => {
    updateTeacher(event.target.value, id)
  };

  const getUsersByUserTypesData = async () => {
    const result = await getUsersByUserTypes({ userTypes: "faculty,teacher", data: "" })
    if (result?.data?.body?.data) {
      setUserTypesDatas(result?.data?.body?.data)
    }
  }

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };


  const removeButtonHandler = (id) => {
    setRemoveId(id)
    handleOpenDelete()
  }
  //======= Delete dialog ===== //


  const Type = [
    {
      label: 'Primary Teacher',
      value: 'primary_teacher',
    },
    {
      label: 'Teaching Assistant',
      value: 'teaching_assistant',
    },
    {
      label: 'Substitute Teacher',
      value: 'substitute_teacher',
    }
  ];

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Email", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User type", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "20%", textAlign: "left", },

  ];
  const updateTeacher = async (value, teacherId) => {
    const data = {
      teacherType: value,
      id: teacherId
    };
    let result = await updateTeacherType({ ...data, course_id });
    if (!result?.data?.error) {
      toast.success('Teacher type updated successfully');
    }
  }


  //============= set datas on the table part start =================//
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item, index) => {
        let roleTitle = ''
        if (item?.firstName && item?.lastName) {
          roleTitle = <div className='IconCell'>
            {truncateText(`${item.firstName} ${item.lastName}`, 35)}
            <Tooltip
              TooltipText="Primary teacher"
              Tooltip={<img src={Crown} />}
            />
          </div>
        } else {
          roleTitle = (item?.name ? truncateText(item?.name, 35) : "N/A")
        }

        return ({
          cell: [
            { textBold: roleTitle },
            { textBold: (item?.userUniqueId || item?.userUniqueID) || <span style={{ color: '#919094' }}>No data available</span> },
            { text: item?.email || 'N/A' },
            { textBold: (toTitleCase(item?.teacherType || item?.userType)) || <span style={{ color: '#919094' }}>No data available</span>, },

            {
              Button: index === 0 ? false : true, buttonLabel: 'Remove', buttonColor: '#FFFFFF', buttonBorder: '#E4E8EC 1px solid',
              buttonTextcolor: '#5C677D', iconStatus: true, buttonIcon: <DownloadForOfflineOutlinedIcon />,
              onClick: index === 0 ? () => removeButtonHandlerFake() : () => removeButtonHandler(item?.id)
            },
          ]
          //======= Action Date End===== //
        })
      });
    } else {
      return [];
    }
  };


  useEffect(() => {
    const section_idData = courseDetails?.sections?.find((dataId) => dataId?.id === section_id)
    setAllTeachers(section_idData?.teachingTeam)
    setPrimaryTeacher(section_idData?.primaryTeacher)
  }, [courseDetails, section_id])
  useEffect(() => {
    if (primaryTeacher) {
      setRows(convertDataToCellRows([primaryTeacher]));
    }
  }, [primaryTeacher])

  useEffect(() => {
    if (allTeachers) {
      setRows(convertDataToCellRows([primaryTeacher, ...allTeachers]));
    }

  }, [allTeachers])

  //============= set datas on the table part end =================//




  // console.log(primaryTeacher, 153)
  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    const result = await getSearchedUserList({ id: course_id, term: searchTerm });
    console.log({ result })
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  }


  // Add Teacher //
  const [openAddTeacherDrawer, setOpenAddTeacher] = useState(false);
  const handleAddTeacherDrawerOpen = () => {
    setOpenAddTeacher(true);
  };
  const handleCloseAddTeacherDrawer = () => {
    setOpenAddTeacher(false);
  };


  // Filter Drawer //
  const [OpenFilter, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleDrawerCloseFilter = () => {
    setOpenFilter(false);
  };

  //================================ add teacher part start ===========================//
  const addUsersToCourse = async () => {
    let data = {
      teachingTeam: courseUsersToUpdate
    };
    const result = await addCourseTeachers({ courseId: course_id, sectionId: section_id, data });
    // console.log(data, 342)
    if (result?.data?.body) {
      if (data?.teachingTeam?.length > 0) {
        toast.success('Teacher added successfully');
      }
      setAddUsers(false)
      setCourseUsers([])
      setCourseUsersToUpdate([])
    } else {
      toast.error(result?.data?.message);
    }
  }
  //================================ add teacher part end ===========================//

  //================================ delete teacher part start ===========================//
  const removeButtonHandlerFake = () => {
    return
  }
  const deleteTeacherHandler = async () => {
    let id = removeId

    let result = await removeCourseTeachers({ courseId: course_id, sectionId: section_id, id })

    if (result?.data) {
      toast.success('Teacher removed');
    } else {
      toast.error(result?.data?.message);
    }
    handleCloseDeleteDialog()
    setRemoveId('')
  }
  //================================ delete teacher part end ===========================//


  return (
    <>
      <div className="TableSection">
        <Table
          title="Teaching team"
          tableHeader={true}
          addLabel="Add Teacher"
          // icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleOpenAddUsers}
          footer={true}
          RowsPerPage={20}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={false}
          searchBoxPlaceholder="Search users"
          isDataLoading={isLoading || options?.isLoading}

          emptyTableTitle="No teacher added yet"
          emptyTableNote="No items available"
          btnLabel="ADD TEACHER"
          showButton={true}
          onClickEmptyCard={handleOpenAddUsers}

          FilterButtonDrawer={false}
          onClickFilterButton={handleFilterDrawerOpen}

        />
      </div>


      {/* Add Teacher */}
      <SideDrawer
        open={OpenAddUsers}
        handleDrawerClose={handleCloseAddUsers}
        title="Add Teachers"
        sideOpen="right"
        BtnTextcolor={courseUsers?.length === 0 ? "#b5b5b5" : "#fff"}
        BtnColor={courseUsers?.length === 0 ? "#e6e6e6" : "#0450E1"}
        ButtonLabel={`Add ${courseUsers?.length || 0} ${courseUsers?.length > 1 ? 'Teachers' : 'Teacher'}`}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        disabledBtn={courseUsers?.length === 0 ? true : false}
        clickSaveButton={(e) => addUsersToCourse()}
        padding="0"
        body={
          <>
            <AddTeachers
              courseUsers={courseUsers}
              courseUsersToUpdate={courseUsersToUpdate}
              setCourseUsers={setCourseUsers}
              addCourseUsersOptions={addCourseTeachersOptions}
              setCourseUsersToUpdate={setCourseUsersToUpdate}
              userTypesDatas={userTypesDatas}
              primaryTeacher={primaryTeacher}
              allTeachers={allTeachers}
            />
          </>
        }
      />


      {/* Filter drawer */}
      <SideDrawer
        open={OpenFilter}
        handleDrawerClose={handleDrawerCloseFilter}
        title="Course Settings"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <Filter />
          </>
        }
      />

      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={(e) => deleteTeacherHandler()}
        title="Remove teacher from course?"
        body={<div>Are you sure you want to remove this teacher from this course?</div>}
        ModalFooter={true}
        actionButton="REMOVE"
      />

    </>
  );
};

export default TeachingTeam;
