import { api } from '../../api/apiSlice';

const classApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubjectList: builder.query({
      query: () => ({
        url: '/subject-area/find-all',
      }),
      providesTags: ['Subjects'],
    }),
    getSubjectDetails: builder.query({
      query: (id) => ({
        url: `/subject-area/getSingle/${id}`,
      }),
      providesTags: ['Subject'],
    }),
    createSubject: builder.mutation({
      query: (data) => ({
        url: '/subject-area/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Subjects'],
    }),
    updateSubject: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/subject-area/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Subject', 'Subjects'],
    }),
    deleteSubject: builder.mutation({
      query: (id) => ({
        url: `/subject-area/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Subject', 'Subjects'],
    }),
    getSubjectAreaSearchResult: builder.mutation({
      query: (data) => ({
        url: '/subject-area/search',
        method: 'POST',
        body: data,
      })
    }),
    checkSubjectArea: builder.mutation({
      query: (data) => ({
        url: '/subject-area/check-subject-area',
        method: 'POST',
        body: data,
      })
    })
  }),
});

export const {
  useGetSubjectListQuery,
  useGetSubjectDetailsQuery,
  useCreateSubjectMutation,
  useUpdateSubjectMutation,
  useDeleteSubjectMutation,
  useGetSubjectAreaSearchResultMutation,
  useCheckSubjectAreaMutation
} = classApi;
