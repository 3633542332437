import React, { useEffect, useState } from 'react'
import Table from '../../../../components/Table/Table'
import DraggableDialog from '../../../../components/hooks/Dialog';
import { capitalizeWords } from '../../../../hooks/HelperFunctions';

const Accounts = ({ accountDatas }) => {
    const [rows, setRows] = useState([])
    const [deleteDialog, setDeleteDialog] = useState(false)

    const onClick = () => {
        alert('onclick working fine!');
    };

    const deleteFunc = () => {
        setDeleteDialog(true)
    }

    useEffect(() => {
        if (accountDatas?.length > 0) {

            setRows(convertDataToCellRows(accountDatas))
        }
        // console.log(accountDatas, 345)
    }, [accountDatas])

    const headCells = [
        {
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: '50%',
            textAlign: 'left',
        },
        {
            numeric: false,
            disablePadding: true,
            label: 'Role',
            width: '40%',
            textAlign: 'left',
        },
        {
            numeric: false,
            disablePadding: true,
            label: '',
            width: '10%',
            textAlign: 'left',
        },
    ]

    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => (
                {
                    cell: [
                        { textBold: item?.accountName },
                        { textBold: capitalizeWords(item?.userType) },
                        // { ActionButton: true, ActionButtonLabel: 'Action', },
                    ],
                    //======= Action Date Start===== //
                    // action: [
                    //     { label: 'Edit', onClick: onClick },
                    //     { label: 'Delete', onClick: deleteFunc },
                    // ],
                    //======= Action Date End===== //
                }

            ));
        } else {
            return [];
        }
    };



    return (
        <div>
            <div className="TableSection">
                <Table
                    headCells={headCells}
                    rows={rows}
                    Checkbox={false}
                    footer={true}
                    innerHeader={false}
                    innerTitle="Table Name"
                    AddButtonInner={false}
                    addLabelInner="Add Payment"
                    InnerButtonColor="#004FE0"
                    InnerButtonTextColor="#fff"
                    // InnderButtonIcon={<AddRoundedIcon />}
                    //onClickInnerButton={onClickInnerButton}
                    showSearchBox={false}
                    AddButtonOutLine={false}
                    //onClickOutlineButton={handleOpenAttendanceEntry}
                    OutLineButtonLabel="Add entry"
                    CheckboxDeleteModal={false}
                    CheckboxDelete={false}
                    DeleteText="REMOVE FROM GROUP"
                    //DeleteButtonOnclick={DeleteButtonOnclick}
                    RowsPerPage={10}

                    emptyTableTitle="No users added yet"
                    emptyTableNote="No items available"
                    btnLabel="ADD USERS"
                    showButton={false}
                    btnLink="/invited-users"
                    //onClickEmptyCard={handleOpenAttendanceEntry}

                    FilterButtonDrawer={false}
                    //onClickFilterButton={onClickFilterButton}

                    ExportButtonInner={true}
                //OnclickExport={OnclickExport}

                />
            </div>

            {/* Delete Group*/}
            <DraggableDialog
                openDialog={deleteDialog}
                handleCloseDialog={() => setDeleteDialog(false)}
                // handleConfirmCloseDialog={deleteSchoolLogoIconOperation}
                title="Delete logo icon?"
                body={<>Are you sure you want to delete this logo icon?<br />Once you delete it, you can't get it back.</>}
                ModalFooter={true}
                actionButton="DELETE"
                cancelButton="CANCEL"
            />
        </div>
    )
}

export default Accounts