import React, { useState } from 'react';
import Table from '../../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery,
  useGetUserListQuery,
  useGetUsersByRoleQuery
} from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../../utils/helpers';
import { toast } from 'react-toastify';
import { truncateText } from '../../../../hooks/HelperFunctions';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import Filter from './Filter';

import { useGetRoleListQuery } from '../../../../redux/features/roles/roleApi';



const SelectGroups = ({
  editeSeletedData,
  messageObject,
  setAddedUsersToUpdate,
  setAddedUsers,
  addedUsersToUpdate,
  addedUsers,
  selectUsers,
  handleCloseSelectUsers,
  selectedList,
  selectedListJust,
  renderFullItem = false
}) => {
  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });

  useProtected(error);

  const [getSearchedUserList, options] = useGetSearchResultMutation();

  const [rows, setRows] = useState([]);
  const [seletectedAddUsers, setSeletectedAddUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {

        return ({
          item: item,
          cell: [
            {
              Check: true,
              checkBoxColor: 'secondary',
              width: "48px",
              padding: "0",
              user_id: item._id,
              checked: true,
              user: {
                id: item?._id,
                name: `${item?.firstName} ${item?.lastName}`,
                // name: truncatedName,
                email: item?.email,
                status: item?.status,
                role: item?.role,
                userUniqueID: item?.userUniqueID,
                gender: item?.gender,
                schoolId: item?.schoolId,
                firstName: item?.firstName || "",
                lastName: item?.lastName || "",
                middleName: item?.middleName || "",
                phone: item?.phone || "",
              }
            },

            { text: "Group 1" },
            { text: '50' },
          ],
        })
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setSeletectedAddUsers(data.body.data)
    }
  }, [data, isLoading, isError]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Group Name',
      width: '60%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Active Students',
      width: '30%',
      textAlign: 'left',
    },

  ];
  //-----search part start------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "", userType: ['student'] });
    searchDataShowing(result)
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm, userType: ['student'] });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-----search part end------//


  useEffect(() => {
    let newUsers = []
    addedUsers?.map((users) => {
      let foundUser = seletectedAddUsers.find((user) => user?._id === users || user?.id === users)
      if (foundUser) {
        newUsers.push({
          id: foundUser?._id,
          name: `${foundUser?.firstName} ${foundUser?.lastName}`,
          email: foundUser?.email,
          status: foundUser?.status,
          role: foundUser?.role,
          userUniqueID: foundUser?.userUniqueID,
          gender: foundUser?.gender,
          schoolId: foundUser?.schoolId,
          firstName: foundUser?.firstName || "",
          lastName: foundUser?.lastName || "",
          middleName: foundUser?.middleName || "",
          phone: foundUser?.phone || ""
        })
      }
    })
    setAddedUsersToUpdate(newUsers)
  }, [addedUsers, seletectedAddUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the user to the array
      setAddedUsers(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setAddedUsers(prevUsers => prevUsers.filter(user => user?.id !== item[0].user?.id));
    }
  }




  // FILTER //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
  const [roles, setRoles] = useState([])

  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };


  const [streams, setStream] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const handleCloseFilterDrawer = async () => {
    setStream([])
    setUserTypes([])
    setOpenFilter(false);
    setRows(convertDataToCellRows(data?.body?.data));
  };

  const filterHandler = async () => {
    //const result = await searchAttendance({ term: searchTerm, date: filterDate, userType: userTypes, streams: streams });
    // console.log(result?.data?.body?.data);
    // if (result?.data?.body?.data) {
    //   setRows(convertDataToCellRows(result?.data?.body?.data));
    // }
    setOpenFilter(false);
  }


  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          showSearchBox={true}
          handleCheckItem={setAddedUsers}
          selectedCheckList={addedUsers}
          handleRowClick={handleRowClick}
          customCellObjectId="user_id"
          searchBoxPlaceholder="Search users"
          RowsPerPage={20}
          CheckboxDelete={true}
          FilterButtonDrawer={true}
          onClickFilterButton={handleFilterDrawerOpen}
          SelectedCounter={true}

          SelectedValueCounter={`${addedUsers?.length} students selected`}
        />
      </div>

      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } users`}
      />


      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCloseFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        BtnColor="#0450E1"
        BtnTextcolor="#fff"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}
        BackArrow={true}

        body={
          <>
            <Filter
              label="Status"
              // checkboxesData={status}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              roles={roles}
              attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
              attendanceCodeOptions={attendanceCodeOptions}

            />
          </>
        }
      />


    </>
  );
};

export default SelectGroups;
