import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import NewMessage from './NewMessage';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';

import DraggableDialog from '../../../components/hooks/Dialog';
import { useAddMessageMutation, useDeleteMessageMutation, useGetMessageCategoriesQuery, useGetMessageSearchResultMutation, useGetMessagesQuery, useUpdateMessageMutation } from "../../../redux/features/messages/meassageApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Backdrop, CircularProgress } from "@mui/material";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { truncateText } from "../../../hooks/HelperFunctions";


const Messages = () => {
  const [openLodaer, setOpenLodaer] = useState(false);
  const {
    data: messageCategoriesData,
    isLoading: messageCategoriesIsLoading,
    isError: messageCategoriesIsError,
    error: messageCategoriesError
  } = useGetMessageCategoriesQuery()

  const {
    data,
    isError,
    isLoading,
    error
  } = useGetMessagesQuery(undefined, { refetchOnMountOrArgChange: true });

  const [addMessage, addMessageOptions] = useAddMessageMutation()
  const [updateMessage, updateMessageOptions] = useUpdateMessageMutation()
  const [deleteMessage, deleteMessageOptions] = useDeleteMessageMutation()
  const [getMessageSearchResult, getMessageSearchResultoptions] = useGetMessageSearchResultMutation();


  useProtected(error);
  useProtected(messageCategoriesError);
  useProtected(addMessageOptions.error);
  useProtected(updateMessageOptions.error);
  useProtected(deleteMessageOptions.error);


  const user = useSelector(state => state.auth.user);

  const [getSearchedUserList, options] = useGetSearchResultMutation();
  const [rows, setRows] = useState([]);
  const [deleteId, setDeleteId] = useState('')
  const [messageCategoryOptions, setMessageCategoryOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [messageCategories, setMessageCategories] = useState([])
  const [basicInfoSectionExpanded, setBasicInfoSectionExpanded] = useState(true)
  const [basicMessageSectionExpanded, setBasicMessageSectionExpanded] = useState(false)
  const [editId, setEditId] = useState('')
  const [emailSelected, setEmailSelected] = useState(false)
  const [sortMessages, setSortMessages] = useState([])
  const [asc, setAsc] = useState(true)
  const [messageObject, setMessageObject] = useState({
    messageName: "",
    sender: "",
    category: "",
    categoryName: "",
    type: "",
    content: "",
    status: "",
    to: [],
    toGroups: [],
    schoolId: "",
    createdBy: {},
    messageEmailFrom: "",
    messageEmailSubject: "",
    messageEmailContent: "",
    messageEmailAttachments: [],
    messageSmsFrom: "",
    messageSmsContent: "",
    messageVoiceFrom: "",
    messageVoiceRecording: "",
    messageVoiceContent: "",
    messageCallToRecordingContent: "",
    sentDate: ""
  })

  const [errors, setErrors] = useState({
    messageName: "",
    category: "",
    messageSmsContent: "",
    messageVoiceContent: "",
    messageCallToRecordingContent: "",
    messageVoiceRecording: "",
    messageEmailFrom: "",
    messageEmailSubject: "",
    messageEmailContent: "",
    to: "",
    messageContent: "",
    messageAttachment: "",

  })

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setSortMessages(data.body.data)
    }
    // setIsSearchResult(false)
  }, [data]);

  const statustData = [
    { value: 'draft', statuscolor: "#EEECFF", select: "Draft", textColor: "#7266FB" },
    { value: 'sent', statuscolor: "#EDF7EA", select: "Sent", textColor: "#66BD50" },
    { value: 'delete', statuscolor: "#FCE7E9", select: "Deleted", textColor: "#E63946" },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {

        let messageType = ''
        if (item?.messageEmailEnabled) messageType += "Email, "
        if (item?.messageSmsEnabled) messageType += "SMS, "
        if (item?.messageVoiceEnabled) messageType += "Voice, "
        // if (item?.messageVoiceContent != "" || item?.messageVoiceRecording != "" || item?.messageCallToRecordingContent != "") messageType += "Voice, "
        messageType = messageType.trim().replace(/^,|,$/g, '')

        const dateObject = new Date(item?.createdAt);
        dateObject.setMinutes(dateObject.getMinutes());
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const estFormattedDate = dateObject.toLocaleDateString('en-US', options);


        let numberOfUsersInGroup = item?.toGroups?.reduce((total, group) => {
          if (group.users) {
            return total + group.users.length;
          }
          return total;
        }, 0);

        let sentTo = ""
        if (item?.to?.length === numberOfUsersInGroup && item?.toGroups?.length === 1) {
          sentTo = `${item?.toGroups[0].name}`
        } else if (item?.to?.length == 1 && item?.toGroups?.length == 0) {
          sentTo = `${item?.to[0].name}`
        } else if (item?.to?.length > 1 && item?.toGroups?.length >= 0) {
          sentTo = `${item?.to?.length} recipients`
        } else if (item?.to?.length == 0 && item?.toGroups?.length == 0) {
          sentTo = `0 recipient`
        }

        let actions = []
        if (item?.status === 'draft') {
          actions.push({ label: 'KEEP EDITING', onClick: (e) => handleEditMessage(item?._id) })
        } else if (item?.status === 'sent') {
          actions.push({ label: 'DETAILS', onClick: (e) => handleShowMessageDetail(item?._id) })
        }
        actions.push({ label: 'DELETE', onClick: (e) => handleDeleteMessage(item?._id) })

        return ({
          cell: [
            { text: truncateText(item?.messageName, 20) || "N/A", },
            { linkText: sentTo || "N/A", linkTextStatus: true, link: `/messages-details/${item?._id}` },
            { text: item?.category || "N/A", },
            { text: messageType || "N/A", },
            { text: estFormattedDate || "N/A", },
            {
              Status: true,
              background: statustData?.find(status => status.value === item?.status).statuscolor,
              color: statustData?.find(status => status.value === item?.status).textColor,
              label: statustData?.find(status => status.value === item?.status)?.select,
            },
            { ActionButton: true, ActionButtonLabel: "Action", ActionButtonsInlineStyles: { fontWeight: 600, height: '28px' } },
          ],
          action: actions
        })
      })

    } else {
      return [];
    }
  }

  const handleEditMessage = (id) => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setEditId(id)
    handleCreateDrawerOpen()
  }
  const handleShowMessageDetail = (id) => {
    navigate(`/messages-details/${id}`)
  }
  const handleDeleteMessage = (id) => {
    setDeleteId(id)
    handleOpenDelete()
  }
  const confirmMessageDelete = async () => {

    let result = await deleteMessage(deleteId)
    if (result?.data?.body?.data) {
      toast.success("Message deleted")

    } else {
      toast.error("Something went wrong")
    }
    handleCloseDeleteDialog()
  }

  useEffect(() => {
    if (!messageCategoriesIsLoading && !messageCategoriesIsError) {
      setMessageCategories(messageCategoriesData?.body?.data)
    }
  }, [messageCategoriesIsLoading, messageCategoriesIsError, messageCategoriesData])

  useEffect(() => {
    setMessageCategoryOptions(getPreparedArray(messageCategories || []))
  }, [messageCategories])

  const getPreparedArray = (data) => {
    return data?.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  };

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        setOpenLodaer(true)
        await handleResetSearch()
      }, 1800);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getMessageSearchResult({ term: "" });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    // setOpenLodaer(true)
    setPageNumberFromOutSide(true)
    const result = await getMessageSearchResult({ term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {

    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
    setOpenLodaer(false)
  }
  // =========== search part start ====================== //




  // Drawer //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const handleCreateDrawerOpen = () => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenCreate(true);
  };
  const handleCloseCreateDrawer = () => {

    setErrors({})
    setErrors({ messageContent: "" })
    resetMessageObject()
    setOpenCreate(false);
  };


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    if (!isTheActionPermitted('deleteMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //



  ///=================================== sorting part start =============//
  const ascendingOrDescendingSortingByCategory = (field) => {
    let sortedMessages = [...sortMessages]
    if (asc) {
      sortedMessages.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedMessages.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedMessages));
    setAsc(!asc)
  }

  const ascendingOrDescendingSortingByCreatedAt = (field) => {
    let sortedMessages = [...sortMessages]
    if (asc) {
      sortedMessages.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedMessages.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedMessages));
    setAsc(!asc)
  }
  ///=================================== sorting part end =============//

  const headCells = [
    { id: "message", numeric: false, disablePadding: true, label: "Message Name", width: "25%", textAlign: "left", },
    { id: "to", numeric: false, disablePadding: true, label: "To", width: "25%", textAlign: "left", },
    { id: "Category", numeric: false, disablePadding: true, label: "Category", width: "20%", textAlign: "left", sort: "sort", headerOnClick: () => ascendingOrDescendingSortingByCategory('category'), },
    { id: "type", numeric: false, disablePadding: true, label: "Type", width: "10%", textAlign: "left", },
    { id: "date", numeric: false, disablePadding: true, label: "Created Date", width: "10%", textAlign: "left", sort: "sort", headerOnClick: () => ascendingOrDescendingSortingByCreatedAt('createdAt') },
    { id: "status", numeric: false, disablePadding: true, label: "Status", width: "5%", textAlign: "left", },
    { id: "action", numeric: false, disablePadding: true, label: "", width: "5%", textAlign: "left", },

  ];

  const checkBasicFormField = async () => {
    let errorObject = { ...errors }
    let error = 0
    if (messageObject.messageName === "") {
      errorObject = { ...errorObject, messageName: "Enter a message name" }
      error++
    } else {
      errorObject = { ...errorObject, messageName: "" }
    }
    if (messageObject.category === "") {
      errorObject = { ...errorObject, category: "Select a category" }
      error++
    } else {
      errorObject = { ...errorObject, category: "" }
    }
    if (messageObject.to.length === 0) {
      errorObject = { ...errorObject, to: "Select at least one user" }
      error++
    } else {
      errorObject = { ...errorObject, to: "" }
    }
    setErrors({ ...errors, ...errorObject })

    return error > 0 ? false : true
  }

  const completeBasicInfoHandler = async () => {
    let isValidBasicForm = await checkBasicFormField()
    if (!isValidBasicForm) {
      toast.error("Please fill all the required fields")
      return false
    }
    setBasicInfoSectionExpanded(false)
    setBasicMessageSectionExpanded(true)
  }
  const saveAsDraftHandler = async () => {
    let data = { ...messageObject, status: 'draft' }
    if (messageObject.messageName === '') {
      toast.error('Please enter a message name to save as draft')
      return false
    }

    data.messageEmailEnabled = (emailSelected) ? true : false
    data.messageSmsEnabled = (messageObject?.messageSmsContent !== '') ? true : false
    data.messageVoiceEnabled = data.messageVoiceEnabled = (messageObject?.messageVoiceContent !== '' || messageObject?.messageVoiceRecording !== '' || messageObject?.messageCallToRecordingContent != '') ? true : false

    setErrors({ ...errors, messageContent: "" })
    let result = await addMessage(data)
    if (result?.data && result?.data?.body?.data) {
      toast.success('Saved as draft')
    }
    resetMessageObject()
    setOpenCreate(false)


  }
  const resetMessageObject = () => {
    setMessageObject({
      messageName: "",
      sender: "",
      category: "",
      categoryName: "",
      type: "",
      content: "",
      status: "",
      to: [],
      toGroups: [],
      schoolId: "",
      createdBy: {},
      messageEmailFrom: "",
      messageEmailSubject: "",
      messageEmailContent: "",
      messageEmailAttachments: [],
      messageSmsFrom: "",
      messageSmsContent: "",
      messageVoiceFrom: "",
      messageVoiceRecording: "",
      messageVoiceContent: "",
      messageCallToRecordingContent: "",
      sentDate: ""
    })
    setBasicInfoSectionExpanded(true)
    setBasicMessageSectionExpanded(false)
    setEmailSelected(false)
    setEditId('')
  }
  const sendMessageHandler = async () => {
    let data = { ...messageObject, status: 'sent' }

    if (messageObject?.messageSmsContent == '' && (messageObject?.messageVoiceContent == '' && messageObject?.messageVoiceRecording == '' && messageObject?.messageCallToRecordingContent == '') && messageObject?.messageEmailContent == '') {
      setErrors({ ...errors, messageContent: "Please add at least one type of message" })
      return false

    } else {
      data.messageEmailEnabled = (emailSelected) ? true : false
      data.messageSmsEnabled = (messageObject?.messageSmsContent != '') ? true : false
      data.messageVoiceEnabled = (messageObject?.messageVoiceContent != '' || messageObject?.messageVoiceRecording != '' || messageObject?.messageCallToRecordingContent != '') ? true : false

      setErrors({ ...errors, messageContent: "" })
    }
    let findWithoutPhoneNumeberUsers = data?.to?.filter((users) => !users?.phone)
    if (findWithoutPhoneNumeberUsers?.length > 0 && data?.messageSmsEnabled && data?.messageSmsContent !== "") {
      const getNames = findWithoutPhoneNumeberUsers?.map(user => (`${user.firstName} ${user.lastName}`));
      const convertedUserName = getNames.join(', ')
      toast.warn(`Due to missing phone number, we can't send an SMS to ${convertedUserName}`, {
        autoClose: 5000
      })
      // console.log(getNames, 345, convertedUserName, data);
    }

    if (editId !== "") {
      data = { ...data, createdBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic }, updatedBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic } }
      let result = await updateMessage({ ...data, id: editId })

      if (result?.data && result?.data?.body?.data) {
        toast.success('Message sent')
      }
    } else {
      data = { ...data, createdBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic }, updatedBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic } }

      let result = await addMessage(data)
      if (result?.data && result?.data?.body?.data) {
        toast.success('Message sent')
      }
    }
    setEditId('')
    setBasicMessageSectionExpanded(false)
    resetMessageObject()
    setOpenCreate(false)
  }

  useEffect(() => {
    // console.log('message object: ', messageObject, 393)
    // console.log('message object: ', messageObject.to)
    if (messageObject.messageSmsContent !== "" || messageObject.messageVoiceContent !== "") {
      setErrors({ ...errors, messageContent: "" })
    }

    if (messageObject?.messageEmailAttachments?.length > 0) {

      const sizeLimitMB = 25;
      const totalSizeKB = messageObject?.messageEmailAttachments?.reduce((total, object) => total + object.size, 0);
      const totalSizeMB = totalSizeKB / 1024; // Convert total size to MB
      if (totalSizeMB > sizeLimitMB) {
        setErrors({ ...errors, messageAttachment: "The maximum size for total file uploads is 25.00 MB" })
      } else {
        setErrors({ ...errors, messageAttachment: "" })
      }
    }
  }, [messageObject])

  useEffect(() => {
    if (emailSelected) {
      setErrors({ ...errors, messageContent: "" })
    }
  }, [emailSelected])


  return (
    <>
      <div className="TableSection">
        <Table
          title={<span style={{ fontSize: '32px' }}>Messages</span>}
          tableHeader={true}
          addLabel="New Message"
          icon={<AddIcon fontSize="small" />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleCreateDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          SearchBoxTop={true}
          searchBoxPlaceholder="Search messages"
          isDataLoading={isLoading || options?.isLoading}
          RowsPerPage={20}
        />
      </div>


      {/* New Message */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="New Message"
        sideOpen="right"
        hideOuterButton={true}
        LeftButton={true}
        ButtonLeft="Save as draft"
        clickButtonLeft={(e) => saveAsDraftHandler()}
        clickButtonLeftCloseDrawer={false}
        ButtonLeftbackground="#f8f8f9"
        ButtonLeftBorder="#0450E1 1px solid"
        ButtonLeftTextColor="#1B1B1F"
        FooterWithoutTab={true}
        body={
          <>
            <NewMessage
              messageObject={messageObject}
              setMessageObject={setMessageObject}
              messageCategoryOptions={messageCategoryOptions}
              completeBasicInfoHandler={completeBasicInfoHandler}
              setErrors={setErrors}
              errors={errors}
              basicInfoSectionExpanded={basicInfoSectionExpanded}
              setBasicInfoSectionExpanded={setBasicInfoSectionExpanded}
              basicMessageSectionExpanded={basicMessageSectionExpanded}
              setBasicMessageSectionExpanded={setBasicMessageSectionExpanded}
              sendMessageHandler={sendMessageHandler}
              editId={editId}
              setEmailSelected={setEmailSelected}
              emailSelected={emailSelected}
              addMessageOptions={addMessageOptions}
              updateMessageOptions={updateMessageOptions}

            />
          </>
        }
      />

      {/* Delete Modal*/}
      {/* <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={confirmMessageDelete}
        title="Delete this message?"
        body={<div>Are you sure you want to delete this message? <br />Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      /> */}
      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={confirmMessageDelete}
        title="Delete this message?"
        body=<div>Are you sure you want to delete this message? <br />Once you delete it, you can't get it back.</div>
      />


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={getMessageSearchResultoptions.isLoading}
        open={openLodaer}
      // onClick={() => setPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
};

export default Messages;
