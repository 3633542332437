import React, { useEffect, useState } from 'react';
import TableSubHeader from '../../../../components/Table/TableSubHeader';
import FeesAndPlansListing from './FeesAndPlansListing';
import CouponsListing from './CouponsListing';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';
import { useGetUsersByRoleQuery } from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';


const FeesPlans = () => {

    const {
        data: studentsData,
        isError: studentsIsError,
        isLoading: studentsIsLoading,
        error: studentsError
    } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });

    useProtected(studentsError)

    const [studentList, setStudentList] = useState([])

    const [OpenDrawerCreatedNew, setOpenCreatedNew] = useState(false);
    const handleDrawerOpenCreatedNew = () => {
        setOpenCreatedNew(true);
    };
    const CloseDrawerCreatedNew = () => {
        setOpenCreatedNew(false);
    };
    const [openLodaer, setOpenLodaer] = useState(false);


    const [OpenDrawerCreateCoupon, setOpenCreateCoupon] = useState(false);
    const handleDrawerOpenCreateCoupon = () => {
        setOpenCreateCoupon(true);
    };
    const CloseDrawerCreateCoupon = () => {
        setOpenLodaer(false)
        setOpenCreateCoupon(false);
    };



    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (studentsData?.body?.data) {
            setStudentList(studentsData?.body?.data)
        }
    }, [studentsData])

    useEffect(() => {
    }, [studentList])

    const tabLabel = [
        {
            label: "Fees & Plans",
        },
        {
            label: "Coupons",
        },

    ]

    const tabPanel = [
        {
            id: 0,
            panel:
                <FeesAndPlansListing
                    OpenDrawer={OpenDrawerCreatedNew}
                    OpenEditFeesAndPlanDrawer={OpenDrawerCreatedNew}
                    setOpenCreatedNew={setOpenCreatedNew}
                    CloseDrawer={CloseDrawerCreatedNew}
                    EmptyCardOnClick={handleDrawerOpenCreatedNew}
                    studentList={studentList}
                />,
        },
        {
            id: 1,
            panel: <CouponsListing
                OpenDrawerCreateCoupon={OpenDrawerCreateCoupon}
                CloseDrawerCreateCoupon={CloseDrawerCreateCoupon}
                EmptyCardOnClick={handleDrawerOpenCreateCoupon}
                openLodaer={openLodaer}
                setOpenLodaer={setOpenLodaer}
            />,
        },
    ]

    return (
        <>
            <div className='settingsTableContainer'>
                <TableSubHeader
                    HeaderTitle={<div className='FlexBox'>Fees & Plans <Tooltip
                        TooltipText="Coupons can be usable only when attached with fees at the discount tab."
                        Tooltip={<HelpOutlineOutlinedIcon />}
                    /></div>}
                    Tabs={true}
                    tabLabel={tabLabel}
                    tabPanel={tabPanel}
                    TabValue={value}
                    TabHandleChange={handleChange}
                    AddButton={true}
                    AddButtonLabel={<div>{value === 0 && ("CREATE NEW")} {value === 1 && ("CREATE COUPON")} </div>}
                    AddButtonColor={value === 0 ? "#0450E1" : "#fff"}
                    BorderColor={value === 0 ? "#0450E1 1px solid" : "#E8EAED 1px solid"}
                    AddButtonTextColor={value === 0 ? "#fff" : "#33415C"}
                    AddOnClick={value === 0 ? () => handleDrawerOpenCreatedNew() : () => handleDrawerOpenCreateCoupon()}
                    Filter={false}
                    //FilterOnClick={FilterOnClick}
                    Border={true}

                />
            </div>

        </>
    );
};

export default FeesPlans;
