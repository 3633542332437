import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SwitchButtonIOS from '../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lime, purple, lightGreen } from '@mui/material/colors';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import dayjs from "dayjs";

// const theme = createTheme({
//   palette: {
//     success: lightGreen,
//   },
// });

const theme = createTheme({
  palette: {
    success: {
      main: '#0fa711',
    },
  },
});



function Communication(props) {
  let {
    communicationAbsentStudentActivity,
    setCommunicationAbsentStudentActivity,
    communicationAbsentStudentEmail,
    setCommunicationAbsentStudentEmail,
    communicationAbsentStudentMessage,
    setCommunicationAbsentStudentMessage,
    communicationAbsentStudentTime,
    setCommunicationAbsentStudentTime,
    communicationAbsentFacultyActivity,
    setCommunicationAbsentFacultyActivity,
    communicationAbsentFacultyActivityStatus,
    communicationAbsentFacultyTime, setCommunicationAbsentFacultyTime,
    communicationAbsentFacultyMessage, setCommunicationAbsentFacultyMessage,
    communicationAbsentFacultyEmail, setCommunicationAbsentFacultyEmail

  } = props;

  let isCondition =
    communicationAbsentFacultyActivityStatus
      ? ((communicationAbsentFacultyEmail === "false" && communicationAbsentFacultyMessage === "false") && communicationAbsentFacultyActivity === "true")
      : ((communicationAbsentStudentEmail === "false" && communicationAbsentStudentMessage === "false") && communicationAbsentStudentActivity === "true")


  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>

        <Grid item md={12} xs={12}>
          <div className="ABsentSwitchCol">
            <div className="AbsLeft">
              {/* <h1>Absent students</h1> */}
              {communicationAbsentFacultyActivityStatus ?
                <h1>Absent faculty</h1> : <h1>Absent students</h1>}
              {/* <h1>Absent students</h1> */}
              <p>Send notification to the <span style={{ display: 'inline' }}>{communicationAbsentFacultyActivityStatus ? "absent faculties" : "guardian/parents of absent students"}</span></p>
            </div>
            <div>
              <SwitchButtonIOS
                label={((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyActivity : communicationAbsentStudentActivity) === "true") ? "Enabled" : "Disabled"}
                BorderLine={true}
                handleChange={(e) =>
                  communicationAbsentFacultyActivityStatus ?
                    setCommunicationAbsentFacultyActivity((e.target.checked)
                      ? 'true' : 'false') :
                    setCommunicationAbsentStudentActivity((e.target.checked)
                      ? 'true' : 'false')}
                checked={((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyActivity : communicationAbsentStudentActivity) === "true") ? true : false}
                name="checkedB"
              />
            </div>
          </div>

        </Grid>

        {((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyActivity : communicationAbsentStudentActivity) === "true") && <Grid item md={12} xs={12}>
          <Typography className="AccTitle">
            Method
          </Typography>

          <CheckBox
            label="Email"
            color="success"
            checked={((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyEmail : communicationAbsentStudentEmail) === "true") ? true : false}

            onChange={(e) => communicationAbsentFacultyActivityStatus
              ? setCommunicationAbsentFacultyEmail((e.target.checked) ? 'true' : 'false')
              : setCommunicationAbsentStudentEmail((e.target.checked) ? 'true' : 'false')}
          />


          <CheckBox
            label="Sms"
            color="success"
            checked={((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyMessage : communicationAbsentStudentMessage) === "true") ? true : false}
            onChange={(e) => communicationAbsentFacultyActivityStatus
              ? setCommunicationAbsentFacultyMessage((e.target.checked) ? 'true' : 'false')
              : setCommunicationAbsentStudentMessage((e.target.checked) ? 'true' : 'false')}
          />


          {isCondition &&
            <Typography className="AccTitle"  >
              <span style={{ fontWeight: 400 }}>Please select an option</span>
            </Typography>}
        </Grid>}




        {((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyActivity : communicationAbsentStudentActivity) === "true") &&
          <Grid item md={6} xs={12}>
            <div className="AccTitleCont" style={{ marginTop: "-10px" }}>
              <div className="FieldLabel AccTitleSelect">Select time</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                  <TimePicker defaultValue={dayjs(`2022-04-17T${communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyTime : communicationAbsentStudentTime}`)} onChange={(e) => communicationAbsentFacultyActivityStatus
                    ? setCommunicationAbsentFacultyTime(`${e.$H}:${e.$m}`)
                    : setCommunicationAbsentStudentTime(`${e.$H}:${e.$m}`)} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </Grid>}

        {((communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyEmail : communicationAbsentStudentEmail) === "true") &&
          <Grid item md={12} xs={12}>
            <div className='MediaLibPanelInfoText'><InfoOutlinedIcon /> Email will be sent only on school days</div>
          </Grid>}






      </Grid >

    </ThemeProvider>

  );
};

export default Communication;
