import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../../../assets/css/filter.css'
import CheckBox from "../../../components/Form/CheckBox/CheckBox";
import { toTitleCase } from "../../../utils/helpers";
import { useGetClassLevelListQuery } from "../../../redux/features/classes/classApi";
import useProtected from "../../../hooks/useProtected";





const UsersFilter = (props) => {
    const { statuses, setStatuses, streams, setStream, userTypes, setUserTypes, roles, setClassLevel, classLevel } = props
    // const [expanded, setExpanded] = React.useState(true);

    // const handleChange = (panel1) => (event, newExpanded) => {
    //   setExpanded(newExpanded ? panel1 : false);
    // };
    const { data: classesData, isLoading: classesIsLoading, isError: classesIsError, error: classesError } = useGetClassLevelListQuery()

    const [classes, setClasses] = useState([])
    useProtected(classesError);

    //============== class_level part start ===================================//
    useEffect(() => {
        if (!classesIsLoading && !classesIsError) {
            setClasses(classesData?.body?.data)
        }

    }, [classesData, classesIsLoading, classesIsError])
    const showClassLevels = () => {
        let userTypeCheckboxes = []
        classes.map((cls) => {
            userTypeCheckboxes.push(<CheckBox
                label={cls?.name?.replace(/(^|\s)\w/g, (match) => match.toUpperCase())}
                value={cls?._id}
                checked={classLevel.includes(cls?._id)}
                onChange={(e) => changeClassLevels(e, cls?._id)}
            />)
        })
        console.log(userTypeCheckboxes, 345)
        return userTypeCheckboxes
    }
    const changeClassLevels = (e, value) => {
        let newClassLevels = [...classLevel]
        if (e.target.checked) {
            if (!classLevel.includes(value)) {
                newClassLevels.push(value);
                setClassLevel(newClassLevels)
            }
        } else {
            if (classLevel.includes(value)) {
                newClassLevels = classLevel.filter((level) => level !== value)
                setClassLevel(newClassLevels)
            }
        }

    }

    useEffect(() => {
        console.log(classLevel, 345)
    }, [classLevel])

    //============== class_level part end ===================================//

    const changeStatusHandler = (e, value) => {
        let newStatuses = [...statuses]
        if (e.target.checked) {
            if (!statuses.includes(value)) {
                newStatuses.push(value);
                setStatuses(newStatuses)
            }
        } else {
            if (statuses.includes(value)) {
                newStatuses = statuses.filter((status) => status !== value)
                setStatuses(newStatuses)
            }
        }

    }

    const changeUserTypeHandler = (e, value) => {
        let newUserTypes = [...userTypes]
        if (e.target.checked) {
            if (!userTypes.includes(value)) {
                newUserTypes.push(value);
                setUserTypes(newUserTypes)
            }
        } else {
            if (userTypes.includes(value)) {
                newUserTypes = userTypes.filter((stream) => stream !== value)
                setUserTypes(newUserTypes)
            }
        }

    }

    const showUserTypes = () => {
        let userTypeCheckboxes = []
        roles?.map((role) => {
            userTypeCheckboxes.push(<CheckBox
                label={toTitleCase(role.name)}
                checked={userTypes.includes(role.name)}
                onChange={(e) => changeUserTypeHandler(e, role.name)}
            />)
        })
        return userTypeCheckboxes
    }
    return (

        <div className="FilterMainCont">


            {/* ========================== status =========================================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Status
                    </Typography>
                    {(statuses?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{statuses?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>

                    <CheckBox
                        label="Active"
                        checked={statuses.includes('active')}
                        onChange={(e) => changeStatusHandler(e, 'active')}
                    />

                    <CheckBox
                        label="Inactive"
                        checked={statuses.includes('inactive')}
                        onChange={(e) => changeStatusHandler(e, 'inactive')}
                    />

                </AccordionDetails>
            </Accordion>


            {/* ==========================  User type ============================= */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        User type
                    </Typography>
                    {(userTypes?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{userTypes?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(roles?.length > 0) &&
                        showUserTypes()

                    }

                </AccordionDetails>
            </Accordion>
            {/* ==========================  class level ============================= */}

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        Class/Level
                    </Typography>
                    {(classLevel?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{classLevel?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {(classes?.length > 0) &&
                        showClassLevels()

                    }

                </AccordionDetails>
            </Accordion>


        </div>
    );
};

export default UsersFilter;
