import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from "../../../components/Form/TextField/TextField";
import SelectField from "../../../components/Form/SelectField/SelectField";
import { useGetCalenderDetailsQuery, useGetCalendersQuery } from "../../../redux/features/calender/calenderApi";

const AddCalendars = (props) => {

  const {
    editId,
    schoolYears,
    calenderObject,
    setCalenderObject,
    schoolYearOptions,
    calenderErrorObject,
    setCalenderErrorObject,
    duplicateId
  } = props;

  const {
    data: calenderDetails,
    isLoading: isCalenderDetailsLoading,
    isFetching: isCalenderDetailsFetching,
    error: calenderDetailsError,
  } = useGetCalenderDetailsQuery((editId || duplicateId), {
    skip: !(editId != "" || duplicateId != ""),
    refetchOnMountOrArgChange: true,
  });


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]

  const onChangeHandler = (val, e, fieldName) => {
    setCalenderObject({ ...calenderObject, [fieldName]: val })
    setCalenderErrorObject({ ...calenderErrorObject, [fieldName]: "" })
  }

  useEffect(() => {
    if (!isCalenderDetailsLoading && !isCalenderDetailsFetching && (editId != "" || duplicateId != "")) {
      let data = calenderDetails?.body?.data
      setCalenderObject({
        name: duplicateId ? `Copy of ${data?.name}` : data?.name,
        academicYearId: data?.academicYearId,
        createdBy: data?.createdBy
      })
    }
  }, [isCalenderDetailsLoading, isCalenderDetailsFetching, calenderDetails, editId, duplicateId])



  return (
    <>

      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <TextField
            label="Name"
            required
            CharAlignMid="CharAlignMid"
            placeholder="Enter name"
            value={calenderObject?.name}
            helperText={calenderErrorObject?.name}
            error={calenderErrorObject?.name == "" ? false : true}
            handleChange={(val, e) => onChangeHandler(val, e, 'name')}
            count={50}

          />
        </Grid>

        <Grid item md={12} xs={12}>
          <SelectField
            label="Academic Year"
            placeholder="Select"
            required
            FieldLabel={true}
            options={schoolYearOptions}
            selectedOption={calenderObject?.academicYearId}
            handleChange={(e) => onChangeHandler(e.target.value, e, 'academicYearId')}
            helperText={calenderErrorObject?.academicYearId}
            error={calenderErrorObject?.academicYearId == "" ? false : true}

          />
        </Grid>


      </Grid >

    </>
  );
};

export default AddCalendars;
