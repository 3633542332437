export const currencies = [
    {
        "label": "USD",
        "value": "USD",
    },
    {
        "label": "CAD",
        "value": "CAD",
    },
    {
        "label": "EUR",
        "value": "EUR",
    }
]