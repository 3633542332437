import React, { useEffect, useState } from "react";
import ModalsResponsiveFullScreen from '../../../components/Form/Modals/ModalsResponsiveFullScreen';
import TextField from "../../../components/Form/TextField/TextField";
import { validateFormData } from "../../../utils/form";
import { toast } from "react-toastify";
import { useAddMessageCategoriesMutation, useCheckCategoriesNameMutation, useGetMessageCategoriesByIdQuery, useUpdateMessageCategoriesMutation } from "../../../redux/features/messages/meassageApi";
import useProtected from "../../../hooks/useProtected";


const AddCategories = (props) => {


  const { openModal, handleClose, editEnable, id, name, setName } = props;
  const [addMessageCategories, addMessageCategoriesOptions] = useAddMessageCategoriesMutation()

  useProtected(addMessageCategoriesOptions.error)
  const { data: messageCategoriesDetails } = useGetMessageCategoriesByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const [updateMessageCategories] = useUpdateMessageCategoriesMutation();
  const [checkCategoryName, checkCategoryNameOptions] = useCheckCategoriesNameMutation()


  //states
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({
    name: ''
  });

  //=== geting single category data from api start===//
  useEffect(() => {
    if (messageCategoriesDetails) {
      const data = messageCategoriesDetails.body.data;
      setName(data?.name);
    }
  }, [messageCategoriesDetails, setName]);

  //=== geting single category data from api end ===//

  //===== adding categroy part start =====//
  const handleInputChange = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    setState(e.target.value);
  };

  // Check if category name already exists
  const checkNameExist = async (trimmedName) => {
    let checkResult = await checkCategoryName({ name: trimmedName });
    if (!checkResult?.data?.error) {
      setErrors({
        'name': 'Category name already exists',
      });
      return true;
    }
    return false;
  }

  //====applying create method ====//
  const handleAddCategoire = async () => {
    let trimmedName = name.trim();

    let categoryData = {
      name: trimmedName,
      description
    };

    const isValid = validateFormData(categoryData, setErrors, {
      name: 'Enter a new category'
    });
    if (!isValid) {
      return;
    }

    if (await checkNameExist(trimmedName)) {
      return false;
    }


    // const isValid = validateFormData(categoryData, setErrors, {
    //   name: 'Enter a new category'
    // });
    // if (!isValid) {
    //   return;
    // }

    //===== check Name already exist then use this code
    let checkResult = await checkCategoryName({ name: name })
    if (!checkResult?.data?.error) {
      setErrors({
        'name': 'Category name already exists',
      })
      return false
    }
    //=============================================== =====//

    let result = null;
    if (id) {
      result = await updateMessageCategories({ ...categoryData, id });
    } else {
      result = await addMessageCategories(categoryData);
    }

    if (result?.data) {
      if (id) {
        toast.success('Category updated');
      } else {
        toast.success('Category added');
      }
      setName('')
      setDescription('')
    } else {
      toast.error(result?.data?.message);
    }
    setErrors({
      'name': ''
    })
    handleClose();
  }
  const closeHandler = () => {
    setName('')
    setErrors({
      'name': ''
    })
    handleClose();

  }
  //===== adding categroy part end =====//

  return (
    // <Modals
    //   ModalTitle={editEnable ? "Edit Category" : "Add Category"}
    //   open={openModal}
    //   handleClose={handleClose}
    //   ModalFooter={true}
    //   ButtonClose="CANCEL"
    //   ButtonConfirm="SAVE"
    //   width="552px"
    //   textAlign="center"
    //   handleConfirm={handleAddCategoire}

    //   ModalBody={
    //     <>
    //       <TextField
    //         label="Category Name"
    //         validation={true}
    //         // required={true}
    //         name={"name"}
    //         value={name}
    //         handleChange={(val, e) => handleInputChange(e)(setName)}
    //         count={30}
    //         helperText={errors?.name}
    //         error={errors?.name}
    //       />
    //     </>
    //   }
    // />
    <ModalsResponsiveFullScreen
      ModalTitle={<span style={{ fontWeight: 600 }}>{editEnable ? "Edit Category" : "Add Category"}</span>}
      open={openModal}
      handleClose={closeHandler}
      ModalFooter={true}
      ButtonClose="CANCEL"
      ButtonConfirm="SAVE"
      width="552px"
      Height="110px"
      textAlign="center"
      BodyOverFlow="hidden"
      handleConfirm={handleAddCategoire}

      ModalBody={
        <div className="ModalSingleField">
          <TextField
            label="Category Name"
            validation={true}
            // required={true}
            name={"name"}
            value={name}
            handleChange={(val, e) => handleInputChange(e)(setName)}
            count={30}
            helperText={errors?.name}
            error={errors?.name}
          />
          <br />
        </div>
      }
    />
  );
};

export default AddCategories;
