import { api } from '../../api/apiSlice';

const calenderApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCalenders: builder.query({
            query: () => ({
                url: '/calendars/all',
            }),
            providesTags: ['Calenders'],
        }),
        addCalender: builder.mutation({
            query: (data) => ({
                url: '/calendars/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Calenders'],
        }),
        getCalenderDetails: builder.query({
            query: (id) => ({
                url: `/calendars/getSingle/${id}`,
            }),
            providesTags: ['Calender'],
        }),
        editCalender: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/calendars/${id}`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: ['Calenders', 'Calender'],
        }),
        deleteCalender: builder.mutation({
            query: (id) => ({
                url: `/calendars/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Calenders'],

        }),
        addSchedule: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/calendars/addSchedule/${id}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Calenders'],
        }),
        editSchedule: builder.mutation({
            query: ({ dataId, ...data }) => ({
                url: `/calendars/updateSchedule/${dataId}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Calenders', 'Calender'],
        }),
        deleteSchedule: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/calendars/removeSchedule/${id}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Calenders'],
        }),
        searchCalender: builder.mutation({
            query: (data) => ({
                url: '/calendars/search',
                method: 'POST',
                body: data,
            }),
        }),
        searchSchedule: builder.mutation({
            query: (data) => ({
                url: '/calendars/searchSchedule',
                method: 'POST',
                body: data,
            }),
        }),
        getCalenderCategories: builder.query({
            query: () => ({
                url: '/calendars/getCategories',
            }),
            providesTags: ['CalenderCategories'],
        }),
        addCalenderCategory: builder.mutation({
            query: (data) => ({
                url: '/calendars/addCategory',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['CalenderCategories'],
        }),
        editCalenderCalender: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/calendars/updateCategory/${id}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['CalenderCategories'],
        }),
        checkCalenderName: builder.mutation({
            query: (data) => ({
                url: '/calendars/checkAvailability',
                method: 'POST',
                body: data,
            }),
        }),
        checkCalenderScheduleName: builder.mutation({
            query: (data) => ({
                url: '/calendars/checkScheduleAvailability',
                method: 'POST',
                body: data,
            }),
        }),
        deleteCalenderCategory: builder.mutation({
            query: (id) => ({
                url: `/calendars/deleteCategory/${id}`,
                method: 'POST'
            }),
            invalidatesTags: ['CalenderCategories', 'Calenders', 'Calender'],
        }),
        checkCalenderCategoryName: builder.mutation({
            query: (data) => ({
                url: '/calendars/checkCategoryAvailability',
                method: 'POST',
                body: data,
            }),
        }),
        getCalenderCategoryDetails: builder.query({
            query: (id) => ({
                url: `/calendars/getSingleCategory/${id}`,
            }),
            providesTags: ['CalenderCategory'],
        })
    }),
});

export const {
    useGetCalendersQuery,
    useAddCalenderMutation,
    useAddScheduleMutation,
    useEditCalenderMutation,
    useEditScheduleMutation,
    useDeleteCalenderMutation,
    useDeleteScheduleMutation,
    useSearchCalenderMutation,
    useSearchScheduleMutation,
    useGetCalenderDetailsQuery,
    useCheckCalenderNameMutation,
    useGetCalenderCategoriesQuery,
    useAddCalenderCategoryMutation,
    useEditCalenderCalenderMutation,
    useDeleteCalenderCategoryMutation,
    useGetCalenderCategoryDetailsQuery,
    useCheckCalenderScheduleNameMutation,
    useCheckCalenderCategoryNameMutation,
} = calenderApi;