import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import {
  useGetSearchResultMutation,
  useGetUserListQuery,
  useGetSearchByRoleAndTermResultMutation,
  useGetUsersByRoleQuery
} from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";
import { truncateText } from "../../../hooks/HelperFunctions";
// import { generateTableRows } from './Functions'


const SelectPrimaryTeacher = ({ setTempTeacher, setTeacher, userTypesDatas }) => {

  // const { data, isError, isLoading, error } = useGetUsersByRoleQuery('teacher', { refetchOnMountOrArgChange: true });
  // useProtected(error);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState(false);

  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();

  const [totalItems, setTotalItems] = useState([]);

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "25%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "", textAlign: "left", },
  ];

  // const handleChangeRedio = (event, rowData) => {
  //   let data = JSON.parse(localStorage.getItem('teachersInfo'));
  //   let teachersInfoObject = JSON.parse(localStorage.getItem('teachersInfoObject'));
  //   let teacher = {}
  //   if (data.length > 0) {

  //     data.map((item, index) => {

  //       item.cell[0].selectedValue = (item.cell[0].RedioValue == event.target.value) ? true : false
  //       item.cell[0].handleChangeRedio = handleChangeRedio

  //       if (item.cell[0].RedioValue == event.target.value) {
  //         teacher = { name: item.cell[0].textBold, id: item.cell[0].RedioValue }
  //         let foundTeacher = teachersInfoObject?.find(teacher => teacher?._id === event?.target?.value)
  //         console.log(foundTeacher, 346)
  //         setTempTeacher(foundTeacher)
  //       }
  //     });
  //     setSelectedTeacher(event.target.value);
  //     // setTempTeacher(foundTeacher)
  //     console.log(data, 346)
  //     setRows(data);
  //     setTempArray(data)
  //   }
  // };

  //======================================    =============================================//
  const handleChangeRedio = (event) => {
    setSelectedTeacher(event.target.value);
  };

  useEffect(() => {
    if (selectedTeacher) {
      let findUsers = userTypesDatas?.find(
        (item) => item._id == selectedTeacher
      );
      setTempTeacher(findUsers)
      setRows(convertDataToCellRows(tempArray));
    }
  }, [selectedTeacher, userTypesDatas]);
  //======================================    =============================================//

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map(item => ({
        cell: [
          {
            textBold: (item.firstName) ? <b>{truncateText(`${item.firstName} ${item.lastName}`, 25)}</b> : <b>{truncateText(item.name, 25)}</b>,
            Radio: true,
            selectedValue: selectedTeacher === item._id,
            // selectedValue: false,
            handleChangeRedio: handleChangeRedio,
            RedioValue: (item._id) ? item._id : item.RedioValue,
            radioName: "primary_teacher",
            Status: true,
            background: item.status === 'active' ? '#EDF7EA' : '#FCE7E9',
            color: item.status === 'active' ? '#66BD50' : '#E63946',
            label: item.status === 'active' ? 'Active' : 'Inactive',
          },
          //{ textBold: `${item.firstName} ${item.lastName}`, Check: true, Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Inactive', },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.userType || "N/A" },
          // { text: "Teacher" },
          { text: "" },
        ]
      }))
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    if (userTypesDatas) {
      setOriginalData(userTypesDatas);
      setRows(convertDataToCellRows(userTypesDatas));
      setTempArray(userTypesDatas)
    }
  }, [userTypesDatas])



  // useEffect(() => {
  //   localStorage.setItem('teachersInfo', JSON.stringify(rows));
  // }, [rows])

  // useEffect(() => {
  //   localStorage.setItem('teachersInfoObject', JSON.stringify(tempArray));
  // }, [tempArray])

  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];



  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 800);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    setSearchTerm("");
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserListByRoleAndTerm({ role: 'faculty,staff,owner,teacher', term: "" });
    searchDataShowing(result)

  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserListByRoleAndTerm({ role: 'faculty,staff,owner,teacher', term: searchTerm });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
      setTempArray(result?.data?.body?.data)
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // useEffect(() => { console.log(searchTerm, 342) }, [searchTerm])
  // =========== search part start ====================== //

  // const handleSearchText = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(e.target.value);
  // }

  // const handleSearch = async () => {
  //   const result = await getSearchedUserListByRoleAndTerm({ role: 'teacher', term: searchTerm });
  //   console.log({ result })
  //   if (result?.data) {
  //     setRows(convertDataToCellRows(result.data.body.data));
  //   }
  // }


  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };



  return (
    <>
      <div className="TableSection"><br />
        <Table
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          pageNumberFromOutSide={pageNumberFromOutSide}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          innerHeader={true}
          showSearchBox={true}
          searchBoxPlaceholder="Search Users"
          // isDataLoading={isLoading || options?.isLoading}
          isDataLoading={options?.isLoading}
          RowsPerPage={10}

        />
      </div>

    </>
  );
};

export default SelectPrimaryTeacher;
