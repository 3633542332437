import React, { useState } from 'react'
import CourseAttendanceTable from "../../../components/Table/CourseAttendanceTable";
import TableSubHeader from '../../../components/Table/TableSubHeader';
import TableHeader from '../../../components/Table/TableHeader';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import CourseAttendanceFilter from '../../../components/Table/CourseAttendanceFilter';
import { useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { useFilterAttendanceOfStudentInSectionMutation } from '../../../redux/features/classes/classApi';









const CourseAttendance = (props) => {
    const {
        section_id,
        course_id,
        courseDetails
    } = props
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedDateRaw, setSelectedDateRaw] = useState(dayjs())
    const [filterDate, setFilterDate] = useState(dayjs());
    const { data: attendanceCodeResponse, isLoading: isLoadingAttendanceCode, error: attendanceCodeError } = useGetAllAttendanceCodesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [filterAttendanceTable, FilterAttendanceTableOptions] = useFilterAttendanceOfStudentInSectionMutation()

    useProtected(attendanceCodeError);
    useProtected(FilterAttendanceTableOptions.error);

    const convertAttendanceCodeResponseToSelectItem = (codes) => {

        if (codes.length > 0) {
            return codes.map((item) => ({ label: item.code, value: item.code }));
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (!isLoadingAttendanceCode && !attendanceCodeError) {
            setAttendanceCodeOptions(
                convertAttendanceCodeResponseToSelectItem(
                    attendanceCodeResponse.body.data,
                ),
            );
            setAttendanceCodeOptionsUnfiltered(attendanceCodeResponse.body.data)
        }
    }, [attendanceCodeResponse, isLoadingAttendanceCode, attendanceCodeError]);


    const onDateChangeHandler = (e) => {
        setSelectedDateRaw(e)
    }
    const goToPreviousDate = (e) => {
        setSelectedDateRaw(selectedDateRaw.subtract(1, 'day'))
    }

    const goToNextDate = (e) => {
        setSelectedDateRaw(selectedDateRaw.add(1, 'day'))
    }

    useEffect(() => {
        if (selectedDateRaw != "") {
            const formattedDate = selectedDateRaw.format('YYYY-MM-DD');
            // setSelectedDate(formattedDate);
            setSelectedDate(selectedDateRaw);
        } else {
            let today = dayjs()
            const formattedDate = today.format('YYYY-MM-DD');
            setSelectedDate(today);
            // setSelectedDate(formattedDate);
        }

    }, [selectedDateRaw])


    // ========================filter drawer start ===========================//
    const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
    const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [filterAttendanceStatus, setfilterAttendanceStatus] = useState(false);
    const [streams, setStream] = useState([])
    const [filterAtteandanceDatas, setFilterAtteandanceDatas] = useState([])
    const [weekStartDate, setWeekStartDate] = useState('');
    const [weekEndDate, setWeekEndDate] = useState('');
    const handleFilterDrawerOpen = () => {
        setOpenFilterDrawer(true);
    };

    const handleCloseFilterDrawer = async () => {
        setStream([])
        setOpenFilterDrawer(false);
        setfilterAttendanceStatus(false)
    };

    const handleCloseFilterDrawerButton = () => {
        setfilterAttendanceStatus(false)
        handleCloseFilterDrawer()
    }

    const filterHandler = async () => {
        let data = {
            sectionId: section_id,
            startDate: weekStartDate,
            atdCodes: streams,
            endDate: weekEndDate
        }

        const result = await filterAttendanceTable(data)
        if (!result?.data?.error) {
            setfilterAttendanceStatus(true)
            setFilterAtteandanceDatas(result?.data?.body?.data)
            setOpenFilterDrawer(false);
        }

    }


    useEffect(() => {
        if (weekStartDate && weekEndDate && filterAttendanceStatus) {
            filterHandler()
        }
    }, [weekStartDate, weekEndDate, filterAttendanceStatus])
    return (
        <div>
            <TableHeader
                TableTitle="Attendance"
            />
            <TableSubHeader
                DateField={true}
                Filter={true}
                FilterOnClick={handleFilterDrawerOpen}
                Export={false}
                //ExportOnClick={ExportOnClick}
                handleDateFilterChange={(e) => onDateChangeHandler(e)}
                goToPreviousDate={(e) => goToPreviousDate(e)}
                goToNextDate={(e) => goToNextDate(e)}
                dateFilter={selectedDateRaw}
            // handleDateFilterChange={(date) => setFilterDate(date)}
            // goToPreviousDate={(e) => setFilterDate(filterDate.subtract(1, 'day'))}
            // goToNextDate={(e) => setFilterDate(filterDate.add(1, 'day'))}
            />
            <CourseAttendanceTable
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                section_id={section_id}
                course_id={course_id}
                courseDetails={courseDetails}
                setWeekStartDate={setWeekStartDate}
                setWeekEndDate={setWeekEndDate}
                filterAtteandanceDatas={filterAtteandanceDatas}
                filterAttendanceStatus={filterAttendanceStatus}
                RowsPerPage={20}
            />
            {/* Filter */}
            <SideDrawer
                open={openFilterDrawer}
                handleDrawerClose={handleCloseFilterDrawer}
                handleDrawerCloseButtonBottom={handleCloseFilterDrawerButton}
                title="Filter"
                buttonLabel="Apply"
                sideOpen="right"
                ButtonLabel="Apply"
                BtnColor="#014fe0"
                BtnTextcolor="#fff"
                cancelButtonLabel="Clear Filters"
                FooterWithoutTab={true}
                clickSaveButton={(e) => filterHandler()}
                drawerWidth="696px"
                body={
                    <>
                        <CourseAttendanceFilter
                            label="Status"
                            streams={streams}
                            setStream={setStream}
                            attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
                            attendanceCodeOptions={attendanceCodeOptions}

                        />
                    </>
                }
            />
        </div>
    )
}

export default CourseAttendance;