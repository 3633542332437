import React from 'react'
import { Button } from '@mui/material'
import AppleStore from "../../../assets/imgs/store-button-image/apple-store.png"
import GoogleStore from "../../../assets/imgs/store-button-image/google-store.png"

const AboutDeenschool = () => {
    return (
        <div className='aboutDeenschoolContainer'>
            <img src="https://deenschool-website-assets.s3.amazonaws.com/assets/deenschool-SCAN-Color-Logo-full-TRP-BG.png" alt="deeen school" />
            <span>All-in-one, cloud based and secured education management platform for Islamic K-12 & weekend schools</span>
            <div className='storeButtonContainer'>
                <Button variant='text' sx={{ width: '210px' }} href="https://apps.apple.com/us/app/deenschool-scan/id6478858185">
                    <img src={AppleStore} alt="applestore button image" width="190px" />
                </Button>
                <Button variant='text' sx={{ width: 'object-fit' }} href="https://play.google.com/store/apps/details?id=com.afnanilab.deenscan&pli=1">
                    <img src={GoogleStore} alt="google play store button image" width="190px" />
                </Button>
            </div>
        </div>
    )
}

export default AboutDeenschool