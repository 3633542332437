import React, { useEffect, useState } from 'react';
import '../../../assets/css/users.css';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import AddFieldGroup from '../../../components/Form/AddTextField/AddFieldGroup';
import { useGetLocationListQuery } from '../../../redux/features/locations/locationApi';
import {
  useCheckEmailMutation,
  useCreateInviteUserMutation,
  useGetUserRolesQuery,
} from '../../../redux/features/users/userApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import useProtected from '../../../hooks/useProtected';
import { isValidEmail } from '../../../hooks/HelperFunctions';



const AddNewGuardiansParents = ({ setUsersData, usersData, handleAddInviteUser, isLoading, setIndexValue, emailErrorStatus }) => {
  const [checkEmail, checkEmailOptions] = useCheckEmailMutation()
  useProtected(checkEmailOptions?.error);

  const [emailError, setEmailError] = useState('')

  const checkEmailHandler = async (event, obj, index) => {
    let error = 0
    if ((!isValidEmail(event.target.value) || event.target.value.trim() === '')) {
      obj.emailError = "Invalid email address"
      error++
    }
    // if (!isValidEmail(event.target.value)) {
    //   obj.emailError = "Invalid email address"
    //   error++
    // }
    let result = await checkEmail({ email: (event.target.value === '') ? '***' : event.target.value })
    if (result?.data && result?.data?.error) {
      obj.emailError = "Email already exists"
      error++
    }
    if (error === 0) obj.emailError = ""

    let newUserData = usersData.map((userObj, i) => {
      if (i === index) {
        return obj
      } else {
        return userObj
      }
    })
    setUsersData(newUserData)

  }

  return (
    <>
      <div className="InvitedUsers">

        <Grid container spacing={2}>

          <Grid item md={12} xs={12}>
            <AddFieldGroup
              placeholder="e.g. abdullah@gmail.com"
              minItem={1}
              maxItem={6}
              col={6}
              primaryField="objId"
              textField="title"
              addButtonLabel="Add more parents/guardians"
              addButtonTextTransform="uppercase"
              submitButtonLabel="Send invitation"
              location={[]}
              FieldOneLabel="Email address"
              requiredFieldOne
              FieldTwoLabel="Name"
              setUsersData={setUsersData}
              usersData={usersData}
              options={[]}
              onclickSubmit={handleAddInviteUser}
              isButtonLoading={isLoading}
              checkEmail={checkEmailHandler}
              emailError={emailError}
              emailErrorStatus={emailErrorStatus}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddNewGuardiansParents;
