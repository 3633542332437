import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import AddIcon from '@mui/icons-material/Add';

import AddTemplate from './AddTemplate';
import Table from "../../../components/Table/Table";
import DraggableDialog from '../../../components/hooks/Dialog';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import useProtected from "../../../hooks/useProtected";
import {
  useGetMessageTemplateListsQuery,
  useCreateMessageTemplateMutation,
  useDeleteMessageTemplatesMutation,
  useGetMessageTemplateQuery,
  useUpdateMessageTemplateMutation,
  useSearchMessageTemplatesMutation
} from "../../../redux/features/messages/templatesApi";
import EmptyCard from "../../../components/EmptyCard/EmptyCard";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { truncateText } from "../../../hooks/HelperFunctions";






const Templates = () => {

  const [rows, setRows] = useState([]); // ? table rows
  const [templateDelete, setTemplateDelete] = useState(''); // ? single row delete
  const [existingTemplateNames, setExistingTemplateNames] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [templateCategory, setTemaplateCategory] = useState("")
  const [templateMessage, setTemplateMessage] = useState("")
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [validationError, setValidationError] = useState({ templateName: false, templateCategory: false, templateMessage: false, });
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  // * ======= Delete dialog start ======= //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (id) => {
    if (!isTheActionPermitted('deleteMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
    setTemplateDelete(id);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  // * ======= Delete dialog end ======= //



  // ! ================== import from api start  ================== * //
  const { data, isError, isLoading, error } = useGetMessageTemplateListsQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createMessageTemplate] = useCreateMessageTemplateMutation()
  const [deleteTemplate] = useDeleteMessageTemplatesMutation();
  useProtected(error);
  const { data: templateData, error: templateError } = useGetMessageTemplateQuery();
  const [updateTemplate, updateTemplateOptions] = useUpdateMessageTemplateMutation();
  useProtected(updateTemplateOptions.error)
  const [getTemplatesSearchResult, options] = useSearchMessageTemplatesMutation();
  // ! ================== import from api end  ================== * //


  // * ================== row data fetch  ================== * //
  useEffect(() => {
    if (!isLoading && !isError) {
      let datas = [...data.body.data];
      datas.sort((a, b) => {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      setRows(convertDataToCellRows(datas));
    }
    // setIsSearchResult(false)
  }, [data, isLoading, isError]);
  // * ================== existing data fetch  ================== * //
  useEffect(() => {
    if (!isLoading && !isError) {
      const templateNames = data.body.data.map((item) => item.templateName);
      setExistingTemplateNames(templateNames);
    }
  }, [data, isLoading, isError]);



  // * ================== row data delete start  ================== * //
  const deleteAttendanceCodeHandler = async () => {
    const result = await deleteTemplate(templateDelete);

    if (result?.data) {
      toast.success('Template deleted');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setTemplateDelete('')
  }
  // * ================== row data delete end  ================== * //



  // * ================== Drawer open  ================== * //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const handleCreateDrawerOpen = (template) => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setSelectedTemplate(template);
    setOpenCreate(true);
  };
  const handleCloseCreateDrawer = () => {
    setOpenCreate(false);
    setTemplateName('')
    setTemaplateCategory('')
    setTemplateMessage('')
    setValidationError({ templateName: false, templateCategory: false, templateMessage: false, })
  };


  const [editDataDrawer, setEditDataDrawer] = useState(false);
  const handleEditDrawerOpen = (template) => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setSelectedTemplate(template);
    setEditDataDrawer(true);
    // if (template) {
    //   templateData(template.id); // Assuming that template has an 'id' property.
    // }
    setEditMode(true)
  };

  const handleEditDrawerClose = () => {
    setEditDataDrawer(false);
  };

  // * ================== Drawer close  ================== * //



  // * ================== Table start  ================== * //
  const headCells = [
    { id: "to", numeric: false, disablePadding: true, label: "Template Name", width: "20%", textAlign: "left", },
    { id: "message", numeric: false, disablePadding: true, label: "Category", width: "20%", textAlign: "left", },
    { id: "message", numeric: false, disablePadding: true, label: "Content ", width: "60%", textAlign: "left", },
    { id: "action", numeric: false, disablePadding: true, label: "", width: "20%", textAlign: "left", },
  ];

  // * ================== Table row data maping  ================== * //
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        cell: [
          { textBold: truncateText(item?.templateName, 60), },
          { text: item?.category, },
          { text: truncateText(item?.content, 100) },
          { ActionButton: true, ActionButtonLabel: 'Action', },
        ],

        // * ======= Action Data Start===== * //
        action: [
          { label: 'EDIT', onClick: (e) => handleEditDrawerOpen(item) },
          { label: 'DELETE', onClick: (e) => handleOpenDelete(item._id) },
        ],
        // * ======= Action Data End===== * //
      }));
    } else {
      return [];
    }
  };
  // * ================== Table end  ================== * //

  // * ================== Search templates start  ================== * //

  // =========== search part start ====================== //

  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])


  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getTemplatesSearchResult({ term: "" });
    searchDataShowing(result)
  };


  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part start ====================== //
  const handleSearchText = (e) => {
    e.preventDefault()
    setSearchQuery(e.target.value);

  }

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getTemplatesSearchResult({ term: searchQuery });
    searchDataShowing(result)
  }
  // * ================== Search templates end    ================== * //

  // * ================== Create template start  ================== * //

  const createTemplateFormSubmitHandler = async (data) => {
    const templateData = {
      templateName,
      category: templateCategory,
      content: templateMessage,
    };

    if (templateName === "" && templateCategory === "" && templateMessage === "") {
      setValidationError({ templateName: true, templateCategory: true, templateMessage: true, })
      toast.error("Please fill required fields")
      return;
    }

    if (templateName === "") {
      setValidationError({ templateName: true })
      toast.error("Please fill required fields")
      return;
    }

    if (templateCategory === "") {
      setValidationError({ templateCategory: true })
      toast.error("Please fill required fields")
      return;
    }
    if (templateMessage === "") {
      setValidationError({ templateMessage: true })
      toast.error("Please fill required fields")
      return;
    }

    if (existingTemplateNames.includes(templateName)) {
      toast.warn('Template with the same name already exists');
      return;
    }

    try {
      const response = await createMessageTemplate(templateData);

      if (response.data) {
        toast.success('Template added');
        setOpenCreate(false);
        setValidationError({ templateName: false, templateCategory: false, templateMessage: false, })
      } else {
        toast.error(response.error.message);
      }
    } catch (error) {
      toast.error('An error occurred while creating the template');
    }



    setTemplateName("")
    setTemaplateCategory("");
    setTemplateMessage("");
    setExistingTemplateNames([...existingTemplateNames, templateName]); // * seting the exsing name so that user will not be able to create same name template

  }
  // * ================== Create template end    ================== * //

  // * ================== Edit template  ================== * //
  const updateTemplateData = (name, message) => {
    setTemplateName(name);
    setTemplateMessage(message);
  };

  const updateTemplateFormSubmitHandler = async () => {
    const templateData = {
      templateName,
      category: templateCategory,
      content: templateMessage,
    };

    if (templateName === "" && templateCategory === "" && templateMessage === "") {
      setValidationError({ templateName: true, templateCategory: true, templateMessage: true, })
      toast.error("Please fill required fields")
      return;
    }

    if (templateName === "") {
      setValidationError({ templateName: true })
      toast.error("Please fill required fields")
      return;
    }

    if (templateCategory === "") {
      setValidationError({ templateCategory: true })
      toast.error("Please fill required fields")
      return;
    }
    if (templateMessage === "") {
      setValidationError({ templateMessage: true })
      toast.error("Please fill required fields")
      return;
    }

    try {
      const response = await updateTemplate({ ...templateData, id: selectedTemplate._id });

      if (response.data) {
        toast.success('Template updated');
        setEditDataDrawer(false);
      } else {
        toast.error(response.error.message);
      }
    } catch (error) {
      toast.error('An error occurred while updating the template');
    }

    // Clear fields and reset selectedTemplate
    setTemplateName("");
    setTemaplateCategory("");
    setTemplateMessage("");
    setSelectedTemplate(null);
  };

  return (
    <>
      <div className="TableSection">

        <Table
          title={<span style={{ fontSize: '32px' }}>Templates</span>}
          tableHeader={true}
          addLabel="New Template"
          icon={<AddIcon fontSize="small" />}
          AddButton={true}
          headCells={headCells}
          rows={rows}

          footer={true}
          RowsPerPage={20}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          pageNumberFromOutSide={pageNumberFromOutSide}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={searchQuery}
          resetsearchTerm={resetsearchTerm}
          innerHeader={true}
          SearchBoxTop={true}
          searchBoxPlaceholder="Search templates"
          emptyTableTitle="No template added yet"
          emptyTableNote="Added template will be visible here"
          btnLabel="New Template"
          TableTitle="Templates"
          showButton={true}
          TableTitleStatus={true}
          onClickEmptyCard={handleCreateDrawerOpen}
          // btnLink={btnLink} 
          isDataLoading={isLoading || options?.isLoading}
        />
      </div>

      {/* ============  Edit template ============== */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="Add Template"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        clickSaveButton={createTemplateFormSubmitHandler}
        FooterWithoutTab={true}
        body={
          <AddTemplate
            editMode={false}
            updateTemplateData={updateTemplateData}
            selectedTemplate={selectedTemplate}
            validationError={validationError}
            templateName={templateName}
            setTemplateName={setTemplateName}
            templateCategory={templateCategory}
            setTemaplateCategory={setTemaplateCategory}
            templateMessage={templateMessage}
            setTemplateMessage={setTemplateMessage}
          />
        }
      />

      <SideDrawer
        open={editDataDrawer}
        handleDrawerClose={handleEditDrawerClose}
        title="Edit Template"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        clickSaveButton={updateTemplateFormSubmitHandler}
        FooterWithoutTab={true}
        body={
          <AddTemplate
            editMode={editMode}
            updateTemplateData={updateTemplateData}
            selectedTemplate={selectedTemplate}
            validationError={validationError}
            templateName={templateName}
            setTemplateName={setTemplateName}
            templateCategory={templateCategory}
            setTemaplateCategory={setTemaplateCategory}
            templateMessage={templateMessage}
            setTemplateMessage={setTemplateMessage}
          />
        }
      />

      {/* ===================  Delete Modal =================== */}
      {/* <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Delete this template?"
        body={<div>Are you sure you want to delete this? <br />Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
        handleConfirmCloseDialog={deleteAttendanceCodeHandler}
      /> */}

      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={deleteAttendanceCodeHandler}
        title="Delete this template?"
        body=<div>Are you sure you want to delete this? <br />Once you delete it, you can't get it back.</div>
      />

    </>
  );
};

export default Templates;
