import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import {
  useGetSearchResultMutation,
  useGetUsersByRoleQuery,
  useGetSearchByRoleAndTermResultMutation
} from "../../../redux/features/users/userApi";
import { toast } from "react-toastify";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import StudentsFilter from "./StudentsFilter";
import AddStudentsFilter from "./AddStudentsFilter";
import { truncateText } from "../../../hooks/HelperFunctions";


const AddStudents = ({
  setCourseUsers,
  courseUsers,
  addCourseUsersOptions,
  setCourseUsersToUpdate,
  courseUsersToUpdate,
  alreadyAddedStudents,
  allUsers,
  // * ==== * //

}) => {

  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('student', { refetchOnMountOrArgChange: true });
  const [getSearchedUserList, searchedUserListOptions] = useGetSearchResultMutation();
  useProtected(error);
  useProtected(searchedUserListOptions?.error);

  const [filterDrawer, setFilterDrawer] = useState(false)
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [students, setStudents] = useState([])
  const [getSearchedUserListByRoleAndTerm, options] = useGetSearchByRoleAndTermResultMutation();

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Full Name", width: "40%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "ID #", width: "20%", textAlign: "left", },
    // { id: "name", numeric: false, disablePadding: true, label: "User Type", width: "25%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "gender", width: "40%", textAlign: "left", },

  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map(item => ({
        cell: [
          { Check: true, width: "34px", user_id: item._id, user: { name: `${item?.firstName} ${item?.lastName}`, id: item._id, email: item?.email, status: item?.status } },
          {
            textBold: truncateText(`${item.firstName} ${item.lastName}`, 35),
            user: {
              name: `${item?.firstName} ${item?.lastName}`,
              id: item._id,
              email: item?.email,
              status: item?.status
            },
            ...((item?.status === 'active') ?
              {
                Status: true,
                background: '#EDF7EA',
                color: '#66BD50',
                label: 'Active',
              } :
              {
                Status: true,
                background: '#FCE7E9',
                color: '#E63946',
                label: 'Inactive',
              }
            )
          },

          { text: item?.userUniqueID || "N/A" },
          // { text: item?.userType || "N/A" },
          { text: item?.gender || "N/A" },

        ]
      }))
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    let toShow = []
    if (!isLoading && !isError) {
      data?.body?.data?.map((user) => {
        let newStudent = allUsers?.find((singleStudent) => singleStudent?.id === user._id)
        if (!newStudent) toShow.push(user)
      })
      setRows(convertDataToCellRows(toShow));
      setStudents(toShow)
    }
  }, [data, allUsers])



  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];


  //-------------------------------------------- search part end -----------------------------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserListByRoleAndTerm({ role: 'student', term: "" });
    searchDataShowing(result)
  };
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserListByRoleAndTerm({ role: 'student', term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    let toShow = []
    if (!result?.data?.error) {
      result?.data?.body?.data?.map((user) => {
        let newStudent = allUsers?.find((singleStudent) => singleStudent?.id === user._id)
        if (!newStudent) toShow.push(user)
      })
      setRows(convertDataToCellRows(toShow));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-------------------------------------------- search part end -----------------------------//

  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };


  useEffect(() => {
    let addSudents = []
    courseUsers?.map((users) => {
      const findStudents = students?.find((studentId) => studentId?._id === users)
      if (findStudents) {
        addSudents.push({
          id: findStudents?._id,
          name: `${findStudents?.firstName} ${findStudents?.lastName}`,
          email: findStudents?.email,
          role: findStudents?.role,
          status: findStudents?.status,
          userUniqueId: findStudents?.userUniqueID,
          classLevel: findStudents.classLevel,
          gender: findStudents.gender
        })
      }
    })
    setCourseUsersToUpdate(addSudents)
  }, [courseUsers])

  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setCourseUsers(prevUsers => [...prevUsers, item[0].user]);
      // setCourseUsersToUpdate(prevUsers => [...prevUsers, item[0].user]);
    } else {
      // Remove the user from the array
      setCourseUsers(prevUsers => prevUsers.filter(user => user._id !== item[0].user._id));
      // setCourseUsersToUpdate(prevUsers => prevUsers.filter(user => user.id !== item[0].user.id));
    }

  }

  //======================== filter part start ========================================//
  const [gradeTypes, setGradeTypes] = useState([])
  const [genderTypes, setGenderTypes] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [classLevel, setClassLevel] = useState([])
  const handleCloseDrawer = () => {
    setFilterDrawer(false)
    setRows(convertDataToCellRows(students));
    setGradeTypes([])
    setGenderTypes([])
    setUserTypes([])
  }

  const userTypesHandlerFilter = (e, value) => {
    let users = [...userTypes]
    if (e.target.checked) {
      users.push(value)
    } else {
      users = userTypes.filter(item => item !== value)
    }
    setUserTypes(users)
  }
  const genderTypeHandlerFilter = (e, value) => {
    let genders = [...genderTypes]
    if (e.target.checked) {
      genders.push(value)
    } else {
      genders = genderTypes?.filter(item => item !== value)
    }
    setGenderTypes(genders)
  }
  const gradeTypesHandlerFilter = (e, value) => {
    let grades = [...gradeTypes]
    if (e.target.checked) {
      grades.push(value)
    } else {
      grades = gradeTypes.filter(item => item !== value)
    }
    setGradeTypes(grades)
  }

  const handleFilterStudentAttendance = async () => {
    const result = await getSearchedUserList({ term: searchTerm, gender: genderTypes, status: userTypes, userType: ["student"] })
    if (result?.data?.body?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    }
    setFilterDrawer(false)
  }
  //======================== filter part start ========================================//

  return (
    <>
      <div className="TableSection">
        <Table
          title={<span style={{ marginLeft: '22px', fontSize: '18px', fontWeight: '400' }}>{courseUsers?.length} student {courseUsers?.length > 1 ? "s" : ""} selected</span>}
          tableHeader={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          innerHeader={true}
          // showSearchBox={true}
          SearchBoxTop={true}

          filterCountStatus={(genderTypes?.length + userTypes?.length) > 0}
          filterCountNumber={(genderTypes?.length + userTypes?.length)}
          FilterButtonTop={true}
          onClickFilterButton={() => setFilterDrawer(true)}

          handleRowClick={handleRowClick}
          searchBoxPlaceholder="Search Users"
          handleCheckItem={setCourseUsers}
          selectedCheckList={courseUsers}
          customCellObjectId="user_id"
          isDataLoading={isLoading || options?.isLoading || addCourseUsersOptions?.isLoading}
          RowsPerPage={20}
          CheckboxDelete={true}
        />
      </div>

      {/* Filter Drawer */}
      <SideDrawer
        open={filterDrawer}
        handleDrawerClose={handleCloseDrawer}
        title="Filter"
        sideOpen="right"
        ButtonLabel="Filter"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={() => handleFilterStudentAttendance()}
        BackArrow={true}
        CloseArrowHide={true}
        drawerWidth="680px"
        drawerMaxWidth="680px"
        body={
          <>
            <AddStudentsFilter gradeTypesHandlerFilter={gradeTypesHandlerFilter}
              setGradeTypesExport={setGradeTypes}
              gradeTypes={gradeTypes}
              setClassLevel={setClassLevel}
              classLevel={classLevel}
              userTypesHandlerFilter={userTypesHandlerFilter}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              genderTypeHandlerFilter={genderTypeHandlerFilter}
              setGenderTypes={setGenderTypes}
              genderTypes={genderTypes} />
          </>
        }
      />
    </>
  );
};

export default AddStudents;
