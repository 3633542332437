import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import img from '../../../assets/imgs/User.png';
import Buttons from '../../../components/Form/Button/Button';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, grey } from '@mui/material/colors';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';

import Cropper from 'react-easy-crop'
import getCroppedImg from "../../../utils/extra";

import axios from 'axios';
import config from "../../../config";

import Externaluse from '../../../components/MediaLibrary/Externaluse';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Grid from '@material-ui/core/Grid';

import WebcamComponent from "../../../components/Webcam/WebcamComponent";

import AddRoundedIcon from '@mui/icons-material/AddRounded';

import MediaLibrarySelectMedia from "../../../components/MediaLibrary/MediaLibrarySelectMedia";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Modals from '../../../components/Form/Modals/Modals';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import { useDeleteUserIDPhotoMutation, useGetUserListQuery, useUpdateUserMutation } from "../../../redux/features/users/userApi";

import IdPhotoHistory from './IdPhotoHistory';

import { toast } from "react-toastify";
import useProtected from '../../../hooks/useProtected';
import DraggableDialog from '../../../components/hooks/Dialog';
import moment from 'moment-timezone';


const Identification = ({ id, userDetails, setUserDetails, handleUpdateProfile, personalInfo, refetch, setFormData }) => {
  const [updateUser, updateUserOptions] = useUpdateUserMutation();
  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteUserIDPhoto] = useDeleteUserIDPhotoMutation();

  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };


  const [userList, setUserList] = useState([])
  const { data, isError, isLoading, error } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(error);
  useEffect(() => {
    if (!isLoading && !isError && data) {
      setUserList(data?.body?.data);
    }

  }, [data]);
  const [imageFromWebcam, setImageFromWebcam] = useState(null);
  const [imageFromCropper, setImageFromCropper] = useState(null);
  const [imageFromCropperSrc, setImageFromCropperSrc] = useState(null);
  const [imageFromCropperName, setImageFromCropperName] = useState(null);

  const [showEditPhotoModal, setShowEditPhotoModal] = useState(false);
  const [showTakePhotoModal, setShowTakePhotoModal] = useState(false);


  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 })
  const [mediaSize, setMediaSize] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  })
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])

  const setCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageFromWebcam,
        croppedAreaPixels,
        0
      );
      setImageFromWebcam(null);

      setImageFromCropper(croppedImage);
      setImageFromCropperSrc(URL.createObjectURL(croppedImage));

      const currentDate = new Date();
      const datestamp = `${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}${currentDate.getFullYear()}`;

      //firstname to be in lowercase 

      const firstName = userDetails?.firstName?.toLowerCase();

      const lastName = userDetails?.lastName?.toLowerCase();

      const fileName = `${userDetails?.userUniqueID}-${firstName}-${lastName}-${datestamp}.jpeg`

      setImageFromCropperName(fileName);

      setCrop({ x: 0, y: 0 });
      setZoom(1);

    } catch (e) {
      console.error(e)
    }
  })

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };







  // MEDIA LIBRARY 

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});

  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };

  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    //setOpen(true);
  };

  const handleDrawerOpenNewMedia = () => {
    //setOpen(false);
    setOpenNewMedia(true);
  };

  const handleOnClickTakePhoto = () => {
    setShowTakePhotoModal(true);
  }

  const handleMediaUploadClose = () => {
    setOpenNewMedia(false);
  };

  const handleOpenNewMedia = (status) => {
    //setOpen(false);
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };

  const getMediaItem = (item) => {
    setMediaItem(item);
  };


  const clickSelectMediaItem = () => {
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("image/")) {
        let imageData = {
          ...personalInfo,
          idPicture: mediaItem.url,
          idPictureName: mediaItem.name
        }
        userUpdatedImage(imageData)

        setOpenSelectMedia(false);
      } else {
        toast.error("Only image file can be accepted")
      }
    }
  };


  const userUpdatedImage = async (data) => {
    const result = await updateUser({ id, ...data });

    if (!result?.data?.error) {
      // setFormData(personalInfo);
      const response = await refetch();
      if (!response?.data?.error) {
        const data = { ...response.data.body.data, birthMonth: response.data.body?.birthDate ? moment(response.data.body.birthDate).month() : "", birthYear: response.data.body?.birthDate ? moment(response.data.body.birthDate).year() : "" }
        setFormData(data);
      }
      toast.success("User information updated")
    } else {
      toast.error(result?.data?.message)
    }
  }

  // MEDIA LIBRARY END

  const handleOnClickUploadPhoto = async () => {
    //Format: id#-firstname-lastname-datestamp (0001-shayan-mahmud-07182023)
    //files/uploadProfileImage/id

    const formData = new FormData();
    formData.append('file', imageFromCropper, imageFromCropperName);
    const apiURL = `${config.api_url}/files/uploadProfileImage/${id}`;

    axios.post(apiURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {

        if (response?.data?.body?.data?.cdnUrl) {
          // setUserDetails({
          //   ...userDetails,
          //   idPicture: response?.data?.body?.data?.cdnUrl,
          //   // idPictureName: imageFromCropperName,
          // });

          let imageData = {
            ...personalInfo,
            idPicture: response?.data?.body?.data?.cdnUrl,
            idPictureName: imageFromCropperName
          }
          userUpdatedImage(imageData)
          // handleUpdateProfile();

          if (imageFromCropperSrc) {
            URL.revokeObjectURL(imageFromCropperSrc)
            setImageFromCropper(null);
            setImageFromCropperSrc(null);
            setImageFromCropperName("");
          }
          refetch();
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error('Error uploading image:', error);

      });
  }


  // ADD AND TAKE MODAL //
  const [openModal, setOpen] = useState(false);
  const setShowProfilePhotoSelectionModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false)
  };
  // ADD AND TAKE MODAL //

  // UPLOAD PICTURE MODAL //
  const [openModalUplaodPiture, setOpenUplaodPiture] = useState(false);
  const ModalOpenUplaodPicture = () => {
    setOpenUplaodPiture(true);
  };

  const handleCloseModalUplaodPiture = () => {
    setOpenUplaodPiture(false)
  };
  // UPLOAD PICTURE MODAL //


  const [openHistory, setOpenHistory] = useState(false);
  const handleDrawerOpenHistory = () => {

    //fetch data



    setOpenHistory(true);
  };
  const handleDrawerCloseHistory = () => {
    setOpenHistory(false);
  };

  // console.log(personalInfo)

  //======= Delete dialog part start ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCourseDeleteDialog = async () => {
    try {
      const result = await deleteUserIDPhoto(id);
      if (!result?.data?.error) {
        await refetch();
        toast.success("ID photo deleted successfully")
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Error deleting course. Please try again.");
    }
    setOpenDialog(false);
  };
  //======= Delete dialog part end ===== //
  return (
    <>
      <div className="OddTabble">
        <div className="TableContainer oddEvenTable">
          <div className='BoxTable'>
            <div className="TableInnerHeader">
              <h3>ID Photo</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      ID Photo
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableLabel">

                      {userDetails?.idPicture == null ?

                        <div className='TableUserAvatar'>
                          <Avatar sx={{ background: "#E4E2E6", borderRadius: '6px' }} variant="rounded">
                            <PersonRoundedIcon />
                          </Avatar>
                          <span style={{ color: '#919094', fontSize: '14px' }}>Upload a ID photo</span>
                        </div>
                        :
                        <div className='TableUserAvatar'>
                          <span><img src={userDetails?.idPicture} style={{ width: '48px', height: '48px', borderRadius: '8px' }} /></span>&nbsp;&nbsp;
                          {userDetails?.idPictureName}
                        </div>}
                    </StyledTableCell>

                    <StyledTableCell align="right" className="TableInfo">
                      <div className='AlignRight'>
                        {userDetails?.idPicture == null ?
                          <Buttons
                            label="Upload photo"
                            icon={<AddAPhotoRoundedIcon />}
                            buttonColor="#fff"
                            border="#C6C6D0 1px solid"
                            color="#004FE0"
                            width=""
                            height="48px"
                            fontSize="14px"
                            onClick={() => { setShowProfilePhotoSelectionModal(true) }}
                            // ButtonLoader={forgotPasswordOptions?.isLoading}
                            LoderColor="#0450E1"
                          //LoaderOnclick={}
                          />
                          :
                          <div className="ActionMenu">
                            <div onClick={handleToggle} className="ActionMenuButton"><MoreVertIcon /></div>
                            <div className={isActive ? "PricingCell" : "Active"}>
                              <div className='DropDownMenuCustom'>
                                <div onClick={handleToggle} style={{ fontSize: '10px' }}>
                                  <a onClick={() => { setShowProfilePhotoSelectionModal(true) }}>Upload New Photo</a>
                                </div>
                                <div onClick={handleToggle}>
                                  <a onClick={handleDrawerOpenHistory} >View history</a>
                                </div>
                                {userDetails?.idPicture != null && <a onClick={handleOpenDelete}>Delete Photo</a>}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>



      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSelectMedia}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        clickButtonLeft={handleDrawerOpenNewMedia}
        clickSaveButton={clickSelectMediaItem}
        body={
          <MediaLibrarySelectMedia
            onClickRadioIcon={setSelectedValue}
            onItemSelected={getMediaItem}
          />
        }
      />

      <Externaluse
        open={openNewMedia}
        handleCloseButton={handleMediaUploadClose}
        handleOpenNewMedia={(handleOpenNewMedia)}
      />



      {/*=== Uplaod ID Modal Start */}
      <Modals
        ModalTitle={<span style={{ fontWeight: '600' }}>Upload ID photo</span>}
        open={openModal && (!openNewMedia || !openSelectMedia)}
        handleClose={handleCloseModal}
        ModalFooter={false}
        ButtonClose="Cancel"
        ButtonConfirm="Upload"
        width="600px"
        textAlign="center"
        ModalBody={
          <div>
            <Grid container spacing={2}>

              {imageFromCropperSrc == null ?

                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <div onClick={handleCloseModal}>
                      <Buttons
                        label="Add from media library"
                        buttonColor="transparent"
                        border="#0450E1 1px solid"
                        icon={<AddRoundedIcon />}
                        //textTransform="initial"
                        color="#004FE0"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        onClick={() => handleDrawerOpenSelectMedia()}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Buttons
                      label="Take Photo"
                      buttonColor="transparent"
                      border="#0450E1 1px solid"
                      icon={<PhotoCameraRoundedIcon />}
                      //textTransform="initial"
                      color="#004FE0"
                      width="100%"
                      height="48px"
                      fontSize="14px"
                      link=""
                      onClick={() => handleOnClickTakePhoto()}
                    />
                  </Grid>
                </>

                :

                <Grid item xs={12} sm={12} md={12}>
                  <div className="UploadPhotoFiled">
                    <div className="UploadPhotoImgCol">
                      <span><img src={imageFromCropperSrc} /></span>
                      <div className="ImgDetails">{imageFromCropperName}</div>
                    </div>
                    <a className="UploadPhotoFiledDelete">
                      <DeleteForeverRoundedIcon onClick={() => {
                        setImageFromCropper(null)
                        setImageFromCropperSrc(null)
                      }} />
                    </a>
                  </div>
                </Grid>
              }

              <Grid item xs={12} sm={12} md={12}>
                <div>Minimum dimension of 600x600 pixels <br />Maximum size of 10.0MB. .jpg, .jpeg, or .png</div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Buttons
                  label="Upload"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  fontWeight="700"
                  disabled={imageFromCropperSrc == null ? true : false}
                  onClick={() => handleOnClickUploadPhoto()}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Buttons
                  label={<u style={{ fontWeight: '500' }}>Cancel</u>}
                  buttonColor=""
                  color="#0450E1"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  onClick={() => {
                    setOpen(false);
                    setImageFromCropper(null)
                    setImageFromCropperSrc(null)
                    setImageFromCropperName("")
                  }
                  }
                  textTransform="initial"
                />
              </Grid>

            </Grid>
          </div>
        }
      />
      {/*=== Uplaod ID Modal End */}


      <Modal
        disableEnforceFocus
        open={showTakePhotoModal}
        onClose={() => { setShowTakePhotoModal(false) }}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <WebcamComponent width={500} height={400}
            setImageSrc={setImageFromWebcam}
            onCaptured={() => {
              setShowEditPhotoModal(true);
            }}
          >

          </WebcamComponent>

          {imageFromWebcam != null ?

            <Modal
              disableEnforceFocus
              open={imageFromWebcam != null && showEditPhotoModal}
              onClose={() => { setShowEditPhotoModal(false) }}
              aria-labelledby="child-modal1-title"
              aria-describedby="child-modal1-description"
            >
              <Box sx={{ ...style, width: 600 }}>
                <div
                  style={{ "position": "relative", width: 500, height: 400 }}
                >
                  <Cropper
                    image={imageFromWebcam}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    setMediaSize={setMediaSize}
                    setCropSize={setCropSize}
                  />
                </div>

                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setShowEditPhotoModal(false);
                    setShowTakePhotoModal(false);
                    setCroppedImage();
                  }}
                >
                  Select
                </Button>

                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setShowEditPhotoModal(false);
                    setShowTakePhotoModal(true);
                    setImageFromWebcam(null);
                  }}
                >
                  Retake
                </Button>

              </Box>
            </Modal>

            : <></>}


        </Box>
      </Modal>

      {   /*=== ID Photo History SideDrawer*/}
      <SideDrawer
        title="ID Photo History"
        ButtonLabel="Done"
        // cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openHistory}
        handleDrawerClose={handleDrawerCloseHistory}
        FooterWithoutTab={true}
        LeftButton={false}
        // ButtonLeft="Upload New"
        // ButtonLeftIcon={<FileUploadOutlinedIcon />}
        // clickButtonLeft={handleDrawerOpenNewMedia}
        clickSaveButton={handleDrawerCloseHistory}
        padding="0"
        body={
          <div className='idPhotoHistoryContainer'><IdPhotoHistory id={id} userList={userList} /></div>
        }
      />



      {/* Delete */}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Delete ID photo?"
        body={<div>Are you sure you want to delete this ID photo?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
        actionButton="Delete"
      />
    </>
  );
};

export default Identification;
