import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import SwitchButtonIOS from '../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';

import DragAndDrop from '../../../components/Dnd/DragAndDrop';

const CourseSettings = () => {


  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  // === DND CODE === //
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data);
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    console.log({ reorderedItems });
    setItems(reorderedItems);
  };



  const data = [
    {
      title: "Boys",
      statusLabel: "Active",
      statusbackground: "#EDF7EA",
      statusColor: "#66BD50"
      //handleChange=handleChange,
    },

    {
      title: "Girls",
      statusLabel: "Archive",
      statusbackground: "#EAD8D8",
      statusColor: "#BA1A1A"
      //handleChange=handleChange,
    },

    {
      title: "Section max character",
      statusLabel: "Active",
      statusbackground: "#EDF7EA",
      statusColor: "#66BD50"
      //handleChange=handleChange,
    },

  ];

  // === DND CODE  END=== //


  return (
    <>

      <div className="CourseSettings">
        <div className="CourseSettingsHeader">
          <h3>Sections</h3>
          <p>Sequence the section how you want to display. <br />Enabled sections will be visible as tabs for easy access.</p>
        </div>




        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items.map((item, index,) => (
                  <Draggable key={item.title} draggableId={item.title} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="DndCard"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >

                        <div className="DragableContainer">
                          <div className="DNDLeftCol">
                            <div className="DragMoveIcon">
                              <MoreVertRoundedIcon />
                            </div>
                            <div className="DNDLeftInfo">
                              <h1>{item.title}</h1>
                              <span
                                className="status rounded statussmall"
                                style={{ background: item.statusbackground, color: item.statusColor }}
                              >
                                {item.statusLabel}
                              </span>
                            </div>
                          </div>

                          <div className="DNDRightCol">
                            <SwitchButtonIOS
                              handleChange={item.handleChange}
                              checked={state.checkedB}
                            />
                          </div>
                        </div>


                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="ArchiveSection">
          <SwitchButtonIOS
            title="Archive Sections"
            subtitle="Automatically archive sections if section end date has passed"
            BorderLine={true}
            ComingSoon={true}
            handleChange={handleChange}
            disabled
            checked={state.checkedB}
          />
        </div>

      </div>

    </>
  );
};

export default CourseSettings;


