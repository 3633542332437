import React, { useEffect, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import SelectCard from '../../../../components/Form/SelectCard/SelectCard';
import PriceTextField from '../../../../components/Form/PriceTextField/PriceTextField';
import SelectField from '../../../../components/Form/SelectField/SelectField';
import Datefield from '../../../../components/Form/Datefield/Datefield';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CheckBox from '../../../../components/Form/CheckBox/CheckBox';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '../../../../components/Form/Tooltip/Tooltip';
import Grid from '@mui/material/Grid';
import TimePickerField from '../../../../components/Form/TimePicker/TimePicker';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';

import SelectUsers from '../../attendance/SelectUsers';
import AddStudents from './AddStudents';
import { useGetCouponDetailsQuery } from '../../../../redux/features/coupons/couponApi';
import dayjs from 'dayjs';
import { FormHelperText } from '@mui/material';



const CreateCoupon = (props) => {
    const {
        editId,
        couponObject,
        setCurrentCode,
        setCouponObject,
        couponErrorObject,
        setCouponErrorObject
    } = props


    const {
        data: couponDetails,
        isLoading: isCouponDetailsLoading,
        isFetching: isCouponDetailsFetching,
        error: couponDetailsError,
    } = useGetCouponDetailsQuery((editId), {
        skip: !(editId != ""),
        refetchOnMountOrArgChange: true,
    });


    const [OpenDrawerAddUser, setOpenAddUser] = useState(false);
    const handleDrawerOpenAddUser = () => {
        setOpenAddUser(true);
    };
    const CloseDrawerAddUser = () => {
        setClassStudents([])
        setOpenAddUser(false);
    };




    const [classStudents, setClassStudents] = useState([]);
    const [value, setValue] = React.useState(0);
    const onChange = (event, newValue) => {
        setValue(newValue);
    };


    useEffect(() => {
        if (!isCouponDetailsLoading && !isCouponDetailsFetching && (editId != "")) {
            let data = couponDetails?.body?.data
            setCouponObject({
                code: data?.code,
                discountValue: data?.discountValue,
                discountType: data?.discountType,
                status: data?.status,
                redemptionLimit: {
                    limitDateRange: data?.redemptionLimit?.limitDateRange, //true, false
                    limitDateRangeData: {
                        date: dayjs(data?.redemptionLimit?.limitDateRangeData?.date),
                        time: dayjs(data?.redemptionLimit?.limitDateRangeData?.time)
                    },
                    limitCouponUser: data?.redemptionLimit?.limitCouponUser, //true, false
                    limitCouponUserData: {
                        couponUsingLimit: data?.redemptionLimit?.limitCouponUserData?.couponUsingLimit
                    },
                    limitToSpecificStudent: data?.redemptionLimit?.limitToSpecificStudent, //true, false
                    limitToSpecificStudentData: {
                        students: data?.redemptionLimit?.limitToSpecificStudentData?.students
                    }
                },
                applyAutomatically: data?.applyAutomatically
            })

            setClassStudents(data?.redemptionLimit?.limitToSpecificStudentData?.students)
            setCurrentCode(data?.code)
        }
    }, [isCouponDetailsLoading, isCouponDetailsFetching, couponDetails, editId])



    const tabsData = [
        {
            title: "Recurring",
            //onClick:onClick,
        },

        {
            title: "One-time",
            //onClick:onClick,
        },
    ]




    const [age, setAge] = React.useState('');
    const handleChanges = (event) => {
        setAge(event.target.value);
    };

    const options = [
        {
            label: "Custom",
            value: "Custom",
        },

    ]


    const optionsCustom = [
        {
            label: "days ",
            value: "days ",
        },

        {
            label: "weeks ",
            value: "weeks ",
        },

        {
            label: "months ",
            value: "months ",
        },

        {
            label: "years ",
            value: "years ",
        },

    ]

    const [state, setState] = React.useState({
        checkedA: true,
    });

    const handleChangeCheck = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };



    const onChangeHandler = (val, e, fieldName) => {
        let value = val
        if (fieldName == "code") {
            value = val?.toUpperCase().replace(/\s+/g, "");
        }
        setCouponObject({ ...couponObject, [fieldName]: value })
        setCouponErrorObject({ ...couponErrorObject, [fieldName]: "" })
    }

    const onChangeRedeemCouponLimitHandler = (val, e, fieldName) => {
        let { redemptionLimit } = couponObject
        redemptionLimit['limitCouponUserData'][fieldName] = val
        let newRedemptionLimit = { ...redemptionLimit }
        setCouponObject({ ...couponObject, redemptionLimit: newRedemptionLimit })
    }

    const onChangeCheckRedeemHandler = (e, fieldName) => {
        let { redemptionLimit } = couponObject
        redemptionLimit[fieldName] = e.target.checked
        let newRedemptionLimit = { ...redemptionLimit }
        setCouponObject({ ...couponObject, redemptionLimit: newRedemptionLimit })
    }

    const onChangeCheckRedeemDateTimeHandler = (val, fieldName) => {
        let { redemptionLimit } = couponObject
        redemptionLimit['limitDateRangeData'][fieldName] = val
        let newRedemptionLimit = { ...redemptionLimit }
        setCouponObject({ ...couponObject, redemptionLimit: newRedemptionLimit })
    }

    const selectValue = [
        {
            label: "Active",
            value: "active",
        },
        {
            label: "Inactive",
            value: "inactive",
        }
    ]



    useEffect(() => {
        console.log("classStudents:", classStudents)



        if (classStudents?.length > 0) {
            setCouponObject({ ...couponObject, discountValue: 2 })

        }
    }, [classStudents])

    const addStudentButtonHandler = () => {

        let { redemptionLimit } = couponObject
        redemptionLimit['limitToSpecificStudentData']['students'] = classStudents
        let newRedemptionLimit = { ...redemptionLimit }
        setCouponObject({ ...couponObject, redemptionLimit: newRedemptionLimit })

        setOpenAddUser(false)
    }

    return (
        <>
            <div className='DrawerFormContainer'>




                {/* ==== Coupon code Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel FieldFlexBox' style={{ marginTop: couponErrorObject?.code !== "" && '-20px' }}>
                        Coupon code<span className='requiredAsterisk'>*</span>
                        <Tooltip
                            TooltipText="This code is case in-sensitive and must be unique across all active promotion codes."
                            Tooltip={<HelpOutlineOutlinedIcon />}
                        />

                    </div>

                    <div className='FloatingFormField'>

                        <TextField
                            value={couponObject?.code}
                            placeholder="COUPON"
                            helperText={couponErrorObject?.code}
                            error={couponErrorObject?.code === "" ? false : true}
                            handleChange={(val, e) => onChangeHandler(val, e, 'code')}
                            countInside={true}
                            count={10}
                        />
                    </div>

                </div>
                {/* ==== Coupon code End ===*/}


                {/* ==== Status Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel FieldFlexBox'>
                        Status<span className='requiredAsterisk'>*</span>

                    </div>

                    <div className='FloatingFormField'>

                        <SelectField
                            validation={true}
                            options={selectValue}
                            handleChange={(e) => onChangeHandler(e.target.value, e, 'status')}
                            selectedOption={couponObject?.status}
                            helperText={couponErrorObject?.status}
                            error={couponErrorObject?.status}
                        />
                    </div>

                </div>
                {/* ==== Status End ===*/}


                {/* ==== Coupon code Start ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel' style={{ marginTop: '6px' }}>
                        Type<span className='requiredAsterisk'>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="female"
                                name="radio-buttons-group"
                                value={couponObject?.discountType}
                                onChange={(e) => onChangeHandler(e.target.value, e, 'discountType')}
                                sx={{
                                    "& .MuiRadio-root": {
                                        padding: '4px !important'
                                    }
                                }}
                            >
                                <FormControlLabel value="percentage" control={<Radio />} label="Percentage discount" />
                                <FormControlLabel value="fixed" control={<Radio />} label="Fixed amount discount" />
                            </RadioGroup>
                        </FormControl>
                    </div>

                </div>
                {/* ==== Coupon code End ===*/}



                {/* ==== Coupon code Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel'>
                        {(couponObject?.discountType == "percentage") ? <span>Percentage off<span className='requiredAsterisk'>*</span></span> : <span>Discount amount<span className='requiredAsterisk'>*</span></span>}
                    </div>

                    <div className='FloatingFormField'>
                        <PriceTextField
                            handleChange={(e) => onChangeHandler(e.target.value, e, 'discountValue')}
                            values={couponObject?.discountValue}
                            // values={couponObject?.discountValue === 0 && ""}
                            placeholder="0"
                            helperText={couponErrorObject?.discountValue}
                            error={couponErrorObject?.discountValue == "" ? false : true}
                            startAdornment={(couponObject?.discountType !== "percentage") && "$"}
                            endAdornment={(couponObject?.discountType === "percentage") && "%"}
                        />
                        {(couponErrorObject?.discountValue) ? <FormHelperText sx={{ color: '#ba1b1a' }} >{couponErrorObject?.discountValue}</FormHelperText> : ""}
                    </div>


                </div>
                {/* ==== Coupon code End ===*/}

                {/* ==== Coupon code Start ===*/}
                {/* <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel FieldFlexBox'>
                        Fee or plans
                        <Tooltip
                            TooltipText="please add a tooltip message here."
                            Tooltip={<HelpOutlineOutlinedIcon />}
                        />
                    </div>

                    <div className='FloatingFormField'>
                        <SelectField
                            options={optionsCustom}
                            handleChange={handleChanges}
                            selectedOption="months"
                            placeholder="Select academic year"
                        />

                        <div className='FieldFlexBox' style={{ marginTop: "15px", }}>
                            <CheckBox
                                label="Apply the coupon automatically"
                                checked={state.checkedB}
                                onChange={handleChangeCheck}
                                color="primary"
                            />
                            <div style={{ marginLeft: "-10px" }}>
                                <Tooltip
                                    TooltipText="please add a tooltip message here."
                                    Tooltip={<HelpOutlineOutlinedIcon />}
                                />
                            </div>
                        </div>

                    </div>

                </div> */}
                {/* ==== Coupon code End ===*/}



                {/* ==== Coupon code Start ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel'>
                        Redemption limits
                    </div>

                    <div className='FloatingFormField'>

                        <div style={{ marginTop: "0px" }}>
                            <CheckBox
                                label="Limit the date range when customers can redeem this coupon"
                                checked={couponObject?.redemptionLimit?.limitDateRange}
                                onChange={(e) => onChangeCheckRedeemHandler(e, 'limitDateRange')}
                                color="primary"
                            />

                            {couponObject?.redemptionLimit?.limitDateRange && <Grid container spacing={2}>

                                <Grid item md={7} xs={12}>
                                    <Datefield
                                        value={couponObject?.redemptionLimit?.limitDateRangeData?.date}
                                        handleChange={(e) => onChangeCheckRedeemDateTimeHandler(e, 'date')}
                                    />
                                </Grid>

                                <Grid item md={5} xs={12}>
                                    <div className='FieldFlexBoxTime'>
                                        <TimePickerField
                                            value={couponObject?.redemptionLimit?.limitDateRangeData?.time}
                                            handleChange={(e) => onChangeCheckRedeemDateTimeHandler(e, 'time')}
                                        />
                                        EST
                                    </div>
                                </Grid>


                            </Grid>}
                        </div>




                        <div style={{ marginTop: "8px" }}>
                            <CheckBox
                                label="Limit the total number of times this coupon can be redeemed"
                                checked={couponObject?.redemptionLimit?.limitCouponUser}
                                onChange={(e) => onChangeCheckRedeemHandler(e, 'limitCouponUser')}
                                color="primary"
                            />

                            {couponObject?.redemptionLimit?.limitCouponUser && <Grid container spacing={2}>

                                <Grid item md={8} xs={12}>
                                    <div style={{ position: 'relative' }} className='timeTextFieldContainer'>
                                        <TextField
                                            placeholder="time"
                                            number="number"
                                            value={couponObject?.redemptionLimit?.limitCouponUserData?.couponUsingLimit}
                                            handleChange={(val, e) => onChangeRedeemCouponLimitHandler(val, e, 'couponUsingLimit')}
                                            countInside={true}
                                        // count={10}
                                        />
                                        <span style={{ position: 'absolute', right: '12px', bottom: '12px' }}>time</span>
                                    </div>
                                </Grid>

                            </Grid>}
                        </div>

                        {/* <div style={{ marginTop: "8px" }}>
                            <CheckBox
                                label="Limit to specific student(s)"
                                checked={couponObject?.redemptionLimit?.limitToSpecificStudent}
                                onChange={(e) => onChangeCheckRedeemHandler(e, 'limitToSpecificStudent')}
                                color="primary"
                            />

                            {couponObject?.redemptionLimit?.limitToSpecificStudent && <Grid container spacing={2}>

                                <Grid item md={12} xs={12}>
                                    <Grid item md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>

                                        <div className="AddField" >
                                            <Button onClick={handleDrawerOpenAddUser}>
                                                <div className="AddIcon">
                                                    <AddRoundedIcon />
                                                </div>
                                                <span style={{ textTransform: "initial" }}>Select users</span>
                                            </Button>
                                            <div
                                                className="UserCount inactiveCount"
                                                // style={{ background: 'blue' }}
                                                style={{
                                                    background: couponObject?.redemptionLimit?.limitToSpecificStudentData?.students?.length === 0 ? '' : '#0450e1',
                                                    color: couponObject?.redemptionLimit?.limitToSpecificStudentData?.students?.length === 0 ? '' : '#fff'
                                                }}
                                            >
                                                {couponObject?.redemptionLimit?.limitToSpecificStudentData?.students?.length}
                                            </div>
                                        </div>

                                    </Grid>
                                </Grid>

                            </Grid>}
                        </div> */}


                    </div>

                </div>
                {/* ==== Coupon code End ===*/}


            </div>



            <SideDrawer
                title="Select Students"
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={OpenDrawerAddUser}
                handleDrawerClose={CloseDrawerAddUser}
                FooterWithoutTab={true}
                ButtonLabel={`Add ${classStudents?.length} Students`}
                clickSaveButton={(e) => addStudentButtonHandler()}
                body={
                    <div>
                        <AddStudents
                            classStudents={classStudents}
                            setClassStudents={setClassStudents}
                            setCouponObject={setCouponObject}
                            couponObject={couponObject}
                        />
                    </div>
                }
            />

        </>
    );
};

export default CreateCoupon;
