import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '../../../components/Form/TextField/TextField';
import { validateFormData } from '../../../utils/form';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import {
  useAddGroupsMutation,
  useGetGroupDetailsQuery,
  useUpdateGroupsMutation,
} from '../../../redux/features/groups/groupsApi';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import Date from '../../../components/Form/Datefield/Datefield';

import Button from '@mui/material/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import '../../../assets/css/TermsForm.css';
import { useCheckClassLevelNameMutation } from '../../../redux/features/classes/classApi';




const CreateClassesLevel = (props) => {

  const [addGroup, addGroupOptions] = useAddGroupsMutation();
  const [updateGroup, updateGroupOptions] = useUpdateGroupsMutation();
  const [checkClassLevelName, checkClassLevelNameOptions] = useCheckClassLevelNameMutation()
  const { name, setName, description, setDescription, closeDrawer, id, errors, setErrors } = props;
  const {
    data: groupDetails,
    isLoading: isGroupDetailsLoading,
    error: groupDetailsError,
  } = useGetGroupDetailsQuery(id, {
    skip: !id,
  });

  useProtected(groupDetailsError);
  useProtected(addGroupOptions.error);
  useProtected(updateGroupOptions.error);
  useProtected(checkClassLevelNameOptions.error);

  const [state, setState] = React.useState({
    checkedA: true,
  });


  useEffect(() => {
    if (id && groupDetails) {
      const data = groupDetails.body.data;
      setName(data.name);
      setDescription(data.description);
    }
  }, [id, groupDetails]);


  const handleInputChange = (e) => (setState) => {
    setState(e.target.value);
    // setErrors((prev) => {
    //   prev[e.target.name] = '';
    //   return prev;
    // });
  };


  const handleAddAttendanceCode = async () => {
    const data = {
      name,
      description,
    };

    const isValid = validateFormData(data, setErrors, {
      name: 'Enter group name',
      description: 'Enter description',
    });

    let checkResult = await checkClassLevelName({ name })

    return false;

    if (!isValid) {
      return;
    }

    let result = null;
    if (id) {
      result = await updateGroup({ ...data, id });
    } else {
      result = await addGroup(data);
    }

    if (result?.data) {
      if (id) {
        toast.success('Group updated successfully');
      } else {
        toast.success('Group added successfully');
      }
      setName('');
      setDescription('');
      closeDrawer();
    } else {
      toast.error(result?.data?.message);
    }
  };


  //=== Term add remove code ==//
  const [inputList, setinputList] = useState([{ firstName: '', lastName: '' }]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setinputList(list);

  }

  const handleremove = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setinputList(list);
  }

  const handleaddclick = () => {
    setinputList([...inputList, { firstName: '', lastName: '' }]);
  }
  //=== Term add remove code ==//

  return (
    <>
      <div className="">
        {isGroupDetailsLoading ||
          addGroupOptions.isLoading ||
          updateGroupOptions.isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                label="Class/Level Name"
                validation={true}
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                count={15}
                name={'name'}
                handleChange={(val, e) => handleInputChange(e)(setName)}
                helperText={errors?.name}
                error={errors?.name}
                value={name}
                TooltipStatus={true}
                TooltipText="Enter your class/grade level for your institution. e.g. Pre-K, K, 1, 2, 3."
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                label="Description"
                // validation={true}
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                count={50}
                name={'description'}
                handleChange={(val, e) => handleInputChange(e)(setDescription)}
                value={description}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid>


          </Grid>
        )}
      </div>
      <DrawerFooterActions
        isLoading={addGroupOptions.isLoading || updateGroupOptions.isLoading}
        handleClose={closeDrawer}
        handleAction={handleAddAttendanceCode}
        closeBtnLabel={'Close'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={id ? 'Update' : 'Save'}
      />
    </>
  );
};

export default CreateClassesLevel;
