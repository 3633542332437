import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import '../../../assets/css/users.css';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Datefield from '../../../components/Form/Datefield/Datefield';
import TimePickerField from '../../../components/Form/TimePicker/TimePicker';

import TextField from '../../../components/Form/TextField/TextField';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import { validateFormData } from '../../../utils/form';
import {
  useAddAttendanceCodeMutation,
  useCheckAttendanceCodeMutation,
  useGetAttendanceCodeByIdQuery,
  useUpdateAttendanceCodeMutation,
} from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';

const AddCode = ({ handleCloseAddCode, type, id }) => {

  const [checkCode, checkCodeOptions] = useCheckAttendanceCodeMutation()
  const [addAttendanceCode, addAttendanceCodeOptions] = useAddAttendanceCodeMutation();
  const [updateAttendanceCode, updateAttendanceCodeOptions] = useUpdateAttendanceCodeMutation();

  const { data: attendanceCodeDetails, isLoading: isAttendanceDetailsLoading, error: attendanceDetailsError } = useGetAttendanceCodeByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  useProtected(attendanceDetailsError);
  useProtected(checkCodeOptions.error);
  useProtected(addAttendanceCodeOptions.error);
  useProtected(updateAttendanceCodeOptions.error);


  const [code, setCode] = useState('');
  const [points, setPoints] = useState(0);
  const [presence, setPresence] = useState('');
  const [codeDetails, setCodeDetails] = useState({});
  const [description, setDescription] = useState('');
  const [codeExist, setCodeExist] = useState(false);
  const [codeCategories, setCodeCategories] = useState([]);
  const [state, setState] = React.useState({ checkedA: true });


  useEffect(() => {
    if (id && attendanceCodeDetails) {
      const data = attendanceCodeDetails.body.data;
      setCode(data.code);
      setDescription(data.description);
      setPresence(data.presence);
      setCodeCategories(data.codeCategories);
      setPoints(data.points);
      setCodeDetails(data)
    }
  }, [id, attendanceCodeDetails]);

  const [errors, setErrors] = useState({
    code: '',
    description: '',
    presence: '',
    codeCategories: '',
  });

  const handleInputChange = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    if (e.target.name === 'presence' && e.target.value === 'Present') {
      setCodeCategories([]);
    }
    setState(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setErrors((prev) => {
      prev['codeCategories'] = '';
      return prev;
    });
    let temp = codeCategories;
    // if (e.target.checked) {
    //   if (!temp.includes(e.target.name)) {
    //     temp = [...temp, e.target.name];
    //     setCodeCategories(temp);
    //   }
    // } else {
    //   if (temp.includes(e.target.name)) {
    //     const newCat = temp.filter((item) => item !== e.target.name);
    //     setCodeCategories(newCat);
    //   }
    // }
    if (e.target.checked) {
      if (!temp.includes(e.target.name)) {
        temp = [e.target.name];
        setCodeCategories(temp);
      }
    } else {
      if (temp.includes(e.target.name)) {
        const newCat = temp.filter((item) => item !== e.target.name);
        setCodeCategories(newCat);
      }
    }
  };

  const handleAddAttendanceCode = async () => {
    const data = {
      code,
      description,
      presence,
      codeCategories,
      points,
    };

    const isValid = validateFormData(data, setErrors, {
      code: 'Enter attendance code',
      description: 'Enter code description',
      presence: 'Enter present type',
      codeCategories: presence === 'Absent' ? 'Enter the code categories' : '',
    });

    if (code) {
      await checkCodeHandler(code)
    }


    if (!isValid || codeExist) {
      return;
    }

    let result = null;
    if (id) {
      result = await updateAttendanceCode({ ...data, id });
    } else {
      result = await addAttendanceCode(data);
    }

    if (!result?.data?.error) {
      if (id) {
        toast.success('Attendance code updated successfully');
      } else {
        toast.success('Attendance code added successfully');
      }
      setCode('');
      setDescription('');
      setPresence('');
      setCodeCategories([]);
      setPoints(0);
      handleCloseAddCode();
    } else {
      toast.error(result?.data?.message);
    }
  };

  const checkCodeHandler = async (code) => {
    let result = await checkCode({ code })
    if (result?.data && result?.data?.body?.data && result?.data?.message === "AttendanceCode found successfully" && ((id && codeDetails?.code !== code) || !id)) {

      setErrors((prevErrors) => ({
        ...prevErrors,
        code: 'Code already exists'
      }));
      setCodeExist(true)
    } else {
      setCodeExist(false)
    }
  }



  return (
    <>
      <div className="">
        {isAttendanceDetailsLoading ||
          addAttendanceCodeOptions.isLoading ||
          updateAttendanceCodeOptions.isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <div className='CodeField'>
                <TextField
                  label="Code"
                  validation={true}
                  CharAlignMid="CharAlignMid"
                  Uppercase="Uppercase"
                  count={3}
                  name={'code'}
                  handleChange={(val, e) => handleInputChange(e)(setCode)}
                  helperText={errors?.code}
                  error={errors?.code}
                  value={code}
                  onBlur={(e) => checkCodeHandler(e.target.value)}
                //value={courseData.Id}
                //handleChange={handleChanges("Id")}
                />
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                label="Description"
                validation={true}
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                count={20}
                name={'description'}
                handleChange={(val, e) => handleInputChange(e)(setDescription)}
                helperText={errors?.description}
                error={errors?.description}
                value={description}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl className="">
                <div className="FieldLabel ">Present status <span>*</span></div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="presence"
                  onChange={(e) => handleInputChange(e)(setPresence)}
                  value={presence}
                >
                  <FormControlLabel
                    value="Present"
                    disabled={code === "P" || code === "A" ? true : false}
                    control={<Radio />}
                    label="Present"
                  />
                  <FormControlLabel
                    value="Absent"
                    disabled={code === "P" || code === "A" ? true : false}
                    control={<Radio />}
                    label="Absent"
                  />
                </RadioGroup>
                {errors.presence && (
                  <p className="error-msg">{errors.presence}</p>
                )}
              </FormControl>
            </Grid>

            {presence === 'Absent' && (
              <Grid item md={12} xs={12}>
                <div className="FieldLabel">Code categories<span>*</span></div>
                <CheckBox
                  label="Excused"
                  name={'Excused'}
                  checked={codeCategories.includes('Excused')}
                  onChange={handleCheckboxChange}
                />

                <CheckBox
                  label="Unexcused"
                  name={'Unexcused'}
                  checked={codeCategories.includes('Unexcused')}
                  onChange={handleCheckboxChange}
                />

                {/* <CheckBox
                  label="Late"
                  name={'Late'}
                  checked={codeCategories.includes('Late')}
                  onChange={handleCheckboxChange}
                /> */}
                {errors.codeCategories && (
                  <p className="error-msg">{errors.codeCategories}</p>
                )}
              </Grid>
            )}

            {/* <Grid item md={6} xs={12}>
              <TextField
                label="Points"
                validation={false}
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                number
                name={'points'}
                handleChange={(val, e) => handleInputChange(e)(setPoints)}
                value={points}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid> */}
          </Grid>
        )}
      </div>

      <DrawerFooterActions
        isLoading={
          addAttendanceCodeOptions.isLoading ||
          updateAttendanceCodeOptions.isLoading
        }
        handleClose={handleCloseAddCode}
        handleAction={handleAddAttendanceCode}
        closeBtnLabel={'CANCEL'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={id ? 'Update' : 'Save'}
      />
    </>
  );
};

export default AddCode;
