import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import TableCells from './TableCells';
import Buttons from '../Form/Button/Button';
import SelectField from '../Form/SelectField/SelectField';
import Filter from './Filter';
import SearchBox from '../Form/SearchBox/SearchBox';
import { Box } from '@mui/system';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import TooltipLabel from '../Form/Tooltip/Tooltip';

import Datefield from '../Form/Datefield/Datefield';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Button from '@mui/material/Button';
import DateRange from '../Form/DateRange/DateRange';



import './Table.css';

import Carosal from './Carosal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    headCellsWeek,
    CheckboxDelete,
    dateSliderData,
    YearLabel,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>



      <TableHead>


        <TableRow className='AttendanceTableHeadRow'>

        </TableRow>

        <TableRow>
          {CheckboxDelete && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
          )}

          {/* ==== WEEK DATE SECTION START ====*/}
          {typeof headCellsWeek !== 'undefined' &&
            headCellsWeek.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'left' : 'right'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  minWidth: headCell.width,
                  width: headCell.width,
                  textAlign: headCell.textAlign,
                }}
                colSpan={headCell.colSpan}
              >
                {headCell.YearStatus &&
                  <div class="AttendanceTableDateIn">{headCell.Year}</div>
                }
                {headCell.DateStatus &&
                  <div className='WeekSldier'>
                    <li className={headCell.DayActicve}>
                      <span>{headCell.Day}</span>
                      <label>{headCell.Date}</label>
                    </li>
                  </div>
                }

              </TableCell>
            ))}

        </TableRow>
        {/* ==== WEEK DATE SECTION END ====*/}

        {/* ==== TABLE TITLE SECTION ====*/}
        <TableRow>
          {CheckboxDelete && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
          )}

          {typeof headCells !== 'undefined' &&
            headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'left' : 'right'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  minWidth: headCell.width,
                  width: headCell.width,
                  textAlign: headCell.textAlign,
                }}
                colSpan={headCell.colSpan}
                className={headCell.class}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}

        </TableRow>
        {/* ==== TABLE TITLE SECTION ====*/}

      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const { innerTitle } = props;
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {innerTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list" style={{ display: 'none' }}>
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const {
    rows,
    col,
    perPageItems,
    handleOpenDialog,
    btnHandleClick,
    iconButtonHandler,
    dateSliderData,
    YearLabel,
    handleDateFilterChange,
    dateFilter,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const RowsPerPage = props.RowsPerPage ? props.RowsPerPage : 5;
  const [rowsPerPage, setRowsPerPage] = React.useState(RowsPerPage);








  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense((event.target.checked = true));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className="Table AttendanceTable">

      <div>
        {props.tableHeader && (
          <div className="TableHeader">
            <h1 className="TableTitle">{props.title}</h1>
            <div className="TableAddButtonContainer">

              {props.AddButtonOutLine && (
                <Buttons
                  label={props.OutLineButtonLabel}
                  buttonColor="#fff"
                  border="#ffb606 1px solid"
                  color="#000000"
                  height="48px"
                  fontSize="14px"
                  link={props.linkButtonOutline}
                  onClick={props.onClickOutlineButton}
                  className="cy_AddButton"
                />
              )}

              {props.AddButton && (
                <Buttons
                  icon={props.icon}
                  label={props.addLabel}
                  buttonColor="#ffb606"
                  color="#000000"
                  height="48px"
                  fontSize="14px"
                  link={props.link}
                  onClick={props.onClick}
                  className="cy_AddButton"
                />
              )}
            </div>

          </div>
        )}

        <div className="TableContainer AttendanceTableContainerMain">
          {props.innerHeader && (
            <div className="TableInnerHeader">
              <div className="TableInnerHeaderLeft">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ flexGrow: 1 }}>
                    <h3>{props.innerTitle}</h3>
                    {props.showSearchBox && (
                      <div className="TableSearchField">
                        <SearchBox
                          placeholder={
                            props.searchBoxPlaceholder
                              ? props.searchBoxPlaceholder
                              : 'Search here'
                          }
                          searchText={props.searchBoxText}
                          handleSearchText={props.handleSearchText}
                          handleSearch={props.handleSearch}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {props.showSearchCount && props.searchBoxText && (
                      <div className="TableSearchField">
                        <span>
                          {rows?.length > 0
                            ? `${rows.length} Results found`
                            : 'No matching records found'}{' '}
                        </span>
                      </div>
                    )}
                  </div>
                </Box>
              </div>
              <div className="TableInnerHeaderRight">
                {props.SelectField && (
                  <SelectField
                    options={props.options}
                    handleChange={props.handleChanges}
                  />
                )}

                {props.FilterButton && <Filter />}

                {props.AddButtonInner && (
                  <Buttons
                    icon={props.InnderButtonIcon}
                    label={props.addLabelInner}
                    buttonColor={props.InnerButtonColor}
                    color={props.InnerButtonTextColor}
                    height="48px"
                    fontSize="14px"
                    link={props.linkInnerButton}
                    onClick={props.onClickInnerButton}
                    // add clas name here
                    className="cy_AddButton"
                  />
                )}
                {props.CheckboxDelete && (
                  <EnhancedTableToolbar numSelected={selected.length} />
                )}
              </div>
            </div>
          )}

          <div className='AddtendanceSubHeader'>
            {props.DateField && (
              <div className='TableDateField'>
                <div className='DateChangeArrowArrowContainer'>
                  <div className='DateChangeArrow'><Button onClick={props.goToPreviousDate}><ArrowBackIosNewRoundedIcon /></Button></div>
                  <div className='DateChangeArrow'><Button onClick={props.goToNextDate}><ArrowForwardIosRoundedIcon /></Button></div>
                </div>
                <Datefield
                  value={props.value}
                  handleChange={props.handleChange}
                  dateFormat={props?.filterDateFormat}
                />

              </div>
            )}
            {props.DateRange && (
              <div className='TableDateField'>
                <div className='DateChangeArrowArrowContainer'>
                  <div className='DateChangeArrow'><Button onClick={props.goToPreviousDate}><ArrowBackIosNewRoundedIcon /></Button></div>
                  <div className='DateChangeArrow'><Button onClick={props.goToNextDate}><ArrowForwardIosRoundedIcon /></Button></div>
                </div>
                <DateRange
                  defaultValue={props.defaultValue}
                  onChange={props.DateRangeOnChange}
                  border="none"
                />
              </div>
            )}


            <div className='AddtendanceSubHeaderRtl'>
              {props.ExportButton && (
                <Buttons
                  label="Export"
                  buttonColor="#fff"
                  border="#C6C6D0 0px solid"
                  color="#5C677D"
                  height="20px"
                  fontSize="14px"
                  onClick={props.onClickExport}
                  icon={<FileUploadOutlinedIcon />}
                />
              )}
              {props.FilterButtonDrawer && (
                <div className='FilterButtonDrawer'>
                  <Buttons
                    icon={<FilterAltIcon />}
                    label="Filter"
                    buttonColor="#fff"
                    border="#C6C6D0 0px solid"
                    color="#5C677D"
                    height="20px"
                    fontSize="14px"
                    filterCountStatus={props.filterCountStatus}
                    filterCountNumber={props.filterCountNumber}
                    onClick={props.onClickFilterButton}
                  />
                </div>
              )}
            </div>
          </div>

          <div style={{ background: "#E8EAED", height: "1px", width: "100%", }}></div>

        </div>






        <Paper className={classes.paper}>
          <TableContainer className="AtndTblContainer">


            {/* <div className='AttendanceTableHead'>
              <div className="AttendanceTableDate"><div className="AttendanceTableDateIn">{YearLabel}</div></div>
              <div className="WeekSldierContainer">
                <Carosal dateSliderData={dateSliderData} />
              </div>
            </div> */}

            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={props.headCells}
                headCellsWeek={props.headCellsWeek}
                CheckboxDelete={props.CheckboxDelete}
                YearLabel={YearLabel}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                dateSliderData={dateSliderData}
              />



              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        {props.CheckboxDelete && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        )}
                        <TableCells
                          cell={row.cell}
                          action={row?.action}
                          handleOpenDialog={handleOpenDialog}
                          btnHandleClick={btnHandleClick}
                          iconButtonHandler={iconButtonHandler}
                        />
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {props.footer && (

            <div className='tablePage TableFooter'>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={<div className='PaginationResultLabel'>Results</div>}
              />
            </div>
          )}
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      </div>
    </div>
  );
}
