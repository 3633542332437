import React, { useEffect } from 'react'
import { useVerifyStripeAccountMutation } from '../../../../redux/features/schools/schoolApi'
import { toast } from 'react-toastify'
const Onboarded = () => {
    const [verifyStripeAccount] = useVerifyStripeAccountMutation()
    const handleVerifyStripeAccount = async () => {
        const result = await verifyStripeAccount()
        if (!result?.data?.error) {
            toast("You are onboarded now")
        } else {
            toast.error("Something went wroSomething went wrong")
        }
    }
    useEffect(() => {
        handleVerifyStripeAccount()
    }, [])

    return (
        <>
            <div className="SignContainer">
                <div className="SignContainerInner">
                    <div className="SignForm">
                        <h1>You are onboarded now</h1>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Onboarded