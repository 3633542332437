import React, { useEffect, useState } from 'react';
import styles from './Notification.module.css';
import Button from '@mui/material/Button';
import { FaRegEdit } from "react-icons/fa";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import AddNotification from './AddNotification';

const Notification = ({ feeObject, setFeeObject, feeErrorObject, setFeeErrorObject }) => {

    const [openAddNotification, setOpenAddNotification] = useState(false);
    const handleDrawerOpenAddNotification = () => {
        setOpenAddNotification(true);
    };
    const handleDrawerCloseAddNotification = () => {
        setOpenAddNotification(false);
    };


    return (
        <>
            <div className={styles.NotificationContainer}>

                {/* Notification Card Start*/}
                <div className={styles.NotificationCard}>
                    <div className={styles.NotificationCardLeft}>
                        <div className={styles.NotificationCardTitle}>
                            <h1>Notification 1</h1>
                            <div className={styles.ChipBox}><span></span>Email</div>
                            <div className={styles.ChipBox}><span></span>SMS</div>
                        </div>
                        <div className={styles.NotificationCardDescription}>7 days before billing date</div>
                    </div>
                    <div className={styles.NotificationCardRight}>
                        <Button className={styles.NotificationActionButton}><FaRegEdit /> Edit</Button>
                    </div>
                </div>
                {/* Notification Card End*/}

                {/* Notification Card Start*/}
                <div className={styles.NotificationCard}>
                    <div className={styles.NotificationCardLeft}>
                        <div className={styles.NotificationCardTitle}>
                            <h1>Notification 2</h1>
                            <div className={styles.ChipBox}><span></span>Email</div>
                        </div>
                        <div className={styles.NotificationCardDescription}>1 day before billing date</div>
                    </div>
                    <div className={styles.NotificationCardRight}>
                        <Button className={styles.NotificationActionButton}><CloseIcon /> Remove</Button>
                        <Button className={styles.NotificationActionButton}><FaRegEdit /> Edit</Button>
                    </div>
                </div>
                {/* Notification Card Start*/}


                <div className={styles.MoreNotificationButtonContainer}>
                    <Button className={styles.NotificationActionButton} onClick={handleDrawerOpenAddNotification}><AddIcon /> More Notification</Button>
                </div>


            </div>



            <SideDrawer
                title="Notification 1"
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={openAddNotification}
                handleDrawerClose={handleDrawerCloseAddNotification}
                FooterWithoutTab={true}
                ButtonLabel="Save"
                body={
                    <div>
                        <AddNotification />
                    </div>
                }
            />

        </>
    );
};

export default Notification;
