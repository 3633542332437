import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

export default function SelectFieldMulti(props) {

    return (
        <Box sx={{ minWidth: 320 }}>
            <div className="FieldLabel">
                {props.labelTop && <label >{props.label}</label>}
                {props.validation && <span>*</span>}
            </div>
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.value}
                    onChange={props.handleChange}
                >
                    {props.statusData.map((select, i) => (
                        <MenuItem
                            key={i}
                            value={select.value}
                        >
                            {props.Status &&
                                <span
                                    style={{
                                        width: "12px",
                                        height: "12px",
                                        display: "inline-block",
                                        borderRadius: "100px",
                                        marginRight: "10px",
                                        background: select.statuscolor
                                    }}
                                >

                                </span>}
                            <span style={{ color: select.statuscolor }}><span style={{ color: props.textcolor }}>{select.select}</span></span>
                        </MenuItem>
                    ))}
                    {props.AddMoreButton &&
                        <div ><Button className="AddMoreButtonDropdown" variant="text" href={props.AddNewlink} onClick={props.handleDrawerOpen ? props.handleDrawerOpen : undefined}> <AddRoundedIcon />{props.AddNewLabel}</Button></div>
                    }
                </Select>
            </FormControl>
        </Box>
    );
}