import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import EmptyCard from '../../../components/EmptyCard/EmptyCard';
import AddCode from './AddCode';
import { useDeleteAttendanceCodeMutation, useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { TrendingUpRounded } from '@mui/icons-material';
import DraggableDialog from '../../../components/hooks/Dialog';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const AttendanceCode = () => {

  const [deleteAttendancecode, deleteAttendanceCodeOptions] = useDeleteAttendanceCodeMutation()
  const { data, isLoading, isError, error } = useGetAllAttendanceCodesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  useProtected(error);
  useProtected(deleteAttendanceCodeOptions.error)

  const [selectedCode, setSelectedCode] = useState('');

  const [OpenAddCode, setAddCode] = useState(false);
  const handleOpenAddCode = (id) => {
    if (!isTheActionPermitted('addEditAttendanceCode')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setSelectedCode(id);
    setAddCode(true);
  };
  const handleCloseAddCode = () => {
    setSelectedCode('');
    setAddCode(false);
  };
  const [rows, setRows] = useState([]);

  const [age, setAge] = React.useState('');
  const [deleteId, setDeleteId] = useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  //======= Delete dialog ===== //


  const onClick = () => {
    alert('onclick working fine!');
  };

  const handleOpenDeleteModal = (id) => {
    if (!isTheActionPermitted('deleteAttendanceCode')) {
      toast.error("You don't have access to this feature")
      return false
    }
    handleOpenDelete()
    setDeleteId(id);
  }

  const undeletableCodes = ['P', 'A', 'L']

  const convertDataToCellRows = (arr) => {



    if (arr.length > 0) {
      return arr.map((item) => {

        let actionTemp = undeletableCodes.includes(item?.code) ? [
          { label: 'Edit', onClick: () => handleOpenAddCode(item._id), },
        ] : [
          { label: 'Edit', onClick: () => handleOpenAddCode(item._id), },
          { label: 'Delete', onClick: (e) => handleOpenDeleteModal(item._id) },
        ]



        return ({
          cell: [
            { textBold: <span style={{ fontWeight: 700 }}>{item?.code ? item?.code : 'N/A'}</span> },
            { text: item?.description ? item?.description : 'N/A' },
            // { text: "Cat name" },
            // { text: item?.points ? item?.points : '0' },
            { text: item?.presence ? item?.presence : 'N/A' },
            {
              ActionButton: true,
              ActionButtonLabel: 'Action',
              ActionButtonsInlineStyles: {
                height: '28px',
                fontWeight: '600'
              }
            },
          ],

          //======= Action Date Start===== //
          action: actionTemp
          //======= Action Date End===== //


        })
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isError]);

  const options = [
    {
      label: 'P',
      value: 'P',
    },

    {
      label: 'A',
      value: 'A',
    },
  ];

  const headCells = [
    {
      id: 'Code',
      numeric: false,
      disablePadding: true,
      label: 'Code',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'Description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
      width: '55%',
      textAlign: 'left',
    },

    // {
    //   id: 'codecategories',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Code Categories',
    //   width: '15%',
    //   textAlign: 'left',
    // },
    // {
    //   id: 'Points',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Points',
    //   width: '10%',
    //   textAlign: 'left',
    // },

    {
      id: 'Clockin',
      numeric: false,
      disablePadding: true,
      label: 'Presence',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
  ];
  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  // const handleAttendanceAdd = (action) => {
  //   action();
  // }

  const deleteAttendanceCodeHandler = async () => {
    const result = await deleteAttendancecode(deleteId);
    if (result?.data) {
      toast.success('Deleted successfully');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setDeleteId('')
  }


  const addCodeHandler = () => {
    if (!isTheActionPermitted('addEditAttendanceCode')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setAddCode(true)
  }

  return (
    <>
      <div className="TableSection">
        <Table
          title="Attendance Code"
          tableHeader={true}
          addLabel="Add Code"
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={(e) => addCodeHandler()}
          footer={true}
          isDataLoading={isLoading}
          RowsPerPage={20}
          emptyTableTitle="No attendance code added yet"
          emptyTableNote="No items available"
          btnLabel="Add Code"
          showButton={true}
          onClickEmptyCard={() => setAddCode(true)}
        />
      </div>

      {/* <EmptyCard
        title="Attendance Code"
        description={<div>No attendance code found on this date.</div>}
        //showButton={true}
        btnLabel="Add Pricing"
      //onClick={handleDrawerOpen}

      /> */}

      {/* Add Code drawer */}
      <SideDrawer
        open={OpenAddCode}
        handleDrawerClose={handleCloseAddCode}
        // clickSaveButton={handleAttendanceAdd}
        title={selectedCode ? 'Edit attendance code' : 'Add attendance code'}
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        hideOuterButton={false}
        ButtonLabel="Save"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={false}
        body={
          <>
            <AddCode
              handleCloseAddCode={handleCloseAddCode}
              id={selectedCode}
            />
          </>
        }
      />
      {/* Delete Group*/}
      {/* <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteAttendanceCodeHandler}
        title="Delete attendance code?"
        body={<div>Are you sure you want to delete this attendance code?<br />Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="DELETE"
        cancelButton="CANCEL"
      /> */}

      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={deleteAttendanceCodeHandler}
      />
    </>
  );
};

export default AttendanceCode;
