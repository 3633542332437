import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './SideDrawer.css';

function SideDrawer(props) {
  const {
    open,
    handleDrawerClose,
    clickSaveButton,
    sideOpen,
    title,
    ButtonLabel,
    body,
    panelTab,
    tabData,
    clickButtonLeft,
    SubDrawer,
    SubDrawerButtonSatus,
    SubDrawerWithOutTabBody,
    SubDrawerTabSatus,
    SubDrawerWithOutTab,
    TitleUnderHeader,
    hideOuterButton,
    clickButtonLeftCloseDrawer = true,
    LeftButtonlink,
    BackArrow,
    CloseArrowHide
  } = props;

  const [defaultChecked, setDefaultChecked] = useState(props.defaultChecked);

  const [value, setValue] = React.useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
    if (typeof props.handleTabChange === 'function') {
      props.handleTabChange(newValue);
    }
    // props.handleTabChange(newValue);
  };

  // const [open, setOpen] = useState(false);
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  // onClick:handleDrawerOpen

  // <SideDrawer
  //   title="Edit Media"
  //   ButtonLabel="Save"
  //   cancelButtonLabel="Cancel"
  //   deleteText="Delete Permanently"
  //   // onClickDelete={onClickDelete}
  //   DeleteButton={true}
  //   sideOpen="right"
  //   open={openEditMedia}
  //   handleDrawerClose={handleDrawerCloseEditMedia}
  //   CheckboxLabel="Add another after saving"
  //   Checkbox={true}
  //   LeftButton={true}
  //   ButtonLeft="Upload New"
  //   ButtonLeftIcon={<FileUploadOutlinedIcon/>}
  //   ButtonLeftbackground="transparent"
  //   ButtonLeftBorder="#E4E2E6 1px solid"
  //   ButtonLeftTextColor="#0450E1"
  //   LeftButtonlink="/invite-new-users"
  //   clickButtonLeft={handleDrawerOpenNewMedia}
  //   FooterWithoutTab={false}
  //   panelTab={panelTab}
  //   tabData={tabData}
  //  arrowIcon={true}
  //   body={
  //     <EditMedia />
  //   }

  // TabsStatus={true}

  // CheckBoxTabsStatus={true}
  // TabsPannelStatus={true}
  //CheckBoxTabsLabelStatus

  // SubDrawer="SubDrawer"
  // SubDrawerButtonSatus={true}
  // SubDrawerTabSatus={true}
  // SubDrawerWithOutTab={false}
  // SubDrawerWithOutTabBody={<p>Sub Drawer WithOut Tab Body</p>}

  // TitleUnderHeaderStatus={true}
  // TitleUnderHeader="Edit Payment"
  // SubTitle="Payment Method*"

  // />

  // const tabData = [
  //   {
  //     lebel: "Upload from device",
  //     icon: <InsertDriveFileIcon />,
  //   },

  //   {
  //     lebel: "YouTube video",
  //     icon: <YouTubeIcon />,
  //   },
  // ]

  // const panelTab = [
  //   {
  //     id: 0,
  //     panleBody: <UploadNewMedia
  //       handleTabChange={handleTabChange}
  //       handleSnackbarOpen={handleSnackbarOpen}
  //       setVideoName={setVideoName}
  //       setVideoUrl={setVideoUrl}
  //       setImageName={setImageName}
  //       videoName={videoName}
  //       videoUrl={videoUrl}
  //       imageName={imageName}
  //     />,

  //     SaveButtonLabel: "Upload File",
  //     //clickSaveButton:clickSaveButton,
  //     CancelButtonLabel: "Cancel",

  //     Checkbox: true,
  //     CheckboxLabel: "Add another after saving",
  //     //onClickCheckbox:onClickCheckbox,

  //     DeleteButton: true,
  //     DeleteLebel: "Delete Permanently",
  //     //onClickDelete:onClickDelete,

  //SubDrawerBodyWithTab:"Sub Drawer Body With Tab",

  //   },

  //   {
  //     id: 1,
  //     panleBody: <p>scsdsfvjsdvlds</p>,
  //     SaveButtonLabel: "Import From YouTube",
  //     //clickSaveButton:clickSaveButton,
  //     cancelButtonLabel: "Cancel",
  //   },

  // ]

  // const tabData = [
  //   {
  //     title: <img src={zelle} alt="zelleIcon" width={30} height={30} />,
  //     description:true,
  //     description: "Zelle",
  //     comingSoon:true,
  //   },

  //   {
  //     title: <img src={check} alt="zelleIcon" width={30} height={30} />,
  //     description:true,
  //     description: "Check",
  //     comingSoon:true,
  //     comingSoonText:"Coming soon"
  //   },
  //   {
  //     title: <img src={cash} alt="zelleIcon" width={30} height={30} />,
  //     description:true,
  //     description: "Cash",
  //     comingSoon:true,
  //     disabled:disabled,
  //   },
  // ]

  // const panelTab = [
  //   {
  //     id: 0,
  //     panleBody: <ZellePayment />,
  //     SubDrawerBodyWithTab:<p>Sub Drawer Body With Tab </p>,
  //   },

  //   {
  //     id: 1,
  //     panleBody: <CheckPayment />,
  //     SubDrawerBodyWithTab:"Sub Drawer Body With Tab",
  //   },

  //   {
  //     id: 2,
  //     panleBody: <CashPayment />,
  //     SubDrawerBodyWithTab:"Sub Drawer Body With Tab",
  //   },

  // ]

  const [isActive, setActive] = useState('true');
  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className="MainDrawer">
      <Drawer
        anchor={sideOpen}
        open={open}
        handleDrawerClose={handleDrawerClose}
        className="MainDrawerWrapper"
      >

        {/* <div className="MuiModal-backdrop-Custom" onClick={handleDrawerClose}></div> */}

        <div className={isActive ? SubDrawer : 'SubDrawerActive'}>
          <div className="DrawerContainer">
            <div className="DrawerHeader">

              <div className="DrawerHeaderTitle">
                {BackArrow &&
                  <Button className="DrawerBackButton" onClick={handleDrawerClose}><ArrowBackIcon fontSize='small' /></Button>
                }
                {title}
              </div>
              <div>

                {SubDrawerButtonSatus && (
                  <>
                    <Button
                      className="DrawerSummaryButton CloseSummary"
                      onClick={handleToggle}
                    >
                      Close Summary
                    </Button>
                    <Button
                      className="DrawerSummaryButton ShowSummary"
                      onClick={handleToggle}
                    >
                      Show Summary
                    </Button>
                  </>
                )}
                {CloseArrowHide ? <></> :
                  <Button className='DrawerCloseIcon' onClick={handleDrawerClose}>
                    <CloseRoundedIcon />
                  </Button>
                }
              </div>
            </div>
            {props.TitleUnderHeaderStatus && (
              <div className="DrawerHeaderTitleUnderHeader">
                {TitleUnderHeader}
              </div>
            )}

            {props.TabsStatus && (
              <div className="DrawerTabsContainer">
                <Tabs value={value} onChange={onChange}>
                  {tabData &&
                    tabData.map((item, i) => (
                      <Tab
                        label={
                          <div className="tabsContent">
                            {item.icon}
                            {item.lebel}
                            {
                              item.filterCountStatus ?
                                <div className='filterCount'>{item.filterCountNumber}</div> : ""
                            }
                          </div>
                        }
                      />
                    ))}
                </Tabs>
              </div>
            )}

            {props.CheckBoxTabsStatus && (
              <div className={props.paddingRemove === true ? 'CheckBoxTabs CheckBoxTabsPaddingRemove' : 'CheckBoxTabs'}>
                {props.CheckBoxTabsLabelStatus &&
                  <div class="FieldLabel">{props.SubTitle}</div>
                }
                <Tabs value={value} onChange={onChange}>
                  {tabData &&
                    tabData.map((item, i) => (
                      <Tab

                        disabled={item.disabled}
                        label={
                          <div className="RedioContents">
                            <div className="RedioIcon">
                              <FiberManualRecordOutlinedIcon className="UnSelectedRedio" />
                              <CheckCircleIcon className="SelectedRedio" />
                              {/* <FiberManualRecordIcon className="SelectedRedio" /> */}
                            </div>
                            <div className="RedioLebel">
                              <h3 className='TabTitle'>{item.title} {item.comingSoon && <div className='TabComingSoon'>{item.comingSoonText}</div>}</h3>
                              {item.description &&
                                <p>{item.description}</p>
                              }
                            </div>
                          </div>
                        }
                      />
                    ))}
                </Tabs>
              </div>
            )}

            {props.TabsPannelStatus && (
              <>
                {panelTab &&
                  panelTab.map((item, i) => (
                    <div className="TabPanel">
                      <TabPanel value={value} index={item.id}>
                        <div className="MDC">
                          <div className="DrawerBody" style={{ padding: props.padding, width: props.drawerWidth, }}>{item.panleBody}</div>

                          {SubDrawerTabSatus && (
                            <div className="SubDrawerBody">
                              <div className="SubDrawerContainer">
                                {item.SubDrawerBodyWithTab}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='DrawerFooterContainer'>
                          <div className="DrawerFooter TabFooter">
                            <div className="DrawerFooterInner">
                              {item.Checkbox && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      defaultChecked={defaultChecked}
                                      onClick={item.onClickCheckbox}
                                    />
                                  }
                                  label={item.CheckboxLabel}
                                />
                              )}
                              {item.DeleteButton && (
                                <Button
                                  onClick={() => item.onClickDelete()}
                                  variant="text"
                                  style={{
                                    color: '#BA1A1A',
                                    textTransform: 'initial',
                                  }}
                                >
                                  {item.DeleteLebel}
                                </Button>
                              )}
                              <span></span>
                              <div className="DrawerFooterButton">
                                <Button
                                  className="DrawerCloseButton"
                                  onClick={handleDrawerClose}
                                >
                                  {item.CancelButtonLabel}
                                </Button>
                                <Button
                                  className="DrawerActionButton"
                                  onClick={item.clickSaveButton}
                                  disabled={item.disabled ? item.disabled : undefined}
                                >
                                  {item.SaveButtonLabel}{' '}
                                  {item.arrowIcon && <ArrowForwardRoundedIcon />}
                                </Button>
                                <Button
                                  className="DrawerCloseButtonMobile"
                                  onClick={handleDrawerClose}
                                >
                                  {item.CancelButtonLabel}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  ))}
              </>
            )}

            <div className="MDC" style={{ width: props.MDCwidth }}>
              <div className="DrawerBody" style={{ padding: props.padding, width: props.drawerWidth, minWidth: props.drawerMinWidth, maxWidth: props.drawerMaxWidth }}>
                {body}
              </div>

              {SubDrawerWithOutTab && (
                <div className="SubDrawerBody">
                  <div className="SubDrawerContainer">
                    {SubDrawerWithOutTabBody}
                  </div>
                </div>
              )}
            </div>

            {/* <div className="DrawerFooterContainer"> */}
            {props.FooterWithoutTab && (
              <div className="DrawerFooter">
                <div className="DrawerFooterInner">
                  {props.Checkbox && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={defaultChecked}
                          onClick={props.onCheckboxClicked}
                        />
                      }
                      label={props.CheckboxLabel}
                    />
                  )}

                  {props.DeleteButton && (
                    <Button
                      onClick={() => props.onClickDelete()}
                      variant="text"
                      style={{ color: '#BA1A1A', textTransform: 'initial' }}
                    >
                      {props.deleteText}
                    </Button>
                  )}
                  {props.LeftButton && (
                    <span onClick={() => {
                      if (clickButtonLeftCloseDrawer) {
                        handleDrawerClose()
                      }
                    }
                    }>
                      <Button
                        className="DrawerActionButton"
                        onClick={clickButtonLeft}
                        style={{ background: props.ButtonLeftbackground, border: props.ButtonLeftBorder, color: props.ButtonLeftTextColor }}
                        href={LeftButtonlink}
                      >
                        <span className="DrawerActionButtonLeftIcon">
                          {props.ButtonLeftIcon}
                        </span>
                        {props.ButtonLeft}
                      </Button>
                    </span>
                  )}
                  <span></span>

                  {!hideOuterButton && (
                    <div className="DrawerFooterButton">
                      <Button
                        className="DrawerCloseButton"
                        onClick={props.handleDrawerCloseButtonBottom ? props.handleDrawerCloseButtonBottom : handleDrawerClose}
                      >
                        {props.cancelButtonLabel}
                      </Button>
                      <Button
                        className="DrawerActionButton"
                        onClick={clickSaveButton}
                        disabled={props.disabledBtn}
                        sx={{ padding: props.saveButtonPadding, background: `${props.BtnColor} !important`, color: `${props.BtnTextcolor} !important` }}
                      >
                        {props.ButtonLabel}{' '}
                        {props.arrowIcon && <ArrowForwardRoundedIcon />}
                      </Button>
                      <Button
                        className="DrawerCloseButtonMobile"
                        onClick={handleDrawerClose}
                      >
                        {props.cancelButtonLabel}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* </div> */}
          </div>
        </div>

      </Drawer>
    </div >
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default SideDrawer;
