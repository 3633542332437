
import React, { useEffect, useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
// import FieldPassword from '../../../components/FieldPassword/FieldPassword';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import FieldPassword from '../../components/Form/FieldPassword/FieldPassword';
import '../../assets/css/form.css';
import axiosApi from '../../utils/axios';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setToken, setUserData, setFirstTimeLogin, setRolesWithPermissions } from '../../redux/features/authSlice';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
// import { validateFormData } from '../../utils/form';
// import config from '../../config';
import LogoHeader from './LogoHeader/LogoHeader';
import emailValidation from '../../hooks/HelperFunctions';
import DraggableDialog from '../../components/hooks/Dialog';
import useInactivity from '../../customhooks/useInactivity';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { useSignApiDataMutation } from '../../redux/features/signApi/signApi';
// import { GoogleRecaptchaExample } from './Practice';
// import { async } from 'q';

function SignIn(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//



  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });

  const isValidPassword = async () => {
    if (password == "") {
      setErrors({ ...errors, password: 'Enter your password' })
      return false
    }
    return true
  }

  const isValidEmail = async () => {
    let enteredEmail = email ? email.toLowerCase() : ''

    if (enteredEmail == "") {
      setErrors({ ...errors, email: 'Enter your email' })
      return false
    }
    let isValidEmail = emailValidation(enteredEmail);
    if (!isValidEmail) {
      setErrors({ ...errors, email: 'Email address is not valid' })
      return false
    }
    return true
  }

  const handleSignIn = async () => {
    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
        ///===================== recapcha ==============================///
        const data = {
          email: email ? email.toLowerCase() : '',
          password,
          token
        };
        let isPasswordValid = await isValidPassword()
        let isEmailValid = await isValidEmail()


        if (!isEmailValid || !isPasswordValid) {
          return;
        }
        try {
          const result = await axiosApi.post('/users/public/login', data);

          if (result?.data?.error) {
            setAlertOptions({
              msg: "Sorry, we don't recognize that email or password. You can try again or reset your password",
              type: 'error',
            });
            return;
          }


          if (result.data.body.data.user.enableMFA) {
            navigate(
              `/verify-email?userId=${result.data.body.data.user._id}&mfa=true`,
              { state: { ...location.state, token: result.data.body.data.token } },
            );
            return;
          }





          setAlertOptions({
            msg: 'User logged in successfully',
            type: 'success',
          });


          let userInfo = result?.data?.body?.data?.user

          let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `/schools/getSingle/${userInfo.schoolId}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${result.data.body.data.token}`
            }
          };

          const resultSchool = await axiosApi.request(config)
          if (resultSchool?.data?.error) {
            setAlertOptions({
              msg: resultSchool.data.message,
              type: 'error',
            });
            return;
          }
          // console.log(resultSchool?.data?.body?.data, 328)
          userInfo.dba = resultSchool?.data?.body?.data.dba

          //================= add school info on user data =============================//
          userInfo.schoolName = resultSchool?.data?.body?.data?.name
          userInfo.schoolCreatedDate = resultSchool?.data?.body?.data?.createdAt
          userInfo.schoolUniqueID = resultSchool?.data?.body?.data?.schoolUniqueID
          //================= add school info on user data =============================//

          config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `/roles/getRolesFromIds`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${result.data.body.data.token}`
            },
            data: {
              ids: userInfo?.roles
            }
          };

          let rolesAndPermissionsData = []

          if (userInfo?.roles?.length > 0) {
            const rolesWithPermissions = await axiosApi.request(config);
            if (rolesWithPermissions?.data?.body?.data) {
              rolesAndPermissionsData = rolesWithPermissions?.data?.body?.data
            }
          }

          dispatch(setUserData(userInfo));
          dispatch(setToken(result.data.body.data.token));
          dispatch(setFirstTimeLogin(true))
          dispatch(setRolesWithPermissions(rolesAndPermissionsData))

          localStorage.setItem('user', JSON.stringify(userInfo));
          localStorage.setItem('token', result.data.body.data.token);
          localStorage.setItem('rolesWithPermissions', JSON.stringify(rolesAndPermissionsData));

          navigate(location.state?.from ? location.state?.from : '/');
          window.location.reload();
        } catch (err) {

          setAlertOptions({
            msg: err.message,
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    });


  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  const signUpHandler = () => {
    navigate("/sign-up")
  }

  const [openDialog, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('inactive', false);
  };
  useEffect(() => {
    const inactive = localStorage.getItem("inactive")
    if (inactive === "true") {
      setOpen(true)
    }
  }, [])
  const handleClosefgfdg = () => {
    alert(3454)
  }

  return (
    <>
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Sign in</h1>

            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />

            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <TextField
                  placeholder="Email address"
                  required={true}
                  email={true}
                  value={email}
                  handleChange={(value) => {
                    if (value == "") {
                      setErrors((prev) => {
                        prev.email = 'Enter your email';
                        return prev;
                      });
                    } else {
                      setErrors((prev) => {
                        prev.email = '';
                        return prev;
                      });
                    }
                    setEmail(value);
                  }}
                  helperText={errors?.email}
                  error={errors?.email}
                //error
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword
                  required={true}
                  value={password}
                  handleChange={(value) => {
                    if (value == "") {
                      setErrors((prev) => {
                        prev.password = 'Enter your password';
                        return prev;
                      });
                    } else {
                      setErrors((prev) => {
                        prev.password = '';
                        return prev;
                      });
                    }
                    setPassword(value);
                  }}
                  helperText={errors?.password}
                  error={errors?.password}
                  placeholder="Password"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Link className="ForgotPassword" to="/forgot-password">
                  Forgot your password?
                </Link>
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Sign In"
                  buttonColor="#0450e1"
                  border="#0450e1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  fontWeight="700"
                  link=""
                  onClick={() => handleSignIn()}
                />
              </Grid>

              {/* <Grid item xs={12} md={12}>
                <div className="DontHaveAccount">
                  <span>Don't have an account?</span>
                </div>
              </Grid> */}

              {/* <Grid item xs={12} md={12}>
                <div style={{ marginBottom: "16px" }}>
                  <Buttons
                    label="Sign up"
                    buttonColor="#fff"
                    border="#FFB606 1px solid"
                    color="#000000"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    // link="/sign-up"
                    onClick={() => signUpHandler()}
                  />
                </div>
              </Grid> */}
            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>
        <div className='OuthFormBottomText'>
          <span> This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy"> Privacy Policy </a> and
            <a href="https://policies.google.com/terms"> Terms of Service </a> apply.</span>
        </div>
      </div>

      {/* <GoogleRecaptchaExample /> */}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleClose}
        title="Session Expired!!"
        body={
          <div>If you want to continue, please log in again</div>
        }
        ModalFooter={true}
        oneButton={true}
        handleConfirmCloseDialog={handleClosefgfdg}
        actionButton="Remove"
      />

    </>
  );
}

export default SignIn;


