import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import axios from 'axios';

import ParentStudentRoutes from './routing/ParentStudentRoutes';

import './App.css';

/** public pages start */
import SignIn from './pages/authentication/SignIn';
import SignUp from './pages/authentication/SignUp';
import CreateNewPassword from './pages/authentication/CreateNewPassword';
import ForgotPassword from './pages/authentication/ForgotPassword';
import Otp from './pages/authentication/Otp';
import ResetPassword from './pages/authentication/ResetPassword';
import ForgotPasswordEmailSent from './pages/authentication/ForgotPasswordEmailSent';
import SignInAddPhoneNumber from './pages/authentication/SignInAddPhoneNumber';
/** public pages end */

/** Example Pages */
import ListingPage from '../src/pages/example-pages/listing-page/ListingPage';
import FormsExample from '../src/pages/example-pages/forms/Forms';
import ParentPortal from './pages/parentPortal/ParentPortal';
// import CourseDetails from '../src/pages/example-pages/courses/details/DetailsPage';

/** Components links */
import TableData from '../src/components/Table/TableData';
import SchoolRoute from './routing/SchoolRoute';
import SchoolRouteWithoutLayout from './routing/SchoolRouteWithoutLayout';
import SchoolPortal from './pages/schoolPortal/SchoolPortal';
/** Components links */

// ** ------------------------- Parent Portal start --------------------------------- **//

// ** ------------------------- Parent Portal end   --------------------------------- **//

// ** ------------------------- School Portal start --------------------------------- **//
import Id from './pages/schoolPortal/id/Id';
import OrderId from './pages/schoolPortal/orderId/orderId';
import Attendance from './pages/schoolPortal/attendance/Attendance';
import AttendanceHistory from './pages/schoolPortal/attendance/AttendanceHistory';
import AttendanceCode from './pages/schoolPortal/attendance/AttendanceCode';
import AttendanceHistoryDetails from './pages/schoolPortal/attendance/AttendanceHistoryDetails';
import AttendanceSettings from './pages/schoolPortal/attendance/AttendanceSettings';

import UsersListing from './pages/schoolPortal/users/UsersListing';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setUserData } from './redux/features/authSlice';
import AdminRoute from './components/ProtectedRoutes/AdminRoute';
import InvitedUsers from './pages/schoolPortal/users/InvitedUsers';
import InviteNewUsers from './pages/schoolPortal/users/InviteNewUsers';
import UsersDetails from './pages/schoolPortal/users/UsersDetails';
import UsersDetailsUnique from './pages/schoolPortal/users/UsersDetailsUnique';

import RolesPermissions from './pages/schoolPortal/users/RolesPermissions';


import Groups from './pages/schoolPortal/users/Groups';
import GroupDetails from './pages/schoolPortal/users/GroupDetails';
import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView';
import Settings from './pages/schoolPortal/settings/SettingsPage';
import AccountVrify from "./pages/authentication/AccountVrify";
import OrgSettingsPage from './pages/schoolPortal/orgsettings/OrgSettingsPage';
import ApplicationListing from './pages/schoolPortal/users/Applications/ApplicationListing';
import ApplicationDetials from './pages/schoolPortal/users/Applications/ApplicationDetials';


import Dashboard from './pages/schoolPortal/orgsettings/FeesPlans/Dashboard';
import Transactions from './pages/schoolPortal/orgsettings/FeesPlans/Transactions';
import FeesPlans from './pages/schoolPortal/orgsettings/FeesPlans/FeesPlans';




// import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView-Backup';
// ** ------------------------- School Portal end   --------------------------------- **
// import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView';

// Classes
import CoursesListing from './pages/schoolPortal/courses/CoursesListing';
import DetailsPage from './pages/schoolPortal/courses/Details';
import VerifyOtp from './pages/authentication/VerifyOtp';

import ParentProfile from './pages/schoolPortal/users/ParentProfile';

import ProfileSuccess from './pages/schoolPortal/users/ProfileSuccess';

import SchoolYears from './pages/schoolPortal/schoolYears/SchoolYears';
import ClassesLevel from './pages/schoolPortal/classesLevel/ClassesLevel';
import ClassesLevelDetails from './pages/schoolPortal/classesLevel/ClassesLevelDetails';
import Test from './components/Test/Test';
import SubjectArea from './pages/schoolPortal/courses/SubjectArea';


import Messages from './pages/schoolPortal/messages/Messages';
import MessagesDetails from './pages/schoolPortal/messages/MessagesDetails';
import Templates from './pages/schoolPortal/messages/Templates';
import Categories from './pages/schoolPortal/messages/Categories';

import InviteUsersPage from './pages/schoolPortal/users/InviteUsersPage';

import CourseDetails from './pages/schoolPortal/courses/CourseDetails';
import { isTheActionPermitted, isTheSectionPermitted } from './hooks/HelperFunctionsPermission';
import NotPermitted from './pages/authentication/NotPermitted';
import ClassDetails from './pages/schoolPortal/courses/ClassDetails';
import Details from './pages/schoolPortal/courses/Details';

// ===== Components Links ========//
import DateRangeData from './components/Form/DateRange/DateRangeData';
import PageTitle from './components/PageTilte/PageTitle';
import Calendars from './pages/schoolPortal/calendars/Calendars';
import SchoolCalendar from './pages/schoolPortal/calendars/SchoolCalendar';
import SchoolCalendarCategories from './pages/schoolPortal/calendars/Categories';
import AcademicsSettings from './pages/schoolPortal/calendars/AcademicsSettings';
import Onboarded from './pages/schoolPortal/orgsettings/Payment/Onboarded';
import useInactivity from './customhooks/useInactivity';
import DraggableDialog from './components/hooks/Dialog';






// ** ------------------------- School Portal end   --------------------------------- **//

function App() {
  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//
  const envType = process.env.REACT_APP_ENV

  // const { isInactive } = useInactivity(10000);
  const { isInactive } = useInactivity(3600000);


  console.log(isInactive, 509)

  return (
    <>
      <PageTitle />
      <Routes>
        {/* ExmaplePages start */}

        {/* <Route path="/" element={<AdminRoute><ListingPage /></AdminRoute>} />     */}
        {/* <Route
        path="/"
        element={
          <SchoolRoute>
            <Attendance />
          </SchoolRoute>
        }
      /> */}
        {/* <Route path="/" element={<ListingPage />} />     */}
        <Route path="/listing-example" element={<ListingPage />} />
        <Route path="/forms" element={<FormsExample />} />
        <Route path="/table" element={<TableData />} />
        <Route path="/test" element={<Test />} />
        {/* ExmaplePages end */}

        {/* public routes start */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/authentication/signin" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/verify-email" element={<Otp />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/add-phone-number" element={<SignInAddPhoneNumber />} />
        <Route
          path="/email-sent"
          element={<ForgotPasswordEmailSent />}
        />

        <Route path="/verified-screen" element={<AccountVrify />} />
        <Route path="/stripe_account_link" element={<Onboarded />} />
        {/* public routes end */}

        {/* Parent Student Portal Start */}
        <Route path="/parent-portal" element={<ParentPortal />} />
        {/* Parent Student Portal end.  */}

        {/* School Portal Start */}
        <Route path="/school-portal" element={<SchoolRoute> <SchoolPortal /> </SchoolRoute>} />
        <Route path="/" element={isTheSectionPermitted('attendance') ? <SchoolRoute collapsed={true}><Attendance /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/attendance-history" element={isTheSectionPermitted('attendance') ? <SchoolRoute collapsed={true}>  <AttendanceHistory /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/attendance-code" element={isTheSectionPermitted('attendance') ? <SchoolRoute collapsed={true}> <AttendanceCode /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/attendance-history-details" element={isTheSectionPermitted('attendance') ? <SchoolRoute collapsed={true}> <AttendanceHistoryDetails /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/attendance-settings" element={isTheSectionPermitted('attendance') ? <SchoolRoute collapsed={true}> <AttendanceSettings /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />


        <Route path="/id" element={<SchoolRoute><Id /></SchoolRoute>} />
        <Route path="/order-id" element={<SchoolRoute><OrderId /></SchoolRoute>} />
        <Route path="/users" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUsers')) ? <SchoolRoute collapsed={true}><UsersListing /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/groups" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUserGroups')) ? <SchoolRoute collapsed={true}> <Groups /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/invite-users" element={(isTheSectionPermitted('users') && isTheActionPermitted('inviteUsers')) ? <SchoolRoute collapsed={true}> <InviteUsersPage /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/roles-permissions" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageRolesPermissions')) ? <SchoolRoute collapsed={true}> <RolesPermissions /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        {/* <Route path="/users/:id" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUsers')) ? <SchoolRoute collapsed={true}><UsersDetails /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} /> */}
        <Route path="/users/:id" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUsers')) ? <UsersDetails /> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/invited-users" element={isTheSectionPermitted('users') ? <SchoolRoute collapsed={true}> <InvitedUsers /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/group-details/:group_id" element={isTheSectionPermitted('users') ? <SchoolRoute collapsed={true}> <GroupDetails /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/invite-new-users" element={isTheSectionPermitted('users') ? <SchoolRoute collapsed={true}> <InviteNewUsers /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/users_u/:unique_id" element={<SchoolRoute collapsed={true}><UsersDetailsUnique /></SchoolRoute>} />

        {/****************************** settings url **************************/}
        <Route path="/settings"
          element={isTheSectionPermitted('settings') ? <SchoolRoute> <OrgSettingsPage /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
        {/****************************** settings url **************************/}

        <Route path="/profile" element={<SchoolRoute> <Settings /> </SchoolRoute>} />
        <Route path="/parent-profile" element={<SchoolRoute> <ParentProfile /> </SchoolRoute>} />
        <Route path="/profile-saved-successfully" element={<SchoolRoute> <ProfileSuccess /> </SchoolRoute>} />
        <Route path="/media-library-table-view" element={<SchoolRoute> <MediaLibraryTableView /> </SchoolRoute>} />

        {/********************  courses url start  **********************************/}
        <Route path="/courses" element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageCourses')) ? <SchoolRoute collapsed={true}><CoursesListing /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/course-details"
          element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageCourses')) ? <SchoolRouteWithoutLayout><CourseDetails /></SchoolRouteWithoutLayout> : <SchoolRouteWithoutLayout><NotPermitted /></SchoolRouteWithoutLayout>}
        />
        <Route path="/course-details/:course_id" element=
          {(isTheSectionPermitted('academics') && isTheActionPermitted('manageCourses')) ? <SchoolRouteWithoutLayout><CourseDetails /></SchoolRouteWithoutLayout> : <SchoolRouteWithoutLayout><NotPermitted /></SchoolRouteWithoutLayout>}
        />
        <Route path="/section-details/:course_id/:section_id" element={<SchoolRouteWithoutLayout><Details /></SchoolRouteWithoutLayout>} />

        <Route path="/subject-area"
          element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageSubjectArea')) ? <SchoolRoute collapsed={true}><SubjectArea /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>}
        />
        <Route path="/academic-years"
          element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageAcademicYears')) ? <SchoolRoute collapsed={true}><SchoolYears /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>}
        />
        <Route path="/classes-level"
          element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageClassLevel')) ? <SchoolRoute collapsed={true}><ClassesLevel /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>}
        />
        <Route path="/classes-level-details/:class_level_id"
          element={(isTheSectionPermitted('academics') && isTheActionPermitted('manageClassLevel')) ? <SchoolRoute collapsed={true}><ClassesLevelDetails /></SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>}
        />
        {/********************  courses url start  **********************************/}

        <Route path="/messages" element={isTheSectionPermitted('messages') ? <SchoolRoute collapsed={true}> <Messages /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/messages-details/:message_id" element={isTheSectionPermitted('messages') ? <SchoolRoute collapsed={true}> <MessagesDetails /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/templates" element={isTheSectionPermitted('messages') ? <SchoolRoute collapsed={true}> <Templates /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        <Route path="/categories" element={isTheSectionPermitted('messages') ? <SchoolRoute collapsed={true}> <Categories /> </SchoolRoute> : <SchoolRoute collapsed={true}><NotPermitted /></SchoolRoute>} />
        {/* School Portal end.  */}



        {/* Components Routs Start.  */}
        <Route path="/date-range" element={<DateRangeData />} />
        {/* Components Routs End.  */}

        {
          envType !== "prod" && (
            <>
              {/* <Route path="/application-listing" element={<SchoolRoute> <ApplicationListing /> </SchoolRoute>} />
              <Route path="/application-details" element={<ApplicationDetials />} /> */}
            </>

          )
        }




        <Route path="/calendars" element={<SchoolRoute collapsed={true}> <Calendars /> </SchoolRoute>} />
        <Route path="/school-calendar/:id" element={<SchoolRoute collapsed={true}> <SchoolCalendar /> </SchoolRoute>} />
        <Route path="/school-calendar-categories" element={<SchoolRoute collapsed={true}> <SchoolCalendarCategories /> </SchoolRoute>} />
        <Route path="/academics-settings" element={<SchoolRoute collapsed={true}> <AcademicsSettings /> </SchoolRoute>} />

        <Route path="/dashboard" element={<SchoolRoute collapsed={true}> <Dashboard /> </SchoolRoute>} />
        <Route path="/transactions" element={<SchoolRoute collapsed={true}> <Transactions /> </SchoolRoute>} />
        <Route path="/fee-and-plans" element={<SchoolRoute collapsed={true}> <FeesPlans /> </SchoolRoute>} />




      </Routes>
    </>

  );
}

export default App;
