import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Table from '../../../components/Table/Table';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  useGetSearchResultMutation,
  useGetUserListQuery
} from '../../../redux/features/users/userApi';
import { toTitleCase } from '../../../utils/helpers';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import UsersFilter from './UsersFilter';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import { truncateText } from "../../../hooks/HelperFunctions"

const AddUsers = ({ groupDetails, setGroupUsers, groupUsers, addGroupUsersOptions, setGroupUsersToUpdate, groupUsersToUpdate, roleUsers }) => {
  const { data, isLoading, isError, error } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  const [getSearchedUserList, options] = useGetSearchResultMutation();
  const [rows, setRows] = useState([]);
  const [uersAvailable, setUersAvailable] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    if (!isLoading && !isError && data.body.data?.length > 0) {
      const users = data.body.data?.filter((user) =>
        !groupDetails?.users?.some((userData) => userData?.id === user?._id)
      );
      if (roleUsers) {
        const usersExpectParentAndStudent = users?.filter((user) => user?.userType === "faculty" || user?.userType === "staff")
        setRows(convertDataToCellRows(usersExpectParentAndStudent));
        setAllUsers(usersExpectParentAndStudent)
        setUersAvailable(usersExpectParentAndStudent)
      } else {
        setRows(convertDataToCellRows(users));
        setAllUsers(users)
        setUersAvailable(users)
      }

    }
  }, [data, isLoading, isError]);
  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm });
    if (result?.data) {
      const users = result.data.body.data?.filter((user) =>
        !groupDetails?.users?.some((userData) => userData?.id === user?._id)
      );
      setRows(convertDataToCellRows(users));
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  }
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  // =========== search part end ====================== //
  const headCells = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '50%',
      textAlign: 'left',
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'userType',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '25%',
      textAlign: 'left',
    },
    // {
    //   id: 'grade',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Grade',
    //   width: '20%',
    //   textAlign: 'left',
    // },
  ];

  const convertDataToCellRows = (arr) => {
    console.log("arr ==>", arr)
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          { Check: true, checkBoxColor: "success", width: "48px", padding: "0", user_id: item._id, checked: true },
          {
            textBold: <span style={{ fontWeight: 700 }}>{truncateText(`${item.firstName} ${item.lastName}`, 25)}</span>, user_id: item?._id,
            user: {
              name: `${item?.firstName} ${item?.lastName}`,
              id: item?._id,
              userUniqueID: item?.userUniqueID,
              // role: item?.role,
              userType: item?.userType,
              email: item?.email,
              firstName: item?.firstName || "",
              lastName: item?.lastName || "",
              middleName: item?.middleName || "",
              phone: item?.phone || "",
              createdAt: item?.createdAt || "",
              status: item?.status || "",
            }
          },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.userType ? toTitleCase(item?.userType) : "N/A" },
          // { text: item?.grade?.join(',') || "N/A" },
        ]
      }));
    } else {
      return [];
    }
  }

  useEffect(() => {
    let addUsers = []
    groupUsers?.map((users) => {
      let foundUser = uersAvailable.find((user) => user?._id === users)
      if (foundUser) {
        addUsers.push({
          id: foundUser?._id,
          name: `${foundUser?.firstName} ${foundUser?.lastName}`,
          userUniqueID: foundUser?.userUniqueID,
          userType: foundUser?.userType,
          // role: foundUser?.userType,
          email: foundUser?.email,
          firstName: foundUser?.firstName || "",
          lastName: foundUser?.lastName || "",
          middleName: foundUser?.middleName || "",
          phone: foundUser?.phone || "",
          status: foundUser?.status || "",
          createdAt: foundUser?.createdAt || "",
        })
      }
    })
    setGroupUsersToUpdate(addUsers)
  }, [groupUsers])


  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setGroupUsers(prevUsers => [...prevUsers, item[1].user?._id]);
    } else {
      // Remove the user from the array
      setGroupUsers(prevUsers => prevUsers.filter(user => user._id !== item[1].user?._id));

    }
  }

  //================================== filter part ===========================================//
  const status = [
    { id: 1, label: 'In Progress' },
    { id: 2, label: 'Dropped' },
    { id: 3, label: 'Blocked' },
    { id: 4, label: 'Completed' },
    { id: 5, label: 'Unavailable' },
  ];

  const [filterDrawer, setFilterDrawer] = useState(false)
  const [roles, setRoles] = useState([])
  const [streams, setStream] = useState([])
  const [endDate, setEndDate] = useState('')
  const [statuses, setStatuses] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [startDate, setStartDate] = useState('')
  const [classLevel, setClassLevel] = useState([])

  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      if (roleUsers) {
        const usersExpectParentAndStudent = roleData?.body?.data?.filter((user) => user?.name !== "parent" && user?.name !== "student")
        setRoles(usersExpectParentAndStudent);
      } else {
        setRoles(roleData?.body?.data);
      }

    }
  }, [roleData, roleIsLoading, roleIsError]);

  const handleCloseDrawer = () => {
    setFilterDrawer(false)
    setRows(convertDataToCellRows(allUsers));
    setStatuses([])
    setUserTypes([])
    setClassLevel([])
    setSearchTerm("")
  }
  const filterHandler = async () => {
    let dataToSubmit = {
      term: searchTerm,
      status: statuses,
      userType: userTypes,
      classLevel: classLevel
    }
    const result = await getSearchedUserList(dataToSubmit);
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
      setFilterDrawer(false)
    }
  }

  //================================== filter part ===========================================//

  return (
    <>
      <div className="TableSection">
        <Table
          YearLabel="May 2023"
          headCells={headCells}
          rows={rows}
          innerHeader={true}

          footer={true}
          RowsPerPage={20}
          showSearchBox={true}
          handleRowClick={handleRowClick}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          isDataLoading={isLoading || addGroupUsersOptions.isLoading}
          handleCheckItem={setGroupUsers}
          selectedCheckList={groupUsers}
          customCellObjectId="user_id"
          CheckboxDelete={true}

          FilterButtonDrawer={true}
          FilterButtonTop={true}
          onClickFilterButton={() => setFilterDrawer(true)}
          filterCountStatus={(statuses?.length + userTypes?.length + classLevel?.length) > 0}
          filterCountNumber={(statuses?.length + userTypes?.length + classLevel?.length)}
        />
      </div>
      {/* Filter Drawer */}
      <SideDrawer
        open={filterDrawer}
        handleDrawerClose={handleCloseDrawer}
        title="Filter"
        sideOpen="right"
        ButtonLabel="Apply"
        cancelButtonLabel="Clear filters"
        BackArrow={true}
        CloseArrowHide={true}
        FooterWithoutTab={true}
        clickSaveButton={() => filterHandler()}
        body={
          <>
            <UsersFilter
              checkboxesData={status}
              statuses={statuses}
              setStatuses={setStatuses}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              roles={roles}
              classLevel={classLevel}
              setClassLevel={setClassLevel}
              roleUsers={roleUsers}
            />
          </>
        }
      />
    </>
  );
};

export default AddUsers;
