import React, { useEffect, useState } from "react";

import InnerPageLayout from '../../../components/InnerPageLayout/InnerPageLayout';

import Status from '../../../components/Status/Status';
import Sections from './Sections';
import { useDeleteCourseMutation, useGetClassLevelListQuery, useGetClassListQuery, useGetCourseDetailsQuery, useUpdateClassMutation } from "../../../redux/features/classes/classApi";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import useProtected from "../../../hooks/useProtected";
import { useParams } from "react-router";
import { capitalizeFirstLetter } from "../../../components/hooks/HelperFunctions";
import CreateCours from './CreateCours';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import { useGetSubjectListQuery } from "../../../redux/features/subjects/subjectApi";
import { toast } from "react-toastify";
import DraggableDialog from "../../../components/hooks/Dialog";
import { useNavigate } from "react-router";
import 'remixicon/fonts/remixicon.css'

import PresentationIcon from '../../../assets/imgs/presentation-line.svg';
import { useGetUsersByUserTypesMutation } from "../../../redux/features/users/userApi";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";


const CourseDetails = () => {

  const { course_id } = useParams()

  const { data, isLoading, isError, error } = useGetCourseDetailsQuery(course_id);
  const { data: classLists, isError: classIsError, isLoading: classIsLoading, error: classError } = useGetClassListQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: schoolYearList, isLoading: isSchoolYearListLoading, isError: isSchoolYearListError, error: schoolYearListError } = useGetSchoolYearListQuery();
  const { data: classLevelList, isLoading: isClassLevelListLoading, isError: isClassLevelListError, error: classLevelListError } = useGetClassLevelListQuery();
  const { data: subjectAreaList, isLoading: isSubjectAreaListLoading, isError: isSubjectAreaListError, error: SubjectAreaListError, isFetching: subjectAreaIsFetching } = useGetSubjectListQuery();


  const [updateCourse, updateCourseOptions] = useUpdateClassMutation()
  const [deleteCourse, deleteCourseOptions] = useDeleteCourseMutation();
  const [updateCourseStatus, updateCourseStatusOptions] = useUpdateClassMutation()
  const [courses, setCourses] = useState([])


  useProtected(classLevelListError);
  useProtected(schoolYearListError);
  useProtected(updateCourseOptions.error);
  useProtected(deleteCourseOptions.error);
  useProtected(updateCourseStatusOptions.error);

  let navigate = useNavigate()


  let courseInitialObject = {
    subjectArea: {},
    name: "",
    courseCode: {
      codePrefix: "",
      code: ""
    },

    sectionName: "",
    classLevel: "",
    acededemicYear: {},
    acededemicTerm: {},
    sectionCode: {
      codePrefix: "",
      code: ""
    },
    primaryTeacher: {},
    classRoomSize: "",


    teachers: [
      ""
    ],
    sections: [
      ""
    ]
  }

  let courseErrorsInitialObject = {
    subjectArea: "",
    name: "",
    courseCode_codePrefix: "",
    courseCode_code: "",
    sectionName: "",
    classLevel: "",
    acededemicYear: "",
    acededemicTerm: "",
    sectionCode_codePrefix: "",
    sectionCode_code: "",
    primaryTeacher: "",
    classRoomSize: "",
    status: "",
    id: ""
  }
  let sectionErrorsInitialObject = {
    sectionName: "",
    classLevel: "",
    acededemicYear: "",
    acededemicTerm: "",
    sectionCode_codePrefix: "",
    sectionCode_code: "",
    primaryTeacher: "",
    classRoomSize: "",
    status: "",
    id: ""
  }
  const [isCourse, setIsCourse] = useState("")
  const [deletedId, setDeletedId] = useState('')
  const [schoolYear, setSchoolYear] = useState('')
  const [classLevel, setClassLevel] = useState('')
  const [subjectArea, setSubjectArea] = useState('')
  const [editCourseId, setEditCourseId] = useState("")
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const [schoolYearOptions, setSchoolYearOptions] = useState([])
  const [classLevelOptions, setClassLevelOptions] = useState([])
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([])
  const [courseObject, setCourseObject] = useState(courseInitialObject)
  const [courseErrors, setCourseErrors] = useState(courseErrorsInitialObject)
  const [openFilterDrawerCreateCourse, setOpenFilterCreateCourse] = useState(false);
  const [courseDetails, setCourseDetails] = useState({})
  const [courseStatus, setCourseStatus] = useState(1)
  const [sectionErrors, setSectionErrors] = useState(sectionErrorsInitialObject)





  useEffect(() => {
    if (!classIsLoading && !classIsError) {
      setCourses(classLists?.body?.data);
    }
  }, [classLists, classIsError, classIsLoading])

  useEffect(() => {
    if (!isSchoolYearListLoading && !isSchoolYearListError) {
      const data = schoolYearList.body.data;
      setSchoolYearOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setSchoolYear(data[0].name)
    }
  }, [schoolYearList, isSchoolYearListLoading, isSchoolYearListError])


  useEffect(() => {
    if (!isClassLevelListLoading && !isClassLevelListError) {
      const data = classLevelList?.body?.data;
      setClassLevelOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setClassLevel(data[0].name)
    }
  }, [classLevelList, isClassLevelListLoading, isClassLevelListError])




  useEffect(() => {
    if (!isLoading && !isError) {
      setCourseDetails(data.body.data)
    }
  }, [data, isLoading, isError]);
  useEffect(() => {
    if (typeof courseDetails === 'object' && Object.keys(courseDetails).length !== 0) {
      // console.log(courseDetails, 'testas')

      let selectedVal = statustData?.find(singleStatus => singleStatus?.select === capitalizeFirstLetter(courseDetails?.status))
      // console.log(selectedVal, 'tustus')
      setCourseStatus(selectedVal?.value)
    }
  }, [courseDetails])
  useEffect(() => {
    if (!isSubjectAreaListLoading && !subjectAreaIsFetching) {
      const data = subjectAreaList?.body?.data;
      setSubjectAreaOptions(getPreparedArray(data || [], 'subjectArea'))
      if (data && data.length > 0) setSubjectArea(data[0].name)
    }
  }, [subjectAreaList, isSubjectAreaListLoading, isSubjectAreaListError, subjectAreaIsFetching])




  const handleFilterDrawerOpen = () => {
    setSectionErrors(sectionErrorsInitialObject)
    setOpenFilter(true);
  };

  const getPreparedArray = (data, property) => {
    return data.map((item) => ({
      value: (property) ? item[property] : item.subjectArea,
      label: (property) ? item[property] : item.subjectArea,
    }));
  };


  const handleFilterDrawerCreateCourseOpen = () => {
    setOpenFilterCreateCourse(true);
    // setIsCourse()
  };
  const handleCloseFilterDrawer = () => {
    setEditCourseId('')
    setOpenFilterCreateCourse(false);
    setCourseObject(courseInitialObject)
    setCourseErrors(courseErrorsInitialObject)
  };

  const editCourseHandler = (id) => {
    setEditCourseId(id)
    handleFilterDrawerCreateCourseOpen()
  }


  const statustData = [
    { value: 1, statuscolor: "#41AB37", select: "Active" },
    { value: 2, statuscolor: "#BA1A1A", select: "Inactive" },
  ];

  const menuOptions = [
    { label: 'ADD SECTION', onClick: handleFilterDrawerOpen },
    { label: 'DELETE', onClick: (e) => handleOpenDelete(course_id) }

  ];


  const breadcrumbsData = [
    {
      icon: <img src={PresentationIcon} alt="presentation icon" />,
      // label: courseDetails?.name || 'N/A',
      label: 'Courses'
    },

  ];



  const staticStatustData = [
    {
      Label: "Section Status",
      SubLabel: "Active",
      Color: "#41AB37",
      line: true,
    },
    {
      Label: "Course Code",
      SubLabel: (courseDetails?.courseCode?.codePrefix) ? `${courseDetails?.courseCode?.codePrefix} ${courseDetails?.courseCode?.code}` : 'N/A',
      Color: "#7D8597",
      line: false,
    },
    {
      Label: "Subject Area",
      SubLabel: (courseDetails?.subjectArea?.subjectArea) ? `${courseDetails?.subjectArea?.subjectArea}` : 'N/A',
      Color: "#7D8597",
      line: false,
    },
  ];


  const onChangeHandler = async (event) => {
    await updateCourseStatus({ status: (event.target.value == 1) ? 'active' : 'inactive', id: course_id })
  }

  const setDefaultStatus = () => {
    let selectedVal = statustData?.find(singleStatus => singleStatus?.select === capitalizeFirstLetter(courseStatus))
    console.log(courseStatus, statustData, selectedVal)

    return selectedVal?.value
  }

  const isSameCourseCode = () => {
    let found = false
    courses?.map((singleCourse) => {
      if (courseObject?.courseCode?.codePrefix === singleCourse?.courseCode?.codePrefix && courseObject?.courseCode?.code === singleCourse?.courseCode?.code && !editCourseId) {
        found = true
      }
    })
    return found
  }



  const checkSection = async () => {
    let errorNumber = 0

    let errorObject = { ...courseErrors }

    if (courseObject.subjectArea === "" || Object.keys(courseObject.subjectArea).length === 0) {
      errorObject = { ...errorObject, subjectArea: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, subjectArea: "" }
    }

    if (courseObject.name === "") {
      errorObject = { ...errorObject, name: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, name: "" }
    }

    if (courseObject.courseCode.codePrefix === "") {
      errorObject = { ...errorObject, courseCode_codePrefix: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, courseCode_codePrefix: "" }
    }

    if (courseObject.courseCode.code === "") {
      errorObject = { ...errorObject, courseCode_code: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, courseCode_code: "" }
    }



    if (editCourseId === "") {
      if (courseObject.sectionName === "") {
        errorObject = { ...errorObject, sectionName: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionName: "" }
      }

      if (courseObject.classLevel === "" || courseObject.classLevel.length === 0) {
        errorObject = { ...errorObject, classLevel: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, classLevel: "" }
      }

      if (courseObject.acededemicYear === "" || Object.keys(courseObject.acededemicYear).length === 0) {
        errorObject = { ...errorObject, acededemicYear: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, acededemicYear: "" }
      }


      if (courseObject.sectionCode.codePrefix === "") {
        errorObject = { ...errorObject, sectionCode_codePrefix: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionCode_codePrefix: "" }
      }

      if (courseObject.sectionCode.code === "") {
        errorObject = { ...errorObject, sectionCode_code: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionCode_code: "" }
      }

      if (courseObject.primaryTeacher === "" || Object.keys(courseObject.primaryTeacher).length === 0) {
        errorObject = { ...errorObject, primaryTeacher: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, primaryTeacher: "" }
      }


      // if (courseObject.classRoomSize == "") {
      //   errorObject = { ...errorObject, classRoomSize: "Required" }
      //   errorNumber++
      // } else {
      //   errorObject = { ...errorObject, classRoomSize: "" }
      // }
      if (courseObject.classRoomSize === "") {
        errorObject = { ...errorObject, classRoomSize: "Required" };
        errorNumber++;
      } else if (parseInt(courseObject.classRoomSize) < 0) {
        errorObject = { ...errorObject, classRoomSize: "Classroom size cannot be negative" };
        errorNumber++;
      } else {
        errorObject = { ...errorObject, classRoomSize: "" };
      }

    }

    setCourseErrors({ ...courseErrors, ...errorObject })

    return (errorNumber > 0) ? false : true
  }


  const setNewData = async () => {
    let classLevelObjects
    let schoolYearObject
    let termObject

    if (editCourseId === "") {
      classLevelObjects = classLevelList.body.data?.filter((classlevel) => {
        return courseObject.classLevel.includes(classlevel.name) ? true : false
      })
      schoolYearObject = schoolYearList.body.data?.find(schoolyear => schoolyear.name === courseObject.acededemicYear)
      termObject = schoolYearObject.terms?.find(term => term.termName === courseObject.acededemicTerm)
    }

    let data = { ...courseObject }

    let subjectAreaObject
    if (typeof courseObject.subjectArea === "string") {
      subjectAreaObject = subjectAreaList.body.data?.find(subjectarea => subjectarea.subjectArea === courseObject.subjectArea)
    } else {
      subjectAreaObject = data.subjectArea
    }

    data.subjectArea = {
      id: subjectAreaObject?._id,
      subjectArea: subjectAreaObject?.subjectArea
    }

    if (editCourseId === "") {
      data.classLevel = classLevelObjects?.map((classObj) => {
        return {
          id: classObj?._id,
          name: classObj?.name
        }
      })
      data.acededemicYear = {
        id: schoolYearObject?._id,
        name: schoolYearObject?.name
      }
      data.acededemicTerm = termObject

    }

    let newData = (editCourseId === "") ? {
      name: data.name,
      subjectArea: data.subjectArea,
      courseCode: data.courseCode,
      sections: [{
        sectionName: data.sectionName,
        classLevel: data.classLevel,
        acededemicYear: data.acededemicYear,
        acededemicTerm: data.acededemicTerm,
        sectionCode: data.sectionCode,
        primaryTeacher: data.primaryTeacher,
        classRoomSize: data.classRoomSize,
      }]
    } : {
      name: data.name,
      subjectArea: data.subjectArea,
      courseCode: data.courseCode
    }

    return newData
  }


  const addCourseHandler = async () => {
    let isValid = await checkSection()

    if (!isValid) {
      return false
    }
    let data = await setNewData()

    let result = await updateCourse({ ...data, id: editCourseId })

    if (result?.data && result?.data?.body?.data) {
      toast.success("Course details updated")
    } else {
      toast.error("Something went wrong")
    }

    setCourseObject(courseInitialObject)
    setCourseErrors(courseErrorsInitialObject)
    handleCloseFilterDrawer()
    setEditCourseId('')






  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (id) => {
    if (!isTheActionPermitted('deleteSections')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
    setDeletedId(id)

  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };


  const handleConfirmCourseDeleteDialog = async () => {
    try {
      const result = await deleteCourse(deletedId)
      if (result?.data) {
        toast.success(result?.data?.message)
        navigate('/courses')
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Error deleting course. Please try again");
    }
    setOpenDialog(false);
  };

  return (
    <>
      <InnerPageLayout
        BackArrow={true}
        BackArrowlink="/courses"
        //BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}

        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}

        ButtonOutLinedStatus={true}
        ButtonOutLinedLabel="Edit Course"
        //ButtonOutLinedIcon={}
        //ButtonOutLinedLink=""
        ButtonOutLinedOnClick={(e) => editCourseHandler(course_id)}

        // ButtonFilledStatus={true}
        // ButtonFilledLabel="Button Filled"
        //ButtonFilledIcon={}
        //ButtonFilledLink=""
        //ButtonFilledOnClick={}

        MenuStatus={true}
        menuOptions={menuOptions}


        BodyStatus={true}
        Body={
          <>
            <Status
              MainTitle={true}
              Title={courseDetails?.name || 'N/A'}
              BackLinkLabel="All courses"
              BackLinkLabelOnClick={(e) => navigate('/courses')}
              statustData={statustData}
              Value={courseStatus}
              onChange={(e) => onChangeHandler(e)}
              StaticStatus={true}
              staticStatustData={staticStatustData}
            />
            <Sections course_id={course_id} courseDetails={courseDetails} openFilterDrawer={openFilterDrawer} setOpenFilter={setOpenFilter} sectionErrorsInitialObject={sectionErrorsInitialObject} setSectionErrors={setSectionErrors} sectionErrors={sectionErrors} />
          </>
        }

      />

      {/* Create classes drawer */}
      <SideDrawer
        open={openFilterDrawerCreateCourse}
        handleDrawerClose={handleCloseFilterDrawer}
        title={editCourseId === "" ? "Create Course" : "Edit Course Details"}
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel={editCourseId === "" ? "Create Course" : "save"}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={addCourseHandler}
        drawerWidth="680px"
        drawerMaxWidth="680px"
        body={
          <>
            <CreateCours
              handleClose={handleCloseFilterDrawer}
              schoolYearOptions={schoolYearOptions}
              setSchoolYearOptions={setSchoolYearOptions}
              classLevelOptions={classLevelOptions}
              setClassLevelOptions={setClassLevelOptions}
              schoolYear={schoolYear}
              setSchoolYear={setSchoolYear}
              classLevel={classLevel}
              setClassLevel={setClassLevel}
              courseObject={courseObject}
              setCourseObject={setCourseObject}
              courseErrors={courseErrors}
              setCourseErrors={setCourseErrors}
              schoolYearList={schoolYearList}
              subjectAreaOptions={subjectAreaOptions}
              id={editCourseId}
              isSameCourseCode={isSameCourseCode}
            />
          </>
        }
      />
      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleConfirmCourseDeleteDialog}
      /> */}

      {/* Delete Group*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Delete course?"
        body={<div>Are you sure you want to delete this course?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
        actionButton="Delete"
      />
    </>

  );
};


export default CourseDetails;


