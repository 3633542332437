import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/css/users.css";
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { RiContactsBookFill } from "react-icons/ri"
import Button from "@mui/material/Button";
import HomeIcon from '@material-ui/icons/Home'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { toTitleCase } from "../../../utils/helpers/index"

import Overview from "./Overview";
import Applications from "./Applications";
import { RxPerson } from "react-icons/rx";
import { RxCalendar } from "react-icons/rx";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetPaymentDeatilsQuery, useGetUserDetailsQuery, useReturnAccessMutation, useRevokeAccessMutation } from "../../../redux/features/users/userApi";
import { useGetApplicationListByUserIdQuery } from "../../../redux/features/applications/applicationApi";
import useProtected from "../../../hooks/useProtected";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import GuardiansParents from './GuardiansParents';
import GuardianStudents from './GuardianStudents';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import UsersProfileEdit from "./UsersProfileEdit";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../../redux/features/users/userApi";
import { filterTruthyValues, mapMonth } from "../../../utils/helpers";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/features/authSlice";


import AddRoundedIcon from '@mui/icons-material/AddRounded';

import Buttons from '../../../components/Form/Button/Button';
import MediaLibrarySelectMedia from "../../../components/MediaLibrary/MediaLibrarySelectMedia";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';

import Modals from '../../../components/Form/Modals/Modals';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';


import img from '../../../assets/imgs/randomPerson.avif';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';


import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

import { BiSolidIdCard } from 'react-icons/bi';

import Identification from './Identification';
import DraggableDialog from "../../../components/hooks/Dialog";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import EmergencyContacts from "./EmergencyContacts";
import { useFindGurdianMutation, useFindGurdianQuery } from "../../../redux/features/users/guardianApi";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { UserOutlined } from "../../../assets/imgs/icons/userOutlined";
import { UserFill } from "../../../assets/imgs/icons/userFill";
import { PassValidOutlined } from "../../../assets/imgs/icons/passValidOutlined";
import { PassValidFill } from "../../../assets/imgs/icons/passValidFill";
import { GroupOutlined } from "../../../assets/imgs/icons/groupOutlined";
import { GroupFill } from "../../../assets/imgs/icons/groupfill";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Payments from "./Payments";
import { useSelector } from "react-redux";


import InnerPageLayout from '../../../components/InnerPageLayout/InnerPageLayout';
import { RiFileInfoFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';





const UsersDetails = (props) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [updateUser, updateUserOptions] = useUpdateUserMutation();
	const [revokeAccess, revokeAccessOptions] = useRevokeAccessMutation()
	const [returnAccess, returnAccessOptions] = useReturnAccessMutation()

	const { data, isLoading, isError, error, refetch } = useGetUserDetailsQuery(id, {
		refetchOnMountOrArgChange: true,
	});
	const {
		data: applicationListResponse,
		isLoading: applicationResponseLoading,
		isError: applicationResponseIsError,
		error: applicationResponseError,
	} = useGetApplicationListByUserIdQuery(id, {
		refetchOnMountOrArgChange: true,
	});


	useProtected(error);
	useProtected(applicationResponseError);
	useProtected(revokeAccessOptions.error);
	useProtected(returnAccessOptions.error);

	const [personalInfo, setPersonalInfo] = useState([]);
	const [manualAddress, setManualAddress] = useState(false)
	const [editProfile, setEditProfile] = useState(false);
	const profileErrorObject = {
		firstName: "",
		lastName: "",
		country: "",
		adddress: "",
		// addressLine1: "",
		timezones: "",
		city: "",
		state: "",
		zip: "",

	}
	const [errors, setErrors] = useState(profileErrorObject)
	const [errorStatus, setErrorStatus] = useState(false)

	useEffect(() => {
		if (errorStatus) {
			checkErrors(personalInfo)
		}
	}, [errorStatus, personalInfo])


	const checkErrors = async (profileInfo) => {
		let errorStatus = 0
		let errorObject = { ...errors }
		if (profileInfo?.firstName === "") {

			errorStatus++
			errorObject.firstName = "Required"
		} else {
			errorObject.firstName = ""
		}
		if (profileInfo?.lastName === "") {
			errorStatus++
			errorObject.lastName = "Required"
		} else {
			errorObject.lastName = ""
		}
		if (profileInfo?.country === undefined || profileInfo?.country === "") {

			errorStatus++
			errorObject.country = "Required"
		} else {
			errorObject.country = ""
		}
		if (profileInfo?.timezones === undefined || profileInfo?.timezones === "") {

			errorStatus++
			errorObject.timezones = "Required"
		} else {
			errorObject.timezones = ""
		}
		// if ((profileInfo?.adddress === undefined || profileInfo?.adddres === "")) {
		// 	errorStatus++
		// 	errorObject.adddress = "Required"
		// } else {
		// 	errorObject.adddress = ""
		// }

		if ((profileInfo?.addressLine1 === "" || profileInfo?.addressLine1 === undefined)) {
			errorStatus++
			errorObject.addressLine1 = "Required"
		} else {
			errorObject.addressLine1 = ""
		}

		if ((profileInfo?.city === "" || profileInfo?.city === undefined)) {

			errorStatus++
			errorObject.city = "Required"
		} else {
			errorObject.city = ""
		}
		if ((profileInfo?.state === "" || profileInfo?.state === undefined)) {

			errorStatus++
			errorObject.state = "Required"
		} else {
			errorObject.state = ""
		}
		if ((profileInfo?.zip === "" || profileInfo?.zip === undefined)) {

			errorStatus++
			errorObject.zip = "Required"
		} else {
			errorObject.zip = ""
		}

		setErrors({ ...errors, ...errorObject })
		return errorStatus > 0 ? false : true
	}

	useEffect(() => {
		if (!isLoading && data && !error) {
			setPersonalInfo(data?.body?.data)

		}
	}, [data, error, isLoading])

	// ========== find gurdian =============================== //
	const [userUniqueIdData, setUserUniqueIdData] = useState('');

	useEffect(() => {
		setUserUniqueIdData(personalInfo?.userUniqueID)
	}, [personalInfo])
	// console.log(userUniqueIdData)






	const [currentTab, setCurrentTab] = useState(0);
	const [userDetails, setUserDetails] = useState(null);

	const [applicationList, setApplicationList] = useState([]);
	const [userEditDrawer, setUserEditDrawer] = useState(false);
	const [formData, setFormData] = useState(null)
	const [openSideDrawer, setOpenSideDrawer] = useState(false);
	const [birthDate, setBirthDate] = useState(null);
	const [birthMonth, setBirthMonth] = useState(null);
	const [birthYear, setBirthYear] = useState(null);
	const handleTabSwitch = (activeTab) => {
		setCurrentTab(activeTab);
	};


	const [openDialogRevokeUser, setOpenRevokeUser] = useState(false);
	const handleOpenRevokeUser = () => {
		if (!isTheActionPermitted('editRevokeUsers')) {
			toast.error("You don't have access to this feature")
			return false
		}
		setOpenRevokeUser(true);
	};
	const handleCloseRevokeUser = () => {
		setOpenRevokeUser(false);
	};

	const [openDialogReturnUser, setOpenReturnUser] = useState(false);
	const handleOpenReturnUser = () => {
		setOpenReturnUser(true);
	};
	const handleCloseReturnUser = () => {
		setOpenReturnUser(false);
	};


	useEffect(() => {
		if (!isLoading && !isError) {
			setUserDetails(data?.body?.data);
		}
	}, [data, isLoading, isError]);

	useEffect(() => {
		if (!applicationResponseLoading && !applicationResponseIsError) {
			setApplicationList(applicationListResponse.body.data);
		}
	}, [
		applicationListResponse,
		applicationResponseLoading,
		applicationResponseIsError,
	]);


	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	useEffect(() => {
		setPersonalInfo({ ...personalInfo, [birthDate]: new Date(birthYear, birthMonth, birthDate) })

	}, [birthYear, birthMonth, birthDate])

	const handleUpdateProfile = async (profileInfo) => {

		setErrorStatus(true)
		const data = {
			firstName: profileInfo?.firstName,
			middleName: profileInfo?.middleName,
			lastName: profileInfo?.lastName,
			gender: profileInfo?.gender,
			profilePic: profileInfo?.profilePic,
			nysedId: profileInfo?.nysedId,
			country: profileInfo?.country,
			address: profileInfo?.address,
			addressLine1: profileInfo?.addressLine1,
			addressLine2: profileInfo?.addressLine2,
			city: profileInfo?.city,
			state: profileInfo?.state,
			zip: profileInfo?.zip,
			highestEducation: profileInfo?.highestEducation,
			occupation: profileInfo?.occupation,
			phone: profileInfo?.phone,
			birthDate: new Date(birthYear, birthMonth - 1, birthDate),
			idPicture: profileInfo?.idPicture,
			idPictureName: profileInfo?.idPictureName,
			timezones: profileInfo?.timezones,
			graduationYear: profileInfo?.graduationYear,
			graduationLevel: profileInfo?.graduationLevel
		}

		//validation start
		const isvalid = await checkErrors(profileInfo)
		if (!isvalid) {
			toast.error("Please complete all the required fields.");
			return false
		}
		//validation end 


		const result = await updateUser({ id, ...data });

		if (!result?.data?.error) {
			// setFormData(personalInfo);
			const response = await refetch();
			if (!response?.data?.error) {
				const data = { ...response.data.body.data, birthMonth: response.data.body?.birthDate ? moment(response.data.body.birthDate).month() : "", birthYear: response.data.body?.birthDate ? moment(response.data.body.birthDate).year() : "" }
				setFormData(data);
			}
			toast.success("User information updated")
		} else {
			toast.error(result.data.message)
		}
		drawerClose();
	};

	const drawerClose = () => {
		setErrors(profileErrorObject);
		setErrorStatus(false)
		setEditProfile(false)
		setOpenSideDrawer(false)
	}
	const handleEditProfile = () => {
		setEditProfile(true);
	}
	useEffect(() => {
		// setFormData(data)

		const dateObj = (personalInfo?.birthDate) ? new Date(personalInfo?.birthDate) : new Date();

		const year = dateObj.getFullYear();
		const month = dateObj.getMonth() + 1; // Adding 1 since getMonth() returns 0-based index
		const day = dateObj.getDate();

		if (!birthDate && personalInfo?.birthDate) setBirthDate(day)
		if (!birthMonth && personalInfo?.birthDate) setBirthMonth(month)
		if (!birthYear && personalInfo?.birthDate) setBirthYear(year)


		// let temp = {...personalInfo}
		// temp.birthMonth = month
		// temp.birthYear = year
		// temp.birthDate = day
		// setPersonalInfo()
	}, [personalInfo])



	const breadcrumbsData = [
		{
			label: "Users",
			link: "/users",
			icon: <RxPerson style={{ marginRight: '-8px', fontSize: '16px' }} />,
		},

		{
			label: <div>{userDetails?.firstName} {userDetails?.lastName}</div>,
			link: "#",
		},

	];




	// <Breadcrumbs
	//   breadcrumbsData={breadcrumbsData}
	// />



	// const [dynamicTabBody, setDynamicTabBody] = useState([])
	const [guardianListData, setGuardianListData] = useState([])


	let tabAndBodyTogether = [
		{
			icon: <UserOutlined />,
			label: "Overview",
			panel: <Overview userDetails={userDetails} isLoading={isLoading} />
		},

		{
			icon: <PassValidOutlined />,
			selectedIcon: <PassValidFill />,
			label: "Identification",
			panel: <Identification
				handleUpdateProfile={handleUpdateProfile}
				userDetails={userDetails}
				setUserDetails={setUserDetails}
				personalInfo={personalInfo}
				id={id}
				isLoading={isLoading}
				refetch={refetch}
				setFormData={setFormData}
			/>
		},
		{
			icon: <GroupOutlined />,
			selectedIcon: <GroupFill />,
			label: "Students",
			panel: <GuardianStudents
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				guardianList={userDetails?.gurdians || []} />
		},
		{
			icon: <PeopleAltRoundedIcon />,
			label: "Guardians/Parents",
			panel: <GuardiansParents
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				guardianList={userDetails?.gurdians || []}
				userDetails={userDetails || []} />
		},
		{
			icon: <RiContactsBookFill />,
			label: "Emergency Contacts",
			panel: <EmergencyContacts
				userUniqueID={userDetails?.userUniqueID}
				userId={userDetails?._id}
				emergencyContacts={userDetails?.emergencyContacts || []}
				isLoading={isLoading} />
		},

		{
			icon: <CreditCardIcon />,
			label: "Payments",
			panel: <Payments userUniqueID={userDetails?.userUniqueID} userDetails={userDetails}

				refetch={refetch}
			/>
		},
	]

	const [tabData, setTabdata] = React.useState([]);


	const [tabBody, setTabBody] = useState([])



	useEffect(() => {
		let tempTabData = []
		let tempTabBody = []
		let i = 0
		for (i; i < tabAndBodyTogether?.length; i++) {
			if ((userDetails?.userType === "student" || userDetails?.userType === "faculty" || userDetails?.userType === "staff") && tabAndBodyTogether[i].label === "Students") {
				continue
			}
			if ((userDetails?.userType === "parent" || userDetails?.userType === "faculty" || userDetails?.userType === "staff") && tabAndBodyTogether[i].label === "Guardians/Parents") {
				continue
			}
			if (tabAndBodyTogether[i].label === "Guardians/Parents" && !isTheActionPermitted('manageGuardiansParents')) {
				continue
			}
			// if (userDetails?.userType === "parent" && tabAndBodyTogether[i].label === "Identification") {
			// 	continue
			// }
			if ((userDetails?.userType === "parent" || userDetails?.userType === "faculty" || userDetails?.userType === "staff" || userDetails?.userType === "teacher") && tabAndBodyTogether[i].label === "Emergency Contacts") {
				continue
			}
			if (tabAndBodyTogether[i].label === "Emergency Contacts" && !isTheActionPermitted('manageEmergencyContacts')) {
				continue
			}
			if (tabAndBodyTogether[i].label === "Identification" && !isTheActionPermitted('manageIdentification')) {
				continue
			}



			tempTabData.push({
				icon: tabAndBodyTogether[i].icon,
				label: tabAndBodyTogether[i].label,
				selectedIcon: tabAndBodyTogether[i].selectedIcon,
			})
			tempTabBody.push({
				id: tempTabData.length - 1,
				panel: tabAndBodyTogether[i].panel
			})
		}
		setTabdata(tempTabData)
		setTabBody(tempTabBody)

	}, [userDetails, setTabdata, setTabBody])

	const revokeAccessHandler = async () => {
		let result = await revokeAccess(id)
		if (result?.data) {
			toast.success('User access revoked');
		} else {
			toast.error(result?.data?.message);
		}
		handleCloseRevokeUser()
	}

	const returnAccessHandler = async () => {
		let result = await returnAccess(id)
		if (result?.data) {
			toast.success('User access restored');
		} else {
			toast.error(result?.data?.message);
		}
		handleCloseReturnUser()
	}

	const editProfileHandler = () => {
		if (!isTheActionPermitted('editRevokeUsers')) {
			toast.error("You don't have access to this feature")
			return false
		}
		handleEditProfile(true)
	}



	return (
		<>


			<div className="CustomDesignTabContainer">
				<InnerPageLayout
					BackArrow={true}
					BackArrowlink="/users"
					//BackArrowOnlcikc={BackArrowOnlcikc}
					CloseArrow={true}

					BreadcrumbsStatus={true}
					breadcrumbsData={breadcrumbsData}

					//MenuStatus={true}
					//menuOptions={menuOptions}

					SideBarStatus={true}
					sideBarTabLabel={tabData}
					sideBarTabBody={tabBody}

					BodyStatus={false}
					RightTopBody={<>

						<div className="UserDetails" style={{ marginBottom: "24px" }}>

							<li>
								<div className="UserDetailsleftcol">

									<div className="UserDetailsrIcon" >
										<Avatar
											src={userDetails?.profilePic}
											sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
										//onClick={() => { setShowProfilePhotoSelectionModal(true) }}
										>{(!userDetails?.profilePic || userDetails?.profilePic == '') && userDetails?.firstName?.charAt(0).toUpperCase()}</Avatar>



										{/* <div className="UploadedCameIcon" onClick={() => { setShowProfilePhotoSelectionModal(true) }}>
								<div className="alfa"></div>
								<AddAPhotoRoundedIcon />
							</div> */}
									</div>

									<div className="UserDetailsDetail">
										<span style={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
											<span
											// style={{ display: "inline-block" }}
											>
												<h1>
													<b className="fullNameWrapText">{userDetails?.firstName} {userDetails?.lastName}</b>
												</h1>
											</span>{" "}
											{
												userDetails?.status !== "inactive" ? <span className="status rounded success" >
													{userDetails?.status ? userDetails.status.toUpperCase() : "N/A"} </span>
													:
													<span className="status rounded error" >
														{userDetails?.status ? userDetails.status.toUpperCase() : "N/A"}
													</span>
											}
										</span>
										<p>
											{userDetails?.userType ? toTitleCase(userDetails?.userType?.toUpperCase()) : "N/A"} |
											Email: {userDetails?.email} |
											ID: {userDetails?.userUniqueID}
										</p>
									</div>
								</div>

								<div className="UserListingAction">
									<div align="left">
										<div className="EditUserProfile">
											<div className="ActionButton">
												<Button
													id="basic-button"
													aria-controls={open ? "basic-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={open ? "true" : undefined}
													onClick={handleClick}
												>
													ACTIONS {
														open ?
															<KeyboardArrowUpIcon sx={{ marginRight: '-8px' }} /> :
															<KeyboardArrowDownRoundedIcon sx={{ marginRight: '-8px' }} />
													}
												</Button>
											</div>

											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												MenuListProps={{
													"aria-labelledby": "basic-button",

												}}
												className="EditDropdown"
												sx={{
													"& .MuiMenuItem-root": {
														fontWeight: 600
													}
												}}
											>
												<MenuItem onClick={() => { editProfileHandler(); handleClose() }}>EDIT</MenuItem>
												{
													(personalInfo?.status === 'active') &&
													<MenuItem onClick={() => { handleOpenRevokeUser(); handleClose() }}>REVOKE ACCESS</MenuItem>
												}
												{
													(personalInfo?.status !== 'active') &&
													<MenuItem onClick={() => { handleOpenReturnUser(); handleClose() }}>RESTORE ACCESS</MenuItem>
												}
												{/* <MenuItem onClick={handleClose}>Delete Customer</MenuItem> */}
											</Menu>
										</div>
									</div>
								</div>
							</li>


						</div>

					</>}

				/>
			</div>



			<SideDrawer
				title="Edit User"
				cancelButtonLabel="CANCEL"
				deleteText="Delete Permanently"
				sideOpen="right"
				open={openSideDrawer || editProfile}
				handleDrawerClose={drawerClose}
				ButtonLeft="Upload New"
				FooterWithoutTab={true}
				ButtonLabel="SAVE"
				clickSaveButton={() => handleUpdateProfile(personalInfo)}
				drawerWidth="670px"
				body={
					<div>
						<UsersProfileEdit personalInfo={personalInfo} setPersonalInfo={setPersonalInfo}
							birthDate={birthDate}
							setBirthDate={setBirthDate}
							birthMonth={birthMonth}
							setBirthMonth={setBirthMonth}
							birthYear={birthYear}
							setBirthYear={setBirthYear}
							manualAddress={manualAddress} setManualAddress={setManualAddress} errors={errors} />
					</div>
				}
			/>

			<DraggableDialog
				openDialog={openDialogRevokeUser}
				handleCloseDialog={handleCloseRevokeUser}
				handleConfirmCloseDialog={revokeAccessHandler}
				title={<span style={{ fontWeight: 600 }}>Revoke user access?</span>}
				body={<span style={{ fontSize: '14px' }}>Are you sure you want to revoke access of this user?</span>}
				ModalFooter={true}
				actionButton="Revoke"
			/>

			<DraggableDialog
				openDialog={openDialogReturnUser}
				handleCloseDialog={handleCloseReturnUser}
				handleConfirmCloseDialog={returnAccessHandler}
				title="Restore user access?"
				body="Are you sure you want to restore access of this user?"
				ModalFooter={true}
				actionButton="Restore"
			/>
		</>
	);
};

export default UsersDetails;
