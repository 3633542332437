import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import VerticalTab from '../../../components/VerticalTab/VerticalTab';
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/material/Button";
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Details from './Details';
import Recipients from './Recipients';
import { useNavigate, useParams } from "react-router-dom";
import { useAddMessageMutation, useDeleteMessageMutation, useGetMessageByIdQuery, useGetMessageCategoriesQuery, useUpdateMessageMutation } from "../../../redux/features/messages/meassageApi";
import useProtected from "../../../hooks/useProtected";
import { useEffect } from "react";
import DraggableDialog from "../../../components/hooks/Dialog";
import { toast } from "react-toastify";
import EmailDuplicate from './EmailDuplicate';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import NewMessage from "./NewMessage";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { FileWarningFill } from "../../../assets/imgs/icons/fileWarningFill";
import { FileWarningOutlined } from "../../../assets/imgs/icons/fileWarningOutlined";
import { GroupFill } from "../../../assets/imgs/icons/groupfill";
import { GroupOutlined } from "../../../assets/imgs/icons/groupOutlined";
import { Paper } from "@mui/material";



const MessagesDetails = () => {
  const { message_id } = useParams()
  const { data, isLoading, isError, error } = useGetMessageByIdQuery(message_id);
  const [deleteMessage, deleteMessageOptions] = useDeleteMessageMutation()

  useProtected(error)
  useProtected(deleteMessageOptions.error)

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //


  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [usersToShow, setUsersToShow] = useState([])
  const [deleteId, setDeleteId] = useState('')
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentTab, setCurrentTab] = useState(0);
  const [messageDetails, setMessageDetails] = useState({});
  const handleTabSwitch = (activeTab) => {
    setCurrentTab(activeTab);
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      setMessageDetails(data?.body?.data)
    }
  }, [data, isLoading, isError])

  useEffect(() => {

    let groupUsers = []
    messageDetails?.toGroups?.map((group) => {
      groupUsers = [...groupUsers, ...group?.users]
    })

    let singleUsersToShow = messageDetails?.to?.filter((user) => {
      let foundUser = groupUsers.find(singleGroupUser => singleGroupUser.id === user.id)
      return (foundUser) ? false : true
    })
    setUsersToShow(singleUsersToShow)

  }, [messageDetails])

  const tabData = [
    {
      icon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><FileWarningOutlined /></span>,
      label: "Details",
      selectedIcon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><FileWarningFill /></span>
    },
    {
      icon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><GroupOutlined /></span>,
      label: "Recipients",
      selectedIcon: <span style={{ marginBottom: '-8px', marginRight: '4px' }}><GroupFill /></span>
    },
  ]

  const tabBody = [
    {
      id: 0,
      panel: <Details
        messageDetails={messageDetails} />,
    },
    {
      id: 1,
      panel: <Recipients
        usersToShow={usersToShow}
        messageDetails={messageDetails} />,
    },

  ]

  const confirmMessageDelete = async () => {

    let result = await deleteMessage(deleteId)
    if (result?.data?.body?.data) {
      toast.success("Message deleted")
      navigate('/messages')
    } else {
      toast.error("Something went wrong")
    }

    handleCloseDeleteDialog()
  }

  const deleteMessageHandler = (id) => {
    if (!isTheActionPermitted('deleteMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setDeleteId(message_id)
    setOpenDialog(true)
  }


  // Drawer //
  const user = useSelector(state => state.auth.user);
  const [addMessage, addMessageOptions] = useAddMessageMutation()
  const [updateMessage, updateMessageOptions] = useUpdateMessageMutation()
  const {
    data: messageCategoriesData,
    isLoading: messageCategoriesIsLoading,
    isError: messageCategoriesIsError,
    error: messageCategoriesError
  } = useGetMessageCategoriesQuery()

  useProtected(messageCategoriesError);
  useProtected(addMessageOptions.error);
  useProtected(updateMessageOptions.error);
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const [messageCategories, setMessageCategories] = useState([])
  const [messageCategoryOptions, setMessageCategoryOptions] = useState([])
  const [basicInfoSectionExpanded, setBasicInfoSectionExpanded] = useState(true)
  const [basicMessageSectionExpanded, setBasicMessageSectionExpanded] = useState(false)
  const [editId, setEditId] = useState('')
  const [emailSelected, setEmailSelected] = useState(false)
  const [messageObject, setMessageObject] = useState({
    messageName: "",
    sender: "",
    category: "",
    categoryName: "",
    type: "",
    content: "",
    status: "",
    to: [],
    toGroups: [],
    schoolId: "",
    createdBy: {},
    messageEmailFrom: "",
    messageEmailSubject: "",
    messageEmailContent: "",
    messageEmailAttachments: [],
    messageSmsFrom: "",
    messageSmsContent: "",
    messageVoiceFrom: "",
    messageVoiceRecording: "",
    messageVoiceContent: "",
    messageCallToRecordingContent: "",
    sentDate: ""
  })
  const [errors, setErrors] = useState({
    messageName: "",
    category: "",
    messageSmsContent: "",
    messageVoiceContent: "",
    messageEmailFrom: "",
    messageEmailSubject: "",
    messageEmailContent: "",
    to: "",
    messageContent: "",
    messageAttachment: ""
  })

  useEffect(() => {
    if (!messageCategoriesIsLoading && !messageCategoriesIsError) {
      setMessageCategories(messageCategoriesData?.body?.data)
    }
  }, [messageCategoriesIsLoading, messageCategoriesIsError, messageCategoriesData])

  useEffect(() => {
    setMessageCategoryOptions(getPreparedArray(messageCategories || []))
  }, [messageCategories])


  const getPreparedArray = (data) => {
    return data?.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  };

  const handleCreateDrawerOpen = () => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenCreate(true);
  };
  const handleCloseCreateDrawer = () => {
    resetMessageObject()
    setOpenCreate(false);
  };

  const checkBasicFormField = async () => {
    let errorObject = { ...errors }
    let error = 0
    if (messageObject.messageName == "") {
      errorObject = { ...errorObject, messageName: "Enter a message name" }
      error++
    } else {
      errorObject = { ...errorObject, messageName: "" }
    }
    if (messageObject.category == "") {
      errorObject = { ...errorObject, category: "Select a category" }
      error++
    } else {
      errorObject = { ...errorObject, category: "" }
    }
    if (messageObject.to.length == 0) {
      errorObject = { ...errorObject, to: "Select at least one user" }
      error++
    } else {
      errorObject = { ...errorObject, to: "" }
    }
    setErrors({ ...errors, ...errorObject })

    return error > 0 ? false : true
  }

  const completeBasicInfoHandler = async () => {
    let isValidBasicForm = await checkBasicFormField()
    if (!isValidBasicForm) {
      toast.error("Please fill all the required fields")
      return false
    }
    setBasicInfoSectionExpanded(false)
    setBasicMessageSectionExpanded(true)
  }
  const saveAsDraftHandler = async () => {
    let data = { ...messageObject, status: 'draft' }
    if (messageObject.messageName == '') {
      toast.error('Please enter a message name to save as draft')
      return false
    }

    data.messageEmailEnabled = (emailSelected) ? true : false
    data.messageSmsEnabled = (messageObject?.messageSmsContent != '') ? true : false
    data.messageVoiceEnabled = (messageObject?.messageVoiceContent != '' || messageObject?.messageVoiceRecording != '') ? true : false


    let result = await addMessage(data)
    if (result?.data && result?.data?.body?.data) {
      toast.success('Saved as draft')
    }
    resetMessageObject()
    setOpenCreate(false)


  }

  const resetMessageObject = () => {
    setMessageObject({
      messageName: "",
      sender: "",
      category: "",
      categoryName: "",
      type: "",
      content: "",
      status: "",
      to: [],
      toGroups: [],
      schoolId: "",
      createdBy: {},
      messageEmailFrom: "",
      messageEmailSubject: "",
      messageEmailContent: "",
      messageEmailAttachments: [],
      messageSmsFrom: "",
      messageSmsContent: "",
      messageVoiceFrom: "",
      messageVoiceRecording: "",
      messageVoiceContent: "",
      messageCallToRecordingContent: "",
      sentDate: ""
    })
    setBasicInfoSectionExpanded(true)
    setBasicMessageSectionExpanded(false)
    setEmailSelected(false)
    setEditId('')
  }

  const sendMessageHandler = async () => {
    let data = { ...messageObject, status: 'sent' }

    if (messageObject?.messageSmsContent == '' && (messageObject?.messageVoiceContent == '' && messageObject?.messageVoiceRecording == '' && messageObject?.messageCallToRecordingContent == '') && messageObject?.messageEmailContent == '') {
      setErrors({ ...errors, messageContent: "Please add at least one type of message" })
      return false
    } else {
      data.messageEmailEnabled = (emailSelected) ? true : false
      data.messageSmsEnabled = (messageObject?.messageSmsContent != '') ? true : false
      data.messageVoiceEnabled = (messageObject?.messageVoiceContent != '' || messageObject?.messageVoiceRecording != '' || messageObject?.messageCallToRecordingContent != '') ? true : false

      setErrors({ ...errors, messageContent: "" })
    }
    if (editId != "") {
      data = { ...data, createdBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic }, updatedBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic } }
      let result = await updateMessage({ ...data, id: editId })

      if (result?.data && result?.data?.body?.data) {
        toast.success('Message sent')
      }
    } else {
      data = { ...data, createdBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic }, updatedBy: { id: user?._id, name: `${user?.firstName} ${user?.lastName}`, profilePic: user?.profilePic } }

      let result = await addMessage(data)
      if (result?.data && result?.data?.body?.data) {
        toast.success('Message sent')
      }
    }
    setEditId('')
    setBasicMessageSectionExpanded(false)
    resetMessageObject()
    setOpenCreate(false)
  }
  const breadcrumbsData = [
    {
      label: "Sent Messages",
      link: "/messages",
      // icon: <PersonOutlineRoundedIcon fontSize="small" />,
    },

    {
      label: "Details",
      link: "#",
    },

  ];


  return (
    <>

      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Breadcrumbs
            breadcrumbsData={breadcrumbsData}
            link="/messages"
          />
        </Grid>

        <Grid item md={12} xs={12}>

          <div className="UserDetails">
            <li>
              <div className="UserDetailsleftcol">
                <div className="UserDetailsDetail">
                  <span style={{ display: "inline-block" }}>

                    <h1>{messageDetails?.messageName} <span className="SentChip" style={{ fontWeight: '600' }}>Sent</span>
                    </h1>
                  </span>


                </div>
              </div>

              <div className="UserListingAction">
                <div align="left">
                  <div className="EditUserProfile">
                    <div className="ActionButton">
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <span style={{ textTransform: 'uppercase !important' }}>Actions</span><KeyboardArrowDownRoundedIcon sx={{ marginRight: '-8px' }} />
                      </Button>
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <Paper sx={{ width: 120, boxShadow: "none" }}>
                        <MenuItem
                          sx={{ fontSize: '14px', fontWeight: '600' }}
                          onClick={() => { handleCreateDrawerOpen(); handleClose() }}>DUPLICATE</MenuItem>
                        <MenuItem
                          sx={{ fontSize: '14px', fontWeight: '600' }}
                          onClick={() => { deleteMessageHandler(); handleClose() }}>DELETE</MenuItem>
                      </Paper>
                    </Menu>
                  </div>
                </div>
              </div>
            </li>
            <div className="userDetailsContainer">
              <VerticalTab
                tabData={tabData}
                tabBody={tabBody}
                onTabSwitch={handleTabSwitch}
              />
            </div>
          </div>

        </Grid>

      </Grid>


      {/* Delete Modal*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={confirmMessageDelete}
        title={<span style={{ fontWeight: '600' }}>Delete this message?</span>}
        body={<div>Are you sure you want to delete this message? <br />Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />

      {/* Create Role drawer
      <SideDrawer
        open={OpenEmailDuplicate}
        handleDrawerClose={handleDrawerCloseEmailDuplicate}
        title="Email"
        sideOpen="right"
        ButtonLabel="Close"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <EmailDuplicate />
          </>
        }
      /> */}
      {/* Duplicate Message */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="New Message"
        sideOpen="right"
        hideOuterButton={true}
        LeftButton={true}
        ButtonLeft="Save as draft"
        clickButtonLeft={(e) => saveAsDraftHandler()}
        clickButtonLeftCloseDrawer={false}
        ButtonLeftbackground="#f8f8f9"
        ButtonLeftBorder="#0450E1 1px solid"
        ButtonLeftTextColor="#1B1B1F"
        FooterWithoutTab={true}
        body={
          <NewMessage
            messageObject={messageObject}
            setMessageObject={setMessageObject}
            messageCategoryOptions={messageCategoryOptions}
            completeBasicInfoHandler={completeBasicInfoHandler}
            setErrors={setErrors}
            errors={errors}
            basicInfoSectionExpanded={basicInfoSectionExpanded}
            setBasicInfoSectionExpanded={setBasicInfoSectionExpanded}
            basicMessageSectionExpanded={basicMessageSectionExpanded}
            setBasicMessageSectionExpanded={setBasicMessageSectionExpanded}
            sendMessageHandler={sendMessageHandler}
            editId={editId}
            setEmailSelected={setEmailSelected}
            emailSelected={emailSelected}
            messageDetails={messageDetails}
          />
        }
      />


    </>
  );
};

export default MessagesDetails;
