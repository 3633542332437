import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import CreateCours from './CreateCours';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';

import DraggableDialog from '../../../components/hooks/Dialog';

import {
  useGetClassSearchResultMutation,
  useGetClassListQuery,
  useGetClassLevelListQuery,
  useCreateCourseMutation,
  useDeleteCourseMutation,
  useUpdateClassMutation,
  useGetCoursesSearchResultMutation,
} from '../../../redux/features/classes/classApi';

import { useGetSchoolYearListQuery } from '../../../redux/features/school_years/schoolYearApi';
import { useGetSubjectListQuery } from "../../../redux/features/subjects/subjectApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { Helmet } from "react-helmet";

const CoursesListing = () => {
  const { data, isError, isLoading, error } = useGetClassListQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: schoolYearList, isLoading: isSchoolYearListLoading, isError: isSchoolYearListError, error: schoolYearListError } = useGetSchoolYearListQuery();
  const { data: classLevelList, isLoading: isClassLevelListLoading, isError: isClassLevelListError, error: classLevelListError } = useGetClassLevelListQuery();
  const { data: subjectAreaList, isLoading: isSubjectAreaListLoading, isError: isSubjectAreaListError, error: SubjectAreaListError } = useGetSubjectListQuery();
  const [createCourse, createCourseOptions] = useCreateCourseMutation()
  const [deleteCourses, deleteCoursesOptions] = useDeleteCourseMutation()
  const [updateCourse, updateCourseOptions] = useUpdateClassMutation()

  useProtected(error);
  useProtected(schoolYearListError);
  useProtected(classLevelListError);
  useProtected(SubjectAreaListError);
  useProtected(createCourseOptions.error);
  useProtected(updateCourseOptions.error);
  useProtected(deleteCoursesOptions.error);

  useEffect(() => {
    if (!isSchoolYearListLoading && !isSchoolYearListError) {
      const data = schoolYearList.body.data;
      setSchoolYearOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setSchoolYear(data[0].name)
    }
  }, [schoolYearList, isSchoolYearListLoading, isSchoolYearListError])


  useEffect(() => {
    if (!isClassLevelListLoading && !isClassLevelListError) {
      const data = classLevelList.body.data;
      setClassLevelOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setClassLevel(data[0].name)
    }
  }, [classLevelList, isClassLevelListLoading, isClassLevelListError])

  useEffect(() => {
    if (!isSubjectAreaListLoading && !isSubjectAreaListError) {
      const data = subjectAreaList.body.data;
      setSubjectAreaOptions(getPreparedArray(data || [], 'subjectArea'))
      if (data && data.length > 0) setSubjectArea(data[0].name)
    }
  }, [subjectAreaList, isSubjectAreaListLoading, isSubjectAreaListError])

  let courseInitialObject = {
    subjectArea: {},
    name: "",
    courseCode: {
      codePrefix: "",
      code: ""
    },

    sectionName: "",
    classLevel: [],
    acededemicYear: {},
    acededemicTerm: [],
    sectionCode: {
      codePrefix: "",
      code: ""
    },
    primaryTeacher: {},
    classRoomSize: "",


    teachers: [
      ""
    ],
    sections: [
      ""
    ]
  }

  let courseErrorsInitialObject = {
    subjectArea: "",
    name: "",
    courseCode_codePrefix: "",
    courseCode_code: "",
    sectionName: "",
    classLevel: "",
    acededemicYear: "",
    acededemicTerm: "",
    sectionCode_codePrefix: "",
    sectionCode_code: "",
    primaryTeacher: "",
    classRoomSize: "",
    status: "",
    id: ""
  }

  const [courseObject, setCourseObject] = useState(courseInitialObject)
  const [courseErrors, setCourseErrors] = useState(courseErrorsInitialObject)
  const [getSearchedCoursesList, options] = useGetCoursesSearchResultMutation();
  const [schoolYearOptions, setSchoolYearOptions] = useState([])
  const [classLevelOptions, setClassLevelOptions] = useState([])
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([])
  const [editCourseId, setEditCourseId] = useState("")
  const [subjectArea, setSubjectArea] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const [schoolYear, setSchoolYear] = useState('')
  const [classLevel, setClassLevel] = useState('')
  const [deletedId, setDeletedId] = useState('')
  const [isCourse, setIsCourse] = useState("")
  const [courses, setCourses] = useState([])
  const [rows, setRows] = useState([]);
  const [asc, setAsc] = useState(true)

  const getPreparedArray = (data, value = 'name') => {
    return data.map((item) => ({
      value: item[value],
      label: item[value],
    }));
  };


  const onClick = () => {
    alert('onclick working fine!');
  };
  const navigate = useNavigate()
  function redirectToCourseDetails(itemId) {
    // Construct the URL with the item's ID
    const url = `/course-details/${itemId}`;
    navigate(url)
    // Redirect to the constructed URL
    // window.location.href = url;
  }
  ///=================================== sorting part start =============//
  const ascendingOrDescendingSortingByName = (field) => {
    let sortedUsers = [...courses]
    if (asc) {
      sortedUsers.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedUsers.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc)
  }
  const ascendingOrDescendingSortingBySubjectArea = (field) => {
    let sortedUsers = [...courses]
    if (asc) {
      sortedUsers.sort((a, b) => a[field]?.subjectArea.localeCompare(b[field]?.subjectArea));
    } else {
      sortedUsers.sort((a, b) => b[field]?.subjectArea.localeCompare(a[field]?.subjectArea));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc)
  }
  ///=================================== sorting part end =============//
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "50%", textAlign: "left", sort: "sort", headerOnClick: () => ascendingOrDescendingSortingByName('name'), },
    { id: "courseCode", numeric: false, disablePadding: true, label: "Course Code", width: "10%", textAlign: "left", },
    { id: "subjectArea", numeric: false, disablePadding: true, label: "Subject Area", width: "15%", textAlign: "left", sort: "sort", headerOnClick: () => ascendingOrDescendingSortingBySubjectArea('subjectArea'), },
    { id: "sections", numeric: false, disablePadding: true, label: "Sections", width: "10%", textAlign: "left", },
    { id: "status", numeric: false, disablePadding: true, label: "Status", width: "10%", textAlign: "left", },
    { id: "action", numeric: false, disablePadding: true, label: "", width: "5%", textAlign: "left", },

    // { id: "name", numeric: false, disablePadding: true, label: "Academic Year", width: "20%", textAlign: "left", },
    // { id: "name", numeric: false, disablePadding: true, label: "Primary Teacher", width: "25%", textAlign: "left", },


  ];
  const statustData = [
    { value: 'draft', statuscolor: "#EEECFF", select: "Draft", textColor: "#7266FB" },
    { value: 'active', statuscolor: "#EDF7EA", select: "Active", textColor: "#66BD50" },
    { value: 'inactive', statuscolor: "#FCE7E9", select: "Inactive", textColor: "#E63946" },
  ];

  const editCourseHandler = (id) => {
    handleFilterDrawerOpen()
    setEditCourseId(id)
  }
  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        return {
          cell: [
            { linkText: item?.name || 'N/A', linkTextStatus: true, link: `/course-details/${item._id}`, },
            { textBold: `${item?.courseCode?.codePrefix} ${item?.courseCode?.code}` || 'N/A', textcolor: "#000" },
            { text: item?.subjectArea?.subjectArea, textcolor: "#000" },
            // { Status: true, background: '#EDEDEF', color: '#676C7B', label: item?.sections?.length, },
            { text: <span style={{ background: '#EDEDEF', color: '#676C7B', padding: '2px 5px', borderRadius: '5px' }}>{item?.sections?.length}</span> },


            // { text: item?.schoolYear || 'N/A' },
            // { text: item?.section || '' },
            // { text: item?.teachers?.find(teacher => teacher.teacherType === 'primary_teacher')?.name || 'N/A' },
            {
              Status: true,
              statusLine: true,
              linebackground: statustData?.find(status => status.value === item?.status)?.textColor,
              background: statustData?.find(status => status.value === item?.status)?.statuscolor,
              color: statustData?.find(status => status.value === item?.status)?.textColor,
              label: statustData?.find(status => status.value === item?.status)?.select,
            },
            // { Status: true, background: '#FCE7E9', color: '#E63946', label: 'Private' },
            // { Status: true, background: '#EDEDEF', color: '#676C7B', label: 'Upcoming', },
            // { Status: true, background: '#E1EAFB', color: '#0450E1', label: 'In progress', },
            // { Status: true, background: '#EDF7EA', color: '#66BD50', label: 'Completed', },

            // { ActionButton: true, ActionButtonLabel: 'Actions', },
            { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

            // {
            //   Button: true, buttonLabel: "Details", buttonBorder: '#c6c6d0 1px solid',
            //   buttonTextcolor: "#004FE0", buttonLink: `/course-details/${item._id}`,
            // },
          ],


          //======= Action Date Start===== //
          action: [
            { label: 'View Details', onClick: () => redirectToCourseDetails(item._id), },
            { label: 'Edit Course', onClick: () => editCourseHandler(item?._id) },
            {
              label: 'Delete', onClick: () => handleOpenDelete(item._id)
            },
          ],
          //======= Action Date End===== //


        }
      }

      )
    }
    else {
      return [];
    }
  }

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setCourses(data?.body?.data);
    }
  }, [data, isError, isLoading])


  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];
  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedCoursesList({ term: "" });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedCoursesList({ term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part end ====================== //

  // Classes Drawer //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = (props) => {
    setCourseErrors(courseErrorsInitialObject)
    setOpenFilter(true);
    setIsCourse(props)
  };
  const handleCloseFilterDrawer = () => {
    setEditCourseId('')
    setOpenFilter(false);
    setCourseObject(courseInitialObject)
    setCourseErrors(courseErrorsInitialObject)
  };

  //======= Delete dialog part start ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (id) => {
    if (!isTheActionPermitted('deleteCourses')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
    setDeletedId(id)

  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmCourseDeleteDialog = async () => {
    try {
      const result = await deleteCourses(deletedId)
      if (result?.data) {
        toast.success("Course deleted")
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Error deleting course. Please try again.");
    }
    setOpenDialog(false);
  };
  //======= Delete dialog part end ===== //
  const isSameCourseCode = () => {
    let found = false
    courses?.map((singleCourse) => {
      if (courseObject?.courseCode?.codePrefix == singleCourse?.courseCode?.codePrefix && courseObject?.courseCode?.code == singleCourse?.courseCode?.code && !editCourseId) {
        found = true
      }
    })
    return found
  }
  const checkSection = async () => {
    let errorNumber = 0

    let errorObject = { ...courseErrors }

    if (courseObject.subjectArea == "" || Object.keys(courseObject.subjectArea).length === 0) {
      errorObject = { ...errorObject, subjectArea: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, subjectArea: "" }
    }

    if (courseObject.name == "") {
      errorObject = { ...errorObject, name: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, name: "" }
    }

    if (courseObject.courseCode.codePrefix == "") {
      errorObject = { ...errorObject, courseCode_codePrefix: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, courseCode_codePrefix: "" }
    }

    if (courseObject.courseCode.code == "") {
      errorObject = { ...errorObject, courseCode_code: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, courseCode_code: "" }
    }

    if (isSameCourseCode()) {
      errorNumber++
    }

    if (editCourseId == "") {
      if (courseObject.sectionName == "") {
        errorObject = { ...errorObject, sectionName: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionName: "" }
      }

      if (courseObject.classLevel == "" || courseObject.classLevel.length === 0) {
        errorObject = { ...errorObject, classLevel: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, classLevel: "" }
      }

      if (courseObject.acededemicYear == "" || Object.keys(courseObject.acededemicYear).length === 0) {
        errorObject = { ...errorObject, acededemicYear: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, acededemicYear: "" }
      }


      if (courseObject.sectionCode.codePrefix == "") {
        errorObject = { ...errorObject, sectionCode_codePrefix: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionCode_codePrefix: "" }
      }

      if (courseObject.sectionCode.code == "") {
        errorObject = { ...errorObject, sectionCode_code: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, sectionCode_code: "" }
      }

      if (courseObject.primaryTeacher == "" || Object.keys(courseObject.primaryTeacher).length === 0) {
        errorObject = { ...errorObject, primaryTeacher: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, primaryTeacher: "" }
      }


      if (courseObject.classRoomSize == "") {
        errorObject = { ...errorObject, classRoomSize: "Required" }
        errorNumber++
      } else if (parseInt(courseObject.classRoomSize) > 100000) {
        errorObject = { ...errorObject, classRoomSize: "The max capacity is 100K" }
        errorNumber++
      } else if (parseInt(courseObject.classRoomSize) <= 0) {
        toast.error(`Class capacity has to be more than 0`);
        errorNumber++
      } else if (!Number.isInteger(Number(courseObject.classRoomSize))) {
        toast.error(`Class capacity must be a whole number.`);
        // errorObject = { ...errorObject, classRoomSize: `Class capacity must be a whole number.` }
        errorNumber++
      }
      else {
        errorObject = { ...errorObject, classRoomSize: "" }
      }

    }

    setCourseErrors({ ...courseErrors, ...errorObject })

    return (errorNumber > 0) ? false : true
  }

  const setNewData = async () => {
    let classLevelObjects
    let schoolYearObject
    let termObject

    if (editCourseId == "") {
      classLevelObjects = classLevelList.body.data?.filter((classlevel) => {
        return courseObject.classLevel.includes(classlevel.name) ? true : false
      })
      schoolYearObject = schoolYearList.body.data?.find(schoolyear => schoolyear.name == courseObject.acededemicYear)
      termObject = schoolYearObject.terms?.find(term => term.termName == courseObject.acededemicTerm)
    }

    let data = { ...courseObject }

    let subjectAreaObject
    if (typeof courseObject.subjectArea === "string") {
      subjectAreaObject = subjectAreaList.body.data?.find(subjectarea => subjectarea.subjectArea == courseObject.subjectArea)
    } else {
      subjectAreaObject = data.subjectArea
    }

    data.subjectArea = {
      id: subjectAreaObject?._id,
      subjectArea: subjectAreaObject?.subjectArea
    }

    if (editCourseId == "") {
      data.classLevel = classLevelObjects?.map((classObj) => {
        return {
          id: classObj?._id,
          name: classObj?.name
        }
      })
      data.acededemicYear = {
        id: schoolYearObject?._id,
        name: schoolYearObject?.name
      }
      data.acededemicTerm = termObject

    }

    let newData = (editCourseId == "") ? {
      name: data.name,
      subjectArea: data.subjectArea,
      courseCode: data.courseCode,
      sections: [{
        sectionName: data.sectionName,
        classLevel: data.classLevel,
        acededemicYear: data.acededemicYear,
        acededemicTerm: data.acededemicTerm,
        sectionCode: data.sectionCode,
        primaryTeacher: data.primaryTeacher,
        classRoomSize: data.classRoomSize,
      }]
    } : {
      name: data.name,
      subjectArea: data.subjectArea,
      courseCode: data.courseCode
    }

    return newData
  }

  const addCourseHandler = async () => {
    let isValid = await checkSection()

    if (!isValid) {
      return false
    }
    let data = await setNewData()

    let result = null
    if (editCourseId == "") {
      result = await createCourse({ ...data })
    } else {
      result = await updateCourse({ ...data, id: editCourseId })
    }

    if (result?.data && result?.data?.body?.data) {
      if (editCourseId == "") {
        toast.success("Course created")
      } else {
        toast.success("Course details updated")
      }
    } else {
      toast.error("Something went wrong")
    }


    setCourseObject(courseInitialObject)
    setCourseErrors(courseErrorsInitialObject)
    handleCloseFilterDrawer()
    setEditCourseId('')






  }

  // useEffect(() => {
  //   console.log('course object: ', courseObject, 345)
  // }, [courseObject])




  return (
    <>

      <div className="TableSection">
        <Table
          title="Courses"
          tableHeader={true}
          addLabel="Create course"
          // icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleFilterDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          showSearchBox={false}
          searchBoxPlaceholder="Search courses"
          isDataLoading={isLoading || options?.isLoading}
          RowsPerPage={20}

          SearchBoxTop={true}

          emptyTableTitle="No course added yet"
          emptyTableNote="No items available"
          showButton={true}
          btnLabel="New Course"
          AddButtonInner={false}
          addLabelInner="Create course"
          InnerButtonColor="#0450E1"
          InnerButtonTextColor="#fff"
          onClickEmptyCard={handleFilterDrawerOpen}

        />
      </div>


      {/* Create classes drawer */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        title={editCourseId === "" ? "Create Course" : "Edit course"}
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel={editCourseId === "" ? "Create Course" : "Save"}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={addCourseHandler}
        drawerWidth="690px"
        body={
          <>
            <CreateCours
              handleClose={handleCloseFilterDrawer}
              schoolYearOptions={schoolYearOptions}
              setSchoolYearOptions={setSchoolYearOptions}
              classLevelOptions={classLevelOptions}
              setClassLevelOptions={setClassLevelOptions}
              schoolYear={schoolYear}
              setSchoolYear={setSchoolYear}
              classLevel={classLevel}
              setClassLevel={setClassLevel}
              courseObject={courseObject}
              setCourseObject={setCourseObject}
              courseErrors={courseErrors}
              setCourseErrors={setCourseErrors}
              schoolYearList={schoolYearList}
              subjectAreaOptions={subjectAreaOptions}
              id={editCourseId}
              isSameCourseCode={isSameCourseCode}
            />
          </>
        }
      />
      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleConfirmCourseDeleteDialog}
      /> */}

      {/* Delete Group*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Delete course?"
        body={<div>Are you sure you want to delete this course?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
        actionButton="Delete"
      />

    </>
  );
};

export default CoursesListing;
