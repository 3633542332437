import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TablePagination, Box } from '@mui/material';
import Buttons from '../../../components/Form/Button/Button';

const StyledTableLeftCells = styled(TableCell)({
    padding: '8px 0',
    paddingLeft: '0px',
    width: '80% !important',
    color: '#5C677D',
    border: '0px',
});

const StyledTableRightCells = styled(TableCell)({
    padding: '8px 0',
    paddingRight: '0px',
    width: '20% !important',
    color: '#5C677D',
    border: '0px',
});

const tableFooterCss = {
    "& .MuiIconButton-root": {
        padding: '0px'
    },
    "& .MuiTablePagination-selectLabel": {
        color: '#5C677D',
        display: "inline !important",
        float: 'left'
    },
    "& .MuiInputBase-root": { display: 'none' },
    "& .MuiTablePagination-toolbar": {
        color: '#5C677D',
        padding: '0px !important',
        display: "block !important",
    },
    "& .MuiTablePagination-displayedRows": {
        display: 'inline'
    },
    "& .MuiTablePagination-actions": {
        float: 'right'
    }
}

const ActivityTable = ({ sectionHeader, sectionButtonTitle, sectionButtonFn, rows, }) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className='ActivitiesTableContainer'>
            <div className="TableTitle">
                <span style={{ fontWeight: 600 }}>{sectionHeader}</span>
                <span></span>
                <Buttons
                    label={sectionButtonTitle}
                    buttonColor="#fff"
                    border="#e8eaed 1px solid"
                    color="#0450e1"
                    width=""
                    height="40px"
                    fontSize="14px"
                    fontWeight="700"
                    onClick={sectionButtonFn}
                />
            </div>
            <TableContainer component={Paper} sx={{ boxShadow: 'none', "& .MuiPaper-elevation": { boxShadow: 'none' } }}>
                <Table sx={{ minWidth: 650, boxShadow: 'none' }} aria-label="simple table">
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                            <TableRow key={index}>
                                <StyledTableLeftCells component="td" scope="row" >
                                    ●&nbsp;{item.activity}
                                </StyledTableLeftCells>
                                <StyledTableRightCells component="td" align="right">
                                    {item.time}
                                </StyledTableRightCells>
                            </TableRow>
                        ))}
                        {(emptyRows > 0 && rows.length === 0) && (
                            <TableRow style={{ height: 0 * emptyRows, }}>
                                <TableCell colSpan={2} sx={{ textAlign: 'center' }}>No data</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div className="TableContainer DrawerTable">
                <div className='tablePage TableFooter'>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={<span className='PaginationResultLabel'>Results</span>}
                        sx={tableFooterCss}
                    />
                </div>
            </div>
        </div>
    );
};

export default ActivityTable;