import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import Categories from "./Categories";
import useProtected from "../../../hooks/useProtected";
import { useGetCalenderCategoriesQuery } from "../../../redux/features/calender/calenderApi";
import { useGetUserTypesQuery } from "../../../redux/features/user_types/userTypesApi";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontSize: '16px'
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#FCFCFC",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//




function AcademicsSettings(props) {

  const {
    data,
    isFetching,
    isLoading,
    error
  } = useGetCalenderCategoriesQuery(undefined, { refetchOnMountOrArgChange: true });

  const {
    data: userTypeData,
    isFetching: userTypeIsFetching,
    isLoading: userTypeIsLoading,
    error: userTypeError
  } = useGetUserTypesQuery(undefined, { refetchOnMountOrArgChange: true });

  useProtected(error);
  useProtected(userTypeError);

  const [userTypes, setUserTypes] = useState([]);
  const [openCategories, setOpenCategories] = useState(false);
  const [calenderCategories, setCalenderCategories] = useState([])

  const handleDrawerOpenCategories = () => {
    setOpenCategories(true);
  };
  const handleDrawerCloseCategories = () => {
    setOpenCategories(false);
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCalenderCategories(data?.body?.data)
    }
  }, [data, isLoading, isFetching])

  useEffect(() => {
    if (!userTypeIsLoading && !userTypeIsFetching) {
      setUserTypes(userTypeData?.body?.data)
    }
  }, [userTypeData, userTypeIsLoading, userTypeIsFetching])

  return (
    <>

      <div className="TableContainer oddEvenTable">
        <div className="TableInnerHeader">
          <h3 style={{ padding: "8px 0px", fontWeight: "700", fontSize: "28px", color: "#001233", }}>Academics Settings</h3>
        </div>

        <div style={{ border: '1px solid #E8EAED', borderRadius: '12px', overflow: "hidden", marginBottom: "100px" }}>
          <div style={{ padding: "8px 14px", borderBottom: "#E8EAED 1px solid", fontWeight: "600", fontSize: "22px" }}>Calendars</div>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow >
                  <StyledTableCell align="left" style={{ color: '#33415C', fontWeight: 600 }}>Categories</StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>{calenderCategories?.length} {calenderCategories?.length > 1 ? "categories" : "category"}</StyledTableCell>
                  <StyledTableCell align="right" className="TableLabel"><a onClick={handleDrawerOpenCategories} style={{ color: "#0450E1", fontWeight: "600", fontSize: "14px", cursor: "pointer", }}>MANAGE</a></StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </div>




      {/* AddCalendars Drawer */}
      <SideDrawer
        open={openCategories}
        handleDrawerClose={handleDrawerCloseCategories}
        title="Categories"
        ButtonLabel="Add now"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}

        body={
          <>
            <Categories
              calenderCategories={calenderCategories}
              userTypes={userTypes} />
          </>
        }
      />


    </>
  );
}






export default AcademicsSettings;

