import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import "../../assets/css/filter.css"
import "../../assets/css/filter.css"
import { toTitleCase } from "../../utils/helpers";
import CheckBox from '../../components/Form/CheckBox/CheckBox';

const CourseAttendanceFilter = (props) => {
    const { streams, setStream, attendanceCodeOptionsUnfiltered, } = props


    const changeStreamHandler = (e, value) => {
        let newStreams = [...streams]
        if (e.target.checked) {
            if (!streams.includes(value)) {
                newStreams.push(value);
                setStream(newStreams)
            }
        } else {
            if (streams.includes(value)) {
                newStreams = streams.filter((stream) => stream !== value)
                setStream(newStreams)
            }
        }

    }
    // console.log(attendanceCodeOptions, 345, attendanceCodeOptionsUnfiltered)
    const showStreamsTypes = () => {
        let streamCheckboxes = []
        attendanceCodeOptionsUnfiltered.map((attendanceCodeOption) => {
            streamCheckboxes.push(
                <CheckBox
                    label={`${toTitleCase(attendanceCodeOption?.code)} - ${toTitleCase(attendanceCodeOption?.description)}`}
                    value={toTitleCase(attendanceCodeOption.code)}
                    checked={streams.includes(attendanceCodeOption.code)}
                    onChange={(e) => changeStreamHandler(e, attendanceCodeOption.code)}
                />)

        })

        return streamCheckboxes

    }






    return (

        <div className="FilterMainCont">

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel1bh-header"
                >
                    <Typography className="AccTitle">
                        Attendance
                    </Typography>
                    {(streams?.length > 0) && <Typography className="FilterCount">{streams?.length}</Typography>}
                </AccordionSummary>
                <AccordionDetails>

                    {showStreamsTypes()}


                </AccordionDetails>
            </Accordion>


            {/* <Accordion expanded> */}


        </div>
    );
};

export default CourseAttendanceFilter;
