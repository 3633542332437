// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api_url: process.env.REACT_APP_API_URL,
  site_key: process.env.REACT_APP_SITE_KEY,
  env: process.env.NODE_ENV,
  s3: {
    s3_bucket: process.env.REACT_APP_S3_BUCKET,
    s3_directory_dev: process.env.REACT_APP_S3_DIRECTORY_DEV,
    s3_directory_prod: process.env.REACT_APP_S3_DIRECTORY_PROD,
    s3_base_url: process.env.REACT_APP_S3_BASE_URL,
    s3_region: process.env.REACT_APP_S3_REGION,
    // s3_access_key: process.env.REACT_APP_S3_ACCESS_KEY,
    // s3_secret_access_key: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    s3_cdn_url: process.env.REACT_APP_CDN_URL,
  }
};
