import React from "react";
import { Button } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CustomAccordion from "../CustomAccordion/CustomAccordion";
import User from "./User/User";
import PictureID from "./PictureID/PictureID";
import GenerateQR from "./GenerateQR/GenerateQR";

const CreateIdDrawer = () => {

  // Manage the 'expanded' state here
  const [expanded, setExpanded] = React.useState('panel1');

  // Update the 'expanded' state when changing accordion panels
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <CustomAccordion 
        id={1} 
        ModalLabel="User" 
        ModalBody={<User />} 
        isExpanded={expanded} 
        handleChange={handleChangeAccordion}
      />
      <CustomAccordion id={2} ModalLabel="Picture for ID" ModalBody={<PictureID />} 
      isExpanded={expanded} // Pass the 'expanded' state to the first accordion
      handleChange={handleChangeAccordion} />
      <CustomAccordion id={3} ModalLabel="QR code" ModalBody={<GenerateQR />} 
      isExpanded={expanded} // Pass the 'expanded' state to the first accordion
      handleChange={handleChangeAccordion} />
    </>
  );
};

export default CreateIdDrawer;