import React from 'react'
import './StatusStatic.css';
import Button from '@mui/material/Button';
import { truncateText } from '../../hooks/HelperFunctions';



{/* <StatusStatic
    StatusStaticbackground=""
    StatusLabel="Course Code"
    StatusSubLabel="Subject Area"
    StatusColor="#7D8597"
    StatusLine={false}
/> */}




const StatusStatic = (props) => {

    return (
        <div className='StatusStaticContainer' style={{ width: props.StatusStaticWidth, maxWidth: props.StatusStaticMaxWidth }}>
            <div className='StatusStaticBackground' style={{ background: props.StatusColor }}></div>
            {props.StatusLine &&
                <div className="StatusStaticLine" style={{ background: props.StatusColor }}></div>
            }
            <div>
                <h1 style={{ color: props.StatusColor }}>{props.StatusLabel}</h1>
                <h2>{truncateText(props.StatusSubLabel, 18)}</h2>
            </div>

        </div>
    )
}

export default StatusStatic;



