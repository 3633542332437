import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import './Dialog.css';

// import Draggable from 'react-draggable';

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }



//======= Delete dialog ===== //
// const [openDialog, setOpenDialog] = useState(true);
// const handleOpenDelete = () => {
//   setOpenDialog(true);
// };
// const handleCloseDeleteDialog = () => {
//   setOpenDialog(false)
// };
//======= Delete dialog ===== //


// const handleOpenDialog = (id) => {
//   console.log(id);
//   setActiveCourseId(id);
//   setDialogTitle("Delete course");
//   setDialogBody("Are you sure, you want to delete a course record and it's related data?");
//   setOpenDialog(true);
// }

// { label: "Delete", onClick: handleOpenDelete, },


{/* <DraggableDialog
    openDialog={openDialog}
    handleCloseDialog={handleCloseDeleteDialog}
    title="Delete this track?"
    body="Are you sure you want to delete this? Once you delete it, you can't get it back."
    ModalFooter={true}
/> */}



export default function DraggableDialog(props) {

    const { openDialog, title, body, actionButton, cancelButton, handleCloseDialog, handleConfirmCloseDialog, oneButton } = props;

    return (
        <div className="DialogBox">
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                className="DialogBoxContainer"
            >
                <div className="DialogTitle" style={{ color: props.Headercolor }}>
                    {title}
                </div>
                <DialogContent>
                    <DialogContentText className='DialogContentText'>
                        {body}
                    </DialogContentText>
                </DialogContent>
                {props.ModalFooter &&
                    <div style={{ background: props.Footerbackground }}>
                        <div className="DialogFooterContainer">
                            {
                                oneButton ? <Button autoFocus onClick={handleCloseDialog} className='DialogbtLight'>
                                    {cancelButton ? cancelButton : "Ok"}
                                </Button> : <><Button autoFocus onClick={handleCloseDialog} className='DialogbtLight'>
                                    {cancelButton ? cancelButton : "Cancel"}
                                </Button>
                                    <Button onClick={handleConfirmCloseDialog} className='DialogbtnBlue'>{actionButton ? actionButton : "Continue"}</Button></>
                            }

                        </div>
                    </div>
                }
            </Dialog>
        </div>
    );
}