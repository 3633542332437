import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../Form/Button/Button';
import HorizontalTab from '../HorizontalTab/HorizontalTab';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import Datefield from '../Form/Datefield/Datefield';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Search from './Search';
import '../../components/HorizontalTab/HorizontalTab.css';
import './TableSubHeader.css';
import './Search.css';



const TableSubHeader = (props) => {

  const { tabLabel, tabPanel, TabValue, TabHandleChange, SearchPlaceholder, SearchValue, SearchOnChange,
    SearchOnclick, SearchOnMouseDown, FilterOnClick, ExportOnClick, dateFilter, handleDateFilterChange, AddOnClick } = props;

  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };


  // const tabLabel = [
  //   {
  //     label: "Invited Users",
  //   },
  //   {
  //     label: "Invite New Users",
  //   },

  // ]

  // const tabPanel = [
  //   {
  //     id: 0,
  //     panel: "1",
  //   },
  //   {
  //     id: 1,
  //     panel: "2",
  //   },
  // ]


  // <TableSubHeader
  //   DateField={true}
  //   Tabs={true}
  //   tabLabel={tabLabel}
  //   tabPanel={tabPanel}
  //   TabValue={value}
  //   TabHandleChange={handleChange}
  //   Filter={true}
  //   //FilterOnClick={FilterOnClick}

  //   //== Search Code == //
  //   Search={true}
  //   SearchPlaceholder="Search..."
  //   SearchValue={searchTerm}
  //   SearchOnChange={handleSearchText}
  //   //SearchOnclick={SearchOnclick}
  //   //SearchOnMouseDown={SearchOnMouseDown}
  //   handleSearch={handleSearch}
  //   Border={true}
  // />



  return (
    <>

      <div className='SubTableHeaderTitle'>{props.HeaderTitle}</div>
      <div className="TableSubHeader">
        {props.Tabs &&
          <div className="HorizontalTab tabBottomBorder">
            <div className="HorizontalTabLabel">
              <Tabs value={TabValue} onChange={TabHandleChange} aria-label="simple tabs example">
                {tabLabel.map((item, i) => (
                  <Tab label={item.label} icon={item.icon} {...a11yProps(0)} />
                ))}
              </Tabs>
            </div>

          </div>
        }



        {props.DateField && (
          <div className='TableDateField'>
            <div className='DateChangeArrowArrowContainer'>
              <div className='DateChangeArrow'><Button className='ArrowLeft arrow' onClick={props.goToPreviousDate}><ArrowBackIosNewRoundedIcon /></Button></div>
              <div className='DateChangeArrow'><Button className='ArrowRight arrow' onClick={props.goToNextDate}><ArrowForwardIosRoundedIcon /></Button></div>
            </div>
            <Datefield
              value={dateFilter}
              handleChange={handleDateFilterChange}
              dateFormat={props?.filterDateFormat}
            />

          </div>
        )}


        <span></span>
        <div className='Tb_hr_R_Section'>

          {props.Search &&
            <div className='Tb_hr_Search'>
              <Search
                SearchPlaceholder={SearchPlaceholder}
                SearchValue={SearchValue}
                SearchOnChange={SearchOnChange}
                SearchOnclick={SearchOnclick}
                SearchOnMouseDown={SearchOnMouseDown}
                setResetSearchTerm={props.setResetSearchTerm}
                resetsearchTerm={props.resetsearchTerm}
                setSearchTerm={props.setSearchTerm}
                handleSearch={props.handleSearch}
              />
            </div>
          }


          {props.Export &&
            <div className='Tbl_Actions'>
              <Button onClick={ExportOnClick}><FileUploadOutlinedIcon /> Export</Button>
            </div>
          }

          {props.Filter &&
            <div className='Tbl_Actions'>
              <Button onClick={FilterOnClick}><FilterAltIcon /> Filter</Button>
            </div>
          }

          {props.AddButton &&
            <div className='Tbl_ActionsAddButton'>
              <Button onClick={AddOnClick} style={{ background: props.AddButtonColor, color: props.AddButtonTextColor, border: props.BorderColor }}><AddRoundedIcon /> {props.AddButtonLabel}</Button>
            </div>
          }

        </div>
      </div>

      {props.Border &&
        <div className='BorderBottom'></div>
      }


      {props.Tabs &&
        <div className="HorizontalTaleTabPanel">
          {tabPanel.map((item, i) => (
            <CustomTabPanel value={TabValue} index={item.id}>
              {item.panel}
            </CustomTabPanel>
          ))}
        </div>
      }


    </>
  );
};

export default TableSubHeader;


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
