import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import SideDrawer from '../../../../components/SideDrawer/SideDrawer';
import CreateCoupon from './CreateCoupon';
import EmptyCard from '../../../../components/EmptyCard/EmptyCard';
import DraggableDialog from '../../../../components/Dialog/Dialog';
import { useAddCouponsMutation, useCheckUniqueCouponMutation, useDeleteCouponMutation, useGetAllCouponsQuery, useUpdateCouponsMutation } from '../../../../redux/features/coupons/couponApi';
import useProtected from '../../../../hooks/useProtected';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { Button, Backdrop, CircularProgress } from "@mui/material";

const CouponsListing = (props) => {

    const {
        data,
        isError,
        isLoading,
        error
    } = useGetAllCouponsQuery(undefined, { refetchOnMountOrArgChange: true });

    // const [openLodaer, props.setOpenLodaer] = useState(false);
    const [addCoupon, addCouponOptions] = useAddCouponsMutation();
    const [deleteCoupon, deleteCouponOptions] = useDeleteCouponMutation();
    const [updateCoupon, updateCouponOptions] = useUpdateCouponsMutation();
    const [checkUniqueCoupon, checkUniqueCouponOptions] = useCheckUniqueCouponMutation()

    const [isSaving, setIsSaving] = useState(false);

    useProtected(error);
    useProtected(addCouponOptions.error);
    useProtected(updateCouponOptions.error);
    useProtected(deleteCouponOptions.error);
    useProtected(checkUniqueCouponOptions.error);



    let initialCouponObject = {
        code: "", //upper case and should be unique
        discountValue: "",
        discountType: "percentage", //percentage, fixed
        // expireAt: "2024-07-01T06:28:07.883Z",
        status: "active", //active, inactive
        redemptionLimit: {
            limitDateRange: false, //true, false
            limitDateRangeData: {
                date: dayjs(),
                time: dayjs()
            },
            limitCouponUser: false, //true, false
            limitCouponUserData: {
                couponUsingLimit: null
            },
            limitToSpecificStudent: false, //true, false
            limitToSpecificStudentData: {
                students: []
            }
        },
        // freeOrPlan: "string",
        applyAutomatically: true //true, false
    }

    const initialCouponErrorObject = {
        "code": "",
        "discountType": "",
        "discountValue": "",
        "status": ""
    }

    const [asc, setAsc] = useState(true)
    const [rows, setRows] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [editId, setEditId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [searchTerm, setSearchTerm] = useState('');

    const [couponObject, setCouponObject] = useState(initialCouponObject)
    const [couponErrorObject, setCouponErrorObject] = useState(initialCouponErrorObject)
    const [currentCode, setCurrentCode] = useState('')



    useEffect(() => {
        if (!isLoading && !isError) {
            setRows(convertDataToCellRows(data.body.data));
            setCoupons(data?.body?.data)
        }
    }, [data, isLoading, isError]);

    const deleteHandler = (id) => {
        setDeleteId(id)
        handleOpenDelete()
    }
    const editCalenderHandler = (id) => {
        setEditId(id)
        props.EmptyCardOnClick()
    }
    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => {
                let couponStatus = item?.status;
                let couponBackground = item?.status == 'active' ? '#EDF7EA' : '#FCE7E9';
                let couponColor = item?.status == 'active' ? '#001233' : '#E63946';
                let couponLineBackground = item?.status == 'active' ? '#41AB37' : '#BA1A1A';

                // Create a new Date object from the timestamp
                const date = new Date(item?.redemptionLimit?.limitDateRangeData?.date);

                // Format the date as "1-Nov-2024"
                const formattedDate = `${date.getUTCDate()}-${date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' })}-${date.getUTCFullYear()}`;

                let actionButtons = [
                    { label: 'Edit', onClick: (e) => editCalenderHandler(item?._id) }
                ]

                if (item?.usages == 0) {
                    actionButtons.push({ label: 'Delete', onClick: (e) => deleteHandler(item?._id) })
                }

                return ({
                    cell: [
                        { text: item?.code ? item.code : 'N/A' },
                        { text: item?.discountType == 'percentage' ? `${item?.discountValue}% off` : `$${item?.discountValue} off` },
                        { text: item?.redemptionLimit?.limitDateRange ? formattedDate : "Never" },
                        { text: item?.redemptions },
                        // { text: item?.redemptionLimit?.limitCouponUser ? `${item?.usages}/${item?.redemptionLimit?.limitCouponUserData?.couponUsingLimit}` : item?.usages },
                        { Status: true, background: couponBackground, color: couponColor, linebackground: couponLineBackground, statusLine: true, label: couponStatus, },
                        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
                    ],

                    //======= Action Date Start===== //
                    action: actionButtons,
                    //======= Action Date End===== //
                })
            })

        } else {
            return [];
        }
    }



    //======= Delete dialog ===== //
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDelete = () => {
        setOpenDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDialog(false)
    };
    //======= Delete dialog ===== //



    const onClick = () => {
        alert('onclick working fine!');
    };
    const groupBy = (input, key) => {
        return input.reduce((acc, currentValue) => {
            let groupKey = currentValue[key];
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push(currentValue);
            return acc;
        }, {});
    };

    ///=================================== sorting part start =============//
    const ascendingOrDescendingSorting = (field) => {
        let sortedCouponsList = [...coupons];

        sortedCouponsList.sort((a, b) => {
            const dateA = new Date(a[field]?.limitDateRangeData?.date);
            const dateB = new Date(b[field]?.limitDateRangeData?.date);

            if (asc) {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        const groupedByLimitDateRange = sortedCouponsList?.reduce((acc, coupon) => {
            const limitDateRange = coupon?.redemptionLimit?.limitDateRange;
            (acc[limitDateRange] = acc[limitDateRange] || []).push(coupon);
            return acc;
        }, {});
        const plainArray = Object.entries(groupedByLimitDateRange).flatMap(([key, value]) => value);
        setRows(convertDataToCellRows(plainArray));
        setAsc(!asc);
    }
    ///=================================== sorting part end =============//

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Code', width: '25%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: 'Terms', width: '25%', textAlign: 'left', },
        { id: 'Expires', numeric: false, disablePadding: true, label: 'Expires', width: '20%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSorting('redemptionLimit'), },
        { id: 'name', numeric: false, disablePadding: true, label: 'Redemptions', width: '10%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: 'Status', width: '10%', textAlign: 'left', },
        { id: 'name', numeric: false, disablePadding: true, label: '', width: '10%', textAlign: 'left', },

    ];

    const checkCodeExist = async () => {

        const trimmedCode = couponObject.code.trim();
        if (trimmedCode === "") {
            return false;
        }
        let searchResult = await checkUniqueCoupon({ code: trimmedCode });

        if (searchResult?.data && !searchResult.data?.body?.data?.isUnique) {
            return true
        }
        return false
    }

    const isCouponValid = async () => {
        let error = 0
        let errorObject = { ...couponErrorObject }

        const trimmedCode = couponObject.code.trim();

        if (trimmedCode == "") {
            error++
            errorObject = { ...errorObject, code: "Required" }

        } else {
            const codeExist = await checkCodeExist()
            console.log(codeExist, 500)
            console.log(codeExist, 'codeExist', codeExist, editId === "", editId !== "", couponObject?.code !== currentCode)
            if ((codeExist && editId === "") || (codeExist && editId !== "" && couponObject?.code !== currentCode)) {
                error++
                errorObject = { ...errorObject, code: "The code is already in use. Choose a different code." }
            } else {
                errorObject = { ...errorObject, code: "" }
            }
        }

        if (couponObject?.status == "") {
            error++
            errorObject = { ...errorObject, status: "Required" }

        } else {
            errorObject = { ...errorObject, status: "" }
        }

        if (couponObject?.discountValue == "") {
            error++
            errorObject = { ...errorObject, discountValue: "Required" }

        } else if (Number(couponObject?.discountValue) > 100) {
            error++
            errorObject = { ...errorObject, discountValue: "The discount value cannot exceed 100%." }
        }
        else {
            errorObject = { ...errorObject, discountValue: "" }
        }

        if (couponObject?.discountType == "") {
            error++
            errorObject = { ...errorObject, discountType: "Required" }
        }

        else {
            errorObject = { ...errorObject, discountType: "" }
        }

        setCouponErrorObject({ ...couponErrorObject, ...errorObject })

        return error > 0 ? false : true
    }


    const saveCouponHandler = async () => {
        if (isSaving) return;

        setIsSaving(true);
        let data = { ...couponObject }

        let isValid = await isCouponValid()

        if (!isValid) {
            toast.error("Required fields are left")
            setIsSaving(false);
            return false
        }

        props.setOpenLodaer(true)

        let result = (editId === "") ? await addCoupon(data) : await updateCoupon({ ...data, id: editId })

        if (result?.data && result?.data?.error === false) {
            (editId === "") ? toast.success(`Coupon added`) : toast.success('Coupon updated')

            setCouponObject(initialCouponObject)
            // handleDrawerCloseAddCalendar()
            props.CloseDrawerCreateCoupon()

            setEditId('')
            // setDuplicateId('')
            setCouponErrorObject(initialCouponErrorObject)
            props.setOpenLodaer(false)
            setIsSaving(false);
        }
    }

    const deleteCalenderConfirmHandler = async () => {
        let id = deleteId

        let result = await deleteCoupon(id)

        if (result?.data) {
            toast.success('Coupon deleted');
        } else {
            toast.error(result?.data?.message);
        }
        handleCloseDeleteDialog()
        setDeleteId('')
    }

    const createCouponDrawerCloseHandler = () => {
        setCouponErrorObject(initialCouponErrorObject)
        setCouponObject(initialCouponObject)
        setIsSaving(false);
        setEditId('')
        setDeleteId('')
        props.CloseDrawerCreateCoupon()
    }




    return (
        <>
            <Table
                headCells={headCells}
                rows={rows}
                Checkbox={true}
                footer={true}
                RowsPerPage={10}
                isDataLoading={isLoading}
            />


            {rows?.length < 1 && <EmptyCard
                title="No coupons added yet"
                description="Added coupons will be visible here"
                showButton={true}
                btnLabel="New coupon"
                onClick={props.EmptyCardOnClick}
            />}



            <SideDrawer
                title={(editId != "") ? "Edit Coupon" : "Create Coupon"}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={props.OpenDrawerCreateCoupon}
                handleDrawerClose={(e) => createCouponDrawerCloseHandler()}
                FooterWithoutTab={true}
                ButtonLabel={(editId != "") ? "Edit Coupon" : "Create Coupon"}
                clickSaveButton={(e) => saveCouponHandler()}
                disabledBtn={isSaving ? true : false}
                body={
                    <div>
                        <CreateCoupon
                            editId={editId}
                            couponObject={couponObject}
                            setCouponObject={setCouponObject}
                            couponErrorObject={couponErrorObject}
                            setCouponErrorObject={setCouponErrorObject}
                            setCurrentCode={setCurrentCode}
                        />
                    </div>
                }
            />

            <DraggableDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDeleteDialog}
                handleConfirmCloseDialog={(e) => deleteCalenderConfirmHandler()}
                title="Delete coupon?"
                body={<div>Are you sure you want to delete this coupon? <br /> Once you delete it, you can't get it back.</div>}
                ModalFooter={true}
                actionButton="Delete"
            //handleConfirmCloseDialog={handleConfirmCloseDialog}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                // open={getMessageSearchResultoptions.isLoading}
                open={props.openLodaer}
            // onClick={() => setPageLoader(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default CouponsListing;
