import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TextField from "../../../components/Form/TextField/TextField";
import Buttons from '../../../components/Form/Button/Button';
import AddSender from './AddSender';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TextEditor from '../../../components/TextEditor/TextEditor';
import img from '../../../assets/imgs/User.png';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SelectTemplate from './SelectTemplate';
import { toast } from 'react-toastify';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import { isImage } from '../../../hooks/HelperFunctions';
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import { Avatar } from '@mui/material';

const CopyButtonStyle = { padding: "0px", color: '#0450E1', fontSize: '14px', fontWeight: 600 }


const EmailDuplicate = (props) => {

  const {
    emailSenders,
    setEmailSenders,
    emailSenderOptions,
    messageObject,
    setMessageObject,
    errors,
    messageDetails
  } = props

  const [openRecipientseDrawer, setRecipientse] = useState(false);
  const handleOpenRecipients = () => setRecipientse(true);
  const handleCloseRecipients = () => setRecipientse(false);

  const [openTemplate, setTemplate] = useState(false);
  const handleOpenTemplate = () => setTemplate(true);
  const handleCloseTemplate = () => setTemplate(false);
  const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [selectedValue2, setSelectedValue2] = React.useState(false);
  const [mediaItem2, setMediaItem2] = useState({});

  const [tempTemplate, setTempTemplate] = useState({})

  const tabData = [
    {
      lebel: "Select individuals",
      //icon: <InsertDriveFileIcon />,
    },

    {
      lebel: "Select groups",
      //icon: <YouTubeIcon />,
    },
  ]

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <></>

      ),

      SaveButtonLabel: 'Send',
      //clickSaveButton: handleAddGuardian,
      CancelButtonLabel: 'Cancel',
    },

    {
      id: 1,
      panleBody: (
        <></>
      ),
      SaveButtonLabel: 'Send Invitation',
      //clickSaveButton: handleInviteUsers,
      CancelButtonLabel: 'Cancel',
    },
  ];




  const backgroundInCount = {
    backgroundColor: '#f2f0f4',
  };



  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const to = [
    {
      label: "Category",
      value: "Category",
    },

    {
      label: "Category",
      value: "Category",
    },

  ]

  const from = [
    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

  ]


  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = () => { setOpenCategory(true); };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  const onChangeHandler = (val, e, fieldName) => {
    let newObject = { ...messageObject, [fieldName]: e.target.value }
    setMessageObject(newObject)
  }
  const textEditorChangeHandler = (val, fieldName) => {
    console.log(val)
    let newObject = { ...messageObject, [fieldName]: val }
    setMessageObject(newObject)
  }
  const handleSelectTemplate = () => {
    textEditorChangeHandler(`[{"type":"paragraph","children":[{"text":"${tempTemplate?.content}"}]}]`, 'messageEmailContent')
    // let newObject = { ...messageObject, messageEmailContent: `[{"type":"paragraph","children":[{"text":"${tempTemplate?.content}"}]}]` }
    // setMessageObject(newObject)

    handleCloseTemplate()
    setTempTemplate({})
  }

  const handleCopyCode = () => {
    if (messageObject?.messageEmailContent) {
      navigator.clipboard.writeText(messageObject?.messageEmailContent)
        .then(() => {
          toast.success('Copied');
        })
        .catch((error) => {
          console.error('Error copying to clipboard: ', error);
          toast.error('Failed to copy code');
        });
    } else {
      toast.warning('No code to copy');
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');

    let newObject = { ...messageObject, messageEmailContent: pastedText }
    setMessageObject(newObject)
  }

  const handleDrawerCloseSelectMedia2 = () => {
    setSelectedValue2(false)
    setMediaItem2({})
    setOpenSelectMedia2(false);
  };
  const handleOpenNewMedia2 = (status) => {
    setOpenNewMedia2(!status);
    handleDrawerOpenSelectMedia2(status);
  };

  const handleDrawerOpenSelectMedia2 = () => {
    setOpenSelectMedia2(true);
  };
  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };
  const getMediaItem2 = (item) => {
    // setting the media item
    setMediaItem2(item);
  };
  const handleMediaUploadClose2 = () => {
    setOpenNewMedia2(false);
  };
  const clickSelectMediaItem2 = async () => {
    console.log(mediaItem2, selectedValue2)
    // //check if any item is selected
    if (selectedValue2) {
      setMessageObject({ ...messageObject, messageEmailAttachments: [...messageObject.messageEmailAttachments, mediaItem2] })
      setSelectedValue2(false)
      setMediaItem2({})
      setOpenSelectMedia2(false);
    }
  };
  const deleteAttachment = (id) => {
    let newAttachments = messageObject?.messageEmailAttachments?.filter((attachment, index) => index !== id)
    setMessageObject({ ...messageObject, messageEmailAttachments: newAttachments })
  }



  const [customText, setCustomText] = useState(null);

  const handleInsertTag = (event, tag) => {
    //event.preventDefault();
    // console.log(tag);
    // console.log(insertFunction);
    // if (insertFunction) {
    //   insertFunction(tag);
    // }
    // setActive(!isActive);

    setCustomText(tag);

  };

  const extractPlainText = (jsonData) => {
    const textSegments = [];
    let parsedData = JSON.parse(jsonData != "" && jsonData)
    let allChildren = parsedData[0]?.children
    allChildren?.forEach(item => {
      if (item.text !== undefined) {
        textSegments.push(item.text);
      } else if (item.children) {
        textSegments.push(extractPlainText(item.children));
      } else if (item.text === '') {
        textSegments.push(' ');
      }
    });

    return textSegments.join('');
  }

  // Handle copy message to clipboard
  const handleCopyMessage = () => {
    const plainTextMessage = extractPlainText(messageDetails?.messageEmailContent);
    if (plainTextMessage) {
      navigator.clipboard.writeText(plainTextMessage)
        .then(() => {
          toast.success('Message copied successfully!');
        })
        .catch(() => {
          toast.error('Failed to copy message');
        });
    } else {
      toast.warning('No message to copy');
    }
  };

  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <TextField
            label="From"
            value={messageDetails?.messageEmailFrom}
            validation
            disabled
            headDisabled
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Subject"
            value={messageDetails?.messageEmailSubject}
            validation
            disabled
            headDisabled
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TextArea
            FieldLabel
            label="Message"
            value={extractPlainText(messageDetails?.messageEmailContent)}
            validation
            disabled
            headDisabled
          />
          <Button variant='text' sx={CopyButtonStyle} onClick={handleCopyMessage}>Copy Message</Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} >
          <div className="FieldLabel" style={{ color: '#1B1B1F', opacity: '38%' }}>Attachments</div>
          {messageDetails?.messageEmailAttachments?.map((attachment, index) => (<div className="UploadPhotoFiled" key={index} style={{ marginBottom: '8px' }}>
            <div className="UploadPhotoImgCol">
              {
                isImage(attachment?.url) ?
                  <span><img src={attachment?.url} alt="file preview" /></span> : <Avatar sx={{ marginRight: '12px', borderRadius: "8px", background: '#E4E2E6' }}><AttachmentRoundedIcon sx={{ color: "#1B1B1F" }} /></Avatar>
              }
              <div className="ImgDetails">{attachment?.name} <p>{(attachment?.size / 1024).toFixed(3)} MB <FiberManualRecordRoundedIcon /> {attachment?.originalFileName}</p></div>
            </div>
          </div>))
          }
        </Grid>

      </Grid>

    </>
  );
};

export default EmailDuplicate;
