import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import img from '../../../assets/imgs/User.png';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { FiDownload } from "react-icons/fi";
import { GrPowerCycle } from "react-icons/gr";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { RiSendPlaneFill } from "react-icons/ri"
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';

import '../../../assets/css/users.css';
import Loader from '../../../components/Loader/Loader';
import { toTitleCase } from '../../../utils/helpers';
import moment from 'moment-timezone';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

import { useGenerateQrCodeMutation } from '../../../redux/features/qrCode/qrCodeApi';
import { useChangePasswordMutation, useForgotPasswordMutation } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Tooltip from '../../../components/Form/Tooltip/Tooltip';
import { countries } from '../../../assets/data/countries';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button } from '@mui/material';
import Modals from '../../../components/Form/Modals/Modals';
import ModalThree from '../../../components/Form/Modals/ModalThree';
import EnteryourPassword from './EnteryourPassword';
import PhoneComponent from '../../../components/Common/Functions';
import { capitalizeWords } from '../../../hooks/HelperFunctions';

const Overview = ({ userDetails, isLoading = false }) => {
  const [generateQrCode, generateQrCodeoptions] = useGenerateQrCodeMutation();
  const [changePassword, changePasswordOptions] = useChangePasswordMutation()
  const [forgotPassword, forgotPasswordOptions] = useForgotPasswordMutation()

  useProtected(generateQrCodeoptions.error)
  useProtected(changePasswordOptions.error)
  useProtected(forgotPasswordOptions.error)
  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  // == Select Field ===//
  const [age, setAge] = React.useState('');
  const [showFullBirthDay, setShowFullBirthDay] = useState(false)
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: 'Parent',
      value: 'Parent',
    },

    {
      label: 'Student',
      value: 'Student',
    },
  ];

  const generateQRHandler = async () => {
    const result = await generateQrCode({ userId: userDetails._id });
    if (result?.data) {
      // setRows(convertDataToCellRows(result.data.body.data));
    }
  };
  const handleSendNewPassword = async () => {
    const result = await changePassword(userDetails._id)
    if (result?.data) {
      toast.success("New password sent successfully.")
    }
  }

  const handleSendResetEmail = async () => {
    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
        ///===================== recapcha ==============================///
        const data = {
          email: userDetails.email ? userDetails.email.toLowerCase() : '',
          token
        };
        const result = await forgotPassword(data)
        if (!result?.data?.error) {
          toast.success("Password reset email sent successfully.")
        } else {
          toast.error("Someting went wrong")
        }
      } catch (error) {
        console.error('Error:', error);
      }
    });
  }

  // ============================================================================
  const [modal, setModal] = useState(false)
  // ============================================================================

  const setShowFullBirthDayModalHandler = () => {
    setModal(true)
    // setShowFullBirthDay(!showFullBirthDay)
  }

  return (
    <>
      <div className="InvitedUsers">
        <div className="TableContainer oddEvenTable">
          <div className='BoxTable'>
            <div className="TableInnerHeader">
              <h3>Overview</h3>
            </div>

            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  {isLoading ? (
                    <Loader
                      isFullWidth={true}
                      width="80px"
                      height="80px"
                      borderWidth="6px"
                    />
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel" width="36%">
                          ID
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.userUniqueID}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel" width="36%">
                          User type
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.userType
                            ? capitalizeWords(userDetails.userType)
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      {(userDetails?.userType === 'student') && <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          NYSED ID #
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.nysedId
                            ? userDetails.nysedId
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>}

                      {(userDetails?.userType === 'student') && <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Current class/level
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.currentClassLevel
                            ? userDetails.currentClassLevel
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>}

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Joined date
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.createdAt
                            ? moment(userDetails?.createdAt).format(
                              "DD-MMM-YYYY"
                            )
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      {(userDetails?.userType === 'student') && <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Started class/level
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.startedClassLevel
                            ? userDetails.startedClassLevel
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>}

                      {(userDetails?.userType === 'student') && <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Started academic year
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {/* Sep 2023 - Jun 2024 */}
                          {userDetails?.academicYear
                            ? userDetails.academicYear
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>}


                      {(userDetails?.userType === 'student') && <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Graduation year
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo"> {userDetails?.graduationLevel && userDetails?.graduationYear && userDetails?.graduationLevel != "" && userDetails?.graduationYear != "" ? `${userDetails?.graduationLevel} - CLASS OF ‘${userDetails?.graduationYear?.slice(-2)}` : 'N/A'}
                          {/* Sep 2023 - Jun 2024 */}
                          {/* {userDetails?.academicYear
                            ? userDetails.academicYear
                            : <span className='noDataspan'>No data available</span>} */}
                        </StyledTableCell>
                      </StyledTableRow>}

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Gender
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.gender
                            ? toTitleCase(userDetails?.gender)
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>


                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          DOB
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          <div className='tableflex'>
                            {userDetails?.birthDate ? ((showFullBirthDay) ? moment(userDetails.birthDate).format("DD/MMM/YYYY") : `**/**/${moment(userDetails.birthDate).format("YYYY")}`) : <span className='noDataspan'>No data available</span>}
                            {/* {showFullBirthDay && (userDetails?.birthDate) ? moment(userDetails.birthDate).format("DD/MMM/YYYY") : <span className='noDataspan'>No data available</span>} */}
                            {
                              userDetails?.birthDate && (
                                (!showFullBirthDay) &&
                                <Buttons
                                  label={showFullBirthDay ? `HIDE FULL BIRTHDAY` : `VIEW FULL BIRTHDAY`}
                                  icon={<VisibilityOutlinedIcon />}
                                  buttonColor=""
                                  border="#E4E8EC 1px solid"
                                  color="#004FE0"
                                  width=""
                                  height="28px"
                                  fontSize="14px"
                                  onClick={(e) => setShowFullBirthDayModalHandler()}
                                />
                              )
                            }


                          </div>
                        </StyledTableCell>


                      </StyledTableRow>







                      {/* <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Started grade
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        1st grade
                      </StyledTableCell>
                    </StyledTableRow> */}

                      {/* <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Started school year
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        Sep 2023 - Jun 2024
                      </StyledTableCell>
                    </StyledTableRow> */}


                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Email
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.email ? <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            {userDetails.email}
                            <Tooltip TooltipText="Verified" Tooltip={<VerifiedRoundedIcon fontSize='small' sx={{ color: '#0450E1' }} />} /></span> : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Phone number
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.phone
                            ? PhoneComponent(userDetails.phone)
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Country
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.country
                            ? countries?.find(country => country.value === userDetails.country)?.label
                            : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Address
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.addressLine1 ? `${userDetails?.addressLine1 || ","} ${userDetails?.city || ","} ${userDetails?.state} ${userDetails?.zip}` : <span className='noDataspan'>No data available</span>}
                        </StyledTableCell>
                      </StyledTableRow>

                      {(userDetails?.userType === 'parent') &&
                        <StyledTableRow>
                          <StyledTableCell align="left" className="TableLabel">
                            Highest education
                          </StyledTableCell>
                          <StyledTableCell align="left" className="TableInfo">
                            {userDetails?.highestEducation
                              ? userDetails.highestEducation
                              : <span className='noDataspan'>No data available</span>}
                          </StyledTableCell>
                        </StyledTableRow>}

                      {(userDetails?.userType === 'parent') &&
                        <StyledTableRow>
                          <StyledTableCell align="left" className="TableLabel">
                            Occupation
                          </StyledTableCell>
                          <StyledTableCell align="left" className="TableInfo">
                            {userDetails?.occupation
                              ? userDetails.occupation
                              : <span className='noDataspan'>No data available</span>}
                          </StyledTableCell>
                        </StyledTableRow>}

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          QR Code
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          <div className='tableflex'>
                            <div className='flexCenter'>
                              {(userDetails?.qrCode) && <CheckCircleRoundedIcon sx={{ color: '#66BD50' }} />}
                              {/* <ErrorOutlineRoundedIcon sx={{ color: '#BA1A1A' }} /> if the button is "Missing" */}
                              {(userDetails?.qrCode) ? <span>Ready</span> : <span className='noDataspan' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}> <ErrorOutlineRoundedIcon sx={{ color: '#BA1A1A' }} fontSize='small' /> Missing</span>}
                            </div>
                            {/* <GrPowerCycle /> if button is Generate now */}
                            {(userDetails?.qrCode) && <Buttons
                              label="Download"
                              icon={<FiDownload />}
                              buttonColor=""
                              border="#E4E8EC 1px solid"
                              color="#004FE0"
                              width=""
                              height="28px"
                              fontSize="14px"
                              link={userDetails?.qrCode}
                              onClick={(e) => console.log("clicked for download")}
                            />}

                            {(!userDetails?.qrCode) && <Buttons
                              label="Generate now"
                              icon={<SyncRoundedIcon sx={{ color: '#004FE0' }} fontSize='small' />}
                              buttonColor=""
                              border="#E4E8EC 1px solid"
                              color="#004FE0"
                              width=""
                              height="28px"
                              fontSize="14px"
                              // link={userDetails?.qrCode}
                              onClick={(e) => generateQRHandler()}
                            />}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>

                      {(userDetails?.userType === 'student' || userDetails?.userType === 'parent') &&
                        <StyledTableRow>
                          <StyledTableCell align="left" className="TableLabel">
                            Timezone
                          </StyledTableCell>
                          <StyledTableCell align="left" className="TableInfo">
                            {userDetails?.timezones
                              ? userDetails.timezones
                              : <span className='noDataspan'>No data available</span>}
                          </StyledTableCell>
                        </StyledTableRow>}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="InvitedUsers" style={{ marginTop: '24px' }}>
        <div className="TableContainer oddEvenTable">
          <div className='BoxTable'>
            <div className="TableInnerHeader">
              <h3>Access Details</h3>
              <Buttons
                label="Send New password"
                // icon={<FiDownload />}
                buttonColor="#0450E1"
                // border="#E4E8EC 1px solid"
                color="#fff"
                width=""
                height="36px"
                fontSize="14px"
                onClick={(e) => handleSendNewPassword()}
                ButtonLoader={changePasswordOptions?.isLoading}
                LoderColor="#fff"

              />
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  {isLoading ? (
                    <Loader
                      isFullWidth={true}
                      width="80px"
                      height="80px"
                      borderWidth="6px"
                    />
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel" width="36%">
                          Email
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.email}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel" width="36%">
                          Password
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableLabel accessDetailsPasswordRow">
                          <div className="PassworedDots">
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                            <FiberManualRecordIcon />
                          </div>
                          <Buttons
                            label="Send Reset Email"
                            icon={<RiSendPlaneFill />}
                            buttonColor=""
                            border="#C6C6D0 1px solid"
                            color="#004FE0"
                            width=""
                            height="28px"
                            fontSize="14px"
                            onClick={(e) => handleSendResetEmail()}
                            ButtonLoader={forgotPasswordOptions?.isLoading}
                            LoderColor="#0450E1"
                          //LoaderOnclick={}
                          />
                        </StyledTableCell>
                      </StyledTableRow>

                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {/* <Button variant='contained' onClick={() => setModal(true)}>modal</Button> */}

      <ModalThree
        // Modaltitle="Enter your password"
        // title="To access certain information, please confirm your password."
        handleClose={() => setModal(false)}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => setModal(false)}
        open={modal}
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={true}

        body={
          <>
            <EnteryourPassword
              cancleButtonOperation={(e) => setModal(false)}
              setShowFullBirthDay={setShowFullBirthDay}
              showFullBirthDay={showFullBirthDay}
            />
          </>
        }

      />
    </>
  );
};

export default Overview;
