import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import dateFormat, { masks } from 'dateformat';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Buttons from '../Form/Button/Button';
import Tooltip from '../Form/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SelectField from '../Form/SelectField/SelectField';
import './Table.css';
import { Button } from '@mui/material';

const StyledMenu = withStyles({})((props) => (
  <div className="ActionMenu">
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  </div>
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: '#fff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#004FE0',
      },
    },
  },
}))(MenuItem);

function TableCells(props) {
  const [timezone, setTimezone] = React.useState(() => {
    const timezone = localStorage.getItem('timezone');
    return timezone ? timezone : 'America/New_York';
  });

  const [checked, setChecked] = React.useState(true);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionBtnHandler = (label, path, id, item) => {
    setAnchorEl(null);
    if (item.onClick) {
      item.onClick();
    } else {
      console.log(label, path);
      if (label === 'View') {
        // Redirects to course listing page
        navigate(path, { replace: true });
      } else if (label === 'Edit') {
        // Redirects to course listing page
        navigate(path, { replace: true });
      } else if (label === 'Delete') {
        props.handleOpenDialog(id);
      } else if (item.extraOperation) {
        props.handleOpenDialog(id, item);
      }
    }
  };

  const btnHandleClick = (id, operation) => {
    console.log(id);
    if (typeof props.btnHandleClick !== 'undefined') {
      props.btnHandleClick(id, operation);
    }
  };

  const showIcon = (iconName) => {
    switch (iconName) {
      case 'ModeEditOutlineOutlinedIcon':
        return <ModeEditOutlineOutlinedIcon />;
      case 'DeleteOutlineOutlinedIcon':
        return <DeleteOutlineOutlinedIcon />;
      case 'FileDownloadOutlinedIcon':
        return <FileDownloadOutlinedIcon />;
      case 'InsertLinkOutlinedIcon':
        return <InsertLinkOutlinedIcon />;
      default:
        return null;
    }
  };

  const iconButtonHandler = (operationType, id) => {
    props.iconButtonHandler(operationType, id);
  };

  return (
    <>
      {props.cell.map((item, i) => (
        <TableCell className="TableCell" style={{ background: item.CellColor, maxWidth: item.width, paddingRight: item.padding, cursor: item.cursor }} onClick={item.onClick} >
          {item.SelectFiled && (
            <span className="TableSelectFiled">
              <SelectField
                isDisabled={item.isDisabled}
                selectedOption={item.selectedOption}
                options={item.options}
                handleChange={item.SelectHandleChanges}
              />
            </span>
          )}

          <span
            className="CellCont"
            style={{ float: item.align, justifyContent: item.TextAlign }}
          >
            {item.Check && (
              <span className="TableCheckbox">
                <Checkbox
                  defaultChecked={item?.defaultChecked}
                  color={item?.checkBoxColor ? item?.checkBoxColor : 'primary'}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onClick={(event) => props.handleClick(event, props.cell)}
                  checked={props.isItemSelected}
                />
              </span>
            )}

            {item.Radio && (
              <span className="TableRedio">
                <Radio
                  checked={item.selectedValue}
                  onChange={item.handleChangeRedio}
                  value={item.RedioValue}
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': `A` }}
                />
              </span>
            )}

            <span className="CellImg">
              {item.imgStatus && <Avatar src={item.img} />}
              {item.imgTextLink ? (
                <a href={item.imgTextLink}>{item.imgText}</a>
              ) : (
                <span className="textBold">{item.imgText} </span>
              )}
            </span>

            <span className="CellImg icon">
              {item.cardicon}
              <span className="textBold">{item.number}</span>
            </span>

            {item.iconStatus && (
              <span className="tableIcon">
                <a
                  href={item.iconLink}
                  onClick={
                    item.operationType
                      ? () => iconButtonHandler(item.operationType, item.id)
                      : () => { }
                  }
                >
                  {showIcon(item.icon)}
                </a>
              </span>
            )}

            <div style={{ display: 'flex', alignItems: 'center', width: "max-content" }}>
              {/* {item.Check &&
              <span className="TableCheckbox">
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={item.onChange}
                />
              </span>
            } */}
              {item.textBold && (
                <span className="textBold" style={{ color: item.textcolor }}>
                  {item.textBold}
                  <Tooltip
                    TooltipText={item.tooltipText}
                    Tooltip={<a className='TextWithLinkText' href={item.link}>{item.TextWithLinkText}</a>}
                  />

                </span>
              )}
              {item.tooltipViewText && (
                <Tooltip
                  TooltipText={item.tooltipText}
                  Tooltip={<a className='TextWithLinkText' href={item.link}>{item.TextWithLinkText}</a>}
                />
              )}
              {item.textLightStatus && (
                <a href={item.textLink} className="textLightLink">
                  {item.textLight}
                </a>
              )}
              {item.text && (
                <span className="text" style={{ color: item.textcolor, display: "flex", }}>
                  {item.iconstart} {item.text}
                </span>
              )}
              {item.date && <span className="textDate">{item.date}</span>}
              {item.zoneBasedDate && (
                <span className="textDate">
                  {moment(item.zoneBasedDate)
                    .tz(item.timezone ? item.timezone : timezone)
                    .format('ddd, MMM Do, YYYY z')}
                </span>
              )}
              {item.subTitle && (
                <span className="subTitle">{item.subTitle}</span>
              )}
            </div>

            {item.Status && (
              <span
                className="status rounded statussmall"
                style={{
                  marginLeft: item.space,
                  background: item.background,
                  color: item.color,
                  display: item.align,
                  width: item.StatusWidth,
                  fontSize: item.fontSize,
                  fontWeight: item.fontWeight
                }}
              >
                {item.statusLine && (
                  <div className='StatusLine' style={{ background: item.linebackground, }}></div>)}
                {item.label}
              </span>
            )}

            {item.VerifyIcon && (
              <span
                className="TableStatusIcon" style={{ background: item.background, }}>
                {item.icon}
              </span>
            )}

            <div className="tablelink">
              {item.chipStatus && (
                <span className="Tablechip rounded">{item.chip}</span>
              )}
              {item.linkTextStatus && (
                <a onClick={item.onClickLinkText} href={item.link}>
                  {item.linkText}
                </a>
              )}
            </div>

            {item.largeText && (
              <div className="largeText">
                <Tooltip
                  TooltipText={item?.tooltipLargeText}
                  Tooltip={item.largeText}
                />
              </div>
            )}

            {item.Button && (
              <div className="tableButtons" onClick={item.onClick}>
                <Tooltip
                  TooltipText={item.TooltipText}
                  Tooltip={
                    <Buttons
                      label={item.buttonLabel}
                      icon={showIcon(item.buttonIcon)}
                      buttonColor={item.buttonColor}
                      border={item.buttonBorder}
                      color={item.buttonTextcolor}
                      textTransform={item.textTransform}
                      width="auto"
                      height={props.height}
                      fontSize={
                        item.buttonFontSize ? item.buttonFontSize : '14px'
                      }
                      link={item?.buttonLink}
                      target={item?.buttonTarget}
                      disabled={item?.disabled}
                      onClick={
                        item.onClick
                          ? item.onClick
                          : () => btnHandleClick(item?.id, item?.operation)
                      }
                      padding={item.padding}
                      align={item?.align ? item.align : 'rigth'}
                    />
                  }
                />
              </div>
            )}

            {item.Component && <div className="">{item.Component}</div>}

            {item.ActionButton && (
              <div className="ActionButtons">
                <Button onClick={handleClick} style={item.ActionButtonsInlineStyles}>
                  {item.ActionButtonLabel} <KeyboardArrowDownOutlinedIcon />
                </Button>
              </div>
            )}

            {item.ActionButtonDotIcon && (
              <div className="ActionButtonsDotIcon">
                <Button onClick={handleClick}>
                  <MoreVertIcon />
                </Button>
              </div>
            )}

          </span>
        </TableCell>
      ))}

      <div className="ActionListing">
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.action?.map((item, i) => (
            <a className='TableActionListing' style={{ color: item.ActionLinkTextColor }}
              onClick={() =>
                actionBtnHandler(item.label, item?.path, item?.id, item)
              }
              href={item.link}
            >
              <ListItemText primary={<span style={{ fontWeight: 600, fontSize: '14px' }}>{item.label}</span>} sx={{ textTransform: "uppercase" }} />
            </a>
          ))}
        </StyledMenu>
      </div>
    </>
  );
}

export default TableCells;
