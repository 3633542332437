import React from "react";
import './ComingSoon.css';

import ClockIcon from '../../assets/imgs/Clock.svg';
import ComingSoonImg from '../../assets/imgs/website-maintenance.svg';



function ComingSoon(props) {



  return (
    <div className="ComingsoonCont">
      <h1>{props.title}</h1>
      <div className="ComingSoon">
        <img src={ComingSoonImg} />
        <h3>Coming soon</h3>
        <p>We are working hard to make this feature available</p>
      </div>
    </div>
  );
}


export default ComingSoon;

