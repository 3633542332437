import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SelectTemplate from './SelectTemplate';

import AddCategories from './AddCategories';
import { toast } from 'react-toastify';


const Sms = (props) => {
  const {
    messageDetails,
    messageObject,
    setMessageObject,
    errors
  } = props
  const [openTemplate, setTemplate] = useState(false);
  const handleOpenTemplate = () => setTemplate(true);
  const handleCloseTemplate = () => setTemplate(false);
  const [tempTemplate, setTempTemplate] = useState({})

  useEffect(() => {
    console.log('temporary template:', tempTemplate)

  }, [tempTemplate])






  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };




  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = () => { setOpenCategory(true); };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };



  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  // useEffect(() => {
  //   let newObject = { ...messageObject, messageSmsContent: ' ' }
  //   setMessageObject(newObject)
  // }, [])
  const [lastSelectionStartSection, setLastSelectionStartSection] = useState(0)
  const onChangeHandler = (val, e, fieldName) => {
    setLastSelectionStartSection(e.target.selectionStart)
    let newObject = { ...messageObject, [fieldName]: e.target.value }
    setMessageObject(newObject)
  }
  const checkTheSelection = (e, fieldName) => {
    setLastSelectionStartSection(e.target.selectionStart)
  }



  const handleSelectTemplate = () => {

    let str = messageObject.messageSmsContent
    const textBeforeCursor = str.slice(0, lastSelectionStartSection);
    const textAfterCursor = str.slice(lastSelectionStartSection);
    const newText = `${textBeforeCursor}${tempTemplate?.content}${textAfterCursor}`;

    setLastSelectionStartSection(newText.length)
    let newObject = { ...messageObject, messageSmsContent: newText }
    setMessageObject(newObject)


    handleCloseTemplate()
    setTempTemplate({})
  }
  const handleCopyCode = () => {
    if (messageObject?.messageSmsContent || messageDetails?.messageSmsContent) {
      navigator.clipboard.writeText(messageObject?.messageSmsContent || messageDetails?.messageSmsContent)
        .then(() => {
          toast.success('Copied');
        })
        .catch((error) => {
          console.error('Error copying to clipboard: ', error);
          toast.error('Failed to copy code');
        });
    } else {
      toast.warning('No code to copy');
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    navigator.clipboard.readText()
      .then(text => {

        let str = messageObject?.messageSmsContent || messageDetails?.messageSmsContent

        const textBeforeCursor = str.slice(0, lastSelectionStartSection);

        const textAfterCursor = str.slice(lastSelectionStartSection);
        const newText = `${textBeforeCursor}${text}${textAfterCursor}`;

        let newObject = { ...messageObject, messageSmsContent: newText }
        setMessageObject(newObject)
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
  const addTag = (val) => {
    let str = messageObject.messageSmsContent
    const textBeforeCursor = str.slice(0, lastSelectionStartSection);
    const textAfterCursor = str.slice(lastSelectionStartSection);
    const newText = `${textBeforeCursor}${val}${textAfterCursor}`;

    let newObject = { ...messageObject, messageSmsContent: newText }
    setMessageObject(newObject)
  }
  return (
    <>

      <Grid container spacing={3}>



        <Grid item md={12} xs={12}>
          <TextArea
            label="Message"
            validation={true}
            FieldLabel={true}

            value={messageObject?.messageSmsContent}
            helperText={messageObject?.messageSmsContent.length === 0 ? errors?.messageSmsContent : ''}
            error={messageObject?.messageSmsContent.length === 0 ? errors?.messageSmsContent : ''}
            handleChange={(val, e) => onChangeHandler(val, val, 'messageSmsContent')}
            // handleChange={(e) => onChangeHandler(e, 'messageSmsContent')}
            onKeyUpHandler={(e) => checkTheSelection(e, e.target.value)}
            onClickHandler={(e) => checkTheSelection(e, e.target.value)}
            count={300}
            HeaderRightBody={
              <div className='InsertSection'>
                <a onClick={handleOpenTemplate}><TextSnippetRoundedIcon />Insert template</a>
                <span>|</span>
                <a onClick={handleToggle}><BookmarkRoundedIcon />Insert tag</a>

                <div className={isActive ? "PricingCell" : "Active"}>
                  <div className='DropDownMenuCustom'>
                    <a onClick={() => addTag('{{FirstName}}')}>First name</a>
                    <a onClick={() => addTag('{{LastName}}')}>Last name</a>
                    <a onClick={() => addTag('{{FullName}}')}>Full name</a>
                  </div>
                  <div className='backdropAlfa' onClick={handleToggle}></div>
                </div>
              </div>
            }
            helperText={
              <div className='TextpastSection'>
                <a className='P_Active' onClick={handlePaste}>PASTE</a><span>|</span> <a className={messageDetails?.messageSmsContent || messageObject?.messageSmsContent?.length > 0 ? `P_Active` : `P_Defaulf`} onClick={handleCopyCode}>Copy message</a>
              </div>
            }
            PartStatus={true}
            PartCharaterLimit={150}
            Partlimit={2}
            allowExceedMaxLength={true}
            errorText={(messageObject?.messageSmsContent?.length > 300) ? "errorText" : ""}
          />
          {(messageObject?.messageSmsContent.length === 0) ? <p className="err_text">{errors?.messageSmsContent}</p> : ''}


        </Grid>
        {(messageObject?.messageSmsContent?.length > 300) &&
          <Grid item md={12} xs={12}>
            <div className='ErrorAlert'>
              <Alert variant="filled" severity="error">
                Your message contains too many characters. Please make your message text shorter
              </Alert>
            </div>
          </Grid>}

      </Grid>

      {/* Select Recipients*/}
      {/* Select Recipients*/}
      <SideDrawer
        open={openTemplate}
        handleDrawerClose={handleCloseTemplate}
        clickSaveButton={handleSelectTemplate}
        title="Select Template"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        ButtonLabel="SELECT"
        BackArrow={true}
        CloseArrowHide={true}
        FooterWithoutTab={true}
        padding="0"
        body={<SelectTemplate
          tempTemplate={tempTemplate}
          setTempTemplate={setTempTemplate} />}
      />

      {/* Add Catgory Modal*/}
      <AddCategories
        openModal={openCategory}
        handleClose={handleCloseCategory}
      />


    </>
  );
};

export default Sms;
