import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useProtected from '../../../hooks/useProtected';
import { useDeleteInviteUserMutation, useGetInviteUserListQuery, useGetInviteUsersSearchResultMutation } from '../../../redux/features/users/userApi';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import DraggableDialog from '../../../components/Dialog/Dialog';
import { toast } from 'react-toastify';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import InvitationDetails from './InvitationDetails';
// import { isFormElement } from 'react-router-dom/dist/dom';

const InviteUsers = (props) => {
  const { setInviteUserButtonEnableDisable, users, pageNumberFromOutSide } = props

  const [deleteUser, deleteUserOptions] = useDeleteInviteUserMutation();
  const [getSearchedUserList, options] = useGetInviteUsersSearchResultMutation();


  useProtected(options.error);
  useProtected(deleteUserOptions.error);


  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('')
  const [InviteDetailsDrawer, setCreateInviteDetails] = useState(false);
  const handleCloseInviteDetails = () => setCreateInviteDetails(false);
  const handleOpenDrawerInviteDetails = () => setCreateInviteDetails(true);
  const [selectedUser, setSelectedUser] = useState('');
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };

  const handleDetailsClick = (id) => {
    setSelectedUser(id);
    setCreateInviteDetails(true);
  };
  useEffect(() => {
    setInviteUserButtonEnableDisable(false)
  }, [])

  useEffect(() => {
    if (users) {
      setRows(convertDataToCellRows(users));
      // setUsers(data?.body?.data)
    }
  }, [users]);

  const onUserDeleteHandler = (id) => {
    handleOpenDelete()
    setDeleteUserId(id)
  }

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let statusTextcolor = ""
        let statusBackgroundColor = ""
        let statusLineBackground = ""
        let statusLevel = ""

        if (item?.status === 'invitation-pending') {
          statusBackgroundColor = "#EDEDEF"
          statusTextcolor = "#676C7B"
          statusLineBackground = "#979DAC"
          statusLevel = "Invitation Pending"
        } else if (item?.status === 'accepted') {
          statusBackgroundColor = "#E1F2E8"
          statusTextcolor = "#0B9444"
          statusLineBackground = "#41AB37"
          statusLevel = "Accepted"
        }

        const truncateText = (text, maxLength) => {
          return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        };
        const fullName = `${item.firstName} ${item.lastName}`;
        const truncatedName = truncateText(fullName, 55);

        return {
          cell: [

            { text: item?.email || "N/A" },
            // { text: `${item?.firstName} ${item?.lastName}` },
            { text: truncatedName },
            { text: item?.userType ? capitalizeFirstLetter(item?.userType) : 'N/A' },
            // { text: item?.role ? capitalizeFirstLetter(item?.role) : 'N/A' },
            {
              Status: true,
              background: statusBackgroundColor,
              color: statusTextcolor,
              linebackground: statusLineBackground,
              statusLine: true,
              label: statusLevel,
              fontSize: "12px"
            },
            {
              Button: true,
              buttonLabel: 'Details',
              buttonTextcolor: '#004FE0',
              onClick: (e) => handleDetailsClick(item?._id),
              TextAlign: "right",
            },
            // { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

          ],

          //======= Action Date Start===== //
          action: [
            { label: 'View', onClick: (e) => handleDetailsClick(item?._id) },
            { label: 'Edit', onClick: onClick },
            { label: 'Delete', onClick: (e) => onUserDeleteHandler(item?._id) },
          ],
          //======= Action Date End===== //
        }
      });
    } else {
      return [];
    }
  };
  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { id: 'Email', numeric: false, disablePadding: true, label: 'Email', width: '25%', textAlign: 'left', },
    { id: 'Name', numeric: true, disablePadding: false, label: 'Name', width: '30%', textAlign: 'left', },
    { id: 'User', numeric: true, disablePadding: false, label: 'User Type', width: '20%', textAlign: 'left', },
    // { id: 'Role', numeric: true, disablePadding: false, label: 'Role', width: '15%', textAlign: 'left', },
    { id: 'Status', numeric: true, disablePadding: false, label: 'Status', width: '20%', textAlign: 'left', },
    { id: 'Action', numeric: true, disablePadding: false, label: '', width: '15%', textAlign: 'left', },
  ];


  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };

  const deleteUserHandler = async () => {
    const result = await deleteUser(deleteUserId);
    if (result?.data) {
      toast.success('User deleted successfully');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setDeleteUserId('')

  }

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm });
    console.log(result);
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          // link="/invite-new-users"
          emptyTableTitle="No users added yet"
          emptyTableNote="No items available"
          btnLabel="New User"
          showButton={true}
          pageNumberFromOutSide={pageNumberFromOutSide}
          RowsPerPage={20}
        />
      </div>

      {/* Delete Group*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteUserHandler}
        title="Delete user?"
        body={<>Are you sure you want to delete this user?</>}
        ModalFooter={true}
        actionButton="DELETE"
        cancelButton="CANCEL"
      />

      {/* new user drawer */}
      <SideDrawer
        open={InviteDetailsDrawer}
        handleDrawerClose={handleCloseInviteDetails}
        title="Invitation details"
        sideOpen="right"
        FooterWithoutTab={false}
        body={
          <>
            <InvitationDetails
              closeDrawer={handleCloseInviteDetails}
              userId={selectedUser}
            />
          </>
        }
      />


    </>
  );
};

export default InviteUsers;
