import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '../../../assets/css/filter.css'
import CheckBox from "../../../components/Form/CheckBox/CheckBox";
import { toTitleCase } from "../../../utils/helpers";
import { useGetClassLevelListQuery } from "../../../redux/features/classes/classApi";
import useProtected from "../../../hooks/useProtected";
import DateRange from "../../../components/DateRange/DateRange";
import moment from "moment-timezone";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";





const PaymentFilter = ({ statuses, setStatuses, cycles, setCycles, academicYearIds, setAcademicYearIds, startDate, setStartDate, setEndDate, endDate }) => {




    //* ==================================== dates ==================================== *//
    const handleDateRangeChange = ({ startDate, endDate }) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }
    //* ==================================== dates ==================================== *//

    //* ==================================== class levels ==================================== *//
    const { data, isError, isLoading, error } = useGetSchoolYearListQuery(undefined, { refetchOnMountOrArgChange: true });
    ;
    const [classes, setClasses] = useState([])
    useProtected(error);
    useEffect(() => {
        if (!isError && !isLoading) {
            setClasses(data?.body?.data)
        }
    }, [data, isLoading, isError])

    const classesTypesHandlerFilter = (e, value) => {
        isCheckedOrNot(e, value, academicYearIds, setAcademicYearIds)
    }
    //* ==================================== class levels ==================================== *//

    //* ==================================== status ========================================== *//
    const paymentStatus = [
        {
            label: 'Due',
            value: 'due',
        },

        {
            label: 'Paid',
            value: 'success',
        },
        {
            label: 'Failed',
            value: 'failed',
        },
    ];
    const statusesTypesHandlerFilter = (e, value) => {
        isCheckedOrNot(e, value, statuses, setStatuses)
    }
    //* ==================================== status ========================================== *//

    //* ==================================== cycles ========================================== *//
    const paymentCycles = [
        {
            label: "One-time",
            value: 'onetime',
        },

        {
            label: "Recurring",
            value: 'recurring',
        },

    ];


    const cyclesTypesHandlerFilter = (e, value) => {
        isCheckedOrNot(e, value, cycles, setCycles)
    }

    //* ==================================== cycles ========================================== *//


    const isCheckedOrNot = (e, value, filterArray, setFilterArray) => {
        let data = [...filterArray]
        if (e.target.checked) {
            data.push(value)
        } else {
            data = filterArray?.filter((item) => item !== value)
        }
        setFilterArray(data)
    }


    return (

        <div className="FilterMainCont">


            {/* ========================== status =========================================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Status
                    </Typography>
                    {statuses?.length > 0 && (<Typography className="FilterCount">{statuses?.length}</Typography>)}
                </AccordionSummary>
                <AccordionDetails>

                    {
                        paymentStatus?.map((item) =>
                            <CheckBox
                                label={item.label}
                                checked={statuses?.includes(item?.value)}
                                onChange={(e) => statusesTypesHandlerFilter(e, item.value)}
                            />
                        )
                    }

                </AccordionDetails>
            </Accordion>

            {/* ========================== Cycle ============================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Cycle
                    </Typography>
                    {cycles?.length > 0 && (<Typography className="FilterCount">{cycles?.length}</Typography>)}
                </AccordionSummary>
                <AccordionDetails>

                    {
                        paymentCycles?.map((item) =>
                            <CheckBox
                                label={item.label}
                                checked={cycles?.includes(item?.value)}
                                onChange={(e) => cyclesTypesHandlerFilter(e, item.value)}
                            />
                        )
                    }
                </AccordionDetails>
            </Accordion>

            {/* ========================== Due Date ============================== */}
            {/* <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Due Date
                    </Typography>
                    {(endDate || startDate) && (<Typography className="FilterCount">1</Typography>)}
                </AccordionSummary>
                <AccordionDetails>

                    <DateRange onDateRangeChange={handleDateRangeChange} startDateData={startDate} endDateData={endDate} />

                </AccordionDetails>
            </Accordion> */}

            {/* ========================== Academic Year ================================ */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Academic Year
                    </Typography>
                    {academicYearIds?.length > 0 && (<Typography className="FilterCount">{academicYearIds?.length}</Typography>)}
                </AccordionSummary>
                <AccordionDetails>
                    {
                        classes?.map((item) =>
                            <CheckBox
                                label={item.name}
                                checked={academicYearIds?.includes(item?._id)}
                                onChange={(e) => classesTypesHandlerFilter(e, item._id)}
                            />
                        )
                    }

                </AccordionDetails>
            </Accordion>

        </div>
    );
};

export default PaymentFilter;
