import React, { useEffect, useState } from 'react';
import ComingSoon from '../../../../components/ComingSoon/ComingSoon';


const Transactions = () => {




    return (
        <>
            <div className='settingsTableContainer'>
                <ComingSoon />
            </div>

        </>
    );
};

export default Transactions;
