import React from "react";
import { makeStyles, } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './StatusDropdown.css';



{/* <StatusDropdown
  labelTop={false}
  labelinsideStatus={true}
  statustData={statustData}
  defaultValue={courseData.status ? courseData.status : ""}
  handleChange={handleChange}
  width="140px"
  height="40px"
  Status={true}
/> */}



function StatusDropdown(props) {
  const { statustData } = props;

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="StatusDropdown">

      <div className="StatusDropdownBody" style={{ height: props.height, width: props.width }}>

        <FormControl >
          <Select
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={props.handleChange}
            value={props.Value}
            defaultValue={props.defaultValue}
          >

            {statustData.map((select, i) => (
              <MenuItem
                key={i}
                value={select.value}
                className="StatusMenuList">

                <div className="StatusInfo">
                  {props.Status &&
                    <span
                      style={{
                        width: "6px",
                        height: "56px",
                        display: "inline-block",
                        borderRadius: "100px",
                        marginRight: "10px",
                        background: select.statuscolor
                      }}
                      className="StatusLine"
                    >
                    </span>}
                  <div>
                    <div className="DropdownStatusTitle" style={{ color: select.statuscolor }}>{props.StatusLabel}</div>
                    <span className="DropdownMenuListLabel" style={{ color: select.statuscolor }}>{select.select}</span>
                  </div>
                </div>

                <div className="StatusBackground" style={{ background: select.statuscolor }}></div>

              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

    </div>
  );
}


export default StatusDropdown;

