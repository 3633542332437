import React, { useContext, useEffect, useState } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@mui/material/Button';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
//import pending icon from material ui
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Buttons from '../../../../components/Form/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useProtected from '../../../../hooks/useProtected';
import { useAddPhoneNumberMutation } from '../../../../redux/features/users/userApi';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ModalThree from '../../../../components/Form/Modals/ModalThree';
import FieldPassword from '../../../../components/Form/FieldPassword/FieldPassword';
import axiosApi from '../../../../utils/axios';
import { PasswordContext } from '../../../../context/PaswordProvider';

const AccSecurity = ({ personalInfo }) => {
    const [addPhoneNumber, addPhoneNumberOptions] = useAddPhoneNumberMutation();
    useProtected(addPhoneNumberOptions.error);


    //context api
    const { setPasswordValue } = useContext(PasswordContext)
    const navigate = useNavigate();

    const handleVerifyPhoneNumber = async () => {
        if (!personalInfo?.phone || personalInfo?.phoneVerified) {
            navigate('/add-phone-number');
            return;
        }

        if (!personalInfo?.phone) {
            toast.error("Phone number required");
            return;
        }
        const data = {
            // phone_number: phone.number,
            // country_code: phone.country.dialCode,
            phone: personalInfo?.phone,
        };
        const result = await addPhoneNumber(data);
        if (!result?.data?.error) {
            navigate(`/verify-otp?phone=${personalInfo?.phone}`)
        } else {
            toast.error(result?.data?.message)
        }
    }

    //================== password change part start ====================================//
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({
        password: '',
    });
    const [passwordChangeModal, setPasswordChangeModal] = useState(false)
    const openModal = () => {
        setPasswordChangeModal(true)
    }

    const handleCloseModal = () => {
        setPassword('')
        setPasswordChangeModal(false)
        setErrors({ ...errors, password: '' })
    }

    //================== google recapcha v3 part start ====================//
    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    //================== google recapcha v3 part end ====================//

    const isValidPassword = async () => {
        if (password == "") {
            setErrors({ ...errors, password: 'Enter your password' })
            return false
        }
        return true
    }

    const handleCheckPassword = async () => {
        ///===================== recapcha ==============================///
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
                ///===================== recapcha ==============================///
                let email = personalInfo?.email

                const data = {
                    email: email ? email.toLowerCase() : '',
                    password,
                    token
                }
                let isPasswordValid = await isValidPassword()
                if (!isPasswordValid) {
                    return;
                }
                try {
                    const result = await axiosApi.post('/users/public/login', data);
                    setPasswordValue(data?.password)
                    // console.log(result, 63)
                    if (result?.data?.error) {
                        setErrors({ ...errors, password: 'Enter your correct password' })
                        return;
                    }
                    if (result?.data?.body?.data) {
                        navigate('/create-new-password');
                    }
                } catch (err) {
                    console.error(err.message)
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        });
    }
    //================== password change part end ====================================//
    return (
        <>

            <div className="TableContainer" style={{ border: '1px solid #E8EAED' }}>
                <div className="TableInnerHeader" style={{ padding: '0 1.2rem', margin: '14px 0' }}>
                    <h3>Access details</h3>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableBody>

                            <StyledTableRow>

                                <StyledTableCell component="th" scope="row" width="25%" height="54px">
                                    Email address
                                </StyledTableCell>

                                <StyledTableCell align="left" width="60%">
                                    <div className="cellwithchip">
                                        <div className="tabletdvrf">
                                            {personalInfo?.email}
                                            {/* ================ status label ================ */}
                                            <div>
                                                {/* <div className="PendingChip"><WarningRoundedIcon /> Pending</div> */}
                                                <div className="VerifyChip"><VerifiedRoundedIcon /> Verified</div>
                                            </div>
                                        </div>
                                    </div>
                                </StyledTableCell>
                                {/* <StyledTableCell align="left" width="20%">
                                </StyledTableCell> */}
                                {/* ========== verify button ========== */}
                                {/* <StyledTableCell align="left" width="20%">
                                    <Buttons
                                        label="Verify Now"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="180px"
                                        height="40px"
                                        fontSize="14px"
                                        link=""
                                    // onClick={verifyNowEmail}
                                    />
                                </StyledTableCell> */}
                                <StyledTableCell align="left">
                                    {/* ========== Change button ========== */}
                                    {/* <Buttons label="Change"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="160px"
                                        height="40px"
                                        fontSize="14px"
                                    //   onClick={changePhoneNumberbutton} 
                                    />  */}
                                    {/* ========== Add email button ========== */}
                                    {/* <Buttons
                                        label="Add email"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="180px"
                                        height="40px"
                                        fontSize="14px"
                                        link=""
                                    //   onClick={addPhoneNumberbutton}
                                    /> */}
                                </StyledTableCell>

                            </StyledTableRow>


                            <StyledTableRow>

                                <StyledTableCell component="th" scope="row">
                                    Phone
                                </StyledTableCell>

                                <StyledTableCell align="left" width="60%">
                                    <div className="tabletdvrf">
                                        {
                                            personalInfo?.phone ? PhoneComponent(personalInfo.phone) : "Add a phone number to keep your account safe"
                                        }
                                        {
                                            personalInfo?.phone ? personalInfo?.phoneVerified ? (
                                                <div className="">
                                                    <div className="VerifyChip"><VerifiedRoundedIcon /> Verified</div>
                                                </div>
                                            ) : (
                                                <div className="PendingChip"><WarningRoundedIcon /> Pending</div>
                                            ) : null
                                        }

                                        {/* ================ status label ================ */}
                                        {/* <div className="">
                                            <div className="VerifyChip"><VerifiedRoundedIcon /> Verified</div>
                                            <div className="PendingChip"><WarningRoundedIcon /> Pending</div>
                                        </div> */}
                                    </div>
                                </StyledTableCell>
                                {/* ========== verify button ========== */}
                                {/* <StyledTableCell align="left">
                                    <span>
                                        <div>
                                            <Buttons
                                                label="Verify Now"
                                                //   onClick={verifyNow}
                                                buttonColor="#FFF"
                                                border="#ccc 1px solid"
                                                color="#004FE0"
                                                width="130px"
                                                height="40px"
                                                fontSize="14px"
                                            />
                                        </div>
                                    </span>
                                </StyledTableCell> */}

                                <StyledTableCell align="left">
                                    {/* ========== Change button ========== */}
                                    {/* <Buttons label="Change"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="160px"
                                        height="40px"
                                        fontSize="14px"
                                    //   onClick={changePhoneNumberbutton} 
                                    />  */}
                                    {/* ========== Add phone button ========== */}
                                    {
                                        personalInfo?.phone ? <Buttons
                                            label={!personalInfo?.phoneVerified ? "Verify" : "Change"}
                                            buttonColor="#FFF"
                                            border="#ccc 1px solid"
                                            color="#004FE0"
                                            width="180px"
                                            height="40px"
                                            fontSize="14px"
                                            // link="/add-phone-number"
                                            onClick={handleVerifyPhoneNumber}
                                        /> : <Buttons
                                            label={"Add Phone"}
                                            buttonColor="#FFF"
                                            border="#ccc 1px solid"
                                            color="#004FE0"
                                            width="180px"
                                            height="40px"
                                            fontSize="14px"
                                            link="/add-phone-number"
                                        // onClick={handleVerifyPhoneNumber}
                                        />
                                    }


                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow >
                                <StyledTableCell align="left" component="th" scope="row" width="25%">Password</StyledTableCell>
                                <StyledTableCell align="left">
                                    <div className="PassworedDots">
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                        <FiberManualRecordIcon />
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {/* ========== Change button ========== */}
                                    {/* <Buttons label="Change"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="160px"
                                        height="40px"
                                        fontSize="14px"
                                    //   onClick={changePhoneNumberbutton} 
                                    />  */}
                                    {/* ========== Add phone button ========== */}

                                    <Buttons
                                        label="Change"
                                        buttonColor="#FFF"
                                        border="#ccc 1px solid"
                                        color="#004FE0"
                                        width="180px"
                                        height="40px"
                                        fontSize="14px"
                                        // link="/add-phone-number"
                                        onClick={openModal}
                                    />

                                </StyledTableCell>
                            </StyledTableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/* ====================== */}
            <ModalThree
                open={passwordChangeModal}
                handleClose={handleCloseModal}
                width="490px"
                Modaltitle="Please re-enter your password"
                titileColor="#33B651"
                padding="16px 0px 0px 0px"

                body={
                    <div>
                        <p style={{ textAlign: 'center' }}>For your security, you must re-enter your password to continue.</p>
                        &nbsp;
                        <FieldPassword
                            required={true}
                            // handleOnChange={(e) => handleReenterPassword(e)}
                            value={password}
                            handleChange={(value) => {
                                if (value == "") {
                                    setErrors((prev) => {
                                        prev.password = 'Enter your password';
                                        return prev;
                                    });
                                } else {
                                    setErrors((prev) => {
                                        prev.password = '';
                                        return prev;
                                    });
                                }
                                setPassword(value);
                            }}
                            helperText={errors?.password}
                            error={errors?.password}
                            placeholder="Password"
                        />
                        &nbsp;
                        <Buttons
                            label="Continue"
                            buttonColor="#004FE0"
                            border="#004FE0 1px solid"
                            color="#fff"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={() => handleCheckPassword()}
                        />
                        <Buttons
                            label="Cancel"
                            buttonColor="#fff"
                            border="#fff 1px solid"
                            color="#000000"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            link=""
                            onClick={handleCloseModal}
                        />
                    </div>
                }

            />
        </>
    )
}

export default AccSecurity;



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function formatPhoneNumber(s) {
    // Remove all non-numeric characters
    const cleaned = ('' + s).replace(/\D/g, '');

    const length = s.length;

    console.log(length)

    if (length == 11) {

        // Check if the cleaned phone number has 11 digits (with country code)
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    } else if (length == 13) {

        // Check if the cleaned phone number has 13 digits (with country code)
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    } else if (length == 12) {

        // Check if the cleaned phone number has 12 digits (with country code)
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    }

    return s;
}

function PhoneComponent(phoneNumber) {

    let phone = formatPhoneNumber(phoneNumber.trim());

    if (!phone.startsWith('+')) {
        phone = '+' + phone;
    }


    return (phone);
}