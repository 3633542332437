import React, { useCallback, useEffect, useRef, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate, ReactEditor } from "slate-react";
import { Editor, Transforms, createEditor, Range, Node, Text, Element } from "slate";
import { withHistory } from "slate-history";
import FormatBold from "@material-ui/icons/FormatBold";
import FormatItalic from "@material-ui/icons/FormatItalic";
import FormatUnderlined from "@material-ui/icons/FormatUnderlined";
import LooksOne from "@material-ui/icons/LooksOne";
import LooksTwo from "@material-ui/icons/LooksTwo";
import FormatQuote from "@material-ui/icons/FormatQuote";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import { Button, Icon, Toolbar } from "./TextEditorComponents";
import './TextEditor.css';



const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "link",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export default function TextEditor(props) {

    const defaultValue = [{ type: 'paragraph', children: [{ text: "" }] }];

    const renderElement = useCallback((props) => <RenderElement {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    //const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const editorRef = useRef();
    if (!editorRef.current) editorRef.current = withHistory(withReact(createEditor()));
    const editor = editorRef.current;

    const { customText, handleOnFocus = null } = props;

    useEffect(() => {
        if (customText != null && customText != undefined && customText !== "")
            Transforms.insertText(editor, customText);
    }, [customText]);

    const [hasValue, setHasValue] = useState(false);

    const [charCount, setCharCount] = useState(0);

    function truncateNodesUntilLimit(nodes, charLimit) {
        let textLength = 0;
        const truncatedNodes = [];

        for (const node of nodes) {
            if (Element.isElement(node)) {
                const nodeTextLength = calculateTextLength(node);

                if (textLength + nodeTextLength <= charLimit) {
                    textLength += nodeTextLength;
                    truncatedNodes.push(node);
                } else if (node.type === 'numbered-list' || node.type === 'bulleted-list') {
                    const truncatedChildren = truncateNodesUntilLimit(node.children, charLimit - textLength);
                    truncatedNodes.push({ ...node, children: truncatedChildren });
                    break;
                } else if (node.type === 'list-item') {
                    const truncatedChildren = truncateNodeChildren(node.children, charLimit - textLength);
                    truncatedNodes.push({ ...node, children: truncatedChildren });
                    break;
                } else if (node.type === 'paragraph' || node.type === 'heading1' || node.type === 'heading2' || node.type === 'blockquote') {
                    const truncatedChildren = truncateNodeChildren(node.children, charLimit - textLength);
                    truncatedNodes.push({ ...node, children: truncatedChildren });
                    break;
                }
            }
        }

        return truncatedNodes;
    }

    function truncateNodeChildren(children, charLimit) {
        let textLength = 0;
        const truncatedChildren = [];

        for (const child of children) {
            if (Text.isText(child)) {
                const childTextLength = child.text.length;

                if (textLength + childTextLength <= charLimit) {
                    textLength += childTextLength;
                    truncatedChildren.push(child);
                } else {
                    const truncatedText = truncateText(child.text, charLimit - textLength);
                    truncatedChildren.push({ ...child, text: truncatedText });
                    break;
                }
            } else if (Element.isElement(child)) {
                const childTextLength = calculateTextLength(child);

                if (textLength + childTextLength <= charLimit) {
                    textLength += childTextLength;
                    truncatedChildren.push(child);
                } else {
                    const truncatedNode = truncateNode(child, charLimit - textLength);
                    truncatedChildren.push(truncatedNode);
                    break;
                }
            }
        }

        return truncatedChildren;
    }

    function truncateNode(node, charLimit) {
        if (Element.isElement(node)) {
            const { children, ...otherProps } = node;
            const truncatedChildren = truncateNodeChildren(children, charLimit);
            return { ...otherProps, children: truncatedChildren };
        }

        return node;
    }

    function truncateText(text, charLimit) {
        return text.slice(0, charLimit);
    }

    function calculateTextLength(node) {
        if (Node.isNode(node) && node.children) {
            return node.children.reduce((length, child) => length + calculateTextLength(child), 0);
        }

        if (Text.isText(node)) {
            return node.text.length;
        }

        return 0;
    }



    useEffect(() => {

        if (!props.value)
            return;

        // if (!hasValue) {

        let value = JSON.parse(props?.value);

        value = truncateNodesUntilLimit(value, props.charLimit);

        // editor.children = arr;
        // Editor.normalize(editor, { force: true });

        // Delete all entries leaving 1 empty node
        Transforms.delete(editor, {
            at: {
                anchor: Editor.start(editor, []),
                focus: Editor.end(editor, []),
            },
        })

        // Removes empty node
        Transforms.removeNodes(editor, {
            at: [0],
        })

        // Insert array of children nodes
        Transforms.insertNodes(
            editor,
            value
        )

        const charCount = Editor.string(editor, []).length;
        setCharCount(charCount);
        // }

        setHasValue(true);

    }, [props.value])


    const handleDOMBeforeInput = (event) => {
        const inputType = event.inputType;
        if (inputType === 'insertText') {
            const textLength = Editor.string(editor, []).length;
            if (textLength >= props.charLimit) {
                event.preventDefault();
                return;
            }
        }
    };


    const getSelectionLength = () => {
        const { selection } = editor;

        if (selection && Range.isExpanded(selection)) {
            const [start, end] = Range.edges(selection);
            const selectedText = Editor.string(editor, { anchor: start, focus: end });
            return selectedText.length;
        }

        return 0;
    };

    const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text/plain');
        const editorLength = Editor.string(editor, []).length - getSelectionLength();
        const totalLength = editorLength + pastedText.length;

        if (editorLength >= props.charLimit) {
            event.preventDefault();
            return;
        }

        if (totalLength > props.charLimit) {
            const remainingLength = props.charLimit - editorLength;

            const truncatedText = pastedText.substring(0, remainingLength);

            Editor.insertText(editor, truncatedText);

            event.preventDefault();
            return;
        }

    };


    // const handlePaste = (event) => {
    //     const clipboardData = event.clipboardData || window.clipboardData;
    //     const pastedText = clipboardData.getData('text/plain');
    //     const remainingChars = props.charLimit;

    //     const truncatedChildren = editor.children.map((child) => {
    //       const currentTextLength = Node.string(child).length;
    //       const childRemainingChars = remainingChars - currentTextLength;

    //       let truncatedText = pastedText;
    //       if (truncatedText.length > childRemainingChars) {
    //         truncatedText = truncatedText.substring(0, childRemainingChars);
    //       }

    //       remainingChars -= truncatedText.length;

    //       return Text.create(truncatedText);
    //     });

    //     const newEditorValue = [{ type: 'paragraph', children: truncatedChildren }];

    //     setValue(newEditorValue);
    //   };


    return (
        <div style={props.styles} className={props.className}>
            <div className='TextAreaHeader'>
                {props.FieldLabel && (<div className="FieldLabel">{props.FieldLabel} {props.required && <span>*</span>} </div>)}
                <div className='HeaderRightBody'>{props.HeaderRightBody}</div>
            </div>
            {props.ButtonHeaderStatus &&
                <div className='TextAreaButtonHeader'>{props.ButtonHeader}</div>
            }



            <div className="SlateEditorTextAreaContainer">
                <div className={props.EditorError}>
                    <div className={`${props.errorMsg !== ""}` ? "SlateEditor" : "SlateEditorRed"} style={{ border: `${props.errorMsg} && 1px solid red !important` }}>

                        <Slate
                            editor={editor}
                            value={defaultValue}
                            onChange={(value) => {
                                props.onChange(JSON.stringify(value));
                                const charCount = Editor.string(editor, []).length;
                                setCharCount(charCount);
                            }}

                        >
                            {props.readOnly ? <></> :
                                <div className="SlateEditorTools">
                                    <Toolbar>
                                        <MarkButton format="bold" icon={<FormatBold />} />
                                        <MarkButton format="italic" icon={<FormatItalic />} />
                                        <MarkButton format="underline" icon={<FormatUnderlined />} />
                                        <BlockButton format="heading-one" icon={<LooksOne />} />
                                        <BlockButton format="heading-two" icon={<LooksTwo />} />
                                        <BlockButton format="block-quote" icon={<FormatQuote />} />
                                        <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
                                        <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
                                    </Toolbar>
                                </div>
                            }

                            <div className="SlateEditorTextArea" style={{ minHeight: props.height }}>
                                <Editable
                                    style={{ minHeight: props.height }}
                                    placeholder={props.placeholder}
                                    readOnly={props.readOnly}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                    onDOMBeforeInput={handleDOMBeforeInput}
                                    onPaste={handlePaste}
                                    onKeyDown={(event) => {
                                        for (const hotkey in HOTKEYS) {
                                            if (isHotkey(hotkey, event)) {
                                                event.preventDefault();
                                                const mark = HOTKEYS[hotkey];
                                                toggleMark(editor, mark);
                                            }
                                        }
                                    }}
                                    onFocus={handleOnFocus}
                                />
                            </div>
                        </Slate>
                    </div>
                </div>
                {props.showCharLimit === true ?
                    <div className="EditCharCounter">
                        <span class="FieldCount">
                            <span style={{ color: "rgb(182, 185, 185)", alignSelf: "flex-end" }}>
                                {`CHAR ${charCount}/${props.charLimit}`}
                            </span>
                        </span>
                    </div>
                    :
                    <></>}
            </div>



            <div className="HelperTextCounter">
                <div>
                    <p className="error-msg">{props.errorMsg}</p>
                    <p className="HelperText">{props.helperText}</p>
                </div>

            </div>

        </div >
    );
}

TextEditor.defaultProps = {
    charLimit: 256,
    showCharLimit: true,
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);
    Transforms.unwrapNodes(editor, {
        match: (n) => LIST_TYPES.includes(n.type),
        split: true,
    });
    Transforms.setNodes(editor, {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};
const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: (n) => n.type === format,
    });
    return !!match;
};
const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};
const RenderElement = ({ attributes, children, element }) => {
    switch (element.type) {
        case "block-quote":
            return <blockquote {...attributes}>{children}</blockquote>;
        case "bulleted-list":
            return <ul {...attributes}>{children}</ul>;
        case "heading-one":
            return <h1 {...attributes}>{children}</h1>;
        case "heading-two":
            return <h2 {...attributes}>{children}</h2>;
        case "list-item":
            return <li {...attributes}>{children}</li>;
        case "numbered-list":
            return <ol {...attributes}>{children}</ol>;
        default:
            return <p {...attributes}>{children}</p>;
    }
};
const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.code) {
        children = <code>{children}</code>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underline) {
        children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};
const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    );
};

