import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import '../../../assets/css/users.css';
import TextField from '../../../components/Form/TextField/TextField';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import { validateFormData } from '../../../utils/form';

import {
  useGetCourseDetailsQuery,
  useCreateCourseMutation,
  useUpdateClassMutation,
  useDeleteCourseTeachersMutation
} from '../../../redux/features/classes/classApi';



import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import SelectField from '../../../components/Form/SelectField/SelectField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import SelectPrimaryTeacher from './SelectPrimaryTeacher';
import { IconButton } from '@mui/material';

import SwitchButtonIOS from '../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import MultipleSelet from '../../../components/Form/MultipleSelet/MultipleSelet';
import { useGetUsersByUserTypesMutation } from '../../../redux/features/users/userApi';


const DuplicateSection = (props) => {
  const {
    schoolYear,
    setSchoolYear,
    classLevel,
    setClassLevel,
    handleClose,
    id,
    type,


    schoolYearOptions,
    setSchoolYearOptions,
    classLevelOptions,
    setClassLevelOptions,
    addSectionToCourseOptions,
    sectionDuplicateObject,
    setSectionDuplicateObject,
    sectionDuplicateErrors,
    setSectionDuplicateErrors,
    copySectionId,
    schoolYearList,
    courseDetails,
    duplicateStudentFilter,
    setDuplicateStudentFilter,
    duplicateInfoSection,
    setDuplicateInfoSection
  } = props



  const [getUsersByUserTypes, getUsersByUserTypesOptions] = useGetUsersByUserTypesMutation();
  useProtected(getUsersByUserTypesOptions.error);

  const [state, setState] = React.useState({
    checkedA: true,
  });



  useEffect(() => {
    if (copySectionId && courseDetails) {
      let foundSection = courseDetails?.sections?.find(singleSection => singleSection.id == copySectionId)
      console.log(foundSection, 'found section');
      if (foundSection && copySectionId != "") {
        setPrimaryTeacher(`${foundSection?.primaryTeacher?.firstName} ${foundSection?.primaryTeacher?.lastName}`)
        setTeacher(foundSection?.primaryTeacher)
        let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === foundSection?.acededemicYear?.name)
        setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

        setvalue(foundSection?.classLevel?.map(singleClassLevel => singleClassLevel.name))
      }
    }
  }, [copySectionId, courseDetails]);

  // useEffect(() => {
  //   if (copySectionId && duplicateSection) {

  //     setSectionDuplicateObject(duplicateSection)

  //     setPrimaryTeacher(`${duplicateSection?.primaryTeacher?.firstName} ${duplicateSection?.primaryTeacher?.lastName}`)
  //     setTeacher(duplicateSection?.primaryTeacher)
  //     let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === duplicateSection?.acededemicYear?.name)
  //     setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

  //     setvalue(duplicateSection?.classLevel?.map(singleClassLevel => singleClassLevel.name))
  //   }
  // }, [copySectionId, duplicateSection])


  const [termOptions, setTermOptions] = useState([])
  const [teacher, setTeacher] = useState({})
  const [tempTeacher, setTempTeacher] = useState({})
  const [primaryTeacher, setPrimaryTeacher] = useState('')


  const [userTypesDatas, setUserTypesDatas] = useState([])

  const getUsersByUserTypesData = async () => {
    const result = await getUsersByUserTypes({ userTypes: "faculty,staff,owner", data: "" })
    if (result?.data?.body?.data) {
      setUserTypesDatas(result?.data?.body?.data)
    }
  }

  const setTeacherHandler = () => {
    if (Object?.keys(tempTeacher)?.length < 1) {
      toast.error("Please select at least one user")
      return false
    }
    handleClosePrimaryTeacherDrawer()
    console.log(tempTeacher)
    setTeacher(tempTeacher)
    setPrimaryTeacher(tempTeacher?.name ? tempTeacher?.name : `${tempTeacher?.firstName} ${tempTeacher?.lastName}`)
  }

  // Add Primary Teacher //
  const [openPrimaryTeacherDrawer, setOpenPrimaryTeacher] = useState(false);
  const handlePrimaryTeacherDrawerOpen = () => {
    getUsersByUserTypesData()
    setOpenPrimaryTeacher(true);
  };
  const handleClosePrimaryTeacherDrawer = () => {
    setOpenPrimaryTeacher(false);
  };



  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  const onChangeHandler = (val, e, fieldName) => {
    if (fieldName === 'acededemicYear') {
      let schoolYearObject = schoolYearList?.body?.data?.find(schoolYearSingle => schoolYearSingle.name === e.target.value)
      setTermOptions(getPreparedArray(schoolYearObject?.terms || []))

    }
    let newObject = { ...sectionDuplicateObject, [fieldName]: e.target.value }
    setSectionDuplicateObject(newObject)
  }

  const getPreparedArray = (data) => {
    return data.map((item) => ({
      value: item.termName,
      label: item.termName,
    }));
  };
  const setLabelAsValueForMultiSelect = (classLevelOptions) => {
    let optionArray = classLevelOptions?.map(option => {
      return option.label
    })
    return optionArray
  }


  // === Multi Select == //
  const [value, setvalue] = React.useState([]);
  const handleChange = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let newObject = { ...sectionDuplicateObject, [fieldName]: typeof value === 'string' ? value.split(',') : value }
    setSectionDuplicateObject(newObject)
  };


  const onChangeHandlerForSecondDepth = (val, e, parentFieldName, fieldName) => {
    let newObject = {
      ...sectionDuplicateObject,
      [parentFieldName]: {
        ...sectionDuplicateObject[parentFieldName],
        [fieldName]: e.target.value
      }
    }
    setSectionDuplicateObject(newObject)
  }
  const isSameSectionCode = () => {
    let found = false
    courseDetails?.sections?.map((singleSection) => {
      if (sectionDuplicateObject?.sectionCode?.codePrefix == singleSection?.sectionCode?.codePrefix && sectionDuplicateObject?.sectionCode?.code == singleSection?.sectionCode?.code) {
        found = true
      }
    })
    return found
  }

  const changeDuplicateFilterHandler = (e, filterName) => {
    let newFilterName = []
    if (e.target.checked) {
      if (!duplicateStudentFilter.includes(filterName)) {
        newFilterName = [...duplicateStudentFilter, filterName]
      } else {
        newFilterName = [...duplicateStudentFilter]
      }
    } else {
      if (duplicateStudentFilter.includes(filterName)) {
        newFilterName = duplicateStudentFilter?.filter(singleFilter => singleFilter != filterName)
      } else {
        newFilterName = [...duplicateStudentFilter]
      }
    }
    setDuplicateStudentFilter(newFilterName)
  }
  const checkDuplicateInfoSectionHandler = (e, filterName) => {
    let newFilterName = []
    if (e.target.checked) {
      if (!duplicateInfoSection.includes(filterName)) {
        newFilterName = [...duplicateInfoSection, filterName]
      } else {
        newFilterName = [...duplicateInfoSection]
      }
    } else {
      if (duplicateInfoSection.includes(filterName)) {
        newFilterName = duplicateInfoSection?.filter(singleFilter => singleFilter != filterName)
      } else {
        newFilterName = [...duplicateInfoSection]
      }
    }
    setDuplicateInfoSection(newFilterName)
  }
  return (



    <>

      {addSectionToCourseOptions.isLoading ? (
        <Loader
          height="80px"
          width="80px"
          borderWidth="8px"
          isFullWidth={true}
          isOverlay={true}
        />
      ) : (
        <>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Section Name<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              <TextField
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                placeholder="Enter section name"
                required={true}
                count={20}
                name={'section'}
                helperText={sectionDuplicateObject.sectionName?.length === 0 ? sectionDuplicateErrors?.sectionName : ''}
                error={sectionDuplicateObject.sectionName?.length === 0 ? sectionDuplicateErrors?.sectionName : ''}
                value={sectionDuplicateObject.sectionName}
                handleChange={(val, e) => onChangeHandler(val, e, 'sectionName')}
              />
            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Class/Level<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              <MultipleSelet
                Placeholder="Select class/level"
                option={setLabelAsValueForMultiSelect(classLevelOptions)}
                handleChange={(e) => handleChange(e, 'classLevel')}
                value={value}
                helperText={value?.length === 0 ? sectionDuplicateErrors?.classLevel : ""}
                error={value?.length === 0 ? sectionDuplicateErrors?.classLevel : ""}
              />
            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Academic Year<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              < SelectField
                label="Select academic year"
                name="acededemicYear"
                options={schoolYearOptions}
                selectedOption={(typeof sectionDuplicateObject?.acededemicYear == "string") ? sectionDuplicateObject?.acededemicYear : sectionDuplicateObject?.acededemicYear?.name}
                helperText={sectionDuplicateErrors?.acededemicYear}
                error={sectionDuplicateErrors?.acededemicYear}
                handleChange={(val) => onChangeHandler(val, val, 'acededemicYear')}
              />
            </div>
          </div>

          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Academic Term</div>
            <div className='FormFieldCol'>
              < SelectField
                label="Select term"
                name="acededemicTerm"
                options={termOptions}
                selectedOption={(typeof sectionDuplicateObject?.acededemicTerm == "string") ? sectionDuplicateObject?.acededemicTerm : sectionDuplicateObject?.acededemicTerm?.termName}
                helperText={sectionDuplicateErrors?.acededemicTerm}
                error={sectionDuplicateErrors?.acededemicTerm}
                handleChange={(val, e) => onChangeHandler(val, val, 'acededemicTerm')}
              />
            </div>
          </div>

          <div className='FormFieldContainer alignTop'>
            <div className='FloatLabelCol' style={{ marginTop: '14px' }}>Section Code<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>

              <Grid container spacing={2}>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code prefix"
                    count={4}
                    name={'code_prefix'}
                    required={true}
                    helperText={sectionDuplicateObject?.sectionCode?.codePrefix?.length === 0 ? sectionDuplicateErrors?.sectionCode_codePrefix : ""}
                    error={sectionDuplicateObject?.sectionCode?.codePrefix?.length === 0 ? sectionDuplicateErrors?.sectionCode_codePrefix : ""}
                    value={sectionDuplicateObject?.sectionCode?.codePrefix}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'codePrefix')}
                  />

                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    CharAlignMid="CharAlignMid"
                    Uppercase="Uppercase"
                    placeholder="Enter code"
                    count={4}
                    name={'classCodeNumber'}
                    number="number"
                    required={true}
                    helperText={sectionDuplicateObject?.sectionCode?.code?.length === 0 ? sectionDuplicateErrors?.sectionCode_code : ""}
                    error={sectionDuplicateObject?.sectionCode?.code?.length === 0 ? sectionDuplicateErrors?.sectionCode_code : ""}
                    value={sectionDuplicateObject?.sectionCode?.code}
                    showErrorMsg={false}
                    handleChange={(val, e) => onChangeHandlerForSecondDepth(val, e, 'sectionCode', 'code')}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className='PreviewSection'>
                    Course code preview: <span style={{ marginLeft: "4px" }}>{sectionDuplicateObject?.sectionCode?.codePrefix} {sectionDuplicateObject?.sectionCode?.code}</span>
                  </div>
                  {(sectionDuplicateErrors?.sectionCode_codePrefix?.length === 4 && sectionDuplicateErrors?.sectionCode_code === 4) ? <p style={{ color: "#d32f2f" }}>Required</p> : ""}
                  {/* {(sectionDuplicateErrors?.sectionCode_codePrefix != "" || sectionDuplicateErrors?.sectionCode_code != "") && <p style={{ color: "#d32f2f" }}>Required</p>} */}

                  {isSameSectionCode() && <p style={{ color: "#d32f2f" }}>Section code already exists</p>}
                </Grid>

              </Grid>

            </div>
          </div>


          <div className='FormFieldContainer'>
            <div className='FloatLabelCol' style={{ marginTop: '-46px' }}>Primary Teacher</div>
            <div className='FormFieldCol'>
              <div className='PrimaryTeacher' onClick={handlePrimaryTeacherDrawerOpen}>
                <div className='PrimaryTeacherinfo'>
                  <SearchRoundedIcon className='iconSearch' />
                  <input type='text' />
                  <div className='FieldTextBox'>
                    <span>
                      {/* {(copySectionId && Object.keys(teacher).length === 0) && primaryTeacher} */}

                      {((Object.keys(teacher).length) > 0 && teacher) && `${teacher?.firstName} ${teacher?.lastName}`}</span> <FiberManualRecordRoundedIcon />
                    {((Object.keys(teacher).length) == 0) && <span style={{ color: '#ACAAAF' }}>Select primary teacher</span>}
                    {(!copySectionId || teacher) && teacher?.userUniqueID}
                  </div>
                </div>
                {((Object.keys(teacher).length) > 0) && <IconButton onClick={(e) => setTeacher({})} className='iconClose'><CancelRoundedIcon /></IconButton>}
              </div>
              <div className='FormHeader'>
                {sectionDuplicateErrors?.primaryTeacher != "" ? <p style={{ color: "#d32f2f" }}>{sectionDuplicateErrors?.primaryTeacher}</p> : <p>You can add additional team from course details.</p>}
              </div>
            </div>
          </div>

          <div className='FormFieldContainer'>
            <div className='FloatLabelCol'>Class Capacity<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>
              <TextField
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                placeholder="Enter max number of students"
                number="number"
                name={'classRoomSize'}
                required={true}
                helperText={sectionDuplicateObject?.classRoomSize?.length === 0 ? sectionDuplicateErrors?.classRoomSize : ""}
                error={sectionDuplicateObject?.classRoomSize?.length === 0 ? sectionDuplicateErrors?.classRoomSize : ""}
                value={sectionDuplicateObject?.classRoomSize}
                handleChange={(val, e) => onChangeHandler(val, e, 'classRoomSize')}
              />
            </div>
          </div>



          <div className='FormFieldContainer alignTop'>
            <div className='FloatLabelCol'>Select What To Duplicate<span className='requiredAsterisk'>*</span></div>
            <div className='FormFieldCol'>

              <Grid container spacing={2}>

                <Grid item md={12} xs={12}>
                  <div>Only selected info will be duplicated</div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="SwitchField CourseDrawerContainer">
                    <SwitchButtonIOS
                      title="Students"
                      handleChange={(e) => checkDuplicateInfoSectionHandler(e, 'students')}
                      checked={duplicateInfoSection.includes('students')}
                    />
                    <div style={duplicateInfoSection.includes('students') ? { display: 'block' } : { display: 'none' }}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <CheckBox
                            label="Active"
                            checked={duplicateStudentFilter.includes('active')}
                            onChange={(e) => changeDuplicateFilterHandler(e, 'active')}
                            color="primary"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <CheckBox
                            label="Inactive"
                            checked={duplicateStudentFilter.includes('inactive')}
                            onChange={(e) => changeDuplicateFilterHandler(e, 'inactive')}
                            color="primary"
                          />
                        </Grid>
                      </Grid>
                    </div>

                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="SwitchField CourseDrawerContainer">
                    <SwitchButtonIOS
                      title="Teaching Team"
                      handleChange={(e) => checkDuplicateInfoSectionHandler(e, 'teachingTeam')}
                      checked={duplicateInfoSection.includes('teachingTeam')}
                    />
                    <div style={duplicateInfoSection.includes('teachingTeam') ? { display: 'block' } : { display: 'none' }}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <CheckBox
                            label="Teaching Assistant"
                            checked={state.checkedB}
                            onChange={handleChangeCheck}
                            color="primary"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <CheckBox
                            label="Substitute Teacher"
                            checked={state.checkedB}
                            onChange={handleChangeCheck}
                            color="primary"
                          />
                        </Grid>
                      </Grid>

                    </div>

                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="SwitchField CourseDrawerContainer">
                    <SwitchButtonIOS
                      title="Schedule"
                      handleChange={handleChange}
                      checked={state.checkedB}
                    />


                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="SwitchField FieldComingSoon">
                    <h6>Announcements</h6>
                    <span>Coming Soon</span>
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="SwitchField FieldComingSoon">
                    <h6>Classwork</h6>
                    <span>Coming Soon</span>
                  </div>
                </Grid>

              </Grid>

            </div>
          </div>


        </>
      )}



      {/* Primary TeacherDrawer */}
      <SideDrawer
        open={openPrimaryTeacherDrawer}
        handleDrawerClose={handleClosePrimaryTeacherDrawer}
        title="Select Primary Teacher"
        sideOpen="right"
        ButtonLabel="Select"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={(e) => { setTeacherHandler() }}
        padding="0"
        BackArrow={true}
        CloseArrowHide={true}
        body={
          <>
            <SelectPrimaryTeacher setTeacher={setTeacher} setTempTeacher={setTempTeacher} userTypesDatas={userTypesDatas} />
          </>
        }
      />


    </>
  );
};

export default DuplicateSection;
