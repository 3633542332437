import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '../../../components/Form/TextField/TextField';
import { validateFormData } from '../../../utils/form';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import {
    useAddGroupsMutation,
    useGetGroupDetailsQuery,
    useUpdateGroupsMutation,
} from '../../../redux/features/groups/groupsApi';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import Date from '../../../components/Form/Datefield/Datefield';

import Button from '@mui/material/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import '../../../assets/css/TermsForm.css';
import dayjs from 'dayjs';
import { useGetSchoolYearDetailsQuery } from '../../../redux/features/school_years/schoolYearApi';
import MultipleSelect from '../../../components/Form/MultipleSelet/MultipleSelet';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';



const EditSchoolYear = (props) => {
    const { schoolYearName,
        setSchoolYearName,
        schoolYearStartDate,
        setSchoolYearStartDate,
        schoolYearEndDate,
        setSchoolYearEndDate,
        terms,
        setTerms,
        id,
        closeDrawer,
        formattedDate,
        createSchoolYearOptions,
        saveSchoolYearHander,
        termsNameError,
        schoolYearRangeError,
        setEditName,
        setEditTerms,
        academicYearNameError,
        setAcademicYearNameError,
        termsNameExistError,
        termsNameDateRangeError } = props


    const { data, isLoading, isError, error, refetch } = useGetSchoolYearDetailsQuery(id, {
        refetchOnMountOrArgChange: true,
    });

    useProtected(error)
    const [schoolYearInfo, setSchoolYearInfo] = useState([]);


    useEffect(() => {
        if (!isLoading && data && !error) {
            setSchoolYearInfo(data.body.data)
        }
    }, [data, error, isLoading])

    useEffect(() => {
        setEditName(schoolYearInfo.name)
        setSchoolYearName(schoolYearInfo.name)
        setSchoolYearStartDate(schoolYearInfo.startDate)
        setSchoolYearEndDate(schoolYearInfo.endDate)

        let newTerms = []
        let setEditNewTerms = []
        schoolYearInfo?.terms?.map((term) => {
            newTerms.push({ termName: term.termName, startDate: term.startDate, endDate: term.endDate, workdays: (term?.workdays) ? term.workdays : [] })
            setEditNewTerms.push({ termName: term.termName, startDate: term.startDate, endDate: term.endDate, workdays: (term?.workdays) ? term.workdays : [] })
        })

        setTerms(newTerms);
        setEditTerms([...setEditNewTerms]);

    }, [schoolYearInfo])


    const {
        data: groupDetails,
        isLoading: isGroupDetailsLoading,
        error: groupDetailsError,
    } = useGetGroupDetailsQuery(id, {
        skip: !id,
    });

    useProtected(groupDetailsError);

    const [state, setState] = React.useState({
        checkedA: true,
    });
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');


    const [errors, setErrors] = useState({
        name: '',
        description: '',
    });




    //=== Term add remove code ==//


    const handleremove = index => {
        const list = [...terms];
        list.splice(index, 1);
        setTerms(list);
    }

    const handleaddclick = () => {
        setTerms([...terms, { termName: '', startDate: formattedDate, endDate: formattedDate, workdays: [] }]);
    }
    //=== Term add remove code ==//

    const setTermName = (index, value) => {
        let newTerms = [...terms]
        newTerms[index].termName = value;
        setTerms(newTerms);
    }

    const setTermStartDate = (index, value) => {
        let newTerms = [...terms]
        newTerms[index].startDate = value;
        setTerms(newTerms);
    }

    const setTermEndDate = (index, value) => {
        let newTerms = [...terms]
        newTerms[index].endDate = value;
        setTerms(newTerms);
    }

    const setLabelAsValueForMultiSelect = (options) => {
        let optionArray = options?.map(option => {
            return option.label
        })
        return optionArray
    }

    const schoolDaysOptions = [
        {
            value: 'sunday',
            label: 'Sunday'
        },
        {
            value: 'mondy',
            label: 'Monday'
        },
        {
            value: 'tuesday',
            label: 'Tuesday'
        },
        {
            value: 'wednesday',
            label: 'Wednesday'
        },
        {
            value: 'thursday',
            label: 'Thursday'
        },
        {
            value: 'friday',
            label: 'Friday'
        },
        {
            value: 'saturday',
            label: 'Saturday'
        }
    ]
    const handleChangeSchoolDays = (event, fieldName, index) => {
        const {
            target: { value },
        } = event;

        let newTerms = [...terms]

        newTerms[index].workdays = typeof value === 'string' ? value.split(',') : value;
        setTerms(newTerms);


    };

    const makeFirstLetterCapitalToArray = (values) => {
        let newArr = []
        if (values && values.length > 0) {
            newArr = values.map(val => capitalizeFirstLetter(val))
        }
        return newArr
    }

    return (
        <>
            <div className="">
                {isGroupDetailsLoading ? (
                    <Loader
                        height="80px"
                        width="80px"
                        borderWidth="8px"
                        isFullWidth={true}
                        isOverlay={true}
                    />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <TextField
                                label="Academic Year Name"
                                validation={true}
                                CharAlignMid="CharAlignMid"
                                Uppercase="Uppercase"
                                count={30}
                                name={'name'}
                                handleChange={(e) => setSchoolYearName(e)}
                                helperText={schoolYearName?.length === 0 ? academicYearNameError : ""}
                                error={schoolYearName?.length === 0 ? academicYearNameError : ""}
                                value={schoolYearName}

                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Date
                                label="Start date"
                                dateFormat="DD-MMM-YYYY"
                                value={dayjs(schoolYearStartDate)} handleChange={(e) => setSchoolYearStartDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                                helperText={schoolYearRangeError}
                                error={schoolYearRangeError != ""}

                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Date
                                label="End date"
                                dateFormat="DD-MMM-YYYY"
                                value={dayjs(schoolYearEndDate)} handleChange={(e) => setSchoolYearEndDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="Devider"></div>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <div className="FormSubTitle">Terms</div>
                        </Grid>

                        {
                            terms?.map((x, i) => {
                                return (
                                    <Grid item xs={12} md={12}>
                                        <div className="TermsFrom">
                                            <div className="TermsFromBox">
                                                <Grid container spacing={3}>
                                                    <Grid item md={12} xs={12}>
                                                        <TextField
                                                            label="Term Name"
                                                            validation={true}
                                                            CharAlignMid="CharAlignMid"
                                                            Uppercase="Uppercase"
                                                            count={30}
                                                            name={'name'}
                                                            handleChange={(e) => setTermName(i, e)}
                                                            helperText={terms[i].termName?.length === 0 ? termsNameError[i] || termsNameExistError[i] : ""}
                                                            error={terms[i].termName?.length === 0 ? termsNameError[i] || termsNameExistError[i] : ""}
                                                            value={terms[i].termName}
                                                        //value={courseData.Id}
                                                        //handleChange={handleChanges("Id")}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Date
                                                            label="Start date"
                                                            dateFormat="DD-MMM-YYYY"
                                                            value={dayjs(x?.startDate)}
                                                            handleChange={(e) => setTermStartDate(i, `${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                                                            helperText={termsNameDateRangeError[i]}
                                                            error={termsNameDateRangeError[i]}
                                                        />
                                                    </Grid>

                                                    <Grid item md={6} xs={12}>
                                                        <Date
                                                            label="End date"
                                                            dateFormat="DD-MMM-YYYY"
                                                            value={dayjs(x?.endDate)}
                                                            handleChange={(e) => setTermEndDate(i, `${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                                                        />
                                                    </Grid>

                                                    <Grid item md={6} xs={12}>
                                                        <MultipleSelect
                                                            FieldLabel={true}
                                                            label="Select school days"
                                                            Placeholder="Select school days"
                                                            option={setLabelAsValueForMultiSelect(schoolDaysOptions)}
                                                            handleChange={(e) => handleChangeSchoolDays(e, 'workdays', i)}
                                                            value={makeFirstLetterCapitalToArray(terms[i].workdays)}
                                                        // helperText={courseErrors?.classLevel}
                                                        // error={courseErrors?.classLevel}
                                                        // errorHelperText={courseErrors?.classLevel}
                                                        />
                                                    </Grid>

                                                </Grid>

                                            </div>

                                            <div className="AddGroupFooter">

                                                <Button
                                                    className="RemoveField"
                                                    variant="text"
                                                    onClick={() => handleremove(i)}
                                                >
                                                    <DeleteForeverRoundedIcon />{' '}
                                                </Button>


                                            </div>

                                        </div>
                                    </Grid>
                                );
                            })}

                        <Grid item xs={12} md={12} sx={{ mt: -3 }}>
                            <div className="TermsFrom">
                                <div className="AddGroupFooter">
                                    <Button
                                        variant="text"
                                        onClick={handleaddclick}
                                        className="AddMoreButton"
                                    >
                                        <AddRoundedIcon />
                                        Add term
                                    </Button>
                                </div>
                            </div>
                        </Grid>



                    </Grid>
                )}
            </div>
            <DrawerFooterActions
                isLoading={createSchoolYearOptions.isLoading}
                handleClose={closeDrawer}
                handleAction={saveSchoolYearHander}
                closeBtnLabel={'Cancel'}
                mobileCloseBtnLabel={'Mobile Close'}
                actionButtonLabel={id ? 'SAVE' : 'Create Now'}
            />
        </>
    );
};

export default EditSchoolYear;
