import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from "../../../components/Form/TextField/TextField";
import SelectFieldMulticolor from "../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor";
import Datefield from "../../../components/Form/Datefield/Datefield";
import TimePickerField from "../../../components/Form/TimePicker/TimePicker";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddCategory from "./AddCategory";
import { useGetUserTypesQuery } from "../../../redux/features/user_types/userTypesApi";
import { useAddCalenderCategoryMutation, useCheckCalenderCategoryNameMutation } from "../../../redux/features/calender/calenderApi";
import { toast } from "react-toastify";

const AddSchedule = (props) => {

  const {
    scheduleObject,
    editScheduleMode,
    setScheduleObject,
    calenderCategories,
    categoryStatusData,
    scheduleErrorObject,
    setScheduleErrorObject,
  } = props

  const handleChange = (event, value) => {
    console.log(event.target.value, value);
  };

  const statustData = [
    { value: 1, statuscolor: "#D50000", select: "Holiday/Recess (No school)" },
    { value: 2, statuscolor: "#F6BF26", select: "First/Last Day of School" },
    { value: 3, statuscolor: "#F4511E", select: "End of Term" },
    { value: 4, statuscolor: "#8E24AA", select: "Half Day" },
    { value: 5, statuscolor: "#33B679", select: "Staff Only Report" },
    { value: 6, statuscolor: "#7986CB", select: "Regents & State Testing" },
  ];

  const onChangeHandler = (val, e, fieldName) => {
    setScheduleObject({ ...scheduleObject, [fieldName]: val })
    setScheduleErrorObject({ ...scheduleErrorObject, [fieldName]: "" })
  }

  const onChangeDateHandler = (val, fieldName) => {
    setScheduleObject({ ...scheduleObject, [fieldName]: val })
    setScheduleErrorObject({ ...scheduleErrorObject, [fieldName]: "" })
  }

  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const initialCalenderCategoryObject = {
    "name": "",
    "color": "",
    "userType": [],
  }

  const initialCalenderCategoryErrorObject = {
    "name": "",
    "color": ""
  }
  const {
    data: userTypeData,
    isFetching: userTypeIsFetching,
    isLoading: userTypeIsLoading,
    error: userTypeError
  } = useGetUserTypesQuery(undefined, { refetchOnMountOrArgChange: true });

  const [checkCalenderCategoryName, checkCalenderCategoryNameOptions] = useCheckCalenderCategoryNameMutation()
  const [addCalenderCategory, addCalenderCategoryOptions] = useAddCalenderCategoryMutation()
  const [calenderCategoryObject, setCalenderCategoryObject] = useState(initialCalenderCategoryObject)
  const [calenderCategoryErrorObject, setCalenderCategoryErrorObject] = useState(initialCalenderCategoryErrorObject)


  useEffect(() => {
    if (!userTypeIsLoading && !userTypeIsFetching) {
      setUserTypes(userTypeData?.body?.data)
    }
  }, [userTypeData, userTypeIsLoading, userTypeIsFetching])

  const handleDrawerOpen = () => {
    setOpenAddCategory(true);
  };
  const handleDrawerCloseAddCategory = () => {
    setOpenAddCategory(false);
  };

  const CloseAddDrawerHandler = () => {
    handleDrawerCloseAddCategory()
    setCalenderCategoryObject(initialCalenderCategoryObject)
    setCalenderCategoryErrorObject(initialCalenderCategoryErrorObject)
  }

  const checkNameExist = async () => {
    if (calenderCategoryObject.name == "") {
      return false
    }
    let searchResult = await checkCalenderCategoryName({ name: calenderCategoryObject.name });
    if (searchResult?.data && searchResult.data?.body?.data && searchResult.data?.body?.data?.length > 0) {
      if (searchResult.data?.body?.data[0]?.name == calenderCategoryObject.name) {
        return false
      }
      return true
    }

    return false
  }
  const isCategoryObjectValid = async () => {
    let error = 0
    let errorObject = { ...calenderCategoryErrorObject }

    if (calenderCategoryObject?.name == "") {
      error++
      errorObject = { ...errorObject, name: "Required" }
    } else {
      const nameExist = await checkNameExist()
      // const nameExist = !editScheduleMode ? await checkNameExist() : false
      if (nameExist) {
        error++
        errorObject = { ...errorObject, name: "The category name is already in use. Choose a difference name" }
      } else {
        errorObject = { ...errorObject, name: "" }
      }

    }

    if (calenderCategoryObject?.color == "") {
      error++
      errorObject = { ...errorObject, color: "Required" }
    } else {
      errorObject = { ...errorObject, color: "" }
    }

    setCalenderCategoryErrorObject({ ...calenderCategoryErrorObject, ...errorObject })

    return error > 0 ? false : true
  }

  const addCategoryHandler = async () => {
    let data = { ...calenderCategoryObject }

    // const nameExist = await checkNameExist()
    let isValid = await isCategoryObjectValid()
    if (!isValid) {
      return false
    }

    let result = await addCalenderCategory(data)

    if (result?.data && result?.data?.error == false) {
      toast.success('Category added')

      setCalenderCategoryObject(initialCalenderCategoryObject)
      handleDrawerCloseAddCategory()


      setCalenderCategoryErrorObject(initialCalenderCategoryErrorObject)
    }
  }
  return (
    <>

      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <TextField
            label="Name"
            validation
            CharAlignMid="CharAlignMid"
            placeholder="Enter name"
            value={scheduleObject?.name}
            helperText={scheduleErrorObject?.name}
            error={scheduleErrorObject?.name == "" ? false : true}
            handleChange={(val, e) => onChangeHandler(val, e, 'name')}
            count={50}
          // disabled={editScheduleMode}

          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Datefield
            label="Start Date"
            validation
            value={scheduleObject?.startDate}
            handleChange={(selectedDate) => onChangeDateHandler(selectedDate, 'startDate')}
            helperText={scheduleErrorObject?.startDate}
            errorData={scheduleErrorObject?.startDate?.length > 0 ? true : false}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Datefield
            label="End Date"
            validation
            value={scheduleObject?.endDate}
            handleChange={(selectedDate) => onChangeDateHandler(selectedDate, 'endDate')}
            helperText={scheduleErrorObject?.endDate}
            errorData={scheduleErrorObject?.endDate?.length > 0 ? true : false}
          />
        </Grid>


        <Grid item md={12} xs={12}>
          <div class="FieldLabel">Time<span>*</span> </div>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TimePickerField
                value={scheduleObject?.startTime}
                handleChange={(selectedTime) => onChangeDateHandler(selectedTime, 'startTime')}
                helperText={scheduleErrorObject?.startTime}
                // error={scheduleErrorObject?.startTime == "" ? false : true} 
                errorData={scheduleErrorObject?.startTime?.length > 0 ? true : false}
              />

              {/* {scheduleErrorObject?.startTime != "" && <p style={{ color: 'red' }}>{scheduleErrorObject?.startTime}</p>} */}
            </Grid>

            <Grid item md={6} xs={12}>
              <TimePickerField
                value={scheduleObject?.endTime}
                handleChange={(selectedTime) => onChangeDateHandler(selectedTime, 'endTime')}
                helperText={scheduleErrorObject?.endTime}
                // error={scheduleErrorObject?.endTime == "" ? false : true}
                errorData={scheduleErrorObject?.endTime?.length > 0 ? true : false}
              />

              {/* {scheduleErrorObject?.endTime != "" && <p style={{ color: 'red' }}>{scheduleErrorObject?.endTime}</p>} */}
            </Grid>

          </Grid>
        </Grid>



        <Grid item md={12} xs={12}>
          <SelectFieldMulticolor
            labelTop={true}
            label="Select Category"
            validation
            statustData={categoryStatusData}
            defaultValue={scheduleObject?.category}
            handleChange={(e) => onChangeHandler(e.target.value, e, 'category')}
            height="52px"
            width="auto"
            Status={true}
            textcolor="#001233"
            AddMoreButton={true}
            AddNewLabel=" Add new category"
            handleDrawerOpen={(e) => handleDrawerOpen()}
          // AddNewlink="/school-calendar-categories"

          />
          {scheduleErrorObject?.category !== "" && <p style={{ color: '#d53b3c', fontSize: '12px' }}>{scheduleErrorObject?.category}</p>}
        </Grid>
        {/* AddCalendars Drawer */}
        <SideDrawer
          open={openAddCategory}
          handleDrawerClose={(e) => CloseAddDrawerHandler()}
          title={"Add Category"}
          ButtonLabel={"Add now"}
          sideOpen="right"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={true}
          clickSaveButton={(e) => addCategoryHandler()}

          body={
            <>
              <AddCategory
                editId=""
                userTypes={userTypes}
                calenderCategoryObject={calenderCategoryObject}
                setCalenderCategoryObject={setCalenderCategoryObject}
                calenderCategoryErrorObject={calenderCategoryErrorObject}
                setCalenderCategoryErrorObject={setCalenderCategoryErrorObject}
                calenderCategories={calenderCategories} />
            </>
          }
        />

      </Grid >

    </>
  );
};

export default AddSchedule;
