import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TextField from "../../../components/Form/TextField/TextField";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { RxPerson } from 'react-icons/rx';

import SelectIndividuals from './SelectIndividuals';
import SelectGroups from './SelectGroups';

import Buttons from '../../../components/Form/Button/Button';
import Email from './Email';
import Sms from './Sms';
import Voice from './Voice';
import AddCategories from './AddCategories';
import { useGetMessageByIdQuery, useGetMessageCategoriesQuery } from '../../../redux/features/messages/meassageApi';
import useProtected from '../../../hooks/useProtected';
import { useGetEmailSendersQuery } from '../../../redux/features/messages/emailFromApi';
import { Backdrop, CircularProgress } from '@mui/material';
import { RiGroupLine } from "react-icons/ri";
import { toast } from 'react-toastify';


const NewMessage = (props) => {
  const {
    messageObject,
    setMessageObject,
    messageCategoryOptions,
    completeBasicInfoHandler,
    basicInfoSectionExpanded,
    setBasicInfoSectionExpanded,
    basicMessageSectionExpanded,
    setBasicMessageSectionExpanded,
    errors,
    setErrors,
    sendMessageHandler,
    editId,
    setEmailSelected,
    emailSelected,
    messageDetails,
    addMessageOptions,
    updateMessageOptions
  } = props

  const {
    data: emailSendersData,
    isError: emailSendersIsError,
    isLoading: emailSendersIsLoading,
    error: emailSendersError
  } = useGetEmailSendersQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  const {
    data: messageData,
    isLoading: messagelsLoading,
    isError: messageIsError,
    error: messageError
  } = useGetMessageByIdQuery(editId, {
    skip: !editId,
    refetchOnMountOrArgChange: true,
  });

  useProtected(messageError)
  useProtected(emailSendersError)





  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };

  const [selectedUsers, setSelectedUsers] = useState([])

  const [addedUsersToUpdate, setAddedUsersToUpdate] = useState([])
  const [addedUsers, setAddedUsers] = useState([])
  const [addedGroupsToUpdate, setAddedGroupsToUpdate] = useState([])
  const [addedGroups, setAddedGroups] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [emailSenders, setEmailSenders] = useState([])
  const [emailSenderOptions, setEmailSendersOptions] = useState([])
  const [name, setName] = useState("");

  //== Recipientse Drawer == //
  const [openRecipientseDrawer, setRecipientse] = useState(false);
  const handleOpenRecipients = () => setRecipientse(true);
  const handleCloseRecipients = () => {

    setAddedUsersToUpdate([])
    setAddedUsers([])
    setAllUsers([])
    setAddedGroupsToUpdate([])
    setAddedGroups([])
    setMessageObject({ ...messageObject, to: [], toGroups: [] })
    setRecipientse(false);
  }

  //== Email Drawer == //
  const [openEmail, setEmail] = useState(false);
  const handleOpenEmail = () => setEmail(true);
  const handleCloseEmail = () => {
    setMessageObject({ ...messageObject, messageEmailFrom: '', messageEmailSubject: '', messageEmailContent: '', messageEmailAttachments: [] })
    setErrors({ ...errors, messageEmailFrom: '', messageEmailSubject: '', messageEmailContent: '', messageEmailAttachments: [] })
    setEmailSelected(false)
    setEmail(false);
  }

  //== Sms Drawer == //
  const [openSms, setSms] = useState(false);
  const handleOpenSms = () => setSms(true);
  const handleCloseSms = () => {
    setErrors({ ...errors, messageSmsContent: "" })
    setMessageObject({ ...messageObject, messageSmsContent: '' })
    setSms(false);
  }

  //== Voice Drawer == //
  const [openVoice, setVoice] = useState(false);
  const handleOpenVoice = () => setVoice(true);
  const handleCloseVoice = () => {
    setMessageObject({ ...messageObject, messageVoiceContent: '' })
    setErrors({ ...errors, messageVoiceContent: "", messageVoiceRecording: "", messageCallToRecordingContent: "", })
    setVoice(false);
  }


  useEffect(() => {
    if (!emailSendersIsError && !emailSendersIsLoading) {
      setEmailSenders(emailSendersData?.body?.data?.filter((options) => options?.fromName !== ""))
    }
  }, [emailSendersData, emailSendersIsError, emailSendersIsLoading])

  useEffect(() => {
    setEmailSendersOptions(getPreparedArray(emailSenders))
  }, [emailSenders])

  useEffect(() => {
    if ((!messagelsLoading && !messageIsError && messageData) || messageDetails) {
      let data = messageData?.body?.data || messageDetails
      setMessageObject({
        ...messageObject,
        messageName: data?.messageName,
        sender: data?.sender,
        category: data?.category,
        categoryName: data?.categoryName,
        type: data?.type,
        content: data?.content,
        status: data?.status,
        to: data?.to,
        toGroups: data?.toGroups,
        schoolId: data?.schoolId,
        createdBy: data?.createdBy,
        messageEmailFrom: data?.messageEmailFrom,
        messageEmailSubject: data?.messageEmailSubject,
        messageEmailContent: data?.messageEmailContent,
        messageEmailAttachments: data?.messageEmailAttachments,
        messageSmsFrom: data?.messageSmsFrom,
        messageSmsContent: data?.messageSmsContent,
        messageVoiceFrom: data?.messageVoiceFrom,
        messageVoiceRecording: data?.messageVoiceRecording,
        messageVoiceContent: data?.messageVoiceContent,
        messageCallToRecordingContent: data?.messageCallToRecordingContent,
        sentDate: data?.sentDate,

      })
      setAddedUsersToUpdate(data?.to)
      let onlyIdArray = []
      data?.to?.map((singleUser) => {
        onlyIdArray.push(singleUser.id)
      })
      setAddedUsers(onlyIdArray)

      setAddedGroupsToUpdate(data?.toGroups)
      let onlySelectedGroupsToUpdate = []
      data?.toGroups?.map((selectedGroupsToUpdate) => {
        onlySelectedGroupsToUpdate.push(selectedGroupsToUpdate._id)
      })
      setAddedGroups(onlySelectedGroupsToUpdate)
    }

  }, [messageData, messagelsLoading, messageIsError])



  const getPreparedArray = (data) => {
    return data?.map((item) => ({
      value: item?.fromName,
      label: item?.fromName,
    }));
  };

  const tabData = [
    {
      lebel: "Select individuals",
      icon: <RxPerson />,
      filterCountStatus: true,
      filterCountNumber: (allUsers?.length > 0 || messageData?.body?.data?.to.length > 0) ? allUsers.length : '',
    },

    {
      lebel: "Select groups",
      icon: <RiGroupLine />,
    },
  ]
  useEffect(() => {
    let allUser = [...addedUsersToUpdate]
    addedGroupsToUpdate?.map((singleGroup) => {
      if (singleGroup.users.length > 0) {
        allUser = [...allUser, ...singleGroup.users]
      }
    })
    const uniqueData = {};
    allUser.forEach(item => {
      uniqueData[item.userUniqueID] = item;
    });
    const result = Object.values(uniqueData);

    setAllUsers(result)
  }, [addedUsersToUpdate, addedGroupsToUpdate])

  useEffect(() => {
    // setMessageObject({ ...messageObject, to: allUsers })
    // setMessageObject({ ...messageObject, toUsers: addedGroupsToUpdate })

  }, [allUsers])
  const handleAddUsers = () => {
    if (allUsers?.length < 1) {
      toast.error("Please select at least one user")
      return false
    }
    setRecipientse(false);
    setMessageObject({ ...messageObject, to: allUsers, toGroups: addedGroupsToUpdate })
  }


  const panelTab = [
    {
      id: 0,
      panleBody: <SelectIndividuals
        addedUsersToUpdate={addedUsersToUpdate}
        addedUsers={addedUsers}
        messageObject={messageObject}
        setAddedUsersToUpdate={setAddedUsersToUpdate}
        setAddedUsers={setAddedUsers} />,
      SaveButtonLabel: allUsers?.length > 1 ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,
      clickSaveButton: handleAddUsers,
      CancelButtonLabel: 'Cancel',
    },

    {
      id: 1,
      panleBody: <SelectGroups
        addedGroupsToUpdate={addedGroupsToUpdate}
        addedGroups={addedGroups}
        setAddedGroupsToUpdate={setAddedGroupsToUpdate}
        setAddedGroups={setAddedGroups}
      />,
      // SaveButtonLabel: ((allUsers?.length > 0 && allUsers?.length > 1) || (messageData?.body?.data?.toGroups.length > 0 && messageData?.body?.data?.toGroups.length > 1)) ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,
      SaveButtonLabel: allUsers?.length > 1 ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,
      clickSaveButton: handleAddUsers,
      CancelButtonLabel: 'Cancel',
    },
  ];



  const backgroundInCount = {
    backgroundColor: '#f2f0f4',
  };

  const backgroundInCountBlue = {
    backgroundColor: '#0450E1',
    color: '#fff'
  }



  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };



  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = () => { setOpenCategory(true); };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };

  const onChangeHandler = (val, e, fieldName) => {
    let newObject = { ...messageObject, [fieldName]: e.target.value }
    setMessageObject(newObject)
  }
  const handleSaveSms = async () => {
    if (messageObject?.messageSmsContent === "") {
      setErrors({ ...errors, messageSmsContent: "Required" })
      setSms(true)

    } else if (messageObject?.messageSmsContent.length > 300) {
      setSms(true)
    } else {

      setErrors({ ...errors, messageSmsContent: "" })
      setSms(false)
    }


  }
  const checkEmailSection = async () => {
    let errorNumber = 0
    let errorObject = { ...errors }
    if (messageDetails?._id) {
      errorObject = { ...errorObject, messageEmailFrom: "" }
    } else {
      if (messageObject?.messageEmailFrom == "") {
        errorObject = { ...errorObject, messageEmailFrom: "Required" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, messageEmailFrom: "" }
      }
    }


    if (messageDetails?._id) {
      errorObject = { ...errorObject, messageEmailSubject: "" }
    } else {
      if (messageObject?.messageEmailSubject == "") {
        errorObject = { ...errorObject, messageEmailSubject: "Enter a subject" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, messageEmailSubject: "" }
      }
    }

    if (messageDetails?._id) {
      errorObject = { ...errorObject, messageEmailContent: "" }
    } else {
      if (messageObject?.messageEmailSubject == "") {
        errorObject = { ...errorObject, messageEmailSubject: "Enter a subject" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, messageEmailSubject: "" }
      }
    }
    if (messageDetails?._id) {
      errorObject = { ...errorObject, messageEmailContent: "" }
    } else {

      if (messageObject?.messageEmailContent == "" || (messageObject?.messageEmailContent != "" && JSON.parse(messageObject?.messageEmailContent)[0].children[0].text == "")) {
        errorObject = { ...errorObject, messageEmailContent: "Enter a message" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, messageEmailContent: "" }
      }

    }

    if (messageObject?.messageEmailAttachments?.length > 0) {

      const sizeLimitMB = 25;
      const totalSizeKB = messageObject?.messageEmailAttachments?.reduce((total, object) => total + object.size, 0);
      const totalSizeMB = totalSizeKB / 1024; // Convert total size to MB
      if (totalSizeMB > sizeLimitMB) {
        errorObject = { ...errorObject, messageAttachment: "The maximum size for total file uploads is 25.00 MB" }
        errorNumber++
      } else {
        errorObject = { ...errorObject, messageAttachment: "" }
      }
    }

    setErrors({ ...errors, ...errorObject })

    return (errorNumber > 0) ? false : true
  }
  const handleSaveEmail = async () => {
    let isValid = await checkEmailSection()
    if (!isValid) {
      return false
    }
    setEmailSelected(true)
    setEmail(false)
  }
  const handleSaveVoice = () => {

    if (messageObject?.messageVoiceContent === "" && messageObject?.messageVoiceRecording === "" && messageObject?.messageCallToRecordingContent === "") {
      setErrors({ ...errors, messageVoiceContent: "Required", messageVoiceRecording: "Required" })
      setVoice(true)
    } else if (messageObject?.messageVoiceContent?.length > 500) {
      setVoice(true)
    }
    else if (messageObject?.messageVoiceContent && messageObject?.messageVoiceRecording === "" && messageObject?.messageCallToRecordingContent === "") {
      setErrors({ ...errors, messageVoiceRecording: "Required", messageContent: "" })
      setVoice(false)
    } else if (messageObject?.messageVoiceContent === "" && messageObject?.messageVoiceRecording && messageObject?.messageCallToRecordingContent === "") {
      setErrors({ ...errors, messageVoiceContent: "Required", messageContent: "" })
      setVoice(false)
    }

    else {
      setErrors({ ...errors, messageVoiceContent: "", messageVoiceRecording: "", messageContent: "" })
      setVoice(false)
    }
    // setErrors({ ...errors, messageContent: "" })
  }

  // * ================= backdrop loader ================
  const [pageLoader, setPageLoader] = useState(false);

  // setErrors({ ...errors, messageSmsContent: "" })

  // useEffect(() => {
  //   console.log(345, messageObject)
  // }, [messageObject])
  return (
    <>


      <div className='CollapsForm'>

        {/* === Basic Info & Recipients === */}
        <Accordion expanded={basicInfoSectionExpanded} onChange={handleChange('panel1')}>

          <AccordionSummary>
            <div className='AccLabel'> Basic Info & Recipients</div>

            <div className='AccIcon'>
              {messageDetails?._id ? <><CheckCircleRoundedIcon className='activeIcon' /></> : <> <Button
                className='EditButton'
                variant="text"
                onClick={() => {
                  setBasicInfoSectionExpanded(true)
                  setBasicMessageSectionExpanded(false)
                }}>EDIT</Button>
                {basicInfoSectionExpanded ? <FiberManualRecordRoundedIcon className='defaultIcon' /> : <CheckCircleRoundedIcon className='activeIcon' />}</>}
            </div>

          </AccordionSummary>

          <AccordionDetails>

            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Message Name"
                  required={true}
                  value={messageObject.messageName}
                  handleChange={(val, e) => onChangeHandler(val, e, 'messageName')}
                  error={messageObject.messageName.length === 0 ? errors?.messageName : ''}
                  helperText={messageObject.messageName.length === 0 ? errors?.messageName : ''}
                  count={50}
                  CharAlignMid="CharAlignMid"
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <div class="FieldLabel">Select Category <span>*</span></div>
                <SelectField
                  FieldLabel={false}
                  // FieldLabel={ture}
                  name="category"
                  label="Select Category"
                  options={messageCategoryOptions}
                  AddNew={true}
                  // selectedOption={messageDetails?._id ? messageDetails?.category : messageObject?.category}
                  selectedOption={messageObject?.category}
                  helperText={messageObject.category.length === 0 ? errors?.category : ''}
                  error={messageObject.category.length === 0 ? errors?.category : ''}
                  validationError={messageObject.category.length === 0 ? errors?.category : ''}
                  handleChange={(val) => onChangeHandler(val, val, 'category')}
                  onClickAddNew={handleOpenCategory}
                />
              </Grid>
              {messageDetails?._id ? '' : <Grid item md={12} xs={12}>
                <div class="FieldLabel">To <span>*</span></div>
                <div className="AddField" onClick={() => handleOpenRecipients()} style={{ border: `${allUsers?.length === 0 && errors?.to ? "#d32f2f 1px solid" : "#e4e2e6 1px solid"}` }}>
                  <Button
                    href="#contained-buttons"
                    onClick={() => handleOpenRecipients()}
                    sx={{ textTransform: 'initial !important' }}
                  >
                    <div className="AddIcon">
                      <AddRoundedIcon />
                    </div>{' '}
                    Select recipients
                  </Button>
                  <div className="UserCount" style={allUsers?.length === 0 ? backgroundInCount : backgroundInCountBlue}>
                    {allUsers?.length}
                  </div>

                </div>
                {allUsers?.length === 0 ? <p style={{ color: '#d32f2f', fontSize: '.80rem' }}>{errors?.to}</p> : ''}
                {/* {errors?.to != "" ? <p style={{ color: '#d32f2f', fontSize: '.80rem' }}>{errors?.to}</p> : ''} */}
              </Grid>}


              <Grid item md={12} xs={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  fontWeight={700}
                  link=""
                  onClick={(e) => completeBasicInfoHandler()}
                />
              </Grid>

            </Grid>


          </AccordionDetails>

        </Accordion>


        {/* === Message content === */}
        <Accordion expanded={basicMessageSectionExpanded} onChange={handleChange('panel2')}>
          {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}> */}

          <AccordionSummary>
            <div className='AccLabel' style={{ color: basicMessageSectionExpanded ? '' : messageObject?.messageEmailContent || messageObject?.messageSmsContent || messageObject?.messageVoiceContent ? "" : '#a8a8aa' }}>Message content</div>
            <div className='AccIcon'>
              {messageDetails?._id ? <CheckCircleRoundedIcon className='activeIcon' /> : <>
                {messageObject?.messageEmailContent || messageObject?.messageSmsContent || messageObject?.messageVoiceContent ? <Button
                  className='EditButton'
                  variant="text"
                  onClick={() => {
                    setBasicInfoSectionExpanded(false)
                    setBasicMessageSectionExpanded(true)
                  }}>EDIT</Button> : <Button
                    className='EditButton'
                    variant="text"
                  >EDIT</Button>}
                {messageObject?.messageEmailContent || messageObject?.messageSmsContent || messageObject?.messageVoiceContent ? <CheckCircleRoundedIcon className='activeIcon' /> : <FiberManualRecordRoundedIcon className='defaultIcon' />}
              </>}
            </div>

          </AccordionSummary>

          <AccordionDetails>

            <Grid container spacing={1}>

              <Grid item md={12} xs={12}>
                <div className={`AddFieldCustome  ${messageObject?.messageEmailContent != "" ? "ButtonActive" : ""} ${(errors?.messageContent !== "") ? "err" : ""}`} onClick={handleOpenEmail}>
                  {/* <div className="AddFieldCustome err" onClick={handleOpenEmail}> */}
                  <div className='AddButtonMsg'>
                    <div className="AddIcon">
                      <AddRoundedIcon />
                    </div>
                    <span>Email</span>
                  </div>
                  <div className="buttonArrow">
                    <ArrowForwardIosRoundedIcon />
                  </div>
                </div>

              </Grid>

              <Grid item md={12} xs={12}>
                <div className={`AddFieldCustome ${messageObject?.messageSmsContent != "" ? "ButtonActive" : ""}   ${errors?.messageContent != "" ? "err" : ""}`} onClick={handleOpenSms}>
                  <div className='AddButtonMsg'>
                    <div className="AddIcon">
                      <AddRoundedIcon />
                    </div>
                    <span>SMS</span>
                  </div>
                  <div className="buttonArrow">
                    <ArrowForwardIosRoundedIcon />
                  </div>
                </div>
              </Grid>

              <Grid item md={12} xs={12} onClick={handleOpenVoice}>
                <div className={`AddFieldCustome ${messageObject?.messageVoiceContent != "" || messageObject?.messageVoiceRecording != '' || messageObject?.messageCallToRecordingContent != '' ? "ButtonActive" : ""} ${errors?.messageContent !== "" ? "err" : ""} `}>

                  <div className='AddButtonMsg'>
                    <div className="AddIcon">
                      <AddRoundedIcon />
                    </div>
                    <span>Voice</span>
                  </div>
                  <div className="buttonArrow">
                    <ArrowForwardIosRoundedIcon />
                  </div>
                </div>
                {(errors?.messageContent != "") && <p className="err_text">{errors?.messageContent}</p>}

              </Grid>

              <Grid item md={12} xs={12}>
                <Buttons
                  label="Send now"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="40px"
                  fontSize="14px"
                  fontWeight={700}
                  link=""
                  onClick={(e) => sendMessageHandler()}
                />
              </Grid>
            </Grid>


          </AccordionDetails>

        </Accordion>
        {/* <Button onClick={() => setPageLoader(true)}>loading</Button> */}

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={addMessageOptions?.isLoading || updateMessageOptions?.isLoading}
        // onClick={() => setPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>


      </div >


      {/* Select Recipients*/}
      <SideDrawer
        open={openRecipientseDrawer}
        handleDrawerClose={handleCloseRecipients}
        title="Select Recipients"
        sideOpen="right"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={false}
        panelTab={panelTab}
        tabData={tabData}
        TabsStatus={true}
        TabsPannelStatus={true}
        padding="0"
        BackArrow={true}
        CloseArrowHide={true}
      />



      {/* Email*/}
      <SideDrawer
        open={openEmail}
        handleDrawerClose={handleCloseEmail}
        title="Email"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={handleSaveEmail}
        BackArrow={true}
        CloseArrowHide={true}
        body={<Email
          emailSenders={emailSenders}
          setEmailSenders={setEmailSenders}
          emailSenderOptions={emailSenderOptions}
          messageObject={messageObject}
          setMessageObject={setMessageObject}
          messageDetails={messageDetails}
          errors={errors} />}
      />

      {/* SMS*/}
      <SideDrawer
        open={openSms}
        handleDrawerClose={handleCloseSms}
        title="SMS"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={() => handleSaveSms()}
        BackArrow={true}
        CloseArrowHide={true}
        body={<Sms
          messageObject={messageObject}
          setMessageObject={setMessageObject}
          messageDetails={messageDetails}
          errors={errors} />}
      />

      {/* Voice*/}
      <SideDrawer
        open={openVoice}
        handleDrawerClose={handleCloseVoice}
        title="Voice"
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={() => handleSaveVoice()}
        BackArrow={true}
        CloseArrowHide={true}
        body={<Voice
          messageObject={messageObject}
          setMessageObject={setMessageObject}
          messageDetails={messageDetails}
          errors={errors} />}
      />

      {/* Add Catgory Modal*/}
      <AddCategories
        openModal={openCategory}
        handleClose={handleCloseCategory}
        name={name}
        setName={setName}
      />


    </>
  );
};

export default NewMessage;
