import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';
import { useGetMessageTemplateListsQuery, useSearchMessageTemplatesMutation } from '../../../redux/features/messages/templatesApi';

const SelectTemplate = ({ setTempTemplate, selectUsers, handleCloseSelectUsers, selectedList, selectedListJust, renderFullItem = false }) => {
  const { data, isError, isLoading, error } = useGetMessageTemplateListsQuery(undefined, { refetchOnMountOrArgChange: true });

  useProtected(error);


  const [getSearchedList, options] = useSearchMessageTemplatesMutation();
  const [rows, setRows] = useState([]);
  const [tempArray, setTempArray] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const handleChangeRedio = (event, rowData) => {
    let data = JSON.parse(localStorage.getItem('templatesInfo'));
    let templatesInfoObject = JSON.parse(localStorage.getItem('templatesInfoObject'));
    let template = {}
    if (data.length > 0) {

      data.map((item, index) => {

        item.cell[0].selectedValue = (item.cell[0].RedioValue == event.target.value) ? true : false
        item.cell[0].handleChangeRedio = handleChangeRedio

        if (item.cell[0].RedioValue == event.target.value) {
          template = { name: item.cell[0].textBold, id: item.cell[0].RedioValue }
          let foundTemplate = templatesInfoObject.find(template => template._id === event.target.value)
          setTempTemplate(foundTemplate)
        }
      });
      setSelectedTemplate(event.target.value);
      // setTempTemplate(foundTemplate)
      setRows(data);



    }

    // props.onClickRadioIcon(event.target.value);
    //find the selected item from totalItems array
    // let selectedItem = totalItems.find(item => item.id == event.target.value);
    // props.onItemSelected( selectedItem );

  };


  useEffect(() => {
    localStorage.setItem('templatesInfo', JSON.stringify(rows));
  }, [rows])

  useEffect(() => {
    localStorage.setItem('templatesInfoObject', JSON.stringify(tempArray));
  }, [tempArray])



  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        item: item,
        cell: [
          {
            textBold: `${item.templateName}`,
            Radio: true,
            // selectedValue: selectedTemplate,
            selectedValue: false,
            handleChangeRedio: handleChangeRedio,
            RedioValue: (item._id) ? item._id : item.RedioValue,
            radioName: "template",
            // Status: true,
            // background: item.status === 'active' ? '#EDF7EA' : '#FCE7E9',
            // color: item.status === 'active' ? '#66BD50' : '#E63946',
            // label: item.status === 'active' ? 'Active' : 'Inactive',
            // user_id: item._id,
            // checked: true,
            // user_id: item._id
          },
          {
            text: item?.content ? item?.content?.slice(0, 50) : 'N/A'
          },
          { text: '' },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setTempArray(data.body.data)
    }
  }, [data, isLoading, isError]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '40%',
      textAlign: 'left',
    },
    {
      id: 'Content',
      numeric: false,
      disablePadding: true,
      label: 'Content',
      width: '60%',
      textAlign: 'left',
    },

    {
      id: '',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '0%',
      textAlign: 'left',
    },

  ];
  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedList({ term: searchTerm });
    if (result?.data) {
      setSelectedTemplate(false)
      setRows(convertDataToCellRows(result.data.body.data));

    }
    setPageNumberFromOutSide(false)
  };
  // =========== search part start ====================== //
  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          showSearchBox={true}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleCheckItem={setSelectedUsers}
          selectedCheckList={selectedListJust}
          customCellObjectId="user_id"
          searchBoxPlaceholder="Search templates"
          RowsPerPage={25}
        />
      </div>
      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } users`}
      />
    </>
  );
};

export default SelectTemplate;
