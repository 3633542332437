import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TextField from "../../../components/Form/TextField/TextField";
import Buttons from '../../../components/Form/Button/Button';
import Modals from '../../../components/Form/Modals/Modals';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import SpatialAudioOffRoundedIcon from '@mui/icons-material/SpatialAudioOffRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SelectTemplate from './SelectTemplate';
import RedioBox from '../../../components/Form/RedioBox/RedioBox';
import AddCategories from './AddCategories';
import { toast } from 'react-toastify';
import { useGetTextToVoiceMutation } from '../../../redux/features/messages/meassageApi';
import useProtected from '../../../hooks/useProtected';
import axiosApi from '../../../utils/axios';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import SideBarDrawer from '../../../components/SideBarDrawer/SideBarDrawer';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import DraggableDialog from '../../../components/Dialog/Dialog';


import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Typography } from '@mui/material';


const VoiceDuplicate = (props) => {

    //======= Listen dialog ===== //
    const [openDialogListen, setOpenDialogListen] = useState(false);
    const handleOpenListen = () => {
        setOpenDialogListen(true);
    };
    const handleCloseListen = () => {
        setOpenDialogListen(false)
    };
    //======= Listen dialog ===== // 


    const {
        messageDetails,
        messageObject,
        setMessageObject,

    } = props

    const [openTemplate, setTemplate] = useState(false);
    const handleOpenTemplate = () => setTemplate(true);
    const handleCloseTemplate = () => setTemplate(false);
    const [tempTemplate, setTempTemplate] = useState({})
    const [audioUrl, setAudioUrl] = useState(null)
    useEffect(() => {
        console.log('temporary template:', tempTemplate)
    }, [tempTemplate])



    const [value, setValue] = React.useState(0);

    const onChange = (event, newValue) => {
        setValue(newValue);
    };




    const handleCopyCode = () => {
        if (messageDetails?.messageVoiceContent) {
            navigator.clipboard.writeText(messageDetails?.messageVoiceContent)
                .then(() => {
                    toast.success('Copied');
                })
                .catch((error) => {
                    console.error('Error copying to clipboard: ', error);
                    toast.error('Failed to copy code');
                });
        } else {
            toast.warning('No code to copy');
        }
    };

    const listenTextHandler = async (e) => {
        if (!messageDetails?.messageVoiceContent) {
            toast.error("Please enter some text to listen to.")
            return
        }
        const utterance = new SpeechSynthesisUtterance(messageDetails?.messageVoiceContent)
        // utterance.voice = speechSynthesis.getVoices().find(voice => voice.name === 'Google UK English Female');
        speechSynthesis.speak(utterance)
    }

    // * ============================== Media lib ============================== 


    const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [selectedValue, setSelectedValue] = useState(false);
    const [mediaItem, setMediaItem] = useState({});



    const deleteAttachment = () => {
        let newObject = { ...messageObject, messageVoiceRecording: "" }
        setMessageObject(newObject)
        toast.success("Voice Message Deleted Successfully")
    }


    //========== call to record apis start ============//

    const handaleDownloadFile = () => {
        // Create a link element
        const link = document.createElement('a');
        link.href = messageDetails?.messageCallToRecordingContent;
        link.download = 'audio.mp3';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    //================== call to record part start end ==========================//
    const tabsData = [
        ...(messageDetails?._id && messageDetails?.messageVoiceContent
            ? [
                {
                    title: "Text-to-speech",
                },
            ]
            : []),

        ...(messageDetails?._id && messageDetails?.messageVoiceRecording
            ? [
                {
                    title: "Upload recording",

                },
            ]
            : []),
        ...(messageDetails?._id && messageDetails?.messageCallToRecordingContent
            ? [
                {
                    title: "Call me to record",
                },
            ]
            : []),
    ]

    const panelData = [
        ...(messageDetails?._id && messageDetails?.messageVoiceContent
            ? [
                {
                    id: 0,
                    panel:

                        <Grid container spacing={3}>
                            {messageDetails?._id && messageDetails?.messageVoiceContent && (
                                <Grid item md={12} xs={12}>
                                    <TextArea
                                        label={<div style={{ color: "#1B1B1F61", }}>Text-to-speech</div>}
                                        FieldLabel={true}
                                        value={messageDetails?.messageVoiceContent}
                                        disabled
                                        ButtonHeaderStatus={true}
                                        ButtonHeader={
                                            <>
                                                <Buttons
                                                    label="Listen"
                                                    buttonColor="#F8F8F8"
                                                    border="#F8F8F8 1px solid"
                                                    color="#004FE0"
                                                    width="100%"
                                                    height="32px"
                                                    fontSize="14px"
                                                    icon={<VolumeUpRoundedIcon />}
                                                    onClick={listenTextHandler}
                                                />
                                            </>
                                        }

                                        helperText={
                                            <div className='TextpastSection'>
                                                <a className={messageDetails?.messageVoiceContent || messageObject?.messageVoiceContent?.length > 0 ? `P_Active` : `P_Defaulf`} onClick={handleCopyCode}>Copy message</a>
                                            </div>
                                        }
                                    />
                                </Grid>
                            )}


                        </Grid>,
                },

            ]
            : []),

        ...(messageDetails?._id && messageDetails?.messageVoiceRecording
            ? [
                {
                    id: messageDetails?.messageVoiceRecording && !messageDetails?.messageVoiceContent ? 0 : 1,
                    // id:  ? 0 : 1,
                    panel:
                        <div>

                            <div class="FieldLabel" style={{ color: "#1B1B1F61", }}>Voice Message</div>

                            {((messageDetails?._id && messageDetails?.messageVoiceRecording) || messageObject?.messageVoiceRecording) && (<Grid item xs={12} sm={12} md={12} sx={{ py: 2 }}>
                                <div className="UploadPhotoFiled" >
                                    <div className="UploadPhotoImgCol">

                                        <div className="ImgDetails"><p>{messageObject?.messageVoiceRecording.substring(messageObject?.messageVoiceRecording.lastIndexOf("/") + 1) || messageDetails?.messageVoiceRecording.substring(messageDetails?.messageVoiceRecording.lastIndexOf("/") + 1)}  </p></div>
                                    </div>
                                    {messageObject?.messageVoiceRecording && (<a className="UploadPhotoFiledDelete" onClick={(e) => deleteAttachment()}>
                                        <DeleteForeverRoundedIcon />
                                    </a>)}

                                </div>

                            </Grid>)}
                        </div >

                },
            ]
            : []),

        ...(messageDetails?._id && messageDetails?.messageCallToRecordingContent
            ? [
                {
                    id:
                        messageDetails?.messageCallToRecordingContent &&
                            !messageDetails?.messageVoiceRecording &&
                            !messageDetails?.messageVoiceContent
                            ? 0
                            : messageDetails?.messageVoiceRecording && messageDetails?.messageCallToRecordingContent && messageDetails?.messageVoiceContent
                                ? 2
                                : 1,
                    panel:
                        <div>

                            <div className='FormFieldContainer'>
                                <div className='FloatLabelCol'>Recording<span>*</span></div>
                                <div className='FormFieldCol'>


                                    <div className='Rec_buttons'>



                                        <Buttons
                                            label="Listen"
                                            buttonColor="#F8F8F8"
                                            border="#F8F8F8 1px solid"
                                            color="#004FE0"
                                            width=""
                                            height="32px"
                                            fontSize="14px"
                                            icon={<VolumeUpRoundedIcon />}
                                            onClick={handleOpenListen}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>

                }
            ]
            : []),

    ]





    return (
        <>

            <Grid container spacing={3}>

                <Grid item md={12} xs={12}>
                    <Typography sx={{ marginBottom: '2px', fontSize: "12px", fontWeight: 600, color: "#1B1B1F61" }}>Type</Typography>
                    <RedioBox
                        tabsData={tabsData}
                        panelDate={panelData}
                        value={value}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <div>
                {audioUrl && <audio controls autoPlay src={audioUrl}>Your browser does not support the audio element.</audio>}
            </div>

            {/* Dialog Listen */}
            <DraggableDialog
                openDialog={openDialogListen}
                handleCloseDialog={handleCloseListen}
                title="Recording"
                actionButton="Record again"
                textAlign="center"
                body={
                    <div className='AudioListenContainer'>
                        {messageDetails?.messageCallToRecordingContent && <audio controls>
                            <source src={messageDetails?.messageCallToRecordingContent} type="audio/mp3" />
                        </audio>}

                        <Buttons
                            label="Download audio file"
                            buttonColor="#0450E1"
                            border="#0450E1 1px solid"
                            color="#fff"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            icon={<FileDownloadOutlinedIcon />}
                            onClick={handaleDownloadFile}
                        />
                        <Buttons
                            label="Close"
                            buttonColor="#fff"
                            border="#F8F8F8 0px solid"
                            color="#0450E1"
                            width="100%"
                            height="48px"
                            fontSize="14px"
                            onClick={handleCloseListen}
                        />
                    </div>
                }
            />
        </>
    );
};

export default VoiceDuplicate;
