import React, { useEffect, useState } from "react";
import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import Status from '../../../components/Status/Status';
import { capitalizeWords } from "../../../hooks/HelperFunctions";


const Home = ({ courseDetails, section_id }) => {
  const [singleSectionDetails, setSingleSectionDetails] = useState({});

  useEffect(() => {
    const section_idData = courseDetails?.sections?.find((dataId) => dataId?.id === section_id)
    setSingleSectionDetails(section_idData)

  }, [courseDetails])
  // console.log(singleSectionDetails, 345)
  const staticStatustData = [
    {
      Label: "Section Status",
      SubLabel: `${singleSectionDetails?.status ? capitalizeWords(singleSectionDetails?.status) : "N/A"}  `,
      Color: "#41AB37",
      line: true,
    },

    {
      Label: "Section Code",
      SubLabel: `${singleSectionDetails?.sectionCode?.codePrefix} ${singleSectionDetails?.sectionCode?.code}`,
      Color: "#7D8597",
    },

    {
      Label: "Primary Teacher",
      SubLabel: `${singleSectionDetails?.primaryTeacher?.firstName} ${singleSectionDetails?.primaryTeacher?.lastName}`,
      Color: "#7D8597",
    },
  ];

  return (

    <div>

      <Status
        MainTitle={true}
        Title="Boys"
        BackLinkLabel="Mathematics 1"
        Backlink="#"
        StaticStatus={true}
        StatusLine={false}
        BottomLine={true}
        staticStatustData={staticStatustData}

      />
      <ComingSoon />
    </div>

  );
};

export default Home;
