import React, { useState } from 'react';
import './SideBarMenu.css';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import PortalSwitcher from '../../components/PortalSwitcher/PortalSwitcher';
import { useLocation } from 'react-router-dom';
import websiteLogo from '../../assets/imgs/websiteLogo.png';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/features/authSlice';
import { AiOutlineCalendar } from 'react-icons/ai';
import { AiFillCalendar } from 'react-icons/ai';
import { AiOutlineSetting } from 'react-icons/ai';
import { RiHome4Fill } from 'react-icons/ri';
import { RiPresentationLine } from 'react-icons/ri';
import { RxPerson } from 'react-icons/rx';
import { RxHome } from 'react-icons/rx';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CustomMenuItem from './CustomMenuItem';
import { useGetSchoolQuery } from '../../redux/features/schools/schoolApi';
import useProtected from '../../hooks/useProtected';
import { useEffect } from 'react';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { isTheActionPermitted, isTheSectionPermitted } from '../../hooks/HelperFunctionsPermission';
import { UserFollow } from '../../assets/imgs/icons/UserFollow';
import { Presentation } from '../../assets/imgs/icons/Presentation';
import { FolderImage } from '../../assets/imgs/icons/folderImage';
import { MdOutlineAttachMoney } from "react-icons/md";




function SideBarMenu(props) {

  // getting data from env file
  const envType = process.env.REACT_APP_ENV




  // const schoolInformation = useSelector(state => state.auth.user);
  const { schoolId } = useSelector(state => state.auth.user);

  const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId)

  useProtected(error);

  const pathname = useLocation().pathname;
  const qafysLogo =
    'https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png';
  const dsLogoUrl = "/";
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState('home');
  const [schoolInfo, setSchoolInfo] = useState({})
  const [schoolLogoIcon, setSchoolLogoIcon] = useState('');
  const [schoolLogoIconName, setSchoolLogoIconName] = useState('');
  const [schoolLogo, setSchoolLogo] = useState('');
  const [schoolLogoName, setSchoolLogoName] = useState('');

  // useEffect(() => {
  //   setSchoolInfo(schoolInformation);
  // }, [schoolInformation])

  useEffect(() => {
    if (!isLoading && data && !error) {
      setSchoolInfo(data?.body?.data)
    }
  }, [data, error, isLoading])

  useEffect(() => {
    setSchoolLogoIcon(schoolInfo?.schoolIcon || '')
    setSchoolLogoIconName(schoolInfo?.schoolIconName || '')
    setSchoolLogo(schoolInfo?.schoolLogo || '')
    setSchoolLogoName(schoolInfo?.schoolLogoName || '')
  }, [schoolInfo])

  // const isTheSectionPermitted = (sectionName) => {
  //   let found = false
  //   rolesWithPermissions?.map(roles => {
  //     roles?.permissions?.map(singlePermission => {
  //       if (singlePermission?.name == sectionName) {
  //         found = true
  //       }
  //     })
  //   })
  //   return found
  // }

  let Attendance = ['/', '/attendance-history', '/attendance-code', '/attendance-settings'];

  let Messages = ['/messages', '/categories', '/templates', '/messages-details'];

  let Users = ['/users', '/groups', '/invite-users', '/group-details', '/roles-permissions'];

  let Academics = ['/courses', '/academic-years', '/classes-level', '/subject-area', '/calendars', '/academics-settings', '/classes-level-details', '/school-calendar'];

  let Finance = ['/dashboard', '/transactions', '/fee-and-plans',];



  return (


    <div className="LayoutMainContainer_ps">
      <div className="SideBarMenu">
        <Sidebar collapsed={props.collapsed}>


          <div className="SideBarSection">

            <div className="BrandMainLogo">
              {dsLogoUrl?.length > 0 && (
                <a
                  className="Branding"
                  href={dsLogoUrl?.length > 0 ? dsLogoUrl : '#'}
                >
                  <img src={schoolLogo != "" ? schoolLogo : qafysLogo} style={{ height: "40px", objectFit: 'contain' }} />
                </a>
              )}
            </div>

            <div className="BrandMonoLogo">
              {dsLogoUrl?.length > 0 && (
                <a
                  className="BrandingMono"
                  href={dsLogoUrl?.length > 0 ? dsLogoUrl : '#'}
                >
                  <img src={schoolLogoIcon != "" ? schoolLogoIcon : qafysLogo} style={{ width: "44px", objectFit: 'contain' }} />
                </a>
              )}
            </div>

            <PortalSwitcher />
            {/* ----------------------  ParentStudentRoutes portal ------------------------ */}
            {props.ParentStudentRoutes && (
              <Menu>
                {/* <CustomMenuItem className={`${pathname === '/listing-example' ? 'LinkActive' : 'link'}`} href="/listing-example"> <MenuBookRoundedIcon />  <span className="MenuPer">Table Example</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/listing-example">Table Example</a></div></CustomMenuItem> */}
                <CustomMenuItem
                  className={`${pathname === '/parent-portal' ? 'LinkActive' : 'link'
                    }`}
                  href="/parent-portal"
                >
                  <RiHome4Fill /> <span className="MenuPer">Home</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/users">
                      Users
                    </a>
                  </div>
                </CustomMenuItem>

                {/* <CustomMenuItem
                  className={`${pathname === '/students' ? 'LinkActive' : 'link'
                    }`}
                  href="/students"
                >
                  <RxPerson /> <span className="MenuPer">Students</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/tracks">
                      Tracks
                    </a>
                  </div>
                </CustomMenuItem>
                <CustomMenuItem
                  className={`${pathname === '/media-library-table-view'
                    ? 'LinkActive'
                    : 'link'
                    }`}
                  href="/media-library-table-view"
                >
                  <AiOutlineCalendar />{' '}
                  <span className="MenuPer">Calendar</span>{' '}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/media-library-table-view"
                    >
                      Media
                    </a>
                  </div>
                </CustomMenuItem> */}

                {/* <SubMenu className="MenuButtonPr" label={<div className="Submenue"><ForumRoundedIcon /> <span className="MenuPer">Message</span></div>}>
                                <div className="submenutitle">MESSAGES</div>
                                <CustomMenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Sent</CustomMenuItem>
                                <CustomMenuItem><FiberManualRecordRoundedIcon className="DotIcon" /> Templates </CustomMenuItem>
                            </SubMenu>  */}

                {/* <span className="divider"></span>
                <CustomMenuItem
                  className={`${pathname === '/coupons' ? 'LinkActive' : 'link'
                    }`}
                  href="/coupons"
                >
                  <InsightsRoundedIcon />{' '}
                  <span className="MenuPer">Coupons</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/coupons">
                      Coupons
                    </a>
                  </div>
                </CustomMenuItem> */}
              </Menu>
            )}
            {/* ---------------------- School portal ------------------------ */}
            {props.SchoolRoute && (
              <Menu>
                {/* <CustomMenuItem className={`${pathname === '/listing-example' ? 'LinkActive' : 'link'}`} href="/listing-example"> <MenuBookRoundedIcon />  <span className="MenuPer">Table Example</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/listing-example">Table Example</a></div></CustomMenuItem> */}

                {/* <CustomMenuItem
                  className={`${pathname === '/' ? 'LinkActive' : 'link'}`}
                  href="/"
                >
                  <RxHome /> <span className="MenuPer">HOME</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/id">
                      HOME
                    </a>
                  </div>
                </CustomMenuItem> */}
                {isTheSectionPermitted('attendance') &&
                  <div className={Attendance?.includes(pathname) ? 'LinkActives' : 'link'}>
                    <SubMenu
                      //defaultOpen={pathname.includes('/') || pathname.includes('/attendance-history') || pathname.includes('/attendance-code') || pathname.includes('/attendance-settings')}
                      className=""
                      label={<div className="Submenue"><UserFollow /> <span className="MenuPer">Attendance</span></div>}
                    >
                      <div className="submenutitle">Attendance</div>

                      <CustomMenuItem className={`${pathname === '/' ? 'LinkActive' : 'link'}`}
                        href="/"><FiberManualRecordRoundedIcon className="DotIcon" /> Main Attendance</CustomMenuItem>

                      <CustomMenuItem className={`${pathname === '/attendance-history' ? 'LinkActive' : 'link'}`}
                        href="/attendance-history"><FiberManualRecordRoundedIcon className="DotIcon" /> Attendance History</CustomMenuItem>

                      <CustomMenuItem className={`${pathname === '/attendance-code' ? 'LinkActive' : 'link'}`}
                        href="/attendance-code"><FiberManualRecordRoundedIcon className="DotIcon" /> Attendance Code </CustomMenuItem>

                      <CustomMenuItem className={`${pathname === '/attendance-settings' ? 'LinkActive' : 'link'}`}
                        href="/attendance-settings"><FiberManualRecordRoundedIcon className="DotIcon" /> Attendance Settings </CustomMenuItem>
                    </SubMenu>
                  </div>
                }




                {isTheSectionPermitted('messages') &&
                  // <div className={Messages?.includes(pathname?.match(/^\/([^\/]+)/)[0]) ? 'LinkActives' : 'link'}>
                  <div className={Messages?.includes(pathname === '/' ? '/' : pathname?.match(/^\/([^\/]+)/)[0]) ? 'LinkActives' : 'link'}>
                    <SubMenu className="" label={<div className="Submenue MessageIcon">

                      <TextsmsOutlinedIcon /> <span className="MenuPer">Message</span></div>

                    }>
                      <div className="submenutitle">MESSAGES</div>

                      <CustomMenuItem className={`${pathname === '/messages' ? 'LinkActive' : 'link'}`}
                        href="/messages"><FiberManualRecordRoundedIcon className="DotIcon" />Sent</CustomMenuItem>

                      <CustomMenuItem className={`${pathname === '/templates' ? 'LinkActive' : 'link'}`}
                        href="/templates"><FiberManualRecordRoundedIcon className="DotIcon" />Templates</CustomMenuItem>

                      <CustomMenuItem className={`${pathname === '/categories' ? 'LinkActive' : 'link'}`}
                        href="/categories"><FiberManualRecordRoundedIcon className="DotIcon" />Categories</CustomMenuItem>

                    </SubMenu>
                  </div>}




                {/* users */}
                {isTheSectionPermitted('users') &&
                  <div className={Users?.includes(pathname === '/' ? '/' : pathname?.match(/^\/([^\/]+)/)[0]) ? 'LinkActives' : 'link'}>
                    <SubMenu
                      defaultOpen={pathname.includes('/users') || pathname.includes('/groups') || pathname.includes('/invite-users') || pathname.includes('/group-details')}
                      className=""
                      label={<div className="Submenue"><RxPerson /> <span className="MenuPer">Users</span></div>}
                    >
                      <div className="submenutitle">Users</div>
                      {isTheActionPermitted('manageUsers') &&
                        <CustomMenuItem className={`${pathname.includes('/users') ? 'LinkActive' : 'link'}`}
                          href="/users"><FiberManualRecordRoundedIcon className="DotIcon" /> User List</CustomMenuItem>
                      }
                      {isTheActionPermitted('manageUserGroups') &&
                        <CustomMenuItem className={`${(pathname.includes('/group-details') || pathname.includes('/groups')) ? 'LinkActive' : 'link'}`}
                          href="/groups"><FiberManualRecordRoundedIcon className="DotIcon" /> User Groups</CustomMenuItem>
                      }

                      {isTheActionPermitted('inviteUsers') ? <CustomMenuItem className={`${pathname.includes('/invite-users') ? 'LinkActive' : 'link'}`} href="/invite-users">
                        <FiberManualRecordRoundedIcon className="DotIcon" />Invite Users</CustomMenuItem> : ""}

                      {isTheActionPermitted('manageRolesPermissions') ? <CustomMenuItem className={`${pathname.includes('/roles-permissions') ? 'LinkActive' : 'link'}`} href="/roles-permissions">
                        <FiberManualRecordRoundedIcon className="DotIcon" />Roles & Permissions</CustomMenuItem> : ""}

                      {/* <CustomMenuItem className={`${pathname.includes('/roles-permissions') ? 'LinkActive' : 'link'}`}
                    href="/roles-permissions"><FiberManualRecordRoundedIcon className="DotIcon" /> Roles & Permissions </CustomMenuItem> */}
                      {
                        envType !== "prod" && (
                          <>
                            {/* <CustomMenuItem className={`${pathname === '/application-listing' ? 'LinkActive' : 'link'}`}
                            href="/application-listing"><FiberManualRecordRoundedIcon className="DotIcon" />Applications</CustomMenuItem> */}
                          </>

                        )
                      }
                    </SubMenu>
                  </div>
                }


                {/* <CustomMenuItem
                  className={`${pathname === '/id' ? 'LinkActive' : 'link'}`}
                  href="/id"
                >
                  <BadgeOutlinedIcon /> <span className="MenuPer">ID</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/id">
                      ID
                    </a>
                  </div>
                </CustomMenuItem> */}


                {isTheSectionPermitted('academics') &&
                  <div className={Academics?.includes(pathname === '/' ? '/' : pathname?.match(/^\/([^\/]+)/)[0]) ? 'LinkActives' : 'link'}>
                    <SubMenu
                      defaultOpen={pathname.includes('/courses') || pathname.includes('/academic-years') || pathname.includes('/classes-level') || pathname.includes('/subject-area')}
                      className=""
                      label={<div className="Submenue"><Presentation /> <span className="MenuPer">Academics</span></div>}
                    >
                      <div className="submenutitle">Academics</div>

                      {isTheActionPermitted('manageCourses') ? <CustomMenuItem className={`${pathname === '/courses' ? 'LinkActive' : 'link'}`}
                        href="/courses"><FiberManualRecordRoundedIcon className="DotIcon" /> Courses</CustomMenuItem> : ""}


                      {isTheActionPermitted('manageAcademicYears') &&
                        <CustomMenuItem className={`${pathname === '/academic-years' ? 'LinkActive' : 'link'}`}
                          href="/academic-years"><FiberManualRecordRoundedIcon className="DotIcon" /> Academic Years</CustomMenuItem>}

                      {isTheActionPermitted('manageClassLevel') &&
                        <CustomMenuItem className={`${pathname === '/classes-level' || pathname.startsWith('/classes-level-details') ? 'LinkActive' : 'link'}`}
                          href="/classes-level"><FiberManualRecordRoundedIcon className="DotIcon" /> Class/Level </CustomMenuItem>}

                      {isTheActionPermitted('manageSubjectArea') &&
                        <CustomMenuItem className={`${pathname === '/subject-area' ? 'LinkActive' : 'link'}`}
                          href="/subject-area"><FiberManualRecordRoundedIcon className="DotIcon" /> Subject Area </CustomMenuItem>}

                      {isTheActionPermitted('manageSubjectArea') &&
                        <CustomMenuItem className={`${pathname === '/calendars' ? 'LinkActive' : 'link'}`}
                          href="/calendars"><FiberManualRecordRoundedIcon className="DotIcon" /> Calendars </CustomMenuItem>}

                      {isTheActionPermitted('manageSubjectArea') &&
                        <CustomMenuItem className={`${pathname === '/academics-settings' ? 'LinkActive' : 'link'}`}
                          href="/academics-settings"><FiberManualRecordRoundedIcon className="DotIcon" /> Settings </CustomMenuItem>}

                    </SubMenu>
                  </div>
                }


                {/* <CustomMenuItem
                  className={`${pathname === '/order-id' ? 'LinkActive' : 'link'
                    }`}
                  href="/order-id"
                >
                  <AiFillCalendar /> <span className="MenuPer">Order ID</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/order-id">
                      Order ID
                    </a>
                  </div>
                </CustomMenuItem> */}
                <CustomMenuItem
                  className={`${pathname === '/media-library-table-view' ? 'LinkActive' : 'link'
                    }`}
                  href="/media-library-table-view"
                >
                  {/* <PermMediaOutlinedIcon />  */}
                  <FolderImage className={`${pathname === '/media-library-table-view' ? 'colorWhite' : ''
                    }`} />
                  <span className="MenuPer">Media</span>{' '}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/media-library-table-view">
                      Media
                    </a>
                  </div>
                </CustomMenuItem>


                {/* Finance */}
                <div className={Finance?.includes(pathname === '/' ? '/' : pathname?.match(/^\/([^\/]+)/)[0]) ? 'LinkActives' : 'link'}>
                  <SubMenu
                    defaultOpen={pathname.includes('/dashboard') || pathname.includes('/transactions') || pathname.includes('/fee-and-plans')}
                    className=""
                    label={<div className="Submenue"><MdOutlineAttachMoney /> <span className="MenuPer">Finance</span></div>}
                  >
                    <div className="submenutitle">Finance</div>
                    {isTheActionPermitted('manageUsers') &&
                      <CustomMenuItem className={`${pathname.includes('/dashboard') ? 'LinkActive' : 'link'}`}
                        href="/dashboard"><FiberManualRecordRoundedIcon className="DotIcon" /> Dashboard</CustomMenuItem>
                    }

                    {isTheActionPermitted('inviteUsers') ? <CustomMenuItem className={`${pathname.includes('/transactions') ? 'LinkActive' : 'link'}`} href="/transactions">
                      <FiberManualRecordRoundedIcon className="DotIcon" />Transactions</CustomMenuItem> : ""
                    }


                    {isTheActionPermitted('inviteUsers') ? <CustomMenuItem className={`${pathname.includes('/fee-and-plans') ? 'LinkActive' : 'link'}`} href="/fee-and-plans">
                      <FiberManualRecordRoundedIcon className="DotIcon" />Fees & Plans</CustomMenuItem> : ""
                    }


                  </SubMenu>
                </div>
                {/* Finance end*/}


                {isTheSectionPermitted('settings') &&
                  <CustomMenuItem
                    className={`${pathname === '/settings'
                      ? 'LinkActive'
                      : 'link'
                      }`}
                    href="/settings"
                  >
                    <AiOutlineSetting /> <span className="MenuPer">Settings</span>{' '}
                    <div className="MenuTooltip">
                      <a
                        className="MenuTooltipLink"
                        href="/settings"
                      >
                        Settings
                      </a>
                    </div>
                  </CustomMenuItem>

                }

                <CustomMenuItem
                  className={`${pathname === '/profile'
                    ? 'LinkActive'
                    : 'link'
                    }`}
                  href="/profile"
                >
                  <AccountCircleOutlinedIcon className="ProfileIcon" /> <span className="MenuPer">Profile</span>{' '}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/settings"
                    >
                      Profile
                    </a>
                  </div>
                </CustomMenuItem>

                {/* <CustomMenuItem
                  onClick={() => dispatch(logout())}
                >
                  <LogoutIcon />{' '}
                  <span className="MenuPer">Logout</span>{' '}
                  <div className="MenuTooltip">
                    <span className="MenuTooltipLink">
                      Log Out
                    </span>
                  </div>
                </CustomMenuItem> */}



                {/* <span className="divider"></span>
                            <CustomMenuItem className={`${pathname === '/coupons' ? 'LinkActive' : 'link'}`} href="/coupons"><InsightsRoundedIcon /> <span className="MenuPer">Coupons</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/coupons">Coupons</a></div></CustomMenuItem> */}
              </Menu>
            )}

            <div className='poweredByContainer'>
              <p>Powered by</p>
              {/* <img src={schoolLogo != "" ? schoolLogo : qafysLogo} style={{ height: "40px", objectFit: 'contain' }} /> */}
              <img src="https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png" alt='logo' />
            </div>
          </div>


          {/* LAYOUT RIGHT BODY START */}
          <div className='LayoutRightContentSection_pp'>
            {props.LayoutRightSection}
          </div>
          {/* LAYOUT RIGHT BODY END */}
        </Sidebar>
      </div>
    </div>


  );
}

export default SideBarMenu;
