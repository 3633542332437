import { api } from '../../api/apiSlice';

const courseStudentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        addCourseToStudents: builder.mutation({
            query: ({ courseId, sectionId, data }) => ({
                url: `/courses/addstudenttosection/${courseId}/${sectionId}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Courses', 'Course'],
        }),

    }),
});

export const {
    useAddCourseToStudentsMutation
} = courseStudentApi;
