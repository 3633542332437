import React, { useEffect, useState } from "react";
import VerifiedUser from '@mui/icons-material/Verified';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import { countries } from "../../../../assets/data/countries";
import moment from "moment-timezone";
import { useUpdateSettingsMutation } from "../../../../redux/features/users/userApi";
import useProtected from "../../../../hooks/useProtected";
import { toast } from "react-toastify";
import { filterTruthyValues, mapMonth } from "../../../../utils/helpers";
import SimpleBackdrop from "../../../../components/Form/Backdrop/SimpleBackdrop";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../redux/features/authSlice";
import dayjs from "dayjs";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#F8F8F8",
        },
    },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const
    Profile = (props) => {
        const dispatch = useDispatch();
        const [updateSettings, updateSettingsOptions] = useUpdateSettingsMutation();
        useProtected(updateSettingsOptions.error);

        const { editProfile, setEditProfile, personalInfo, setPersonalInfo, refetchProfile, formData, setFormData } = props;
        // const [formData, setFormData] = useState(null)
        const [openSideDrawer, setOpenSideDrawer] = useState(false);

        const drawerClose = () => {
            setEditProfile(false)
            setOpenSideDrawer(false)
        }
        const [manualAddress, setManualAddress] = useState(false)
        const handleUpdateProfile = async (profileInfo) => {

            const data = {
                firstName: profileInfo?.firstName,
                middleName: profileInfo?.middleName,
                lastName: profileInfo?.lastName,
                gender: profileInfo?.gender,
                profilePic: profileInfo?.profilePic,
                country: profileInfo?.country,
                address: profileInfo?.address,
                addressLine2: profileInfo?.addressLine2,
                city: profileInfo?.city,
                state: profileInfo?.state,
                zip: profileInfo?.zip,
                streetAddress: profileInfo?.streetAddress,
                birthDate: (profileInfo?.birthMonth && profileInfo?.birthYear && profileInfo?.birthDate) ? new Date(profileInfo?.birthYear, typeof profileInfo?.birthMonth === 'string' ? mapMonth(profileInfo?.birthMonth) : profileInfo?.birthMonth, profileInfo?.birthDay) : profileInfo?.birthDate,

            }
            const result = await updateSettings((data));

            if (!result?.data?.error) {
                drawerClose();
                // setFormData(personalInfo);
                const response = await refetchProfile();
                if (!response?.data?.error) {
                    const data = {
                        ...response.data.body.data,
                        birthMonth: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).month() : "",
                        birthYear: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).year() : "",
                        birthDay: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).date() : ""
                    }
                    setFormData(data);
                    dispatch(setUserData({ ...data }));
                }
            } else {
                toast.error(result.data.message)
            }
        };

        useEffect(() => {

            const data = {
                ...personalInfo,
                birthMonth: personalInfo?.birthDate ? (moment(personalInfo.birthDate).month() + 1) : "",
                birthYear: personalInfo?.birthDate ? moment(personalInfo.birthDate).year() : "",
                birthDay: personalInfo?.birthDate ? moment(personalInfo.birthDate).date() : ""
            }
            setFormData(data)
        }, [personalInfo])

        return (
            <>
                <div className="TableContainer oddEvenTable" style={{ border: '1px solid #E8EAED' }}>
                    <SimpleBackdrop open={updateSettingsOptions.isLoading} />
                    <div className="TableInnerHeader" style={{ padding: '0 0.8rem', margin: '14px 0' }}>
                        <h3>Profile</h3>
                    </div>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableBody>

                                {/* ================= ID name ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">ID</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        <span>{personalInfo?.userUniqueID || ""}</span>
                                        {/* <a href="#" className="addText">Add Name</a> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= full name ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">Full name</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        <span>{personalInfo?.firstName || ""} {personalInfo?.middleName || ""} {personalInfo?.lastName || ""}</span>
                                        {/* <a href="#" className="addText">Add Name</a> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= gender type ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">Gender</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        {/* <span>Male</span> */}
                                        {
                                            personalInfo?.gender ? <span>{personalInfo?.gender}</span> : <a className="addText" onClick={() => setOpenSideDrawer(true)} >Add gender</a>
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= birthday ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">DOB</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        {
                                            personalInfo?.birthDate ? <span>{dayjs(personalInfo?.birthDate).format('DD-MMM-YYYY')}</span> : <a className="addText" onClick={() => setOpenSideDrawer(true)} >Add birthDate</a>
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= country ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">Country</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        {
                                            personalInfo?.country ? <span>{countries.find(item => item.value === personalInfo?.country).label}</span> : <a className="addText" onClick={() => setOpenSideDrawer(true)} >Add country</a>
                                        }
                                        {/* <a href="#" className="addText" onClick={() => setOpenSideDrawer(true)}>Add Country</a> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= address ================= */}
                                <StyledTableRow >
                                    <StyledTableCell align="left" className="TableLabel">Address</StyledTableCell>
                                    <StyledTableCell align="left" className="TableInfo">
                                        {
                                            personalInfo?.addressLine1 ? `${personalInfo?.addressLine1} ${personalInfo?.city}, ${personalInfo?.state} ${personalInfo?.zip}` :
                                                personalInfo?.address ? <span>{personalInfo?.address?.label}</span> : <a className="addText" onClick={() => setOpenSideDrawer(true)} >Add address</a>
                                        }
                                        {/* <a href="#" className="addText" onClick={() => setOpenSideDrawer(true)}>Add address</a> */}
                                    </StyledTableCell>
                                </StyledTableRow>
                                {/* ================= Whats App ================= */}




                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <SideDrawer
                    title="Edit Profile"
                    cancelButtonLabel="Cancel"
                    deleteText="Delete Permanently"
                    sideOpen="right"
                    open={openSideDrawer || editProfile}
                    handleDrawerClose={drawerClose}
                    ButtonLeft="Upload New"
                    FooterWithoutTab={true}
                    ButtonLabel="Save"
                    clickSaveButton={() => handleUpdateProfile(formData)}
                    body={
                        <div>
                            <UpdatePersonalInfo personalInfo={formData} setPersonalInfo={setFormData}
                                manualAddress={manualAddress} setManualAddress={setManualAddress}
                            />
                        </div>
                    }
                />
            </>
        )
    }

export default Profile;