import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthData, getUserData } from '../components/hooks/HelperFunctions';
import Header from '../components/Header/Header';
import SideBarDrawer from '../components/SideBarDrawer/SideBarDrawer';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import RunningCourseAlertMessage from '../components/Form/AlrtMessage/RunningCourseAlertMessage';

import Sidebar from '../components/sidebar/Sidebar';
import { useSelector } from "react-redux";

const SchoolRoute = ({ collapsed, children, }) => {

    const user = useSelector((state) => state.auth.user);
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();
    // const userAuthenticated = getUserAuthData();
    // retrive user data
    // const userData = getUserData();
    // assign user role
    const userType = user?.userType ? user.userType : "";
    if (!user) {
        return <Navigate to="/signin" state={{ from: location.pathname }} replace />
    } else if (!((userType === 'staff') || (userType === 'owner') || (userType === 'faculty'))) {
        return <Navigate to="/parent-portal" state={{ from: location.pathname }} replace />
    }



    return (
        <>
            <div className="DesktopView">
                <Header

                    AlertBody={
                        <RunningCourseAlertMessage portal="org" />
                    }


                />


                <Sidebar
                    collapsed={collapsed}
                    SchoolRoute={true}
                    LayoutRightSection={
                        <div className="app-body"> {children} </div>
                    }
                />
            </div>

            <div className="ResponsiveMenu">
                <Header
                    AlertBody={
                        <RunningCourseAlertMessage portal="org" />
                    }
                    IconMenu={<a onClick={handleDrawerOpen}><MenuRoundedIcon /></a>}
                />


                <SideBarDrawer
                    open={open}
                    handleDrawerClose={handleDrawerClose}
                    DrawerBody={
                        <Sidebar SchoolRoute={true} />
                    }
                />

                <div className="app-body-Responsive"> {children} </div>


            </div>

        </>
    );
}

export default SchoolRoute;