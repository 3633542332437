import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Divider, Typography } from '@mui/material';
import stripeLogo from "../../../../assets/imgs/Stripe-Logo-Deenschool.png"
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import "./Payment.css"
import { useSelector } from 'react-redux';
import { useCreateAccountLinkMutation, useGetSchoolQuery } from '../../../../redux/features/schools/schoolApi';
import useProtected from '../../../../hooks/useProtected';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const Payment = () => {


    const { schoolId } = useSelector(state => state.auth.user);
    const { data, isLoading, error } = useGetSchoolQuery(schoolId)
    const [createAccountLink, createAccountLinkOptions] = useCreateAccountLinkMutation()
    useProtected(createAccountLinkOptions.error);
    useProtected(error);

    const [stripeOnboardingStatus, setStripeOnboardingStatus] = useState(false);
    useEffect(() => {
        if (!isLoading && data && !error) {
            if (data?.body?.data?.stripeAccountId) {
                setStripeOnboardingStatus(true)
            }
        }
    }, [data, error, isLoading])


    // const [stripeOnboardingStatus, setStripeOnboardingStatus] = useState(schoolInfo?.stripeOnboardingStatus);

    const handleStripeConnect = async () => {
        const result = await createAccountLink()
        if (result?.data?.statusCode === 444) {
            // const url = result?.data?.body?.data?.url
            // window.location.href = url
            setStripeOnboardingStatus(true)
        } else {
            toast.error("An error occurred while connecting to Stripe. Please try again.")
        }
    }


    return (
        <TableContainer component={Paper} sx={{
            "&.MuiTableContainer-root": {
                boxShadow: 'none',
                border: '1px solid #E4E2E6',
                borderRadius: '12px'
            }
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='h4' sx={{ fontSize: '22px', fontWeight: '600' }}>Setup Payments</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <div className={`stripConnectionLabel  ${stripeOnboardingStatus ? "connected" : "dicConnected"}`} >
                                <span>●</span>
                                {stripeOnboardingStatus ? "Connected" : "Disconnected"}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" colSpan={2}>
                            <div className='stripePaymentRowContainer'>
                                <div className='stripeImageContainer'>
                                    <img src={stripeLogo} alt="stripeLogo" />
                                </div>
                                <p>
                                    <span>Stripe</span> is our secured online payment processing partner;
                                    trusted by millions of companies around the world.
                                </p>
                                <div>
                                    <Button
                                        onClick={handleStripeConnect}
                                        startIcon={<span className='stripeS' style={{ color: stripeOnboardingStatus ? '#646FDE' : '#fff' }}>S</span>}
                                        variant='contained'
                                        disabled={stripeOnboardingStatus}
                                        sx={{
                                            padding: '10px 20px !important',
                                            background: '#646FDE ',
                                            fontWeight: '700 !important',
                                            textTransform: 'initial !important',
                                            boxShadow: 'none !important',
                                            borderRadius: '8px !important',
                                            "&:hover": {
                                                background: '#646FDE !important',
                                                boxShadow: 'none !important',
                                            },
                                            "&.Mui-disabled": {
                                                color: "#41AB37 !important"
                                            }
                                        }}
                                    >
                                        <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '4px 4px 4px 2px', background: '#002855' }} />
                                        {
                                            stripeOnboardingStatus && <DoneRoundedIcon />
                                        }
                                        {
                                            stripeOnboardingStatus ?
                                                'Connected!' :
                                                "Connect with Stripe"
                                        }
                                    </Button>
                                    {
                                        stripeOnboardingStatus &&
                                        <p className='contact-us-text'>Please <a href="https://deenschool.io/contact-us" target='_blank' rel="noreferrer">contact us</a> to change account</p>
                                    }
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default Payment