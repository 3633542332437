import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import { Button } from '@mui/material';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import tableImg from '../../../assets/imgs/tableImg.png';
import CustomAccordion from '../../../components/CustomAccordion/CustomAccordion';
import CreateIdDrawer from '../../../components/CreateIdDrawer/CreateIdDrawer';
import OrderIdDrawer from '../../../components/OrderIdDrawer/OrderIdDrawer';
import useProtected from '../../../hooks/useProtected';
import {
  useCreateOrderIdMutation,
  useGetOrderIdListQuery,
  useGetOrderIdSearchResultMutation,
} from '../../../redux/features/order/orderApi';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import PaymentModal from '../../../components/PaymentModal/PaymentModal';

const OrderId = () => {
  const { data, isError, isLoading, error } = useGetOrderIdListQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );
  useProtected(error);

  const [getOrderIdSearchResult, options] = useGetOrderIdSearchResultMutation();
  useProtected(options.error);

  const [createOrderId, createOrderIdOptions] = useCreateOrderIdMutation();
  useProtected(createOrderIdOptions.error);

  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const [createIdDrawer, setCreateIdDrawer] = useState(false);
  const handleOpenDrawer = () => setCreateIdDrawer(true);
  const handleCloseDrawer = () => setCreateIdDrawer(false);
  // Filter //
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setOpenFilter(false);
  };

  const onClick = () => {
    alert('onclick working fine!');
  };

  // ------------------- payment modal start  -------------------------
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModal2, setPaymentModal2] = useState(false);

  // ------------------- payment modal end -------------------------

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Order # ',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Order Name',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'QTY ',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Order date',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '10%',
      textAlign: 'left',
    },
  ];

  //    * ------------------------------- table rows ---------------------------------

  const renderStatusColor = (status) => {
    switch (status) {
      case 'draft':
        return {
          background: '#EEECFF',
          color: '#7266FB',
        };
      case 'shipped':
        return {
          background: '#E1F1FE',
          color: '#0590FA',
        };
      case 'cancelled':
        return {
          background: '#FCE7E9',
          color: '#E63946',
        };
      case 'pending':
        return {
          background: '#FFF5E6',
          color: '#FFAE35',
        };
      case 'delivered':
        return {
          background: '#EDF7EA',
          color: '#66BD50',
        };
      case 'refunded':
        return {
          background: '#E1EAFB',
          color: '#0450E1',
        };
      case 'partiallyRefunded':
        return {
          background: '#EDEDEF',
          color: '#676C7B',
        };
      case 'paid':
        return {
          background: '#EDF7EA',
          color: '#66BD90',
        };
      default:
        return {};
    }
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            linkText: item?.orderId || 'N/A',
            chip: 'PRIMARY',
            chipStatus: false,
            linkTextStatus: true,
            link: '#',
          },
          { textBold: item?.orderName || 'N/A', align: 'left' },
          { textBold: item?.quantity || 'N/A', align: 'left' },
          {
            textBold: item?.createdAt
              ? moment(item.createdAt).format('MMM DD, YYYY')
              : 'N/A',
            align: 'left',
          },
          {
            Status: true,
            background: renderStatusColor(item.status).background,
            color: renderStatusColor(item.status).color,
            label: item?.status.toUpperCase() || 'N/A',
          },
          {
            Button: true,
            buttonLabel: item?.status === 'paid' ? 'Details' : 'Continue',
            buttonColor: '#FFFFFF',
            buttonBorder: '#E4E8EC 1px solid',
            buttonTextcolor: '#004FE0',
            buttonLink: '#',
            onClick: () => {
              setSelectedOrderId(item._id);
              setSelectedId(item.orderId);
              setCreateIdDrawer(true);
            },
          },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isError, isLoading]);

  // const rows = [
  //   {
  //     cell: [
  //       {
  //         linkText: 'Sep 2023 all grades ',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#EEECFF',
  //         color: '#7266FB',
  //         label: 'Draft',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Sep 2023 staffs',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       { Status: true, background: '#E1EAFB', color: '#0450E1', label: 'New' },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Lost ID - Nov 2022',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#FFF5E6',
  //         color: '#FFAE35',
  //         label: 'In progress',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Sep 2023 all grades ',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#EDEDEF',
  //         color: '#676C7B',
  //         label: 'On hold',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Sep 2023 staffs',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#E1F1FE',
  //         color: '#0590FA',
  //         label: 'Shipped',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Lost ID - Nov 2022',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#EDF7EA',
  //         color: '#66BD50',
  //         label: 'Completed',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         linkText: 'Sep 2023 staffs',
  //         chip: 'PRIMARY',
  //         chipStatus: false,
  //         linkTextStatus: true,
  //         link: '#',
  //       },
  //       { textBold: '123456789', align: 'left' },
  //       { textBold: 'Male ', align: 'left' },
  //       { textBold: 'Jun 15, 2006', align: 'left' },
  //       {
  //         Status: true,
  //         background: '#FCE7E9',
  //         color: '#E63946',
  //         label: 'Canceled',
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: 'Details',
  //         buttonColor: '#FFFFFF',
  //         buttonBorder: '#E4E8EC 1px solid',
  //         buttonTextcolor: '#004FE0',
  //         buttonLink: '#',
  //         onClick: onClick,
  //       },
  //     ],
  //   },
  // ];

  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  const handleCreateOrder = async () => {
    const result = await createOrderId({ status: 'draft' });
    if (!result?.data.error) {
      toast.success(result.data.message);
      setSelectedOrderId(result.data?.body?.data?._id);
      setSelectedId(result.data?.body?.data?.orderId);
      setCreateIdDrawer(true);
    } else {
      toast.error(result.data.message);
    }
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getOrderIdSearchResult({ term: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  return (
    <>
      <div className="TableSection">
        <Table
          title="Order ID"
          tableHeader={true}
          addLabel="CREATE ID"
          AddButton={true}
          headCells={headCells}
          rows={rows}
          CheckboxDelete={false}
          // onClick={handleOpenDrawer}
          onClick={handleCreateOrder}
          footer={true}
          //   handleSearchText={handleSearchText}
          //   handleSearch={handleSearch}

          innerHeader={true}
          showSearchBox={true}
          FilterButton={false}
          searchBoxPlaceholder="Search"
          isDataLoading={options.isLoading || isLoading}
        />
      </div>

      {/* <Button variant="contained" onClick={() => setPaymentModal(true)}>
        payment modal pending
      </Button>
      <Button variant="contained" onClick={() => setPaymentModal2(true)}>
        payment modal successfully
      </Button>
      <PaymentModal
        paymentStatus={false}
        open={paymentModal}
        handleClose={() => setPaymentModal(false)}
      />
      <PaymentModal
        paymentStatus={true}
        open={paymentModal2}
        handleClose={() => setPaymentModal2(false)}
      /> */}

      {/* new user drawer */}
      <SideDrawer
        open={createIdDrawer}
        handleDrawerClose={handleCloseDrawer}
        title={`New order - ${selectedId}`}
        buttonLabel="Save"
        // buttonLink={}
        // panelTab={panelTab}
        CheckBoxTabsStatus={false}
        tabData={tabData}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={true}
        CheckboxLabel="Add another after saving"
        FooterWithoutTab={false}
        body={
          <>
            <OrderIdDrawer
              orderId={selectedOrderId}
              closeDrawer={handleCloseDrawer}
            />
          </>
        }
      />
      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        body={<>hello</>}
      />
    </>
  );
};

export default OrderId;
