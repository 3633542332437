import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Crown from '../../../assets/imgs/vip-crown-fill.svg';
import CreateRole from './CreateRole';
import DraggableDialog from '../../../components/hooks/Dialog';
import { useCheckRoleNameExistedMutation, useCreateRoleMutation, useDeleteRoleMutation, useGetRoleListQuery, useGetRolesSearchResultMutation, useUpdateRoleMutation } from '../../../redux/features/roles/roleApi';
import useProtected from '../../../hooks/useProtected';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useGetPermissionListQuery } from '../../../redux/features/permissions/permissionApi';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

const RolesPermissions = () => {

  const { data, isLoading, isFetching, error } = useGetRoleListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const {
    data: permissionData,
    isLoading: permissionIsLoading,
    isFetching: permissionIsFetching,
    error: permissionError
  } = useGetPermissionListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const [createRole, createRoleOptions] = useCreateRoleMutation()
  const [updateRole, updateRoleOptions] = useUpdateRoleMutation()
  const [deleteRole, deleteRoleOptions] = useDeleteRoleMutation()
  const [searchRole, searchRoleOptions] = useGetRolesSearchResultMutation()
  const [checkRoleName, checkRoleNameOptions] = useCheckRoleNameExistedMutation()
  useProtected(checkRoleNameOptions.error)

  useProtected(error)
  useProtected(permissionError)
  useProtected(createRoleOptions.error)
  useProtected(deleteRoleOptions.error)
  useProtected(searchRoleOptions.error)
  useProtected(updateRoleOptions.error)





  let initialRolesPermissionsObject = {
    name: "",
    description: "",
    status: "",
    permissions: [],
    users: [],
    removedUsers: [],
    order: 0
  }
  let initialRolesPermissionsObjectError = {
    name: "",
    description: "",
    status: "",
    permissions: "",
    users: "",
    order: ""
  }

  const [rolesPermissionsObject, setRolesPermissionsObject] = useState(initialRolesPermissionsObject)
  const [rolesPermissionsErrorObject, setRolesPermissionsErrorObject] = useState(initialRolesPermissionsObjectError)


  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let roleTitle = ''
        if (item?.name != "") {
          roleTitle = (item?.name == 'owner') ? <div className='IconCell'>{capitalizeFirstLetter(item?.name)} <img src={Crown} /></div> : capitalizeFirstLetter(item?.name)
        } else {
          roleTitle = 'N/A'
        }

        return ({
          cell: [
            { textBold: <span style={{ fontWeight: 600 }}>{roleTitle}</span> },
            (item?.name && item?.name === 'owner') ? { textBold: <span style={{ padding: '6px 0', fontWeight: '600' }}>{item?.users.length} user</span> } : { linkText: <span style={{ fontWeight: 600 }}>{item?.users.length}  {item?.users.length === 1 ? "user" : "users"}</span>, linkTextStatus: true, onClick: (e) => userLinkHandler(item?._id), },
            // { ActionButton: (item?.name && item?.name == 'owner') ? false : true, ActionButtonLabel: 'Action', TextAlign: 'right', },
            { ActionButtonDotIcon: (item?.name && item?.name === 'owner') ? false : true, ActionButtonLabel: 'Action', TextAlign: "right" },
          ],

          action: [
            { label: 'Edit', onClick: (e) => roleEditHandler(item?._id) },
            { label: 'Delete', onClick: (e) => handleOpenDelete(item?._id) },
          ],
          //======= Action Date End===== //
        })
      });
    } else {
      return [];
    }
  };

  let DeletedConfirmWord = "CONFIRM"
  const [openModal, setOpenModal] = useState(false)
  const [textValue, setTextValue] = React.useState("")
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false);
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([])
  const [roleId, setRoleId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [roleUsers, setRoleUsers] = useState([]);
  const [OpenRole, setOpenRole] = useState(false);
  const [openUserTab, setOpenUserTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [roleUsersToUpdate, setRoleUsersToUpdate] = useState([]);
  const [roleUsersToRemove, setRoleUsersToRemove] = useState([]);
  const [existNameChecked, setExistNameChecked] = useState(false)
  const handleDrawerOpenRole = () => {
    setOpenRole(true);
  };
  const handleDrawerCloseRole = () => {
    setRoleId('')
    setRoleUsers([])
    setRolesPermissionsObject(initialRolesPermissionsObject)
    setRolesPermissionsErrorObject(initialRolesPermissionsObjectError)
    setRoleUsersToUpdate([])
    setOpenRole(false);
  };

  const roleEditHandler = (id) => {
    setRoleId(id)
    handleDrawerOpenRole()
  }

  //============= user link tab part start ========================//
  const userLinkHandler = (id) => {
    setOpenUserTab(true)
    setRoleId(id)
    handleDrawerOpenRole()
  }
  //============= user link tab part end ========================//



  //======= Delete dialog ===== //
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const handleOpenDelete = (id) => {
    if (!isTheActionPermitted('deleteRolesPermissions')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setDeleteId(id)
    setOpenDialogDelete(true);
  };
  const deleteRoleHandler = async () => {
    const result = await deleteRole(deleteId);
    if (!result.data.error) {
      toast.success("Role deleted successfully");
      setDeleteId('');
    } else {
      toast.error(result.data.message);
    }
    handleCloseDialogDelete();
  }
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRoles(data?.body?.data)
    }
  }, [isLoading, isFetching, data])

  useEffect(() => {
    if (!permissionIsLoading && !permissionIsFetching) {
      setPermissions(permissionData?.body?.data)
    }
  }, [permissionIsLoading, permissionIsFetching, permissionData])

  useEffect(() => {
    if (roles?.length > 0) {
      setRows(convertDataToCellRows(roles))
    }
  }, [roles])

  const handleSearch = async () => {
    const result = await searchRole({ term: searchTerm });
    if (result?.data) {
      setRoles(result.data.body.data);
    }
  };

  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { id: 'Role', numeric: false, disablePadding: true, label: 'Role name', width: '40%', textAlign: 'left', },
    { id: 'Users', numeric: false, disablePadding: true, label: 'Users', width: '40%', textAlign: 'left', },
    { id: 'Users', numeric: false, disablePadding: true, label: '', width: '40%', textAlign: 'right', },

  ];

  const getAllPermissionNumber = () => {
    let permissionNumber = 0
    rolesPermissionsObject?.permissions?.map(permission => {
      permissionNumber += permission?.operations?.length
    })
    return permissionNumber
  }
  const checkRolesPermissionValidity = async () => {

    const hasSettingsPermission = rolesPermissionsObject?.permissions?.find((item) => item?.name === "settings" && item?.enabled)
    let errorObject = { ...rolesPermissionsErrorObject }
    let error = 0
    if (rolesPermissionsObject.name === "") {
      errorObject = { ...errorObject, name: "Required" }
      error++
    } else {
      errorObject = { ...errorObject, name: "" }
    }


    if (getAllPermissionNumber() === 0 && !hasSettingsPermission) {
      toast.error("Please select at least one permission")
      errorObject = { ...errorObject, permissions: "Required" }
      error++
    } else {
      errorObject = { ...errorObject, permissions: "" }
    }
    setRolesPermissionsErrorObject({ ...rolesPermissionsErrorObject, ...errorObject })

    return (error > 0) ? false : true
  }


  const createRoleHandler = async () => {
    // let errorObject = { ...rolesPermissionsErrorObject }
    let data = { ...rolesPermissionsObject }
    data.status = 'active'
    let valid = await checkRolesPermissionValidity()
    if (!valid) {
      return false
    }

    if (existNameChecked) {
      return false
    }



    let result = null
    if (roleId !== "") {
      result = await updateRole({ ...data, id: roleId })
    } else {
      delete data.removedUsers;
      result = await createRole({ ...data })

    }

    if (result?.data && result?.data?.body?.data) {
      setRolesPermissionsObject(initialRolesPermissionsObject)
      setRolesPermissionsErrorObject(initialRolesPermissionsObjectError)
      if (roleId !== "") {
        toast.success("Role updated")
      } else {
        toast.success("Role added")
      }
      setRoleUsers([])
      setRoleUsersToUpdate([])
      handleDrawerCloseRole()
    }


  }

  useEffect(() => {
    console.log('role permission object:', rolesPermissionsObject)
  }, [rolesPermissionsObject])


  useEffect(() => {
    setRolesPermissionsObject({ ...rolesPermissionsObject, users: roleUsersToUpdate })
  }, [roleUsers, roleUsersToUpdate])

  return (
    <>
      <div className="TableSection">
        <Table
          title="Roles & Permissions"
          tableHeader={true}
          addLabel="Create new"
          AddButton={true}
          // icon={<AddRoundedIcon />}
          headCells={headCells}
          rows={rows}
          onClick={handleDrawerOpenRole}
          footer={true}
          innerHeader={true}
          showSearchBox={false}
          FilterButton={false}
          searchBoxPlaceholder="Search users"
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          RowsPerPage={20}
          isDataLoading={isLoading || createRoleOptions.isLoading || deleteRoleOptions.isLoading || searchRoleOptions.isLoading || updateRoleOptions.isLoading}

          emptyTableTitle="No Roles  added yet"
          emptyTableNote="No items available"
          btnLabel="Create new"
          showButton={true}
          onClickEmptyCard={handleDrawerOpenRole}
        />
      </div>

      {/* Create Role drawer */}
      <SideDrawer
        open={OpenRole}
        handleDrawerClose={handleDrawerCloseRole}
        clickSaveButton={createRoleHandler}
        title={roleId ? "Edit Role" : "Create Role"}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        padding="0"
        body={
          <>
            <CreateRole
              rolesPermissionsObject={rolesPermissionsObject}
              setRolesPermissionsObject={setRolesPermissionsObject}
              errors={rolesPermissionsErrorObject}
              setErrors={setRolesPermissionsErrorObject}
              roleUsers={roleUsers}
              setRoleUsers={setRoleUsers}
              setRoleUsersToUpdate={setRoleUsersToUpdate}
              roleUsersToUpdate={roleUsersToUpdate}
              roleUsersToRemove={roleUsersToRemove}
              setRoleUsersToRemove={setRoleUsersToRemove}
              roleId={roleId}
              permissions={permissions}
              openUserTab={openUserTab}
              setOpenUserTab={setOpenUserTab}
              // checkedName={checkedName}
              setExistNameChecked={setExistNameChecked}
            />
          </>
        }
      />

      {/* Delete section*/}
      {/* <DraggableDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        handleConfirmCloseDialog={(e) => { deleteRoleHandler() }}
        title="Delete role?"
        body={<div>Are you sure you want to delete this role?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      /> */}


      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirm={deleteRoleHandler}
        title="Delete role?"
        body=<div style={{ fontSize: '14px' }}>Are you sure you want to delete this role?<br /> Once you delete it, you can't get it back.</div>
      />
    </>
  );
};

export default RolesPermissions;
