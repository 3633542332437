import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import "./GooglePlaceField.css"

const GooglePlaceField = (props) => {

  return (
    <div>
      {
        (props.label === "") ? <></> : <div className="FieldLabel">{props.label} {props.validation && <span>*</span>}</div>
      }
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY}
        selectProps={{
          value: props.value,
          onChange: props.handleChange,
          placeholder: props.placeholder,
          styles: {
            input: (provided) => ({
              ...provided,
              borderColor: props.error ? "#ba1b1a" : "#c8c6ca",
            }),
            control: (provided) => ({
              ...provided,
              borderColor: props.error ? "#ba1b1a" : "#c8c6ca",
            }),
            // dropdownIndicator: (provided) => ({
            //   ...provided,
            //   display: "none",
            // }),
            // indicatorSeparator: (provided) => ({
            //   ...provided,
            //   display: "none",
            // }),
          },
        }}
        className="dscdscvs"
      />
    </div>
  );
}

export default GooglePlaceField;