import { api } from '../../api/apiSlice';

const schoolApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolList: builder.query({
      query: () => ({
        url: '/schools/mySchools',
      }),
      providesTags: ['Schools'],
    }),
    getSchool: builder.query({
      query: (id) => ({
        url: `/schools/getSingle/${id}`
      }),
      providesTags: ['School'],
    }),
    updateSchool: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/schools/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Schools', 'School'],
    }),
    deleteSchoolLogoIcon: builder.mutation({
      query: (id) => ({
        url: `/schools/removeIcon/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Schools', 'School'],
    }),
    deleteSchoolLogo: builder.mutation({
      query: (id) => ({
        url: `/schools/removeLogo/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Schools', 'School'],
    }),
    createAccountLink: builder.mutation({
      query: () => ({
        url: `/stripe/admin/create-account-link`,
        method: 'POST',
      }),
      invalidatesTags: ['Schools', 'School'],
    }),
    verifyStripeAccount: builder.mutation({
      query: () => ({
        url: `/stripe/admin/verify-account`,
        method: 'POST',
      }),
      invalidatesTags: ['Schools', 'School'],
    })

  }),
});

export const {
  useGetSchoolListQuery,
  useGetSchoolQuery,
  useUpdateSchoolMutation,
  useDeleteSchoolLogoIconMutation,
  useDeleteSchoolLogoMutation,
  useCreateAccountLinkMutation,
  useVerifyStripeAccountMutation
} = schoolApi;
