import React, { useState, useEffect } from 'react';
import './DateRange.css';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';



//=== Package ( URL: https://rsuitejs.com/components/date-range-picker/ ) ===//

function DateRange(props) {


  return (
    <div className="DateRange">
      <div className={props.border}>
        <DateRangePicker
          format="dd-MM-yyyy"
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default DateRange;
