import React, { useEffect, useState } from 'react';

import Table from '../../../components/Table/Table';
import tableImg from '../../../assets/imgs/tableImg.png';
import { useGetUserDetailsQuery, useGetUserIDPhotoHistoryQuery, useGetUserListQuery, useLazyGetUserDetailsQuery } from '../../../redux/features/users/userApi';
import moment from 'moment-timezone';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';


const IdPhotoHistory = ({ id, userList }) => {
  const { isLoading, data, error } = useGetUserIDPhotoHistoryQuery(id,
    { refetchOnMountOrArgChange: true })


  useProtected(error);



  const [userIDPhotoHistory, setUserIDPhotoHistory] = useState([])

  const [rows, setRows] = useState([])
  useEffect(() => {
    if (data && !isLoading && !error) {
      setUserIDPhotoHistory(data?.body?.data)
    }
  }, [data, isLoading, error])


  useEffect(() => {
    if (userIDPhotoHistory?.length > 0) {
      setRows(convertCellsToRows(userIDPhotoHistory))
    }
  }, [userIDPhotoHistory])


  const uploadedUserData = (id) => {
    const data = userList?.find((item) => item?._id === id)
    let name = ` ${data?.firstName} ${data?.lastName}`
    return name
  }

  const handleDownLoad = (name, file) => {
    if (!file) {
      toast.error("Something went wrong")
      return false
    }
    const link = document.createElement("a")
    link.href = file
    link.download = name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const headCells = [
    { id: 'Photo', numeric: false, disablePadding: true, label: 'Photo', width: '35%', textAlign: 'left', },
    { id: 'uploaded', numeric: false, disablePadding: true, label: 'Uploaded by', width: '25%', textAlign: 'left', },
    { id: 'date', numeric: false, disablePadding: true, label: 'Uploaded date', width: '33%', textAlign: 'left', },
    { id: 'Photo', numeric: false, disablePadding: true, label: '', width: '10%', textAlign: 'left', },

  ];


  const convertCellsToRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => (

        {
          cell: [
            { img: item?.fileUrl ? item?.fileUrl : tableImg, imgStatus: true, imgText: item?.fileName || "N/A", },
            { date: uploadedUserData(item.fileUploadedBy) },
            { date: moment(item?.fileUploadedAt).format("DD-MMM-YYYY, hh:mm A") },
            { Button: true, buttonLabel: 'Download', buttonTextcolor: '#004FE0', align: 'right', onClick: (e) => handleDownLoad(item?.fileName, item?.fileUrl) },
          ],

        }
      ))
    } else {
      return []
    }
  }
  // const rows = [
  //   {
  //     cell: [
  //       { img: tableImg, imgStatus: true, imgText: '0111050-shayan-mahmud-07202023', },
  //       { date: 'Shayan Mahmud' },
  //       { date: '12-Aug-2023, 10:00 AM' },
  //       { Button: true, buttonLabel: 'Download', buttonTextcolor: '#004FE0', buttonLink: '#', buttonTarget: '_blank', align: 'right' },
  //     ],

  //   },
  // ];


  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          innerHeader={true}
          RowsPerPage={10}
        />
      </div>
    </>
  );
};

export default IdPhotoHistory;
