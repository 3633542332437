import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import AddSection from './AddSection';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';

import {
  useGetClassSearchResultMutation,
  useGetClassListQuery,
  useGetClassLevelListQuery,
  useAddSectionToCourseMutation,
  useUpdateSectionToCourseMutation,
  useDeleteSectionToCourseMutation,
  useGetSectionSearchResultMutation,
} from '../../../redux/features/classes/classApi';

import { useGetSchoolYearListQuery } from '../../../redux/features/school_years/schoolYearApi';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DuplicateSection from './DuplicateSection'
import DraggableDialog from '../../../components/hooks/Dialog';
import { toast } from "react-toastify";


import TableHeader from '../../../components/Table/TableHeader';
import TableSubHeader from '../../../components/Table/TableSubHeader';

import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import { useNavigate } from "react-router";
import { useGetUsersByUserTypesMutation } from "../../../redux/features/users/userApi";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { truncateText } from "../../../hooks/HelperFunctions";



const Sections = (props) => {
  const {
    course_id,
    courseDetails,
    openFilterDrawer,
    setOpenFilter,
    sectionErrors,
    sectionErrorsInitialObject, setSectionErrors
  } = props
  const { data, isError, isLoading, error } = useGetClassListQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: schoolYearList, isLoading: isSchoolYearListLoading, isError: isSchoolYearListError, error: schoolYearListError } = useGetSchoolYearListQuery();
  const { data: classLevelList, isLoading: isClassLevelListLoading, isError: isClassLevelListError, error: classLevelListError } = useGetClassLevelListQuery();

  const [addSectionToCourse, addSectionToCourseOptions] = useAddSectionToCourseMutation()
  const [updateSectionToCourse, updateSectionToCourseOptions] = useUpdateSectionToCourseMutation()
  const [deleteSectionFromCourse, deleteSectionFromCourseOptions] = useDeleteSectionToCourseMutation()
  const [searchSection, searchSectionOptions] = useGetSectionSearchResultMutation()


  useProtected(error);
  useProtected(schoolYearListError);
  useProtected(classLevelListError);
  useProtected(searchSectionOptions.error);
  useProtected(addSectionToCourseOptions.error);
  useProtected(updateSectionToCourseOptions.error);
  useProtected(deleteSectionFromCourseOptions.error);

  useEffect(() => {
    if (!isSchoolYearListLoading && !isSchoolYearListError) {
      const data = schoolYearList.body.data;
      setSchoolYearOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setSchoolYear(data[0].name)
    }
  }, [schoolYearList, isSchoolYearListLoading, isSchoolYearListError])


  useEffect(() => {
    if (!isClassLevelListLoading && !isClassLevelListError) {
      const data = classLevelList.body.data;
      setClassLevelOptions(getPreparedArray(data || []))
      if (data && data.length > 0) setClassLevel(data[0].name)
    }
  }, [classLevelList, isClassLevelListLoading, isClassLevelListError])


  let sectionInitialObject = {
    sectionName: "",
    classLevel: [],
    acededemicYear: {},
    acededemicTerm: [],
    sectionCode: {
      codePrefix: "",
      code: ""
    },
    primaryTeacher: {},
    classRoomSize: "",
    status: "",
    id: ""
  }

  const [sectionObject, setSectionObject] = useState(sectionInitialObject)
  // const [sectionErrors, setSectionErrors] = useState(sectionErrorsInitialObject)
  const [sectionDuplicateObject, setSectionDuplicateObject] = useState(sectionInitialObject)
  const [sectionDuplicateErrors, setSectionDuplicateErrors] = useState(sectionErrorsInitialObject)
  const [getSearchedClassList, options] = useGetClassSearchResultMutation();
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [schoolYearOptions, setSchoolYearOptions] = useState([])
  const [classLevelOptions, setClassLevelOptions] = useState([])
  const [schoolYear, setSchoolYear] = useState('')
  const [classLevel, setClassLevel] = useState('')
  const [sectionList, setSectionList] = useState([])
  const [editSectionId, setEditSectionId] = useState('')
  const [copySectionId, setCopySectionId] = useState('')
  const [archiveSectionId, setArchiveSectionId] = useState('')
  const [deleteSectionId, setDeleteSectionId] = useState('')
  const [duplicateInfoSection, setDuplicateInfoSection] = useState([])
  const [duplicateStudentFilter, setDuplicateStudentFilter] = useState([])
  const [checkBeforeSavingErros, setCheckBeforeSavingErros] = useState(false)



  const getPreparedArray = (data) => {
    return data.map((item) => ({
      value: item.name,
      label: item.name,
    }));
  };


  const onClick = () => {
    alert('onclick working fine!');
  };

  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Section name", width: "20%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Class/Level", width: "15%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Academic Year", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Section Code", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Primary Teacher", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Status", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "5%", textAlign: "left", },

    // { id: "name", numeric: false, disablePadding: true, label: "Academic Year", width: "20%", textAlign: "left", },
    // { id: "name", numeric: false, disablePadding: true, label: "Primary Teacher", width: "25%", textAlign: "left", },


  ];
  const statustData = [
    { value: 'draft', statuscolor: "#EEECFF", select: "Draft", textColor: "#7266FB" },
    { value: 'active', statuscolor: "#EDF7EA", select: "Active", textColor: "#66BD50" },
    { value: 'inactive', statuscolor: "#FCE7E9", select: "Inactive", textColor: "#E63946" },
  ];
  const renderClassLevelToolTip = (classLevels) => {
    let tooltipArray = []
    if (classLevels?.length > 0) {
      classLevels?.map((classlevel) => {
        tooltipArray.push(<li>{classlevel.name}</li>)
      })
    }
    return tooltipArray
  }
  const archiveUpdateHandler = (id) => {
    setArchiveSectionId(id)
    handleOpenArchive()
  }
  const unarchiveUpdateHandler = (id) => {
    setArchiveSectionId(id)
    handleOpenUnarchive()
  }
  const deleteSectionHandler = (id) => {
    if (!isTheActionPermitted('deleteSections')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setDeleteSectionId(id)
    handleOpenDelete()
  }
  const copySectionHandler = (id) => {
    setCopySectionId(id)
    let foundSection = courseDetails?.sections?.find(singleSection => singleSection.id == id)
    setSectionDuplicateObject({ ...foundSection, sectionName: `Copy of ${foundSection?.sectionName}` })
    handleDrawerOpenDuplicate()
  }
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        console.log(item)
        return {
          cell: [
            { textBold: item?.sectionName, link: `/section-details/${course_id}/${item.id}`, },
            {
              textBold: (item?.classLevel?.length > 0) && item?.classLevel[0]?.name,
              TextWithLinkText: (item?.classLevel?.length > 1) && `+${item?.classLevel?.length - 1} more`, link: "",
              tooltipText: <div>{renderClassLevelToolTip(item?.classLevel)}</div>
            },
            { text: item?.acededemicYear?.name },
            { textBold: `${item?.sectionCode?.codePrefix} ${item?.sectionCode?.code}` },
            { text: truncateText(`${item?.primaryTeacher?.firstName} ${item?.primaryTeacher?.lastName}`, 30) || 'N/A' },
            // { linkText: `${item?.primaryTeacher?.firstName} ${item?.primaryTeacher?.lastName}` || 'N/A', linkTextStatus: true, link: `/course-details/${item._id}`, },

            {
              Status: true, statusLine: true, linebackground: (item?.status == 'active') ? '#41AB37' : '#BA1A1A',
              background: (item?.status == 'active') ? '#EDF7EA' : '#FCE7E9',
              color: (item?.status == 'active') ? '#66BD50' : '#E63946',
              label: (item?.status == 'active') ? 'Active' : 'Archive',
            },
            // { Status: true, background: '#FCE7E9', color: '#E63946', label: 'Archive' },
            { ActionButtonDotIcon: true, },
          ],


          //======= Action Date Start===== //
          action: [
            { label: 'Details', onClick: (e) => redirectToSectionDetails(item?.id) },
            { label: 'Edit', onClick: (e) => editSectionHandler(item?.id) },
            { label: 'Duplicate', onClick: (e) => copySectionHandler(item?.id) },
            (item?.status == 'active') ? { label: 'Archive', onClick: (e) => archiveUpdateHandler(item?.id) } : { label: 'UnArchive', onClick: (e) => unarchiveUpdateHandler(item?.id) },
            { label: 'Delete', onClick: (e) => deleteSectionHandler(item?.id) },
          ],
          //======= Action Date End===== //


        }
      }

      )
    }
    else {
      return [];
    }
  }
  const editSectionHandler = (id) => {
    setEditSectionId(id)
    let foundSection = courseDetails?.sections?.find(section => section.id === id)
    if (foundSection) {
      setSectionObject(foundSection)

    }
    handleFilterDrawerOpen()

  }
  // useEffect(() => {
  //   if (!isLoading && !isError) {
  //     setRows(convertDataToCellRows(data.body.data));
  //   }
  // }, [data, isError, isLoading])

  useEffect(() => {

    setSectionList(courseDetails?.sections)
  }, [courseDetails])

  useEffect(() => {
    setRows(convertDataToCellRows(sectionList));
  }, [sectionList])

  const navigate = useNavigate()
  const redirectToSectionDetails = (id) => {
    navigate(`/section-details/${course_id}/${id}`)
  }


  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchSection({ term: "", course_id: course_id });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchSection({ term: searchTerm, course_id: course_id });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part end ====================== //

  // Classes Drawer //

  const handleFilterDrawerOpen = () => {
    setSectionErrors(sectionErrorsInitialObject)
    setSectionDuplicateErrors(sectionErrorsInitialObject)
    setOpenFilter(true);
  };
  const handleCloseFilterDrawer = () => {
    setCheckBeforeSavingErros(false)
    setOpenFilter(false);
    setSectionObject(sectionInitialObject)
    setSectionErrors(sectionErrorsInitialObject)
    setSectionDuplicateErrors(sectionErrorsInitialObject)
    setEditSectionId('')
  };


  // Duplicate Section//
  const [OpenDuplicate, setOpenDuplicate] = useState(false);
  const handleDrawerOpenDuplicate = () => {
    setOpenDuplicate(true);
  };
  const handleDrawerCloseDuplicate = () => {
    setOpenDuplicate(false);
    setSectionDuplicateObject(sectionInitialObject)
    setSectionDuplicateErrors(sectionErrorsInitialObject)
    setCopySectionId('')
    setDuplicateInfoSection([])
    setDuplicateStudentFilter([])
  };

  //======= Archive section dialog ===== //
  const [openDialogArchive, setOpenDialogArchive] = useState(false);
  const handleOpenArchive = () => {
    setOpenDialogArchive(true);
  };
  const handleCloseDialogArchive = () => {
    setArchiveSectionId('')
    setOpenDialogArchive(false);
  };

  //======= Unarchive section dialog ===== //
  const [openDialogUnarchive, setOpenDialogUnarchive] = useState(false);
  const handleOpenUnarchive = () => {
    setOpenDialogUnarchive(true);
  };
  const handleCloseDialogUnarchive = () => {
    setArchiveSectionId('')
    setOpenDialogUnarchive(false);
  };

  //======= Unarchive section dialog ===== //
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialogDelete(true);
  };
  const handleCloseDialogDelete = () => {
    setDeleteSectionId('')
    setOpenDialogDelete(false);
  };
  const isSameSectionCodeForAdd = () => {
    let found = false
    courseDetails?.sections?.map((singleSection) => {
      if (sectionObject?.sectionCode?.codePrefix == singleSection?.sectionCode?.codePrefix && sectionObject?.sectionCode?.code == singleSection?.sectionCode?.code && !editSectionId) {
        found = true
      }
    })
    return found
  }
  const isSameSectionCode = () => {
    let found = false
    courseDetails?.sections?.map((singleSection) => {
      if (sectionDuplicateObject?.sectionCode?.codePrefix == singleSection?.sectionCode?.codePrefix && sectionDuplicateObject?.sectionCode?.code == singleSection?.sectionCode?.code) {
        found = true
      }
    })
    return found
  }

  const checkSection = async () => {
    let errorNumber = 0
    let errorObject = { ...sectionErrors }
    if (sectionObject.sectionName == "") {
      errorObject = { ...errorObject, sectionName: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionName: "" }
    }

    if (sectionObject.classLevel == "" || sectionObject.classLevel.length === 0) {
      // console.log(sectionObject.classLevel, 345)
      errorObject = { ...errorObject, classLevel: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, classLevel: "" }

    }

    if (sectionObject.acededemicYear == "" || Object.keys(sectionObject.acededemicYear).length === 0) {
      errorObject = { ...errorObject, acededemicYear: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, acededemicYear: "" }
    }
    if (sectionObject.acededemicTerm?.length === 0) {
      errorObject = { ...errorObject, acededemicTerm: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, acededemicTerm: "" }
    }


    if (sectionObject.sectionCode.codePrefix == "") {
      errorObject = { ...errorObject, sectionCode_codePrefix: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionCode_codePrefix: "" }
    }

    if (sectionObject.sectionCode.code == "") {
      errorObject = { ...errorObject, sectionCode_code: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionCode_code: "" }
    }

    if (sectionObject.primaryTeacher == "" || Object.keys(sectionObject.primaryTeacher).length === 0) {
      errorObject = { ...errorObject, primaryTeacher: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, primaryTeacher: "" }
    }


    if (sectionObject.classRoomSize == "") {
      errorObject = { ...errorObject, classRoomSize: "Required" }
      errorNumber++
    }
    else if (parseInt(sectionObject.classRoomSize) <= 0) {
      toast.error(`Class capacity has to be more than 0`);
      errorNumber++
    } else if (!Number.isInteger(Number(sectionObject.classRoomSize))) {
      toast.error(`Class capacity must be a whole number.`);
      // errorObject = { ...errorObject, classRoomSize: `Class capacity must be a whole number.` }
      errorNumber++
    }
    else {
      errorObject = { ...errorObject, classRoomSize: "" }
    }

    if (isSameSectionCodeForAdd()) {
      errorNumber++
    }
    setSectionErrors({ ...sectionErrors, ...errorObject })

    return (errorNumber > 0) ? false : true
  }




  const checkSectionDuplicate = async () => {
    let errorNumber = 0
    let errorObject = { ...sectionDuplicateErrors }
    if (sectionDuplicateObject.sectionName == "") {
      errorObject = { ...errorObject, sectionName: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionName: "" }
    }

    if (sectionDuplicateObject.classLevel == "" || sectionDuplicateObject.classLevel.length === 0) {
      errorObject = { ...errorObject, classLevel: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, classLevel: "" }
    }

    if (sectionDuplicateObject.acededemicYear == "" || Object.keys(sectionDuplicateObject.acededemicYear).length === 0) {
      errorObject = { ...errorObject, acededemicYear: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, acededemicYear: "" }
    }


    if (sectionDuplicateObject.sectionCode.codePrefix == "") {
      errorObject = { ...errorObject, sectionCode_codePrefix: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionCode_codePrefix: "" }
    }

    if (sectionDuplicateObject.sectionCode.code == "") {
      errorObject = { ...errorObject, sectionCode_code: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, sectionCode_code: "" }
    }

    if (isSameSectionCode()) {
      errorNumber++
    }

    if (sectionDuplicateObject.primaryTeacher == "" || Object.keys(sectionDuplicateObject.primaryTeacher).length === 0) {
      errorObject = { ...errorObject, primaryTeacher: "Required" }
      errorNumber++
    } else {
      errorObject = { ...errorObject, primaryTeacher: "" }
    }


    if (sectionDuplicateObject.classRoomSize == "") {
      errorObject = { ...errorObject, classRoomSize: "Required" }
      errorNumber++
    }
    else if (parseInt(sectionDuplicateObject.classRoomSize) <= 0) {
      toast.error(`Class capacity has to be more than 0`);
      errorNumber++
    } else if (!Number.isInteger(Number(sectionDuplicateObject.classRoomSize))) {
      toast.error(`Class capacity must be a whole number.`);
      // errorObject = { ...errorObject, classRoomSize: `Class capacity must be a whole number.` }
      errorNumber++
    }
    else {
      errorObject = { ...errorObject, classRoomSize: "" }
    }



    setSectionDuplicateErrors({ ...sectionDuplicateErrors, ...errorObject })

    return (errorNumber > 0) ? false : true
  }

  const setNewData = async () => {
    let classLevelObjects = classLevelList.body.data?.filter((classlevel) => {

      if (sectionObject?.classLevel?.length > 0 && typeof sectionObject?.classLevel[0] == "string") {
        return sectionObject.classLevel.includes(classlevel.name) ? true : false
      } else {
        return sectionObject.classLevel.find(singleClassLevel => singleClassLevel.name == classlevel.name)
      }
    })

    let acedemicYearName = (typeof sectionObject?.acededemicYear == "string") ? sectionObject?.acededemicYear : sectionObject?.acededemicYear?.name
    let acedemicTermName = (typeof sectionObject?.acededemicTerm == "string") ? sectionObject?.acededemicTerm : sectionObject?.acededemicTerm?.termName

    let schoolYearObject = schoolYearList.body.data?.find(schoolyear => schoolyear.name == acedemicYearName)
    let termObject = schoolYearObject.terms?.find(term => term.termName == acedemicTermName)
    let data = { ...sectionObject }
    data.classLevel = classLevelObjects?.map((classObj) => {
      return {
        id: classObj?._id,
        name: classObj?.name
      }
    })
    data.acededemicYear = {
      id: schoolYearObject?._id,
      name: schoolYearObject?.name
    }
    data.acededemicTerm = termObject
    if (editSectionId != "") {
      data.id = editSectionId
    }



    return data
  }
  const setNewDataDuplicate = async () => {
    let classLevelObjects = classLevelList.body.data?.filter((classlevel) => {

      if (sectionDuplicateObject?.classLevel?.length > 0 && typeof sectionDuplicateObject?.classLevel[0] == "string") {
        return sectionDuplicateObject.classLevel.includes(classlevel.name) ? true : false
      } else {
        return sectionDuplicateObject.classLevel.find(singleClassLevel => singleClassLevel.name == classlevel.name)
      }
    })

    let acedemicYearName = (typeof sectionDuplicateObject?.acededemicYear == "string") ? sectionDuplicateObject?.acededemicYear : sectionDuplicateObject?.acededemicYear?.name
    let acedemicTermName = (typeof sectionDuplicateObject?.acededemicTerm == "string") ? sectionDuplicateObject?.acededemicTerm : sectionDuplicateObject?.acededemicTerm?.termName

    let schoolYearObject = schoolYearList.body.data?.find(schoolyear => schoolyear.name == acedemicYearName)
    let termObject = schoolYearObject.terms?.find(term => term.termName == acedemicTermName)
    let data = { ...sectionDuplicateObject }
    data.classLevel = classLevelObjects?.map((classObj) => {
      return {
        id: classObj?._id,
        name: classObj?.name
      }
    })
    data.acededemicYear = {
      id: schoolYearObject?._id,
      name: schoolYearObject?.name
    }
    data.acededemicTerm = termObject
    if (editSectionId != "") {
      data.id = editSectionId
    }

    // check the status if have then pic the student and add them to section
    let newStudents = []
    if (duplicateInfoSection.includes('students') && duplicateStudentFilter.length > 0) {
      newStudents = data?.students?.filter(singleStudents => duplicateStudentFilter.includes(singleStudents.status))
    }
    data.students = newStudents



    return data
  }
  //========================= automatic error checking ========================================//
  useEffect(() => {
    if (checkBeforeSavingErros) {
      checkSection()
    }
  }, [checkBeforeSavingErros, sectionObject])
  //========================= automatic error checking ========================================//
  const addSectionHandler = async () => {
    setCheckBeforeSavingErros(true)
    let isValid = await checkSection()

    if (!isValid) {
      return false
    }
    let data = await setNewData()

    let result = null

    if (editSectionId == "") {
      result = await addSectionToCourse({ ...data, status: 'active', id: course_id })
    } else {
      result = await updateSectionToCourse({ ...data, course_id: course_id })
    }

    if (result?.data && result?.data?.body?.data) {
      if (editSectionId == "") {
        toast.success("Section added")
      } else {
        toast.success("Section details updated")
      }
    } else {
      toast.error("Something went wrong")
    }
    setSectionObject(sectionInitialObject)
    setSectionErrors(sectionErrorsInitialObject)
    handleCloseFilterDrawer()
    setEditSectionId('')
    setCheckBeforeSavingErros(false)

  }

  const duplicateSectionHandler = async () => {
    let isValid = await checkSectionDuplicate()

    if (!isValid) {
      return false
    }
    let data = await setNewDataDuplicate()

    let result = null

    result = await addSectionToCourse({ ...data, id: course_id })

    if (result?.data && result?.data?.body?.data) {
      toast.success("Section duplicated")
    } else {
      toast.error("Something went wrong")
    }
    setSectionDuplicateObject(sectionInitialObject)
    setSectionDuplicateErrors(sectionErrorsInitialObject)
    handleDrawerCloseDuplicate()
    setCopySectionId('')
    setDuplicateInfoSection([])
    setDuplicateStudentFilter([])

  }

  const archiveHandler = async () => {

    let result = await updateSectionToCourse({ status: 'archive', id: archiveSectionId, course_id: course_id })
    if (result?.data && result?.data?.body?.data) {
      toast.success("Section archived")
    } else {
      toast.error("Something went wrong")
    }
    handleCloseDialogArchive()
  }

  const unarchiveHandler = async () => {

    let result = await updateSectionToCourse({ status: 'active', id: archiveSectionId, course_id: course_id })
    if (result?.data && result?.data?.body?.data) {
      toast.success("Section unarchived")
    } else {
      toast.error("Something went wrong")
    }
    handleCloseDialogUnarchive()
  }

  const deleteHandler = async () => {
    let result = await deleteSectionFromCourse({ id: deleteSectionId, course_id: course_id })
    if (result?.data && result?.data?.body?.data) {
      toast.success("Section deleted")
    } else {
      toast.error("Something went wrong")
    }
    handleCloseDialogDelete()
  }





  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabLabel = [
    {
      label: "Table",
    },
    {
      label: "Card",
    },

  ]

  const tabPanel = [
    {
      id: 0,
      panel: <div>
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          isDataLoading={isLoading || options?.isLoading}
          RowsPerPage={10}
          emptyTableTitle="No course added yet"
          emptyTableNote="No items available"
          btnLabel="New Course"
          showButton={true}
          onClickEmptyCard={handleFilterDrawerOpen}
          pageNumberFromOutSide={pageNumberFromOutSide}
        />
      </div>,
    },
    {
      id: 1,
      panel: <ComingSoon />,
    },
  ]


  // useEffect(() => {
  //   const drawerBody = document.querySelector('.DrawerBody');
  //   const sectionFormContainer = document.querySelector('.DrawerBody .sectionFormContainer');
  //   if (drawerBody && sectionFormContainer) {
  //     drawerBody.style.width = '680px';
  //     drawerBody.style.background = 'red';
  //   }
  // }, [openFilterDrawer]);


  return (
    <>

      <TableHeader
        TableTitleSmall="Sections"
        AddButton={true}
        AddButtonLabel="Add section"
        AddButtonOnClick={handleFilterDrawerOpen}
        Border={true}

      />

      <div className="sectionTableContainer">
        <TableSubHeader
          Tabs={true}
          tabLabel={tabLabel}
          tabPanel={tabPanel}
          TabValue={value}
          TabHandleChange={handleChange}
          Filter={false}
          //FilterOnClick={FilterOnClick}

          //== Search Code == //
          Search={true}
          SearchPlaceholder="Search..."
          // SearchValue={searchTerm}
          setResetSearchTerm={setResetSearchTerm}
          SearchOnChange={handleSearchText}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}

          //SearchOnclick={SearchOnclick}
          //SearchOnMouseDown={SearchOnMouseDown}
          handleSearch={handleSearch}
          Border={true}
        />
      </div>


      {/* Create classes drawer */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        title={(editSectionId === "") ? "Add Section" : "Edit Section"}
        sideOpen="right"
        ButtonLabel={(editSectionId === "") ? "Add Section" : "Save"}
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={addSectionHandler}
        drawerWidth="680px"
        // drawerMinWidth="100%"
        drawerMaxWidth="680px"
        body={
          // <div className="sectionFormContainer">
          <AddSection
            handleClose={handleCloseFilterDrawer}
            schoolYearOptions={schoolYearOptions}
            setSchoolYearOptions={setSchoolYearOptions}
            classLevelOptions={classLevelOptions}
            setClassLevelOptions={setClassLevelOptions}
            schoolYear={schoolYear}
            setSchoolYear={setSchoolYear}
            classLevel={classLevel}
            setClassLevel={setClassLevel}
            sectionObject={sectionObject}
            setSectionObject={setSectionObject}
            sectionErrors={sectionErrors}
            setSectionErrors={setSectionErrors}
            schoolYearList={schoolYearList}
            editSectionId={editSectionId}
            courseDetails={courseDetails}
            isSameSectionCodeForAdd={isSameSectionCodeForAdd}
            checkSection={checkSection}
          />
          // </div>
        }
      />


      {/* Duplicate Section */}
      <SideDrawer
        open={OpenDuplicate}
        handleDrawerClose={handleDrawerCloseDuplicate}
        title="Duplicate Section"
        sideOpen="right"
        ButtonLabel="Duplicate"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        clickSaveButton={duplicateSectionHandler}
        drawerWidth="680px"
        // drawerMinWidth="100%"
        drawerMaxWidth="680px"
        body={
          <>
            <DuplicateSection
              schoolYearOptions={schoolYearOptions}
              setSchoolYearOptions={setSchoolYearOptions}
              classLevelOptions={classLevelOptions}
              setClassLevelOptions={setClassLevelOptions}
              sectionDuplicateObject={sectionDuplicateObject}
              setSectionDuplicateObject={setSectionDuplicateObject}
              sectionDuplicateErrors={sectionDuplicateErrors}
              setSectionDuplicateErrors={setSectionDuplicateErrors}
              copySectionId={copySectionId}
              courseDetails={courseDetails}
              schoolYearList={schoolYearList}
              addSectionToCourseOptions={addSectionToCourseOptions}
              duplicateStudentFilter={duplicateStudentFilter}
              setDuplicateStudentFilter={setDuplicateStudentFilter}
              duplicateInfoSection={duplicateInfoSection}
              setDuplicateInfoSection={setDuplicateInfoSection}
            />
          </>
        }
      />


      {/* Archive section */}
      <DraggableDialog
        openDialog={openDialogArchive}
        handleCloseDialog={handleCloseDialogArchive}
        handleConfirmCloseDialog={archiveHandler}
        title="Archive section"
        body="Are you sure you want to archive this section?"
        ModalFooter={true}
        actionButton="Archive"
      />

      {/* Unarchive section */}
      <DraggableDialog
        openDialog={openDialogUnarchive}
        handleCloseDialog={handleCloseDialogUnarchive}
        handleConfirmCloseDialog={unarchiveHandler}
        title="Unarchive section"
        body="Are you sure you want to unrchive this section?"
        ModalFooter={true}
        actionButton="UnArchive"
      />
      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirm={deleteHandler}
      /> */}

      {/* Delete section*/}
      <DraggableDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        handleConfirmCloseDialog={deleteHandler}
        title="Delete section"
        body={<div>Are you sure you want to delete this section?<br /> Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />

    </>
  );
};

export default Sections;
