
import React from "react";
import SideBarMenu from './SidebarMenu';

function Sidebar(props) {



  return (
    <div>
      <SideBarMenu
        collapsed={props.collapsed}
        ParentStudentRoutes={props.ParentStudentRoutes}
        SchoolRoute={props.SchoolRoute}
        LayoutRightSection={props.LayoutRightSection}
      />
    </div>
  );
}

export default Sidebar;




























// import React from "react";
// import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import SendIcon from '@mui/icons-material/Send';
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
// import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
// import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
// import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
// import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
// import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
// import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
// import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
// import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
// import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
// import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
// import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded';
// import SettingsIcon from '@mui/icons-material/Settings';
// import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';




// function Sidebar(props) {



//   return (
//     <div className="SidebarMenu">

//       <MenuList>
//         {/* <MenuItem>
//           <ListItemIcon>
//             <DashboardRoundedIcon />
//           </ListItemIcon>
//           <a href="#"> Home</a>
//         </MenuItem> */}

//         {/* <MenuItem>
//           <ListItemIcon>

//             < NotificationsRoundedIcon />
//           </ListItemIcon>
//           <a href="#">Alerts</a>
//         </MenuItem>

//         <MenuItem>
//           <ListItemIcon>
//             <ForumRoundedIcon />

//           </ListItemIcon>
//           <a href="#">Messages</a>
//         </MenuItem> */}


//         <MenuItem>
//           <a href="/courses-listing">
//             <ListItemIcon>
//               <MenuBookRoundedIcon />
//             </ListItemIcon>
//             <div>Courses</div>
//           </a>
//         </MenuItem>

//         <MenuItem>
//           <a href="/users">
//             <ListItemIcon>
//               <PersonRoundedIcon />
//             </ListItemIcon>
//             <div>Users</div>
//           </a>
//         </MenuItem>

//         <MenuItem>
//           <a href="/tracks">
//             <ListItemIcon>
//               <InsightsRoundedIcon />
//             </ListItemIcon>
//             <div>Tracks</div>
//           </a>
//         </MenuItem>

//         <MenuItem>
//           <a href="/media-library-table-view">
//             <ListItemIcon>
//               <CollectionsRoundedIcon />
//             </ListItemIcon>
//             <div>Media</div>
//           </a>
//         </MenuItem>

//         {/* <span className="divider"></span> */}

//         {/* <MenuItem>
//           <ListItemIcon>
//             <GradeRoundedIcon />
//           </ListItemIcon>

//           <a href="#">Grade</a>
//         </MenuItem> */}

//         {/* <MenuItem>
//           <a href="/categories-listing">
//             <ListItemIcon>
//               <CategoryRoundedIcon />
//             </ListItemIcon>
//             <div>Categories</div>
//           </a>
//         </MenuItem> */}


//         {/* <MenuItem>
//           <ListItemIcon>
//             <FavoriteRoundedIcon />
//           </ListItemIcon>
//           <a href="/featured-listing">Featured</a>
//         </MenuItem>  */}

//         {/* <MenuItem>
//           <a href="/instructors-listing">
//             <ListItemIcon>
//               <GroupsRoundedIcon />
//             </ListItemIcon>
//             <div>Team</div>
//           </a>
//         </MenuItem> */}

//         <span className="divider"></span>

//         {/* <MenuItem>
//           <ListItemIcon>
//             <ReceiptLongRoundedIcon />
//           </ListItemIcon>
//           <a href="#">Finance</a>
//         </MenuItem> */}

//         <MenuItem>
//           <a href="/coupons">
//             <ListItemIcon>
//               <DiscountRoundedIcon />
//             </ListItemIcon>
//             <div>Coupons</div>
//           </a>
//         </MenuItem>


//         {/* <MenuItem>
//           <a href="/coupons">
//             <ListItemIcon>
//               <SettingsIcon />
//             </ListItemIcon>
//             <div>Settings</div>
//           </a>
//         </MenuItem> */}

//         {/* <MenuItem>
//           <ListItemIcon>
//             <FormatQuoteRoundedIcon />
//           </ListItemIcon>
//           <a href="#">Review</a>
//         </MenuItem> */}

//         {/* <span className="divider"></span>
//         <MenuItem>
//           <ListItemIcon>
//             <SendIcon fontSize="small" />
//           </ListItemIcon>
//           <a href="/org-details">School Courses Details</a>
//         </MenuItem>

//         <MenuItem>
//           <ListItemIcon>
//             <SendIcon fontSize="small" />
//           </ListItemIcon>
//           <a href="/table">Table</a>
//         </MenuItem> */}


//       </MenuList>





//     </div>
//   );
// }


// export default Sidebar;

