import React from "react";
import { ImQrcode } from "react-icons/im";
import Buttons from "../../Form/Button/Button";
import cardImg from "../../../assets/imgs/tableImg.png";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { IconButton } from "@mui/material";
import "./GenerateQR.css";

const GenerateQR = () => {
  return (
    <>
      <Buttons
        label="GENERATE QR CODE"
        icon={<ImQrcode />}
        buttonColor="#FFB606"
        // border="#E4E8EC 1px solid"
        borderRadius="8px"
        color="#000000"
        width=""
        height="40px"
        fontSize="14px"
        // link=""
        // onClick={}
      />
      <div>
        <div className="accordionItemCard">
          <div className="cardInfo">
            <img src={cardImg} alt="cardImg" />
            <p>000022-shayan-mahmud-07202023</p>
          </div>
          <IconButton>
            <DeleteForeverRoundedIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default GenerateQR;
