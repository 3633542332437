import React, { useEffect, useState } from 'react';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import '../../../assets/css/users.css';

import SelectUsers from './SelectUsers';
import { useAddAttendanceMutation, useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import { validateFormData } from '../../../utils/form';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import dayjs from 'dayjs';
import Datefield from '../../../components/Form/Datefield/Datefield';
import { Typography } from '@mui/material';
import { codePreview } from '@uiw/react-md-editor';

const AddAttendance = ({ closeDrawer, attendanceAll, filterDate, roles }) => {
  const [addAttendance, addAttendanceOptions] = useAddAttendanceMutation();
  const {
    data: attendanceCodeResponse,
    isLoading: isLoadingAttendanceCode,
    error: attendanceCodeError,
  } = useGetAllAttendanceCodesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useProtected(addAttendanceOptions.error);
  useProtected(attendanceCodeError);

  const [age, setAge] = React.useState('');

  const [userIdList, setUserIdList] = useState([]);
  const [userIdListJust, setUserIdListJust] = useState([]);
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({
    userIdList: '',
    status: '',
    reason: '',
    comment: '',
  });
  const [selectedCode, setSelectedCode] = useState();
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState();
  const [attendanceCodeData, setAttendanceCodeData] = useState();


  const convertAttendanceCodeResponseToSelectItem = (codes) => {
    const defaultOptions = [];
    codes.map((code) => {
      defaultOptions.push({ label: `${code.code.charAt(0).toUpperCase()}${code.code.slice(1)} - ${code.description.charAt(0).toUpperCase()}${code.description.slice(1)}`, value: code.code })
    })
    let temp = defaultOptions;
    // if (codes.length > 0) {
    //   temp = [...temp, ...codes.map((item) => ({ label: item.code, value: item._id }))]
    // }
    return temp;
  };

  useEffect(() => {
    console.log(attendanceCodeResponse)
    if (!isLoadingAttendanceCode && !attendanceCodeError) {
      setAttendanceCodeOptions(
        convertAttendanceCodeResponseToSelectItem(
          attendanceCodeResponse.body.data,
        ),
      );
      setAttendanceCodeData(attendanceCodeResponse.body.data)
      // setAttendanceCodeOptions(prev => [...prev, ...convertAttendanceCodeResponseToSelectItem(
      //   attendanceCodeResponse.body.data,
      // )])
    }
  }, [attendanceCodeResponse, isLoadingAttendanceCode, attendanceCodeError]);

  const handleChanges = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    if (e.target.name === 'status') {
      if (attendanceCodeData?.length > 0) {
        setSelectedCode(attendanceCodeData?.find(item => item?.code === e.target.value))
      }
    }
    setState(e.target.value);
  };

  // const attendance = [
  //   {
  //     label: 'Present',
  //     value: 'Present',
  //   },

  //   {
  //     label: 'Absent',
  //     value: 'Absent',
  //   },
  // ];

  const Reason = [
    {
      label: 'Medical',
      value: 'Medical',
    },

    {
      label: 'Family Emergency',
      value: 'Family Emergency',
    },
    {
      label: 'Transportation',
      value: 'Transportation',
    },
  ];

  const [OpenSelectUsers, setSelectUsers] = useState(false);
  const handleOpenSelectUsers = () => setSelectUsers(true);
  const handleCloseSelectUsers = () => setSelectUsers(false);
  const [startDate, setStartDate] = useState('')
  const handleCloseDrawer = () => {
    closeDrawer();
  };
  useEffect(() => {
    if (filterDate) {
      setStartDate(`${filterDate.$y}-${String(filterDate.$M + 1).padStart(2, '0')}-${String(filterDate.$D).padStart(2, '0')}`)
    }
  }, [filterDate])
  // useEffect(() => {
  //   console.log(startDate, 328)
  // }, [startDate])
  const handleAddAttendance = async () => {
    let today = new Date()
    let startDateObj = new Date(startDate);
    if (startDateObj > today) {
      return
    }

    let data = {
      userIdList,
      status,
      reason,
      comment,
      startDate
    };

    let errorObj;
    if (status !== 'P') {
      errorObj = {
        userIdList: 'At least one user required',
        status: 'Enter Attendance',
        // reason: 'Enter Absent reason',
        // comment: 'Enter comment',
      };
    } else {
      errorObj = {
        userIdList: 'At least one user required',
        status: 'Enter Attendance',
      };

    }

    const isValid = validateFormData(data, setErrors, errorObj);
    // console.log(isValid, status);
    if (!isValid) {
      return;
    }

    const result = await addAttendance(data);

    if (result?.data) {
      toast.success('Attendance added successfully');
      setUserIdList([]);
      setUserIdListJust([]);
      setStatus('');
      setReason('');
      setComment('');
      handleCloseDrawer();
    } else {
      toast.error(result?.data?.message);
    }
  };

  const selectUsers = (userList) => {
    const newList = userList.map((item) => ({
      _id: item?._id || '',
      name: `${item?.firstName || ''} ${item?.lastName || ''}`,
      userType: item?.userType || '',
      userImage: item?.userImage || '',
      userUniqueID: item?.userUniqueID || '',
    }));
    setUserIdList(newList);
    setUserIdListJust(userList.map(item => item?._id || ''))
  };
  // find only admin
  // const onlyAdmin = roles?.find((admin) => admin?.name === "admin")


  return (
    <>
      <>
        <div className="">
          {addAttendanceOptions?.isLoading ? (
            <Loader
              height="80px"
              width="80px"
              borderWidth="8px"
              isFullWidth={true}
              isOverlay={true}
            />
          ) : (
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Datefield
                  label="Select date"
                  value={dayjs(filterDate)}
                  disableFuture={true}
                  CustomIcon={true}
                  // disabled={onlyAdmin ? false : true}
                  dateFormat="DD-MMM-YYYY"
                  handleChange={(e) => setStartDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                />
              </Grid>

              <Grid item md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography variant='span' sx={{ color: '#1B1B1F !important', fontWeight: '600' }}>Attendees</Typography>
                <div className="AddField" onClick={() => handleOpenSelectUsers()}>
                  <Button
                    href="#contained-buttons"
                  >
                    <div className="AddIcon">
                      <AddRoundedIcon />
                    </div>{' '}
                    <span style={{ textTransform: "initial" }}>Select users</span>
                  </Button>
                  <div className={`UserCount ${userIdList.length ? 'activeCount' : 'inactiveCount'}`}>
                    {userIdList.length ? userIdList.length : 0}
                  </div>
                </div>
                {(errors?.userIdList && userIdList.length === 0) && <span style={{ color: "#d32f2f", fontSize: '12px' }}>Please select at least one user</span>}
              </Grid>

              <Grid item md={12} xs={12}>
                <SelectField
                  FieldLabel={true}
                  label="Select attendance"
                  selectedOption={status}
                  // value={status}
                  options={attendanceCodeOptions}
                  handleChange={(e) => handleChanges(e)(setStatus)}
                  name={'status'}
                  helperText={errors?.status}
                  error={Boolean(errors?.status)}
                />
              </Grid>

              {((status?.toLowerCase() !== 'p') && status?.toLowerCase() !== '') && (
                <>
                  <Grid item md={12} xs={12}>
                    <SelectField
                      FieldLabel={true}
                      label="Reason"
                      // selectedOption={'Medical'}
                      // value={'Medical'}
                      selectedOption={reason}
                      options={Reason}
                      handleChange={(e) => handleChanges(e)(setReason)}
                      name={'reason'}
                      helperText={errors?.reason}
                      error={Boolean(errors?.reason)}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextArea
                      FieldLabel={true}
                      TextArea={true}
                      label="Comment"
                      handleChange={(e) => handleChanges(e)(setComment)}
                      name={'comment'}
                      errorMsg={errors?.comment}
                      value={comment}
                      innerCounter
                      count={100}

                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </div>

        <DrawerFooterActions
          isLoading={addAttendanceOptions.isLoading}
          handleClose={handleCloseDrawer}
          handleAction={handleAddAttendance}
          closeBtnLabel={'Cancel'}
          mobileCloseBtnLabel={'Mobile Close'}
          actionButtonLabel={'Save'}
        />

        {/* new user drawer */}
        <SideDrawer
          open={OpenSelectUsers}
          handleDrawerClose={handleCloseSelectUsers}
          title="Select Users"
          buttonLabel="Save"
          BackArrow={true}
          CloseArrowHide={true}
          // buttonLink={}
          sideOpen="right"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={false}
          padding="0"
          body={
            <>
              <SelectUsers
                selectUsers={selectUsers}
                handleCloseSelectUsers={handleCloseSelectUsers}
                selectedList={userIdList}
                selectedListJust={userIdListJust}
                renderFullItem={true}
                attendanceAll={attendanceAll}
              />
            </>
          }
        />
      </>
    </>
  );
};

export default AddAttendance;
