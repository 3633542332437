import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material'

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import TextField from "../../../components/Form/TextField/TextField";
import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';
import { toast } from 'react-toastify';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import { useImportFileGetColumnsMutation, useImportFileGetDataMutation } from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';


export const ImportUsers = (props) => {
    const { matchColumns,
        setMatchColumns,
        importedUsers,
        setImportedUsers,
        setActiveTab,
        setImportUserDrawer, selectedUserType, setSelectedUserType, setEmailIndex, setFirstNameIndex, setMiddleNameIndex, setLastNameIndex
    } = props
    const {
        data: userTypeData,
        isLoading: userTypeIsLoading,
        isFetching: userTypeIsFetching,
        error: userTypeError
    } = useGetUserTypesQuery()

    const [getData, getDataOptions] = useImportFileGetDataMutation()
    const [getColumnsData, getColumnsDataOptions] = useImportFileGetColumnsMutation()


    useProtected(userTypeError)
    useProtected(getDataOptions.error)
    useProtected(getColumnsDataOptions.error)



    const userType = [
        {
            label: "Student",
            value: "Student",
        },

        {
            label: "Parent",
            value: "Parent",
        },

        {
            label: "Faculty",
            value: "Faculty",
        },

        {
            label: "Staff",
            value: "Staff",
        },

    ]




    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };
    const [basicInfoSectionExpanded, setBasicInfoSectionExpanded] = useState(true)
    const [importSectionExpanded, setImportSectionExpanded] = useState(false)
    const [columnMatchSectionExpanded, setColumnMatchSectionExpanded] = useState(false)

    const [userTypes, setUserTypes] = useState([])
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedAttachmentToDelete, setSelectecAttachmentToDelete] = useState('')
    const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
    const [attachmentData, setAttachmentData] = useState([]);
    const [openNewMedia, setOpenNewMedia] = useState(false);
    const [openSelectMedia, setOpenSelectMedia] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState(false);
    const [mediaLibError, setMediaLibError] = useState(false)
    const [mediaItem, setMediaItem] = useState({});
    const [loading, setLoading] = React.useState(false);
    const [userTypeOptions, setUserTypeOptions] = useState([])
    // const [selectedUserType, setSelectedUserType] = useState('');
    const [errors, setErrors] = useState({
        selectedUserType: ''
    })




    useEffect(() => {
        if (!userTypeIsLoading && !userTypeIsFetching) {
            setUserTypes(userTypeData?.body?.data)
        }
    }, [userTypeData, userTypeIsLoading, userTypeIsFetching])

    useEffect(() => {
        setUserTypeOptions(getPreparedArray(userTypes || [], 'name', 'name')) //label, value
    }, [userTypes])

    const getPreparedArray = (data, lab, val) => {
        return data?.map((item) => ({
            value: item[val],
            label: capitalizeFirstLetter(item[lab]),
        }));
    };






    //*****for media library****** */
    const handleDrawerCloseSelectMedia = () => {
        setSelectedValue(false)
        setMediaItem({})
        setOpenSelectMedia(false);
    };
    const handleDrawerOpenNewMedia = () => {
        setOpenNewMedia(true);
    };


    //========= add media item =============//
    const clickSelectMediaItem = async () => {
        if (Object.keys(mediaItem).length != 0) {
            if (mediaItem.mimeType != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                toast.error("Please select valid file")
                return false
            }
            setSelectedValue(false)
            setOpenSelectMedia(false);
        } else {
            toast.error("No item is selected")
        }
    };
    //========= add media item =============//

    const handleCloseDeleteDialog = () => {
        setOpenDialog(false);
    };

    const getMediaItem = (item) => {
        // setting the media item
        setMediaItem(item);
    };

    const handleMediaUploadClose = () => {
        setOpenNewMedia(false);
    };

    const handleOpenNewMedia = (status) => {
        setOpenNewMedia(!status);
        handleDrawerOpenSelectMedia(status);
    };
    const handleDrawerOpenSelectMedia = () => {
        setOpenSelectMedia(true);
        setActiveLogoIcon(!isActiveLogoIcon);
    };

    const deleteAttachmentHandler = async () => {
        const deletedFilterData = attachmentData.filter((datas, index) => index !== selectedAttachmentToDelete)
        setAttachmentData(deletedFilterData)
        toast.success('Attachment deleted successfully');
        setOpenDialog(false)
        setSelectecAttachmentToDelete('')
        handleCloseDeleteDialog()
    }

    // const onChangeHandler = (val, e, fieldName) => {
    //     let newObject = { ...messageObject, [fieldName]: e.target.value }
    //     setMessageObject(newObject)
    // }
    const [options, setOptions] = useState([])
    // const capitalizeWords = (str) => {
    //     const words = str.split(/\s+/); // Split at spaces
    //     return words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join(' ');
    // };

    const uploadExcelFileHandler = async (e) => {
        if (Object?.keys(mediaItem)?.length === 0) {
            setMediaLibError(true)
            return false
        }

        try {
            let result1 = await getColumnsData({ fileUrl: mediaItem.url });
            let arrayLoopIndex = 0;
            if (result1?.data?.body?.data) {
                // const optionsData = result1.data.body.data.map(column => ({
                //     label: capitalizeWords(column),
                //     value: capitalizeWords(column),
                // }));
                const optionsData = result1.data.body.data.map((column, index) => ({
                    label: (column),
                    value: (index),
                }));

                setOptions(optionsData)
                let result = await getData({ fileUrl: mediaItem.url })
                if (result?.data && result?.data?.body?.data && !result?.data?.error) {
                    const filterDatas = result?.data?.body?.data?.filter((user) => user?.length !== 0)
                    setMediaLibError(false)
                    setImportedUsers(filterDatas)
                    setBasicInfoSectionExpanded(false)
                    setImportSectionExpanded(false)
                    setColumnMatchSectionExpanded(true)
                    handleChange('panel3')
                } else {
                    toast.error('Something went wrong')
                }

            }

        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    const setUserTypeHandler = (e) => {
        if (selectedUserType == "") {
            setErrors({
                selectedUserType: "Required"
            })
        } else {
            setBasicInfoSectionExpanded(false)
            setImportSectionExpanded(true)
            setColumnMatchSectionExpanded(false)
            handleChange('panel2')
        }
    }
    const setSection = (section) => {
        if (section == "1") {
            setBasicInfoSectionExpanded(true)
            setImportSectionExpanded(false)
            setColumnMatchSectionExpanded(false)
            handleChange('panel1')
        }
        if (section == "2") {
            setBasicInfoSectionExpanded(false)
            setImportSectionExpanded(true)
            setColumnMatchSectionExpanded(false)
            handleChange('panel2')
        }
        if (section == "3") {
            setBasicInfoSectionExpanded(false)
            setImportSectionExpanded(false)
            setColumnMatchSectionExpanded(true)
            handleChange('panel3')
        }
    }

    //==================================== match columns part start  =====================//
    const [emailStatus, setEmailStatus] = useState("")
    const [firstNameStatus, setfirstNameStatus] = useState("")
    const [middleNameStatus, setmiddleNameStatus] = useState("")
    const [lastNameStatus, setlastNameStatus] = useState("")

    const [matchColumnsErrors, setMatchColumnsErrors] = useState({
        emailError: '',
        firstNameError: "",
        lastNameError: "",
    })
    const handleClose = () => {
        setLoading(false);
    };


    //================== get data in the array part start ================================//
    const getDatas = (value) => {
        let newMatchColumns = [...matchColumns];
        newMatchColumns.push(value);
        setMatchColumns(newMatchColumns);
    }
    useEffect(() => {
        if (emailStatus) {
            getDatas(emailStatus)
        }
    }, [emailStatus])
    useEffect(() => {
        if (firstNameStatus) {
            getDatas(firstNameStatus)
        }
    }, [firstNameStatus])
    useEffect(() => {
        if (middleNameStatus) {
            getDatas(middleNameStatus)
        }
    }, [middleNameStatus])
    useEffect(() => {
        if (lastNameStatus) {
            getDatas(lastNameStatus)
        }
    }, [lastNameStatus])
    //================== get data in the array part end ================================//

    //====================================== get the indexes number ============================//
    useEffect(() => {
        const emailIndexInArray = matchColumns.indexOf('Email');
        setEmailIndex(emailIndexInArray);
        const firstNameIndexInArray = matchColumns.indexOf('First Name');
        setFirstNameIndex(firstNameIndexInArray);
        const middleNameIndexInArray = matchColumns.indexOf('Middle Name');
        setMiddleNameIndex(middleNameIndexInArray);
        const lastNameIndexInArray = matchColumns.indexOf('Last Name');
        setLastNameIndex(lastNameIndexInArray)
    }, [matchColumns]);
    //====================================== get the indexes number ============================//


    const checkBasicFormField = () => {
        let errorObject = {
            ...matchColumnsErrors
        }
        let error = 0
        if (emailStatus === "") {
            errorObject = {
                ...errorObject, emailError: 'You must match an email address column to import'
            }
            error++
        } else {
            errorObject = {
                ...errorObject, emailError: ''
            }
        }
        if (firstNameStatus === "") {
            errorObject = {
                ...errorObject, firstNameError: "You must match a first name column to import"
            }
            error++
        } else {
            errorObject = {
                ...errorObject, firstNameError: ''
            }
        }
        if (lastNameStatus === "") {
            errorObject = {
                ...errorObject, lastNameError: "You must match a last name column to import"
            }
            error++
        } else {
            errorObject = {
                ...errorObject, lastNameError: ''
            }
        }
        setMatchColumnsErrors({ ...matchColumnsErrors, ...errorObject })
        return (error > 0) ? false : true
    }

    const handleImport = () => {
        const isCheckBasicFormField = checkBasicFormField()
        if (!isCheckBasicFormField) {
            return;
        }
        setLoading(true);
        setActiveTab(1)
        setImportUserDrawer(false)
        // console.log(emailStatus, firstNameStatus, middleNameStatus, lastNameStatus, 333)
        setEmailIndex(emailStatus)
        setFirstNameIndex(firstNameStatus)
        setMiddleNameIndex(middleNameStatus)
        setLastNameIndex(lastNameStatus)
        setMatchColumns([])

    };
    //==================================== match columns part end  =====================//
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div className='CollapsForm'>
                        {/* ===================== User Type ==================== */}
                        <Accordion expanded={basicInfoSectionExpanded} onChange={handleChange('panel1')}>
                            <AccordionSummary>
                                <div className='AccLabel'>User Type</div>
                                <div className='AccIcon'>
                                    {!basicInfoSectionExpanded && (<Button
                                        className='EditButton'
                                        variant="text"
                                        onClick={() => setSection('1')}>EDIT</Button>)}

                                    {basicInfoSectionExpanded ? <FiberManualRecordRoundedIcon className='defaultIcon' /> : <CheckCircleRoundedIcon className='activeIcon' />}
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container spacing={1}>

                                    <Grid item md={12} xs={12}>
                                        <div className='FormFieldContainer'>
                                            <div className='FloatLabelCol'>User Type<span className='requiredAsterisk'>*</span></div>
                                            <div className='FormFieldCol'>
                                                <SelectField
                                                    label="Select user type"
                                                    name="email"
                                                    options={userTypeOptions}
                                                    defaultValue="default"
                                                    selectPlaceholder="Select user type"
                                                    selectedOption={selectedUserType}
                                                    // handleChange={handleChanges}
                                                    handleChange={(e) => setSelectedUserType(e.target.value)}
                                                    helperText={errors?.selectedUserType}
                                                    error={errors?.selectedUserType}
                                                />
                                            </div>
                                        </div>
                                        {/* <div>   
                                            <Select
                                                defaultValue="default"
                                                sx={{
                                                    '& input[value="default"] ~ .MuiSelect-select': {
                                                        color: "#CCC",
                                                    },
                                                }}
                                            >
                                                <MenuItem value="default" sx={{ display: "none" }}>
                                                    Filter users
                                                </MenuItem>
                                                <MenuItem value="allUsers">All users</MenuItem>
                                                <MenuItem value="blockedUsers">Blocked users</MenuItem>
                                            </Select>
                                        </div> */}
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Buttons
                                            label="Continue"
                                            buttonColor="#0450E1"
                                            border="#0450E1 1px solid"
                                            color="#fff"
                                            width="100%"
                                            height="40px"
                                            fontSize="14px"
                                            fontWeight="700"
                                            onClick={(e) => setUserTypeHandler(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {/* ===================== Upload an Excel File ==================== */}
                        <Accordion expanded={importSectionExpanded} onChange={handleChange('panel2')}>
                            <AccordionSummary>
                                <div className='AccLabel' style={{ color: `${basicInfoSectionExpanded ? "rgba(27, 27, 31, 0.38)" : "black"}` }}>Upload an Excel File</div>
                                <div className='AccIcon'>

                                    {!basicInfoSectionExpanded && !importSectionExpanded && (<Button
                                        className='EditButton'
                                        variant="text"
                                        onClick={() => setSection('2')}>EDIT</Button>)}

                                    {basicInfoSectionExpanded || importSectionExpanded ? <FiberManualRecordRoundedIcon className='defaultIcon' /> : <CheckCircleRoundedIcon className='activeIcon' />}
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: '16px' }}>
                                        <p>
                                            Make sure your Excel file has the columns in the following format: Email, First Name, Middle
                                            Name,  Last Name, or &nbsp;
                                            <a href="https://web-assets.deenschool.io/assets/data/ds-import-users.xlsx" style={{
                                                display: 'inline-block',
                                                lineHeight: 'none',
                                                p: "0px !important", m: 0,
                                                fontSize: '16px',
                                                textTransform: 'initial !important',
                                                color: "#1976d2",
                                                textDecoration: 'none',
                                                transition: 'background-color 0.3s ease',
                                                ":hover": {
                                                    backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                                },
                                            }} download>download a sample Excel file.</a>

                                            {/* <Button
                                                variant='text'
                                                sx={{
                                                    display: 'inline-block',
                                                    lineHeight: 'none',
                                                    p: "0px !important", m: 0,
                                                    fontSize: '16px',
                                                    textTransform: 'initial !important',
                                                }}>download a sample Excel file.</Button> */}
                                        </p>
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <div class="FieldLabel">Excel File<span>*</span></div>
                                        {(Object.keys(mediaItem).length === 0) && (
                                            <>
                                                <Buttons
                                                    label="Add excel file"
                                                    buttonColor=""
                                                    color="#0450E1"
                                                    width="100%"
                                                    height="48px"
                                                    fontSize="14px"
                                                    fontWeight="700"
                                                    // onClick={() => {
                                                    //   setOpen(false);
                                                    //   setImageFromCropper(null)
                                                    //   setImageFromCropperSrc(null)
                                                    //   setImageFromCropperName("")
                                                    // }
                                                    // }
                                                    onClick={(e) => handleDrawerOpenSelectMedia()}
                                                    border={mediaLibError ? `#BA1A1A 1px solid` : `#E4E2E6 1px solid`}
                                                    textTransform="uppercase"
                                                    icon={<AddRoundedIcon />}
                                                />
                                                <Typography
                                                    variant='span'
                                                    sx={{
                                                        color: `${mediaLibError ? `#BA1A1A` : ``}`,
                                                        display: `${mediaLibError ? 'block' : 'none'}`
                                                    }}>Upload an Excel file to continue</Typography>
                                            </>
                                        )

                                        }
                                    </Grid>


                                    {(Object.keys(mediaItem).length > 0) && <Grid item xs={12} md={12}>
                                        <div>
                                            <div className='attachmentCardContainer' style={{ marginBottom: '10px' }}>
                                                {/* {console.log(attachment)} */}
                                                <div className='attachmentIcon'>
                                                    <AttachmentRoundedIcon />
                                                </div>
                                                <div className='attachmentFileInfo' style={{ flex: '1 1' }}>
                                                    <p>{mediaItem?.name}</p>
                                                    <p style={{ fontSize: "12px" }}>
                                                        {(mediaItem?.size) && <span>{(mediaItem?.size / 1024).toFixed(3)} MB</span>}
                                                        &nbsp;{(mediaItem?.size) && <FiberManualRecordRoundedIcon fontSize='small' sx={{ color: '#D9D9D9', width: '8px', height: '8px' }} />}&nbsp;
                                                        {(mediaItem?.size) && <span>{mediaItem?.originalFileName}</span>}
                                                    </p>
                                                </div>
                                                <div className='attachmentDeleteBtn'>
                                                    <IconButton onClick={(e) => setMediaItem({})}>
                                                        <DeleteForeverRoundedIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>}
                                    <Grid item md={12} xs={12}>
                                        <Buttons
                                            label="Continue"
                                            buttonColor="#0450E1"
                                            border="#0450E1 1px solid"
                                            color="#fff"
                                            width="100%"
                                            height="40px"
                                            fontSize="14px"
                                            fontWeight="700"
                                            onClick={(e) => uploadExcelFileHandler(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {/* ===================== Match Columns ==================== */}
                        <Accordion expanded={columnMatchSectionExpanded} onChange={handleChange('panel3')}>
                            <AccordionSummary>
                                <div className='AccLabel' style={{ color: `${(basicInfoSectionExpanded || importSectionExpanded) ? "rgba(27, 27, 31, 0.38)" : "black"}` }}>Match Columns</div>
                                <div className='AccIcon'>
                                    {!basicInfoSectionExpanded && !importSectionExpanded && !columnMatchSectionExpanded && (<Button
                                        className='EditButton'
                                        variant="text"
                                        onClick={() => setSection('3')}>EDIT</Button>)}

                                    {basicInfoSectionExpanded || importSectionExpanded || columnMatchSectionExpanded ? <FiberManualRecordRoundedIcon className='defaultIcon' /> : <CheckCircleRoundedIcon className='activeIcon' />}

                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: '16px' }}>
                                        <p>
                                            {importedUsers?.length} user{importedUsers?.length > 1 ? "s" : ""} were recognized in this file. Match column labels to user information
                                        </p>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <div className='FormFieldContainer'>
                                            <div className='FloatLabelCol'>Email</div>
                                            <div className='FormFieldCol'>
                                                <SelectField
                                                    label="Email"
                                                    name="Email"
                                                    options={options}
                                                    selectedOption={emailStatus}
                                                    handleChange={(e) => setEmailStatus(e.target.value)}
                                                    helperText={emailStatus.length === 0 ? matchColumnsErrors?.emailError : ''}
                                                    error={emailStatus.length === 0 ? matchColumnsErrors?.emailError : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className='FormFieldContainer'>
                                            <div className='FloatLabelCol'>First Name</div>
                                            <div className='FormFieldCol'>
                                                <SelectField
                                                    label="First Name"
                                                    name="First Name"
                                                    options={options}
                                                    selectedOption={firstNameStatus}
                                                    handleChange={(e) => setfirstNameStatus(e.target.value)}
                                                    helperText={firstNameStatus.length === 0 ? matchColumnsErrors?.firstNameError : ''}
                                                    error={firstNameStatus.length === 0 ? matchColumnsErrors?.firstNameError : ''}
                                                />
                                            </div>
                                        </div>
                                        <div className='FormFieldContainer'>
                                            <div className='FloatLabelCol'>Middle Name</div>
                                            <div className='FormFieldCol'>
                                                <SelectField
                                                    label="Middle Name"
                                                    name="Middle Name"
                                                    options={options}
                                                    selectedOption={middleNameStatus}
                                                    handleChange={(e) => setmiddleNameStatus(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='FormFieldContainer'>
                                            <div className='FloatLabelCol'>Last Name</div>
                                            <div className='FormFieldCol'>
                                                <SelectField
                                                    label="Last Name"
                                                    name="Last Name"
                                                    options={options}
                                                    selectedOption={lastNameStatus}
                                                    handleChange={(e) => setlastNameStatus(e.target.value)}
                                                    helperText={lastNameStatus.length === 0 ? matchColumnsErrors?.lastNameError : ''}
                                                    error={lastNameStatus.length === 0 ? matchColumnsErrors?.lastNameError : ''}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>

                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Buttons
                                            label="Import"
                                            buttonColor="#0450E1"
                                            border="#0450E1 1px solid"
                                            color="#fff"
                                            width="100%"
                                            height="40px"
                                            fontSize="14px"
                                            fontWeight="700"
                                            onClick={handleImport}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid >

            {/* ====================== Attachment field start ====================== */}


            <SideDrawer
                title="Media Library"
                ButtonLabel="Add"
                //clickSaveButton={clickSaveButton}
                cancelButtonLabel="Cancel"
                sideOpen="right"
                open={openSelectMedia}
                handleDrawerClose={handleDrawerCloseSelectMedia}
                FooterWithoutTab={true}
                LeftButton={true}
                ButtonLeft="Upload New"
                ButtonLeftIcon={< FileUploadOutlinedIcon />}
                clickButtonLeft={handleDrawerOpenNewMedia}
                clickSaveButton={clickSelectMediaItem}
                padding="0"
                body={
                    < MediaLibrarySelectMedia
                        onClickRadioIcon={setSelectedValue}
                        onItemSelected={getMediaItem}
                    />
                }
            />

            <Externaluse
                open={openNewMedia}
                handleCloseButton={handleMediaUploadClose}
                handleOpenNewMedia={(handleOpenNewMedia)}
            />



            {/* --------- loading -------------- */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
