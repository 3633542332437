import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import './Datefield.css';
import { CalendarIcon } from '../../../assets/imgs/icons/calendarIcon';

function Datefield(props) {
  const {
    label,
    required,
    validation,
    error,
    placeholder,
    value,
    handleChange,
    disablePast,
    helperText,
    disabled,
    disableFuture,
    dateFormat = "MM/DD/YYYY",
    errorData
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState('');

  // useEffect(() => {
  //   if (required || validation) {
  //     console.log(value);

  //     if (!value) {
  //       setValidationError(true);
  //       setValidationText('Required');
  //     } else {
  //       setValidationError(false);
  //       setValidationText('');
  //     }
  //   }
  // }, [value]);

  const CustomIcon = props.CustomIcon ? {
    openPickerIcon: CalendarIcon
  } : {}

  return (
    <div className="Datefield">
      {props.label && (
        <div className="FieldLabel">
          {label} {validation && <span>*</span>}
        </div>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DesktopDatePicker defaultValue={dayjs('2022-04-17')} /> */}
        <DesktopDatePicker
          icon={<AcUnitIcon />}
          label=""
          inputFormat="MM/DD/YYYY"
          format={dateFormat}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params}
          // error={error || validationError}
          />}
          slotProps={{
            textField: {
              helperText: `${helperText || validationText}`,
              error: errorData
              // error: `${error || validationError}`
            }
          }}
          slots={CustomIcon}
          disablePast={disablePast ? true : false}
          disabled={disabled}
          disableFuture={disableFuture}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Datefield;
