import React, { useEffect, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import TextAreaCustom from '../../../../components/Form/TextArea/TextAreaCustom';



const Overview = (props) => {
    let { feeObject, setFeeObject, feeErrorObject, setFeeErrorObject } = props


    const onChangeHandler = (val, e, fieldName) => {

        setFeeObject({ ...feeObject, [fieldName]: val })
        setFeeErrorObject({ ...feeErrorObject, [fieldName]: "" })
    }


    return (
        <>
            <div className='DrawerFormContainer'>

                {/* ==== Name Start ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: feeErrorObject?.name !== "" && '-28px' }}>
                        Name<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <TextField
                            value={feeObject?.name}
                            placeholder="Enter payment/fee name"
                            helperText={feeErrorObject?.name}
                            error={feeErrorObject?.name === "" ? false : true}
                            handleChange={(val, e) => onChangeHandler(val, e, 'name')}
                            count={50}
                            CharAlignMid="CharAlignMid"
                        />
                    </div>

                </div>
                {/* ==== Name End ===*/}


                {/* ==== Description Start ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel' style={{ marginTop: '12px' }}>
                        Description
                    </div>

                    <div className='FloatingFormField'>
                        <TextAreaCustom
                            value={feeObject?.description}
                            // placeholder="Enter payment/fee name"
                            handleChange={(e) => onChangeHandler(e.target.value, e, 'description')}
                            count={100}
                            CharAlignMid="CharAlignMid"
                        />
                    </div>

                </div>
                {/* ==== Description End ===*/}


            </div>

        </>
    );
};

export default Overview;
