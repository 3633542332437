import React, { useEffect, useState } from "react";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import '../../../assets/css/users.css';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Datefield from '../../../components/Form/Datefield/Datefield';
import TimePickerField from '../../../components/Form/TimePicker/TimePicker';
import dayjs from "dayjs";


const AddClock = (props) => {

  const { clockTime, setClockTime, setClockType, clockType, clockTypeError, setClockTypeError, attendanceDetails, clockInOutTimeError, OpenAttendanceDetails } = props





  return (
    <>
      <div>

        <Grid container spacing={3}>

          <Grid item md={12} xs={12}>
            <FormControl className="RedioWithBox">
              <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: '12px', fontWeight: 600, color: '#33415C' }}>Type</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(e) => setClockType(e.target.value)}
              // className={clockType === "clock_in" ? "selected-label" : ""}
              >
                <FormControlLabel value="clock_in" control={<Radio />} label="Clock in" className={clockType === "clock_in" ? "selected-label" : "unselected-label"} />
                <FormControlLabel value="clock_out" control={<Radio />} label="Clock out" className={clockType === "clock_out" ? "selected-label" : "unselected-label"} />
              </RadioGroup>
            </FormControl>
            {(clockTypeError) && <span style={{ color: '#BA1A1A', fontSize: "12px" }}>Select a type</span>}
          </Grid>

          <Grid item md={6} xs={12}>
            <div className="DateFieldContainer">
              <Datefield
                label="Select date"
                value={dayjs(new Date())}
                disabled={true}
                dateFormat="DD-MMM-YYYY"
                error={clockInOutTimeError}
              // helperText={clockInOutTimeError}
              />
            </div>
          </Grid>

          <Grid item md={6} xs={12} >
            <TimePickerField
              errorData={clockInOutTimeError}
              error="error"
              TextField={true}
              label="Select time"
              value={dayjs(`2022-04-17T${clockTime}`)}
              defaultValue={dayjs(`2022-04-17T${clockTime}`)} handleChange={(e) => setClockTime(`${e.$H}:${e.$m}`)}
              helperText={clockInOutTimeError}
            />
          </Grid>
        </Grid>
      </div>




    </>
  );
};

export default AddClock;
