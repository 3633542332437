import React, { useState, useEffect } from "react";

import './VerticalTab.css';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Buttons from '../../components/Form/Button/Button';
import Tooltip from '../../components/Form/Tooltip/Tooltip';

import Box from '@mui/material/Box';
import TabsMobile, { tabsClasses } from '@mui/material/Tabs';
import TabMobile from '@mui/material/Tab';

import StickyBox from "react-sticky-box";

function VerticalTab(props) {

  const { tabData, tabBody, tabExtraBody, } = props;

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (props.activeTab) {
      setValue(props.activeTab);
    }
  }, [props.activeTab]);

  const handleChange = (event, newValue) => {
    props.onTabSwitch(newValue);
    setValue(newValue);
  };


  // all commented code copy and past your page //

  // const [currentTab, setCurrentTab] = useState(0);
  // const handleTabSwitch = (activeTab) => {
  //   console.log(activeTab);
  //   setCurrentTab(activeTab);
  // }



  // const tabData = [
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  // ]

  // const tabBody = [
  //   {
  //     id: 0,
  //     panel: "dsfsdf",
  //   },
  //   {
  //     id: 1,
  //     panel: "dsfsdfdsfsdv",
  //   },
  //   {
  //     id: 2,
  //     panel: "dsfsddsvsdvsdvsdvf",
  //   },

  // ]

  // <VerticalTab 
  //       title="Introduction to Islamic Economics and Finance"
  //       name="MUNA Academy"
  //ButtonLabel=""
  //Button={true}
  //       link="#"
  //       tabData={tabData}
  //       tabBody={tabBody}
  //     />



  return (
    <div className="VerticalTab">

      <div className="TabListingContainer">
        <StickyBox offsetTop={120} offsetBottom={20}>
          <div className="TabTitle">
            <h1>{props.title}</h1>
            <p><a href={props.link} target="_blank" rel="noreferrer">{props.name}</a></p>
          </div>

          <div className="MobileViewTabs">
            <TabsMobile
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {tabData.map((item, i) => (
                <Tab
                  label={item.label}
                  // icon={item.icon} 
                  icon={value === i ? item.selectedIcon : item.icon}
                  {...a11yProps(i)} />

              ))}
            </TabsMobile>
          </div>



          <AppBar position="static" className="DekstopViewTabs">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              {tabData.map((item, i) => (
                <Tab
                  label={item.label}
                  // icon={item.icon} 
                  icon={value === i ? (item.selectedIcon ? item.selectedIcon : item.icon) : item.icon}
                  {...a11yProps(i)} />
              ))}
            </Tabs>
          </AppBar>


          {props.Button &&
            <div style={{ marginTop: "20px" }}>
              <Tooltip
                TooltipText={props.tooltipText}
                Tooltip={
                  <Buttons
                    label={props.ButtonLabel}
                    buttonColor="#004FE0"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    link={props.buttonLink}
                    target={props.buttonTarget}
                    onClick={props.buttonOnClick}
                    disabled={props.disabled}
                  />
                }
              />

            </div>
          }
        </StickyBox>
      </div>


      <div className="VerticalTabBody">
        {tabBody.map((item, i) => (
          <TabPanel value={value} index={item.id}>
            {props.alertBox}
            {item.panel}
          </TabPanel>
        ))}
        {tabExtraBody ? tabExtraBody : ""}
      </div>

    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default VerticalTab;

