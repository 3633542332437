import { Button, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import PhoneInputField from '../../../../components/Form/PhoneInputField/PhoneInputField';
import ComboBox from '../../../../components/Form/ComboBox/ComboBox';
import { countries } from "../../../../assets/data/countries";
import moment from "moment-timezone";
import { currencies } from '../../../../assets/data/currencies';
import GooglePlaceField from '../../../../components/Form/GooglePlaceField/GooglePlaceField';

const EditAccountDetails = (props) => {
    const {
        schoolInfo,
        setFormFieldsValue,
        formFieldsValue,
        setEinNumberError,
        einNumberError,
        nameError,
        setNameError,
        dbaError,
        setDbaError,
        emailError,
        setEmailError,
        countryError,
        setCountryError,
        checkRequiredNumber,
        manualAddress, setManualAddress, errors
    } = props

    const [countrySelect, setCountrySelect] = useState('');
    const [name, setName] = useState(schoolInfo?.name);
    const [dba, setDba] = useState(schoolInfo?.dba);
    const [einNumber, setEinNumber] = useState(schoolInfo?.einNumber);
    const [email, setEmail] = useState(schoolInfo?.email);
    const [phone, setPhone] = useState(schoolInfo?.phone);
    const [phoneExt, setPhoneExt] = useState(schoolInfo?.phoneExt);
    const [country, setCountry] = useState(schoolInfo?.country);
    const [address, setAddress] = useState(schoolInfo?.address);
    const [currency, setCurrency] = useState(schoolInfo?.currency);
    const [timezone, setTimezone] = useState(schoolInfo?.timezone);
    // const [manualAddress, setManualAddress] = useState(false);


    const handleFieldChange = (fieldName, fieldValue) => {
        setFormFieldsValue({
            ...formFieldsValue,
            [fieldName]: fieldValue
        });
    };
    const prepareEinNumberValue = (val) => {
        val = val.replace(/[^0-9]/g, '')
        if (val.length > 9) {
            val = val.slice(0, -1)
        }
        return val
    }
    const handleChanges = (val, event, setMethod, fieldName) => {
        if (fieldName === 'einNumber') {

            val = prepareEinNumberValue(val)
        }
        setMethod(val)
        handleFieldChange(fieldName, val)
    };

    // ================= Time zone ====================
    const [selectedTimeZone, setSelectedTimeZone] = useState('')
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);

    useMemo(() => {
        const names = moment.tz.names();
        let offsetTmz = [];
        const uniqueOffsets = new Set();

        for (let i in names) {
            if (names[i].startsWith('America/') || names[i].startsWith('US') || names[i].startsWith('Canada')) {
                let offSet = moment.tz(names[i]).format('Z');
                // console.log(offSet)
                // if (!uniqueOffsets.has(offSet)) {
                //     uniqueOffsets.add(offSet);
                //     offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`]
                // }
                offsetTmz = [...offsetTmz, `${names[i]} (GMT${offSet})`]
            }
        }
        console.log({ offsetTmz })
        setTimeZoneOptions(offsetTmz.map(item => ({ label: item, value: item })))
    }, [])

    const handleChangeWithData = (fieldName, fieldData, setMethod) => {
        setMethod(fieldData)
        handleFieldChange(fieldName, fieldData)
    };
    // =================================================

    const checkValidEinNumber = (event) => {
        if (event.target.value.length < 9) {
            checkRequiredNumber(event, 'einNumber')
            setEinNumberError("Enter a valid EIN number")
        } else {
            setEinNumberError("")
        }
    }
    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }
    const handleChangesData = (fieldName) => (event) => {
        const fieldData = event.target.value;
        setFormFieldsValue({ ...formFieldsValue, [fieldName]: fieldData });
    };
    return (
        <>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            validate={true}
                            label="School Full Name"
                            required={true}
                            value={name}
                            handleChange={(val, e) => handleChanges(val, e, setName, 'name')}
                            onBlur={(e) => checkRequiredNumber(e, 'name')}
                            error={errors?.name}
                            helperText={errors?.name}
                            count={50}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            validate={true}
                            label="DBA/Short Name"
                            required={true}
                            value={dba}
                            handleChange={(val, e) => handleChanges(val, e, setDba, 'dba')}
                            onBlur={(e) => checkRequiredNumber(e, 'dba')}
                            error={errors?.dba}
                            helperText={errors?.dba}
                            count={50}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            validate={true}
                            label="EIN"
                            required={true}
                            value={einNumber}
                            handleChange={(val, e) => handleChanges(val, e, setEinNumber, 'einNumber')}
                            onBlur={(e) => checkValidEinNumber(e)}
                            error={errors?.einNumber}
                            helperText={errors?.einNumber}
                            count={9}
                            number="number"


                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            label="Email Address"
                            required={true}
                            email={true}
                            value={email}
                            handleChange={(val, e) => handleChanges(val, e, setEmail, 'email')}
                            onBlur={(e) => checkRequiredNumber(e, 'email')}
                            error={errors?.email}
                            helperText={errors?.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone}
                            countryCodeEditable={false}
                            onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue, setPhone)}
                            onBlur={() => console.log("onBlur")}

                            variant="outlined"
                            onlyCountries={["us", "ca", "bd"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone Number"
                            LabelStatus={true}
                        //helpertext="Helper text"
                        //error="error"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TextField
                            label="Ext."
                            required={false}
                            email={true}
                            value={phoneExt}
                            count={4}
                            number="number"
                            handleChange={(val, e) => {
                                val = val.replace(/[^0-9]/g, '')
                                if (val.length > 4) {
                                    val = val.slice(0, -1)
                                }
                                handleChanges(val, e, setPhoneExt, 'phoneExt')
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select Country"
                            validation={true}
                            options={countries}
                            selectedOption={country}
                            handleChange={(val, e) => handleChanges(val, e, setCountry, 'country')}
                            onBlur={(e) => checkRequiredNumber(e, 'country')}
                            error={errors?.country}
                            helperText={errors?.country}
                        />
                    </Grid>
                    {
                        manualAddress ?
                            <Grid item xs={12} sm={12} md={12}>
                                <GooglePlaceField
                                    label="Address"
                                    validation={true}
                                    placeholder="Your current resident address"
                                    value={checkJsonStringOrNot(address)}
                                    handleChange={(address) => handleChangeWithData("address", address, setAddress)}
                                    error={errors?.address}
                                    helperText={errors?.address}

                                />
                            </Grid> :
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            label="Street address"
                                            required={true}
                                            placeholder="Current resident address"
                                            value={formFieldsValue?.addressLine1}

                                            handleChange={(val, e) => handleChangesData("addressLine1")(e)}
                                            error={errors?.addressLine1}
                                            helperText={errors?.addressLine1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5}>
                                        <TextField
                                            label="Apt/Floor"
                                            required={false}
                                            value={formFieldsValue?.addressLine2}
                                            handleChange={(val, e) => handleChangesData("addressLine2")(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            label="City/Town/Village"
                                            required={true}
                                            value={formFieldsValue?.city}
                                            handleChange={(val, e) => handleChangesData("city")(e)}

                                            error={errors?.city}
                                            helperText={errors?.city}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            label="State/Province/Territory"
                                            required={true}
                                            value={formFieldsValue?.state}
                                            handleChange={(val, e) => handleChangesData("state")(e)}
                                            city
                                            error={errors?.state}
                                            helperText={errors?.state}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <TextField
                                            label="Zip/Postal Code"
                                            required={true}
                                            value={formFieldsValue?.zip}
                                            handleChange={(val, e) => handleChangesData("zip")(e)}
                                            error={errors?.zip}
                                            helperText={errors?.zip}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} sx={{ padding: '0px' }}>
                        <Button
                            variant="text"
                            sx={{ padding: '0px', marginTop: '-26px', color: '#0450E1', textTransform: 'initial' }}
                            onClick={() => setManualAddress(!manualAddress)}
                        >
                            {manualAddress ?
                                "Enter address manually" : "Search your address"}</Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: '-16px' }}>
                        <ComboBox
                            label="Currency"
                            validation={false}
                            options={currencies}
                            selectedOption={currency}
                            handleChange={(val, e) => handleChanges(val, e, setCurrency, 'currency')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <ComboBox
                            label="Select Timezone"
                            validation={true}
                            options={timeZoneOptions}
                            selectedOption={timezone}
                            error={errors?.timezone}
                            helperText={errors?.timezone}
                            handleChange={(val, e) => handleChanges(val, e, setTimezone, 'timezone')}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default EditAccountDetails