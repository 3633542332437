import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextField from '../../../components/Form/TextField/TextField'
import { useGetSubjectDetailsQuery } from '../../../redux/features/subjects/subjectApi'

const SubjectAreaEdit = (props) => {
    const {
        subjectArea,
        setSubjectArea,
        subjectAreaDescription,
        setSubjectAreaDescription,
        subjectAreaError,
        setEditNamePrevious,
        id
    } = props

    const { data, isError, isLoading, error } = useGetSubjectDetailsQuery(id, {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const [subjectAreaDetails, setSubjectAreaDetails] = useState({})

    useEffect(() => {
        if (!isError && !isLoading) {
            setSubjectAreaDetails(data?.body?.data)
        }
    }, [data, isError, isLoading])

    useEffect(() => {
        if (id) {
            setSubjectArea(subjectAreaDetails?.subjectArea)
            setSubjectAreaDescription(subjectAreaDetails?.subjectAreaDescription)
            setEditNamePrevious(subjectAreaDetails?.subjectArea)

        }
    }, [subjectAreaDetails, id])


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        label="Subject Area Name"
                        validation={true}
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        count={20}
                        value={subjectArea}
                        handleChange={(val) => setSubjectArea(val)}
                        helperText={subjectArea?.length === 0 ? subjectAreaError : ""}
                        error={subjectArea?.length === 0 ? subjectAreaError : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        validation={false}
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        count={50}
                        value={subjectAreaDescription}
                        handleChange={(val) => setSubjectAreaDescription(val)}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SubjectAreaEdit