import React from "react";
import { makeStyles, } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SelectFieldMulticolor.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


{/* <SelectFieldMulticolor
  labelTop={false}
  labelinsideStatus={true}
  statustData={statustData}
  defaultValue={courseData.status ? courseData.status : ""}
  handleChange={handleChange}
  width="140px"
  height="40px"
  Status={true}
/> */}



function SelectFieldMulticolor(props) {
  const { statustData } = props;

  // * === icon func === * //
  const CustomArrowDropDownIcon = withStyles({
    root: {
      color: props.defaultValue === "active" ? "#66bd50 !important" : '#c44058 !important',
    },
  })(ArrowDropDownIcon);

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeValueColor, setActiveValueColor] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // console.log(props.defaultValue, 54)

  return (
    <div className="SelectFieldMulticolor">
      <div className="FieldLabel">
        {props.labelTop && <label >{props.label}</label>}
        {props.validation && <span>*</span>}
      </div>
      <div className="SelectFieldMulticolorBody" style={{ height: props.height, width: props.width }}>
        {props.placeholderStatus && <label >{props.placeholder}</label>}
        <FormControl fullWidth >
          <Select
            id="basic-menu"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={props.handleChange}
            defaultValue={props.defaultValue}
            IconComponent={CustomArrowDropDownIcon}
          // MenuProps={{
          //   PaperProps: {
          //     style: {
          //       marginTop: props?.dropdownMarginTop,
          //     },
          //     anchorOrigin: {
          //       vertical: 'top',
          //       horizontal: 'center'
          //     },
          //     transformOrigin: {
          //       vertical: 'top',
          //       horizontal: 'center'
          //     }
          //   },
          // }}
          >

            {statustData.map((select, i) => (
              <MenuItem
                key={i}
                value={select.value}
              >
                {props.Status &&
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      display: "inline-block",
                      borderRadius: "100px",
                      marginRight: "10px",
                      background: select.statuscolor
                    }}
                  >

                  </span>}
                <span style={{ color: select.statuscolor }}><span style={{ color: props.textcolor }}>{select.select}</span></span>
              </MenuItem>
            ))}

            {props.AddMoreButton &&
              <div ><Button className="AddMoreButtonDropdown" variant="text" href={props.AddNewlink} onClick={props.handleDrawerOpen ? props.handleDrawerOpen : undefined}> <AddRoundedIcon />{props.AddNewLabel}</Button></div>
            }
          </Select>
        </FormControl>
      </div>

    </div>
  );
}


export default SelectFieldMulticolor;

