import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import tableImg from '../../../assets/imgs/tableImg.png';

import AttendanceDetails from './AttendanceDetails';
import AddAttendance from './AddAttendance';
import {
  useGetAllAttendanceCodesQuery,
  useGetAttendanceByDateQuery,
  useGetAttendanceBySearchMutation,
  useUpdateAttendanceMutation,
} from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { toast } from 'react-toastify';
import { toTitleCase } from '../../../utils/helpers';
import AttendanceFilter from './AttendanceFilter';
import { useGetRoleListQuery } from '../../../redux/features/roles/roleApi';
import { useGetUserListQuery } from '../../../redux/features/users/userApi';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import { useSelector } from 'react-redux';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';

const Attendance = () => {
  const [filterDate, setFilterDate] = useState(dayjs());
  const [isStarted, setIsStarted] = useState(false);
  const [sortAttendance, setSortAttendance] = useState([])
  const [asc, setAsc] = useState(true)

  const { data, isLoading, isError, error, refetch } = useGetAttendanceByDateQuery(filterDate, {
    refetchOnMountOrArgChange: true,
    pollingInterval: isStarted ? 5000 : 0
  });

  const [updateAttendance, updateAttendanceOptions] = useUpdateAttendanceMutation();

  const [searchAttendance, searchAttendanceOptions] = useGetAttendanceBySearchMutation();

  const { data: attendanceCodeResponse, isLoading: isLoadingAttendanceCode, error: attendanceCodeError } = useGetAllAttendanceCodesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useProtected(error);
  useProtected(searchAttendanceOptions.error);
  useProtected(attendanceCodeError);

  const [OpenAttendanceDetails, setAttendanceDetails] = useState(false);

  const handleCloseAttendanceDetails = () => {

    setAttendanceDetails(false);
  }

  const rollbackSingleAttendanceStatusIfNeeded = async () => {
    let data = {
      status: previousSelectedStatus,
    }

    const result = await updateAttendance({ id: selectedAttendanceId, ...data });
    setPreviousSelectedStatus('')
  }

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [OpenAttendanceEntry, setAttendanceEntry] = useState(false);
  const handleOpenAttendanceEntry = () => {
    if (!isTheActionPermitted('addManualEntry')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setAttendanceEntry(true);
  }
  const handleCloseAttendanceEntry = () => setAttendanceEntry(false);
  const [rows, setRows] = useState([]);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState('');
  const [isRefetching, setIsRefetching] = useState(false);
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);
  const [attendanceCodeOptionsUnfiltered, setAttendanceCodeOptionsUnfiltered] = useState([]);
  const [previousSelectedStatus, setPreviousSelectedStatus] = useState('');
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState('');
  const [attendanceAll, setAttendanceAll] = useState([]);
  const [openFilterDrawer, setOpenFilter] = useState(false);
  const [roles, setRoles] = useState([])
  const [streams, setStream] = useState([])
  const [userTypes, setUserTypes] = useState([])

  const { data: roleData, isError: roleIsError, isLoading: roleIsLoading, error: roleError } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true
  })

  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpenAttendanceDetails = (id) => {
    setSelectedAttendanceId(id);
    setAttendanceDetails(true);
  };

  const convertAttendanceCodeResponseToSelectItem = (codes) => {

    if (codes.length > 0) {
      return codes.map((item) => ({ label: item.code, value: item.code }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoadingAttendanceCode && !attendanceCodeError && attendanceCodeResponse) {
      setAttendanceCodeOptions(
        convertAttendanceCodeResponseToSelectItem(
          attendanceCodeResponse.body.data,
        ),
      );

      setAttendanceCodeOptionsUnfiltered(attendanceCodeResponse.body.data)


    }
  }, [attendanceCodeResponse, isLoadingAttendanceCode, attendanceCodeError]);



  const convertTimestampToEst = (timestamp) => {

    const estTime = new Date(parseInt(timestamp));

    const formattedTime = estTime.toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      hour12: true,
      hour: "numeric",
      minute: "numeric"
    }) + " (EST)";

    return formattedTime
  }


  //================= sorting part start =======================================//
  const ascendingOrDescendingSortingByStatus = (fieldName) => {
    let sortedAttendance = [...sortAttendance]
    if (asc) {
      sortedAttendance.sort((a, b) => a[fieldName].localeCompare(b[fieldName]));
    } else {
      sortedAttendance.sort((a, b) => b[fieldName].localeCompare(a[fieldName]));
    }
    setRows(convertDataToCellRows(sortedAttendance));
    setAsc(!asc)
  }

  const ascendingOrDescendingSortingByUserType = (fieldName) => {
    let sortedAttendance = [...sortAttendance]
    if (asc) {
      sortedAttendance.sort((a, b) => a[fieldName].localeCompare(b[fieldName]));
    } else {
      sortedAttendance.sort((a, b) => b[fieldName].localeCompare(a[fieldName]));
    }
    setRows(convertDataToCellRows(sortedAttendance));
    setAsc(!asc)
  }
  //================= sorting part end =======================================//
  useEffect(() => {
    if (!isLoading && !isError && data) {

      setAttendanceAll(data?.body?.data)
      setSortAttendance(data?.body?.data)

      // setRows(convertDataToCellRows(data?.body?.data));

    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    let empty = []
    if (attendanceAll?.length > 0 && attendanceCodeOptionsUnfiltered?.length > 0) {
      setRows(convertDataToCellRows(attendanceAll));
    } else {
      setRows(convertDataToCellRows(empty));
    }
  }, [attendanceAll, attendanceCodeOptionsUnfiltered])


  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      // console.log(arr.length, 345)
      return arr.map((item) => {
        let clockInformattedDate = '';
        let clockOutformattedDate = '';
        if (item?.clockIn) {
          clockInformattedDate = convertTimestampToEst(item?.clockIn)
        }
        if (item?.clockOut) {
          clockOutformattedDate = convertTimestampToEst(item?.clockOut)
        }

        const truncatedName = item?.name ? (item.name.length > 50 ? item.name.substring(0, 50) + '...' : item.name) : 'N/A';

        return ({

          cell: [
            {
              img: item?.userImage || 'https://web-assets.deenschool.io/assets/avatar.png',
              imgStatus: true,
              // linkText: item?.name || 'N/A',
              linkText: truncatedName,
              linkTextStatus: true,
              // link: `/users_u/${item?.userId}`,
              link: `/users/${item?.userId}`,
            },
            { text: item?.userId || 'N/A' },
            {
              Status: true,
              background: item?.status === 'P' ? 'rgba(65, 171, 55, 0.12)' :
                item?.status === 'A' ? 'rgba(186, 26, 26, 0.12)' :
                  item?.status === 'L' ? 'rgba(255, 153, 0, 0.12)' : '#EDEDEF',
              color: item?.status === 'P' ? '#41AB37' :
                item?.status === 'A' ? '#BA1A1A' :
                  item?.status === 'L' ? '#F90' : '#676C7B',
              label: <span style={{ fontWeight: '700' }}>{item?.status}</span>,
              StatusWidth: '100px'
            },

            // {
            //   SelectFiled: true,
            //   options: attendanceCodeOptions,
            //   selectedOption: item?.status.replace(/"/g, ''),
            //   isDisabled: true,
            //   SelectHandleChanges: SelectHandleChanges,
            // },
            { text: item?.userType ? toTitleCase(item?.userType) : 'N/A' },

            // { text: item?.clockIn ? clockInformattedDate : 'N/A' },
            { text: (attendanceCodeOptionsUnfiltered.find(option => option.code === item?.status)?.presence === 'Present') && item?.clockIn ? clockInformattedDate : 'N/A' },




            {
              text: (item?.clockOut && item?.status != "A") ? clockOutformattedDate : 'N/A',
            },
            {
              Button: true,
              buttonLabel: 'Details',
              // textTransform: "initial",
              buttonTextcolor: '#004FE0',
              onClick: () => handleOpenAttendanceDetails(item._id),
            },
          ],
        })
      }
      );
    } else {
      return [];
    }
  };



  const options = [
    {
      label: 'Present',
      value: 'Present',
    },

    {
      label: 'Absent',
      value: 'Absent',
    },
  ];

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '35%',
      textAlign: 'left',
    },
    {
      id: 'ID',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'Attendance',
      numeric: false,
      disablePadding: true,
      label: 'Main Attendance',
      width: '15%',
      textAlign: 'left',
      sort: streams?.length > 0 || userTypes?.length > 0 ? "" : "Main Attendance",
      headerOnClick: () => ascendingOrDescendingSortingByStatus('status')
    },
    {
      id: 'Type',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '10%',
      textAlign: 'left',
      sort: streams?.length > 0 || userTypes?.length > 0 ? "" : "User Type",
      headerOnClick: () => ascendingOrDescendingSortingByUserType('userType')
    },
    {
      id: 'Clockin',
      numeric: false,
      disablePadding: true,
      label: 'Clock in',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'Clockout',
      numeric: false,
      disablePadding: true,
      label: 'Clock out',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '15%',
      textAlign: 'left',
    },
  ];

  // ========================filter drawer start ===========================//

  const handleFilterDrawerOpen = () => {
    setOpenFilter(true);
  };

  const handleCloseFilterDrawer = async () => {
    setStream([])
    setUserTypes([])
    setOpenFilter(false);
    setRows(convertDataToCellRows(data?.body?.data));
  };

  // useEffect(() => {
  //   console.log(streams, userTypes)

  // }, [streams, userTypes])

  //UserTypes are setting as roles
  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      setRoles(roleData?.body?.data);
    }
  }, [roleData, roleIsLoading, roleIsError]);


  const filterHandler = async () => {
    const result = await searchAttendance({ term: searchTerm, date: filterDate, userType: userTypes, streams: streams });
    // console.log(result?.data?.body?.data);
    if (result?.data?.body?.data) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    }
    setOpenFilter(false);
  }
  // ========================filter drawer end ===========================//
  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  const handleAttendanceRefetch = async (date) => {
    setIsRefetching(true);
    await refetch({ date });
    setIsRefetching(false);
  };


  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  useEffect(() => {
    // console.log(filterDate, 324)
    handleAttendanceRefetch(filterDate);
  }, [filterDate]);

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchAttendance({ term: "", date: filterDate });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchAttendance({ term: searchTerm, date: filterDate });
    searchDataShowing(result)
  };

  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part start ====================== //

  const handleAttendanceStartStop = () => {
    if (!isTheActionPermitted('takeAttendance')) {
      toast.error("You don't have access to this feature")
      return false
    }
    if (isStarted) {
      toast.success("Attendance table will not update automatically anymore.");
      setIsStarted(false);
    } else {
      toast.success("Attendance started, table will refresh every 5 seconds.");
      setIsStarted(true);
    }
  }

  return (
    <>
      <div className="TableSection AttendanceTable">
        <Table
          title="Main Attendance"
          tableHeader={true}
          // AddButton={true}
          // addLabel="See Live"

          icon={<SensorsRoundedIcon />}
          // onClick={handleOpenAttendanceEntry}
          // AddButtonOutLine={true}
          // onClickOutlineButton={handleOpenAttendanceEntry}
          // OutLineButtonLabel="Add entry"
          DateField={true}
          //value={}
          //handleChange={DatehandleChange}

          headCells={headCells}
          rows={rows}
          //filter part 
          filterCountStatus={(streams?.length + userTypes?.length) > 0}
          filterCountNumber={(streams?.length + userTypes?.length)}

          // * add entry button
          AddButtonOutLine={true}
          OutLineButtonIcon={<AddRoundedIcon />}
          OutLineButtonLabel="Add entry"
          onClickOutlineButton={handleOpenAttendanceEntry}

          // * start stop button
          SecondInnerButton={true}
          SecondInnerButtonIcon={isStarted ? <StopRoundedIcon /> : <PlayArrowRoundedIcon />}
          SecondInnerButtonLabel={isStarted ? "Stop" : "Start"}
          SecondInnerButtonTextColor={isStarted ? "#1B1B1F" : "#fff"}
          SecondInnerButtonColor={isStarted ? "transparent" : "#0450E1"}
          SecondInnerButtonBorder={isStarted ? "1px solid #BA1A1A" : "1px solid #0450E1"}
          onClickSecondInnerButton={handleAttendanceStartStop}

          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          showSearchBox={true}
          FilterButtonDrawer={true}
          onClickFilterButton={handleFilterDrawerOpen}
          searchBoxPlaceholder="Search users"
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          // link="/invited-users"
          isDataLoading={
            isLoading || isRefetching || searchAttendanceOptions?.isLoading
          }
          emptyTableTitle="No attendance found on this date"
          emptyTableNote={<div>Change the date or click “START” to record today’s <br /> attendance.</div>}
          dateFilter={filterDate}
          filterDateFormat={"ddd, MMM DD"}
          handleDateFilterChange={(date) => setFilterDate(date)}
          goToPreviousDate={(e) => setFilterDate(filterDate.subtract(1, 'day'))}
          goToNextDate={(e) => setFilterDate(filterDate.add(1, 'day'))}
          onClickEmptyCard={handleOpenAttendanceEntry}
          RowsPerPage={20}
        />
      </div>
      <div >
        <div className='changeSettings'>We will send an email to the parents and staff at the end of the day. <a href='/attendance-settings'>Change Settings</a></div>
        <br />
      </div>
      {/* Details drawer */}
      <div>
        <SideDrawer
          open={OpenAttendanceDetails}
          handleDrawerClose={handleCloseAttendanceDetails}
          title="Attendance details"
          buttonLabel="Save"
          drawerWidth="690px"
          // buttonLink={}
          sideOpen="right"
          ButtonLabel="Save"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={false}
          body={
            <>
              <AttendanceDetails
                attendanceId={selectedAttendanceId}
                closeDrawer={handleCloseAttendanceDetails}
                previousSelectedStatus={previousSelectedStatus}
                setPreviousSelectedStatus={setPreviousSelectedStatus}
                currentSelectedStatus={currentSelectedStatus}
                setCurrentSelectedStatus={setCurrentSelectedStatus}
                singleDrawerCloser={setAttendanceDetails}
              />
            </>
          }
        />
      </div>

      {/* Add Entry drawer */}
      <SideDrawer
        open={OpenAttendanceEntry}
        handleDrawerClose={handleCloseAttendanceEntry}
        title="Add Attendance"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        body={
          <>
            <AddAttendance closeDrawer={handleCloseAttendanceEntry} attendanceAll={attendanceAll} filterDate={filterDate} roles={roles} />
          </>
        }
      />

      {/* Filter */}
      <SideDrawer
        open={openFilterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCloseFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        BtnColor="#0450E1"
        BtnTextcolor="#fff"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}

        body={
          <>
            <AttendanceFilter
              label="Status"
              // checkboxesData={status}
              streams={streams}
              setStream={setStream}
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              roles={roles}
              attendanceCodeOptionsUnfiltered={attendanceCodeOptionsUnfiltered}
              attendanceCodeOptions={attendanceCodeOptions}
            />
          </>
        }
      />
    </>
  );
};

export default Attendance;
