import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import { useGetClassLevelListQuery } from '../../../redux/features/classes/classApi';
import useProtected from '../../../hooks/useProtected';

const StudentsFilter = (props) => {
    const { data: classesData, isLoading: classesIsLoading, isError: classesIsError, error: classesError } = useGetClassLevelListQuery()
    const [state, setState] = React.useState({
        checkedA: true,
    });
    const { userTypesHandlerFilter,
        gradeTypesHandlerFilter,
        userTypes,
        gradeTypes,
        genderTypeHandlerFilter,
        genderTypes,
        classLevel,
        setClassLevel,
    } = props;

    const [classes, setClasses] = useState([])
    useProtected(classesError);

    //============== user status part start ===================================//
    const userTypesData = [
        {
            label: 'Inactive',
            value: 'inactive',
        },

        {
            label: 'Active',
            value: 'active',
        },
    ];
    //============== user status part end ===================================//

    //============== gender part start ===================================//
    const gender = [
        {
            label: 'Male',
            value: 'male',
        },

        {
            label: 'Female',
            value: 'female',
        },
    ];
    //============== gender part end ===================================//

    //============== class_level part start ===================================//
    useEffect(() => {
        if (!classesIsLoading && !classesIsError) {
            setClasses(classesData?.body?.data)
        }
    }, [classesData, classesIsLoading, classesIsError])
    useEffect(() => {
        if (classes?.length > 0) {
            let allClasses = []
            let val_array = []
            classes.map((cls) => {
                allClasses.push({ label: cls?.name?.replace(/(^|\s)\w/g, (match) => match.toUpperCase()), value: cls?.name?.toLowerCase() })
                val_array.push(cls?.name?.toLowerCase())
            })
            setClassLevel(allClasses)
            // setGradeTypes(val_array)
        }
    }, [classes])


    //============== class_level part end ===================================//
    return (
        <div className="FilterMainCont">
            {/* ============================== Status ============================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Status
                    </Typography>
                    {
                        userTypes?.length > 0 &&
                        <Typography className="FilterCount">{userTypes?.length}</Typography>
                    }

                </AccordionSummary>
                <AccordionDetails>
                    {userTypesData?.map((item, i) => (
                        <CheckBox
                            label={item.label}
                            checked={userTypes.includes(item.value)}
                            onChange={(e) => userTypesHandlerFilter(e, item.value)}
                        />
                    ))}

                </AccordionDetails>
            </Accordion>
            {/* ============================== Gender ============================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Gender
                    </Typography>
                    {
                        genderTypes?.length > 0 &&
                        <Typography className="FilterCount">{genderTypes?.length}</Typography>
                    }

                </AccordionSummary>
                <AccordionDetails>
                    {gender?.map((item, i) => (
                        <CheckBox
                            label={item.label}
                            checked={genderTypes.includes(item.value)}
                            onChange={(e) => genderTypeHandlerFilter(e, item.value)}
                        />
                    ))}

                </AccordionDetails>
            </Accordion>
            {/* ============================== Class/Level ============================== */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className="AccTitle">
                        Class/Level
                    </Typography>
                    {
                        gradeTypes?.length > 0 &&
                        <Typography className="FilterCount">{gradeTypes?.length}</Typography>
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {classLevel.map((item, i) => (
                        <CheckBox
                            label={item.label}
                            checked={gradeTypes.includes(item.value)}
                            onChange={(e) => gradeTypesHandlerFilter(e, item.value)}
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default StudentsFilter