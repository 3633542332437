import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from "@mui/material";
import "./SingleSelect.css"

const SingleSelect = (props) => {
    const { option, handleChange, value, setvalue } = props;

    return (
        <div className="MultipleSelet">
            {props.FieldLabel &&
                <div className="FieldLabel">{props.label} {props.validation && <span>*</span>}</div>
            }
            <Select
                displayEmpty
                value={value}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (typeof selected === 'object' && Object.keys(selected).length === 0) {
                        return <em>{(!props.Placeholder || props.Placeholder === "") ? "Select" : props.Placeholder}</em>;
                    }
                    console.log("selected ===> ", selected);
                    return selected.toString()
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                error={props.error}
                disabled={props.disabled}
            >
                <MenuItem disabled value="">
                </MenuItem>
                {option.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                    >
                        {/* <Checkbox checked={value.indexOf(name) > -1} /> */}
                        {name}
                    </MenuItem>
                ))}
            </Select>
            {(props.errorHelperText) && (
                <FormHelperText sx={{ color: '#ba1b1a' }} >Required</FormHelperText>
            )}
        </div>
    );
};

export default SingleSelect;