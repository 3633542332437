import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import AddIcon from '@mui/icons-material/Add';
import DraggableDialog from '../../../components/hooks/Dialog';
import AddCategories from './AddCategories';
import { useDeleteMessageCategoriesMutation, useGetCategoriesSearchResultMutation, useGetMessageCategoriesQuery } from "../../../redux/features/messages/meassageApi";
import { toast } from "react-toastify";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";

const Categories = () => {

  //geting data
  const { data, isError, isLoading, error } = useGetMessageCategoriesQuery(undefined, { refetchOnMountOrArgChange: true });
  useProtected(error);
  const [getCategoriesSearchResult, options] = useGetCategoriesSearchResultMutation();
  // delete category  api
  const [deleteCategory] = useDeleteMessageCategoriesMutation()
  //states 
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState("");
  const [rows, setRows] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [editEnable, setEditEnable] = useState(false);


  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = (id) => {
    if (!isTheActionPermitted('deleteMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
    setDeleteId(id)
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //


  //-----search part start------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getCategoriesSearchResult({ term: "" });
    searchDataShowing(result)
  };
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getCategoriesSearchResult({ term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-----search part end------//

  // Drawer // For add and upadte category
  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = (id) => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setEditEnable(true);
    setSelectedId(id);
    setOpenCategory(true);
  };
  const handleOpenCategoryNew = () => {
    if (!isTheActionPermitted('manageMessages')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenCategory(true);
  };
  const handleCloseCategory = () => {
    setEditEnable(false);
    setSelectedId("");
    setOpenCategory(false)
  };
  // Drawer // For add and upadte category

  // data showing part  start //
  const headCells = [
    { id: "to", numeric: false, disablePadding: true, label: "Category Name", width: "20%", textAlign: "left", },
    { id: "message", numeric: false, disablePadding: true, label: "", width: "20%", textAlign: "left", },
    { id: "message", numeric: false, disablePadding: true, label: "", width: "60%", textAlign: "left", },
    { id: "action", numeric: false, disablePadding: true, label: "", width: "20%", textAlign: "left", },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            textBold: item.name ? item.name : "No Data",
            color: item.name ? "#1B1B1F" : "#919094",
          },
          { text: "", },
          { text: " ", },
          { ActionButton: true, ActionButtonLabel: 'Action', },
        ],

        //======= Action Date Start===== //
        action: [
          { label: 'EDIT', onClick: () => handleOpenCategory(item._id), },
          { label: 'DELETE', onClick: (e) => handleOpenDelete(item._id) },
        ],
        //======= Action Date End===== //
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [isLoading, isError, data]);



  // data showing part  end //

  //====== deleting part start ===//
  const deleteCategoryHandler = async () => {
    const result = await deleteCategory(deleteId);
    if (result?.data) {
      toast.success('Category deleted');
      setOpenDialog(false)
    } else {
      toast.error(result?.data?.message);
    }
    setDeleteId('')

  }
  //====== deleting part end ===//

  return (
    <>
      <div className="TableSection">
        <Table
          title={<span style={{ fontSize: '32px' }}>Categories</span>}
          tableHeader={true}
          addLabel="New Category"
          icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleOpenCategoryNew}
          footer={true}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          innerHeader={true}
          SearchBoxTop={true}
          RowsPerPage={20}
          searchBoxPlaceholder="Search categories"
          isDataLoading={isLoading || options?.isLoading}
        />
      </div>

      {/* Add Catgory Modal*/}
      <AddCategories
        openModal={openCategory}
        handleClose={handleCloseCategory}
        editEnable={editEnable}
        id={selectedId}
        name={name}
        setName={setName}
      />

      {/* Delete Modal*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteCategoryHandler}
        title={<span style={{ fontWeight: 600 }}>Delete this category?</span>}
        body={<div>Are you sure you want to delete this? <br />Once you delete it, you can't get it back</div>}
        ModalFooter={true}
        actionButton="Delete"
      />
    </>
  );
};

export default Categories;
