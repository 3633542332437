import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@mui/material/Grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  useCreateSettingMutation,
  useGetSettingListQuery,
  useGetSettingsByGroupFacultyQuery,
  useGetSettingsByGroupQuery,
  useUpdateSettingMutation
} from '../../../redux/features/settings/settingApi';

import SwitchButtonIOS from '../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import Buttons from '../../../components/Form/Button/Button';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';


import StartAndEndTime from './StartAndEndTime';
import Communication from './Communication';
import dayjs from "dayjs";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";
import { isValidTimeHHMM } from "../../../hooks/HelperFunctions";
import { useSelector } from "react-redux";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";
import { Avatar, AvatarGroup } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Refresh } from "../../../assets/imgs/icons/refresh";
import AboutDeenschool from "./AboutDeenschool";
import ScanTime from "./ScanTime";
import SelectUsers from "./SelectUsers";
import ScanUser from "./ScanUser";
import { useGetScanUserListQuery } from "../../../redux/features/attendence/attendanceApi";
import { getProfileAvatarFromName } from "../../../utils/helpers";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f8f9fb',
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const AvatargroupStyle = { alignItems: 'flex-start', justifyContent: 'flex-end', "& .MuiAvatar-root": { width: '32px', height: '32px', background: '#0450E1', fontSize: '14px' } }



function AttendanceSettings(props) {


  // const { data, isLoading, isError, error } = useGetSettingListQuery();
  const { data, isLoading, isError, error } = useGetSettingsByGroupQuery('attendance');
  const { data: facultyDatas, isLoading: facultyIsLoading, isError: facultyIsError, error: facultyError } = useGetSettingsByGroupFacultyQuery();
  const [updateSetting, updateSettingOptions] = useUpdateSettingMutation();

  const {
    data: scanUserData,
    isLoading: scanUserIsLoading,
    isFetching: scanUserIsFetching,
    error: scanUserError
  } = useGetScanUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })


  useProtected(scanUserError);
  useProtected(updateSettingOptions.error);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const [attendanceSettings, setAttendanceSettings] = useState([]);
  const [attendanceFacultySettings, setAttendanceFacultySettings] = useState([]);
  const [scheduleSetting, setScheduleSetting] = useState({});
  const [scheduleSettingLate, setScheduleSettingLate] = useState({});
  const [communicationSetting, setCommunicationSetting] = useState({});
  const [communicationSettingForFaulty, setCommunicationSettingForFaulty] = useState({});
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeLate, setStartTimeLate] = useState('');
  const [endTimeLate, setEndTimeLate] = useState('');

  const [communicationAbsentStudentActivity, setCommunicationAbsentStudentActivity] = useState('false')
  const [communicationAbsentFacultyActivity, setCommunicationAbsentFacultyActivity] = useState('false')
  const [communicationAbsentFacultyActivityStatus, setCommunicationAbsentFacultyActivityStatus] = useState(false)
  const [communicationAbsentStudentEmail, setCommunicationAbsentStudentEmail] = useState('false')
  const [communicationAbsentFacultyEmail, setCommunicationAbsentFacultyEmail] = useState('false')
  const [communicationAbsentStudentMessage, setCommunicationAbsentStudentMessage] = useState('false')
  const [communicationAbsentFacultyMessage, setCommunicationAbsentFacultyMessage] = useState('false')
  const [communicationAbsentStudentTime, setCommunicationAbsentStudentTime] = useState('')
  const [communicationAbsentFacultyTime, setCommunicationAbsentFacultyTime] = useState('')
  const [errorStartTimePresent, setErrorStartTimePresent] = useState(false)
  const [errorEndTimePresent, setErrorEndTimePresent] = useState(false)
  const [errorStartEndTimePresent, setErrorStartEndTimePresent] = useState(false)
  const [errorStartTimeLate, setErrorStartTimeLate] = useState(false)
  const [errorEndTimeLate, setErrorEndTimeLate] = useState(false)
  const [errorStartEndTimeLate, setErrorStartEndTimeLate] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [aboutDeenSchoolDrawer, setAboutDeenSchoolDrawer] = useState(false)
  const [qrDatePickerDrawer, setQrDatePickerDrawer] = useState(false)
  const [userDrawer, setUserDrawer] = useState(false)


  const [alreadyAddedUsers, setAlreadyAddedUsers] = useState([]);


  const handleChange = (event) => {
    if (!isTheActionPermitted('manageAttendanceSetting')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    if (communicationAbsentStudentActivity == "false") {
      setCommunicationAbsentStudentEmail("false")
      setCommunicationAbsentStudentMessage("false")
    }

  }, [communicationAbsentStudentActivity])
  useEffect(() => {
    if (communicationAbsentFacultyActivity == "false") {
      setCommunicationAbsentFacultyEmail("false")
      setCommunicationAbsentFacultyMessage("false")
    }
    // else {
    //   setCommunicationAbsentFacultyEmail("true")
    //   setCommunicationAbsentFacultyMessage("true")
    // }
  }, [communicationAbsentFacultyActivity])
  useEffect(() => {
    if (!isLoading && !isError) {
      let allData = data.body.data
      setAttendanceSettings(allData)

    }
  }, [data, isLoading, isError])
  useEffect(() => {
    if (!facultyIsLoading && !facultyIsError) {
      let allData = facultyDatas.body.data
      setAttendanceFacultySettings(allData)

    }

  }, [facultyDatas, facultyIsLoading, facultyIsError])


  useEffect(() => {
    console.log(startTime, endTime)
  }, [startTime, endTime])

  useEffect(() => {
    let schedule_setting = attendanceSettings.find((setting) => setting.name === 'schedule_attendance')
    let schedule_setting_late = attendanceSettings.find((setting) => setting.name === 'schedule_attendance_late')
    let communication_setting = attendanceSettings.find((setting) => setting.name === 'communication')
    // let communication_settin_for_faulty = attendanceSettings.find((setting) => setting.name === 'faculty_absent')
    // console.log(communication_settin_for_faulty, 345)
    setScheduleSetting(schedule_setting)
    setScheduleSettingLate(schedule_setting_late)
    setCommunicationSetting(communication_setting)
    // setCommunicationSettingForFaulty(communication_settin_for_faulty)

    setStartTime(schedule_setting?.value?.from)
    setEndTime(schedule_setting?.value?.to)

    setStartTimeLate(schedule_setting_late?.value?.from)
    setEndTimeLate(schedule_setting_late?.value?.to)

    setCommunicationAbsentStudentActivity(communication_setting?.value?.activity)
    setCommunicationAbsentStudentEmail(communication_setting?.value?.emailCommunication)
    setCommunicationAbsentStudentMessage(communication_setting?.value?.messageCommunication)
    setCommunicationAbsentStudentTime(communication_setting?.value?.communicationTime)

    // setCommunicationAbsentFacultyActivity(communication_settin_for_faulty?.value?.activity)
    // setCommunicationAbsentFacultyEmail(communication_settin_for_faulty?.value?.emailCommunication)
    // setCommunicationAbsentFacultyMessage(communication_settin_for_faulty?.value?.messageCommunication)
    // setCommunicationAbsentFacultyTime(communication_settin_for_faulty?.value?.communicationTime)

    setErrorStartTimePresent(!isValidTimeHHMM(schedule_setting?.value?.from))
    setErrorEndTimePresent(!isValidTimeHHMM(schedule_setting?.value?.to))

    setErrorStartTimeLate(!isValidTimeHHMM(schedule_setting_late?.value?.from))
    setErrorEndTimeLate(!isValidTimeHHMM(schedule_setting_late?.value?.to))

  }, [attendanceSettings])

  useEffect(() => {
    let communication_settin_for_faulty = attendanceFacultySettings.find((setting) => setting.name === 'faculty_absent')
    // console.log(communication_settin_for_faulty, 345)
    setCommunicationSettingForFaulty(communication_settin_for_faulty)
    setCommunicationAbsentFacultyActivity(communication_settin_for_faulty?.value?.activity)
    setCommunicationAbsentFacultyEmail(communication_settin_for_faulty?.value?.emailCommunication)
    setCommunicationAbsentFacultyMessage(communication_settin_for_faulty?.value?.messageCommunication)
    setCommunicationAbsentFacultyTime(communication_settin_for_faulty?.value?.communicationTime)

  }, [attendanceFacultySettings])

  useEffect(() => {
    if (!scanUserIsLoading && !scanUserIsFetching) {
      setAlreadyAddedUsers(scanUserData.body.data);
    }
  }, [scanUserData, scanUserIsLoading, scanUserIsFetching])



  //== Start and end time ==//
  const [openTime, setOpenTime] = useState(false);
  const handleDrawerOpenTime = () => {
    if (!isTheActionPermitted('manageAttendanceSetting')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenTime(true);
  };
  const handleDrawerCloseTime = () => {
    setOpenTime(false);
  };

  const [openTimeLate, setOpenTimeLate] = useState(false);
  const handleDrawerOpenTimeLate = () => {
    if (!isTheActionPermitted('manageAttendanceSetting')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenTimeLate(true);
  };
  const handleDrawerCloseTimeLate = () => {
    setOpenTimeLate(false);
  };
  //== Start and end time ==//

  //== Communication ==//
  const [openCommunication, setOpenCommunication] = useState(false);
  const handleDrawerOpenCommunication = () => {
    if (!isTheActionPermitted('manageAttendanceSetting')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenCommunication(true);
  };
  const handleDrawerCloseCommunication = () => {
    setOpenCommunication(false);
    setCommunicationAbsentFacultyActivityStatus(false)
  };
  //== Communication ==//

  const updateScheduleAttendenceHandler = async () => {

    console.log(startTime, 500, endTime,)

    let [hoursStart, minutesStart] = startTime?.split(':');
    const formattedStartTime = `${hoursStart?.padStart(2, '0')}:${minutesStart?.padStart(2, '0')}`;
    let [hoursEnd, minutesEnd] = endTime?.split(':');
    const formattedEndTime = `${hoursEnd?.padStart(2, '0')}:${minutesEnd?.padStart(2, '0')}`;

    setErrorStartTimePresent(false)
    setErrorEndTimePresent(false)
    setErrorStartEndTimePresent(false)
    setErrorMessage('')



    const time1Obj = dayjs(formattedStartTime, 'HH:mm');
    const time2Obj = dayjs(formattedEndTime, 'HH:mm');

    let endGreaterThanStart = false
    if (time1Obj.isAfter(time2Obj)) {
      endGreaterThanStart = true
    }
    console.log(formattedStartTime, 500, formattedEndTime, endGreaterThanStart)
    if (!isValidTimeHHMM(formattedStartTime) || !isValidTimeHHMM(formattedEndTime) || endGreaterThanStart) {
      if (!isValidTimeHHMM(formattedStartTime)) {
        setErrorStartTimePresent(!isValidTimeHHMM(formattedStartTime))
        setErrorMessage('Please enter valid time')
      }
      console.log("dfg", 500)
      if (!isValidTimeHHMM(formattedEndTime)) {
        setErrorEndTimePresent(!isValidTimeHHMM(formattedEndTime))
        setErrorMessage('Please enter valid time')
      }
      console.log("dfg", 500)
      if (endGreaterThanStart) {
        setErrorStartEndTimePresent(endGreaterThanStart)
        setErrorMessage('The start time should be greater than the end time.')
      }
      console.log("dfg", 500)
      return;
    }


    const data = {
      "name": "schedule_attendance",
      "value": {
        "from": formattedStartTime,
        "to": formattedEndTime
      },
      "type": "object",
      "group": "attendance"
    }
    let result = null;
    let scheduleSettingID = scheduleSetting?._id
    result = await updateSetting({ id: scheduleSettingID, ...data });

    if (!result?.data?.error) {
      toast.success('Settings updated successfully');
      handleDrawerCloseTime()
    } else {
      toast.error(result?.data?.message);
    }

  }

  const updateScheduleAttendenceHandlerLate = async () => {
    if (!isTheActionPermitted('manageAttendanceSetting')) {
      toast.error("You don't have access to this feature")
      return false
    }


    let [hoursStart, minutesStart] = startTimeLate?.split(':');
    const formattedStartTime = `${hoursStart?.padStart(2, '0')}:${minutesStart?.padStart(2, '0')}`;
    let [hoursEnd, minutesEnd] = endTimeLate?.split(':');
    const formattedEndTime = `${hoursEnd?.padStart(2, '0')}:${minutesEnd?.padStart(2, '0')}`;


    setErrorStartTimeLate(false)
    setErrorEndTimeLate(false)
    setErrorStartEndTimeLate(false)
    setErrorMessage('')


    const time1Obj = dayjs(formattedStartTime, 'HH:mm');
    const time2Obj = dayjs(formattedEndTime, 'HH:mm');

    let endGreaterThanStart = false
    if (time1Obj.isAfter(time2Obj)) {
      endGreaterThanStart = true
    }

    if (!isValidTimeHHMM(formattedStartTime) || !isValidTimeHHMM(formattedEndTime) || endGreaterThanStart) {
      if (!isValidTimeHHMM(formattedStartTime)) {
        setErrorStartTimeLate(!isValidTimeHHMM(formattedStartTime))
        setErrorMessage('Please enter valid time')
      }

      if (!isValidTimeHHMM(formattedEndTime)) {
        setErrorEndTimeLate(!isValidTimeHHMM(formattedEndTime))
        setErrorMessage('Please enter valid time')
      }

      if (endGreaterThanStart) {
        setErrorStartEndTimeLate(endGreaterThanStart)
        setErrorMessage('The start time should be greater than the end time.')
      }

      return;
    }

    const data = {
      "name": "schedule_attendance_late",
      "value": {
        "from": formattedStartTime,
        "to": formattedEndTime
      },
      "type": "object",
      "group": "attendance"
    }
    let result = null;
    let scheduleSettingLateID = scheduleSettingLate._id
    result = await updateSetting({ id: scheduleSettingLateID, ...data });

    if (!result?.data?.error) {
      toast.success('Settings updated successfully');
      handleDrawerCloseTimeLate()
    } else {
      toast.error(result?.data?.message);
    }

  }

  const updateCommunicationHandler = async () => {
    const data = {
      // "name": "faculty_absent",
      "name": communicationAbsentFacultyActivityStatus ? "faculty_absent" : "communication",
      "value": {
        "activity": communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyActivity : communicationAbsentStudentActivity,
        "emailCommunication": communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyEmail : communicationAbsentStudentEmail,
        "messageCommunication": communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyMessage : communicationAbsentStudentMessage,
        "communicationTime": communicationAbsentFacultyActivityStatus ? communicationAbsentFacultyTime : communicationAbsentStudentTime
      },
      "type": "object",
      // "group": "faculty_communication"
      "group": communicationAbsentFacultyActivityStatus ? "faculty_communication" : "attendance"
    }
    let result = null;
    // let communicationSettingID = "64ec900fbef2a75b856d3979"
    let communicationSettingID = communicationAbsentFacultyActivityStatus ? communicationSettingForFaulty?._id : communicationSetting?._id
    console.log(communicationAbsentFacultyActivityStatus, 500)
    const trueData = communicationAbsentFacultyActivityStatus

      ? ((communicationAbsentFacultyEmail == "true" || communicationAbsentFacultyMessage == "true") && communicationAbsentFacultyActivity == "true")
      : ((communicationAbsentStudentEmail == "true" || communicationAbsentStudentMessage == "true") && communicationAbsentStudentActivity == "true")

    const falseData = communicationAbsentFacultyActivityStatus

      ? (communicationAbsentFacultyActivity == "false" && communicationAbsentFacultyEmail == "false" && communicationAbsentFacultyMessage == "false")
      : (communicationAbsentStudentActivity == "false" && communicationAbsentStudentEmail == "false" && communicationAbsentStudentMessage == "false")



    if (trueData || falseData) {
      result = await updateSetting({ id: communicationSettingID, ...data });

      if (!result?.data?.error) {
        toast.success('Settings updated successfully');
        handleDrawerCloseCommunication()
      } else {
        toast.error(result?.data?.message);
      }

    }
  }

  const showAllScanUserThumb = () => {
    let showAllScanUserThumbArray = []
    if (alreadyAddedUsers.length > 0) {
      alreadyAddedUsers?.map((user) => {
        let userFirstName = user?.firstName?.toUpperCase()
        showAllScanUserThumbArray.push(
          <Avatar alt={userFirstName} src={user?.profilePic ? user?.profilePic : getProfileAvatarFromName(userFirstName)}>
          </Avatar>
        )
      })
    }
    return showAllScanUserThumbArray
  }

  useEffect(() => {
    console.log(communicationAbsentFacultyActivity, 346, communicationAbsentFacultyActivityStatus)
  }, [communicationAbsentFacultyActivity, communicationAbsentFacultyActivityStatus])
  return (

    <Grid container spacing={3}>

      <Grid item md={12} xs={12}>
        <div className="TableMainTitle">Attendance Settings</div>

        <div className="AttendanceSettings">
          <div className="TableContainer oddEvenTable">
            <div className="TableInnerHeader">
              <h3>Schedule Attendance</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <div className="infoIcon">
                        <h4>Present - start and end time</h4> <Tooltip title="Start time and end time only applies to school days." arrow><InfoOutlinedIcon /></Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ fontWeight: '700' }}>
                      {(scheduleSetting?.value?.from) ? dayjs(`2022-04-17T${scheduleSetting?.value?.from}`).format('hh:mm A') : '**'} - {(scheduleSetting?.value?.to) ? dayjs(`2022-04-17T${scheduleSetting?.value?.to}`).format('hh:mm A') : '**'}
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor=""
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        onClick={handleDrawerOpenTime}
                      />
                    </StyledTableCell>

                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <div className="infoIcon">
                        <h4>Late - start and end time</h4> <Tooltip title="Start time and end time only applies to school days." arrow><InfoOutlinedIcon /></Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{ fontWeight: '700' }}>
                      {(scheduleSettingLate?.value?.from) ? dayjs(`2022-04-17T${scheduleSettingLate?.value?.from}`).format('hh:mm A') : '**'} - {(scheduleSettingLate?.value?.to) ? dayjs(`2022-04-17T${scheduleSettingLate?.value?.to}`).format('hh:mm A') : '**'}
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor=""
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        onClick={handleDrawerOpenTimeLate}
                      />
                    </StyledTableCell>

                  </StyledTableRow>



                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

      </Grid>

      <Grid item md={12} xs={12}>
        <div className="AttendanceSettings">
          <div className="TableContainer oddEvenTable">
            <div className="TableInnerHeader">
              <h3>Communication</h3>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow onClick={handleDrawerOpenCommunication}>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <h4>Absent students</h4>
                      Send notification to the guardian/parents of absent students
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <SwitchButtonIOS
                        label={(communicationAbsentStudentActivity === "true") ? "Enabled" : "Disabled"}
                        BorderLine={true}
                        handleChange={(e) => {
                          setOpenCommunication(true);
                          setCommunicationAbsentFacultyActivityStatus(false)
                        }}
                        // handleChange={(e) => console.log(e.target.checked)}
                        checked={(communicationAbsentStudentActivity === "true") ? true : false}
                        name="checkedA"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor=""
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        onClick={() => {
                          handleDrawerOpenCommunication();
                          setCommunicationAbsentFacultyActivityStatus(false);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <h4>Absent faculty</h4>
                      Send notification to the absent faculties
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo SwitchComingSoon">
                      <SwitchButtonIOS
                        label={(communicationAbsentFacultyActivity === "true") ? "Enabled" : "Disabled"}
                        BorderLine={true}
                        ComingSoon={false}
                        handleChange={(e) => {
                          setOpenCommunication(true);
                          setCommunicationAbsentFacultyActivityStatus(true)
                        }
                        }
                        checked={(communicationAbsentFacultyActivity === "true") ? true : false}
                        name="checkedB"
                      />
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor="#fff"
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        textTransform="initial"
                        link=""
                        onClick={() => {
                          handleDrawerOpenCommunication();
                          setCommunicationAbsentFacultyActivityStatus(true);
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>



                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>


      {/* ======================== Deenschool scan table ============================ */}


      <Grid item md={12} xs={12}>

        <div className="AttendanceSettings">
          <div className="TableContainer oddEvenTable">
            <div className="TableInnerHeader">
              <h3>DeenSchool SCAN</h3>
              <Buttons
                label="About DeenSchool SCAN"
                buttonColor=""
                border="#E8EAED 1px solid"
                color="#001233"
                icon={<img src="https://deenschool-website-assets.s3.amazonaws.com/assets/deenschool-islamic-school-management-logomark-cl.png" width="24" height="24" alt="logo mark" />}
                width=""
                height="30px"
                fontSize="14px"
                textTransform="initial"
                onClick={() => setAboutDeenSchoolDrawer(true)}
              />
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <div className="infoIcon">
                        <h4>Users</h4>
                        <Tooltip title="To access DeenSchool SCAN app add user here" arrow><InfoOutlinedIcon /></Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div style={{ display: 'flex', alignItems: 'center' }}>

                        {alreadyAddedUsers?.length > 0 &&
                          <AvatarGroup max={5} sx={AvatargroupStyle}>
                            {showAllScanUserThumb()}

                          </AvatarGroup>

                        }
                        {/* <b style={{ color: '#0450E1' }}>+2 more</b> */}
                      </div>
                      {/* <span style={{ color: '#7D8597' }}>Add users who can access DeenSchool SCAN</span> */}
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor=""
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        onClick={() => setUserDrawer(true)}
                      />
                    </StyledTableCell>

                  </StyledTableRow>

                  {1 == 2 && <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <div className="infoIcon">
                        <h4>Start and end time</h4> <Tooltip title="Specify the date & time." arrow><InfoOutlinedIcon /></Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <b>Feb 29, 8:31 am - Mar 30, 9:00 am</b>
                      {/* <span style={{ color: '#7D8597' }}>Add the start date and time as well as the end date and time</span> */}
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight">
                      <Buttons
                        label="EDIT"
                        buttonColor=""
                        border="#E4E8EC 0px solid"
                        color="#0450E1"
                        width=""
                        height="30px"
                        fontSize="14px"
                        onClick={() => setQrDatePickerDrawer(true)}
                      />
                    </StyledTableCell>

                  </StyledTableRow>}


                  {1 == 2 && <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel" width="50%">
                      <div className="infoIcon">
                        <h4>Scan access code</h4>
                        {/* <Tooltip title="Start time and end time only applies to school days." arrow><InfoOutlinedIcon /></Tooltip> */}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <b>123456</b>
                    </StyledTableCell>

                    <StyledTableCell align="left" className="TableInfo alightRight" style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Buttons
                        label="Copy"
                        buttonColor=""
                        border="#E8EAED 1px solid"
                        color="#0450E1"
                        icon={<ContentCopyIcon fontSize="small" />}
                        width=""
                        height="30px"
                        fontSize="14px"
                        textTransform="uppercase"
                      // onClick={handleDrawerOpenTime}
                      />
                      <Buttons
                        label="Regenerate"
                        buttonColor=""
                        border="#E8EAED 1px solid"
                        color="#0450E1"
                        icon={<Refresh />}
                        width=""
                        height="30px"
                        fontSize="14px"
                        textTransform="uppercase"
                      // onClick={handleDrawerOpenTime}
                      />
                    </StyledTableCell>

                  </StyledTableRow>}



                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

      </Grid>


      {/* Present Time Edit */}
      <SideDrawer
        open={openTime}
        handleDrawerClose={handleDrawerCloseTime}
        title="Present - start and end time"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={(e) => updateScheduleAttendenceHandler()}
        body={
          <>
            <StartAndEndTime
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              startTime={startTime}
              endTime={endTime}
              errorStartTime={errorStartTimePresent}
              errorEndTime={errorEndTimePresent}
              errorStartEndTime={errorStartEndTimePresent}
              errorMessage={errorMessage}
            />
          </>
        }
      />
      {/* Late Time Edit */}
      <SideDrawer
        open={openTimeLate}
        handleDrawerClose={handleDrawerCloseTimeLate}
        title="Late - start and end time"
        buttonLabel="SAVE"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={(e) => updateScheduleAttendenceHandlerLate()}
        body={
          <>
            <StartAndEndTime
              setStartTime={setStartTimeLate}
              setEndTime={setEndTimeLate}
              startTime={startTimeLate}
              endTime={endTimeLate}
              errorStartTime={errorStartTimeLate}
              errorEndTime={errorEndTimeLate}
              errorStartEndTime={errorStartEndTimeLate}
              errorMessage={errorMessage}
            />
          </>
        }
      />

      {/* Communication*/}
      <SideDrawer
        open={openCommunication}
        handleDrawerClose={handleDrawerCloseCommunication}
        title="Communication"
        buttonLabel="SAVE"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={(e) => updateCommunicationHandler()}
        body={
          <>
            <Communication
              communicationAbsentStudentActivity={communicationAbsentStudentActivity}
              communicationAbsentFacultyActivity={communicationAbsentFacultyActivity}
              setCommunicationAbsentStudentActivity={setCommunicationAbsentStudentActivity}
              setCommunicationAbsentFacultyActivity={setCommunicationAbsentFacultyActivity}
              communicationAbsentStudentEmail={communicationAbsentStudentEmail}
              setCommunicationAbsentStudentEmail={setCommunicationAbsentStudentEmail}
              communicationAbsentFacultyEmail={communicationAbsentFacultyEmail}
              setCommunicationAbsentFacultyEmail={setCommunicationAbsentFacultyEmail}
              communicationAbsentStudentMessage={communicationAbsentStudentMessage}
              setCommunicationAbsentStudentMessage={setCommunicationAbsentStudentMessage}
              communicationAbsentFacultyMessage={communicationAbsentFacultyMessage}
              setCommunicationAbsentFacultyMessage={setCommunicationAbsentFacultyMessage}
              communicationAbsentStudentTime={communicationAbsentStudentTime}
              setCommunicationAbsentStudentTime={setCommunicationAbsentStudentTime}
              communicationAbsentFacultyTime={communicationAbsentFacultyTime}
              setCommunicationAbsentFacultyTime={setCommunicationAbsentFacultyTime}
              communicationAbsentFacultyActivityStatus={communicationAbsentFacultyActivityStatus}
            />
          </>
        }
      />


      {/* === About Deenschool ===*/}
      <SideDrawer
        open={aboutDeenSchoolDrawer}
        handleDrawerClose={() => setAboutDeenSchoolDrawer(false)}
        title="About DeenSchool Scan"
        buttonLabel="SAVE"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={false}
        clickSaveButton={(e) => updateCommunicationHandler()}
        body={
          <>
            <AboutDeenschool />
          </>
        }
      />

      {/* === QR Date picker Deenschool ===*/}
      <SideDrawer
        open={qrDatePickerDrawer}
        handleDrawerClose={() => setQrDatePickerDrawer(false)}
        title="About DeenSchool Scan"
        buttonLabel="SAVE"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={false}
        clickSaveButton={(e) => updateCommunicationHandler()}
        body={
          <>
            <ScanTime />
          </>
        }
      />
      {/* === user Drawer  ===*/}
      <SideDrawer
        open={userDrawer}
        handleDrawerClose={() => setUserDrawer(false)}
        title="Users"
        buttonLabel="SAVE"
        // buttonLink={}
        padding="0px"
        // drawerWidth="min(100%, 724px)"
        drawerWidth="724px"
        sideOpen="right"
        ButtonLabel="SAVE"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={false}
        clickSaveButton={(e) => updateCommunicationHandler()}
        body={
          <>
            <ScanUser />
          </>
        }
      />

    </Grid>



  );
};

export default AttendanceSettings;
