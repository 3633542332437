import { api } from '../../api/apiSlice';

const emailFromApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getEmailSenders: builder.query({
            query: () => ({
                url: '/email-from/find-all',
            }),
            providesTags: ['EmailSerders'],
        }),
        addEmailSender: builder.mutation({
            query: (data) => ({
                url: '/email-from/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['EmailSerders'],
        })

    }),
});

export const {
    useGetEmailSendersQuery,
    useAddEmailSenderMutation
} = emailFromApi;
