import React, { useState } from "react";
import Buttons from "../../Form/Button/Button";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { PiUploadSimpleBold } from "react-icons/pi";
import { IconButton } from "@mui/material";
import cardImg from "../../../assets/imgs/tableImg.png"; 
import "./PictureID.css";
import Modals from "../../Form/Modals/Modals";
import FileUploadPicID from "./FileUploadPicID/FileUploadPicID";

const PictureID = () => {
  const [modal, setModal] = useState(false);
  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);  

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Buttons
          label="UPLOAD FROM DEVICE"
          icon={<PiUploadSimpleBold />}
          buttonColor="#FFB606"
          // border="#E4E8EC 1px solid"
          borderRadius="8px"
          color="#000000"
          width=""
          height="40px"
          fontSize="14px"
          // link=""
          onClick={() => handleOpen()}
        />

        <Buttons
          label="TAKE PHOTO"
          icon={<PiUploadSimpleBold />}
          buttonColor="transparent"
          border="1px solid #FFB606"
          borderRadius="8px"
          color="#000000"
          width=""
          height="40px"
          fontSize="14px"
          // link=""
          onClick={() => handleOpen()}
        />
      </div>
      <div>
        <div className="accordionItemCard">
          <div className="cardInfo">
            <img src={cardImg} alt="cardImg" />
            <p>000022-shayan-mahmud-07202023</p>
          </div>
          <IconButton>
            <DeleteForeverRoundedIcon />
          </IconButton>
        </div>
      </div>

      <Modals
        ModalTitle="Upload from device"
        handleClose={() => handleClose()}
        // handleConfirm={() => handleConfirm()}
      
        open={modal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"

        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={false}

        ModalBody={
          <div>
            <FileUploadPicID/>
          </div>
        }

      />
    </div>
  );
};

export default PictureID;
