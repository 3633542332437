import { api } from '../../api/apiSlice';

const schoolYearApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolYearList: builder.query({
      query: () => ({
        url: '/school-year/findall',
      }),
      providesTags: ['SchoolYears'],
    }),
    getSchoolYearDetails: builder.query({
      query: (id) => ({
        url: `/school-year/getSingle/${id}`,
      })
    }),
    createSchoolYear: builder.mutation({
      query: (data) => ({
        url: '/school-year/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SchoolYears'],
    }),
    updateSchoolYear: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/school-year/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['SchoolYears'],
    }),
    deleteSchoolYear: builder.mutation({
      query: (id) => ({
        url: `/school-year/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SchoolYears'],
    }),
    getSchoolYearSearchResult: builder.mutation({
      query: (data) => ({
        url: '/school-year/search',
        method: 'POST',
        body: data,
      })
    }),
    checkSchoolYearName: builder.mutation({
      query: (data) => ({
        url: '/school-year/checkName',
        method: 'POST',
        body: data,
      })
    }),
    checkSchoolYearTermName: builder.mutation({
      query: (data) => ({
        url: '/school-year/checkTermName',
        method: 'POST',
        body: data,
      })
    })

  }),
});

export const {
  useGetSchoolYearListQuery,
  useGetSchoolYearDetailsQuery,
  useCreateSchoolYearMutation,
  useUpdateSchoolYearMutation,
  useDeleteSchoolYearMutation,
  useGetSchoolYearSearchResultMutation,
  useCheckSchoolYearNameMutation,
  useCheckSchoolYearTermNameMutation
} = schoolYearApi;
