import React, { useState } from "react";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import Table from "../../../components/Table/AttendanceTable";
import Grid from '@mui/material/Grid';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { RxPerson } from 'react-icons/rx';

const AttendanceHistoryDetails = () => {




  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "P",
      value: "P",
    },

    {
      label: "A",
      value: "A",
    },

  ]



  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "10%", textAlign: "left", },
  ];



  const rows = [

    {
      cell: [

        { linkText: "Shayan Mahmud ", linkTextStatus: true, link: "#", },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "L", CellColor: "#FFF3E0", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "HOL", CellColor: "#E0E3EA", TextAlign: "center", textcolor: "#00164E" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "WKD", CellColor: "#E1EAFB", TextAlign: "center", textcolor: "#0450E1" },
        { textBold: "WKD", CellColor: "#E1EAFB ", TextAlign: "center", textcolor: "#0450E1" },
      ],
    },

    {
      cell: [

        { linkText: "Shayan Mahmud ", linkTextStatus: true, link: "#", },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "L", CellColor: "#FFF3E0", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "HOL", CellColor: "#E0E3EA", TextAlign: "center", textcolor: "#00164E" },
        { textBold: "ED", CellColor: "#E6E3E5", TextAlign: "center", textcolor: "#2C122A" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "WKD", CellColor: "#E1EAFB", TextAlign: "center", textcolor: "#0450E1" },
        { textBold: "WKD", CellColor: "#E1EAFB ", TextAlign: "center", textcolor: "#0450E1" },
      ],
    },

    {
      cell: [

        { linkText: "Shayan Mahmud ", linkTextStatus: true, link: "#", },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "L", CellColor: "#FFF3E0", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "HOL", CellColor: "#E0E3EA", TextAlign: "center", textcolor: "#00164E" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "WKD", CellColor: "#E1EAFB", TextAlign: "center", textcolor: "#0450E1" },
        { textBold: "WKD", CellColor: "#E1EAFB ", TextAlign: "center", textcolor: "#0450E1" },
      ],
    },

    {
      cell: [

        { linkText: "Shayan Mahmud ", linkTextStatus: true, link: "#", },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "L", CellColor: "#FFF3E0", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "HOL", CellColor: "#E0E3EA", TextAlign: "center", textcolor: "#00164E" },
        { textBold: "ED", CellColor: "#E6E3E5", TextAlign: "center", textcolor: "#2C122A" },
        { textBold: "P", CellColor: "#E2F4E2", TextAlign: "center", textcolor: "#10A711" },
        { textBold: "WKD", CellColor: "#E1EAFB", TextAlign: "center", textcolor: "#0450E1" },
        { textBold: "WKD", CellColor: "#E1EAFB ", TextAlign: "center", textcolor: "#0450E1" },
      ],
    },



  ];

  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const dateSliderData = [
    {
      Slides: <div className="WeekSldier">
        <li><span>MON</span> <label className="DayActicve">12</label></li>
        <li><span>TUE</span> <label>13</label></li>
        <li><span>WED</span> <label>14</label></li>
        <li><span>THU</span> <label>15</label></li>
        <li><span>FRI</span> <label>16</label></li>
        <li><span>SAT</span> <label>17</label></li>
        <li><span>SUN</span> <label>18</label></li>
      </div>

    },

    {
      Slides: <div className="WeekSldier">
        <li><span>MON</span> <label className="DayActicve">12</label></li>
        <li><span>TUE</span> <label>13</label></li>
        <li><span>WED</span> <label>14</label></li>
        <li><span>THU</span> <label>15</label></li>
        <li><span>FRI</span> <label>16</label></li>
        <li><span>SAT</span> <label>17</label></li>
        <li><span>SUN</span> <label>18</label></li>
      </div>

    },

  ];


  const breadcrumbsData = [
    {
      label: "Users",
      link: "/attendance-history",
      icon: <RxPerson />,
    },

    {
      label: "Details",
    },

  ];

  return (
    <>
      <Breadcrumbs
        breadcrumbsData={breadcrumbsData}
      />
      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <div className="UserDetails">
            <li>
              <div className="UserDetailsleftcol">
                <div className="UserDetailsrIcon">
                  <Avatar
                    sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                  ></Avatar>
                </div>

                <div className="UserDetailsDetail">
                  <span style={{ display: "inline-block" }}>
                    <h4>
                      Shayan Mahmud
                    </h4>
                  </span>{" "}
                  <span
                    style={{ display: "inline-block" }}
                    className="status rounded success"
                  >
                    Student
                  </span>
                  <p>

                    ID: 000001
                  </p>
                </div>
              </div>

              <div className="UserListingAction">
                <div align="left">
                  <div className="EditUserProfile">
                    <div className="ActionButton">
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        Actions <KeyboardArrowDownRoundedIcon />
                      </Button>
                    </div>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <span onClick={handleClose} className="MenuListContainer">
                        <MenuItem>Revoke Access</MenuItem>
                      </span>
                      <span onClick={handleClose} className="MenuListContainer">
                        <MenuItem>Delete Customer</MenuItem>
                      </span>
                    </Menu>
                  </div>
                </div>
              </div>
            </li>

          </div>

        </Grid>
        <Grid item md={12} xs={12}>
          <div className="TableSection">
            <Table
              YearLabel="May 2023"
              headCells={headCells}
              rows={rows}
              dateSliderData={dateSliderData}
              footer={true}
            />
          </div>
        </Grid>
      </Grid>

    </>
  );
};

export default AttendanceHistoryDetails;
