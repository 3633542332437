import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from '../redux/features/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DraggableDialog from '../components/hooks/Dialog';

const useInactivity = (timeout = 30000) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [isInactive, setIsInactive] = useState(false);
  const timerRef = useRef(null);
  const handleLogout = async () => {
    dispatch(logout());
    // * custom navigate
    navigate('/authentication/signin');
  };

  const resetTimer = async () => {
    setIsInactive(false);
    localStorage.setItem('inactive', false);
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(async () => {
      setIsInactive(true);
      localStorage.setItem('inactive', true);
      await handleLogout();
    }, timeout);
  };
  useEffect(() => {
    const events = [
      'mousemove',
      'keydown',
      'click',
      'scroll',
      'keypress',
      'mousedown',
      '',
    ];
    const handleActivity = () => resetTimer();

    events.forEach((event) => window.addEventListener(event, handleActivity));

    // Set initial inactivity timer
    resetTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity),
      );

      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [timeout]);

  return { isInactive };
};
export default useInactivity;
