import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import { useEffect } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { toTitleCase } from '../../../utils/helpers';
import useProtected from '../../../hooks/useProtected';
import { useGetStudentsOfGuardianQuery } from '../../../redux/features/users/userApi';

const Applications = ({ userId, userUniqueID }) => {

    const { data, isLoading, isError, error } = useGetStudentsOfGuardianQuery(userUniqueID, { refetchOnMountOrArgChange: true });

    useProtected(error);

    const [AddDrawer, setAddDrawer] = useState(false);
    const handleOpenAdd = () => setAddDrawer(true);
    const handleCloseDrawer = () => {
        setAddDrawer(false);
    };


    const [innerList, setInnerList] = useState([]);

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: '20%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'ID',
            width: '18%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Email',
            width: '18%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Status',
            width: '20%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: '',
            width: '20%',
            textAlign: 'left',
        },
    ];

    const renderStatusColor = (status) => {
        switch (status) {
            case 'invitation_pending':
                return { bg: '#EDEDEF', color: '#676C7B' };
            case 'inactive':
                return { bg: '#FCE7E9', color: '#E63946' };
            case 'active':
                return { bg: '#EDF7EA', color: '#66BD50' };
            default:
                return { bg: '', color: '' };
        }
    };

    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => ({
                item: item,
                cell: [
                    { textBold: `${item?.firstName} ${item?.lastName}` },
                    { text: item?.userUniqueID || 'N/A' },
                    { text: item?.email || 'N/A' },
                    {
                        Status: true,
                        background: renderStatusColor(item.status).bg,
                        color: renderStatusColor(item.status).color,
                        label: item?.status ? toTitleCase(item?.status) : 'N/A',
                    },
                    {
                        Button: true,
                        buttonLabel: 'Details',
                        buttonTextcolor: '#004FE0',
                        buttonLink: `/users/${item._id}`,
                    },
                ],
            }));
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (!isLoading && !isError) {
            setInnerList(convertDataToCellRows(data?.body?.data));
        }
    }, [data, isLoading, isError])



    return (
        <>
            <div className="TableSection">
                <Table
                    innerTitle="Students"
                    headCells={headCells}
                    rows={innerList}
                    footer={true}
                    innerHeader={true}
                    isDataLoading={isLoading}
                    AddButtonInner={false}
                    addLabelInner="Add guardian/Parent"
                    InnerButtonColor="#FFB606"
                    InnerButtonTextColor="#000"
                    InnderButtonIcon={<AddRoundedIcon />}
                    onClickInnerButton={handleOpenAdd}
                    emptyTableTitle="No student yet"
                    emptyTableNote="No items available"
                    btnLabel="Add guardian/parent"
                    showButton={false}
                    onClickEmptyCard={handleOpenAdd}

                />
            </div>

            {/* Add Guardians/Parents */}
            <SideDrawer
                open={AddDrawer}
                handleDrawerClose={handleCloseDrawer}
                title="Add Guardians/Parents"
                buttonLabel="Add now"
                // buttonLink={}
                CheckBoxTabsStatus={true}
                TabsPannelStatus={true}
                sideOpen="right"
                ButtonLabel="Add now"
                cancelButtonLabel="Cancel"
                Checkbox={false}
                CheckboxLabel="Add another after saving"

            />


        </>
    );
};

export default Applications;
