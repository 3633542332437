import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchResultMutation,
  useGetSearchAttendanceUsersMutation,
  useGetUserByAttendanceQuery
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';
import { useGetAllGroupsQuery, useGetGroupsSearchResultMutation } from '../../../redux/features/groups/groupsApi';
import { toast } from 'react-toastify';

const SelectGroups = ({
  selectUsers,
  handleCloseSelectUsers,
  selectedList,
  selectedListJust,
  renderFullItem = false,
  addedGroupsToUpdate,
  addedGroups,
  setAddedGroupsToUpdate,
  setAddedGroups
}) => {
  const { data, isError, isLoading, error } = useGetAllGroupsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [getSearchedUserList, options] = useGetGroupsSearchResultMutation();

  useProtected(error);
  useProtected(options.error);
  const [rows, setRows] = useState([]);
  const [seletectedGroupUsers, setSeletectedGroupUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        item: item,
        cell: [
          {
            Check: true,
            checkBoxColor: 'secondary',
            width: "48px",
            padding: "0",
            group_id: item._id,
            checked: true,
            group: {
              id: item?._id,
              name: item?.name,
              description: item?.description,
              users: item?.users,
              schoolId: item?.schoolId,
            }

          },
          {
            textBold: item?.name || 'N/A',
            group_id: item._id
            // handleSelect: () => handleSelectUser(item),
          },
          { text: '' },
          { text: '' },
          // { text: (item?.users) ? item?.users?.length : 0 },
          { Status: true, background: '#EDEDEF', color: '#676C7B', linebackground: "#979DAC ", statusLine: false, label: <span style={{ fontWeight: '600' }}>{(item?.users) ? item?.users?.length : 0}&nbsp;{item?.users?.length === 1 ? 'user' : 'users'}</span>, },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      let groupsToShow = data?.body?.data?.filter((group) => group.users.length > 0)

      setRows(convertDataToCellRows(groupsToShow));
      setSeletectedGroupUsers(groupsToShow)
    }
  }, [data, isLoading, isError]);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },

    {
      id: 'Class/Level',
      numeric: false,
      disablePadding: true,
      label: 'Users',
      width: '30%',
      textAlign: 'left',
    },
  ];
  //-----search part start------//
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);

  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: "" });
    searchDataShowing(result)
  };

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      let groupsToShow = result.data.body.data?.filter((group) => group.users.length > 0)
      setRows(convertDataToCellRows(groupsToShow));
      setSeletectedGroupUsers(groupsToShow)
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  //-----search part end------//


  // const handleSearchText = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(e.target.value);
  // };

  // const handleSearch = async () => {
  //   const result = await getSearchedUserList({ term: searchTerm });
  //   if (result?.data) {
  //     let groupsToShow = result.data.body.data?.filter((group) => group.users.length > 0)
  //     setRows(convertDataToCellRows(groupsToShow));
  //     setSeletectedGroupUsers(groupsToShow)
  //   }
  // };
  useEffect(() => {
    let newGroups = []
    addedGroups?.map((groupId) => {

      let foundGroups = seletectedGroupUsers.find((group) => group?._id === groupId || group?.id === groupId)
      if (foundGroups) {
        newGroups.push(foundGroups)
      }
    })
    setAddedGroupsToUpdate(newGroups)

  }, [addedGroups, seletectedGroupUsers])





  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the group to the array
      setAddedGroups(prevGroups => [...prevGroups, item[0].group]);
      // setAddedGroupsToUpdate(prevGroups => [...prevGroups, item[0].group]);
    } else {
      // Remove the group from the array
      setAddedGroups(prevGroups => prevGroups.filter(group => group?.id !== item[0].group?.id));
      // setAddedGroupsToUpdate(prevGroups => prevGroups.filter(group => group.id !== item[0].group.id));
    }
  }

  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          pageNumberFromOutSide={pageNumberFromOutSide}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          showSearchBox={true}
          handleCheckItem={setAddedGroups}
          selectedCheckList={addedGroups}
          handleRowClick={handleRowClick}
          customCellObjectId="group_id"
          searchBoxPlaceholder="Search users"
          RowsPerPage={20}
          CheckboxDelete={true}
        />
      </div>
      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } users`}
      />
    </>
  );
};

export default SelectGroups;
