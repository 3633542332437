import React, { useState } from "react";
import ModalsResponsiveFullScreen from '../../../components/Form/Modals/ModalsResponsiveFullScreen';
import TextField from "../../../components/Form/TextField/TextField";
import { useAddEmailSenderMutation } from "../../../redux/features/messages/emailFromApi";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";






const AddSender = (props) => {
  const [addEmailSender, addEmailSenderOptions] = useAddEmailSenderMutation()

  useProtected(addEmailSenderOptions.error)

  const { openModal, handleClose } = props;



  const [fromName, setFromName] = useState('')


  const handleAddEmailSender = async () => {
    let data = {
      fromName,
      fromEmail: ""
    }
    let result = await addEmailSender(data)

    if (result?.data && result?.data?.body?.data) {
      toast.success("Sender Added")
      handleClose()
      setFromName("")

    } else {
      toast.error("Something went wrong")
    }


  }


  return (
    <ModalsResponsiveFullScreen
      ModalTitle="Add Sender"
      HeaderFontWeight={600}
      open={openModal}
      handleClose={handleClose}
      handleConfirm={handleAddEmailSender}
      ModalFooter={true}
      ButtonClose="CANCEL"
      ButtonConfirm="SAVE"
      width="552px"
      Hieght="110px"
      textAlign="center"
      ModalBody={
        <div className="ModalSingleField">
          <TextField
            label="Sender Name"
            required={true}
            value={fromName}
            handleChange={(val, e) => setFromName(val)}
            count={50}
          />
        </div>
      }
    />
  );
};

export default AddSender;
