import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import NewMessage from './NewMessage';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const Recipients = (props) => {
  const {
    messageDetails,
    usersToShow
  } = props



  const headCells = [
    { id: "to", numeric: false, disablePadding: true, label: "Name", width: "30%", textAlign: "left", },
    { id: "message", numeric: false, disablePadding: true, label: "Phone", width: "20%", textAlign: "left", },
    { id: "date", numeric: false, disablePadding: true, label: "Email", width: "35%", textAlign: "left", },
    { id: "type", numeric: false, disablePadding: true, label: "# of recipients", width: "15%", textAlign: "left", },
  ];
  function formatPhoneNumber(s) {
    // Remove all non-numeric characters
    const cleaned = ('' + s).replace(/\D/g, '');

    const length = s.length;

    // console.log(length)

    if (length == 11) {

      // Check if the cleaned phone number has 11 digits (with country code)
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }
    } else if (length == 13) {

      // Check if the cleaned phone number has 13 digits (with country code)
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }
    } else if (length == 12) {

      // Check if the cleaned phone number has 12 digits (with country code)
      const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
      }
    }

    return s;
  }
  function PhoneComponent(phoneNumber) {

    let phone = formatPhoneNumber(phoneNumber?.trim());

    if (!phone.startsWith('+')) {
      phone = '+' + phone;
    }


    return (phone);
  }

  const rows = [];

  usersToShow?.map((user) => {
    rows.push(
      {
        cell: [
          { textBold: user?.name || "N/A", },
          { text: (user?.phone) ? PhoneComponent(user?.phone) : "N/A" },
          // { text: "N/A", VerifyIcon: true, icon: <CheckRoundedIcon />, },
          { text: user?.email || "N/A", VerifyIcon: (user?.email) ? true : false, icon: <CheckRoundedIcon />, },
          { Status: true, background: '#EDEDEF', color: '#676C7B', label: '1', },
        ],
      })
  })

  messageDetails?.toGroups?.map((group) => {
    rows.push({
      cell: [
        { textBold: group?.name || "N/A", },
        { text: "", },
        { text: "", },
        { Status: true, background: '#EDEDEF', color: '#676C7B', label: group?.users?.length, },
      ],
    })
  })



  // Filter //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const handleCreateDrawerOpen = () => {
    setOpenCreate(true);
  };
  const handleCloseCreateDrawer = () => {
    setOpenCreate(false);
  };



  const status = [
    { id: 1, label: "In Progress" },
    { id: 2, label: "Dropped" },
    { id: 3, label: "Blocked" },
    { id: 4, label: "Completed" },
    { id: 5, label: "Unavailable" },
  ];
  // Filter //



  const tabData = [
    {
      title: "SMS",
    },

    {
      title: "Voice",
    },

    {
      title: "Email",
      comingSoon: true,
      comingSoonText: "Coming soon",
      disabled: "disabled",
    },

  ]


  const panelTab = [
    {
      id: 0,
      panleBody: (
        <NewMessage />
      ),

      SaveButtonLabel: 'Send',
      //clickSaveButton: handleAddGuardian,
      CancelButtonLabel: 'Cancel',
    },

    {
      id: 1,
      panleBody: (
        <NewMessage />
      ),
      SaveButtonLabel: 'Send Invitation',
      //clickSaveButton: handleInviteUsers,
      CancelButtonLabel: 'Cancel',
    },
  ];


  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
        // isDataLoading={isLoading || options?.isLoading}
        />
      </div>


      {/* Filter */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="New Message"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        tabData={tabData}
        panelTab={panelTab}
        CheckBoxTabsStatus={true}
        TabsPannelStatus={true}
      />

    </>
  );
};

export default Recipients;
