import { Button, Grid, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import SelectFieldMulticolor from '../../../components/Form/SelectFieldMulticolor/SelectFieldMulticolor'
import TextField from '../../../components/Form/TextField/TextField';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';
import SelectField from '../../../components/Form/SelectField/SelectField';
import GooglePlaceField from '../../../components/Form/GooglePlaceField/GooglePlaceField';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import { countries } from '../../../assets/data/countries';

const EmergencyContactsDrawer = (props) => {
    const {
        emergencyContact,
        setEmergencyContact,
        errors,
        setErrors,
        manualAddress, setManualAddress
    } = props



    const Statustdata = [
        { value: 'active', statuscolor: "#66BD50", select: "Active" },
        { value: 'inactive', statuscolor: "#C44058", select: "Inactive" },
    ];

    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const onChangeHandler = (val, e, fieldName) => {
        let newObject = { ...emergencyContact, [fieldName]: e.target.value }
        setEmergencyContact(newObject)
    }

    const handleChangeWithData = (fieldName, fieldData) => {
        let newObject = { ...emergencyContact, [fieldName]: fieldData }
        setEmergencyContact(newObject)
    };

    const options = [
        {
            label: "Father",
            value: "father",
        },

        {
            label: "Mother",
            value: "mother",
        },

        {
            label: "Step-father",
            value: "step-father",
        },

        {
            label: "Step-mother",
            value: "step-mother",
        },

        {
            label: "Sibling",
            value: "sibling",
        },

        {
            label: "Aunt",
            value: "aunt",
        },

        {
            label: "Uncle",
            value: "uncle",
        },

        {
            label: "Brother",
            value: "brother",
        },

        {
            label: "Sister",
            value: "sister",
        },

        {
            label: "Cousin",
            value: "cousin",
        },

        {
            label: "Grandparent",
            value: "grandparent",
        },

    ]

    const checkJsonStringOrNot = (address) => {

        if (typeof address === 'string') {

            if (address.length == 0) {
                return "";
            }

            if (/^[\],:{}\s]*$/.test(address.replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

                return JSON.parse(address);

            } else {

                return {};

            }
        } else {
            return address;
        }

    }
    return (
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                }}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Status<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <div className='emergencyContactActiveField'>
                        <SelectFieldMulticolor
                            labelTop={false}
                            labelinsideStatus={true}
                            statustData={Statustdata}
                            defaultValue={emergencyContact.status}
                            handleChange={(e) => onChangeHandler(e.target.value, e, 'status')}
                            width="100%"
                            height="40px"
                            Status={true}
                        />
                        {(errors?.status !== "") && <p style={{ color: '#d32f2f' }}>{errors?.status}</p>}
                    </div>
                </Grid>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                    marginTop: errors?.status !== "" && '-4px !important',
                }}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>First Name<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter first name"
                        required={true}
                        value={emergencyContact.firstName}
                        handleChange={(val, e) => onChangeHandler(val, e, 'firstName')}
                        error={errors?.firstName}
                        helperText={errors?.firstName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Middle Name (Optional)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter middle name"
                        value={emergencyContact.middleName}
                        handleChange={(val, e) => onChangeHandler(val, e, 'middleName')}
                        error={errors?.middleName}
                        helperText={errors?.middleName}
                    />
                </Grid>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                    marginTop: errors?.status !== "" && '-6px !important',
                }}>
                    <Typography variant='p' sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: '#1B1B1F',
                    }}>Last Name<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter last name"
                        required={true}
                        value={emergencyContact.lastName}
                        handleChange={(val, e) => onChangeHandler(val, e, 'lastName')}
                        error={errors?.lastName}
                        helperText={errors?.lastName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Email (Optional)</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        placeholder="Enter email address"
                        value={emergencyContact.email}
                        handleChange={(val, e) => onChangeHandler(val, e, 'email')}
                        error={errors?.email}
                        helperText={errors?.email}
                    />
                </Grid>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                    marginTop: errors?.status !== "" && '-2px !important',
                }}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Phone<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <PhoneInputField
                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                        country={'us'}
                        value={emergencyContact.phone}
                        countryCodeEditable={false}
                        onChange={(value, country, e, formattedValue) => handleChangeWithData("phone", formattedValue)}
                        onBlur={() => console.log("onBlur")}

                        variant="outlined"
                        onlyCountries={["us", "ca", "bd"]}
                        containerStyle={{}}
                        buttonStyle={{}}
                        inputStyle={{
                            marginLeft: "0px",
                            height: "40px",
                            width: "100%",
                            fontFamily: "Roboto-Regular",
                            fontSize: "16px",
                        }}
                        containerClass={{}}
                        // errorText={"Phone number is required"}
                        errorStyles={{ marginBottom: "5px" }}
                        fieldBorderRadius="8px"
                        autoFormat={true}
                        disableDropdown={false}
                        disabled={false}
                        disableCountryCode={false}
                        enableSearch={false}
                        disableSearchIcon={false}
                        placeholder={""}
                        inputProps={{}}
                        specialLabel={'Primary Phone'}
                        // preferredCountries={[]}
                        // excludeCountries={[]}
                        labelFixed=""
                        LabelStatus={false}
                        //helpertext="Helper text"
                        error={errors?.phone != "" ? "error" : ""}
                    />
                    {(errors?.phone != "") && <p style={{ color: '#d32f2f' }}>{errors?.phone}</p>}
                </Grid>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                    marginTop: errors?.status !== "" && '-6px !important',
                }}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Relationship to the student<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <SelectField
                        label="Relationship to the student "
                        defaultValue="default"
                        selectPlaceholder="Select relationship to the student"
                        options={options}
                        selectedOption={emergencyContact?.relationship}
                        helperText={errors?.relationship}
                        error={errors?.relationship}
                        handleChange={(val) => onChangeHandler(val, val, 'relationship')}
                    />
                </Grid>
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" && '0px !important',
                    marginTop: errors?.status !== "" && '-6px !important',
                }}>
                    <Typography variant='p' sx={{ fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Select country<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                <Grid item xs={8}>
                    <SelectField
                        label="Select country "
                        defaultValue="default"
                        selectPlaceholder="Select country"
                        options={countries}
                        selectedOption={emergencyContact?.country}
                        error={errors?.country}
                        helperText={errors?.country}
                        handleChange={(val) => onChangeHandler(val, val, 'country')}
                    />
                </Grid>

                {/* 
                { marginTop: '12px', fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }
                 */}
                <Grid item xs={4} sx={{
                    paddingTop: errors?.status !== "" ? '26px !important' : manualAddress ? "27px !important" : '32px !important',
                    // paddingTop: '32px !important',
                    // marginTop: errors?.status !== "" && '-6px !important',
                    alignSelf: 'stretch'
                }}>
                    <Typography variant='p' sx={{ marginTop: '22px', fontSize: '14px', fontWeight: '600', color: '#1B1B1F' }}>Address<span className='requiredAsterisk'>*</span></Typography>
                </Grid>
                {/* <Grid item xs={8} >
                    <GooglePlaceField
                        validation={true}
                        label=""
                        placeholder="Current resident address"
                        value={checkJsonStringOrNot(emergencyContact?.address)}
                        handleChange={(address) => handleChangeWithData("address", address)}
                        error={errors?.address !== "" ? true : false}
                    />
                    {(errors?.address !== "") && <p style={{ color: '#d32f2f' }}>{errors?.address}</p>}
                </Grid> */}

                {
                    manualAddress ?
                        <Grid item xs={12} sm={12} md={8}>
                            <GooglePlaceField
                                validation={true}
                                label=""
                                placeholder="Current resident address"
                                value={checkJsonStringOrNot(emergencyContact?.address)}
                                handleChange={(address) => handleChangeWithData("address", address)}
                                error={errors?.address !== "" ? true : false}
                            />
                            {(errors?.address !== "") && <p style={{ color: '#d32f2f' }}>{errors?.address}</p>}
                        </Grid>
                        :
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        placeholder="Current resident address"
                                        required={true}
                                        value={emergencyContact?.streetAddress}
                                        handleChange={(val, e) => onChangeHandler(val, e, 'streetAddress')}
                                        helperText={errors?.streetAddress}
                                        error={errors?.streetAddress}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        placeholder="Apt/Floor"
                                        required={false}
                                        value={emergencyContact?.floor}
                                        handleChange={(val, e) => onChangeHandler(val, e, 'floor')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        placeholder="City/Town/Village"
                                        required={true}
                                        value={emergencyContact?.city}
                                        handleChange={(val, e) => onChangeHandler(val, e, 'city')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        placeholder="State/Province/Territory"
                                        required={true}
                                        value={emergencyContact?.state}
                                        handleChange={(val, e) => onChangeHandler(val, e, 'state')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        placeholder="Zip/Postal code"
                                        required={true}
                                        value={emergencyContact?.zip}
                                        handleChange={(val, e) => onChangeHandler(val, e, 'zip')}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                }

                <Grid item xs={0} md={4}></Grid>
                <Grid item xs={12} sm={12} md={8} sx={{ padding: '0px' }}>
                    <Button
                        variant="text"
                        sx={{ padding: '0px', marginTop: '-12px', color: '#0450E1', textTransform: 'initial' }}
                        onClick={() => setManualAddress(!manualAddress)}
                    >
                        {manualAddress ?
                            "Enter address manually" : "Search your address"}</Button>
                </Grid>
            </Grid>
        </>
    )
}

export default EmergencyContactsDrawer