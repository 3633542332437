import React, { useState } from "react";
import Grid from '@mui/material/Grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from "dayjs";



function StartAndEndTime(props) {
  let { setStartTime, setEndTime, startTime, endTime, errorStartTime, errorEndTime, errorStartEndTime, errorMessage } = props

  return (

    <Grid container spacing={3}>

      <Grid item md={6} xs={12}>
        <div className="TimeFieldLabel">Start time</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            {/* <TimePicker value={dayjs(startTime)} onChange={(e) => setStartTime(`${e.$H}:${e.$M}`)} /> */}
            <TimePicker
              slotProps={{
                textField: {
                  error: errorStartEndTime || errorStartTime,
                  helperText: (errorStartEndTime || errorStartTime) && errorMessage,
                },
              }} defaultValue={dayjs(`2022-04-17T${startTime}`)} onChange={(e) => (e?.$H || isNaN(e?.$H) && (e.$m || isNaN(e.$m))) ? setStartTime(`${e.$H}:${e.$m}`) : setStartTime(`hh:mm`)} />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>

      <Grid item md={6} xs={12}>
        <div className="TimeFieldLabel">End time</div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker
              slotProps={{
                textField: {
                  error: errorStartEndTime || errorEndTime,
                  helperText: errorEndTime && errorMessage
                },
              }}
              defaultValue={dayjs(`2022-04-17T${endTime}`)} onChange={(e) => (e?.$H || isNaN(e?.$H) && (e.$m || isNaN(e.$m))) ? setEndTime(`${e.$H}:${e.$m}`) : setEndTime(`hh:mm`)} />
          </DemoContainer>

        </LocalizationProvider>
      </Grid>

      <Grid item md={12} xs={12}>
        <div className='MediaLibPanelInfoText'><InfoOutlinedIcon /> Start time and end time only applies to school days</div>
      </Grid>





    </Grid>



  );
};

export default StartAndEndTime;
