import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './HorizontalTab.css';



function HorizontalTab(props) {

  const { tabLabel, tabPanel, handleChange, value } = props;


  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };


  // const tabLabel = [
  //   {
  //     label: "Announcements",
  //   },
  //   {
  //     label: "Announcements",
  //   },
  //   {
  //     label: "Announcements",
  //   },
  // ]

  // const tabPanel = [
  //   {
  //     id: 0,
  //     panel: "1",
  //   },
  //   {
  //     id: 1,
  //     panel: "2",
  //   },
  //   {
  //     id: 2,
  //     panel: "3",
  //   },

  // ]

  //   <HorizontalTab
  //   tabLabel={tabLabel}
  //   tabPanel={tabPanel}
  //   value={value}
  //   handleChange={handleChange}
  // />


  return (
    <div className="HorizontalTab">

      <div className="HorizontalTabLabel">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {tabLabel.map((item, i) => (
            <Tab label={item.label} icon={item.icon} {...a11yProps(0)} />
          ))}
        </Tabs>
      </div>


      <div className="HorizontalTabPanel">
        {tabPanel.map((item, i) => (
          <CustomTabPanel value={value} index={item.id}>
            {item.panel}
          </CustomTabPanel>
        ))}
      </div>

    </div>
  );
}





export default HorizontalTab;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}