import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { Button } from "@mui/material";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import tableImg from "../../../assets/imgs/tableImg.png";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import CreateIdDrawer from "../../../components/CreateIdDrawer/CreateIdDrawer";

const Id = () => {
  const [createIdDrawer, setCreateIdDrawer] = useState(false);
  const handleOpenDrawer = () => setCreateIdDrawer(true);
  const handleCloseDrawer = () => setCreateIdDrawer(false);

  const onClick = () => {
    alert("onclick working fine!");
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "40%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "ID # ",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Gender ",
      width: "5%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "User type ",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Issued",
      width: "15%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Contact",
      width: "15%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Status",
      width: "5%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
  ];

  const rows = [
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Omar Suleiman",
          align: "left",
        },
        { textBold: "123456789", align: "left" },
        { textBold: "Male ", align: "left" },
        { textBold: "Staff ", align: "left" },
        { textBold: "Jun 15, 2006", align: "left" },
        { textBold: "+1 (123) 123-1234", align: "left" },
        {
          Status: true,
          background: "#E1F2E8",
          color: "#0B9444",
          label: "Active",
        },
        { ActionButton: true, ActionButtonLabel: "Action", align: "left" },
      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ],
      //======= Action Date End===== //
    },
    {
      cell: [
        {
          img: tableImg,
          imgStatus: true,
          imgText: "Omar Suleiman",
          align: "left",
        },
        { textBold: "123456789", align: "left" },
        { textBold: "Male ", align: "left" },
        { textBold: "Staff ", align: "left" },
        { textBold: "Jun 15, 2006", align: "left" },
        { textBold: "+1 (123) 123-1234", align: "left" },
        {
          Status: true,
          background: "#E1F2E8",
          color: "#0B9444",
          label: "Active",
        },
        { ActionButton: true, ActionButtonLabel: "Action", align: "left" },
      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ],
      //======= Action Date End===== //
    },
  ];

  const tabData = [
    {
      title: "Users",
    },
    {
      title: "Visitors (comin soon)",
    },
  ];

  return (
    <>
      <div className="TableSection">
        <Table
          title="ID"
          tableHeader={true}
          addLabel="CREATE ID"
          AddButton={true}
          headCells={headCells}
          rows={rows}
          CheckboxDelete={true}
            onClick={handleOpenDrawer}
          footer={true}
          //   handleSearchText={handleSearchText}
          //   handleSearch={handleSearch}
          innerHeader={true}
          showSearchBox={true}
          FilterButton={false}
          searchBoxPlaceholder="Search"
        />
      </div>

      {/* new user drawer */}
      <SideDrawer
        open={createIdDrawer}
        handleDrawerClose={handleCloseDrawer}
        title="Create ID"
        buttonLabel="Save"
        // buttonLink={}
        // panelTab={panelTab}
        CheckBoxTabsStatus={true}
        tabData={tabData}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={true}
        CheckboxLabel="Add another after saving"
        FooterWithoutTab={true}
        body={
          <>
            <CreateIdDrawer />
          </>
        }
      />
    </>
  );
};

export default Id;
