import React, { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import StudentDetailsTable from './StudentDetailsTable';
import Attendance from './Attendance';
import GuardiansParents from './GuardiansParents';

import ComingSoon from '../../../components/ComingSoon/ComingSoon';
import { getProfileAvatarFromName, toTitleCase } from "../../../utils/helpers";
import { useSchoolYearfindByDateMutation } from "../../../redux/features/users/userApi";
import useProtected from "../../../hooks/useProtected";



const StudentDetails = (props) => {
  const { singleStudentDetails, academicStartDate, academicEndDate, section_id, course_id } = props



  const { _id, firstName, lastName, userType, status, userUniqueID, nysedId, gender, gurdians, profilePic, createdAt, } = singleStudentDetails

  const [value, setValue] = React.useState(0);
  const [startedAcademicYear, setStartedAcademicYear] = useState('');
  const [endedAcademicYear, setEndedAcademicYear] = useState('');
  const [getSchoolYearfindByDate, getSchoolYearfindByDateOptions] = useSchoolYearfindByDateMutation()
  useProtected(getSchoolYearfindByDateOptions.error);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (createdAt) {
      getSchoolYearData()
    }
  }, [createdAt])

  const [getStatusData, setGetStatusData] = useState('')
  useEffect(() => {
    if (status) {
      setGetStatusData(status)
    }
  }, [status])

  const getSchoolYearData = async () => {
    const result = await getSchoolYearfindByDate({ date: createdAt })
    let startData = "";
    let endData = ""
    if (result?.data?.body?.data) {
      startData = result?.data?.body?.data?.startDate
      endData = result?.data?.body?.data?.endDate
    }
    setStartedAcademicYear(startData)
    setEndedAcademicYear(endData)
    // console.log(startData, 278, endData)
  }




  return (
    <>

      <div className="StudentDetailsContainer">
        <div className="BlueSection"></div>
        <div className="P_imgSection">
          {/* <Avatar alt={firstName} src={profilePic}> </Avatar> */}
          <Avatar alt={firstName} src={profilePic ? profilePic : getProfileAvatarFromName(firstName)}>
          </Avatar>
          {/* <Avatar> </Avatar> */}
          <div className="P_imgSectionInfo">
            <h1>{firstName ? firstName : "N/A"} {lastName ? lastName : "N/A"} <FiberManualRecordIcon /></h1>
            <div className="P_imgSectionInfoSub">
              <p>{userType ? toTitleCase(userType) : "N/A"}</p><span></span><p>ID: {userUniqueID ? userUniqueID : "N/A"}</p>
            </div>
          </div>
        </div>


        <div className="H_Tab_c">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Attendance" {...a11yProps(1)} />
              <Tab label="Guardians/Parents" {...a11yProps(2)} />
              <Tab label="communication" {...a11yProps(3)} />
              <Tab label="Assignment" {...a11yProps(4)} />
            </Tabs>
          </Box>


          <div className="TsbPanelContainer">
            <CustomTabPanel value={value} index={0}>
              <StudentDetailsTable
                startedAcademicYear={startedAcademicYear}
                endedAcademicYear={endedAcademicYear}
                _id={_id}
                gender={gender}
                nysedId={nysedId}
                userUniqueID={userUniqueID}
                academicStartDate={academicStartDate}
                academicEndDate={academicEndDate}
                status={status}
                section_id={section_id} course_id={course_id}
                getStatusData={getStatusData}
                setGetStatusData={setGetStatusData}
              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Attendance section_id={section_id} student_id={_id} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <GuardiansParents
                gurdians={gurdians}
              />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
              <ComingSoon />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
              <ComingSoon />
            </CustomTabPanel>
          </div>
        </div>
      </div >


    </>
  );
};

export default StudentDetails;


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}