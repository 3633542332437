import React, { useEffect, useState } from 'react';
import TextField from '../../../../components/Form/TextField/TextField';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import SelectCard from '../../../../components/Form/SelectCard/SelectCard';
import PriceTextField from '../../../../components/Form/PriceTextField/PriceTextField';
import SelectField from '../../../../components/Form/SelectField/SelectField';
import Datefield from '../../../../components/Form/Datefield/Datefield';
import MultipleSelet from '../../../../components/Form/MultipleSelet/MultipleSelet';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import SelectIndividuals from './SelectIndividuals';
import SelectClass from './SelectClass';
import { RxPerson } from 'react-icons/rx';
import { RiGroupLine } from "react-icons/ri";
import EventIcon from '@mui/icons-material/Event';
import Table from '../../../../components/Table/Table';
import { IoSchoolOutline } from "react-icons/io5";

import dayjs from 'dayjs';
import SelectGroups from './SelectGroups';


const Billing = (props) => {

    let {
        editFeeId,
        feeObject,
        feeDetails,
        studentList,
        setFeeObject,
        feeErrorObject,
        schoolYearList,
        classesSelected,
        billingTypeValue,
        schoolYearClasses,
        setFeeErrorObject,
        setClassesSelected,
        setStudentsSelected,
        setBillingTypeValue,
        setSchoolYearClasses,
        setClassesSelectedIds,
        schoolYearClassesOptions,
        getClassesBySchoolYearId,
        setSchoolYearClassesOptions
    } = props

    const [value, setValue] = React.useState(0);
    const [schoolYearOptions, setSchoolYearOptions] = useState([])
    const [totalClassesStudents, setTotalClassesStudents] = useState(0)
    const [totalStudentsNumber, setTotalStudentsNumber] = useState(0)
    // const [schoolYearClassesOptions, setSchoolYearClassesOptions] = useState([])
    // const [classesSelected, setClassesSelected] = useState([])

    const onChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setBillingTypeValue(value)
    }, [value])

    const tabsData = [
        {
            title: "Recurring",
            //onClick:onClick,
        },

        {
            title: "One-time",
            //onClick:onClick,
        },
    ]




    const [lastDayStatus, setLastDayStatus] = React.useState('month');
    const [optionsProcessPaymentOn, setOptionsProcessPaymentOn] = useState([])
    const [rows, setRows] = useState([])
    const [age, setAge] = React.useState('');
    const handleChanges = (event) => {
        setAge(event.target.value);
    };

    useEffect(() => {
        if (feeObject?.billingCycle === "Weekly") {
            setLastDayStatus("week")
        } else if (feeObject?.billingCycle === "Yearly") {
            setLastDayStatus("year")
        } else if (feeObject?.billingCycle === "Every 3 months") {
            setLastDayStatus("every 3 months")
        } else if (feeObject?.billingCycle === "Every 6 months") {
            setLastDayStatus("every 6 months")
        } else {
            setLastDayStatus("month")
        }
    }, [feeObject?.billingCycle])

    useEffect(() => {
        const currentMonth = new Date(feeObject?.billingDurationStartDate).getMonth()
        const currentYear = new Date(feeObject?.billingDurationStartDate).getFullYear()
        const lastDateOfTheMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

        processPaymentOnHandle(currentMonth, lastDateOfTheMonth)


    }, [feeObject?.billingDurationStartDate])

    const processPaymentOnHandle = (selectedMonth, lastDateOfTheMonth) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const currentMonth = selectedMonth
        const currentMonthShort = (currentMonth == 11) ? months[0] : months[currentMonth + 1];

        setOptionsProcessPaymentOn([
            {
                label: `1st day (${currentMonthShort} 1)`,
                value: 1
            },
            {
                label: `2nd day (${currentMonthShort} 2)`,
                value: 2
            },
            {
                label: `3rd day (${currentMonthShort} 3)`,
                value: 3
            },
            {
                label: `4th day (${currentMonthShort} 4)`,
                value: 4
            },
            {
                label: `5th day (${currentMonthShort} 5)`,
                value: 5
            },
            {
                label: `Last day ${lastDayStatus}`,
                value: lastDateOfTheMonth
            }
        ])
    }

    useEffect(() => {
        const currentMonth = new Date(feeObject?.billingDurationStartDate).getMonth() + 1
        const currentYear = new Date(feeObject?.billingDurationStartDate).getFullYear()
        const lastDateOfTheMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const currentMonthShort = (currentMonth == 11) ? months[0] : months[currentMonth + 1];


        let rowsToAdd = []

        let billingCycleTime = 0
        if (feeObject?.billingCycle == 'Monthly') {
            billingCycleTime = 1
        } else if (feeObject?.billingCycle == '3-months') {
            billingCycleTime = 3
        } else if (feeObject?.billingCycle == '6-months') {
            billingCycleTime = 6
        }


        let changingMonth = currentMonth + 1
        for (let cycle = 1; cycle <= feeObject?.billingCycleCount; cycle++) {
            const cycleYear = currentYear;
            let cycleMonth = (cycle == 1) ? (changingMonth - 1) % 12 : (changingMonth + billingCycleTime - 1) % 12; // Calculate the month for the current cycle
            const cycleYearAdjustment = (cycle == 1) ? Math.floor((changingMonth - 1) / 12) : Math.floor((changingMonth + billingCycleTime - 1) / 12); // Adjust year if needed

            const formattedMonth = months[cycleMonth];
            const formattedYear = cycleYear + cycleYearAdjustment;
            const formattedDate = `01-${formattedMonth}-${formattedYear}`;

            changingMonth = (cycle != 1) ? changingMonth + billingCycleTime : changingMonth


            rowsToAdd.push({
                cell: [
                    { text: `Cycle ${cycle}`, textcolor: "#001233" },
                    { text: `${feeObject?.processPaymentOn}-${formattedMonth}-${formattedYear}`, textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
                ],
            });
        }
        setRows(rowsToAdd)

    }, [feeObject?.billingDurationStartDate, feeObject?.billingCycleCount, feeObject?.processPaymentOn, feeObject?.billingCycle])


    const BillingDuration = [
        {
            label: "1 Cycle",
            value: 1
        },
        {
            label: "2 Cycle",
            value: 2
        },
        {
            label: "3 Cycle",
            value: 3
        },
        {
            label: "6 Cycle",
            value: 6
        },
        {
            label: "12 Cycle",
            value: 12
        },
        // {
        //     label: `Last day of the ${lastDayStatus}`,
        //     value: `Last day of the ${lastDayStatus}`
        // },

    ]

    useEffect(() => {
        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        const lastDateOfTheMonth = new Date(currentYear, currentMonth + 1, 0).getDate()

        processPaymentOnHandle(currentMonth, lastDateOfTheMonth)

    }, [])

    const options = [
        // {
        //     label: "Daily",
        //     value: "Daily",
        // },
        // {
        //     label: "Weekly",
        //     value: "Weekly",
        // },
        {
            label: "Monthly",
            value: "Monthly",
        },
        {
            label: "Every 3 months",
            value: "3-months",
        },
        {
            label: "Every 6 months",
            value: "6-months",
        },
        // {
        //     label: "Yearly",
        //     value: "Yearly",
        // },
        // {
        //     label: "Custom",
        //     value: "Custom",
        // }
    ]


    const optionsCustom = [
        {
            label: "Days",
            value: "Days",
        },

        {
            label: "Weeks",
            value: "Weeks",
        },

        {
            label: "Months",
            value: "Months",
        },

        {
            label: "Years",
            value: "Years",
        },

    ]

    useEffect(() => {
        let val = 'recurring'
        if (value == 1) {
            val = 'onetime'
        } else if (value == 0) {
            val = 'recurring'
        }


        // setFeeObject({ ...feeObject, type: val })
    }, [value])



    const onChangeHandler = (val, fieldName) => {

        if (fieldName === "amount" && parseFloat(val) > 999999.00) {
            toast.error("Amount must be no more than $999,999.99")
            return false
        }
        setFeeObject({ ...feeObject, [fieldName]: val })
        setFeeErrorObject({ ...feeErrorObject, [fieldName]: "" })
    }

    const onSchoolYearChangeHandler = async (e) => {
        let { billTo } = feeObject
        let foundSchoolYear = schoolYearList?.find((schoolYear) => schoolYear?._id == e.target.value)


        if (foundSchoolYear) {
            let data = {
                schoolYearId: e.target.value
            }
            let result = await getClassesBySchoolYearId(data)
            if (result?.data && result?.data?.body?.data) {
                setSchoolYearClasses(result?.data?.body?.data)
            }
        }
        let newBillTo = { ...billTo }
        newBillTo['academicYearId'] = e.target.value
        newBillTo['academicYearName'] = foundSchoolYear?.name
        newBillTo['classLevelIds'] = []
        setFeeObject({ ...feeObject, billTo: newBillTo })
    }


    useEffect(() => {
        if (schoolYearList?.length > 0) {
            let schoolYearOptionData = schoolYearList?.map((schoolYear) => ({ label: `${schoolYear?.name}`, value: schoolYear?._id }))
            setSchoolYearOptions(schoolYearOptionData)
        }
    }, [schoolYearList])

    useEffect(() => {
        if (schoolYearClasses?.length > 0) {
            let schoolYearClassesOptionData = schoolYearClasses?.map((schoolYearClass) => ({ label: `All active Students of ${schoolYearClass?.name}`, value: schoolYearClass?._id }))
            setSchoolYearClassesOptions(schoolYearClassesOptionData)

            if (editFeeId != "") {
                let totalClassStudents = 0
                if (schoolYearClasses?.length > 0 && feeObject?.billTo?.classLevelIds?.length > 0) {
                    schoolYearClasses?.map((schoolYearClass) => {
                        feeObject?.billTo?.classLevelIds?.map((group) => {
                            if (schoolYearClass?._id == group) {
                                totalClassStudents += schoolYearClass?.classStudents?.length
                            }
                        })
                    })
                }
                setTotalClassesStudents(totalClassStudents)
            }
        } else {
            setSchoolYearClassesOptions([])
        }
    }, [schoolYearClasses, editFeeId, feeObject])

    useEffect(() => {
        if (classesSelected?.length > 0) {
            setClassesSelectedIds(classesSelected)
        } else {
            setClassesSelectedIds([])
        }
    }, [classesSelected])

    const setLabelAsValueForMultiSelect = (options) => {
        let optionArray = options?.map(option => {
            return option.label
        })
        return optionArray
    }

    const handleChangeSchoolYearClass = (event) => {
        const {
            target: { value },
        } = event;

        let newClassesSelected = [...classesSelected]

        newClassesSelected = typeof value === 'string' ? value.split(',') : value;

        setClassesSelected(newClassesSelected);


    };

    const selectFieldChangeHandler = (e, property) => {
        let value = e.target.value

        setFeeObject({ ...feeObject, [property]: value })
    }

    const billingCycleCustomNumberChangeHandler = (value, e) => {
        const { billingCycleCustom } = feeObject
        let newbillingCycleCustom = { ...billingCycleCustom }

        newbillingCycleCustom['custom_number'] = value
        setFeeObject({ ...feeObject, billingCycleCustom: newbillingCycleCustom })
    }

    const billingCycleCustomTypeChangeHandler = (e) => {
        const { billingCycleCustom } = feeObject
        let newbillingCycleCustom = { ...billingCycleCustom }

        newbillingCycleCustom['custom_type'] = e.target.value
        setFeeObject({ ...feeObject, billingCycleCustom: newbillingCycleCustom })

    }

    useEffect(() => {
        const termLabels = schoolYearClassesOptions.map((item) => item.label);
        const filterTermData = classesSelected?.every((term) => termLabels?.includes(term))
        let data = []
        if (!filterTermData) {
            setClassesSelected(data);
        }
    }, [schoolYearClassesOptions])









    const [addedUsersToUpdate, setAddedUsersToUpdate] = useState([])
    const [addedUsers, setAddedUsers] = useState([])
    const [addedGroupsToUpdate, setAddedGroupsToUpdate] = useState([])
    const [addedGroups, setAddedGroups] = useState([])

    const [messageObject, setMessageObject] = useState([])

    const handleAddUsers = () => {
        setRecipientse(false);
        setMessageObject({ ...messageObject, to: allUsers, toGroups: addedGroupsToUpdate })


        let { billTo } = feeObject

        let newBillTo = { ...billTo }
        newBillTo['studentIds'] = addedUsers
        newBillTo['classLevelIds'] = addedGroups
        setFeeObject({ ...feeObject, billTo: newBillTo })
    }

    const handleCloseRecipients = () => {
        setAddedUsersToUpdate([])
        setAddedUsers([])
        setAllUsers([])
        setAddedGroupsToUpdate([])
        setAddedGroups([])
        setRecipientse(false);
    }


    useEffect(() => {
        let allUser = [...addedUsersToUpdate]
        console.log(addedGroupsToUpdate, 'addedGroupsToUpdate')
        addedGroupsToUpdate?.map((singleGroup) => {
            if (singleGroup.classStudents.length > 0) {
                allUser = [...allUser]
            }
        })
        const uniqueData = {};
        allUser.forEach(item => {
            uniqueData[item.userUniqueID] = item;
        });
        const result = Object.values(uniqueData);

        setAllUsers(result)
    }, [addedUsersToUpdate, addedGroupsToUpdate])




    useEffect(() => {
        setClassesSelected(addedGroups)
        let totalClassStudents = 0

        schoolYearClasses?.map((schoolYearClass) => {
            addedGroups?.map((group) => {
                if (schoolYearClass?._id == group) {
                    totalClassStudents += schoolYearClass?.classStudents?.length
                }
            })
        })
        setTotalClassesStudents(totalClassStudents)
    }, [addedGroups])

    useEffect(() => {
        let totalNumberOfStudents = 0
        totalNumberOfStudents = totalClassesStudents + addedUsers?.length
        setTotalStudentsNumber(totalNumberOfStudents)
    }, [totalClassesStudents, addedUsers])

    useEffect(() => {
        if (editFeeId != "") {

            setAddedUsers(feeObject?.billTo?.studentIds)
            let newAddedUsersToUpdate = []

            feeObject?.billTo?.studentIds?.map((singleStudentId) => {
                let found = studentList?.find((singleStudent) => singleStudent?._id == singleStudentId)
                if (found) {
                    newAddedUsersToUpdate.push(found)
                }
            })
            setAddedUsersToUpdate(newAddedUsersToUpdate)


            let foundSchoolYear = schoolYearList?.find((schoolYear) => schoolYear?._id == feeObject?.billTo?.academicYearId)

            if (foundSchoolYear) {
                let data = {
                    schoolYearId: feeObject?.billTo?.academicYearId
                }

                getClassesByYearId(data)

            }

            setAddedGroups(feeObject?.billTo?.classLevelIds)


            let newGroups = []
            feeObject?.billTo?.classLevelIds?.map((classId) => {
                let foundClassLevels = schoolYearClasses.find((group) => group?._id === classId || group?.id === classId)
                if (foundClassLevels) {
                    newGroups.push(foundClassLevels)
                }
            })

            setAddedGroupsToUpdate(newGroups)


            let typeValue = 0;
            typeValue = feeObject?.type == "onetime" ? 1 : 0
            setValue(typeValue)

        }
    }, [feeObject, editFeeId, feeDetails])


    const getClassesByYearId = async (data) => {
        let result = await getClassesBySchoolYearId(data)

        if (result?.data && result?.data?.body?.data) {
            setSchoolYearClasses(result?.data?.body?.data)
        }
        return result
    }

    useEffect(() => {
        console.log('totalStudentsNumber', totalStudentsNumber)
    }, [totalStudentsNumber])

    useEffect(() => {
        setStudentsSelected(addedUsers)
    }, [addedUsers])

    const backgroundInCount = {
        backgroundColor: '#f2f0f4',
    };


    const backgroundInCountBlue = {
        backgroundColor: '#0450E1',
        color: '#fff'
    }
    const messageData = {}
    const [allUsers, setAllUsers] = useState([])
    const handleOpenStudentClassList = () => setRecipientse(true);
    const [openRecipientseDrawer, setRecipientse] = useState(false);
    const {
        errors,
        setErrors,
    } = props

    const tabData = [
        {
            lebel: "SELECT INDIVIDUALS",
            icon: <RxPerson />,
            filterCountStatus: true,
            filterCountNumber: (allUsers?.length > 0 || messageData?.body?.data?.to.length > 0) ? allUsers.length : '',
        },

        {
            lebel: "SELECT CLASS/LEVEL",
            icon: <IoSchoolOutline />,
            filterCountStatus: true,
            filterCountNumber: (totalClassesStudents > 0) ? totalClassesStudents : '',
        },

        {
            lebel: "SELECT GROUPS",
            icon: <RiGroupLine />,
            filterCountStatus: true,
            filterCountNumber: (totalClassesStudents > 0) ? totalClassesStudents : '',
        },
    ]


    const panelTab = [
        {
            id: 0,
            panleBody: <SelectIndividuals
                addedUsersToUpdate={addedUsersToUpdate}
                addedUsers={addedUsers}
                messageObject={messageObject}
                setAddedUsersToUpdate={setAddedUsersToUpdate}
                setAddedUsers={setAddedUsers} />,
            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Students` : `Select ${totalStudentsNumber} Students`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },

        {
            id: 1,
            panleBody: <SelectClass
                addedGroupsToUpdate={addedGroupsToUpdate}
                addedGroups={addedGroups}
                addedUsers={addedUsers}
                setAddedGroupsToUpdate={setAddedGroupsToUpdate}
                setAddedGroups={setAddedGroups}
                schoolYearClasses={schoolYearClasses}
                totalClassesStudents={totalClassesStudents}
            />,
            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Users` : `Select ${totalStudentsNumber} User`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },


        {
            id: 2,
            panleBody: <SelectGroups
                addedUsersToUpdate={addedUsersToUpdate}
                addedUsers={addedUsers}
                messageObject={messageObject}
                setAddedUsersToUpdate={setAddedUsersToUpdate}
                setAddedUsers={setAddedUsers} />,

            // SaveButtonLabel: (allUsers?.length > 1 || messageData?.body?.data?.to?.length > 1) ? `Select ${allUsers?.length} Users` : `Select ${allUsers?.length} User`,

            SaveButtonLabel: totalStudentsNumber > 1 ? `Select ${totalStudentsNumber} Students` : `Select ${totalStudentsNumber} Students`,
            clickSaveButton: handleAddUsers,
            CancelButtonLabel: 'Cancel',
        },
    ];




    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Cycle #', width: '70%', textAlign: 'left', },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Billing Date', width: '50%', textAlign: 'left', },
    ];


    // const rows = [
    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },

    //     {
    //         cell: [
    //             { text: 'Cycle 1', textcolor: "#001233" },
    //             { text: '01-Oct-2024', textcolor: "#001233", iconstart: <EventIcon style={{ color: "#5C677D", marginRight: "4px", fontSize: "22px" }} /> },
    //         ],
    //     },
    // ];




    return (
        <>
            <div className='DrawerFormContainer'>

                {/* ==== Billing end date Start ===*/}

                <Grid container>
                    <Grid item xs={4} sx={{ paddingTop: '10px' }}>
                        <span style={{ fontSize: '14px', fontWeight: '600' }}>Bill/Applies to<span style={{ color: '#ff0033' }}>*</span></span>
                    </Grid>
                    <Grid item xs={8} sx={{ paddingLeft: '12px' }}>
                        <div className='FloatingFormField'>
                            <SelectField
                                options={schoolYearOptions}
                                handleChange={(e) => onSchoolYearChangeHandler(e)}
                                selectedOption={feeObject?.billTo?.academicYearId}
                                selectPlaceholder="Select academic year"
                            />

                            {/* <div style={{ marginTop: "-15px" }}>
                                <MultipleSelet
                                    FieldLabel={true}
                                    Placeholder="Select student"
                                    option={setLabelAsValueForMultiSelect(schoolYearClassesOptions)}
                                    handleChange={(e) => handleChangeSchoolYearClass(e)}
                                    value={classesSelected}
                                />
                            </div> */}

                            {feeObject?.billTo?.academicYearId != "" && <div className="AddField" onClick={() => handleOpenStudentClassList()} style={{ border: `${allUsers?.length === 0 && errors?.to ? "#d32f2f 1px solid" : "#e4e2e6 1px solid"}`, marginTop: "12px", }}>
                                <Button
                                    href="#contained-buttons"
                                    onClick={() => handleOpenStudentClassList()}
                                    sx={{ textTransform: 'initial !important' }}
                                >
                                    <div className="AddIcon">
                                        <AddRoundedIcon />
                                    </div>{' '}
                                    Select students
                                </Button>
                                <div className="UserCount" style={totalStudentsNumber === 0 ? backgroundInCount : backgroundInCountBlue}>
                                    {totalStudentsNumber}
                                </div>

                            </div>}
                            {totalStudentsNumber === 0 ? <p style={{ color: '#d32f2f', fontSize: '.80rem' }}>{errors?.to}</p> : ''}

                        </div>
                    </Grid>
                </Grid>
                <div className='divider' style={{ marginTop: "24px", marginBottom: "24px" }}></div>
                {/* ==== Billing end date End ===*/}

                {/* ==== Name Start ===*/}
                <div className='FloatingFormContainer align-mid' style={{ marginBottom: "24px" }}>

                    <div className='FloatingFormLabel'>
                        Type<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        {editFeeId == "" && <SelectCard
                            tabsData={tabsData}
                            value={value}
                            onChange={onChange}

                        />}
                        {editFeeId != "" && <TextField
                            value={feeObject?.type == "onetime" ? "One-time" : "Recurring"}
                            disabled={true}
                        />}
                    </div>

                </div>
                {/* ==== Name End ===*/}


                {/* ==== Amount Start ===*/}
                <div className='FloatingFormContainer align-mid' style={{ marginBottom: value === 0 ? '' : '0px' }}>

                    <div className='FloatingFormLabel'>
                        Amount<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <PriceTextField
                            values={feeObject?.amount === 0 ? "" : feeObject.amount} // if amount is 0 then show empty string
                            placeholder="0"
                            startAdornment={<span style={{ marginBottom: '2px', color: '#001233' }}>$</span>}
                            handleChange={(e) => onChangeHandler(e.target.value, 'amount')}
                            error={feeErrorObject?.amount}
                        />


                    </div>

                </div>
                {/* ==== Amount End ===*/}

                <div className='divider' style={{ margin: '24px 0' }}></div>
                {(value === 1) && <div className='FloatingFormContainer align-mid' style={{ marginTop: "-6px" }}>

                    <div className='FloatingFormLabel'>
                        Due date<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <Datefield
                            value={feeObject?.dueDate}
                            handleChange={(e) => onChangeHandler(e, 'dueDate')}
                        />
                    </div>

                </div>}

                {/* ==== Billing period Start ===*/}
                {(value === 0) &&
                    <Grid container style={{ marginBottom: '24px', marginTop: "24px", }}>
                        <Grid item xs={4}>
                            <p style={{ margin: '0px', fontSize: '14px', fontWeight: '600' }}>Billing cycle<span style={{ color: '#ff0033' }}>*</span></p>
                        </Grid>
                        <Grid item xs={8} sx={{ paddingLeft: '12px' }}>
                            <div className='FloatingFormField'>
                                <SelectField
                                    options={options}
                                    handleChange={(e) => selectFieldChangeHandler(e, 'billingCycle')}
                                    selectedOption={feeObject?.billingCycle}
                                />

                                {(feeObject?.billingCycle === "Custom") && <div className='FloatingFormFieldGroup'>
                                    <TextField
                                        value="every"
                                        disabled={true}
                                    //handleChange={handleTemplateNameChange}
                                    />
                                    <TextField
                                        value={feeObject?.billingCycleCustom?.custom_number}
                                        placeholder="Enter payment/fee name"
                                        number
                                        handleChange={(e, val) => billingCycleCustomNumberChangeHandler(e, val)}
                                    />
                                    <SelectField
                                        options={optionsCustom}
                                        handleChange={(e) => billingCycleCustomTypeChangeHandler(e)}
                                        selectedOption={feeObject?.billingCycleCustom?.custom_type}
                                    />
                                </div>}

                            </div>
                        </Grid>
                    </Grid>
                }
                {/* ==== Billing period End ===*/}

                {/* <div className='divider' style={{ margin: '24px 0' }}></div>

                {(value === 1) && <div className='FloatingFormContainer align-mid' style={{ marginTop: "-6px" }}>

                    <div className='FloatingFormLabel'>
                        Due date<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <Datefield
                            value={feeObject?.dueDate}
                            handleChange={(e) => onChangeHandler(e, 'dueDate')}
                        />
                    </div>

                </div>} */}



                {/* ==== Billing start date Start ===*/}
                {(value === 0) &&
                    <Grid container style={{ marginBottom: '24px' }}>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }} >
                            <p style={{ margin: '0px', fontSize: '14px', fontWeight: '600' }}>Billing duration<span style={{ color: '#ff0033' }}>*</span></p>

                        </Grid>
                        <Grid item xs={8} sx={{ paddingLeft: '12px' }}>
                            <div className='FloatingFormField'>
                                <div className='FloatingFormFieldGroup' style={{ marginTop: '0px' }}>
                                    <Datefield
                                        value={feeObject?.billingDurationStartDate}
                                        handleChange={(e) => onChangeHandler(e, 'billingDurationStartDate')}
                                        disabled={editFeeId != "" ? true : false}
                                    />
                                    <SelectField
                                        options={BillingDuration}
                                        handleChange={(e) => selectFieldChangeHandler(e, 'billingCycleCount')}
                                        selectedOption={feeObject?.billingCycleCount}
                                        isDisabled={feeObject?.billingCycle === "Daily" || feeObject?.billingCycle === "Custom" ? true : false}
                                    />

                                </div>

                            </div>
                        </Grid>
                    </Grid>}
                {/* ==== Billing start date Start ===*/}

                {/* ==== Process payment on theStart ===*/}
                {(value === 0) &&
                    <Grid container style={{ marginBottom: '24px' }}>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }} >
                            <span style={{ fontSize: '14px', fontWeight: '600' }}> Process payment on the</span>
                        </Grid>
                        <Grid item xs={8} sx={{ paddingLeft: '12px' }}>
                            <div className='FloatingFormField'>
                                <div className='FloatingFormFieldGroup' style={{ marginTop: '0px' }}>
                                    <SelectField
                                        options={optionsProcessPaymentOn}
                                        handleChange={(e) => selectFieldChangeHandler(e, 'processPaymentOn')}
                                        selectedOption={feeObject?.processPaymentOn}
                                        isDisabled={feeObject?.billingCycle === "Daily" || feeObject?.billingCycle === "Custom" ? true : false}
                                    />
                                    <TextField
                                        value="of the month"
                                        disabled={true}
                                    //handleChange={handleTemplateNameChange}
                                    />

                                </div>

                            </div>
                        </Grid>
                    </Grid>}
                {/* ==== Process payment on the End ===*/}



                {/* ==== Billing end date Start ===*/}
                {/* {(value == 0) && <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel'>
                        Billing end date<span style={{ color: '#ff0033' }}>*</span>
                    </div>

                    <div className='FloatingFormField'>
                        <Datefield
                            value={feeObject?.billingEndDate}
                            handleChange={(e) => onChangeHandler(e, 'billingEndDate')}
                            errorData={feeErrorObject?.billingEndDate}
                            helperText={feeErrorObject?.billingEndDate}
                        />
                    </div>

                </div>} */}
                {/* ==== Billing end date End ===*/}




                <div className="PaymentScheduleTable">
                    <span style={{ fontSize: '14px', fontWeight: '600' }}> Payment schedule</span>
                    <div className='PaymentScheduleTableInner'>
                        <Table
                            headCells={headCells}
                            rows={rows}
                            footer={true}
                            RowsPerPage={10}

                        />
                    </div>
                </div>





                {/* Select Students*/}
                <SideDrawer
                    open={openRecipientseDrawer}
                    handleDrawerClose={handleCloseRecipients}
                    title="Select Students"
                    sideOpen="right"
                    cancelButtonLabel="CANCEL"
                    FooterWithoutTab={false}
                    panelTab={panelTab}
                    tabData={tabData}
                    TabsStatus={true}
                    TabsPannelStatus={true}
                    padding="0"
                    BackArrow={true}

                />



            </div>

        </>
    );
};

export default Billing;
