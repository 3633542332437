import React, { useEffect, useState } from "react";

import moment from "moment-timezone";
import { useGetPaymentDeatilsQuery } from "../../../redux/features/users/userApi";
import useProtected from "../../../hooks/useProtected";
import { capitalizeWords } from "../../../hooks/HelperFunctions";
import { useGetSchoolYearListQuery } from "../../../redux/features/school_years/schoolYearApi";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import PaymentFilter from "./PaymentFilter";
import { usePaymentsSearchMutation, useVerifyMicroDepositMutation } from "../../../redux/features/fees/feeApi";

import ACHAuthorizationForm from "./ACHAuthorizationForm";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";
import TextField from "../../../components/Form/TextField/TextField";
import { CurrencyDollar } from "../../../assets/imgs/icons/CurrencyDollar";
import Buttons from "../../../components/Form/Button/Button";
import { toast } from "react-toastify";
import SyncIcon from "@mui/icons-material/Sync";
import Tooltip from "../../../components/Form/Tooltip/Tooltip";
import { Cycle } from "../../../assets/imgs/icons/Cycle";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const microDepositeStyle = { padding: '0px 24px', marginTop: '12px', display: 'flex', flexDirection: 'column', gap: "14px" }



const Payments = ({ userUniqueID, userDetails, refetch }) => {

  const [userPaymentDetails, setUserPaymentDetails] = useState([]);
  const [academicYears, setAcademicYears] = useState([])
  const [saveAccountSuccess, setSaveAccountSuccess] = useState(false);
  const [asc, setAsc] = useState(true);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [hasRecurringPayments, setHasRecurringPayments] = useState(false);
  const [microDepositDrawer, setMicroDepositDrawer] = useState(false)
  const {
    data: useGetPaymentDeatilsData,
    isLoading: useGetPaymentDeatilsLoading,
    isError: useGetPaymentDeatilsIsError,
    error: useGetPaymentDeatilsError,
  } = useGetPaymentDeatilsQuery(userUniqueID, {
    refetchOnMountOrArgChange: true,
  });
  const [paymentSearch, setPaymentSearchOptions] = usePaymentsSearchMutation()
  const {
    data: schoolYearData,
    isFetching: schoolYearIsFetching,
    isLoading: schoolYearIsLoading,
    error: schoolYearError
  } = useGetSchoolYearListQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (!schoolYearIsFetching && !schoolYearIsLoading) {
      const data = schoolYearData.body.data;
      setAcademicYears(schoolYearData?.body?.data)

    }
  }, [schoolYearData, schoolYearIsFetching, schoolYearIsLoading])


  useEffect(() => {
    if (!useGetPaymentDeatilsLoading && !useGetPaymentDeatilsIsError && useGetPaymentDeatilsData && schoolYearData) {
      setUserPaymentDetails(useGetPaymentDeatilsData?.body?.data);
    }
  }, [
    useGetPaymentDeatilsData,
    useGetPaymentDeatilsLoading,
    useGetPaymentDeatilsIsError,
    schoolYearData
  ]);

  const [verifyMicroDeposit, verifyMicroDepositOptions] = useVerifyMicroDepositMutation()
  useProtected(verifyMicroDepositOptions?.error);
  useProtected(useGetPaymentDeatilsError);
  const [rows, setRows] = useState([])

  const onClick = () => {
    // alert('onclick working fine!');
  };

  useEffect(() => {
    if (userPaymentDetails?.length > 0) {
      setRows(convertDataToCellRows(userPaymentDetails));
      const recurringPayments = userPaymentDetails?.filter((item) => item?.cycle === "recurring")
      if (recurringPayments?.length > 0) {
        setHasRecurringPayments(true)
      }
    }

  }, [userPaymentDetails])

  ///=================================== sorting part start =============//
  const ascendingOrDescendingSorting = (field) => {
    let sortedPaymentDetails = [...userPaymentDetails]
    if (asc) {
      sortedPaymentDetails.sort((a, b) => a[field]?.localeCompare(b[field]));
    } else {
      sortedPaymentDetails.sort((a, b) => b[field]?.localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedPaymentDetails));
    setAsc(!asc)
  }
  ///=================================== sorting part end =============//
  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Amount', width: '15%', textAlign: 'left', },
    { id: 'status', numeric: false, disablePadding: true, label: 'status', width: '10%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSorting('status'), },
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '30%', textAlign: 'left', },
    { id: 'cycle', numeric: false, disablePadding: true, label: 'Cycle', width: '10%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSorting('cycle'), },
    { id: 'paymentDate', numeric: false, disablePadding: true, label: 'Date', width: '15%', textAlign: 'left', sort: "sort", headerOnClick: () => ascendingOrDescendingSorting('paymentDate'), },
    { id: 'name', numeric: false, disablePadding: true, label: 'Academic year', width: '15%', textAlign: 'left', },
    { id: 'name', numeric: false, disablePadding: true, label: '', width: '5%', textAlign: 'left', },
  ];


  const statustData = [
    {
      value: "success",
      statuscolor: "#EDF7EA",
      select: "Paid",
      textColor: "#66BD50",
    },
    {
      value: "due",
      statuscolor: "#EDEDEF",
      select: "Due",
      textColor: "#676C7B",
    },
    {
      value: "pending",
      statuscolor: "#EDEDEF",
      select: "Pending",
      textColor: "#676C7B",
    },
    {
      value: "cancelled",
      statuscolor: "#FCE7E9",
      select: "Cancelled",
      textColor: "#E63946",
    },
    {
      value: "failed",
      statuscolor: "#FCE7E9",
      select: "Failed",
      textColor: "#E63946",
    },
  ];

  const currentDate = new Date();
  const isCurrent = (data) => {
    const startDate = new Date(data?.startDate);
    const endDate = new Date(data?.endDate);
    return currentDate >= startDate && currentDate < new Date(endDate.setDate(endDate.getDate() + 1))
  }
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        const foundYear = academicYears?.find(
          (year) => year?._id == item?.academicYear?.id
        );
        const isYearCurrent = isCurrent(foundYear);

        return {
          cell: [
            { text: `$${item?.amount}` || "N/A" },
            {
              Status: true,
              statusLine: true,
              linebackground: statustData?.find(status => status.value === item?.status)?.textColor,
              background: statustData?.find(status => status.value === item?.status)?.statuscolor,
              color: statustData?.find(status => status.value === item?.status)?.textColor,
              label: statustData?.find(status => status.value === item?.status)?.select,
            },
            { text: item?.name || "N/A" },
            {
              text:
                item?.cycle === "onetime"
                  ? "One-time"
                  :
                  <span className="paymentDateContainer">
                    <Cycle fontSize="small" color="#7D8597" height="16px" />
                    {/* {capitalizeWords(item?.cycle)} */}
                    Monthly
                  </span>
                  || "N/A",
            },
            // { text: moment(item?.paymentDate)?.format("DD-MMM-YYYY") || "N/A" },
            {
              text: item?.paymentDate ? (
                moment(item?.paymentDate)?.format("DD-MMM-YYYY")
              ) : (
                <span className="paymentDateContainer">
                  {moment(item?.dueDate)?.format("DD-MMM-YYYY")}
                  <Tooltip
                    TooltipText={
                      <span>
                        Upcoming payment scheduled:{" "}
                        <b>{moment(item?.dueDate)?.format("DD-MMM-YYYY")}</b>
                      </span>
                    }
                    Tooltip={<QueryBuilderRoundedIcon fontSize="small" />}
                  />
                </span>
              ),
            },
            {
              text: foundYear?.name || "N/A",
              chip: isYearCurrent ? "Current" : "",
              chipStatus: isYearCurrent ? true : false,
            },
            { ActionButtonDotIcon: false, ActionButtonLabel: 'Action', TextAlign: "right" },

          ],

          //======= Action Date Start===== //
          action: [
            { label: 'View', onClick: onClick },
            // { label: 'Delete', onClick: onClick },
          ],
          //======= Action Date End===== //
        }
      }

      )
    }
    else {
      return [];
    }
  }
  //* ====================== filter part start  ==========================================  *//
  const [statuses, setStatuses] = useState([])
  const [cycles, setCycles] = useState([])
  const [academicYearIds, setAcademicYearIds] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)


  const formattedStartdate = moment(startDate).format("YYYY-MM-DD")
  const formattedEnddate = moment(endDate).format("YYYY-MM-DD")

  const handleCloseFilterDrawer = async () => {
    setFilterDrawer(false);
  };
  const handleCancelFilterDrawer = async () => {
    setFilterDrawer(false);
    setStatuses([])
    setCycles([])
    setAcademicYearIds([])
    setStartDate(null)
    setEndDate(null)
    const resultData = convertDataToCellRows(userPaymentDetails)
    setRows(resultData);
  }
  const handleFilterPayment = async () => {


    let data = {
      cycle: cycles,
      status: statuses,
      dueDateStart: startDate ? formattedStartdate : "",
      dueDateEnd: endDate ? formattedEnddate : "",
      academicYearIds: academicYearIds,
      userUniqueID: [
        userUniqueID
      ],
      userType: userDetails?.userType
    }
    const result = await paymentSearch(data)
    if (result?.data?.body?.data && !result?.data?.error) {
      const resultData = convertDataToCellRows(result?.data?.body?.data)
      setRows(resultData);
    }
    setFilterDrawer(false)
  }
  //* ====================== filter part end  ==========================================  *//

  //* ====================== authorization part start  ===============================  *//
  const [openPaymentDrawer, setOpenPayment] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [microErrorStatus, setMicroErrorStatus] = useState(false);
  const [microError, setMicroErro] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleDrawerOpenPayment = () => {
    setOpenPayment(true);
  };

  const initalState = {
    accountHolderName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
    accountType: "Individual",
    email: userDetails?.email ? userDetails?.email : "",
    authorizationForm: "",
    userId: userDetails?._id
  }
  const initalErrorState = {
    accountHolderName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    routingNumber: "",
    accountType: "",
    email: "",
  }
  const [authorizeAccount, setAuthorizeAccount] = useState(initalState)
  const [errors, setErrors] = useState(initalErrorState)

  const handleDrawerClosePayment = () => {
    setOpenPayment(false);
    // setSaveAccountSuccess(false);
    setErrorStatus(false);
    setIsSaving(false);
    setAuthorizeAccount(initalState)
    setErrors(initalErrorState)
  };
  //* ====================== authorization part end  =======================================  *//

  //* ====================== Verify micro-deposits part start  =========================  *//


  const initalStateOfMicroDeposit = {
    amount1: "",
    amount2: "",
    userId: userDetails?._id
  }
  const initalErrorStateOfMicroDeposit = {
    amount1: "",
    amount2: "",
  }
  const [microDeposit, setMicroDeposit] = useState(initalStateOfMicroDeposit)
  const [microDepositErrors, setMicroDepositErrors] = useState(initalErrorStateOfMicroDeposit)


  //* ====================== check errors ===============================  *//
  useEffect(() => {
    if (microErrorStatus) {
      isValidMicroDeposit()
    }
  }, [microErrorStatus, microDeposit])
  const isValidMicroDeposit = async () => {
    let error = 0
    let errorObejct = { ...microDepositErrors }

    if (microDeposit?.amount1 === "") {
      error++
      errorObejct.amount1 = "Required"
    } else {
      errorObejct.amount1 = ""
    }
    if (microDeposit?.amount2 === "") {
      error++
      errorObejct.amount2 = "Required"
    } else {
      errorObejct.amount2 = ""
    }
    setMicroDepositErrors({ ...microDepositErrors, ...errorObejct })
    return error > 0 ? false : true
  }

  //* ====================== check errors ===============================  *//


  //* ====================== save data ===============================  *//
  const handleVerifyMicroDeposit = async () => {
    setMicroErrorStatus(true)
    if (isSaving) return
    const isValid = await isValidMicroDeposit()
    if (!isValid) {
      toast.error("Ensure that you have entered the correct deposit amount.")
      setIsSaving(false)
      return false
    }
    const formattedAmount1 = `.${microDeposit?.amount1}`
    const formattedAmount2 = `.${microDeposit?.amount2}`
    let data = {
      amount1: Number(formattedAmount1),
      amount2: Number(formattedAmount2),
      userId: userDetails?._id
    }

    try {
      const result = await verifyMicroDeposit(data)
      if (!result?.data?.error && result?.data?.body?.data) {
        refetch()
        handleDrawerCloseMicroDeposit()
      } else {
        setMicroErro(true)
        toast.error("Ensure that you have entered the correct deposit amount.")
      }
    } catch (error) {
      console.log(error?.message)
    }

  }
  //* ====================== save data ===============================  *//


  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    const numberRegex = /^[0-9]*\.?[0-9]*$/;
    if (!numberRegex.test(fieldData)) {
      return false;
    }
    setMicroDeposit({ ...microDeposit, [fieldName]: fieldData });

  };

  const handleDrawerCloseMicroDeposit = () => {
    setMicroDepositDrawer(false);
    setMicroErro(false);
    // setSaveAccountSuccess(false);
    setMicroErrorStatus(false);
    setIsSaving(false);
    setMicroDeposit(initalStateOfMicroDeposit)
    setMicroDepositErrors(initalErrorStateOfMicroDeposit)
  };
  //* ====================== Verify micro-deposits part end ============================= *//



  return (
    <div>
      {
        (userDetails?.userType === "parent" && !userDetails?.bankAccountVerified && hasRecurringPayments) && (
          <div className="SetupPaymentAlert">
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert
                severity="error"
                sx={{ "& .MuiAlert-action": { paddingTop: '2px' } }}
                action={
                  userDetails?.paymentMethodId && userDetails?.paymentMethodId !== "" ?
                    <Button size="small" onClick={() => setMicroDepositDrawer(true)}>VERIFY</Button>
                    : <Button color="inherit" size="small" onClick={handleDrawerOpenPayment}>
                      Setup Payment
                    </Button>
                }
              >
                {userDetails?.paymentMethodId && userDetails?.paymentMethodId !== "" ? "Verify ACH account with 2 small deposits" : "Set up an ACH payment method in order to make recurring payments."}
              </Alert>
            </Stack>
          </div>
        )
      }
      {/* <Button color="inherit" size="small" onClick={handleDrawerOpenPayment}>
        Setup Payment
      </Button> */}

      <Table
        title="Payments"
        tableHeader={true}
        headCells={headCells}
        rows={rows}
        footer={true}
        RowsPerPage={10}
        FilterButtonTop={true}
        onClickFilterButton={() => setFilterDrawer(true)}
        isDataLoading={schoolYearIsLoading || useGetPaymentDeatilsLoading}
        filterCountStatus={(statuses?.length + cycles?.length + academicYearIds?.length + ((startDate || endDate) ? 1 : 0)) > 0}
        filterCountNumber={(statuses?.length + cycles?.length + academicYearIds?.length + ((startDate || endDate) ? 1 : 0))}
      // onClickFilterButton={onClickFilterButton}
      />

      {/* === filter === */}
      <SideDrawer
        title="Filter"
        cancelButtonLabel="CLEAR FILTER"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={filterDrawer}
        handleDrawerClose={handleCloseFilterDrawer}
        handleDrawerCloseButtonBottom={handleCancelFilterDrawer}
        ButtonLeft="Upload New"
        FooterWithoutTab={true}
        ButtonLabel="APPLY"
        clickSaveButton={() => handleFilterPayment()}
        body={
          <div>
            <PaymentFilter statuses={statuses} setStatuses={setStatuses} cycles={cycles} setCycles={setCycles} academicYearIds={academicYearIds} setAcademicYearIds={setAcademicYearIds} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
          </div>
        }
      />


      {/* === Setup Payment Drawer === */}
      <SideDrawer
        title="Verify micro-deposits"
        cancelButtonLabel="CANCEL"
        deleteText="Delete Permanently"
        sideOpen="right"
        padding="0px"
        open={microDepositDrawer}
        handleDrawerClose={handleDrawerCloseMicroDeposit}
        body={
          <div>
            <Alert severity="warning" icon={<InfoOutlinedIcon />}>
              To verify account, enter the two micro-deposits below. <br />
              If you don’t see them, they should arrive in 1-3 business days.
            </Alert>

            <div style={microDepositeStyle}>
              <TextField
                label="Micro-deposit 1"
                required={true}
                // helperText={microDepositErrors?.amount1}
                error={microDepositErrors?.amount1 || microError}
                startAdornment={<><CurrencyDollar height="18px" /> <p>.</p></>}
                value={microDeposit?.amount1}
                handleChange={(val, e) => handleChanges("amount1")(e)}
              />
              <TextField
                label="Micro-deposit 2"
                required={true}
                // helperText={microDepositErrors?.amount2}
                error={microDepositErrors?.amount2 || microError}
                startAdornment={<><CurrencyDollar height="18px" /> <p>.</p></>}
                value={microDeposit?.amount2}
                handleChange={(val, e) => handleChanges("amount2")(e)}
              />
              <div style={{ marginTop: '10px' }}>
                <Buttons
                  label="Verify"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  fontWeight="700"
                  onClick={handleVerifyMicroDeposit}
                />
              </div>
            </div>
          </div>
        }
      />

      {/* === Setup Payment Drawer === */}
      <SideDrawer
        title="ACH Authorization"
        cancelButtonLabel="CANCEL"
        deleteText="Delete Permanently"
        sideOpen="right"
        drawerWidth="690px"
        open={openPaymentDrawer}
        handleDrawerClose={handleDrawerClosePayment}
        body={
          <div>
            <ACHAuthorizationForm
              authorizeAccount={authorizeAccount}
              setAuthorizeAccount={setAuthorizeAccount}
              errors={errors}
              handleDrawerClosePayment={handleDrawerClosePayment}
              setErrors={setErrors}
              errorStatus={errorStatus} setErrorStatus={setErrorStatus}
              isSaving={isSaving} setIsSaving={setIsSaving}
              setSaveAccountSuccess={setSaveAccountSuccess}
              refetch={refetch}
            />
          </div>
        }
      />

    </div>
  );
};

export default Payments;


