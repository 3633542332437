import React from 'react'

export const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => (

    <span
      {...props}
      ref={ref}

      style={{
        cursor: 'pointer',
        color: `${reversed
          ? active
            ? 'white'
            : '#aaa'
          : active
          ? 'black'
          : '#ccc'}`
      }}
    />
  )
)


export const Icon = React.forwardRef(({ className, ...props }, ref) => (

    <span
      {...props}
      ref={ref}
      className={"material-icons"}
      style={{
        fontSize: "18px",
        verticalAlign: "text-bottom"
      }}
    />
  ))
  export const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      style={{
        display: 'inline-block',
        marginLeft: '15px'
      }}
    />
  ))

  export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
    <Menu
      {...props}
      ref={ref}
      style={{
        position: 'relative',
        padding: '1px 18px 17px',
        margin: '0 -20px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px'
      }}
    />
  ))