import React, { useState } from "react";

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '../Tooltip/Tooltip';
import './PriceTextField.css';




{/* <PriceTextField
  label="Compare-at price"
  TooltipStatus={true}
  TooltipText="Compare-at price"
/> */}

// const [values, setValues] = React.useState({
//   amount: '',
// });
// const handleChange = (prop) => (event) => {
//   setValues({ ...values, [prop]: event.target.value });
// };



function PriceTextField(props) {

  const { label, required, number, handleChange, values, error, helperText } = props;

  return (
    <div className="PriceTextField">
      {props.labelStatus &&
        <div className="PriceTextFieldLabel">{label} {required && <span>*</span>}
          {props.TooltipStatus &&
            <Tooltip
              TooltipText={props.TooltipText}
              Tooltip={<InfoRoundedIcon />}
            />
          }
        </div>
      }

      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-adornment-amount"
          type="number"
          value={values}
          onChange={handleChange}
          price
          startAdornment={<InputAdornment position="start">{props.startAdornment}</InputAdornment>}
          endAdornment={<InputAdornment position="end">{props.endAdornment}</InputAdornment>}
          placeholder={props.placeholder}
          error={error}
          helperText={helperText}
          className="no-spinners"
          disabled={props.disabled}
        />
      </FormControl>
    </div>
  );
}


export default PriceTextField;

