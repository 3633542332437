import React, { useState, useEffect } from 'react';

import DateRange from './DateRange';


//=== Package ( URL: https://rsuitejs.com/components/date-range-picker/ ) ===//

function DateRangeData(props) {


  return (
    <DateRange
      //defaultValue={}
      // onChange={onChange}
      border="none"
    />
  );
}

export default DateRangeData;
