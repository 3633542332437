
import React from 'react'
import Datefield from '../../../components/Form/Datefield/Datefield';
import { Grid } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ScanTime = () => {
    return (
        <div>

            <Grid container spacing={3} justifyContent="center" alignItems="flex-end">
                {/* ============= Start time ================== */}
                <Grid item md={6} xs={12}>
                    <div className='scantableDatePicker'>
                        <Datefield
                            label="Start date"
                            //   value={dayjs(filterDate)}
                            disableFuture={true}
                            // disabled={onlyAdmin ? false : true}
                            dateFormat="DD-MMM-YYYY"
                        //   handleChange={(e) => setStartDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className="TimeFieldLabel">Start time</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            {/* <TimePicker value={dayjs(startTime)} onChange={(e) => setStartTime(`${e.$H}:${e.$M}`)} /> */}
                            <TimePicker
                                // slotProps={{
                                //     textField: {
                                //         error: errorStartEndTime || errorStartTime,
                                //         helperText: (errorStartEndTime || errorStartTime) && errorMessage,
                                //     },
                                // }} defaultValue={dayjs(`2022-04-17T${startTime}`)} onChange={(e) => (e?.$H || isNaN(e?.$H) && (e.$m || isNaN(e.$m))) ? setStartTime(`${e.$H}:${e.$m}`) : setStartTime(`hh:mm`)}                                     
                                sx={{
                                    "& .MuiInputBase-input": {
                                        padding: '13px 0 13px 14px'
                                    }
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
                {/* ============= End time ================== */}
                <Grid item md={6} xs={12}>
                    <div className='scantableDatePicker'>
                        <Datefield
                            label="End date"
                            //   value={dayjs(filterDate)}
                            disableFuture={true}
                            // disabled={onlyAdmin ? false : true}
                            dateFormat="DD-MMM-YYYY"
                        //   handleChange={(e) => setStartDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`)}
                        />
                    </div>
                </Grid>

                <Grid item md={6} xs={12}>
                    <div className="TimeFieldLabel">End time</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            {/* <TimePicker value={dayjs(startTime)} onChange={(e) => setStartTime(`${e.$H}:${e.$M}`)} /> */}
                            <TimePicker
                                // slotProps={{
                                //     textField: {
                                //         error: errorStartEndTime || errorStartTime,
                                //         helperText: (errorStartEndTime || errorStartTime) && errorMessage,
                                //     },
                                // }} defaultValue={dayjs(`2022-04-17T${startTime}`)} onChange={(e) => (e?.$H || isNaN(e?.$H) && (e.$m || isNaN(e.$m))) ? setStartTime(`${e.$H}:${e.$m}`) : setStartTime(`hh:mm`)}                                     
                                sx={{
                                    "& .MuiInputBase-input": {
                                        padding: '13px 0 13px 14px'
                                    }
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
                <Grid item md={12} xs={12}>
                    <div className='MediaLibPanelInfoText' style={{ color: '#001233' }}><InfoOutlinedIcon /> Schedule only applies to school days.</div>
                </Grid>

            </Grid>
        </div>
    )
}

export default ScanTime