import React, { useEffect, useState } from 'react';

import MultipleSeletChip from '../../../../components/Form/MultipleSeletChip/MultipleSeletChip';
import { useGetAllCouponsQuery } from '../../../../redux/features/coupons/couponApi';
import useProtected from '../../../../hooks/useProtected';
import { getDataMap } from '../../../../hooks/HelperFunctions';
import CheckBox from '../../../../components/Form/CheckBox/CheckBox';
import SwitchButtonIOS from '../../../../components/Form/SwitchButtonIOS/SwitchButtonIOS';



const AddNotification = ({ feeObject, setFeeObject, feeErrorObject, setFeeErrorObject }) => {

    const {
        data,
        isError,
        isLoading,
        error
    } = useGetAllCouponsQuery(undefined, { refetchOnMountOrArgChange: true });
    useProtected(error);
    const [coupons, setCoupons] = useState([]);
    const [couponsOptions, setCouponsOptions] = useState([]);
    const [selectedCouponsOptions, setSelectedCouponsOptions] = useState([]);
    useEffect(() => {
        if (!isLoading && !isError) {
            setCoupons(data?.body?.data)
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        setCouponsOptions(getDataMap(
            coupons,
            "code",
            "code"
        ))
    }, [coupons])
    useEffect(() => {
        if (feeObject?.discountCodes?.length > 0) {
            const formattedArray = feeObject?.discountCodes?.map((option) => {
                return {
                    label: option,
                    value: option
                }
            })
            setSelectedCouponsOptions(formattedArray)
        } else {
            setSelectedCouponsOptions([])
        }
    }, [feeObject?.discountCodes])

    useEffect(() => {
        let filterOptions = couponsOptions?.filter((data) =>
            !selectedCouponsOptions?.some((option) => option?.value === data?.value))
        setCouponsOptions(filterOptions)
    }, [selectedCouponsOptions])

    useEffect(() => {
        const unselectedOptions = coupons.filter(coupon =>
            !selectedCouponsOptions.some(option => option.value === coupon.code)
        ).map(coupon => ({ label: coupon.code, value: coupon.code }));

        setCouponsOptions(unselectedOptions);
    }, [selectedCouponsOptions, coupons]);

    const handleChangeWithData = (fieldName, fieldData) => {
        setFeeObject({ ...feeObject, [fieldName]: fieldData.map(option => option.value) });
        setSelectedCouponsOptions(fieldData)
    };
    const onChangeCheck = (e) => {
        setFeeObject({
            ...feeObject, applyCouponAutomatically: e.target.checked
        });
    }


    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <>
            <div className='DrawerFormContainer' style={{ marginTop: '-6px' }}>

                {/* ==== Select time ===*/}
                <div className='FloatingFormContainer align-mid'>

                    <div className='FloatingFormLabel' style={{ marginTop: '29px' }}>
                        Select time
                    </div>

                    <div className='FloatingFormField'>
                        <MultipleSeletChip
                            placeholder={selectedCouponsOptions.length !== 0 ? "" : "Select"}
                            handleChange={(event, fieldData) =>
                                handleChangeWithData("discountCodes", fieldData)
                            }
                            count={50}
                            options={couponsOptions}
                            selectedOption={selectedCouponsOptions}
                            CharAlignMid="CharAlignMid"
                        />
                    </div>

                </div>
                {/* ==== Select time End===*/}

                {/* ==== Select time ===*/}
                <div className='FloatingFormContainer'>

                    <div className='FloatingFormLabel' style={{ paddingTop: "16px" }}>
                        Select method
                    </div>

                    <div className='FloatingFormField'>
                        <div className='SwitchButtonContainer'>
                            <SwitchButtonIOS
                                title="Email"
                                handleChange={handleChange}
                                //disabled
                                checked={state.checkedB}
                            />
                        </div>
                        <div className='SwitchButtonContainer'>
                            <SwitchButtonIOS
                                title="SMS"
                                handleChange={handleChange}
                                //disabled
                                checked={state.checkedB}
                            />
                        </div>
                    </div>

                </div>
                {/* ==== Select time End===*/}


            </div>

        </>
    );
};

export default AddNotification;
