import React, { useState } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
// import AlrtMessage from '../../../components/AlrtMessage/AlrtMessage';
// import ReCAPTCHA from "react-google-recaptcha";

// import styles from '../../../assets/css/form.module.css';
import TextField from '../../components/Form/TextField/TextField';
import AlrtMessage from './../../components/Form/AlrtMessage/AlertMessage';
import axiosApi from './../../utils/axios/index';
import PasswordStrength from '../../components/Form/FieldPassword/PasswordStrength';
import { calculatePasswordStrength, validateFormData } from '../../utils/form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetInvitedUserDetailsQuery } from '../../redux/features/users/userApi';
import { useEffect } from 'react';
import '../../assets/css/form.css';
// import config from '../../config';
import LogoHeader from './LogoHeader/LogoHeader';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/features/authSlice';

function SignUp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [invitationId, setInvitationId] = useState('');
  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    isError: userDetailsError,
  } = useGetInvitedUserDetailsQuery(searchParams.get('invitation'), {
    refetchOnMountOrArgChange: true,
  });
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [step, setStep] = useState(1);

  const [password, setPassword] = useState('');
  const [, setPasswordValidation] = useState(true);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//

  useEffect(() => {
    const id = searchParams.get('invitation');
    if (id) {
      setInvitationId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!userDetailsError && !isUserDetailsLoading) {
      const user = userDetails?.body?.data;
      if (user) {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
      }
    }
  }, [invitationId, userDetailsError, isUserDetailsLoading, userDetails]);


  const parentPrtalUrl = process.env.REACT_APP_ENV === "stage" ? "https://stage-parent-portal.deenschool.io/students" : "https://parent-portal.deenschool.io/students"

  const handleSignUpStep1 = () => {
    const isValid = validateFormData(
      { firstName, lastName, email },
      setErrors,
      {
        email: 'Enter your email address',
        firstName: 'Enter your first name',
        lastName: 'Enter your last name',
      },
    );
    if (!isValid) return;
    setStep(2);
  };

  const handleSignUp = async () => {
    let err = {};

    if (!password) {
      setAlertOptions({
        msg: 'Please enter a strong password',
        type: 'error',
      });
      err['password'] = 'Enter your password';
      setErrors(err);
      return;
    } else {
      const strength = calculatePasswordStrength(password);
      if (strength < 4) {
        setAlertOptions({
          msg: 'Please enter a strong password',
          type: 'error',
        });
        err['password'] = 'Please enter a strong password';
        setErrors(err);
        return;
      }
    }

    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
        ///===================== recapcha ==============================///

        const data = {
          firstName,
          lastName,
          email: email.toLowerCase(),
          password,
          invitationId: invitationId,
          token
        };

        try {
          const result = await axiosApi.post('/users/public/signup', data);
          if (result.data.error) {
            setAlertOptions({
              msg: result.data.message,
              type: 'error',
            });
            return;
          }
          setAlertOptions({
            msg: result.data.message,
            type: 'success',
          });
          if (invitationId) {
            navigate('/verified-screen');
            dispatch(setUserData(result?.data?.body?.data));
          } else {
            navigate(
              `/email-sent?userId=${result.data.body.data?._id}&&signUpEmail=${result.data.body.data.email}`,
            );
          }

        } catch (err) {
          setAlertOptions({
            msg: err.message,
            type: 'error',
          });
        }
      } catch (error) {
        console.error('Error:', error);
      }
    })

  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  return (
    <>
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>{step === 1 ? 'Sign up' : 'Create new password'}</h1>

            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />

            <Grid container spacing={3}>

            </Grid>
            {/* <Grid container spacing={2}> */}
            {step === 1 && (
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <TextField
                    placeholder="First name"
                    required={true}
                    error={errors?.firstName}
                    value={firstName}
                    handleChange={(value) => {
                      setErrors((prev) => {
                        prev.firstName = '';
                        return prev;
                      });
                      setFirstName(value.replace(/[^a-zA-Z]/g, ''));
                    }}
                    helperText={errors?.firstName}
                  // error
                  />
                </Grid>

                <Grid item xs={6} md={6}>
                  <TextField
                    placeholder="Last name"
                    required={true}
                    error={errors?.lastName}
                    value={lastName}
                    handleChange={(value) => {
                      setErrors((prev) => {
                        prev.lastName = '';
                        return prev;
                      });
                      setLastName(value.replace(/[^a-zA-Z]/g, ''));
                    }}
                    helperText={errors?.lastName}
                  //error
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    placeholder="Email address"
                    required={true}
                    email={true}
                    value={email}
                    disabled={invitationId}
                    handleChange={(value) => {
                      setErrors((prev) => {
                        prev.email = '';
                        return prev;
                      });
                      setEmail(value);
                    }}
                    error={errors?.email}
                    helperText={errors?.email}

                  //error
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Buttons
                    label="Continue"
                    buttonColor="#0450e1"
                    border="#0450e1 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    fontWeight="700"
                    // link="/authentication/create-new-password"
                    onClick={handleSignUpStep1}
                  />
                </Grid>

                {/* //if no invitationId then show sign in link */}

                {!invitationId && (
                  <>
                    <Grid item xs={12} md={12}>
                      <div className="AlreadyText">
                        Already have an account?{' '}
                        <Link to="/signin">
                          <span>Sign in</span>
                        </Link>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="Devider"></div>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12}>
                  <p className="FormDesText">
                    By signing up, you acknowledge you have read and
                    <br /> agreed to our{' '}
                    <Link to="/terms-of-use">
                      Terms of Use and Privacy Policy
                    </Link>
                    .
                  </p>
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <PasswordStrength
                    // handlePasswordText={setPassword}
                    handlePasswordValidation={setPasswordValidation}
                    placeholder="Password"
                    handlePasswordText={(value) => {
                      setErrors((prev) => {
                        prev.password = '';
                        return prev;
                      });
                      setPassword(value);
                    }}
                  // helperText={errors?.password}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Buttons
                    label="Create Account"
                    buttonColor="#0450e1"
                    border="#0450e1 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    fontWeight="700"
                    // link="/signin"
                    onClick={handleSignUp}
                  />
                </Grid>
              </Grid>
            )}
            {/* </Grid> */}
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>
      </div>
    </>
  );
}

export default SignUp;
