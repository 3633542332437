import React, { useEffect, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TextField from "../../../components/Form/TextField/TextField";
import Buttons from '../../../components/Form/Button/Button';
import Modals from '../../../components/Form/Modals/Modals';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import SpatialAudioOffRoundedIcon from '@mui/icons-material/SpatialAudioOffRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SelectTemplate from './SelectTemplate';
import RedioBox from '../../../components/Form/RedioBox/RedioBox';
import AddCategories from './AddCategories';
import { toast } from 'react-toastify';
import { useGetCallToRecordDataQuery, useGetCallToRecordMutation, useGetTextToVoiceMutation, useLazyGetCallToRecordDataQuery } from '../../../redux/features/messages/meassageApi';
import useProtected from '../../../hooks/useProtected';
import axiosApi from '../../../utils/axios';
import Externaluse from '../../../components/MediaLibrary/Externaluse';
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import SideBarDrawer from '../../../components/SideBarDrawer/SideBarDrawer';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import DraggableDialog from '../../../components/Dialog/Dialog';


import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useRef } from 'react';
import Alert from '@mui/material/Alert';

const recordedChipBarStyle = { padding: '2px', margin: '4px 0px', height: '100%', background: "#41AB37", borderRadius: '99px' }


const Voice = (props) => {

  //========== call to record apis start ============//
  const [callRecordId, setCallRecordId] = useState(0)
  const [getCallToRecord, getCallToRecordOptions] = useGetCallToRecordMutation()
  const [getCallToRecordDataLazy, { data, isLoading, isError, error }] = useLazyGetCallToRecordDataQuery(callRecordId, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(getCallToRecordOptions.error);

  const [callToRecordData, setCallToRecordData] = useState('');
  const [callToRecordContent, setCallToRecordContent] = useState('');
  const [callToRecordError, setCallToRecordError] = useState(false);
  const [callToRecordAgainStatus, setCallToRecordAgainStatus] = useState(false);
  //============= call to record apis end ============//

  //======= Record Again dialog ===== //
  const [openDialogRecordAgain, setOpenDialogRecordAgain] = useState(false);
  const handleOpenRecordAgain = () => {
    setOpenDialogRecordAgain(true);
  };
  const handleCloseRecordAgain = () => {
    setOpenDialogRecordAgain(false)
  };
  //======= Record Again dialog ===== //

  //======= Listen dialog ===== //
  const [openDialogListen, setOpenDialogListen] = useState(false);
  const handleOpenListen = () => {
    setOpenDialogListen(true);
  };
  const handleCloseListen = () => {
    setOpenDialogListen(false)
  };
  //======= Listen dialog ===== // 





  const [getTextToVoice, getTextToVoiceOptions] = useGetTextToVoiceMutation()

  useProtected(getTextToVoiceOptions.error)

  const {
    messageDetails,
    messageObject,
    setMessageObject,
    errors
  } = props

  const [openTemplate, setTemplate] = useState(false);
  const handleOpenTemplate = () => setTemplate(true);
  const handleCloseTemplate = () => setTemplate(false);
  const [tempTemplate, setTempTemplate] = useState({})
  const [audioUrl, setAudioUrl] = useState(null)
  // useEffect(() => {
  //   console.log('temporary template:', tempTemplate)
  // }, [tempTemplate])


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const to = [
    {
      label: "Category",
      value: "Category",
    },

    {
      label: "Category",
      value: "Category",
    },

  ]

  const from = [
    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

  ]


  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = () => { setOpenCategory(true); };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };


  const [value, setValue] = React.useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabsData = [
    {
      title: "Text-to-speech",
    },

    {
      title: "Upload recording",
    },

    {
      title: "Call me to record",
    },
  ]
  const [lastSelectionStartSection, setLastSelectionStartSection] = useState(0)
  const onChangeHandler = (val, e, fieldName) => {
    setLastSelectionStartSection(e.target.selectionStart)
    let newObject = { ...messageObject, [fieldName]: e.target.value }
    setMessageObject(newObject)
  }

  const handleSelectTemplate = () => {
    let str = messageObject.messageVoiceContent
    const textBeforeCursor = str.slice(0, lastSelectionStartSection);
    const textAfterCursor = str.slice(lastSelectionStartSection);
    const newText = `${textBeforeCursor}${tempTemplate?.content}${textAfterCursor}`;

    setLastSelectionStartSection(newText.length)
    let newObject = { ...messageObject, messageVoiceContent: newText }
    setMessageObject(newObject)

    handleCloseTemplate()
    setTempTemplate({})
  }
  const handleCopyCode = () => {
    if (messageObject?.messageVoiceContent) {
      navigator.clipboard.writeText(messageObject?.messageVoiceContent)
        .then(() => {
          toast.success('Copied');
        })
        .catch((error) => {
          console.error('Error copying to clipboard: ', error);
          toast.error('Failed to copy code');
        });
    } else {
      toast.warning('No code to copy');
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();

    navigator.clipboard.readText()
      .then(text => {
        let str = messageObject?.messageVoiceContent

        const textBeforeCursor = str.slice(0, lastSelectionStartSection);

        const textAfterCursor = str.slice(lastSelectionStartSection);
        const newText = `${textBeforeCursor}${text}${textAfterCursor}`;

        let newObject = { ...messageObject, messageVoiceContent: newText }
        setMessageObject(newObject)
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
  const listenTextHandler = async (e) => {
    if (!messageObject?.messageVoiceContent) {
      toast.error("Please enter some text to listen to.")
      return
    }
    const utterance = new SpeechSynthesisUtterance(messageObject?.messageVoiceContent)
    // utterance.voice = speechSynthesis.getVoices().find(voice => voice.name === 'Google UK English Female');
    speechSynthesis.speak(utterance)
  }
  // const listenTextHandler = async (e) => {
  //   let data = {
  //     term: messageObject?.messageVoiceContent,
  //     voiceId: "Joey"
  //   }
  //   // let result = await getTextToVoice(data)
  //   const result = await axiosApi.post('/public-api/textToSpeech', data, {
  //     responseType: 'arraybuffer'
  //   });

  //   const blob = new Blob([result.data], { type: 'audio/mpeg' });
  //   // const audioData = await result.buffer();


  //   setAudioUrl(URL.createObjectURL(blob))
  // }

  // * ============================== Media lib ============================== 
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [mediaItem, setMediaItem] = useState({});

  const clickSelectMediaItem = async () => {

    //check if any item is selected
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("audio/")) {
        let newObject = { ...messageObject, messageVoiceRecording: mediaItem.url }
        setMessageObject(newObject)
        toast.success("Voice message added successfully")
        setOpenSelectMedia(false)
      } else {
        toast.error("Only audio file can be accepeted")
      }


    } else {
      alert("Please select any media");
    }
  };

  const deleteAttachment = () => {
    let newObject = { ...messageObject, messageVoiceRecording: "" }
    setMessageObject(newObject)
    toast.success("Voice message deleted successfully")
  }
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  const handleMediaUploadClose = () => {
    setOpenSelectMedia(true)
    setOpenNewMedia(false);
  };

  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
    setOpenSelectMedia(true)
  };
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };
  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };
  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setSelectedValue(false)
    setMediaItem({})
    setOpenSelectMedia(false);
  };


  // * ============================== Media lib ============================== 

  //================== call to record part start ==========================//
  const handleInputChange = (e) => {
    setCallToRecordData(e.target.value);
  };


  const handleCallToRecord = async () => {
    const phoneNumberPattern = /^\+?(?:\d{2,4}\s?)*\d+$/;
    if (callToRecordData === "" || !phoneNumberPattern.test(callToRecordData) || callToRecordData?.length < 10) {
      setCallToRecordError(true)
      return
    } else {
      setCallToRecordError(false)
    }
    const result = await getCallToRecord({ to: `+1${callToRecordData}` })
    // console.log(result, 34)
    if (result?.data?.body) {
      setCallToRecordAgainStatus(true)
      setCallRecordId(result?.data?.body?.data)
      callingTwiceTheApi(result?.data?.body?.data)
      // toast.success("Call Recorded Successfully")
    } else {
      // toast.error("Failed to make call")
      return false
    }
  }

  const callingTwiceTheApi = async (id) => {
    const result = await getCallToRecordDataLazy(id)

    if (result?.data?.body?.data?.s3Url && result?.data?.body?.data?.s3Url !== "notfound") {
      setCallToRecordContent(result?.data?.body?.data?.s3Url);
      setCallToRecordAgainStatus(false)
    } else {
      setTimeout(() => { callingTwiceTheApi(id) }, 2000);
    }
  }


  useEffect(() => {
    if (callToRecordContent) {
      setCallToRecordAgainStatus(false)
      let newObject = { ...messageObject, messageCallToRecordingContent: callToRecordContent }
      setMessageObject(newObject)
    }
  }, [callToRecordContent])


  const handaleDownloadFile = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = callToRecordContent;
    link.download = 'audio.mp3';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRecordAgain = () => {
    setCallToRecordContent('')
    let newObject = { ...messageObject, messageCallToRecordingContent: '' }
    setMessageObject(newObject)
    setOpenDialogRecordAgain(false)
  }
  //================== call to record part start end ==========================//


  const panelDate = [
    {
      id: 0,
      panel:
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <TextArea
              label={<div style={{ color: "#1B1B1F61", }}>Text-to-speech</div>}
              validation={true}
              FieldLabel={true}
              value={messageObject.messageVoiceContent}
              helperText={messageObject?.messageVoiceContent.length === 0 ? errors?.messageVoiceContent : ''}
              error={messageObject?.messageVoiceContent.length === 0 ? errors?.messageVoiceContent : ''}
              handleChange={(val, e) => onChangeHandler(val, val, 'messageVoiceContent')}
              count={500}
              allowExceedMaxLength={true}
              errorText={(messageObject?.messageVoiceContent?.length > 500) ? "errorText" : ""}
              HeaderRightBody={
                <div className='InsertSection'>
                  <a onClick={handleOpenTemplate}><TextSnippetRoundedIcon />Insert template</a>
                </div>

              }
              ButtonHeaderStatus={true}
              ButtonHeader={
                <>
                  <Buttons
                    label="Listen"
                    buttonColor="#F8F8F8"
                    border="#F8F8F8 1px solid"
                    color="#004FE0"
                    width="100%"
                    height="32px"
                    fontSize="14px"
                    icon={<VolumeUpRoundedIcon />}
                    onClick={listenTextHandler}
                  />

                </>
              }

              helperText={
                <div className='TextpastSection'>
                  <a className='P_Active' onClick={handlePaste}>PASTE</a><span>|</span> <a className={messageObject?.messageVoiceContent?.length > 0 ? `P_Active` : `P_Defaulf`} onClick={handleCopyCode}>Copy message</a>
                </div>
              }
            />
            {(messageObject?.messageVoiceContent.length === 0) ? <p className="err_text">{errors?.messageVoiceContent}</p> : ''}
          </Grid>

          {(messageObject?.messageVoiceContent?.length > 500) &&
            <Grid item md={12} xs={12}>
              <div className='ErrorAlert'>
                <Alert variant="filled" severity="error">
                  Your message contains too many characters. Please make your message text shorter
                </Alert>
              </div>
            </Grid>}

        </Grid>,
    },

    {
      id: 1,
      panel:
        <div>
          <div class="FieldLabel">Voice Message<span>*</span></div>

          {(messageObject?.messageVoiceRecording) && (<Grid item xs={12} sm={12} md={12} sx={{ py: 2 }}>
            <div className="UploadPhotoFiled" >
              <div className="UploadPhotoImgCol">

                <div className="ImgDetails"><p>{messageObject?.messageVoiceRecording.substring(messageObject?.messageVoiceRecording.lastIndexOf("/") + 1) || messageDetails?.messageVoiceRecording.substring(messageDetails?.messageVoiceRecording.lastIndexOf("/") + 1)}  </p></div>
              </div>
              {messageObject?.messageVoiceRecording && (<a className="UploadPhotoFiledDelete" onClick={(e) => deleteAttachment()}>
                <DeleteForeverRoundedIcon />
              </a>)}

            </div>

          </Grid>)}

          {
            messageObject?.messageVoiceRecording !== ''
              ? ""
              : (
                <Buttons
                  label="Add from media library"
                  buttonColor="transparent"
                  border="#E4E8EC 1px solid"
                  icon={<AddRoundedIcon />}
                  color="#004FE0"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={() => handleDrawerOpenSelectMedia()}
                />
              )
          }

          {(messageObject?.messageVoiceRecording.length === 0) ? <p className="err_text">{errors?.messageVoiceRecording}</p> : ''}

          {/* ========= Media lib drawer start ============ */}

          <SideDrawer
            title="Media Library"
            ButtonLabel="Add"
            //clickSaveButton={clickSaveButton}
            cancelButtonLabel="Cancel"
            sideOpen="right"
            open={openSelectMedia}
            handleDrawerClose={handleDrawerCloseSelectMedia}
            BackArrow={true}
            CloseArrowHide={true}
            FooterWithoutTab={true}
            LeftButton={true}
            ButtonLeft="Upload New"
            ButtonLeftIcon={<FileUploadOutlinedIcon />}
            clickButtonLeft={handleDrawerOpenNewMedia}
            clickSaveButton={clickSelectMediaItem}
            padding="0"
            body={
              <MediaLibrarySelectMedia
                onClickRadioIcon={setSelectedValue}
                onItemSelected={getMediaItem}
              />
            }
          />

          <Externaluse
            open={openNewMedia}
            handleCloseButton={handleMediaUploadClose}
            handleOpenNewMedia={(handleOpenNewMedia)}
          />


          {/* ====================== Attachment field end ====================== */}
        </div >

    },

    {
      id: 2,
      panel:
        <div>

          <div className='FormFieldContainer'>
            <div className='FloatLabelCol' style={{ marginTop: callToRecordContent || messageObject?.messageCallToRecordingContent ? '-6px' : '-30px', fontSize: '14px', fontWeight: 600 }}>Recording<span style={{ color: '#ff0033' }}>*</span></div>
            <div className='FormFieldCol'>


              <div className='inputError'>

                {callToRecordAgainStatus ?
                  <Buttons
                    label="Recording"
                    buttonColor="#0450E1"
                    border="#0450E11px solid"
                    color="#fff"
                    width="100%"
                    height="40px"
                    fontSize="16px"
                    onClick={listenTextHandler}
                    disabled
                    ButtonLoader={true}
                  /> : <>
                    {callToRecordContent || messageObject?.messageCallToRecordingContent ?
                      <div className='Rec_buttons'>
                        <div className='RecordedChip'> <div style={recordedChipBarStyle}></div>&nbsp;Recorded</div>

                        <Buttons
                          label="Listen"
                          buttonColor="#F8F8F8"
                          border="#F8F8F8 1px solid"
                          color="#004FE0"
                          width=""
                          height="32px"
                          fontSize="14px"
                          icon={<VolumeUpRoundedIcon />}
                          onClick={handleOpenListen}
                        />

                        <Buttons
                          label="Record again"
                          buttonColor="#F8F8F8"
                          border="#F8F8F8 1px solid"
                          color="#004FE0"
                          width=""
                          height="32px"
                          fontSize="14px"
                          icon={<ReplayOutlinedIcon />}
                          onClick={handleOpenRecordAgain}
                        />
                      </div> : <div className='PhoneField'>
                        <div className='PhoneFieldInput' >
                          <input
                            className='CustomePhoneInput'
                            style={{
                              border: `${callToRecordError ? "#BA1A1A 1px solid" : "#E8EAED 1px solid"}`
                            }}
                            type="number"
                            maxlength="10"
                            name="callToRecord"
                            value={callToRecordData}
                            onChange={handleInputChange}
                          />
                          <span> +1</span>
                          {/* {callToRecordData.length === 0 && (<span> +1</span>)} */}
                        </div>
                        <Buttons
                          label="Call now to record"
                          buttonColor="#0450E1"
                          border="#0450E11px solid"
                          color="#fff"
                          width="100%"
                          height="40px"
                          fontSize="16px"
                          onClick={handleCallToRecord}
                        />
                      </div>}
                  </>}


                {callToRecordContent || messageObject?.messageCallToRecordingContent ? '' : <p className={callToRecordError || messageObject?.messageCallToRecordingContent?.length === 0 ? 'PhoneHelperText' : ''} style={{ marginTop: '6px', fontSize: "14px", color: "#77767A" }}>Enter the phone number to call to record your voice message</p>}
              </div>


            </div>

          </div>

        </div>

    }

  ]





  return (
    <>

      <Grid container spacing={3}>

        {/* <Grid item md={12} xs={12}>
          <SelectField
            label="From"
            required={true}
            FieldLabel={true}
            options={from}
            handleChange={handleChanges}
            AddNew={true}
            onClickAddNew={() => handleOpenCategory(true)}
          />
        </Grid> */}

        <Grid item md={12} xs={12}>
          <div class="FieldLabel" style={{ marginBottom: '2px', fontSize: "12px", fontWeight: 600, color: "#1B1B1F61" }}>Type</div>
          <div className='voiceRadioBox'>
            <RedioBox
              tabsData={tabsData}
              panelDate={panelDate}
              value={value}
              onChange={onChange}
            />
          </div>
        </Grid>
      </Grid>

      {/* ========= Media lib drawer start ============ */}



      {/* Select Template*/}
      <SideDrawer
        open={openTemplate}
        handleDrawerClose={handleCloseTemplate}
        clickSaveButton={handleSelectTemplate}
        title="Select Template"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        ButtonLabel="SELECT"
        BackArrow={true}
        CloseArrowHide={true}
        FooterWithoutTab={true}
        padding="0"
        body={<SelectTemplate
          tempTemplate={tempTemplate}
          setTempTemplate={setTempTemplate} />}
      />

      {/* Add Catgory Modal*/}
      <AddCategories
        openModal={openCategory}
        handleClose={handleCloseCategory}
      />

      <div>
        {audioUrl && <audio controls autoPlay src={audioUrl}>Your browser does not support the audio element.</audio>}
      </div>


      {/* Record again?*/}
      <DraggableDialog
        openDialog={openDialogRecordAgain}
        handleCloseDialog={handleCloseRecordAgain}
        title="Record again?"
        body={<div>Are you sure you want to record again?  <br /> Current recording will be deleted.</div>}
        ModalFooter={true}
        actionButton="Record again"
        handleConfirmCloseDialog={handleRecordAgain}
      />

      {/* Dialog Listen */}
      <DraggableDialog
        openDialog={openDialogListen}
        handleCloseDialog={handleCloseListen}
        title="Recording"
        actionButton="Record again"
        textAlign="center"
        body={
          <div className='AudioListenContainer'>
            {callToRecordContent && <audio controls>
              <source src={callToRecordContent} type="audio/mp3" />
            </audio>}

            <Buttons
              label="Download audio file"
              buttonColor="#0450E1"
              border="#0450E1 1px solid"
              color="#fff"
              width="100%"
              height="48px"
              fontSize="14px"
              icon={<FileDownloadOutlinedIcon />}
              onClick={handaleDownloadFile}
            />
            <Buttons
              label="Close"
              buttonColor="#fff"
              border="#F8F8F8 0px solid"
              color="#0450E1"
              width="100%"
              height="48px"
              fontSize="14px"
              onClick={handleCloseListen}
            />
          </div>
        }
      />



    </>
  );
};

export default Voice;
