import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useGetSearchResultMutation, useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useEffect } from "react";
import useProtected from "../../../hooks/useProtected";
import CreateSchoolYear from './CreateSchoolYear';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import AddIcon from '@mui/icons-material/Add';
import {
  useGetSchoolYearListQuery,
  useCreateSchoolYearMutation,
  useGetSchoolYearSearchResultMutation,
  useUpdateSchoolYearMutation,
  useDeleteSchoolYearMutation,
  useCheckSchoolYearNameMutation,
  useCheckSchoolYearTermNameMutation
} from "../../../redux/features/school_years/schoolYearApi";
import { toast } from "react-toastify";
import EditSchoolYear from "./EditSchoolYear";
import dayjs from "dayjs";
import DraggableDialog from "../../../components/hooks/Dialog";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { isTheActionPermitted } from "../../../hooks/HelperFunctionsPermission";

const SchoolYears = () => {
  const { data, isError, isLoading, error } = useGetSchoolYearListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [createSchoolYear, createSchoolYearOptions] = useCreateSchoolYearMutation(data)
  const [searchSchoolYear, searchSchoolYearOptions] = useGetSchoolYearSearchResultMutation()
  const [updateSchoolYear, updateSchoolYearOptions] = useUpdateSchoolYearMutation()
  const [deleteSchoolYear, deleteSchoolYearOptions] = useDeleteSchoolYearMutation()
  const [checkSchoolYearName, checkSchoolYearNameOptions] = useCheckSchoolYearNameMutation()
  const [checkSchoolYearTermName, checkSchoolYearTermNameOptions] = useCheckSchoolYearTermNameMutation()

  useProtected(error)
  useProtected(createSchoolYearOptions.error)
  useProtected(updateSchoolYearOptions.error)
  useProtected(deleteSchoolYearOptions.error)
  useProtected(searchSchoolYearOptions.error)
  useProtected(checkSchoolYearNameOptions.error)
  useProtected(checkSchoolYearTermNameOptions.error)

  const [rows, setRows] = useState([])
  const [terms, setTerms] = useState([])
  const [editId, setEditId] = useState('')
  const [editName, setEditName] = useState('')
  const [editTerms, setEditTerms] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [termsNameError, setTermsNameError] = useState([])
  const [schoolYearList, setSchoolYearList] = useState([])
  const [termsNameExistError, setTermsNameExistError] = useState([])
  const [termsNameDateRangeError, setTermsNameDateRangeError] = useState([])
  const [schoolYearName, setSchoolYearName] = useState('')
  const [schoolYearEndDate, setSchoolYearEndDate] = useState([])
  const [schoolYearStartDate, setSchoolYearStartDate] = useState('')
  const [schoolYearRangeError, setSchoolYearRangeError] = useState('')
  const [academicYearNameError, setAcademicYearNameError] = useState('')
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setSchoolYearList(data?.body?.data);
    }
  }, [data, isError, isLoading])


  const monthAbbreviations = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // format date from 2023-01-22T00:00:00.000Z to 22-Jan-2023 format 
  const formatDate = (date) => {

    const inputDate = new Date(date);

    const year = inputDate.getFullYear();
    const monthIndex = inputDate.getMonth();
    const day = inputDate.getDate();

    return `${day}-${monthAbbreviations[monthIndex]}-${year}`
  }
  const editHandler = (id) => {
    handleEditDrawerOpen()
    setEditId(id)
  }

  //======= Delete dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const deleteHandler = (id) => {
    if (!isTheActionPermitted('deleteAcademicYears')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setOpenDialog(true);
    setDeleteId(id)
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //======= Delete dialog ===== //
  const deleteAcademicYearsHandler = async () => {
    let result = await deleteSchoolYear(deleteId)
    if (result?.data) {
      toast.success("Academic year deleted successfully")
    }
    setOpenDialog(false)
  }
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr.map((item) => {
        const startDate = new Date(item?.startDate);
        const endDate = new Date(item?.endDate);
        return {
          cell: [
            {
              textBold: item?.name ? <>{item?.name}&nbsp;&nbsp;</> : 'N/A',
              Status: currentDate >= startDate && currentDate < new Date(endDate.setDate(endDate.getDate() + 1)) ? true : false,
              background: '#EDF7EA',
              color: '#66BD50',
              label: 'Current',
            },
            { textBold: item?.startDate ? <b>{formatDate(item?.startDate)}</b> : 'N/A' },
            { textBold: item?.endDate ? <b>{formatDate(item?.endDate)}</b> : 'N/A' },
            { textBold: item?.terms?.length > 0 ? <span className="squareBg">{item?.terms?.length}</span> : <span className="squareBg">0</span> },
            // {
            //   Status: true,
            //   background: '#EDEDEF',
            //   color: '#46464A',
            //   label: item?.terms?.length > 0 ? item?.terms?.length : 0
            // },
            {
              ActionButton: true,
              ActionButtonLabel: <span style={{ fontWeight: '600' }}>ACTIONS</span>,
            },
          ],

          //======= Action Date Start===== //
          action: [
            { label: 'EDIT', onClick: (e) => editHandler(item?._id) },
            { label: 'DELETE', onClick: (e) => deleteHandler(item?._id) },
          ],
          //======= Action Date End===== //
        }
      });
    } else {
      return [];
    }

  };


  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name", width: "30%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Start", width: "18%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "End", width: "18%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "Terms", width: "25%", textAlign: "left", },
    { id: "name", numeric: false, disablePadding: true, label: "", width: "20%", textAlign: "left", },

  ];

  // =============================== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchSchoolYear({ term: "" });
    searchDataShowing(result)
  };
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await searchSchoolYear({ term: searchTerm });
    searchDataShowing(result)
  }
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =================================== search part end ====================== //

  // Filter //
  const [openCreateDrawer, setOpenCreate] = useState(false);
  const [openEditDrawer, setOpenEdit] = useState(false);

  const handleCreateDrawerOpen = () => {
    setOpenCreate(true);
  };
  const handleEditDrawerOpen = () => {
    setOpenEdit(true);
  };

  const handleCloseCreateDrawer = () => {
    setIsSaving(false)
    setOpenCreate(false);
    setSchoolYearName('')
    setSchoolYearStartDate(new Date(formattedDate))
    setSchoolYearEndDate(new Date(formattedDate))
    setAcademicYearNameError('')
    setSchoolYearRangeError('')
    setTerms([])
  };

  const handleCloseEditDrawer = () => {
    setIsSaving(false)
    setEditId('')
    setOpenEdit(false);
    setSchoolYearName('')
    setSchoolYearStartDate(new Date(formattedDate))
    setSchoolYearEndDate(new Date(formattedDate))
    setTerms([])
  };

  const isValid = () => {
    if (schoolYearName != '') {

      setAcademicYearNameError('')
      return true
    } else {
      setAcademicYearNameError('Required')
      return false
    }
  }
  const isTermValid = async () => {

    let isValid = true
    let termNameErrorArray = []
    terms.map((term) => {
      if (isValid) {
        if (term.termName == '') {
          isValid = false
        }
      }
      termNameErrorArray.push((term.termName == '') ? 'Required' : '')
    })
    setTermsNameError(termNameErrorArray)

    return isValid
  }

  const isTermNameExists = async () => {
    let error = 0
    let termNameExistErrorArray = []
    let i = 0
    console.log('here it is')
    for (i; i < terms.length; i++) {
      let found_another = terms?.find((singleTerm, index) => (singleTerm.termName == terms[i]?.termName) && index != i)
      if (found_another) {
        termNameExistErrorArray.push('Term name already exists')
        error++
      } else {
        termNameExistErrorArray.push('')
      }
    }
    setTermsNameExistError(termNameExistErrorArray)
    return (error > 0)
  }

  function isDateRangeValid(newStartDate, newEndDate, dataArray) {
    const newStart = new Date(newStartDate);
    const newEnd = new Date(newEndDate);

    let overLap = false;
    dataArray.map((singleSchoolYear) => {
      const existingStart = new Date(singleSchoolYear?.startDate);
      const existingEnd = new Date(singleSchoolYear?.endDate);
      if (newStart <= existingEnd && newEnd >= existingStart && singleSchoolYear?._id != editId) {
        overLap = true;
      }

    })

    return !overLap; // No overlap found
  }

  const isValidDateRange = async () => {
    var startDate = new Date(schoolYearStartDate);
    var endDate = new Date(schoolYearEndDate);
    if (startDate > endDate) {
      setSchoolYearRangeError('Invalid date range')
      return false
    } else if (!isDateRangeValid(startDate, endDate, schoolYearList)) {
      setSchoolYearRangeError('The dates for this academic year must not overlap with those of an existing academic year. Please enter different dates.')
      return false
    }
    setSchoolYearRangeError('')
    return true
  }

  const isTermDateRangeOk = async () => {
    let error = 0
    let termNameDateRangeErrorArray = []
    let i = 0
    for (i; i < terms.length; i++) {

      let startDate = new Date(terms[i].startDate);
      let endDate = new Date(terms[i].endDate);
      let schoolYearStartDateToTimeSpan = new Date(schoolYearStartDate);
      let schoolYearEndDateToTimeSpan = new Date(schoolYearEndDate);

      if ((startDate > endDate) || (startDate < schoolYearStartDateToTimeSpan || endDate < schoolYearStartDateToTimeSpan || startDate > schoolYearEndDateToTimeSpan || endDate > schoolYearEndDateToTimeSpan)) {
        termNameDateRangeErrorArray.push('Invalid date range')
        error++
      } else {
        termNameDateRangeErrorArray.push('')
      }

      // Check for overlapping dates within the terms array
      for (let j = 0; j < terms.length; j++) {
        if (i !== j) { // Avoid comparing the term with itself
          let otherStartDate = new Date(terms[j].startDate);
          let otherEndDate = new Date(terms[j].endDate);

          if ((startDate <= otherEndDate) && (endDate >= otherStartDate)) {
            termNameDateRangeErrorArray[i] = 'The dates for this term must not overlap with those of an existing term. Please enter different dates.';
            error++;
            break; // No need to check further once an overlap is found
          }
        }
      }
    }
    setTermsNameDateRangeError(termNameDateRangeErrorArray)
    return (error == 0)
  }


  let checkSchoolYearNameValidity = async () => {
    if (schoolYearName == "") {
      setAcademicYearNameError("Required")
      return false
    }
    let resultCheck = await checkSchoolYearName({ name: schoolYearName })
    if (resultCheck?.data && resultCheck?.data?.body?.data) {
      if (editId == "" || (editId != "" && editName != schoolYearName)) {
        setAcademicYearNameError("Academic year name already exists")
        return false
      }
    }
    setAcademicYearNameError("")
    return true
  }

  // Filter //

  const saveSchoolYearHander = async () => {
    if (isSaving) return

    setIsSaving(true)
    if (terms.length == 0) {
      setIsSaving(false)
      toast.error('Add at least one term to continue');
      return false
    }

    let newTerms = [...terms]

    newTerms.forEach(term => {
      term.workdays = term.workdays.map(day => day.toLowerCase());
    });

    const schoolYearData = {
      name: schoolYearName,
      startDate: schoolYearStartDate,
      endDate: schoolYearEndDate,
      status: "pending",
      schoolCode: "",
      schoolYear: "",
      terms: newTerms
    }

    let checkSchoolYearNameValid = await checkSchoolYearNameValidity()
    let valid_term = await isTermValid()
    let termNameExists = await isTermNameExists()
    let isValidDateRangeOk = await isValidDateRange()
    let isTermValidDateRangeOk = await isTermDateRangeOk()

    if (termNameExists || !checkSchoolYearNameValid || !valid_term || !isValidDateRangeOk || !isTermValidDateRangeOk) {
      setIsSaving(false)
      return false
    }

    let result
    if (editId === '') {
      result = await createSchoolYear(schoolYearData)
    } else {
      result = await updateSchoolYear({ ...schoolYearData, id: editId })
    }

    if (result?.data) {
      if (editId === '') {
        handleCloseCreateDrawer()
        toast.success("Academic year added")
      } else {
        handleCloseEditDrawer()
        toast.success("Academic year saved")
      }
      setSchoolYearName('')
      setSchoolYearStartDate(new Date(formattedDate))
      setSchoolYearEndDate(new Date(formattedDate))

      setTerms([])
    } else {
      toast.success("Something went wrong")
    }
  }
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split('T')[0];

  useEffect(() => {
    setSchoolYearStartDate(new Date(formattedDate))
    setSchoolYearEndDate(new Date(formattedDate))
  }, [])


  useEffect(() => {
    console.log(isSaving, 500, "xgsd")
  }, [isSaving])



  return (
    <>
      <div className="TableSection">
        <Table
          title="Academic Years"
          tableHeader={true}
          addLabel="Add Academic year"
          icon={<AddIcon />}
          AddButton={true}
          headCells={headCells}
          rows={rows}
          onClick={handleCreateDrawerOpen}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          SearchBoxTop={true}
          searchBoxPlaceholder="Search"
          isDataLoading={isLoading || searchSchoolYearOptions?.isLoading}
          RowsPerPage={20}
          emptyTableTitle="No academic year added yet"
          emptyTableNote="No items available"
          btnLabel="Add Academic year"
          showButton={true}
          onClickEmptyCard={handleCreateDrawerOpen}
        />
      </div>


      {/* Filter */}
      <SideDrawer
        open={openCreateDrawer}
        handleDrawerClose={handleCloseCreateDrawer}
        title="Create Academic Year"
        buttonLabel="Create Now"
        sideOpen="right"
        ButtonLabel="Create Now"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        clickSaveButton={(e) => saveSchoolYearHander()}
        body={
          <>
            <CreateSchoolYear
              schoolYearName={schoolYearName}
              setSchoolYearName={setSchoolYearName}
              schoolYearStartDate={schoolYearStartDate}
              setSchoolYearStartDate={setSchoolYearStartDate}
              schoolYearEndDate={schoolYearEndDate}
              setSchoolYearEndDate={setSchoolYearEndDate}
              terms={terms}
              setTerms={setTerms}
              formattedDate={formattedDate}
              createSchoolYearOptions={createSchoolYearOptions}
              closeDrawer={handleCloseCreateDrawer}
              saveSchoolYearHander={saveSchoolYearHander}
              academicYearNameError={academicYearNameError}
              setAcademicYearNameError={setAcademicYearNameError}
              termsNameError={termsNameError}
              schoolYearRangeError={schoolYearRangeError}
              termsNameExistError={termsNameExistError}
              termsNameDateRangeError={termsNameDateRangeError} />
          </>
        }
      />

      {/* Filter */}
      <SideDrawer
        open={openEditDrawer}
        handleDrawerClose={handleCloseEditDrawer}
        title="Edit Academic Year"
        buttonLabel="Update Now"
        sideOpen="right"
        ButtonLabel="Update Now"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        clickSaveButton={(e) => saveSchoolYearHander()}
        body={
          <>
            <EditSchoolYear
              schoolYearName={schoolYearName}
              setSchoolYearName={setSchoolYearName}
              schoolYearStartDate={schoolYearStartDate}
              setSchoolYearStartDate={setSchoolYearStartDate}
              schoolYearEndDate={schoolYearEndDate}
              setSchoolYearEndDate={setSchoolYearEndDate}
              terms={terms}
              setTerms={setTerms}
              formattedDate={formattedDate}
              createSchoolYearOptions={createSchoolYearOptions}
              closeDrawer={handleCloseEditDrawer}
              saveSchoolYearHander={saveSchoolYearHander}
              id={editId}
              academicYearNameError={academicYearNameError}
              setAcademicYearNameError={setAcademicYearNameError}
              termsNameError={termsNameError}
              schoolYearRangeError={schoolYearRangeError}
              termsNameExistError={termsNameExistError}
              termsNameDateRangeError={termsNameDateRangeError}
              setEditName={setEditName}
              setEditTerms={setEditTerms}
            />
          </>
        }
      />
      {/* <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={openDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={deleteAcademicYearsHandler}
      /> */}
      {/* Delete Modal*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        handleConfirmCloseDialog={deleteAcademicYearsHandler}
        title="Delete this academic year?"
        body={<div>Are you sure you want to delete this? <br />Once you delete it, you can't get it back.</div>}
        ModalFooter={true}
        actionButton="Delete"
      />

    </>
  );
};

export default SchoolYears;
