import React, { useEffect, useState } from "react";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';

import img from '../../../assets/imgs/User.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from "dayjs";

import Buttons from '../../../components/Form/Button/Button';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";

import EmailDuplicate from './EmailDuplicate';
import SMSDuplicate from "./SMSDuplicate";
import VoiceDuplicate from "./VoiceDuplicate";

const Details = (props) => {
  const {
    messageDetails
  } = props

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F8F8F8',
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//


  // Classes Drawer //
  const [openEmail, setOpenEmail] = useState(false);
  const handleEmailDrawerOpen = () => {
    setOpenEmail(true);
  };
  const handleCloseEmailDrawer = () => {
    setOpenEmail(false);
  };

  // SMS Drawer //
  const [openSms, setOpenSms] = useState(false);
  const handleSmsDrawerOpen = () => {
    setOpenSms(true);
  };
  const handleCloseSmsDrawer = () => {
    setOpenSms(false);
  };

  // Voice Drawer //
  const [openVoice, setOpenVoice] = useState(false);
  const handleVoiceDrawerOpen = () => {
    setOpenVoice(true);
  };
  const handleCloseVoiceDrawer = () => {
    setOpenVoice(false);
  };



  return (
    <>
      <div className="SentDetailsTableContainer">
        <div className="TableContainer oddEvenTable">
          <div className="TableInnerHeader" style={{ padding: '14px 18px' }}>
            <h3>Details</h3>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel" width="25%">
                    ID
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {messageDetails?.messageId || "N/A"}

                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel" width="25%">
                    Created Date
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <u>{dayjs(messageDetails?.createdAt).format('DD-MMM-YYYY, h:mm A')}</u>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel" width="25%">
                    Created By
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="UserNamePhoto"><Avatar src={messageDetails?.createdBy?.profilePic}>{(!messageDetails?.createdBy?.profilePic) && messageDetails?.createdBy?.name.charAt(0).toUpperCase()}</Avatar> {messageDetails?.createdBy?.name}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>

                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel" width="25%">
                    Last Updated
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <u>{dayjs(messageDetails?.updatedAt).format('DD-MMM-YYYY, h:mm A')}</u> <span className="ByText">by</span> {messageDetails?.updatedBy?.name}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel" width="25%">
                    Category
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {messageDetails?.category}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Message Name
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {messageDetails?.messageName}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                  </StyledTableCell>
                </StyledTableRow>
                {messageDetails?.messageEmailContent != "" &&
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="AddedChip"><CheckCircleIcon /> Added</div>
                    </StyledTableCell>

                    <StyledTableCell align="right" className="TableInfo">
                      <div style={{ display: "inline-block", }}>
                        <Buttons
                          label="View details"
                          buttonColor="transparent"
                          border="#E4E8EC 0px solid"
                          color="#0450E1"
                          width=""
                          height="40px"
                          fontSize="14px"
                          onClick={handleEmailDrawerOpen}
                        />
                      </div>
                    </StyledTableCell>



                  </StyledTableRow>}
                {messageDetails?.messageSmsContent != "" &&
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      SMS
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="AddedChip"><CheckCircleIcon /> Added</div>
                    </StyledTableCell>
                    <StyledTableCell align="right" className="TableInfo">
                      <div style={{ display: "inline-block", }}>
                        <Buttons
                          label="View details"
                          buttonColor="transparent"
                          border="#E4E8EC 0px solid"
                          color="#0450E1"
                          width=""
                          height="40px"
                          fontSize="14px"
                          onClick={handleSmsDrawerOpen}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>}
                {(messageDetails?.messageVoiceContent != "" || messageDetails?.messageVoiceRecording != "" || messageDetails?.messageCallToRecordingContent != "") &&
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Voice
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="AddedChip"><CheckCircleIcon /> Added</div>
                    </StyledTableCell>
                    <StyledTableCell align="right" className="TableInfo">
                      <div style={{ display: "inline-block", }}>
                        <Buttons
                          label="View details"
                          buttonColor="transparent"
                          border="#E4E8EC 0px solid"
                          color="#0450E1"
                          width=""
                          height="40px"
                          fontSize="14px"
                          onClick={handleVoiceDrawerOpen}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>}

              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>


      {/* Email Details*/}
      <SideDrawer
        open={openEmail}
        handleDrawerClose={handleCloseEmailDrawer}
        title="Email"
        sideOpen="right"
        ButtonLabel="CLOSE"
        clickSaveButton={handleCloseEmailDrawer}
        // cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <EmailDuplicate messageDetails={messageDetails} />
          </>
        }
      />
      {/* SMS Duplicate*/}
      <SideDrawer
        open={openSms}
        handleDrawerClose={handleCloseSmsDrawer}
        title="SMS"
        sideOpen="right"
        ButtonLabel="CLOSE"
        clickSaveButton={handleCloseSmsDrawer}
        // cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <SMSDuplicate messageDetails={messageDetails} />
          </>
        }
      />

      {/* Voice Duplicate*/}
      <SideDrawer
        open={openVoice}
        handleDrawerClose={handleCloseVoiceDrawer}
        title="Voice"
        sideOpen="right"
        ButtonLabel="CLOSE"
        clickSaveButton={handleCloseVoiceDrawer}
        // cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <VoiceDuplicate messageDetails={messageDetails} />
          </>
        }
      />



    </>
  );
};

export default Details;
