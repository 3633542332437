import React, { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CheckBox from '../../components/Form/CheckBox/CheckBox';
import Datefield from '../../components/Form/Datefield/Datefield';

import '../../assets/css/filter.css';
import dayjs from "dayjs";
import { toTitleCase } from "../../utils/helpers";

const Filter = (props) => {
  const { statuses, setStatuses, streams, setStream, userTypes, setUserTypes, startDate, endDate, setStartDate, setEndDate, roles, setDefaultDate, defaultDate } = props
  // const [expanded, setExpanded] = React.useState(true);

  // const handleChange = (panel1) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel1 : false);
  // };


  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const changeStatusHandler = (e, value) => {
    let newStatuses = [...statuses]
    if (e.target.checked) {
      if (!statuses.includes(value)) {
        newStatuses.push(value);
        setStatuses(newStatuses)
      }
    } else {
      if (statuses.includes(value)) {
        newStatuses = statuses.filter((status) => status !== value)
        setStatuses(newStatuses)
      }
    }

  }

  const changeStreamHandler = (e, value) => {
    let newStreams = [...streams]
    if (e.target.checked) {
      if (!streams.includes(value)) {
        newStreams.push(value);
        setStream(newStreams)
      }
    } else {
      if (streams.includes(value)) {
        newStreams = streams.filter((stream) => stream !== value)
        setStream(newStreams)
      }
    }

  }

  const changeUserTypeHandler = (e, value) => {
    let newUserTypes = [...userTypes]
    if (e.target.checked) {
      if (!userTypes.includes(value)) {
        newUserTypes.push(value);
        setUserTypes(newUserTypes)
      }
    } else {
      if (userTypes.includes(value)) {
        newUserTypes = userTypes.filter((stream) => stream !== value)
        setUserTypes(newUserTypes)
      }
    }

  }

  const showUserTypes = () => {
    let userTypeCheckboxes = []
    roles.map((role) => {
      userTypeCheckboxes.push(<CheckBox
        label={toTitleCase(role.name)}
        checked={userTypes.includes(role.name)}
        onChange={(e) => changeUserTypeHandler(e, role.name)}
      />)
    })
    return userTypeCheckboxes
  }
  return (

    <div className="FilterMainCont">

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="AccTitle">
            Joined date
          </Typography>
          {(!defaultDate) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>1</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          <div className="FieldLabel">Select date range</div>
          <div className="FilterDate">

            <Datefield value={dayjs(startDate)} handleChange={(e) => setStartDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`, setDefaultDate(false))} />
            <span className="RangeDivider">-</span>
            <Datefield value={dayjs(endDate)} handleChange={(e) => setEndDate(`${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}`, setDefaultDate(false))} />
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className="AccTitle">
            Status
          </Typography>
          {(statuses?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{statuses?.length}</Typography>}
        </AccordionSummary>
        <AccordionDetails>

          <CheckBox
            label="Active"
            checked={statuses.includes('active')}
            onChange={(e) => changeStatusHandler(e, 'active')}
          />

          <CheckBox
            label="Inactive"
            checked={statuses.includes('inactive')}
            onChange={(e) => changeStatusHandler(e, 'inactive')}
          />

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="AccTitle">
            Stream
          </Typography>
          {(streams?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{streams?.length}</Typography>}
        </AccordionSummary>
        <AccordionDetails>

          <CheckBox
            label="Online"
            checked={streams.includes('online')}
            onChange={(e) => changeStreamHandler(e, 'online')}
          />

          <CheckBox
            label="Manual"
            checked={streams.includes('manual')}
            onChange={(e) => changeStreamHandler(e, 'manual')}
          />

        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="AccTitle">
            User type

          </Typography>
          {(userTypes?.length > 0) && <Typography className="FilterCount" style={{ fontSize: '14px' }}>{userTypes?.length}</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          {(roles?.length > 0) &&
            showUserTypes()

          }

        </AccordionDetails>
      </Accordion>




    </div>
  );
};

export default Filter;
