import { api } from '../../api/apiSlice';

const couponApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllCoupons: builder.query({
            query: () => ({
                url: '/coupons/admin/all',
            }),
            providesTags: ['Coupons'],
        }),
        getCouponDetails: builder.query({
            query: (id) => ({
                url: `/coupons/admin/getSingle/${id}`,
            }),
            providesTags: ['Coupon'],
        }),
        addCoupons: builder.mutation({
            query: (data) => ({
                url: `/coupons/admin/create`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Coupons'],
        }),
        updateCoupons: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/coupons/admin/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Coupons'],
        }),
        checkCouponCode: builder.mutation({
            query: (data) => ({
                url: '/coupons/checkCouponCode',
                method: 'POST',
                body: data,
            }),
        }),
        deleteCoupon: builder.mutation({
            query: (id) => ({
                url: `/coupons/admin/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Coupons'],
        }),
        checkUniqueCoupon: builder.mutation({
            query: (data) => ({
                url: `/coupons/admin/checkUnique`,
                method: 'POST',
                body: data
            })
        })
    }),
});

export const {
    useGetAllCouponsQuery,
    useGetCouponDetailsQuery,
    useAddCouponsMutation,
    useUpdateCouponsMutation,
    useCheckCouponCodeMutation,
    useDeleteCouponMutation,
    useCheckUniqueCouponMutation
} = couponApi;
