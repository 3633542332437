import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/css/TermsForm.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import TextField from "../../../components/Form/TextField/TextField";
import Buttons from '../../../components/Form/Button/Button';
import AddSender from './AddSender';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TextEditor from '../../../components/TextEditor/TextEditor';
import img from '../../../assets/imgs/User.png';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import SelectTemplate from './SelectTemplate';
import { toast } from 'react-toastify';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MediaLibrarySelectMedia from '../../../components/MediaLibrary/MediaLibrarySelectMedia';
import Externaluse from '../../../components/MediaLibrary/Externaluse';




const Email = (props) => {

  const {
    messageDetails,
    emailSenders,
    setEmailSenders,
    emailSenderOptions,
    messageObject,
    setMessageObject,
    errors
  } = props

  const [openRecipientseDrawer, setRecipientse] = useState(false);
  const handleOpenRecipients = () => setRecipientse(true);
  const handleCloseRecipients = () => setRecipientse(false);

  const [openTemplate, setTemplate] = useState(false);
  const handleOpenTemplate = () => setTemplate(true);
  const handleCloseTemplate = () => setTemplate(false);
  const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [selectedValue2, setSelectedValue2] = React.useState(false);
  const [mediaItem2, setMediaItem2] = useState({});

  const [tempTemplate, setTempTemplate] = useState({})

  const tabData = [
    {
      lebel: "Select individuals",
      //icon: <InsertDriveFileIcon />,
    },

    {
      lebel: "Select groups",
      //icon: <YouTubeIcon />,
    },
  ]

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <></>

      ),

      SaveButtonLabel: 'Send',
      //clickSaveButton: handleAddGuardian,
      CancelButtonLabel: 'Cancel',
    },

    {
      id: 1,
      panleBody: (
        <></>
      ),
      SaveButtonLabel: 'Send Invitation',
      //clickSaveButton: handleInviteUsers,
      CancelButtonLabel: 'Cancel',
    },
  ];




  const backgroundInCount = {
    backgroundColor: '#f2f0f4',
  };



  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const to = [
    {
      label: "Category",
      value: "Category",
    },

    {
      label: "Category",
      value: "Category",
    },

  ]

  const from = [
    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

    {
      label: "Quran Academy for Young Scholars",
      value: "Quran Academy for Young Scholars",
    },

  ]


  const [openCategory, setOpenCategory] = useState(false);
  const handleOpenCategory = () => { setOpenCategory(true); };

  const handleCloseCategory = () => {
    setOpenCategory(false)
  };

  const [isActive, setActive] = useState("false");
  const menuRef = useRef(null);

  const onFocusHandler = () => {
    setActive(true);
  }
  const handleToggle = () => {
    setActive(!isActive);
  };
  const onChangeHandler = (val, e, fieldName) => {
    let newObject = { ...messageObject, [fieldName]: e.target.value }
    setMessageObject(newObject)
  }
  const textEditorChangeHandler = (val, fieldName) => {

    let newObject = { ...messageObject, [fieldName]: val }
    setMessageObject(newObject)
  }

  useEffect(() => {
    let newObject = { ...messageObject, messageEmailContent: '[{ "type": "paragraph", "children": [{ "text": "" }] }]' }
    setMessageObject(newObject)
    // console.log(messageObject?.messageEmailContent, 328)
  }, [])
  const handleSelectTemplate = () => {
    setCustomText(tempTemplate?.content)
    handleCloseTemplate()
    setTempTemplate({})
  }

  const handleCopyCode = () => {
    let text = extractPlainText(messageObject?.messageEmailContent)
    if (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          toast.success('Copied');

        })
        .catch((error) => {
          console.error('Error copying to clipboard: ', error);
          toast.error('Failed to copy code');
        });
    } else {
      toast.warning('No code to copy');
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    navigator.clipboard.readText()
      .then(text => {
        let pasteData = [...text.split(",").filter(char => char.trim() !== "")]
        setCustomText(pasteData)
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }



  const handleDrawerCloseSelectMedia2 = () => {
    setSelectedValue2(false)
    setMediaItem2({})
    setOpenSelectMedia2(false);
  };
  const handleOpenNewMedia2 = (status) => {
    setOpenNewMedia2(!status);
    handleDrawerOpenSelectMedia2(status);
  };

  const handleDrawerOpenSelectMedia2 = () => {
    setOpenSelectMedia2(true);
  };
  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };
  const getMediaItem2 = (item) => {
    // setting the media item
    setMediaItem2(item);
  };
  const handleMediaUploadClose2 = () => {
    setOpenNewMedia2(false);
  };
  const clickSelectMediaItem2 = async () => {

    if (selectedValue2) {
      setMessageObject({ ...messageObject, messageEmailAttachments: [...messageObject.messageEmailAttachments, mediaItem2] })
      setSelectedValue2(false)
      setMediaItem2({})
      setOpenSelectMedia2(false);
    } else {
      toast.error("No item is selected")
    }
  };
  const deleteAttachment = (id) => {
    let newAttachments = messageObject?.messageEmailAttachments?.filter((attachment, index) => index !== id)
    setMessageObject({ ...messageObject, messageEmailAttachments: newAttachments })
  }



  const [customText, setCustomText] = useState(null);

  const handleInsertTag = (event, tag) => {
    setCustomText(tag);
  };


  const extractPlainText = (jsonData) => {
    const textSegments = [];

    // Check if jsonData is a valid JSON string
    let parsedData;
    try {
      parsedData = JSON.parse(jsonData);
    } catch (error) {
      console.error("Invalid JSON string:", error);
      return ""; // Return empty string if jsonData is not valid JSON
    }

    const processChildren = (children) => {
      children.forEach((item) => {
        if (item.text !== undefined) {
          textSegments.push(item.text);
        } else if (item.children) {
          processChildren(item.children); // Recursively process nested children
        } else if (item.text === "") {
          textSegments.push(" ");
        }
      });
    };

    let allChildren = parsedData ? parsedData[0]?.children : null;
    if (allChildren) {
      processChildren(allChildren);
    }

    return textSegments.join("");
  }

  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={12} xs={12}>
          <SelectField
            FieldLabel={true}
            name="messageEmailFrom"
            label="From"
            required
            // required={messageObject?.messageEmailFrom.length === 0 ? errors?.messageEmailFrom : ''}
            options={emailSenderOptions}
            // handleChange={handleChanges}
            AddNew={true}
            selectedOption={messageObject?.messageEmailFrom}
            helperText={messageObject?.messageEmailFrom.length === 0 ? errors?.messageEmailFrom : ''}
            error={messageObject?.messageEmailFrom.length === 0 ? errors?.messageEmailFrom : ''}
            handleChange={(val) => onChangeHandler(val, val, 'messageEmailFrom')}
            onClickAddNew={() => handleOpenCategory(true)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label="Subject"
            required={true}
            validation={true}

            value={messageObject.messageEmailSubject}
            helperText={messageObject?.messageEmailSubject.length === 0 ? errors?.messageEmailSubject : ''}
            error={messageObject?.messageEmailSubject.length === 0 ? errors?.messageEmailSubject : ''}
            handleChange={(val, e) => onChangeHandler(val, e, 'messageEmailSubject')}
            count={50}
            CharAlignMid="CharAlignMid"
          />
        </Grid>

        <Grid item md={12} xs={12}>


          <TextEditor
            FieldLabel="Message"
            EditorError={messageObject?.messageEmailContent.length === 0 || messageObject?.messageEmailContent.length === 47 ? errors?.messageEmailContent ? "EditorError" : '' : ''} //== here is the error state ==//
            value={messageObject?.messageEmailContent}
            // onChange={(val) => textEditorChangeHandler(JSON.parse(val)[0].children[0].text, 'messageEmailContent')}
            onChange={(val) => textEditorChangeHandler(val, 'messageEmailContent')}
            // onChange={(val) => onChangeHandler(val, val, 'messageEmailContent')}
            required={true}
            customText={customText}
            handleOnFocus={(e) => onFocusHandler()}
            ref={menuRef}
            HeaderRightBody={
              <div className='InsertSection'>
                <a onClick={handleOpenTemplate}><TextSnippetRoundedIcon />Insert template</a>
                <span>|</span>
                <a onClick={handleToggle}><BookmarkRoundedIcon />Insert tag</a>

                <div className={isActive ? "PricingCell" : "Active"} ref={menuRef}>
                  <div className='DropDownMenuCustom'>
                    <a onClick={(event) => handleInsertTag(event, '{{FirstName}}')}>First name</a>
                    <a onClick={(event) => handleInsertTag(event, '{{LastName}}')}>Last name</a>
                    <a onClick={(event) => handleInsertTag(event, '{{FullName}}')}>Full name</a>
                  </div>
                  <div className='backdropAlfa' onClick={handleToggle}></div>
                </div>
              </div>
            }
            helperText={
              <div className='TextpastSection'>
                <a className='P_Active' onClick={(e) => handlePaste(e)}>PASTE</a><span>|</span> <a className={extractPlainText(messageObject?.messageEmailContent)?.length > 0 ? `P_Active` : `P_Defaulf`} onClick={handleCopyCode}>Copy message</a>
              </div>
            }
            charLimit={1000}
            height="150px"

          />

          {(messageObject?.messageEmailContent.length === 0 || messageObject?.messageEmailContent.length === 47) ? <p className="err_text">{errors?.messageEmailContent}</p> : ''}


        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {
            messageObject?.messageEmailAttachments?.length === 0 ? "" :
              <div className="FieldLabel" style={{ color: '#1B1B1F' }}>Attachments</div>
          }
          {messageObject?.messageEmailAttachments?.map((attachment, index) => (<div className="UploadPhotoFiled" key={index} style={{ marginBottom: '8px' }}>
            <div className="UploadPhotoImgCol">
              <span className='attachmentIcon'>
                {/* <img src={attachment?.url} /> */}
                <AttachmentRoundedIcon />
              </span>
              <div className="ImgDetails">{attachment?.name} <p>{(attachment?.size / 1024).toFixed(3)} MB <FiberManualRecordRoundedIcon /> {attachment?.originalFileName}</p></div>
            </div>
            <a className="UploadPhotoFiledDelete" onClick={(e) => deleteAttachment(index)}>
              <DeleteForeverRoundedIcon />
            </a>
          </div>))
          }
        </Grid>

        <SideDrawer
          title="Media Library"
          ButtonLabel="Add"
          //clickSaveButton={clickSaveButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={openSelectMedia2}
          handleDrawerClose={handleDrawerCloseSelectMedia2}
          FooterWithoutTab={true}
          LeftButton={true}
          BackArrow={true}
          CloseArrowHide={true}
          ButtonLeft="Upload New"
          ButtonLeftIcon={<FileUploadOutlinedIcon />}
          clickButtonLeft={handleDrawerOpenNewMedia2}
          clickSaveButton={clickSelectMediaItem2}
          padding="0"
          body={
            <>
              <br />
              <MediaLibrarySelectMedia
                onClickRadioIcon={setSelectedValue2}
                onItemSelected={getMediaItem2}
              />
            </>
          }
        />

        <Externaluse
          open={openNewMedia2}
          handleCloseButton={handleMediaUploadClose2}
          handleOpenNewMedia={(handleOpenNewMedia2)}
        />
        <Grid item xs={12} sm={12} md={12}>
          {
            messageObject?.messageEmailAttachments?.length === 0 ?
              <div className="FieldLabel" style={{ color: '#1B1B1F' }}>Attachments</div> : ""
          }
          {messageDetails?._id ? '' : <Buttons
            label="Add attachment"
            buttonColor=""
            color="#0450E1"
            width="100%"
            height="48px"
            fontSize="14px"
            fontWeight={700}
            onClick={(e) => handleDrawerOpenSelectMedia2()}
            // onClick={() => {
            //   setOpen(false);
            //   setImageFromCropper(null)
            //   setImageFromCropperSrc(null)
            //   setImageFromCropperName("")
            // }
            // }
            border="#E4E2E6 1px solid"
            textTransform="uppercase"
            icon={<AddRoundedIcon />}
          />}

          {(errors?.messageAttachment != "") && <div class="UplaodTextHelper">{errors?.messageAttachment}</div>}
        </Grid>


      </Grid>

      {/* Select Recipients*/}
      <SideDrawer
        open={openRecipientseDrawer}
        handleDrawerClose={handleCloseRecipients}
        title="New Message"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        tabData={tabData}
        panelTab={panelTab}
        CheckBoxTabsStatus={true}
        TabsStatus={true}
      />

      {/* Select Template*/}
      <SideDrawer
        open={openTemplate}
        handleDrawerClose={handleCloseTemplate}
        clickSaveButton={handleSelectTemplate}
        title="Select Template"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        ButtonLabel="SELECT"
        BackArrow={true}
        CloseArrowHide={true}
        FooterWithoutTab={true}
        padding="0"
        body={< SelectTemplate
          tempTemplate={tempTemplate}
          setTempTemplate={setTempTemplate} />}
      />

      {/* Add Catgory Modal*/}
      <AddSender
        openModal={openCategory}
        handleClose={handleCloseCategory}
      />


    </>
  );
};

export default Email;
