import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
// import FieldPassword from '../../../components/FieldPassword/FieldPassword';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { useNavigate } from 'react-router-dom';
import axiosApi from '../../utils/axios';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import { validateFormData } from '../../utils/form';
import LogoHeader from './LogoHeader/LogoHeader';
// import AlrtMessage from '../../../components/AlrtMessage/AlrtMessage';

// import styles from '../../../assets/css/form.module.css';

function ForgotPassword(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });


  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //================== google recapcha v3 part end ====================//


  const handleForgetPassword = async () => {

    try {
      const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
      ///===================== recapcha ==============================///

      const data = {
        email: email ? email.toLowerCase() : '',
        token
      };

      const isValid = validateFormData(data, setErrors, {
        email: 'Enter your email',
      });
      if (!isValid) {
        return;
      }

      try {
        const result = await axiosApi.post('/users/public/forgot-password', data);
        if (result.data.error) {
          setAlertOptions({
            msg: result.data.message,
            type: 'error',
          });
          return;
        }

        setAlertOptions({
          msg: result.data.message,
          type: 'success',
        });

        navigate(`/email-sent?email=${data.email}`);
      } catch (err) {
        setAlertOptions({
          msg: err.message,
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  return (
    <>
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Forgot password?</h1>
            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <p className="FormHelpText">
                  Please enter the email address below that you use with your
                  account.
                </p>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  placeholder="Email address"
                  required={true}
                  email={true}
                  value={email}
                  handleChange={(value) => {
                    if (value == "") {
                      setErrors((prev) => {
                        prev.email = ' ';
                        return prev;
                      });
                    } else {
                      setErrors((prev) => {
                        prev.email = '';
                        return prev;
                      });
                    }
                    setEmail(value);
                  }}
                  helperText={errors?.email}
                  error={errors?.email}
                  showErrorMsg={false}
                //error
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Send Reset Email"
                  buttonColor="#0450e1"
                  border="#0450e1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  fontWeight="700"
                  link=""
                  onClick={handleForgetPassword}
                />

                <div className='FormCancelButton'>
                  <Buttons
                    label="Cancel"
                    buttonColor="#fff"
                    border="#fff 1px solid"
                    color="#0450E1"
                    width="100%"
                    // height="48px"
                    fontSize="14px"
                    link="/signin"
                    onClick={undefined}
                  />
                </div>
              </Grid>


            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
