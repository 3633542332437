import React, { useState, useRef, useEffect } from 'react';
import Table from '../Table/Table';
import SideDrawer from '../SideDrawer/SideDrawer';
import UploadFromDevice from './UploadFromDevice';
import UploadFromYoutube from './UploadFromYoutube';
import EditMedia from './EditMedia';

import DraggableDialog from '../Dialog/Dialog';
// import CustomizedSnackbar from '../../hooks/Snackbar';
//import internal functions
import {
  convertFileSize,
  generaImageUrl,
} from './Functions';
// import SimpleBackdrop from '../../components/Form/Backdrop/SimpleBackdrop';
import YouTubeIcon from '@mui/icons-material/YouTube';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import MediaLibrarySelectMedia from './MediaLibrarySelectMedia';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import './MediaLibrary.css';
import {
  useAddMediaLibMutation,
  useDeleteMediaLibMultipleitemMutation,
  useDeleteMediaLibMutation,
  useGetMediaLibDetailsQuery,
  useGetMediaLibListQuery,
  useGetMediaLibrarySearchResultMutation,
  useReArrangedFeildMutation,
  useUpdateMediaLibMutation,
} from '../../redux/features/media-lib/mediaLibApi';
import useProtected from '../../hooks/useProtected';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import SimpleBackdrop from '../Backdrop/SimpleBackdrop';
import { validateFormData } from '../../utils/form';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import DeleteModal from '../DeleteModal/DeleteModal';

function MediaLibraryTableView(props) {
  const [selectedMediaId, setSelectedMediaId] = useState('');
  const { data, isError, isLoading, error } = useGetMediaLibListQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: mediaDetailsResponse,
    isError: isMediaDetailsError,
    isLoading: isMediaDetailsLoading,
    error: mediaDetailsError,
    refetch: refetchMediaDetails,
  } = useGetMediaLibDetailsQuery(selectedMediaId, {
    refetchOnMountOrArgChange: true,
  });



  const [deleteAMedia, deleteMediaOptions] = useDeleteMediaLibMutation();
  const [deleteMultipleMedia, deleteMultipleMediaOptions] = useDeleteMediaLibMultipleitemMutation();
  const [updateAMedia, updateMediaOptions] = useUpdateMediaLibMutation();
  const [createMedia, createMediaOptions] = useAddMediaLibMutation();

  useProtected(error);
  useProtected(mediaDetailsError);
  useProtected(deleteMediaOptions.error);
  useProtected(updateMediaOptions.error);
  useProtected(createMediaOptions.error);
  useProtected(deleteMultipleMediaOptions.error);

  const user = useSelector(state => state.auth.user);

  const [getSearchedMediaLibrary, options] =
    useGetMediaLibrarySearchResultMutation();

  const [reArrangedRows, reArrangedRowsOptions] =
    useReArrangedFeildMutation();

  const [rows, setRows] = useState([]);
  const [addedMediaItems, setAddedMediaItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [errors, setErrors] = useState({
    videoName: '',
    videoUrl: '',
  });





  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Media',
      width: '50%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Type',
      width: '15%',
      textAlign: 'left',
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByType('mimeType'),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Size',
      width: '15%',
      textAlign: 'left',
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingBySize('size'),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      width: '15%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '15%',
      textAlign: 'left',
    },
  ];
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        cell: [
          {
            Check: true,
            img: generaImageUrl(item),
            imgStatus: true,
            text: item?.name,
            linkText: item?.name,
            linkTextStatus: false,
            mediaItem_id: item?._id,
            checked: true
            // onClickLinkText: handleDrawerOpenEditMedia(item._id),
            // onClick: handleDrawerOpenEditMedia(item._id),
          },
          { text: item?.mimeType },
          {
            text: item?.mimeType?.startsWith("image/") || item?.mimeType?.startsWith("audio/") ? convertFileSize(item?.size) : "N/A",
            textcolor: item?.isImage ? '#1B1B1F' : '#A4A7B0',
          },
          // { text: convertDate(item.created_at) },
          { text: item?.createdAt ? moment(item?.createdAt).format("DD MMM, YYYY") : "N/A" },
          {
            Button: true,
            buttonLabel: 'Edit',
            buttonTextcolor: '#004FE0',
            buttonBorder: '#E4E8EC 1px solid',
            onClick: handleDrawerOpenEditMedia(item?._id),
          },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setMediaListings(data?.body?.data)
    }
  }, [data]);


  const tabData = [
    {
      lebel: 'Upload from device',
      icon: <UploadFileRoundedIcon />,
    },

    // {
    //   lebel: 'YouTube video',
    //   icon: <YouTubeIcon />,
    // },
  ];

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch()
      }, 300);
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedMediaLibrary({ term: "" });
    searchDataShowing(result)
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedMediaLibrary({ term: searchTerm });
    searchDataShowing(result)
  };
  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong")
    }
    setPageNumberFromOutSide(false)
  }
  // =========== search part start ====================== //
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const childRef = useRef(null);

  const [file_name, setFileName] = useState('');
  const [file_type, setFileType] = useState('');
  const [file_size, setFileSize] = useState('');
  const [file_url, setFileUrl] = useState('');
  const [file_id, setFileId] = useState('');
  const [created_at, setCreatedAt] = useState('');
  const [medianame, setMediaName] = useState('');
  const [fileMeta, setFileMeta] = useState({});

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [videoName, setVideoName] = useState('');

  const [imageName, setImageName] = useState('');

  const [videoUrl, setVideoUrl] = useState('');

  const [addNewItem, setAddNewItem] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);

  const [imageNameError, setImageNameError] = useState(false);

  const [imageNameEditError, setImageNameEditError] = useState('');

  const [fileUploadError, setFileUploadError] = useState(false);

  const [isfileSelected, setFileSelected] = useState(false);
  const [selectedToBlank, setSelectedToBlank] = useState(false)

  const [imaneNameErrorMessage, setImageNameErrorMessage] = useState('');
  const fileInputRef = useRef(null);
  const [filePathData, setFilePathData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };
  const handleDrawerCloseNewMedia = () => {
    setErrors({
      name: '',
      url: '',
      videoName: '',
      videoUrl: '',
    });
    setImageNameErrorMessage("");
    setImageNameError(false);
    setImageName("");
    setOpenNewMedia(false);
    setFileUploadError(false);
    setFileSelected(false);
    setSelectedFile(null)
  };

  const [openEditMedia, setOpenEditMedia] = useState(false);
  const handleDrawerOpenEditMedia = (id) => async (event) => {
    await setSelectedMediaId(id);
    const result = await refetchMediaDetails();
    //find the data from the totalItems array
    if (!result?.data?.error) {
      const data = result.data.body.data;
      setFileName(data.originalFileName);
      setFileType(data.mimeType);
      setFileSize(convertFileSize(data.size));
      setFileUrl(data.url || data.thumbnail);
      setFileId(data._id);
      setCreatedAt(moment(data.created_at).format('DD MMM, 2023'));
      setMediaName(data.name);
      //json parse the file_meta and set the values
      setFileMeta({
        width: data?.width || 0,
        height: data?.height || 0,
      });
      setOpenEditMedia(true);
    } else {
      toast.error(result.data.message);
    }
  };
  const handleDrawerCloseEditMedia = () => {
    setOpenEditMedia(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
    setDeleteMedia(false);
  };

  const [uploadButtonText, setUploadButtonText] = useState('Upload Image');

  const deleteConfirm = () => {
    setDeleteMedia(true);
  };

  const deleteMediaItem = async () => {
    //open loading icon
    // setOpenBackdrop(true);
    //call api to delete the media
    const result = await deleteAMedia(file_id);

    if (!result?.data?.error) {
      toast.success(result.data.message);
      setOpenEditMedia(false);
      setOpen(false);
      setDeleteMedia(false);
    } else {
      toast.error(result.data.message);
      setDeleteMedia(false);
    }
  };

  const [videoUpload, setVideoUpload] = useState(false);

  const handleTabChange = (event) => {
    if (event == 1) {
      setUploadButtonText('Import from YouTube');
      setVideoUpload(true);
    } else {
      setUploadButtonText('Upload Image');
      setVideoUpload(false);
    }
  }

  const validateYouTubeUrl = (url) => {
    const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    return youtubePattern.test(url);
  }

  const clickSaveButton = async () => {
    // setOpenBackdrop(true);
    setErrors({
      name: '',
      videoNameError: '',
      url: '',
    });

    if (videoUpload) {
      const data = {
        name: videoName,
        url: videoUrl,
        mimeType: 'video/mp4',
      };
      const isValid = validateFormData(data, setErrors, {
        url: 'Video url is required',
      });

      if (!isValid) {
        setErrors((prev) => ({
          ...prev,
          videoNameError: 'Video name is required',
        }));
        return;
      }
      const isYouTubeUrlValid = validateYouTubeUrl(videoUrl);

      if (!isYouTubeUrlValid) {
        setErrors((prev) => ({
          ...prev,
          url: 'Please enter a valid YouTube URL.',
        }));
        return;
      }

      if (!isValid) {
        return;
      }

      // save data
      //call to api via axios
      const result = await createMedia(data);
      // setOpenBackdrop(false)
      //if response status is 200

      if (!result?.data?.error) {

        toast.success(result.data.message);
        resetYouTabtabHandler()
        setImageName("")

        if (addNewItem) {
          setOpenNewMedia(true);
        } else {
          setOpenNewMedia(false);
          handleDrawerCloseNewMedia()
        }

      } else {
        toast.error(result.data.message);
      }
    } else {
      let error = 0
      if (imageName === '') {
        setErrors((prev) => {
          const temp = prev;
          temp['name'] = 'Please enter media name';
          return temp;
        });
        error++
      } else {
        setImageNameError(false);
        setImageNameErrorMessage('');

      }

      if (isfileSelected === false) {
        setFileUploadError(true);
        error++
      } else {
        setFileUploadError(false);
      }

      if (error > 0) return false;

      if (childRef.current) {
        childRef.current.uploadFile();
      }
    }
    // setOpenBackdrop(false);
  };
  useEffect(() => {
    if (isfileSelected === true) {
      setFileUploadError(false);
    }
  }, [isfileSelected])

  const updateMedia = async () => {
    setOpenBackdrop(true)
    //call api to update the media

    let error = 0
    if (medianame === '') {
      setImageNameEditError('Required')
      error++
    }


    if (error > 0) { setOpenBackdrop(false); return false };

    const result = await updateAMedia({ id: file_id, name: medianame });

    //if response status is 200
    if (!result?.data?.error) {
      toast.success(result.data.message);
      // setOpenEditMedia(false);
    } else {
      toast.error(result.data.message);
    }
    handleDrawerCloseEditMedia()
    setOpenBackdrop(false)
  };

  const resetImagtabHandler = () => {
    setFileSelected(false);
    setImageName("");
    setFileUploadError(false);
    setSelectedFile(null)
  }
  const resetYouTabtabHandler = () => {
    setVideoName('');
    setVideoUrl('');
    setErrors({
      name: '',
      url: '',
      videoName: '',
      videoUrl: '',
    });
  }
  // //function to process fileupload
  const processFileUpload = async (
    filePath,
    fileName,
    fileSize,
    fileType,
    width,
    height,
    is_private
  ) => {
    setOpenBackdrop(true);
    if (fileUploadError) {
      toast.error("Something went wrong when uploading image.")
      setOpenBackdrop(false);
      return;
    }
    const response = await createMedia({
      name: imageName,
      schoolId: user ? user.schollId : "",
      url: filePath,
      originalFileName: fileName,
      size: fileSize,
      width: width,
      height: height,
      mimeType: fileType,
      is_private: is_private
    });

    if (!response.data?.error) {
      toast.success("Media file uploaded successfully");

      resetImagtabHandler()
      if (addNewItem) {
        if (childRef.current) {
          childRef.current.clickButton();
        }
      } else {
        setOpen(false);
        setOpenNewMedia(false);
      }
    } else {
      toast.error(response.data.message);
    }
    setOpenBackdrop(false);
  };



  ///=================================== sorting part start =============//
  const [mediaListings, setMediaListings] = useState([])
  const [asc, setAsc] = useState(true)
  const ascendingOrDescendingSortingByType = (field) => {
    let sortedMediaListing = [...mediaListings]
    if (asc) {
      sortedMediaListing.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedMediaListing.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedMediaListing));
    setAsc(!asc)
  }
  const ascendingOrDescendingSortingBySize = (field) => {
    let sortedMediaListing = [...mediaListings];
    if (asc) {
      sortedMediaListing.sort((a, b) => a[field] - b[field]);
    } else {
      sortedMediaListing.sort((a, b) => b[field] - a[field]);
    }
    setRows(convertDataToCellRows(sortedMediaListing));
    setAsc(!asc);
  }
  ///=================================== sorting part end =============//


  ///=========================== multipule items delete =========================//
  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setAddedMediaItems(preMediaItem => [...preMediaItem, item[0]?.mediaData]);
    } else {
      // Remove the user from the array
      setAddedMediaItems(preMediaItem => preMediaItem.filter(mediaData => mediaData?._id !== item[0]?.mediaData?._id));

    }
  }

  const [deleteMultipleMediaStatus, setDeleteMultipleMediaStatus] = useState(false)
  const handleMultipleMediaItemDelete = async () => {
    setDeleteMedia(true)
    setDeleteMultipleMediaStatus(true)
    // setSelectedToBlank(true)
    // let text = addedMediaItems?.length > 1 ? "Media items have removed" : "Media item has removed"
    // let data = {
    //   mediaLibIds: addedMediaItems,
    // };
    // const result = await deleteMultipleMedia(data);
    // if (result?.data?.body) {
    //   toast.success(text);

    // } else {
    //   toast.error(result?.data?.message);
    // }
    // setSelectedToBlank(false)
    // setAddedMediaItems([])
  }
  const multipleMediaItemDelete = async () => {
    setSelectedToBlank(true)
    let text = addedMediaItems?.length > 1 ? "Media files are removed." : "Media file is removed."
    let data = {
      mediaLibIds: addedMediaItems,
    };
    const result = await deleteMultipleMedia(data);
    if (result?.data?.body) {
      toast.success(text);

    } else {
      toast.error(result?.data?.message);
    }
    setSelectedToBlank(false)
    setAddedMediaItems([])
    setDeleteMultipleMediaStatus(false)
    setDeleteMedia(false)
  }

  ///=========================== multipule items delete =========================//
  // /**
  //  * close backdrop
  //  */
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const fileSelected = (status) => {
    setFileSelected(status);
  };
  const handleCheckboxClicked = (event) => {
    setAddNewItem(event.target.checked);
  };

  // useEffect(() => {
  //   console.log(selectedFile, 345)
  // }, [selectedFile])
  const panelTab = [
    {
      id: 0,
      panleBody: (
        <UploadFromDevice
          handleTabChange={handleTabChange}
          // handleSnackbarOpen={handleSnackbarOpen}
          setImageName={setImageName}
          videoName={videoName}
          videoUrl={videoUrl}
          imageName={imageName}
          showImageNameError={imageNameError}
          onFileUpload={processFileUpload}
          ref={childRef}
          fileSelected={fileSelected}
          showFileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
          imageErrorMessage={imaneNameErrorMessage}
          errors={errors}
          isLoading={createMediaOptions?.isLoading}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileInputRef={fileInputRef}
          filePathData={filePathData}
          setFilePathData={setFilePathData}
          resetYouTabtabHandler={resetYouTabtabHandler}
          uploaded={uploaded}
          setUploaded={setUploaded}

        />
      ),

      SaveButtonLabel: 'Upload File',
      clickSaveButton: clickSaveButton,
      CancelButtonLabel: 'Cancel',
      disabled: uploaded ? true : false,
      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      // onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: 'Delete Permanently',
      onClickCheckbox: handleCheckboxClicked,
      handleTabChange: handleTabChange,
      // onClickDelete:onClickDelete,
    },

    // {
    //   id: 1,
    //   panleBody: (
    //     <UploadFromYoutube
    //       setVideoName={setVideoName}
    //       setVideoUrl={setVideoUrl}
    //       onCheckboxClicked={handleCheckboxClicked}
    //       videoName={videoName}
    //       videoUrl={videoUrl}
    //       errors={errors}
    //       isLoading={createMediaOptions?.isLoading}
    //       resetImagtabHandler={resetImagtabHandler}

    //     />
    //   ),
    //   SaveButtonLabel: 'Import From YouTube',
    //   clickSaveButton: clickSaveButton,
    //   cancelButtonLabel: 'Cancel',
    //   videoUrl: videoUrl,
    //   videoName: videoName,
    //   CancelButtonLabel: 'Cancel',
    //   handleTabChange: handleTabChange,
    //   Checkbox: true,
    //   CheckboxLabel: "Add another after saving",
    //   onClickCheckbox: handleCheckboxClicked,
    //   arrowIcon: true,
    // },
  ];

  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
  };
  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="TableSection">
        {/* <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} /> */}
        <Table
          title="Media Library"
          tableHeader={true}
          addLabel="Add New"
          icon={<AddIcon />}
          headCells={headCells}
          rows={rows}
          AddButton={true}
          onClick={handleDrawerOpenNewMedia}
          // Checkbox={true}
          handleCheckItem={setAddedMediaItems}
          selectedCheckList={addedMediaItems}
          handleRowClick={handleRowClick}
          CheckboxDelete={false}
          DateField={false}
          customCellObjectId="mediaItem_id"
          CheckboxDeleteModal={true}
          DeleteText="REMOVE ITEMS"
          DeleteButtonOnclick={handleMultipleMediaItemDelete}
          setSelectedToBlank={selectedToBlank}

          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          innerHeader={true}
          SearchBoxTop={true}
          FilterButton={false}
          searchBoxPlaceholder="Search"
          isDataLoading={isLoading}
          emptyTableTitle="No media added yet"
          emptyTableNote="No items available"
          btnLabel="Add New"
          RowsPerPage={20}
          pageNumberFromOutSide={pageNumberFromOutSide}
          onClickEmptyCard={handleDrawerOpenNewMedia}
        />
      </div>

      {/* <div className="TableSection">
        <button onClick={() => handleDrawerOpenSelectMedia()}>Select Media</button>
      </div> */}

      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        //clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSelectMedia}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        clickButtonLeft={handleDrawerOpenNewMedia}
        body={<MediaLibrarySelectMedia />}
      />

      <SideDrawer
        title="Upload New Media"
        ButtonLabel={uploadButtonText}
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openNewMedia}
        handleDrawerClose={handleDrawerCloseNewMedia}
        // CheckboxLabel="Add another after saving"
        // Checkbox={true}
        onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        //=== Tabs Codes ===//
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
      />

      <SideDrawer
        title="Edit Media"
        ButtonLabel="Update"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        onClickDelete={deleteConfirm}
        clickSaveButton={updateMedia}
        DeleteButton={true}
        sideOpen="right"
        open={openEditMedia}
        handleDrawerClose={handleDrawerCloseEditMedia}
        CheckboxLabel="Add another after saving"
        FooterWithoutTab={true}
        body={
          <EditMedia
            file_name={file_name}
            file_type={file_type}
            file_size={file_size}
            file_url={file_url}
            file_id={file_id}
            created_at={created_at}
            medianame={medianame}
            setMediaName={setMediaName}
            fileMeta={fileMeta}
            isLoading={updateMediaOptions?.isLoading || isMediaDetailsLoading}
            imageNameEditError={imageNameEditError}
          />
        }
      />


      {/* 
      <DraggableDialog
        title="Delete this media?"
        body={
          <div>
            Are you sure you want to delete this?<br></br> Once you delete it,
            you can't get it back.
          </div>
        }
        openDialog={deleteMedia}
        handleCloseDialog={handleClose}
        ModalFooter={true}
        ButtonClose="Cancel"
        ButtonConfirm="Delete"
        width="460px"
        textAlign="center"
        handleConfirmCloseDialog={deleteMediaItem}
      /> */}

      <DeleteModal
        DeletedConfirmWord="CONFIRM"
        openModal={deleteMedia}
        handleClose={handleClose}
        handleConfirm={deleteMultipleMediaStatus ? multipleMediaItemDelete : deleteMediaItem}
        title="Delete this media?"
        body={
          <div>
            Are you sure you want to delete this?<br></br> Once you delete it,
            you can't get it back.
          </div>
        }
      />
    </>
  );
}

export default MediaLibraryTableView;
